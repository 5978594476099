import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.title',
		defaultMessage: 'Fulfillment Information',
	},
	lblTicketDeliveryOption: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.ticketDeliveryOption',
		defaultMessage: 'Ticket Delivery Option',
	},
	lblTicketLocator: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.ticketLocator',
		defaultMessage: 'Ticket Locator ID',
	},
	lblTicketLocatorStatus: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.ticketLocatorStatus',
		defaultMessage: 'Ticket Locator Status',
	},
	lblIssuedDate: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.issuedDate',
		defaultMessage: 'Issued Date',
	},
	lblRetrievalInformation: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.retrievalInformation',
		defaultMessage: 'Retrieval Information',
	},
	lblRetrievalInformationCustomer: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.retrievalInformationCustomer',
		defaultMessage: 'Customer: {value}',
	},
	lblRetrievalInformationCreditCard: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.retrievalInformationCreditCard',
		defaultMessage: 'Credit Card: {value}',
	},
	lblRetrievalInformationCode: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.retrievalInformationCode',
		defaultMessage: 'Code: {value}',
	},
	lblPickUpStation: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.pickUpStation',
		defaultMessage: 'Pick Up Station:',
	},
	lblShippingName: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.shippingName',
		defaultMessage: 'Shipping Name: {name}',
	},
	lblShippingAddress: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.shippingAddress',
		defaultMessage: 'Shipping Address: {address} {city} {country} {zipCode}',
	},
	lblPaperEmail: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.paperEmail',
		defaultMessage: 'Agent Email: {email}',
	},
	lblSMSPhone: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.smsPhone',
		defaultMessage: 'Phone Number: {smsPhone}',
	},
	lblValueDocumentCouponsTitle: {
		id: 'app.shopping.manageBooking.valueDocumentCoupons.title',
		defaultMessage: 'Value Document Coupons',
	},
	lblAmendCTR: {
		id: 'app.shopping.manageBooking.fulfillmentInformation.amendCTR',
		defaultMessage: 'Simulate ticket pickup',
	},
});
