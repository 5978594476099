const styles = {
	container: {
		display: 'inline-flex',
		width: '100%',
		flex: '50%',
		'&.error': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: '#d32f2f',
			},
		},
	},
	errorText: {
		color: '#d32f2f',
		margin: 'unset',
		fontWeight: '400',
		fontSize: '0.75rem',
		lineHeight: '1.66', // SA Magic Number
		marginTop: '3px',
	},
	depArr: {
		width: 140,
		paddingRight: 15,
		marginTop: 30,
	},
	datepicker: {
		flexGrow: 1,
	},
	datepickerInput: {
		textTransform: 'uppercase',
	},
};

export default styles;
