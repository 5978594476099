export default {
	linkTitle: {
		cursor: 'pointer',
	},
	title: {
		marginRight: 10,
	},
	rowItemStyle: {
		whiteSpace: 'normal',
		paddingLeft: 10,
		paddingRight: 10,
	},
	buttonStyle: {
		padding: 0,
		margin: 0,
		border: 'none',
		background: 'transparent',
		cursor: 'pointer',
		outline: 'none',
		float: 'right',
	},
	buttons: {
		marginTop: 10,
	},
	spinner: {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	wrapper: {
		position: 'relative',
	},
};
