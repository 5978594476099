import { black, red } from '../../../../colors';

export default {
	orderTitle: {
		fontSize: '1.5em',
	},
	orderSubtitle: {
		fontSize: '0.9em',
		fontWeight: 500,
		color: black,
		marginLeft: 8,
	},
	fraudDetected: {
		fontSize: '1.2em',
		fontWeight: 700,
		color: red,
		marginLeft: 8,
	},
};
