import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { gaEvent } from '../../utils/googleAnalytics';
import messages from './messagesAbout';

import './header.css';
import inlineStyles from './styles';

import amex from '../../images/amex.png';
import diners from '../../images/diners.png';
import discover from '../../images/discover.png';
import jcb from '../../images/jcb.png';
import maestro from '../../images/maestro.png';
import mastercard from '../../images/mastercard.png';
import solo from '../../images/solo.png';
import visa from '../../images/visa.png';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const paymentCards = [
	amex,
	diners,
	discover,
	jcb,
	maestro,
	mastercard,
	solo,
	visa,
];

const About = ({
	open,
	handleClose,
	projectVersion,
	intl: { formatMessage },
}) => {
	const trimmedVersion =
		projectVersion?.length && projectVersion.indexOf('-') !== -1
			? projectVersion.split('-')[0]
			: projectVersion;

	const termsURL = `${process.env.API_HOST}${process.env.BASE_PATH_SUFFIX}resources/js/templates/SRT-Terms-and-Conditions.html`;

	const actions = (
		<div className="row" style={inlineStyles.dialogActionsRoot}>
			<div className="col-4 offset-8 col-sm-3 offset-sm-9">
				<Button variant="contained" onClick={handleClose} fullWidth>
					{formatMessage(messages.lblClose)}
				</Button>
			</div>
		</div>
	);

	const aboutTermsClick = () => {
		gaEvent('aboutTermsConditions');
	};

	return (
		<div>
			<StyledDialog open={open} onClose={handleClose} maxWidth={false}>
				<DialogTitle>{formatMessage(messages.lblAboutTitle)}</DialogTitle>
				<DialogContent>
					<div styleName="about">
						<div>{formatMessage(messages.lblAboutSubsidiaries)}</div>
						<div>{formatMessage(messages.lblAboutFinancial)}</div>
						<div>{formatMessage(messages.lblAboutCreditCard)}</div>
						<div>
							{formatMessage(messages.lblAboutContactSupport)}
							<br />
							{formatMessage(messages.lblAboutContactEmail)}
							<br />
							{formatMessage(messages.lblAboutContactPhone)}
						</div>
						<div>{`${formatMessage(messages.lblAboutSilverAgentVersion)} ${trimmedVersion}`}</div>
						<div>
							{formatMessage(messages.lblAboutSilverAgentRights1, {
								year: new Date().getFullYear(),
							})}
							<br />
							{formatMessage(messages.lblAboutSilverAgentRights2)}
						</div>
						<h4>{formatMessage(messages.lblAboutAcceptedPayment)}</h4>
					</div>
					<ImageList cellheight={44} style={inlineStyles.gridList} cols={4}>
						{paymentCards.map((cardUrl) => (
							<ImageListItem key={cardUrl}>
								<img role="presentation" src={cardUrl} />
							</ImageListItem>
						))}
					</ImageList>
					<div styleName="about-terms">
						<a
							href={termsURL}
							target="_blank"
							rel="noopener noreferrer"
							onClick={aboutTermsClick}
						>
							{formatMessage(messages.lblAboutTerms)}
						</a>
					</div>
				</DialogContent>
				<DialogActions>{actions}</DialogActions>
			</StyledDialog>
		</div>
	);
};

About.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired,
	}).isRequired,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	projectVersion: PropTypes.string,
};

export { About as AboutAlias };

export default injectIntl(About);
