import { whiteSmoke2, midGray2 } from '../../../colors';

export default {
	dialogPaper: {
		overflowY: 'hidden',
		maxWidth: 768,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	dialogContent: {
		padding: 0,
	},
	iconLeft: {
		marginLeft: 15,
	},
	floatingLabel: {
		fontWeight: 'bold',
	},
	subtitle: {
		marginTop: 25,
		marginBottom: 5,
	},
	travelDoc: {
		marginTop: 10,
	},
	loyaltyCard: {
		marginTop: 20,
	},
	tabContainer: {
		padding: '0px 24px 24px',
	},
	containerOverflow: {
		overflowX: 'hidden',
	},
	otherBookingsTable: {
		minWidth: 200,
		table: {
			minWidth: 200,
		},
	},
};

export const withStylesTheme = (theme) => ({
	tabRoot: {
		color: 'black',
		[theme.breakpoints.up('md')]: {
			minWidth: 0,
			fontSize: 14,
		},
	},
	tabsRoot: {
		backgroundColor: whiteSmoke2,
		fontSize: 14,
	},
	tabsIndicator: {
		backgroundColor: midGray2,
	},
});
