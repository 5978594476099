import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';

import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import { LOCAL_STORAGE_KEYS, USER_LOGOUT } from './constants/constants';

/* eslint-disable no-underscore-dangle */
const srtCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const initialState = fromJS({});

export const userLogout = () => {
	localStorage.setItem(LOCAL_STORAGE_KEYS.loggedOut, 'true');
	return { type: USER_LOGOUT };
};

export const configureStore = () => {
	const newStore = createStore(
		rootReducer,
		initialState,
		srtCompose(applyMiddleware(thunkMiddleware)),
	);

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('./reducers', () => newStore.replaceReducer(rootReducer));
	}

	return newStore;
};

export const store = configureStore();
