import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.returnCouponsDialog.title',
		defaultMessage: 'Return Coupons',
	},
	lblReturnUtn: {
		id: 'app.shopping.returnCouponsDialog.returnUtn',
		defaultMessage: 'Return by Unique Ticket Number',
	},
	lblForceCancel: {
		id: 'app.shopping.returnCouponsDialog.forceCancel',
		defaultMessage: 'Force cancellation',
	},
	btnClose: {
		id: 'app.shopping.returnCouponsDialog.close',
		defaultMessage: 'Discard Changes',
	},
	btnSubmit: {
		id: 'app.shopping.returnCouponsDialog.submit',
		defaultMessage: 'Submit Coupons As Returned',
	},
	lblReceiptDate: {
		id: 'app.shopping.returnCouponsDialog.receiptDate',
		defaultMessage: 'Receipt Date',
	},
	lblNumberOfCoupons: {
		id: 'app.shopping.returnCouponsDialog.numberOfCoupons',
		defaultMessage: 'No. of Coupons',
	},
	lblNotes: {
		id: 'app.shopping.returnCouponsDialog.notes',
		defaultMessage: 'Notes',
	},
	lblTravelSegments: {
		id: 'app.shopping.returnCouponsDialog.travelSegments',
		defaultMessage: 'Travel Segments',
	},
	lblAvailableCoupons: {
		id: 'app.shopping.returnCouponsDialog.availableCoupons',
		defaultMessage: 'Number of Coupons To Return',
	},
	lblReturnedCoupons: {
		id: 'app.shopping.returnCouponsDialog.returnedCoupons',
		defaultMessage: 'Number of Previously Returned Coupons',
	},
	lblTravelTo: {
		id: 'app.shopping.returnCouponsDialog.travelTo',
		defaultMessage: 'to',
	},
	errNoReceiptDate: {
		id: 'app.shopping.returnCouponsDialog.noReceiptDate',
		defaultMessage: '^Please enter a receipt date.',
	},
	errNoNote: {
		id: 'app.shopping.returnCouponsDialog.noNote',
		defaultMessage: '^Please enter a note.',
	},
	errNoCouponsSelected: {
		id: 'app.shopping.returnCouponsDialog.noCouponsSelected',
		defaultMessage: 'Please select coupons to return.',
	},
	errGeneric: {
		id: 'app.shopping.returnCouponsDialog.errGeneric',
		defaultMessage:
			'Error(s) detected in the form. Please check the form for details.',
	},
});
