import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';
import dompurify from 'dompurify';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import {
	loadEmailConfirmationApi,
	sendEmailConfirmationApi,
} from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';

import messages from './messagesBookingEmailConfirmation';
import './stylesBookingEmailConfirmation.css';
import inlineStyles from './styles';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../utils/googleAnalytics';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});

class BookingEmailConfirmationDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		booking: PropTypes.object.isRequired,
		onBookingEmailConfirmationSuccess: PropTypes.func,
		orderId: PropTypes.string,
	};

	initialState = {
		emailHtml: '',
		isFetching: false,
		isProcessingSubmit: false,
		submitDisabled: false,
		alertText: '',
		email: '',
		errors: {},
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS(this.initialState),
		};
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { open } = this.props;
		if (open === false && nextProps.open) {
			this.setState(
				(state) => ({
					data: state.data.merge(
						Object.assign(this.initialState, { isFetching: true }),
					),
				}),
				() => {
					const { booking, orderId } = this.props;
					const primaryName = booking.BookingPrimaryPaxFirstName;
					const primaryLastName = booking.BookingPrimaryPaxLastName;
					const primaryPassenger = booking.BookingPassengers.find(
						(passenger) =>
							passenger.BookingPaxFirstName.indexOf(primaryName) !== -1 &&
							passenger.BookingPaxLastName.indexOf(primaryLastName) !== -1,
					);

					let email = '';
					if (!validate.isEmpty(primaryPassenger)) {
						email =
							primaryPassenger.BookingPaxContactEmailAddresses[0]
								.BookingPaxContactInfo;
					}

					loadEmailConfirmationApi(
						{
							queryItems: booking.queryItems,
							orderId,
							bookingOrders: booking.BookingOrders,
						},
						(response) => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									submitDisabled: true,
									alertText: response.errorResponse.message,
								}),
							}));
						},
						(response) => {
							const responseHtml = new DOMParser().parseFromString(
								response,
								'text/html',
							);
							const errorMessage =
								responseHtml.getElementById('srtErrorMessage');

							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									emailHtml:
										response.successResponse.data.BookingConfirmationEmail,
									email,
									submitDisabled: !!errorMessage,
									alertText: errorMessage ? errorMessage.innerHTML.trim() : '',
								}),
							}));
						},
					);
				},
			);
		}
	};

	constraints = (formatMessage) => ({
		email: {
			presence: {
				message: formatMessage(messages.errConfirmationEmail),
				allowEmpty: false,
			},
			email: {
				message: formatMessage(messages.errConfirmationEmail),
			},
		},
	});

	handleChangeField = ({ target: { name, value } }) => {
		this.setState((state) => ({ data: state.data.merge({ [name]: value }) }));
	};

	handleErrorResponse = (errorMessage) => {
		this.setState((state) => ({
			data: state.data.merge({
				isProcessingSubmit: false,
				alertText: errorMessage,
			}),
		}));
	};

	handleSendBookingEmailConfirmation = () => {
		this.setState(
			(state) => ({ data: state.data.merge({ isProcessingSubmit: true }) }),
			() => {
				const {
					booking,
					orderId,
					onBookingEmailConfirmationSuccess,
					onClose,
					intl: { formatMessage },
				} = this.props;
				const data = this.state.data.toJS();
				const errors = validate(data, this.constraints(formatMessage));
				if (!validate.isEmpty(errors)) {
					this.setState((state) => ({
						data: state.data.merge({
							isProcessingSubmit: false,
							errors,
						}),
					}));
					return;
				}

				sendEmailConfirmationApi(
					{
						booking,
						email: data.email,
						orderId,
					},
					(response) => {
						this.handleErrorResponse(response.errorResponse.message);
					},
					() => {
						this.setState(
							(state) => ({
								data: state.data.merge({
									isProcessingSubmit: false,
									alertText: '',
								}),
							}),
							() => {
								if (orderId) {
									onBookingEmailConfirmationSuccess(booking.queryItems);
								}
								onClose();
							},
						);
					},
				);
			},
		);
	};

	handleSnackBarClose = () => {
		this.setState((state) => ({ data: state.data.set('alertText', '') }));
	};

	render() {
		const {
			open,
			onClose,
			intl: { formatMessage },
		} = this.props;
		const data = this.state.data.toJS();
		const disabled = data.isFetching || data.isProcessingSubmit;
		const submitDisabled = disabled || data.submitDisabled;
		const sanitizer = dompurify.sanitize;

		const closeAction = () => {
			gaEvent('emailConfirmationClose');
			onClose();
		};
		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-12 col-sm-3 offset-sm-4">
					<Button
						variant="contained"
						id="srtBookingEmailClose"
						onClick={closeAction}
						fullWidth
						disabled={disabled}
					>
						{formatMessage(messages.btnCancel)}
					</Button>
				</div>
				<div className="col-12 col-sm-5">
					<Button
						variant="contained"
						id="srtBookingEmailSend"
						onClick={this.handleSendBookingEmailConfirmation}
						color="primary"
						fullWidth
						disabled={submitDisabled}
					>
						{formatMessage(messages.btnSubmit)}
					</Button>
				</div>
			</div>
		);

		const titleDiv = (
			<div className="row">
				<div className="col-12 col-sm-6">
					{formatMessage(messages.lblTitle)}
				</div>
				<div
					className="col-12 col-sm-6 text-right"
					style={inlineStyles.titleLabelContainer}
				>
					{formatMessage(messages.lblNotTicket)}
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={open}
					onClose={closeAction}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{titleDiv}</DialogTitle>
					<StyledDialogContent>
						<div className="container-fluid">
							<div className="row" styleName="emailContainer">
								<div
									className="col-12 col-sm-6 text-right"
									styleName="emailLabel"
								>
									{formatMessage(messages.lblSentToEmail)}
								</div>
								<div className="col-12 col-sm-6 ">
									<TextField
										id="srtEmailToSend"
										name="email"
										disabled={disabled}
										fullWidth
										onChange={this.handleChangeField}
										value={data.email}
										error={data.errors && !!data.errors.email}
										helperText={data.errors && data.errors.email}
										variant="standard"
									/>
								</div>
							</div>
						</div>
						{/* eslint-disable-next-line react/no-danger */}
						<div
							dangerouslySetInnerHTML={{ __html: sanitizer(data.emailHtml) }}
						/>
						<RefreshIndicator
							size={36}
							top={0}
							left={0}
							status={disabled ? 'loading' : 'hide'}
							style={inlineStyles.indicator}
						/>
					</StyledDialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtBookingBookingEmailConfirmationSnackBar"
					open={data.alertText !== ''}
					message={data.alertText}
					onClose={this.handleSnackBarClose}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	feeTypes: state.getIn(['settings', 'ServiceFeeTypes'])?.toJS(),
});

const mapDispatchToProps = (dispatch) => ({
	onBookingEmailConfirmationSuccess: bindActionCreators(
		fetchBookingDetails,
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(BookingEmailConfirmationDialog));
