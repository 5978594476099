import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FileCopy from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import DateSelector from '../../../components/DateSelector/DateSelector';
import Payment from '../../../components/Payment/Payment';
import SendToEmail from '../SendToEmail/SendToEmail';

import { confirmationOptions, findConfirmationValues } from '../../utils';
import messages from './messagesConfirmation';
import inlineStyles from './styles';

const loyaltyCardDisplayYears = (() => {
	const SHOW_YEARS = 10;
	const currentYear = new Date(Date.now()).getFullYear();
	const years = [];
	for (let i = 0; i <= SHOW_YEARS; ++i) {
		years.push(currentYear + i);
	}
	return years;
})();

const Confirmation = ({
	id,
	confirmationDetails,
	confirmationDetails: {
		type,
		language,
		expiryDateDay: confirmExpiryDateDay,
		expiryDateMonth: confirmExpiryDateMonth,
		expiryDateYear: confirmExpiryDateYear,
		isCardTypeAvailable,
		vdEmail,
		showAlternateEmail: confirmShowAlternateEmail,
		loyaltyCardProgram,
		loyaltyCardIdentifier,
	},
	errors,
	styles,
	disabled,
	emails,
	disableCustomerEmailSending,
	paymentInfo,
	intl: { formatMessage },
	isSms,
	onChange,
	confirmationInformation,
	showAlternateEmail,
	columnClass,
	confirmationOnly,
}) => {
	const {
		manual,
		none,
		manualEach,
		confirmed,
		creditCard,
		debitCard,
		loyaltyCard,
		manualNoInfo,
	} = confirmationOptions;

	const onUsePaymentCard = () => {
		const { method, cardNumber, expiryDateMonth, expiryDateYear } = paymentInfo;
		onChange({
			method,
			cardNumber,
			expiryDateMonth,
			expiryDateYear,
		});
	};

	const determineCardType = (confirmationType) => {
		let cardType = '';
		if (confirmationType === creditCard) {
			cardType = 'CC';
		} else if (confirmationType === debitCard) {
			cardType = 'DC';
		}
		return cardType;
	};

	const handleChange = (updates) => {
		onChange(updates);
	};

	const handleChangeCheckField = (event, value) => {
		handleChange({ type: value });
	};

	const handleChangeField =
		(name) =>
		({ target }) => {
			handleChange({ [name || target.name]: target.value });
		};

	const renderMessage = (confirmationType) => {
		let message = '';
		switch (confirmationType) {
			case manual:
				message = formatMessage(messages.lblConfirmAfterMessage);
				break;
			case none:
				message = formatMessage(messages.lblConfirmAutomaticMessage);
				break;
			case manualEach:
				message = formatMessage(messages.lblConfirmEachMessage);
				break;
			case confirmed:
				message = formatMessage(messages.lblConfirmedMessage);
				break;
			case creditCard:
			case debitCard:
			case loyaltyCard:
				message = formatMessage(messages.lblConfirmCarryCard);
				break;
			case manualNoInfo:
			default:
				break;
		}

		return message;
	};

	// TODO workaround for RadioButtonGroup issue https://github.com/callemall/material-ui/issues/6344
	// if fixed please remove and place RadioButton inside render function
	const renderBasicConfirmationOption = () => {
		const options = [];
		options.push(
			<FormControlLabel
				key={`confOption_${manualNoInfo}`}
				value={manualNoInfo}
				label={formatMessage(messages.lblConfirmOrderUponPayment)}
				disabled={disabled}
				control={<Radio />}
			/>,
		);
		if (!isCardTypeAvailable) {
			options.push(
				<FormControlLabel
					key={`confOption_${manual}`}
					value={manual}
					label={formatMessage(messages.lblDoNotConfirmOrderUponPayment)}
					disabled={disabled}
					control={<Radio />}
				/>,
			);
		}
		return options;
	};

	const renderConfirmationOption = (options) => {
		const confOptions = [];
		options.forEach((confirmationOption) => {
			let item = null;
			switch (confirmationOption.BookingPaymentConfirmationType) {
				case creditCard:
					item = (
						<FormControlLabel
							key={`confOption_${creditCard}`}
							value={creditCard}
							label={formatMessage(messages.lblCreditCard)}
							disabled={disabled}
							control={<Radio />}
						/>
					);
					break;
				case debitCard:
					item = (
						<FormControlLabel
							key={`confOption_${debitCard}`}
							value={debitCard}
							label={formatMessage(messages.lblDebitCard)}
							disabled={disabled}
							control={<Radio />}
						/>
					);
					break;
				case confirmationOptions.loyaltyCard:
					item = (
						<FormControlLabel
							key={`confOption_${loyaltyCard}`}
							value={loyaltyCard}
							label={formatMessage(messages.lblLoyaltyCard)}
							disabled={disabled}
							control={<Radio />}
						/>
					);
					break;
				default:
					break;
			}
			if (item) {
				confOptions.push(item);
			}
		});
		return confOptions;
	};

	const isNotManual = manual !== type;
	const hasMultipleOptions =
		confirmationInformation.BookingPaymentConfirmationOptions.length > 1;
	const hasEmail =
		emails && emails.length === 1 && isNotManual && !showAlternateEmail;
	const hasMultipleEmail =
		(emails && emails.length > 1 && isNotManual) || showAlternateEmail;
	const message = renderMessage(type);

	const optionValues = findConfirmationValues(type, confirmationInformation);
	const cardType = determineCardType(type);
	const confirmAll = type !== manual && type !== manualEach;
	const copyPaymentCard = paymentInfo &&
		optionValues &&
		optionValues.BookingPaymentConfirmationValues &&
		optionValues.BookingPaymentConfirmationValues.find(
			(item) => item.BookingPaymentConfirmationValueType === paymentInfo.method,
		) && (
			<Tooltip title={formatMessage(messages.lblUsePaymentCard)}>
				<IconButton
					aria-label={formatMessage(messages.lblUsePaymentCard)}
					onClick={onUsePaymentCard}
					style={inlineStyles.usePaymentCardButton}
				>
					<FileCopy />
				</IconButton>
			</Tooltip>
		);

	return (
		<div className="row">
			<div className={columnClass}>
				{!confirmationOnly ? (
					<div>
						<h5 style={styles.subTitle}>{formatMessage(messages.lblTitle)}</h5>
						{type === confirmationOptions.manual ||
						type === confirmationOptions.manualNoInfo ||
						isCardTypeAvailable ? (
							<div id="srtBasicConfirmationOptions">
								<RadioGroup
									name="confirmationOption"
									onChange={handleChangeCheckField}
									value={
										isCardTypeAvailable
											? confirmationOptions.manualNoInfo
											: type
									}
								>
									{renderBasicConfirmationOption()}
								</RadioGroup>
							</div>
						) : null}
					</div>
				) : (
					<div style={inlineStyles.confirmationNotRequired}>
						{formatMessage(messages.lblConfirmationNotRequired)}
					</div>
				)}
				{hasMultipleOptions ? (
					<div id="srtFormsOfConfirmationOptions">
						<h5>{formatMessage(messages.lblAcceptableConfirmationOptions)}</h5>
						<RadioGroup
							name="confirmationCards"
							onChange={handleChangeCheckField}
							value={type}
						>
							{renderConfirmationOption(
								confirmationInformation.BookingPaymentConfirmationOptions,
							)}
						</RadioGroup>
					</div>
				) : null}
				{confirmAll && language !== '' ? (
					<div id="languageSelectorContainer">
						<TextField
							id="srtTicketLanguageSelector"
							label={formatMessage(messages.lblTicketLanguage)}
							fullWidth
							onChange={handleChangeField('language')}
							error={!!errors.language}
							helperText={errors.language}
							value={language}
							disabled={disabled}
							select
							variant="standard"
						>
							{confirmationInformation.BookingPaymentConfirmationTicketLanguages.map(
								(lang) => (
									<MenuItem
										id={`${id}_lang${lang.BookingPaymentConfirmationTicketLanguageType}`}
										key={`lang_${lang.BookingPaymentConfirmationTicketLanguageType}`}
										value={lang.BookingPaymentConfirmationTicketLanguageType}
									>
										{lang.BookingPaymentConfirmationTicketLanguageDescription}
									</MenuItem>
								),
							)}
						</TextField>
					</div>
				) : null}
				{message !== '' ? (
					<div className="row" style={inlineStyles.messageContainer}>
						<div className="col-12">{message}</div>
					</div>
				) : null}
				{confirmAll && !disableCustomerEmailSending && !isSms ? (
					<SendToEmail
						vdCurrEmail={vdEmail}
						showAlternateEmail={confirmShowAlternateEmail}
						onChangeEmail={handleChange}
						hasEmail={hasEmail}
						hasMultipleEmail={hasMultipleEmail}
						emails={emails}
						errors={errors}
						disabled={disabled}
					/>
				) : null}
			</div>
			{hasMultipleOptions ? (
				<div className={columnClass}>
					<h5 style={styles.subTitle}>
						{formatMessage(messages.lblConfirmationDetails)}
					</h5>
					{type === confirmationOptions.creditCard ||
					type === confirmationOptions.debitCard ? (
						<div id="srtConfirmationCardOptions">
							<div style={inlineStyles.confirmationCardOptionsTitle}>
								{type === confirmationOptions.creditCard && (
									<h6 style={styles.subTitleIn}>
										{formatMessage(messages.lblCreditCardOptions)}
									</h6>
								)}
								{type === confirmationOptions.debitCard && (
									<h6 style={styles.subTitleIn}>
										{formatMessage(messages.lblDebitCardOptions)}
									</h6>
								)}
								{copyPaymentCard}
							</div>
							<Payment
								id="srtConfirmation"
								isConfirmation
								onChange={handleChange}
								paymentMethods={optionValues.BookingPaymentConfirmationValues.map(
									(item) => ({
										code: item.BookingPaymentConfirmationValueType,
										name: item.BookingPaymentConfirmationValueDescription,
										type: cardType,
									}),
								)}
								showSortCode={
									optionValues.BookingPaymentConfirmationIsSortRequired
								}
								errors={errors}
								values={confirmationDetails}
								disabled={disabled}
							/>
						</div>
					) : null}
					{type === confirmationOptions.loyaltyCard ? (
						<div id="srtLoyaltyOptions">
							<h6 style={styles.subTitleIn}>
								{formatMessage(messages.lblLoyaltyCardOptions)}
							</h6>
							<TextField
								id="srtConfirmationLoyaltyProgram"
								label={formatMessage(messages.lblLoyaltyCardProgram)}
								fullWidth
								onChange={handleChangeField('loyaltyCardProgram')}
								error={!!errors.loyaltyCardProgram}
								helperText={errors.loyaltyCardProgram}
								value={loyaltyCardProgram}
								disabled={disabled}
								select
								variant="standard"
							>
								{optionValues.BookingPaymentConfirmationValues.map((item) => (
									<MenuItem
										id={`srtLC_${item.BookingPaymentConfirmationValueType}`}
										key={`lc_${item.BookingPaymentConfirmationValueType}`}
										value={item.BookingPaymentConfirmationValueType}
									>
										{item.BookingPaymentConfirmationValueDescription}
									</MenuItem>
								))}
							</TextField>
							<TextField
								id="srtConfirmationLoyaltyIdentifier"
								name="loyaltyCardIdentifier"
								label={formatMessage(messages.lblLoyaltyIdentifier)}
								fullWidth
								onChange={handleChangeField()}
								error={!!errors.loyaltyCardIdentifier}
								helperText={errors.loyaltyCardIdentifier}
								value={loyaltyCardIdentifier}
								disabled={disabled}
								variant="standard"
							/>
							{optionValues.BookingPaymentConfirmationIsExpirationRequired ? (
								<DateSelector
									id="srtLCExpiryDate"
									showFullDate
									dayMapName="expiryDateDay"
									monthMapName="expiryDateMonth"
									yearMapName="expiryDateYear"
									displayYears={loyaltyCardDisplayYears}
									errors={{
										expiryDay: errors.expiryDay,
										expiryMonth: errors.expiryMonth,
										expiryYear: errors.expiryYear,
									}}
									values={{
										confirmExpiryDateDay,
										confirmExpiryDateMonth,
										confirmExpiryDateYear,
									}}
									labels={{
										day: formatMessage(messages.lblConfDay),
										month: formatMessage(messages.lblConfMonth),
										year: formatMessage(messages.lblConfYear),
										fieldLabel: formatMessage(messages.lblConfExpiryDate),
									}}
									onChange={handleChange}
									disabled={disabled}
								/>
							) : null}
						</div>
					) : null}
				</div>
			) : null}
		</div>
	);
};

Confirmation.propTypes = {
	id: PropTypes.string,
	intl: PropTypes.object,
	confirmationInformation: PropTypes.object,
	confirmationDetails: PropTypes.object,
	showAlternateEmail: PropTypes.bool,
	emails: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	errors: PropTypes.object,
	styles: PropTypes.object,
	confirmationOnly: PropTypes.bool,
	columnClass: PropTypes.string,
	disableCustomerEmailSending: PropTypes.bool,
	paymentInfo: PropTypes.object,
	isSms: PropTypes.bool,
};

export { Confirmation as ConfirmationAlias };
export default injectIntl(Confirmation);
