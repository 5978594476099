import { midGray6, white } from '../../../colors';

export default {
	leg: {
		width: '100%',
	},
	legType: {
		backgroundColor: midGray6,
		fontSize: 16,
		color: white,
		lineHeight: 2.14,
		margin: '20px 0',
	},
	legTypeBooking: {
		backgroundColor: midGray6,
		fontSize: 16,
		color: white,
		lineHeight: 2.14,
		marginTop: 15,
	},
	fareRulesButton: {
		color: white,
		fontWeight: 200,
		textTransform: 'capitalize',
		textDecoration: 'underline',
	},
	overtakenContainer: {
		marginTop: 5,
		padding: 3,
	},
	returnFareCard: {
		position: 'absolute',
		right: 7,
		top: 81,
	},
	returnJourney: {
		marginTop: 15,
		padding: 0,
		position: 'relative',
	},
};
