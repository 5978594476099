import { defineMessages } from 'react-intl';

export default defineMessages({
	lblSingle: {
		id: 'app.shopping.search.tripType.single',
		defaultMessage: 'Single',
	},
	lblReturn: {
		id: 'app.shopping.search.tripType.return',
		defaultMessage: 'Return',
	},
	lblSeason: {
		id: 'app.shopping.search.tripType.season',
		defaultMessage: 'Season',
	},
	lblStationSelect: {
		id: 'app.shopping.search.stationSelect',
		defaultMessage: 'Enter station name or code',
	},
	lblDepartStation: {
		id: 'app.shopping.search.departStation',
		defaultMessage: 'Depart from station',
	},
	lblDepartDate: {
		id: 'app.shopping.search.departDate',
		defaultMessage: 'Depart date',
	},
	lblArriveStation: {
		id: 'app.shopping.search.arriveStation',
		defaultMessage: 'Arrive to station',
	},
	lblOpenReturn: {
		id: 'app.shopping.search.openReturn',
		defaultMessage: 'Open Return',
	},
	lblReturnDate: {
		id: 'app.shopping.search.returnDate',
		defaultMessage: 'Return date',
	},
	lblStartDate: {
		id: 'app.shopping.search.startDate',
		defaultMessage: 'Start date',
	},
	lblEndDate: {
		id: 'app.shopping.search.endDate',
		defaultMessage: 'End date (optional)',
	},
	lblStation: {
		id: 'app.shopping.search.station',
		defaultMessage: 'Station',
	},
	lblVia: {
		id: 'app.shopping.search.via',
		defaultMessage: 'via',
	},
	lblAvoid: {
		id: 'app.shopping.search.avoid',
		defaultMessage: 'avoid',
	},
	lblViaAvoidOutbound: {
		id: 'app.shopping.search.viaAvoidOutbound',
		defaultMessage: 'Add Via / Avoid Station for outbound journey',
	},
	lblViaAvoidInbound: {
		id: 'app.shopping.search.viaAvoidInbound',
		defaultMessage: 'Add Via / Avoid Station for inbound journey',
	},
	lblSelectViaAvoidOutbound: {
		id: 'app.shopping.search.selectViaAvoidOutbound',
		defaultMessage: 'Select Via/Avoid for outbound journey',
	},
	lblSelectViaAvoidInbound: {
		id: 'app.shopping.search.selectViaAvoidInbound',
		defaultMessage: 'Select Via/Avoid for inbound journey',
	},
	lblViaAvoidRemove: {
		id: 'app.shopping.search.viaAvoidRemove',
		defaultMessage: 'Remove',
	},
	lblJourneyDetails: {
		id: 'app.shopping.search.journeyDetails',
		defaultMessage: 'Journey Details',
	},
	lblPassengerLabel: {
		id: 'app.shopping.search.passengerLabel',
		defaultMessage: 'Passenger {id}',
	},
	lblPassengerAges: {
		id: 'app.shopping.search.passengerAges',
		defaultMessage: 'Passenger Ages',
	},
	lblPassengerRemove: {
		id: 'app.shopping.search.passengerRemove',
		defaultMessage: 'Remove',
	},
	lblPassengerAdd: {
		id: 'app.shopping.search.passengerAdd',
		defaultMessage: 'Add',
	},
	lblDiscounts: {
		id: 'app.shopping.search.discounts',
		defaultMessage: 'Discount Cards',
	},
	lblPromoCode: {
		id: 'app.shopping.search.promoCode',
		defaultMessage: 'Promo Code',
	},
	lblValidationCode: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.validationcode',
		defaultMessage: 'Validation Code',
	},
	lblDiscountsAdd: {
		id: 'app.shopping.search.discountsAdd',
		defaultMessage: 'Add',
	},
	lblDiscountsRemove: {
		id: 'app.shopping.search.discountsRemove',
		defaultMessage: 'Remove',
	},
	lblDiscountsPassengerPlaceholder: {
		id: 'app.shopping.search.discountsPassengerPlaceholder',
		defaultMessage: 'Select Passenger',
	},
	lblDiscountsSelect: {
		id: 'app.shopping.search.discountsSelect',
		defaultMessage: 'Select discount card',
	},
	lblDiscountsCorporate: {
		id: 'app.shopping.search.discountsCorporate',
		defaultMessage: '** Corporate Id **',
	},
	lblDiscountsTADA: {
		id: 'app.shopping.search.discountsTADA',
		defaultMessage: 'Travel Agency Discount Authority',
	},
	lblSearchAction: {
		id: 'app.shopping.search.title',
		defaultMessage: 'Search for fares',
	},
	lblResetFields: {
		id: 'app.shopping.search.resetFields',
		defaultMessage: 'Reset Fields',
	},
	lblClose: {
		id: 'app.shopping.search.close',
		defaultMessage: 'Close',
	},
	errGetAccount: {
		id: 'app.shopping.search.errGetAccount',
		defaultMessage:
			"^There was a problem getting the user's account list. Please try again!",
	},
	errNoAccount: {
		id: 'app.shopping.search.noAccount',
		defaultMessage: '^Please select an Account',
	},
	errNoStation: {
		id: 'app.shopping.search.noStation',
		defaultMessage: '^Please choose a station',
	},
	errNoAdult: {
		id: 'app.shopping.search.noAdult',
		defaultMessage:
			'There should be at least one passenger with the age of 16 or above',
	},
	errNotValidDate: {
		id: 'app.shopping.search.notValidDate',
		defaultMessage: 'Please select a valid date',
	},
	errReturnDateEarlier: {
		id: 'app.shopping.search.returnDateEarlier',
		defaultMessage: 'Return date cannot be before depart date',
	},
	errStartDateAfterLimitSeason: {
		id: 'app.shopping.search.startDateAfterLimitSeason',
		defaultMessage:
			'Start date can only be within seven days from the current date',
	},
	errEndDateAfterLimitSeason: {
		id: 'app.shopping.search.endDateAfterLimitSeason',
		defaultMessage:
			'End date for season pass can not be more than one year after start date',
	},
	errStationNoMatch: {
		id: 'app.shopping.search.stationMatch',
		defaultMessage: 'Station cannot be used twice in a search',
	},
	errNoRailcardId: {
		id: 'app.shopping.search.noRailcardId',
		defaultMessage: 'Please enter a valid Id',
	},
	lblMaximumConnections: {
		id: 'app.shopping.search.maximumConnections',
		defaultMessage: 'Maximum Connections',
	},
	lblFRMilitaire: {
		id: 'app.shopping.search.frMilitaire',
		defaultMessage: 'Military Discount Card Number',
	},
});
