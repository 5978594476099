import { refreshUserStateApi } from '../apiShopping';

export const REFRESH_USER_STATE = 'REFRESH_USER_STATE';
export const RECEIVE_NEW_USER_STATE = 'RECEIVE_NEW_USER_STATE';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const CHANGE_DEPART_STATION = 'CHANGE_DEPART_STATION';
export const CHANGE_OPEN_RETURN = 'CHANGE_OPEN_RETURN';
export const CHANGE_DEPART_DATE = 'CHANGE_DEPART_DATE';
export const CHANGE_ARRIVE_STATION = 'CHANGE_ARRIVE_STATION';
export const CHANGE_RETURN_DATE = 'CHANGE_RETURN_DATE';
export const CLEAR_END_DATE = 'CLEAR_END_DATE';
export const CHANGE_VIA_AVOID_STATION = 'CHANGE_VIA_AVOID_STATION';
export const CHANGE_VIA_AVOID_TYPE = 'CHANGE_VIA_AVOID_TYPE';
export const REMOVE_VIA_AVOID = 'REMOVE_VIA_AVOID';
export const CHANGE_TRIP_TYPE = 'CHANGE_TRIP_TYPE';
export const ADD_PASSENGER = 'ADD_PASSENGER';
export const CHANGE_PASSENGER = 'CHANGE_PASSENGER';
export const REMOVE_PASSENGER = 'REMOVE_PASSENGER';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const CHANGE_DISCOUNT_PROGRAM = 'CHANGE_DISCOUNT_PROGRAM';
export const CHANGE_DISCOUNT_NUMBER = 'CHANGE_DISCOUNT_NUMBER';
export const FARE_QUALIFIER_CHANGED = 'FARE_QUALIFIER_CHANGED';
export const CHANGE_DISCOUNT_PASSENGER = 'CHANGE_DISCOUNT_PASSENGER';
export const CHANGE_RAILCARD_ID = 'CHANGE_RAILCARD_ID';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const CLEAR_DEPART_STATION = 'CLEAR_DEPART_STATION';
export const CLEAR_ARRIVE_STATION = 'CLEAR_ARRIVE_STATION';
export const CLEAR_PASSENGERS = 'CLEAR_PASSENGERS';
export const CLEAR_VIA_AVOID_STATION = 'CLEAR_VIA_AVOID_STATION';
export const DISMISS_ERROR_RESPONSE = 'DISMISS_ERROR_RESPONSE';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const ADMIN_DATA_CHANGED = 'ADMIN_DATA_CHANGED';
export const CHANGE_DEPART_MAX_CONNECTONS = 'CHANGE_DEPART_MAX_CONNECTONS';
export const CHANGE_RETURN_MAX_CONNECTONS = 'CHANGE_RETURN_MAX_CONNECTONS';
export const SWAP_STATIONS = 'SWAP_STATIONS';
export const CHANGE_PROMO_CODE = 'CHANGE_PROMO_CODE';
export const CHANGE_VALIDATION_CODE = 'CHANGE_VALIDATION_CODE';

export const initiateUserStateChange = () => ({ type: REFRESH_USER_STATE });

export const receiveNewUserState = (payload, error) => ({
	type: RECEIVE_NEW_USER_STATE,
	payload,
	error,
});

export const fetchUserData = () => (dispatch) => {
	dispatch(initiateUserStateChange());
	return refreshUserStateApi(
		(response) => {
			dispatch(receiveNewUserState(response.errorResponse.message, true));
		},
		(response) => {
			const availableAccounts =
				response.successResponse.data.AccountManagementRefreshedAccounts.map(
					(item) => ({
						text: item.Label,
						value: item.Context,
						fareSearchAllowed: item.AccountManagementFareSearchAllowed,
					}),
				);
			const formattedResponse = {
				availableAccounts,
				availableSuppliers:
					response.successResponse.data.UserManagementRefreshedSuppliers,
				locale: response.successResponse.data.SecurityLocale,
			};
			dispatch(receiveNewUserState(formattedResponse, false));
		},
	);
};

export const changeAccount = (accountName, context) => ({
	type: CHANGE_ACCOUNT,
	name: accountName,
	context,
});

export const changeTripType = (tripType) => ({
	type: CHANGE_TRIP_TYPE,
	tripType,
});

export const changeDepartStation = (station) => ({
	type: CHANGE_DEPART_STATION,
	code: station.code,
	supplier: station.supplier,
	txt: station.name,
	countryCode: station.countryCode,
});

export const changeDepartDate = (date, timeOption) => ({
	type: CHANGE_DEPART_DATE,
	date,
	timeOption,
});

export const changeArriveStation = (station) => ({
	type: CHANGE_ARRIVE_STATION,
	code: station.code,
	supplier: station.supplier,
	txt: station.name,
	countryCode: station.countryCode,
});

export const changeOpenReturn = () => ({ type: CHANGE_OPEN_RETURN });

export const changeReturnDate = (date, timeOption) => ({
	type: CHANGE_RETURN_DATE,
	date,
	timeOption,
});

export const swapStations = () => ({
	type: SWAP_STATIONS,
});

export const changeDepartMaxConnections = (maxConnections) => ({
	type: CHANGE_DEPART_MAX_CONNECTONS,
	maxConnections,
});

export const changeReturnMaxConnections = (maxConnections) => ({
	type: CHANGE_RETURN_MAX_CONNECTONS,
	maxConnections,
});

export const clearEndDate = () => ({ type: CLEAR_END_DATE });

export const changeViaAvoidStation = (station, direction) => ({
	type: CHANGE_VIA_AVOID_STATION,
	code: station.code,
	txt: station.name,
	direction,
});

export const changeViaAvoidType = (viaAvoidType, direction) => ({
	type: CHANGE_VIA_AVOID_TYPE,
	viaAvoidType,
	direction,
});

export const removeViaAvoid = (direction) => ({
	type: REMOVE_VIA_AVOID,
	direction,
});

export const addPassenger = () => ({ type: ADD_PASSENGER });

export const changePassenger = (paxAge, index) => ({
	type: CHANGE_PASSENGER,
	paxAge,
	index,
});

export const removePassenger = (index) => ({ type: REMOVE_PASSENGER, index });

export const clearPassengers = () => ({ type: CLEAR_PASSENGERS });

export const addDiscount = () => ({ type: ADD_DISCOUNT });

export const removeDiscount = (index) => ({ type: REMOVE_DISCOUNT, index });

export const changeDiscountProgram = (discountProgram, index) => ({
	type: CHANGE_DISCOUNT_PROGRAM,
	discountProgram,
	index,
});

export const changeDiscountNumber = (discountNumber, index) => ({
	type: CHANGE_DISCOUNT_NUMBER,
	discountNumber,
	index,
});

export const changeFareQualifier = (changed, index) => ({
	type: FARE_QUALIFIER_CHANGED,
	changed,
	index,
});

export const changeDiscountPassenger = (passenger, index) => ({
	type: CHANGE_DISCOUNT_PASSENGER,
	passenger,
	index,
});

export const changeRailcardId = (railcardId, index) => ({
	type: CHANGE_RAILCARD_ID,
	railcardId,
	index,
});

export const clearFields = () => ({ type: CLEAR_FIELDS });

export const clearDepartStation = (text) => ({
	type: CLEAR_DEPART_STATION,
	text,
});

export const clearArriveStation = (text) => ({
	type: CLEAR_ARRIVE_STATION,
	text,
});

export const clearViaAvoidStation = (text, direction) => ({
	type: CLEAR_VIA_AVOID_STATION,
	text,
	direction,
});

export const dismissError = () => ({ type: DISMISS_ERROR_RESPONSE });

export const updateSearchQuery = (query) => ({
	type: UPDATE_SEARCH_QUERY,
	query,
});

export const adminDataChanged = () => ({ type: ADMIN_DATA_CHANGED });

export const changePromoCode = (value) => ({
	type: CHANGE_PROMO_CODE,
	promoCode: value,
});

export const changeValidationCode = (value) => ({
	type: CHANGE_VALIDATION_CODE,
	validationCode: value,
});
