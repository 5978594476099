import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS, is } from 'immutable';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import './reservations.css';
import messages from './messagesReservations';
import ReservationsView from './ReservationsView';
import { getReservable } from '../../../util';
import { filterObject, seatOrBike } from '../../utils';
import inlineStyles from './styles';
import { gaEvent } from '../../../../utils/googleAnalytics';
import { usePrevious } from '../../../../utils/hooks';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});

const ReservationsDialog = ({
	shoppingPassengers,
	addOrderMode,
	exchangeOrderMode,
	seatReservation = true,
	onDiscardSeatReservations,
	onDiscardBikeReservations,
	onChangeSeatReservations,
	onChangeBikeReservations,
	open,
	faresSelected,
	bikeReservations = [],
	intl: { formatMessage },
	selectedSeatReservations = {},
	selectedBikeReservations = {},
}) => {
	const [state, setState] = React.useState({
		selectedSeatReservations: {},
		selectedBikeReservations: {},
		passengerId: shoppingPassengers.getIn([0, 'ShoppingPassengerRefId']),
	});

	const prevSelectedSeatReservations = usePrevious(selectedSeatReservations);
	const prevSelectedBikeReservations = usePrevious(selectedBikeReservations);

	useEffect(() => {
		if (
			!is(
				fromJS(prevSelectedSeatReservations),
				fromJS(selectedSeatReservations),
			)
		) {
			setState((prevState) => ({
				...prevState,
				selectedSeatReservations,
			}));
		}

		if (
			!is(
				fromJS(prevSelectedBikeReservations),
				fromJS(selectedBikeReservations),
			)
		) {
			setState((prevState) => ({
				...prevState,
				selectedBikeReservations,
			}));
		}
	}, [
		selectedSeatReservations,
		prevSelectedSeatReservations,
		selectedBikeReservations,
		prevSelectedBikeReservations,
	]);

	const changeAllReservation = (segmentId, enableAllReservation, price) => {
		let segmentReservation = state.selectedSeatReservations[segmentId];
		const paxLength = shoppingPassengers.size;
		const pricePerPassenger = price / paxLength;

		shoppingPassengers.toJS().forEach((passenger) => {
			const passengerId = passenger.ShoppingPassengerRefId;
			const enablePassengerSeats = {
				enablePassengerSeats: enableAllReservation,
				price: pricePerPassenger,
			};
			const enableAllSeats = { enableAllSeats: enableAllReservation, price };
			if (segmentReservation) {
				if (segmentReservation[passengerId]) {
					segmentReservation[passengerId] = enablePassengerSeats;
				} else {
					segmentReservation = {
						...segmentReservation,
						[passengerId]: enablePassengerSeats,
					};
				}
			} else {
				segmentReservation = { [passengerId]: enablePassengerSeats };
			}
			segmentReservation = { ...segmentReservation, ...enableAllSeats };
		});

		setState((preState) => ({
			...preState,
			selectedSeatReservations: {
				...preState.selectedSeatReservations,
				[segmentId]: segmentReservation,
			},
		}));
	};

	const handleClearAll = () => {
		if (addOrderMode)
			gaEvent(`addOrderClear${seatOrBike(seatReservation)}Reservations`);
		else if (exchangeOrderMode)
			gaEvent(`exchangeOrderClear${seatOrBike(seatReservation)}Reservations`);
		else
			gaEvent(`createBookingClear${seatOrBike(seatReservation)}Reservations`);

		const reservation = seatReservation
			? 'selectedSeatReservations'
			: 'selectedBikeReservations';

		setState({
			...state,
			[reservation]: {},
		});
	};

	const handleDiscard = () => {
		if (addOrderMode)
			gaEvent(`addOrderDiscard${seatOrBike(seatReservation)}Reservations`);
		else if (exchangeOrderMode)
			gaEvent(`exchangeOrderDiscard${seatOrBike(seatReservation)}Reservations`);
		else
			gaEvent(`createBookingDiscard${seatOrBike(seatReservation)}Reservations`);

		if (seatReservation) onDiscardSeatReservations();
		else onDiscardBikeReservations();
	};

	const handleRequestSeatsOrBike = () => {
		if (addOrderMode)
			gaEvent(`addOrderRequest${seatOrBike(seatReservation)}Reservations`);
		else if (exchangeOrderMode)
			gaEvent(`exchangeOrderRequest${seatOrBike(seatReservation)}Reservations`);
		else
			gaEvent(`createBookingRequest${seatOrBike(seatReservation)}Reservations`);

		if (seatReservation && state.selectedSeatReservations) {
			onChangeSeatReservations(state.selectedSeatReservations);
		} else if (state.selectedBikeReservations) {
			onChangeBikeReservations(state.selectedBikeReservations);
		}
	};

	const handlePassengerClick = (passengerId) => {
		setState({
			...state,
			passengerId,
		});
	};

	const handleChangeReservation = (segmentId, passengerId, reservation) => {
		setState((prevState) => {
			let segmentReservation = state.selectedSeatReservations[segmentId];
			if (segmentReservation) {
				if (segmentReservation[passengerId]) {
					segmentReservation[passengerId] = Object.assign(
						segmentReservation[passengerId],
						reservation,
					);
				} else {
					segmentReservation = {
						...segmentReservation,
						[passengerId]: reservation,
					};
				}
			} else {
				segmentReservation = { [passengerId]: reservation };
			}

			return {
				...prevState,
				selectedSeatReservations: {
					...prevState.selectedSeatReservations,
					[segmentId]: segmentReservation,
				},
			};
		});
	};

	const handleChangePassengerReservation = (
		segmentId,
		passengerId,
		enablePassengerReservation,
		price,
	) => {
		setState((prevState) => {
			const enablePassengerSeats = {
				enablePassengerSeats: enablePassengerReservation,
				price,
			};
			let segmentReservation = prevState.selectedSeatReservations[segmentId];
			if (segmentReservation) {
				if (segmentReservation[passengerId]) {
					segmentReservation[passengerId] = enablePassengerSeats;
				} else {
					segmentReservation = {
						...segmentReservation,
						[passengerId]: enablePassengerSeats,
					};
				}
			} else {
				segmentReservation = { [passengerId]: enablePassengerSeats };
			}

			if (!enablePassengerReservation) {
				segmentReservation = Object.assign(segmentReservation, {
					enableAllSeats: enablePassengerReservation,
				});
			}

			return {
				...prevState,
				selectedSeatReservations: {
					...prevState.selectedSeatReservations,
					[segmentId]: segmentReservation,
				},
			};
		});
	};

	const handleChangeBikeReservation = (
		segmentId,
		passengerId,
		enableBikeReservation,
		price,
	) => {
		setState((prevState) => {
			const enablePassengerBikes = {
				enablePassengerBikes: enableBikeReservation,
				price,
			};
			let segmentReservation = prevState.selectedBikeReservations[segmentId];
			if (segmentReservation) {
				if (segmentReservation[passengerId]) {
					segmentReservation[passengerId] = enablePassengerBikes;
				} else {
					segmentReservation = {
						...segmentReservation,
						[passengerId]: enablePassengerBikes,
					};
				}
			} else {
				segmentReservation = { [passengerId]: enablePassengerBikes };
			}
			segmentReservation = filterObject(
				segmentReservation,
				'enablePassengerBikes',
				false,
			);

			return {
				...prevState,
				selectedBikeReservations: {
					...prevState.selectedBikeReservations,
					[segmentId]: segmentReservation,
				},
			};
		});
	};

	const handleChangeAllSegmentReservation = (
		segmentId,
		enableAllReservation,
		price,
	) => {
		changeAllReservation(segmentId, enableAllReservation, price);
	};

	const handleChangeAllLegReservation = (
		travelSegments,
		segmentId,
		seatReservables,
		enableAllReservation,
		price,
	) => {
		travelSegments.forEach((segment) => {
			const priceAll = segment.TravelSegmentID === segmentId ? price : 0.0;
			const reservable = getReservable(
				seatReservables,
				segment.TravelSegmentID,
			);
			if (reservable.reservable !== 'NOT_POSSIBLE') {
				changeAllReservation(
					segment.TravelSegmentID,
					enableAllReservation,
					priceAll,
				);
			}
		});
	};

	const actions = [
		<Button
			variant="text"
			id={`srt${seatOrBike(seatReservation)}ReservationsClearAll`}
			key={`srt${seatOrBike(seatReservation)}ReservationsClearAll`}
			onClick={handleClearAll}
			style={inlineStyles.buttons}
			styleName="clearAll"
			color="secondary"
		>
			{formatMessage(messages.btnClearAll)}
		</Button>,
		<Button
			variant="contained"
			id={`srt${seatOrBike(seatReservation)}ReservationsDiscard`}
			key={`srt${seatOrBike(seatReservation)}ReservationsDiscard`}
			onClick={handleDiscard}
			style={inlineStyles.buttons}
		>
			{formatMessage(messages.btnDiscard)}
		</Button>,
		<Button
			variant="contained"
			id={`srt${seatOrBike(seatReservation)}ReservationsRequest`}
			key={`srt${seatOrBike(seatReservation)}ReservationsRequest`}
			onClick={handleRequestSeatsOrBike}
			color="primary"
			style={inlineStyles.buttons}
		>
			{formatMessage(
				seatReservation ? messages.btnReqestSeats : messages.btnReqestBikes,
			)}
		</Button>,
	];

	return (
		<StyledDialog
			open={open}
			onClose={handleDiscard}
			disableEnforceFocus
			maxWidth={false}
		>
			<DialogTitle>
				{formatMessage(
					seatReservation ? messages.lblTitle : messages.lblBikeTitle,
				)}
			</DialogTitle>
			<StyledDialogContent>
				<ReservationsView
					faresSelected={faresSelected}
					bikeReservations={bikeReservations}
					seatReservation={seatReservation}
					shoppingPassengers={shoppingPassengers}
					selectedSeatReservations={state.selectedSeatReservations}
					selectedBikeReservations={state.selectedBikeReservations}
					onPassengerClick={handlePassengerClick}
					onChangeReservation={handleChangeReservation}
					onChangeAllSegmentReservation={handleChangeAllSegmentReservation}
					onChangeAllLegReservation={handleChangeAllLegReservation}
					onChangePassengerReservation={handleChangePassengerReservation}
					onChangeBikeReservation={handleChangeBikeReservation}
					passengerId={state.passengerId}
					exchangeOrderMode={exchangeOrderMode}
				/>
				{seatReservation && <div>{formatMessage(messages.lblNote)}</div>}
			</StyledDialogContent>
			<DialogActions>{actions}</DialogActions>
		</StyledDialog>
	);
};

ReservationsDialog.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool.isRequired,
	faresSelected: PropTypes.object.isRequired,
	bikeReservations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	seatReservation: PropTypes.bool,
	shoppingPassengers: PropTypes.object,
	onDiscardSeatReservations: PropTypes.func.isRequired,
	onDiscardBikeReservations: PropTypes.func.isRequired,
	onChangeSeatReservations: PropTypes.func.isRequired,
	onChangeBikeReservations: PropTypes.func.isRequired,
	selectedSeatReservations: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	selectedBikeReservations: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	addOrderMode: PropTypes.bool,
	exchangeOrderMode: PropTypes.bool,
};

export { ReservationsDialog as ReservationsDialogAlias };

export default injectIntl(ReservationsDialog);
