import { defineMessages } from 'react-intl';

export default defineMessages({
	btnClose: {
		id: 'app.shopping.fareRulesMatrix.close',
		defaultMessage: 'Close',
	},
	lblFareRulesMatrix: {
		id: 'app.shopping.fareRulesMatrix.fareRulesMatrix',
		defaultMessage: 'Fare Rules',
	},
});
