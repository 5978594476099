import messages from './messagesFareLeg';
import {
	dateStrToDate,
	dateTimeStrToDate,
	dayDiff,
	convertDurationToMessage,
	millisecondsToDuration,
} from '../../../utils/datetimeUtils';

const getFares = (legFares, filterFn, hasSelected, isBooking) => {
	const filteredFares = hasSelected ? legFares : legFares.filter(filterFn);
	const fares = filteredFares.map((currFare) => {
		const priceId = currFare.ShoppingLegFarePriceId;
		const currency = currFare.ShoppingLegFareTotalPriceCurrency;
		const price = Number(currFare.ShoppingLegFareTotalPrice);
		const seatsAvailable = currFare.ShoppingLegFareSeatsAvailable;
		const { isOverbooked } = currFare;
		const discountApplied = currFare.ShoppingLegFareDiscountApplied;
		const discountDescription =
			currFare.ShoppingLegFareDiscountsInfo[0]
				?.ShoppingLegFareDiscountDescription;
		const campaignInfo = currFare.ShoppingLegCampaignInfo;
		const linkedReturnPrices = currFare.ShoppingLegFareLinkedReturnPrices.map(
			(item) => item.ShoppingLegFareLinkedReturnPriceId,
		);
		const fareCodes = currFare.ShoppingLegFareCodeInfo.map((item) => ({
			farePrice: Number(item.ShoppingLegFarePrice),
			fareClass: item.ShoppingLegFareFareClass,
			fareClassDesc: item.ShoppingLegFareFareClassDesc,
			cabinClass: item.ShoppingLegFareCabinClass,
			serviceClass: item.ShoppingLegFareServiceClass,
			primaryColumnClass: item.ShoppingLegFarePrimaryColumnClass,
			openReturn: item.ShoppingLegFareOpenReturn === 'YES',
			reservableCode: item.ShoppingLegFareReservableCode,
			reservableDesc: item.ShoppingLegFareReservableDesc,
			applicabilityCode: item.ShoppingLegFareApplicabilityCode,
			applicabilityDesc: item.ShoppingLegFareApplicabilityDesc.replace(
				/(.*)Schedule/i,
				'$1',
			).trim(),
			expirationDate: item.expirationDate,
			scheduleClass: item.scheduleClass,
			fareTypeClass: item.fareTypeClass,
		}));
		const amenities = currFare.ShoppingLegFareServicesAndAmenities.map(
			(item) => ({
				code: item.ShoppingLegFareServiceAndAmenityCode,
				desc: item.ShoppingLegFareServiceAndAmenityDesc,
			}),
		);
		const rules = currFare.ShoppingLegFareRules.map((item) => ({
			category: item.FareRuleCategory,
			descriptions:
				item.FareRulesDescription &&
				item.FareRulesDescription.map((description) => description.Description),
		}));
		const fareRulesDetails = currFare.ShoppingLegFareRulesDetails?.map(
			(item) => ({
				paxRefs: item.ShoppingFareRulesPaxRefs,
				fareClasses: item.ShoppingFareRulesFareClasses,
				displayName: item.ShoppingLegFareDisplayName,
				fareCodes: item.ShoppingLegFareCodes,
				fareOrigins: item.ShoppingLegFareOrigin,
				fareDestinations: item.ShoppingLegFareDestination,
				originTerminals: item.ShoppingLegFareOriginTerminals,
				destinationTerminals: item.ShoppingLegFareDestinationTerminals,
				fareMatrix: item.ShoppingFareRulesFareMatrix.map((rule) => ({
					isTextRule: rule.ShoppingFareRuleRuleIsTextRule,
					category: rule.FareRuleCategory,
					textRuleDesc:
						rule.FareRulesDescription &&
						rule.FareRulesDescription.map(
							(description) => description.Description,
						),
					priceType: rule.ShoppingFareRulePriceType || '',
					applicableTicketingOption:
						rule.ShoppingFareRuleApplicableTicketingOption || '',
					expirationDateTime: rule.ShoppingFareRuleExpirationDateTime || '',
					refundAllowedConfirmed:
						rule.ShoppingFareRuleRefundAllowedConfirmed || '',
					refundAllowedConfirmedPenalty:
						rule.ShoppingFareRuleRefundAllowedConfirmedPenalty || '',
					refundAllowedTicketed:
						rule.ShoppingFareRuleRefundAllowedTicketed || '',
					refundAllowedTicketedPenalty:
						rule.ShoppingFareRuleRefundAllowedTicketedPenalty || '',
					exchangeAllowedConfirmed:
						rule.ShoppingFareRuleExchangeAllowedConfirmed || '',
					exchangeAllowedConfirmedPenalty:
						rule.ShoppingFareRuleExchangeAllowedConfirmedPenalty || '',
					exchangeAllowedTicketed:
						rule.ShoppingFareRuleExchangeAllowedTicketed || '',
					exchangeAllowedTicketedPenalty:
						rule.ShoppingFareRuleExchangeAllowedTicketedPenalty || '',
					refundableIndicator:
						rule.ShoppingFareRuleRefundableIndicator || false,
				})),
				isBooking,
			}),
		);
		const seatPrefObj = currFare.ShoppingLegFareSeatPreferenceData;
		const seatPreferences = seatPrefObj
			? {
					reservable: seatPrefObj.ShoppingLegFareSeatPreferenceDataReservable,
					reservables:
						seatPrefObj.ShoppingLegFareSeatPreferenceDataReservables.map(
							(reservable) => ({
								linkedPasseger: reservable.LinkedPasseger,
								linkedTravelSegment: reservable.LinkedTravelSegment,
								reservable: reservable.Reservable,
							}),
						),
					preferences: seatPrefObj.ShoppingLegFareSeatPreferences.map(
						(preference) => ({
							description: preference.ShoppingLegFareSeatPreferenceDataDesc,
							name: preference.ShoppingLegFareSeatPreferenceDataName,
							type: preference.ShoppingLegFareSeatPreferenceDataType,
							marketingCarrier: preference.ShoppingLegFareMarketingCarrier,
							serviceClass: preference.ShoppingLegFareServiceClass,
							equipmentType: preference.ShoppingLegFareSupplierEquipmentType,
							travelSegmentIDRef: preference.ShoppingLegFareTravelSegmentIDRef,
						}),
					),
				}
			: {};

		const optionalPrices = currFare.OptionalPrices
			? currFare.OptionalPrices.map((item) => ({
					id: item.ID,
					currency: item.currency,
					linkedPassengers: item.linkedPassengers.map((passenger) => ({
						passenger,
					})),
					linkedTravelSegments: item.linkedTravelSegments.map(
						(travelSegment) => ({
							travelSegment,
						}),
					),
					maxQuantity: item.maxQuantity,
					minQuantity: item.minQuantity,
					travelCardConsumptionRules: item.travelCardConsumptionRules.map(
						(rule) => ({
							rule,
						}),
					),
					type: item.type,
					value: item.value,
				}))
			: {};

		return {
			priceId,
			currency,
			price,
			seatsAvailable,
			isOverbooked,
			fareCodes,
			discountApplied,
			linkedReturnPrices,
			amenities,
			rules,
			fareRulesDetails,
			seatPreferences,
			optionalPrices,
			campaignInfo,
			discountDescription,
		};
	});

	return fares;
};

const getItineraryDetails = (
	travelSegments,
	formatMessage,
	shoppingIntermediateTravelPoints,
) => {
	let lastArriveEpoch = 0;
	let arriveTimeOffsetNum = 0;
	let departTimeOffsetNum = 0;

	const itineraryDetails = travelSegments.map((currSegment, index) => {
		const travelSegmentID = currSegment.TravelSegmentID;
		let departStation = '';
		let arriveStation = '';
		const departDate = currSegment.ShoppingFareDepartDateSegment;
		let departTime = departDate.split(' ')[1];
		const arriveDate = currSegment.ShoppingFareArriveDateSegment;
		let arriveTime = arriveDate.split(' ')[1];
		const carrierLogo = currSegment.ShoppingFareServiceCode
			? `${currSegment.ShoppingFareServiceCode}.jpg`
			: 'walking.png';
		const departEpoch = currSegment.ShoppingFareDepartTimetSegment;
		// dayDiff function only accepts date objects
		const arriveDateInst = new Date(departDate);
		const departDateInst = new Date(arriveDate);
		const equipmentType = currSegment.ShoppingFareEquipmentType;
		const equipmentTypeCode = currSegment.ShoppingFareEquipmentTypeCode;
		const { carbonOffset } = currSegment;

		// date hours needs to be set to 0 or the calculation will be wrong
		arriveDateInst.setHours(0, 0, 0, 0);
		departDateInst.setHours(0, 0, 0, 0);

		let designatorText = '';
		if (
			currSegment.TravelSegmentDesignator !== null &&
			currSegment.TravelSegmentDesignator.length > 0
		) {
			const designatorCode = currSegment.TravelSegmentDesignator;
			const equipment =
				equipmentType === 'Pedestrian' || equipmentTypeCode === 'PED'
					? formatMessage(messages.lblPedestrian)
					: equipmentType;
			designatorText =
				designatorCode !== ''
					? `${formatMessage(messages.lblTrain)} ${designatorCode} ${equipmentType}`
					: equipment;
		}

		let marketingServiceName = '';
		if (currSegment.ShoppingFareMarketingServiceName !== null) {
			marketingServiceName = `${currSegment.ShoppingFareMarketingServiceName} `;
		}

		const equipmentDesignator = `${marketingServiceName}${designatorText}`;

		let connectionTimeText = '';
		if (index > 0) {
			const connectionTime = convertDurationToMessage(
				departEpoch - lastArriveEpoch,
				formatMessage,
			);
			connectionTimeText = `- ${connectionTime} ${formatMessage(messages.lblConnectionTime)} -`;
		}

		departStation = currSegment.ShoppingFareDepartStationSegment;
		departTimeOffsetNum = Math.abs(dayDiff(departDateInst, arriveDateInst)) - 1;
		if (departTimeOffsetNum > 0) {
			departTimeOffsetNum += arriveTimeOffsetNum;
		} else if (arriveTimeOffsetNum > 0) {
			departTimeOffsetNum = arriveTimeOffsetNum;
		}
		if (departTimeOffsetNum > 0) {
			departTime = `${departTime} (+${departTimeOffsetNum})`;
		}

		arriveStation = currSegment.ShoppingFareArriveStationSegment;
		arriveTimeOffsetNum = dayDiff(arriveDateInst, departDateInst);
		if (arriveTimeOffsetNum > 0) {
			arriveTime = `${arriveTime} (+${arriveTimeOffsetNum})`;
		}

		lastArriveEpoch = currSegment.ShoppingFareArriveTimetSegment;

		const travelPoints = shoppingIntermediateTravelPoints?.filter(
			(shoppingIntermediateTravelPoint) =>
				travelSegmentID === shoppingIntermediateTravelPoint.TravelSegmentId,
		);

		return {
			travelSegmentID,
			departStation,
			arriveStation,
			departTime,
			arriveTime,
			equipmentDesignator,
			carrierLogo,
			connectionTimeText,
			unconfirmedSchedule: !currSegment.isScheduleConfirmed,
			duration: millisecondsToDuration(
				currSegment.ShoppingFareArriveTimetSegment -
					currSegment.ShoppingFareDepartTimetSegment,
			),
			carbonOffset,
			intermediateTravelPoints: travelPoints
				? travelPoints[0]?.IntermediateTravelPoints
				: [],
			warnings: currSegment.TravelSegmentWarnings,
			passengerRights: currSegment.TravelSegmentPassengerRights,
		};
	});

	return itineraryDetails;
};

const getDaysOffset = (
	legDepartDate,
	legSolutionDateTime,
	dateFormat,
	timeFormat,
	formatMessage,
) => {
	const legSolutionDate = dateTimeStrToDate(
		legSolutionDateTime,
		dateFormat,
		timeFormat,
	);
	if (!legSolutionDate) {
		return 0;
	}
	legSolutionDate.setHours(0, 0, 0, 0);
	const daysOffsetNum = dayDiff(legDepartDate, legSolutionDate);

	let daysOffset = '';
	if (daysOffsetNum !== 0) {
		const daysOffsetLabel =
			daysOffsetNum > 1
				? formatMessage(messages.lblDays)
				: formatMessage(messages.lblDay);
		daysOffset = ` (${daysOffsetNum > 0 ? '+' : ''}${daysOffsetNum} ${daysOffsetLabel})`;
	}
	return daysOffset;
};

const getLegItinerary = (
	itinerary,
	itineraryNumber,
	isBooking,
	legDepartDateString,
	dateFormat,
	timeFormat,
	formatMessage,
	filterFn,
	hasSelected,
) => {
	const legDepartDate = dateStrToDate(legDepartDateString, dateFormat);
	legDepartDate.setHours(0, 0, 0, 0);

	// calculate depart and arrive date offsets from "legDepartDate"
	const departDaysOffset = getDaysOffset(
		legDepartDate,
		itinerary.ShoppingLegSolutionDepartDateTime,
		dateFormat,
		timeFormat,
		formatMessage,
	);
	const arriveDaysOffset = getDaysOffset(
		legDepartDate,
		itinerary.ShoppingLegSolutionArriveDateTime,
		dateFormat,
		timeFormat,
		formatMessage,
	);

	// Carrier info
	let carrierLogo = null;
	if (itinerary.ShoppingLegSolutionMarketingCarriers.length > 1) {
		carrierLogo = ''; // Multiple carriers
	} else if (itinerary.ShoppingLegSolutionMarketingCarrierLogoPath.length > 0) {
		carrierLogo = itinerary.ShoppingLegSolutionMarketingCarrierLogoPath;
	}

	// Overtaken train
	const overtaken = itinerary.ShoppingLegSolutionOvertakenJourney;
	// unconfirmed schedule
	const unconfirmedSchedule = itinerary.ShoppingFareTravelSegments.some(
		(travelSegment) => !travelSegment.isScheduleConfirmed,
	);

	// Itinerary number (shopping page) or depart date (booking page)
	let itineraryNumberText = `#${itineraryNumber.toString()}`;
	if (isBooking) {
		const departDateTimeLocal =
			itinerary.ShoppingLegSolutionCartFormatDepartDateTime || '';
		itineraryNumberText = departDateTimeLocal.substr(
			0,
			departDateTimeLocal.indexOf(' @'),
		);
	}

	const getUniqueWarnings = (warningsArray) => {
		if (!warningsArray || warningsArray.length === 0) {
			return [];
		}

		const uniqueMessagesSet = new Set(
			warningsArray.map((warning) => warning.warningMessage),
		);

		const uniqueWarnings = Array.from(uniqueMessagesSet).map((message) => ({
			warningCategory: 'FIRST_CLASS_SEATING_NOT_AVAILABLE',
			warningMessage: message,
		}));

		return uniqueWarnings;
	};

	return {
		legSolutionId: itinerary.ShoppingLegSolutionId,
		shoppingContext: itinerary.ShoppingContext,
		itineraryNumber: itineraryNumberText,
		departStation: itinerary.ShoppingLegSolutionDepartStationName,
		arriveStation: itinerary.ShoppingLegSolutionArriveStationName,
		departTime: `${itinerary.ShoppingLegSolutionDepartTime}${departDaysOffset}`,
		arriveTime: `${itinerary.ShoppingLegSolutionArriveTime}${arriveDaysOffset}`,
		elapsedTime: itinerary.ShoppingLegSolutionDuration,
		nrChanges: itinerary.ShoppingLegSolutionNumChanges,
		carrierLogo,
		overtaken,
		unconfirmedSchedule,
		details: getItineraryDetails(
			itinerary.ShoppingFareTravelSegments,
			formatMessage,
			itinerary.ShoppingIntermediateTravelPoints,
		),
		legSolutionWarnings: getUniqueWarnings(
			itinerary.ShoppingLegSolutionWarnings,
		),
		fares: getFares(
			itinerary.ShoppingLegFares,
			filterFn,
			hasSelected,
			isBooking,
		),
		serviceAlerts: itinerary.ServiceAlert,
		revisions: itinerary.Revisions,
	};
};

export default getLegItinerary;
