import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import keycode from 'keycode';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import BookingSearchNeedHelp from './BookingSearchNeedHelp';
import orderStatusMessage from '../OrderTitle/messages';
import DatetimePicker from '../../../../components/DateTimePicker/DateTimePicker';

import TableView from '../../../../components/TableView/TableView';
import { gaSetAccount, gaEvent } from '../../../../utils/googleAnalytics';
import { getContext } from '../../utils';

import './styleBookingSearch.css';
import messages from './messagesBookingSearch';
import inlineStyles from './styles';

class BookingSearch extends PureComponent {
	static propTypes = {
		intl: PropTypes.object,
		isProcessingAction: PropTypes.bool.isRequired,
		searchResults: PropTypes.array,
		hasResults: PropTypes.bool,
		onSubmitSearch: PropTypes.func.isRequired,
		onChangeField: PropTypes.func.isRequired,
		onOpenBooking: PropTypes.func.isRequired,
		onOpenEmailConfirmDialog: PropTypes.func.isRequired,
		searchQuery: PropTypes.object,
		errors: PropTypes.object,
	};

	static defaultProps = {
		searchResults: [],
		hasResults: false,
		searchQuery: {},
	};

	getResultsValue = (value) => (value === null ? '' : value.trim());

	getOrderStatusValue = (value) => {
		const {
			intl: { formatMessage },
		} = this.props;
		if (value === null) {
			return '';
		}
		return formatMessage(orderStatusMessage[value]);
	};

	tableHeaders = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		return [
			formatMessage(messages.lblBookingLocator),
			formatMessage(messages.lblTicketLocator),
			formatMessage(messages.lblUniqueTicketNumber),
			formatMessage(messages.lblReferenceNumber),
			formatMessage(messages.lblCustomerNumber),
			formatMessage(messages.lblName),
			formatMessage(messages.lblDepartDate),
			formatMessage(messages.lblFrom),
			formatMessage(messages.lblEmail),
			formatMessage(messages.lblPhone),
			formatMessage(messages.lblOrderStatus),
			formatMessage(messages.lblReceipt),
		];
	};

	handleChangeField = (event) =>
		this.props.onChangeField(event.target.name, event.target.value);

	handleChangeDepartureDate = (date) => {
		this.props.onChangeField('departureDate', date.getTime());
	};

	handleClickBookingRecordLoc = (item) => {
		const {
			intl: { formatMessage },
		} = this.props;
		gaSetAccount(getContext(item.queryItems));
		if (item.value === formatMessage(messages.lblEmail) && item.queryItems) {
			gaEvent('openConfirmationEmailFromSearch');
			this.props.onOpenEmailConfirmDialog(item.queryItems);
		} else if (item.queryItems) {
			this.props.onOpenBooking(item.queryItems);
		}
	};

	handleKeyDownField = (event) => {
		if (keycode(event) === 'enter') {
			this.props.onSubmitSearch();
		}
	};

	transformResultsToTableViewFormat = (results) => {
		const {
			intl: { formatMessage },
		} = this.props;
		return results.map((item) => [
			{
				// 'Booking Locator'
				id: `${item.BookingRecordLoc}`,
				value: item.BookingRecordLoc.trim(),
				link: '',
				clickOnly: true,
				queryItems: {
					srtAgencyCode: item.BookingAgencyCode,
					srtChannelCode: item.BookingChannelCode,
					srtCompanyCode: item.BookingCompanyCode,
					srtDistCode: item.BookingDistributorCode,
					srtPOSCode: item.BookingPOSCode,
					srtRecLocator: item.BookingRecordLoc,
				},
			},
			{
				// 'Ticket Locator',
				id: `${item.BookingRecordLoc}_TL`,
				value: this.getResultsValue(item.BookingValueDocLocator),
				link: '',
			},
			{
				// 'Reference #',
				id: `${item.BookingRecordLoc}_RN`,
				value: this.getResultsValue(item.BookingReferenceNumber),
				link: '',
			},
			{
				// 'Customer #',
				id: `${item.BookingRecordLoc}_CN`,
				value: this.getResultsValue(item.BookingCustomerNumber),
				link: '',
			},
			{
				// 'Name',
				id: `${item.BookingRecordLoc}_Name`,
				value: `${item.BookingPaxFirstName} ${item.BookingPaxLastName}`,
				link: '',
			},
			{
				// 'Depart Date',
				id: `${item.BookingRecordLoc}_DepartDate`,
				value: item.BookingDepartureDate,
				link: '',
			},
			{
				// 'From',
				id: `${item.BookingRecordLoc}_From`,
				value: this.getResultsValue(
					item.BookingOrderDetailsLegTravelSegmentOrigStationName,
				),
				link: '', // TODO replace with station information link
			},
			{
				// 'Email',
				id: `${item.BookingRecordLoc}_Email`,
				value: this.getResultsValue(item.BookingPrimaryPaxEmail),
				link: '',
			},
			{
				// 'Phone',
				id: `${item.BookingRecordLoc}_Phone`,
				value: this.getResultsValue(item.BookingPrimaryPaxPhone),
				link: '',
			},
			{
				// 'Order status',
				id: `${item.BookingRecordLoc}_OrderStatus`,
				value: this.getOrderStatusValue(item.BookingOrderStatus),
				link: '',
			},
			{
				// 'Receipt',
				id: `${item.BookingRecordLoc}_Receipt`,
				value: formatMessage(messages.lblEmail),
				clickOnly:
					this.getResultsValue(item.BookingOrderStatus) === 'CONFIRMED',
				link: '',
				queryItems: {
					srtAgencyCode: item.BookingAgencyCode,
					srtChannelCode: item.BookingChannelCode,
					srtCompanyCode: item.BookingCompanyCode,
					srtDistCode: item.BookingDistributorCode,
					srtPOSCode: item.BookingPOSCode,
					srtRecLocator: item.BookingRecordLoc,
				},
			},
		]);
	};

	render() {
		const {
			intl: { formatMessage },
			searchQuery,
			errors,
			hasResults,
			isProcessingAction: disabled,
			searchResults,
		} = this.props;

		return (
			<div className="container" styleName="containerNoPadding rowClipFix">
				{hasResults && searchResults.length > 1 ? (
					<Paper
						styleName="srtBookingsSearchContainer"
						style={inlineStyles.contentContainer}
						data-testid="results-view"
					>
						<h2 styleName="headingNoBottomMargin">
							{formatMessage(messages.lblResults)}
						</h2>
						<hr />
						<TableView
							id="srtBookingsSearchTable"
							tableHeaders={this.tableHeaders()}
							data={this.transformResultsToTableViewFormat(searchResults)}
							fixedHeader
							isSortable
							wrapperHeight="480px"
							responsive
							clickHandler={this.handleClickBookingRecordLoc}
							style={inlineStyles.table}
						/>
					</Paper>
				) : (
					<div className="row">
						<div className="col-12 col-md-6" data-testid="passengerDetails">
							<Paper style={inlineStyles.contentContainer}>
								<h3 styleName="headingNoBottomMargin">
									{formatMessage(messages.lblPassengerDetails)}
								</h3>
								<TextField
									id="srtPaxFirstLastName"
									data-testid="srtPaxFirstLastName"
									name="firstLastName"
									label={formatMessage(messages.lblFirstLastName)}
									fullWidth
									value={searchQuery.firstLastName}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
								<TextField
									id="srtPaxEmail"
									data-testid="srtPaxEmail"
									name="email"
									label={formatMessage(messages.lblEmail)}
									fullWidth
									value={searchQuery.email}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									error={!!errors.email}
									helperText={errors.email}
									variant="standard"
								/>
								<TextField
									id="srtPaxPhone"
									data-testid="srtPaxPhone"
									name="phone"
									label={formatMessage(messages.lblPhone)}
									fullWidth
									value={searchQuery.phone}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									error={!!errors.phone}
									helperText={errors.phone}
									variant="standard"
								/>
								<TextField
									id="srtPaxSmartCardNumber"
									data-testid="srtPaxSmartCardNumber"
									name="smartCardNumber"
									label={formatMessage(messages.lblSmartCardNumber)}
									fullWidth
									value={searchQuery.smartCardNumber}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									error={!!errors.smartCardNumber}
									helperText={errors.smartCardNumber}
									variant="standard"
								/>
								<DatetimePicker
									id="srtDepartureDate"
									name="departureDate"
									label={formatMessage(messages.lblDepartureDate)}
									value={
										searchQuery.departureDate &&
										new Date(searchQuery.departureDate)
									}
									onChange={this.handleChangeDepartureDate}
									onKeyDown={this.handleKeyDownField}
									disabled={disabled}
									errorText={
										errors.departureDate && errors.departureDate[0].message
									}
									variant="standard"
								/>
							</Paper>
						</div>
						<div className="col-12 col-md-6" data-testid="bookingDetails">
							<Paper style={inlineStyles.contentContainer}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell style={inlineStyles.cellNoBorderStyle}>
												<h3 styleName="headingNoBottomMargin">
													{formatMessage(messages.lblBookingDetails)}
												</h3>
											</TableCell>
											<TableCell
												style={inlineStyles.cellNoBorderStyle}
												align="right"
											>
												<BookingSearchNeedHelp />
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								<TextField
									id="srtBookingLocator"
									data-testid="srtBookingLocator"
									name="bookingLocator"
									label={formatMessage(messages.lblBookingLocator)}
									fullWidth
									value={searchQuery.bookingLocator}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									error={!!errors.bookingLocator}
									helperText={errors.bookingLocator}
									variant="standard"
								/>
								<TextField
									id="srtSupplierLocator"
									data-testid="srtSupplierLocator"
									name="supplierLocator"
									label={formatMessage(messages.lblSupplierLocator)}
									fullWidth
									value={searchQuery.supplierLocator}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
								<TextField
									id="srtCarrierLocator"
									data-testid="srtCarrierLocator"
									name="carrierLocator"
									label={formatMessage(messages.lblCarrierLocator)}
									fullWidth
									value={searchQuery.carrierLocator}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
								<TextField
									id="srtTicketLocator"
									data-testid="srtTicketLocator"
									name="ticketLocator"
									label={formatMessage(messages.lblTicketLocator)}
									fullWidth
									value={searchQuery.ticketLocator}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
								<TextField
									id="srtUniqueTicketNumber"
									data-testid="srtUniqueTicketNumber"
									name="uniqueTicketNumber"
									label={formatMessage(messages.lblUniqueTicketNumber)}
									fullWidth
									value={searchQuery.uniqueTicketNumber}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
							</Paper>
							<Paper style={inlineStyles.contentContainer}>
								<h3 styleName="headingNoBottomMargin">
									{formatMessage(messages.lblCustomInformation)}
								</h3>
								<TextField
									id="srtReferenceNumber"
									data-testid="srtReferenceNumber"
									name="referenceNumber"
									label={formatMessage(messages.lblReferenceNumber)}
									fullWidth
									value={searchQuery.referenceNumber}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
								<TextField
									id="srtCustomerNumber"
									data-testid="srtCustomerNumber"
									name="customerNumber"
									label={formatMessage(messages.lblCustomerNumber)}
									fullWidth
									value={searchQuery.customerNumber}
									onChange={this.handleChangeField}
									InputProps={{ onKeyDown: this.handleKeyDownField }}
									disabled={disabled}
									variant="standard"
								/>
							</Paper>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export { BookingSearch as BookingSearchAlias };

export default injectIntl(BookingSearch);
