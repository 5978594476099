import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import DatetimePicker from '../../../components/DateTimePicker/DateTimePicker';
import StationAutoComplete from '../../components/StationAutoComplete/StationAutoComplete';
import messages from '../messagesShoppingSearch';
import '../shoppingSearch.css';
import inlineStyles from './styles';

const weekInMillis = 604800000;
const dayInMillis = 86400000;

const SeasonView = ({
	journey,
	errors,
	disabled,
	quickSearch,
	onSwapStations,
	intl: { formatMessage },
	exchangeOrderMode,
	onQuickSearchSwitch,
	onChangeDepartDate,
	onChangeReturnDate,
	onClearDepartStation,
	onChangeDepartStation,
	onChangeArriveStation,
	onClearArriveStation,
	onClearEndDate,
	seasonFareDetails,
}) => {
	const quickSearchClickAction = quickSearch ? onQuickSearchSwitch : undefined;
	const defaultStartDate = new Date(Date.now());
	defaultStartDate.setHours(0, 0, 0, 0);
	const currentStartDate = new Date(journey.departDate);
	const defaultEndDate = new Date(
		currentStartDate.getTime() +
			(exchangeOrderMode ? dayInMillis : weekInMillis * 2),
	);

	const seasonEndDate = seasonFareDetails?.endDate?.split('/');
	const exchangeEndDate = seasonEndDate
		? new Date(seasonEndDate[2], seasonEndDate[1] - 1, seasonEndDate[0])
		: null;

	const handleStartDateChange = (date) => {
		onChangeDepartDate(date.getTime(), 0);
	};

	const handleEndDateChange = (date) => {
		onChangeReturnDate(date.getTime(), 0);
	};

	useEffect(() => {
		if (exchangeOrderMode) {
			handleEndDateChange(exchangeEndDate);
		}
	}, []);

	return (
		<div className="row">
			<div className="col-12 col-sm-5" onClick={quickSearchClickAction}>
				<StationAutoComplete
					id="srtDepartStationAutoComplete"
					hintText={formatMessage(messages.lblStationSelect)}
					floatingLabelText={formatMessage(messages.lblDepartStation)}
					station={journey.departStation}
					otherStationCode={journey.arriveStation.code}
					onNewRequest={onChangeDepartStation}
					errorText={errors.departCode}
					disabled={disabled}
					clearStation={onClearDepartStation}
					styleName={quickSearch && 'quickSearch'}
					includeTravelCards
				/>
			</div>
			<div
				className="col-12 col-sm-2"
				onClick={quickSearchClickAction}
				styleName="single-verticalAlign-center"
			>
				<IconButton onClick={onSwapStations} disabled={disabled}>
					<SwapHoriz />
				</IconButton>
			</div>
			<div className="col-12 col-sm-5" onClick={quickSearchClickAction}>
				<StationAutoComplete
					id="srtArriveStationAutoComplete"
					hintText={formatMessage(messages.lblStationSelect)}
					floatingLabelText={formatMessage(messages.lblArriveStation)}
					station={journey.arriveStation}
					otherStationCode={journey.departStation.code}
					onNewRequest={onChangeArriveStation}
					errorText={errors.arriveCode}
					disabled={disabled}
					clearStation={onClearArriveStation}
					styleName={quickSearch && 'quickSearch'}
					includeTravelCards
				/>
			</div>
			<div className="col-12 col-sm-6" onClick={quickSearchClickAction}>
				<DatetimePicker
					id="srtStartDate"
					label={formatMessage(messages.lblStartDate)}
					defaultDate={defaultStartDate}
					minDate={defaultStartDate}
					value={currentStartDate}
					InputLabelProps={{ shrink: true }}
					onChange={handleStartDateChange}
					errorText={errors.departDate}
					disabled={disabled}
					maxDate={defaultEndDate}
				/>
			</div>
			{!(journey.returnDate !== null || quickSearch === false) || (
				<div
					className="col-12 col-sm-6"
					onClick={quickSearchClickAction}
					style={inlineStyles.enddDateContainer}
				>
					<DatetimePicker
						id="srtEndDate"
						label={formatMessage(messages.lblEndDate)}
						defaultDate={exchangeOrderMode ? exchangeEndDate : defaultEndDate}
						minDate={defaultEndDate}
						value={
							exchangeOrderMode
								? exchangeEndDate
								: journey.returnDate && new Date(journey.returnDate)
						}
						onChange={handleEndDateChange}
						errorText={errors.returnDate}
						disabled={disabled || exchangeOrderMode}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					{journey.returnDate && (
						<IconButton
							onClick={onClearEndDate}
							style={inlineStyles.clearButton}
						>
							<Clear />
						</IconButton>
					)}
				</div>
			)}
		</div>
	);
};

SeasonView.propTypes = {
	disabled: PropTypes.bool,
	errors: PropTypes.object,
	intl: PropTypes.object,
	journey: PropTypes.object,
	onChangeArriveStation: PropTypes.func.isRequired,
	onChangeDepartDate: PropTypes.func.isRequired,
	onChangeDepartStation: PropTypes.func.isRequired,
	onChangeReturnDate: PropTypes.func.isRequired,
	onClearArriveStation: PropTypes.func,
	onClearDepartStation: PropTypes.func,
	onClearEndDate: PropTypes.func.isRequired,
	onQuickSearchSwitch: PropTypes.func,
	onSwapStations: PropTypes.func,
	quickSearch: PropTypes.bool,
	exchangeOrderMode: PropTypes.bool,
	seasonFareDetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
	seasonFareDetails: state
		.getIn(['booking', 'orderDetails', 'BookingSeasonFareDetails'])
		?.toJS(),
});

export { SeasonView as SeasonViewAlias };

export default connect(mapStateToProps, null)(injectIntl(SeasonView));
