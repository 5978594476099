import { postalCodeRegex } from '../../utils/regex';
import messages from './messagesTicketDelivery';

export const constraints = (formatMessage) => ({
	tdo: {
		presence: {
			message: formatMessage(messages.errTdoEmpty),
			allowEmpty: false,
		},
	},
});

export const smsConstraints = (formatMessage) => ({
	tdoSmsCountry: {
		presence: {
			message: formatMessage(messages.errSmsCountryEmpty),
			allowEmpty: false,
		},
	},
	tdoSmsNumber: {
		presence: {
			message: formatMessage(messages.errSmsNumberEmpty),
			allowEmpty: false,
		},
	},
});

export const mailConstraints = (formatMessage) => ({
	tdoMailName: {
		presence: {
			message: formatMessage(messages.errNameEmpty),
			allowEmpty: false,
		},
	},
});

export const addressConstraints = (formatMessage) => ({
	countryCode: {
		presence: {
			message: formatMessage(messages.errCountryEmpty),
			allowEmpty: false,
		},
	},
	address1: {
		presence: {
			message: formatMessage(messages.errAddressLine1Empty),
			allowEmpty: false,
		},
	},
	city: {
		presence: {
			message: formatMessage(messages.errCityEmpty),
			allowEmpty: false,
		},
	},
	stateOrProvince: (state, attr) => {
		const code = attr.countryCode;
		const isCanada = code === 'CA';
		if (isCanada || code === 'US') {
			const message = isCanada
				? formatMessage(messages.errProvinceEmpty)
				: formatMessage(messages.errStateEmpty);
			return {
				presence: { message, allowEmpty: false },
			};
		}
		return null;
	},
	postalCode: (postalCode, attr) => {
		const code = attr.countryCode;
		const req = {
			presence: {
				message: formatMessage(messages.errPostalCodeEmpty),
				allowEmpty: false,
			},
		};
		switch (code) {
			case 'CA':
			case 'US':
				req.format = {
					pattern: postalCodeRegex[code],
					message: formatMessage(messages.errPostalCodeInvalid),
				};
				break;
			default:
				req.length = {
					maximum: 15,
					wrongLength: formatMessage(messages.errPostalCodeInvalid),
				};
		}
		return req;
	},
});

export const pickUpStationConstraints = (formatMessage) => ({
	value: {
		presence: {
			message: formatMessage(messages.errSmartCardPickupStation),
			allowEmpty: false,
		},
	},
});

export const smartCardNumberConstraints = (formatMessage) => ({
	smartCardNumber: {
		presence: {
			message: formatMessage(messages.errSmartCardNumberEmpty),
			allowEmpty: false,
		},
		numericality: { message: formatMessage(messages.errSmartCardNumberFormat) },
		length: {
			is: 18,
			message: formatMessage(messages.errSmartCardNumberLength),
		},
	},
});

export const paperEmailConstraints = (formatMessage) => ({
	tdoPaperEmail: {
		presence: {
			message: formatMessage(messages.errEmptyEmail),
			allowEmpty: false,
		},
		email: {
			message: formatMessage(messages.errInvalidEmail),
		},
	},
});
