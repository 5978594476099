import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';

import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import styles from './styles';

const CustomInfoItem = ({
	type = '',
	value = '',
	index,
	labels,
	errors,
	hideRequired,
	handleChange,
	handleRemoveCI,
	typeReadOnly,
	disabled,
	required,
	disableRequired,
	showRemove,
}) => {
	const handleChangeField = (event) => {
		handleChange(index, { [event.target.name]: event.target.value });
	};

	const handleCheckRequired = (event, isChecked) => {
		handleChange(index, { required: isChecked });
	};

	const hasErrors = !validate.isEmpty(errors);

	return (
		<div className="row container-fluid" data-testid="CustomInfoItem">
			<div className="col-sm-3">
				<TextField
					id={`srtCIType${index}`}
					name="type"
					label={labels.type}
					disabled={typeReadOnly || disabled}
					fullWidth
					value={type}
					error={hasErrors && !!errors.type}
					helperText={hasErrors && errors.type}
					onChange={handleChangeField}
					variant="standard"
				/>
			</div>
			<div className="col-sm-4">
				<TextField
					id={`srtCIValue${index}`}
					name="value"
					label={labels.value}
					disabled={disabled}
					fullWidth
					value={value}
					error={hasErrors && !!errors.value}
					helperText={hasErrors && errors.value}
					onChange={handleChangeField}
					variant="standard"
				/>
			</div>
			<div className="col-sm-2" style={styles.ciRequiredContainer}>
				{!hideRequired && (
					<FormControlLabel
						control={
							<Checkbox
								id={`srtCIRequired${index}`}
								onChange={handleCheckRequired}
								checked={required}
								disabled={typeReadOnly || disableRequired || disabled}
								style={styles.ciRequired}
							/>
						}
						label={labels.required}
					/>
				)}
			</div>
			{!showRemove || (
				<div className="col-sm-3" style={styles.ciRemoveButtonContainer}>
					<Button
						variant="contained"
						id={`srtCIRemoveButton${index}`}
						onClick={() => handleRemoveCI(index)}
						color="secondary"
						fullWidth
					>
						{labels.remove}
					</Button>
				</div>
			)}
		</div>
	);
};

CustomInfoItem.propTypes = {
	index: PropTypes.number.isRequired,
	type: PropTypes.string,
	typeReadOnly: PropTypes.bool,
	value: PropTypes.string,
	required: PropTypes.bool,
	disableRequired: PropTypes.bool,
	disabled: PropTypes.bool,
	handleChange: PropTypes.func.isRequired,
	handleRemoveCI: PropTypes.func.isRequired,
	showRemove: PropTypes.bool,
	errors: PropTypes.object,
	labels: PropTypes.shape({
		type: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		required: PropTypes.string.isRequired,
		remove: PropTypes.string.isRequired,
	}).isRequired,
	hideRequired: PropTypes.bool,
};

export default CustomInfoItem;
