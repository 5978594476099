import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { FormattedMessage } from 'react-intl';

import messages from './messagesReturnCoupons';
import inlineStyles from './styles';

const Actions = ({ disabled, onClose, handleSubmitCoupons }) => (
	<div className="row" style={inlineStyles.dialogActionsRoot}>
		<div className="col-12 col-sm-4 offset-sm-2">
			<Button
				variant="contained"
				id="srtReturnCouponsClose"
				onClick={onClose}
				fullWidth
				disabled={disabled}
			>
				<FormattedMessage {...messages.btnClose} />
			</Button>
		</div>
		<div className="col-12 col-sm-6">
			<Button
				variant="contained"
				id="srtReturnCouponsSubmit"
				onClick={handleSubmitCoupons}
				fullWidth
				color="primary"
				disabled={disabled}
			>
				<FormattedMessage {...messages.btnSubmit} />
			</Button>
		</div>
	</div>
);

Actions.propTypes = {
	disabled: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	handleSubmitCoupons: PropTypes.func.isRequired,
};

export default Actions;
