export default {
	button: {
		margin: 4,
	},
	dialogPaper: {
		overflowY: 'hidden',
	},
	dialogActionsRoot: {
		width: '100%',
	},
};
