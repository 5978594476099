import { defineMessages } from 'react-intl';

export default defineMessages({
	lblAboutTitle: {
		id: 'app.common.header.about.title',
		defaultMessage: 'About SilverAgent',
	},
	lblAboutSubsidiaries: {
		id: 'app.common.header.about.subsidiaries',
		defaultMessage:
			'SilverRail Technologies Inc and its subsidiaries connects the world of rail.  ' +
			'We specialize in building best-in-class technical solutions that help Railways and Travel Distributors easily connect with each other.',
	},
	lblAboutFinancial: {
		id: 'app.common.header.about.financial',
		defaultMessage:
			'Financial transactions through this site are processed by SilverRail Technologies UK Ltd, ' +
			'with the registered address 2 Minton Place, Victoria Rd, Bicester, Oxon, OX26 6QB, United Kingdom.',
	},
	lblAboutCreditCard: {
		id: 'app.common.header.about.creditCard',
		defaultMessage:
			'All credit card information is submitted on a secured page.',
	},
	lblAboutContactSupport: {
		id: 'app.common.header.about.contactSupport',
		defaultMessage: 'Contact SilverAgent Support:',
	},
	lblAboutContactEmail: {
		id: 'app.common.header.about.contactEmail',
		defaultMessage: 'silveragent.support@silverrailtech.com',
	},
	lblAboutContactPhone: {
		id: 'app.common.header.about.contactPhone',
		defaultMessage: '+1 617 934 6786 x895',
	},
	lblAboutSilverAgentVersion: {
		id: 'app.common.header.about.saVersion',
		defaultMessage: 'SilverAgent version',
	},
	lblAboutSilverAgentRights1: {
		id: 'app.common.header.about.saRights1',
		defaultMessage: '© 2012 - {year} SilverRail Technologies Inc.',
	},
	lblAboutSilverAgentRights2: {
		id: 'app.common.header.about.saRights2',
		defaultMessage: 'All rights reserved.',
	},
	lblAboutAcceptedPayment: {
		id: 'app.common.header.about.acceptedPayment',
		defaultMessage: 'Accepted Payment',
	},
	lblAboutTerms: {
		id: 'app.common.header.about.terms',
		defaultMessage: 'Terms and Conditions',
	},
	lblClose: {
		id: 'app.common.header.about.close',
		defaultMessage: 'Close',
	},
});
