import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SeatMapVariant, SeatMapWidget } from '@silverrailtech/srtlibrary';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { getSeatMap } from '../../../../../../api';
import { gaEvent } from '../../../../../../utils/googleAnalytics';
import { getAvailableDeck, getAvailableCoach, getDeckLevel } from '../util';
import messages from '../../messagesReservations';
import '../../reservations.css';
import ExtendedSnackbar from '../../../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import messagesSeatMap from './messagesSeatMap';
import inlineStyles from './styles';
import { Wrapper, Title, SeatMapWrapper } from './styled';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

export const fetchSeatMapData = async (
	languageCode,
	travelSegments,
	setSeatMap,
	setErrorText,
	setIsProcessing,
	shoppingContext,
) => {
	travelSegments.forEach(async (segment) => {
		if (segment.SeatMapInformation) {
			return getSeatMap(
				{
					externalproposal: segment.SeatMapInformation.ExternalProposal,
					externaltravelsegment:
						segment.SeatMapInformation.ExternalTravelSegment,
					externalSupplier: segment.SeatMapInformation.ExternalSupplier,
					externalSearchToken: segment.SeatMapInformation.ExternalSearchToken,
					carrier: segment.SeatMapInformation.Carrier,
					vehicleId: segment.SeatMapInformation.VehicleId,
					boardLocation: segment.SeatMapInformation.BoardLocation,
					alightLocation: segment.SeatMapInformation.AlightLocation,
					departureDateTime: segment.SeatMapInformation.DepartureDateTime,
					ticketClass: segment.SeatMapInformation.TicketClass,
					inventoryCode: segment.SeatMapInformation.InventoryCode, // new field
					context: shoppingContext,
				},
				(e) => {
					const errorMessage = e?.errorResponse?.message || e;
					setErrorText(errorMessage);
					setIsProcessing(false);
				},
				(data) => {
					const dataParsed = {
						...data,
						vehicle: {
							...data.vehicle,
							coaches: data.vehicle.coaches.map((coach) => ({
								...coach,
								decks: coach.decks.map((deck, idx) => ({
									...deck,
									id: `${coach.id}-${idx}`,
								})),
							})),
						},
					};

					setSeatMap((prev) => ({
						...prev,
						[segment.TravelSegmentID]: dataParsed,
					}));
					setIsProcessing(false);
				},
			);
		}
		return null;
	});
};

const RESIZE_WIDTH = 1600;

const SeatSelectorDialog = ({
	open,
	intl: { formatMessage, locale },
	onDiscardSeatSelector,
	onRequestSeats,
	seatMapData,
	travelSegmentID,
	exchangeOrderMode,
	departStation,
	arriveStation,
	selectedPax,
	passengerId,
	selectedSeatReservations,
	paxLength,
	shoppingContext,
}) => {
	const [seatMap, setSeatMap] = useState({});
	const [errorText, setErrorText] = useState('');
	const [selectedCoach, setSelectedCoach] = useState();
	const [selectedDeckLevel, setSelectedDeckLevel] = useState();
	const [selectedSeats, setSelectedSeats] = useState([]);
	const [isProcessing, setIsProcessing] = useState(true);
	const localeFiltered = locale.split('-');
	const [vehicleWithFloorPlan, setVehicleWithFloorPlan] = useState(null);

	const [isTabletOrMobile, setIsTabletOrMobile] = useState(
		window.innerWidth <= RESIZE_WIDTH,
	);

	useEffect(() => {
		const handleResize = () => {
			setIsTabletOrMobile(window.innerWidth <= RESIZE_WIDTH);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (seatMapData) {
			fetchSeatMapData(
				localeFiltered[0],
				seatMapData,
				setSeatMap,
				setErrorText,
				setIsProcessing,
				shoppingContext,
			);
			setSelectedCoach(null);
		}
	}, [seatMapData]);

	const seats = seatMap[travelSegmentID];
	const coachesDecks = seats?.vehicle?.coaches?.map(
		(coach) => coach.decks?.length,
	);
	const isDoubleDeckers = (coachesDecks && Math.max(...coachesDecks)) > 1;
	const availableCoach = getAvailableCoach(seats?.vehicle?.coaches);
	const availableDeck = getAvailableDeck(availableCoach?.decks);

	const initialDeckLevel = getDeckLevel(availableCoach, availableDeck);

	const handleSeatChange = () => (e) => {
		const { value } = e.target;
		const coachWhichContainsTarget = vehicleWithFloorPlan?.coaches?.find(
			(coach) => coach.id === value?.[0]?.coachId,
		);
		const deckIdWithTarget = coachWhichContainsTarget?.decks?.find((deck) =>
			deck?.seats?.find((seat) => seat.id === value?.[0]?.id),
		).id;
		if (value) {
			setSelectedCoach(value?.[0].coachId);
			setSelectedDeckLevel(value?.[0]?.deckLevel);
			const amendedSeat = { ...value?.[0], deckId: deckIdWithTarget };
			setSelectedSeats([amendedSeat]);
		}
	};

	const handleDiscard = () => {
		if (exchangeOrderMode) {
			gaEvent('exchangeOrderSeatMapDiscardSeats');
		} else {
			gaEvent('seatmapComponentDiscard');
		}
		onDiscardSeatSelector();
	};

	const handleRequestSeats = () => {
		if (exchangeOrderMode) {
			gaEvent('exchangeOrderSeatMapRequestSeats');
		} else {
			gaEvent('seatmapComponentRequestSeats');
		}

		try {
			if (selectedSeats?.length) {
				onRequestSeats(
					travelSegmentID,
					selectedCoach,
					selectedSeats[0].id,
					selectedDeckLevel || initialDeckLevel,
					selectedSeats[0],
				);
			} else {
				console.log('No seats chosen');
			}
		} catch (error) {
			console.error('Error in handleRequestSeats:', error);
		}
	};

	const handleSnackbarClose = () => {
		setErrorText('');
	};

	const seatReservations = selectedSeatReservations[travelSegmentID];

	const otherPassengerSeats = seatReservations
		? Object.entries(seatReservations)
				.filter(([key]) => key !== passengerId)
				.map((seat) =>
					formatMessage(messagesSeatMap.lblOtherPassengerSeats, {
						deckLevel: isDoubleDeckers && seat[1].deckLevel,
						coach: seat[1].nearbyCoach,
						seat: seat[1].nearbySeat,
					}),
				)
				.join(', ')
		: '';

	const actions = [
		<Button
			variant="contained"
			id="srtReservationsDiscard"
			key="srtReservationsDiscard"
			onClick={handleDiscard}
			style={inlineStyles.buttons}
		>
			{formatMessage(messages.btnDiscard)}
		</Button>,
		<Button
			variant="contained"
			id="srtReservationsRequest"
			key="srtReservationsRequest"
			onClick={handleRequestSeats}
			color="primary"
			style={inlineStyles.buttons}
		>
			{formatMessage(messages.btnReqestSeats)}
		</Button>,
	];

	const hasSeatForPax = !!seatReservations?.[passengerId];

	// Sometimes french market doesn't return floorplan size and we cannot update this to latest SRTLibrary due to Styled Component versions
	const coachWithNoFloorPlan = vehicleWithFloorPlan?.coaches?.find((coach) =>
		coach.decks.find((deck) => !deck.floorPlanSize),
	);

	useEffect(() => {
		if (open && seats) {
			const defaultFloorPlanSize = { width: 1200, height: 100 };
			const defaultSeatSize = { width: 32, height: 32 };
			const coaches = seats?.vehicle?.coaches.map((coach) => ({
				...coach,
				decks: coach.decks.map((deck) => ({
					...deck,
					floorPlanSize: deck?.floorPlanSize || defaultFloorPlanSize,
					defaultSeatSize: deck?.defaultSeatSize || defaultSeatSize,
				})),
			}));
			setVehicleWithFloorPlan({ ...seats?.vehicle, coaches });
		}
	}, [seats?.vehicle, open]);

	return (
		<>
			<StyledDialog
				open={open}
				onClose={handleDiscard}
				disableEnforceFocus
				maxWidth={false}
			>
				<DialogTitle>
					<FormattedMessage
						{...messagesSeatMap.lblSeatMapTitle}
						values={{ departStation, arriveStation, selectedPax }}
					/>
				</DialogTitle>
				<DialogContent>
					<Wrapper>
						{paxLength > 1 && (
							<Title>
								<FormattedMessage
									{...messagesSeatMap.lblOtherPassengerSelectedSeats}
									values={{ seats: otherPassengerSeats }}
								/>
							</Title>
						)}
						{!isProcessing &&
							seats &&
							!coachWithNoFloorPlan &&
							vehicleWithFloorPlan && (
								<>
									<SeatMapWrapper>
										<SeatMapWidget
											selectedSeats={
												(hasSeatForPax && [
													seatReservations?.[passengerId]?.selectedSeat,
												]) ||
												null
											}
											key={`${seats.vehicle.id}_${passengerId}`}
											vehicle={vehicleWithFloorPlan}
											maxSeats={1}
											variant={
												isTabletOrMobile
													? SeatMapVariant.VERTICAL
													: SeatMapVariant.HORIZONTAL
											}
											coachTitle={
												<FormattedMessage
													{...messagesSeatMap.lblSelectPreferredCoach}
												/>
											}
											seatMapTitle={
												<FormattedMessage
													{...messagesSeatMap.lblSelectPreferredSeat}
												/>
											}
											seatMapLabels={{
												selected: {
													label: (
														<FormattedMessage
															{...messagesSeatMap.lblSelected}
														/>
													),
												},
												unavailable: {
													label: (
														<FormattedMessage
															{...messagesSeatMap.lblUnavailable}
														/>
													),
												},
												available: {
													label: (
														<FormattedMessage
															{...messagesSeatMap.lblAvailable}
														/>
													),
												},
												direction: {
													label: (
														<FormattedMessage
															{...messagesSeatMap.lblDirectionOfTravel}
														/>
													),
												},
											}}
											onChange={handleSeatChange()}
										/>
									</SeatMapWrapper>
								</>
							)}
					</Wrapper>
				</DialogContent>
				<DialogActions>{actions}</DialogActions>
			</StyledDialog>
			<ExtendedSnackbar
				id="srtSeatMapSnackBar"
				open={errorText !== ''}
				message={errorText}
				onClose={handleSnackbarClose}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const seatMapData = state.getIn(['booking', 'seatMap']);
	return {
		seatMapData: seatMapData ? seatMapData.toJS() : seatMapData,
	};
};

SeatSelectorDialog.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool.isRequired,
	onDiscardSeatSelector: PropTypes.func.isRequired,
	onRequestSeats: PropTypes.func.isRequired,
	seatMapData: PropTypes.array,
	travelSegmentID: PropTypes.string,
	exchangeOrderMode: PropTypes.bool,
	departStation: PropTypes.string,
	arriveStation: PropTypes.string,
	selectedPax: PropTypes.number,
	passengerId: PropTypes.string,
	selectedSeatReservations: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	paxLength: PropTypes.number,
	shoppingContext: PropTypes.string,
};

export { SeatSelectorDialog as SeatSelectorDialogAlias };

export default connect(mapStateToProps, null)(injectIntl(SeatSelectorDialog));
