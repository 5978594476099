import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import './fare.css';
import inlineStyles from './fareStyles';

const StyledPaperNoOverflow = styled(Paper)(() => ({
	overflowY: 'unset !important',
}));

const FareDisplay = ({
	disabled,
	selected,
	isSplitTicketFare,
	showSeatsWarning,
	fare,
	formattedPrice,
	farePriceSmall,
	fareTypeClass,
	primaryColumnClass,
	scheduleClass,
	seasonAttribute,
	refundableIndicator,
	messages,
	splitTicket,
	handleClickSelection,
	formatMessage,
	renderIconButton,
}) => (
	<StyledPaperNoOverflow
		styleName={`fare${disabled ? ' disabled' : ''}`}
		onClick={handleClickSelection}
	>
		{isSplitTicketFare ? <div /> : renderIconButton()}
		{selected ? <div styleName="selected" /> : <span />}
		{showSeatsWarning && (
			<div styleName="seatsContainer">
				<div styleName="seats">
					{fare.isOverbooked && (
						<FormattedMessage {...messages.lblOverbooked} />
					)}
					{fare.seatsAvailable && fare.isOverbooked && ', '}
					{fare.seatsAvailable}
				</div>
			</div>
		)}
		<div className="price" styleName={`price${farePriceSmall}`}>
			<span>{formattedPrice}</span>
			{(fare.discountApplied || fare?.campaignInfo?.success) && '*'}
		</div>
		{isSplitTicketFare ? (
			<div>
				<div style={inlineStyles.splitTicketLogoContainerStyle}>
					<div style={inlineStyles.fareStyles}>
						{formatMessage(messages.lblSplitTickets)}
					</div>
					<img src={splitTicket} style={inlineStyles.splitTicketLogoStyle} />
				</div>
			</div>
		) : (
			<div>
				<div
					className="typeClass"
					style={inlineStyles.fareStyles}
					title={fareTypeClass}
				>
					{fareTypeClass}
				</div>
				<div
					className="seatingClass"
					style={inlineStyles.fareStyles}
					title={primaryColumnClass}
				>
					{primaryColumnClass}
				</div>
				<div
					className="timeClass"
					style={inlineStyles.fareStyles}
					title={scheduleClass}
				>
					{scheduleClass}
				</div>
				{fareTypeClass === 'Season' && (
					<div
						className="fareClass"
						style={inlineStyles.fareStyles}
						title={seasonAttribute}
					>
						{seasonAttribute}
					</div>
				)}
				<div>
					<p
						style={{
							whiteSpace: 'pre',
							...inlineStyles.fareStyles,
							...(fareTypeClass === 'Season'
								? inlineStyles.seasonFareStyles
								: {}),
						}}
					>
						{formatMessage(
							refundableIndicator
								? messages.lblRefundable
								: messages.lblNoRefund,
						)}
					</p>
				</div>
			</div>
		)}
	</StyledPaperNoOverflow>
);

FareDisplay.propTypes = {
	disabled: PropTypes.bool,
	selected: PropTypes.bool,
	isSplitTicketFare: PropTypes.bool,
	showSeatsWarning: PropTypes.bool,
	fare: PropTypes.shape({
		isOverbooked: PropTypes.bool,
		seatsAvailable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		discountApplied: PropTypes.bool,
		campaignInfo: PropTypes.shape({
			success: PropTypes.bool,
		}),
	}),
	formattedPrice: PropTypes.string.isRequired,
	farePriceSmall: PropTypes.string,
	fareTypeClass: PropTypes.string,
	primaryColumnClass: PropTypes.string,
	scheduleClass: PropTypes.string,
	seasonAttribute: PropTypes.string,
	refundableIndicator: PropTypes.bool,
	messages: PropTypes.object.isRequired,
	splitTicket: PropTypes.string,
	handleClickSelection: PropTypes.func.isRequired,
	formatMessage: PropTypes.func.isRequired,
	renderIconButton: PropTypes.func.isRequired,
};

export default FareDisplay;
