import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import messages from './messagesTicketDelivery';

function SmsOptions({
	intl: { formatMessage },
	errors,
	onSmsCountryChange,
	onSmsNumberChange,
	smsCodeOptions,
	tdoSmsCountry,
	tdoSmsNumber,
}) {
	return (
		<div>
			<div>
				<TextField
					id="srtTdoSmsCountryCode"
					fullWidth
					placeholder={formatMessage(messages.lblTDOSmsSelect)}
					value={tdoSmsCountry}
					onChange={onSmsCountryChange}
					error={!!errors.tdoSmsCountry}
					helperText={errors.tdoSmsCountry}
					select
					variant="standard"
				>
					{smsCodeOptions.map((code) => (
						<MenuItem key={code.value} value={code.value} id={code.value}>
							{code.text}
						</MenuItem>
					))}
				</TextField>
			</div>
			<div>
				<TextField
					id="srtTdoSmsText"
					fullWidth
					placeholder={formatMessage(messages.lblTDOSmsNumber)}
					value={tdoSmsNumber}
					onChange={onSmsNumberChange}
					error={!!errors.tdoSmsNumber}
					helperText={errors.tdoSmsNumber}
					variant="standard"
				/>
			</div>
		</div>
	);
}

SmsOptions.propTypes = {
	intl: PropTypes.object,
	errors: PropTypes.object,
	onSmsCountryChange: PropTypes.func,
	onSmsNumberChange: PropTypes.func,
	smsCodeOptions: PropTypes.array,
	tdoSmsCountry: PropTypes.string,
	tdoSmsNumber: PropTypes.string,
};

export { SmsOptions as SmsOptionsAlias };

export default injectIntl(SmsOptions);
