import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import BillingTable from '../BillingTable/BillingTable';

function BillingSummaryOverview({ booking }) {
	return (
		<BillingTable
			billingItems={sortBy(
				booking.BookingBillingSummary,
				(item) => new Date(item.BookingBillingDate),
			)}
			type="overview"
			isSortable
			orders={booking.BookingOrders}
		/>
	);
}

BillingSummaryOverview.propTypes = {
	booking: PropTypes.object.isRequired,
};

export default BillingSummaryOverview;
