import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import messages from '../messagesShoppingSearch';
import '../shoppingSearch.css';
import { passengerAges } from '../../util';
import { gaEvent } from '../../../utils/googleAnalytics';

const Passengers = ({
	paxAge,
	disable,
	onChangePassenger,
	onRemovePassenger,
	onAddPassenger,
	addOrderMode,
	exchangeOrderMode,
	intl: { formatMessage },
	isSeason,
}) => (
	<div>
		{paxAge.map((item, index) => {
			const showAddButton = !(addOrderMode || exchangeOrderMode || isSeason);

			return (
				<div
					className="row"
					key={`passenger${index}`}
					id={`srtPassenger${index}AgesDropdownContainer`}
				>
					<div className="col-7 col-sm-8 col-md-9 col-lg-9 col-xl-10">
						<TextField
							id={`srtPassenger${index}`}
							fullWidth
							value={item}
							onChange={({ target: { value } }) => {
								onChangePassenger(value, index);
							}}
							disabled={disable}
							select
							variant="standard"
							label={
								<FormattedMessage
									{...messages.lblPassengerLabel}
									values={{ id: index + 1 }}
								/>
							}
						>
							{passengerAges(formatMessage).map((age, i) => (
								<MenuItem
									id={`srtPassenger${i}_${age.value}`}
									key={`${i}_${age.value}`}
									value={age.value}
								>
									{age.label}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div
						className="col-5 col-sm-4 col-md-3 col-lg-3 col-xl-2"
						styleName="buttons-verticalAlignWithLabel"
					>
						{index > 0 ? (
							<Button
								variant="contained"
								id={`srtRemovePassenger${index}`}
								fullWidth
								onClick={() => {
									gaEvent('fareSearchRemovePassenger');
									onRemovePassenger(index);
								}}
								disabled={disable}
							>
								<FormattedMessage {...messages.lblPassengerRemove} />
							</Button>
						) : (
							showAddButton && (
								<Button
									variant="contained"
									id="srtAddPassenger"
									fullWidth
									color="secondary"
									onClick={() => {
										gaEvent('fareSearchAddPassenger');
										onAddPassenger();
									}}
									disabled={disable}
								>
									<FormattedMessage {...messages.lblPassengerAdd} />
								</Button>
							)
						)}
					</div>
				</div>
			);
		})}
	</div>
);

Passengers.propTypes = {
	intl: PropTypes.object,
	paxAge: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	disable: PropTypes.bool,
	maxNumPax: PropTypes.number,
	onRemovePassenger: PropTypes.func,
	onAddPassenger: PropTypes.func,
	onChangePassenger: PropTypes.func,
	addOrderMode: PropTypes.bool,
	exchangeOrderMode: PropTypes.bool,
	isSeason: PropTypes.bool,
};

export { Passengers as PassengersAlias };

export default injectIntl(Passengers);
