import { darkGray3 } from '../../../colors';

export default {
	itinerary: {
		marginTop: 10,
		padding: '5px 0',
		color: darkGray3,
		fontWeight: 200,
	},
};
