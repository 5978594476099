export default {
	tableContainer: {
		marginBottom: 15,
		marginTop: 15,
	},
	dialogPaper: {
		overflowY: 'visible',
		maxWidth: 768,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	refreshIndicator: {
		zIndex: 2000,
		marginTop: '5%',
		marginLeft: '48%',
	},
	couponsToAuthorizeColumn: {
		width: 160,
	},
	authorizedCouponsColumn: {
		width: 170,
	},
};
