import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { injectIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import messages from './messagesIssueRefund';
import BillingExternalProviderFields from './BillingExternalProviderFields';
import RefundAmountFields from './RefundAmountFields';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { formatPrice, getCurrency } from '../../../utils';
import inlineStyles from './styles';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import bookingViewMessages from '../../ManageBooking/components/BookingView/messagesBookingView';

const DEFAULT_INDICATOR_TIMEOUT_DURATION = 2500;
const DEFAULT_MESSAGE_TIMEOUT_DURATION = 5000;

const StyledLabelHighlightedDiv = styled('div')({
	...inlineStyles.labelHighlighted,
});

const IssueRefundBraintree = ({
	id,
	disabled,
	intl,
	onUpdateRefundAmount,
	isProcessingSubmit,
	receiptDetails,
	refundAmount: amountReturned,
	refundDetails,
	refundCurrency,
	showWelcomeConfirmation,
	reverseRefundMutation,
}) => {
	const currency = getCurrency(refundCurrency, intl);

	const [showIndicator, setShowIndicator] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [showWarningMessage, setShowWarningMessage] = useState(false);
	const [concatenatedMessage, setConcatenatedMessage] = useState('');

	useEffect(() => {
		if (reverseRefundMutation) {
			setShowIndicator(true);
			setShowSnackbar(true);

			const indicatorTimer = setTimeout(() => {
				setShowIndicator(false);
			}, DEFAULT_INDICATOR_TIMEOUT_DURATION);

			const snackbarTimer = setTimeout(() => {
				setShowSnackbar(false);
			}, DEFAULT_MESSAGE_TIMEOUT_DURATION);

			return () => {
				clearTimeout(indicatorTimer);
				clearTimeout(snackbarTimer);
			};
		}
		return undefined; // eslint warning clearup
	}, [reverseRefundMutation]);

	useEffect(() => {
		const warningMessages = refundDetails?.BookingRefundableWarningMessages;
		if (warningMessages && warningMessages.length) {
			const concatenatedWarningMessage = warningMessages.join(' ');
			setConcatenatedMessage(concatenatedWarningMessage);
			setShowWarningMessage(true);

			const messageTimer = setTimeout(() => {
				setShowWarningMessage(false);
				setConcatenatedMessage('');
			}, DEFAULT_MESSAGE_TIMEOUT_DURATION);

			return () => clearTimeout(messageTimer);
		}

		return undefined; // eslint warning fix
	}, [refundDetails?.BookingRefundableWarningMessages]);

	return (
		<div className="container-fluid" data-testid="isssue-refund-braintree">
			<div className="row">
				{receiptDetails ? (
					<StyledLabelHighlightedDiv
						className="col-12"
						data-testid="receipt-details"
					>
						{showWelcomeConfirmation ? (
							<FormattedMessage
								{...messages.lblWelcomeConfirmation}
								values={{
									amount: formatPrice(amountReturned, refundCurrency, intl),
								}}
							/>
						) : (
							<>
								<TextField
									id={`${id}_ReceiptNumber`}
									data-testid={`${id}_ReceiptNumber`}
									name=""
									label={<FormattedMessage {...messages.lblReceiptNumber} />}
									fullWidth
									value={
										receiptDetails.BookingBillingPaymentReceiptNumber || ''
									}
									disabled
									variant="standard"
								/>
								<TextField
									id={`${id}_PaymentDate`}
									data-testid={`${id}_PaymentDate`}
									label={<FormattedMessage {...messages.lblPaymentDate} />}
									fullWidth
									value={receiptDetails.BookingBillingPaymentDate || ''}
									disabled
									variant="standard"
								/>
								<TextField
									id={`${id}_AmountPaid`}
									data-testid={`${id}_AmountPaid`}
									label={<FormattedMessage {...messages.lblAmountPaid} />}
									fullWidth
									value={
										receiptDetails.BookingBillingPaymentDetailCurrency
											? formatPrice(
													receiptDetails.BookingBillingPaymentDetailAmount,
													receiptDetails.BookingBillingPaymentDetailCurrency,
													intl,
												)
											: ''
									}
									disabled
									variant="standard"
								/>
								<TextField
									id={`${id}_Balance`}
									data-testid={`${id}_Balance`}
									label={<FormattedMessage {...messages.lblBalance} />}
									fullWidth
									value={
										receiptDetails.BookingBillingPaymentDetailCurrency
											? formatPrice(
													receiptDetails.BookingBillingPaymentDetailBalance,
													receiptDetails.BookingBillingPaymentDetailCurrency,
													intl,
												)
											: ''
									}
									disabled
									variant="standard"
								/>
								{receiptDetails.BookingBillingExternalProvider ? (
									<BillingExternalProviderFields
										id={id}
										receiptDetails={receiptDetails}
									/>
								) : (
									<>
										<TextField
											id={`${id}_PaymentProcessor`}
											data-testid={`${id}_PaymentProcessor`}
											label={
												<FormattedMessage {...messages.lblPaymentProcessor} />
											}
											fullWidth
											value={
												receiptDetails.BookingBillingPaymentDetailPaymentProcessor ||
												''
											}
											disabled
											variant="standard"
										/>
										<TextField
											id={`${id}_ApprovalNumber`}
											data-testid={`${id}_ApprovalNumber`}
											label={
												<FormattedMessage {...messages.lblApprovalNumber} />
											}
											fullWidth
											value={
												receiptDetails.BookingBillingPaymentDetailApprovalNumber ||
												''
											}
											disabled
											variant="standard"
										/>
										<TextField
											id={`${id}_Error`}
											data-testid={`${id}_Error`}
											label={<FormattedMessage {...messages.lblError} />}
											fullWidth
											value={
												receiptDetails.BookingBillingPaymentDetailError || ''
											}
											disabled
											variant="standard"
										/>
									</>
								)}
								<TextField
									id={`${id}_PaymentMethod`}
									label={<FormattedMessage {...messages.lblPaymentMethod} />}
									fullWidth
									value={receiptDetails.BookingBillingPaymentDetailMethod || ''}
									disabled
									variant="standard"
								/>
								<RefundAmountFields
									id={id}
									amountReturned={amountReturned}
									currency={currency}
									disabled={disabled}
									onUpdateRefundAmount={onUpdateRefundAmount}
								/>
							</>
						)}
						{showWarningMessage && !showWelcomeConfirmation && (
							<ExtendedSnackbar
								id="issue-refund-braintree-snackbar-settlement"
								open={showWarningMessage}
								message={concatenatedMessage}
								onClose={() => setShowWarningMessage(false)}
							/>
						)}
					</StyledLabelHighlightedDiv>
				) : null}
				{(isProcessingSubmit || showIndicator) && (
					<RefreshIndicator
						size={36}
						top={0}
						left={0}
						status="loading"
						style={inlineStyles.refreshIndicator}
					/>
				)}
				{showIndicator && (
					<ExtendedSnackbar
						id="issue-refund-braintree-snackbar"
						open={showSnackbar}
						message={
							<FormattedMessage
								{...bookingViewMessages.unableToRetrievePayment}
							/>
						}
						onClose={() => setShowSnackbar(false)}
					/>
				)}
			</div>
		</div>
	);
};

IssueRefundBraintree.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	intl: PropTypes.object,
	onUpdateRefundAmount: PropTypes.func,
	receiptDetails: PropTypes.object,
	refundDetails: PropTypes.object,
	refundAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	refundCurrency: PropTypes.string,
	showWelcomeConfirmation: PropTypes.bool.isRequired,
	reverseRefundMutation: PropTypes.bool,
	isProcessingSubmit: PropTypes.bool,
};

export { IssueRefundBraintree as IssueRefundAlias };
export default injectIntl(IssueRefundBraintree);
