import commonStyles from '../../../../commonStyles';
import bookingViewStyles from '../BookingView/styles';

export default {
	contentContainer: bookingViewStyles.contentContainer,
	paperContainer: bookingViewStyles.paperContainer,
	bookingTable: bookingViewStyles.bookingTable,
	tableWrapper: commonStyles.tableWrapper,
	bookingTableRow: bookingViewStyles.bookingTableRow,
};
