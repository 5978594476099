import { defineMessages } from 'react-intl';

export default defineMessages({
	manageAccounts: {
		id: 'app.common.nav.manageAccounts',
		defaultMessage: 'Manage Accounts',
	},
	createUser: {
		id: 'app.common.nav.createUser',
		defaultMessage: 'Create User',
	},
	manageUsers: {
		id: 'app.common.nav.manageUsers',
		defaultMessage: 'Manage Users',
	},
	userProfile: {
		id: 'app.common.nav.userProfile',
		defaultMessage: 'User Profile',
	},
	lblNavBook: {
		id: 'app.common.nav.book',
		defaultMessage: 'Book',
	},
	lblNavManage: {
		id: 'app.common.nav.manage',
		defaultMessage: 'Manage',
	},
	lblNavAdmin: {
		id: 'app.common.nav.admin',
		defaultMessage: 'Admin',
	},
});
