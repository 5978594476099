import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { injectIntl } from 'react-intl';

import messages from './messagesSendToEmail';
import inlineStyles from './styles';
import SendToSms from '../SendToSms/SendToSms';

const SendToEmail = ({
	vdCurrEmail,
	showAlternateEmail,
	onChangeEmail,
	onChangeSms,
	hasEmail,
	isSms,
	smsNumber,
	smsCountryCode,
	hasMultipleEmail,
	emails,
	errors,
	disabled,
	smsCodeOptions,
	intl: { formatMessage },
}) => {
	const alternateDummyText = 'alt01e!';
	const vdEmail = showAlternateEmail ? alternateDummyText : vdCurrEmail;

	const handleChangeEmail = ({ target: { value } }) => {
		if (value.indexOf(alternateDummyText) === -1) {
			onChangeEmail({ vdEmail: value, showAlternateEmail: false });
		} else {
			onChangeEmail({ vdEmail: '', showAlternateEmail: true });
		}
	};

	const handleChangeAltEmail = (event) => {
		onChangeEmail({ [event.target.name]: event.target.value });
	};

	const emailLayout = (
		<div>
			{hasEmail ? (
				<div style={inlineStyles.section}>
					<h5>{formatMessage(messages.lblEmailWillBeSentStatement)}</h5>
					<div
						id="srtConfirmationEmailSingle"
						data-testid="srtConfirmationEmailSingle"
					>
						{vdEmail}
					</div>
				</div>
			) : null}
			{hasMultipleEmail ? (
				<div style={inlineStyles.section}>
					<b>{formatMessage(messages.lblEmailWillBeSentStatement)}</b>
					<TextField
						id="srtSendToEmailSelect"
						data-testid="srtSendToEmailSelect"
						label={formatMessage(messages.lblSelectEmail)}
						fullWidth
						onChange={handleChangeEmail}
						error={!showAlternateEmail && !!errors.vdEmail}
						helperText={!showAlternateEmail ? errors.vdEmail : ''}
						value={vdEmail}
						disabled={disabled}
						select
						variant="standard"
					>
						{emails.map((item) => (
							<MenuItem
								id={`srtVDEmail_${item}`}
								key={`vd_${item}`}
								value={item}
							>
								{item}
							</MenuItem>
						))}
						<MenuItem
							id="srtVDEmail_alternate"
							key="vd_alternate"
							value={alternateDummyText}
						>
							{formatMessage(messages.lblAlternateEmail)}
						</MenuItem>
					</TextField>
					{showAlternateEmail ? (
						<TextField
							id="srtSendToAltEmail"
							name="vdEmail"
							label={formatMessage(messages.lblAlternateEmail)}
							fullWidth
							onChange={handleChangeAltEmail}
							error={!!errors.vdEmail}
							helperText={errors.vdEmail}
							value={vdCurrEmail}
							disabled={disabled}
							variant="standard"
						/>
					) : null}
				</div>
			) : null}
		</div>
	);

	const smsLayout = (
		<SendToSms
			smsNumber={smsNumber}
			smsCountryCode={smsCountryCode}
			onChangeSms={onChangeSms}
			smsCodeOptions={smsCodeOptions}
		/>
	);

	return isSms ? smsLayout : emailLayout;
};

SendToEmail.propTypes = {
	intl: PropTypes.object,
	vdCurrEmail: PropTypes.string,
	showAlternateEmail: PropTypes.bool,
	hasEmail: PropTypes.bool,
	hasMultipleEmail: PropTypes.bool,
	isSms: PropTypes.bool,
	smsNumber: PropTypes.string,
	smsCountryCode: PropTypes.string,
	onChangeEmail: PropTypes.func,
	onChangeSms: PropTypes.func,
	emails: PropTypes.array,
	errors: PropTypes.object,
	disabled: PropTypes.bool,
	smsCodeOptions: PropTypes.array,
};

export { SendToEmail as SendToEmailAlias };

export default injectIntl(SendToEmail);
