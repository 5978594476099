import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import {
	DatePicker,
	TimePicker,
	DateTimePicker as DateTimePickerMUI,
} from '@mui/x-date-pickers';
import localeSettings from '../../localeSettings';
import messages from './messages';
import styles from './styles';

const Container = styled('div')(() => ({
	...styles.container,
	...styles['.error'],
}));

const ErrorText = styled('div')(() => ({
	...styles.errorText,
}));

const DateTimePicker = memo(
	({
		addTimeOption = false,
		cancelLabel = <FormattedMessage {...messages.lblCancel} />,
		disabled,
		errorText,
		id,
		intl,
		maxDate,
		minDate,
		okLabel = <FormattedMessage {...messages.lblOk} />,
		pickerType = 'Date',
		timeType,
		value,
		onChange,
		...other
	}) => {
		const { formatMessage, formatTime } = intl;

		const onTimeChange = (date, newTimeType = timeType) => {
			onChange(date, newTimeType);
		};

		const onError = () => {
			// eslint-disable no-console
			console.log('error occurred in DateTimePicker');
		};

		const getFormatMask = () => {
			const dateFormat = formatMessage(localeSettings.dateFormat).toLowerCase();
			const timeFormat = formatMessage(localeSettings.timeFormat).toLowerCase();
			const is12Hours = /(AM|PM)/i.test(formatTime(Date.now()));
			let format = '';
			const mask = [];

			if (['Date', 'Datetime'].includes(pickerType)) {
				format += dateFormat.replace('mm', 'MM');
				mask.push(
					...dateFormat
						.split('')
						.map((letter) => (/(\d|\w)/.test(letter) ? /\d/ : letter)),
				);
			}

			if (['Time', 'Datetime'].includes(pickerType)) {
				if (format) format += ' ';
				format += is12Hours ? timeFormat : timeFormat.replace('hh', 'HH');

				if (mask.length) mask.push(' ');
				mask.push(
					...timeFormat
						.split('')
						.map((letter) => (/(\d|\w)/.test(letter) ? /\d/ : letter)),
				);

				if (is12Hours) {
					if (format) format += ' a';
					mask.push(' ', /(A|P)/i, /M/i);
				}
			}

			return {
				format,
				mask,
				is12Hours,
			};
		};

		const PickerComponent = {
			Date: DatePicker,
			Time: TimePicker,
			Datetime: DateTimePickerMUI,
		}[pickerType];

		const formatMask = getFormatMask();

		const otherProps = {
			...other,
			minDate: minDate ? new Date(minDate) : undefined,
			maxDate: maxDate ? new Date(maxDate) : undefined,
		};

		const dateTimeError = errorText !== undefined;

		return (
			<>
				<Container className={dateTimeError ? 'error' : ''} id={id}>
					{addTimeOption && (
						<TextField
							id={`${id}_DepArr`}
							onChange={({ target: { value: newTimeType } }) => {
								onTimeChange(value, parseInt(newTimeType, 10));
							}}
							value={timeType}
							select
							disabled={disabled}
						>
							<MenuItem id={`${id}_depart`} value={0}>
								<FormattedMessage {...messages.lblDeparting} />
							</MenuItem>
							<MenuItem id={`${id}_arrive`} value={1}>
								<FormattedMessage {...messages.lblArriving} />
							</MenuItem>
						</TextField>
					)}
					<PickerComponent
						{...otherProps}
						disabled={disabled}
						value={value}
						onChange={(date) => onTimeChange(date, timeType)}
						onError={onError}
						format={formatMask.format}
						mask={formatMask.mask}
						ampm={
							['Time', 'Datetime'].includes(pickerType)
								? formatMask.is12Hours
								: undefined
						}
						cancelText={cancelLabel}
						okText={okLabel}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton>
										<EventIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
						sx={{ flex: 1 }}
						className={`${id}-text-field-wrapper`}
					/>
					<Helmet
						link={[
							{
								rel: 'stylesheet',
								href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
							},
						]}
					/>
				</Container>
				<ErrorText>
					<p>{errorText}</p>
				</ErrorText>
			</>
		);
	},
);

DateTimePicker.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	timeType: PropTypes.number,
	errorText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	disabled: PropTypes.bool,
	pickerType: PropTypes.oneOf(['Date', 'Time', 'Datetime']),
	intl: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	defaultDate: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	minDate: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	maxDate: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date),
	]),
	addTimeOption: PropTypes.bool,
	id: PropTypes.string,
	cancelLabel: PropTypes.node,
	okLabel: PropTypes.node,
	invalidDateMessage: PropTypes.node,
	maxDateMessage: PropTypes.node,
	minDateMessage: PropTypes.node,
};

export { DateTimePicker as DateTimePickerAlias };

export default injectIntl(DateTimePicker);
