import { defineMessages } from 'react-intl';

export default defineMessages({
	lblUserMenuAbout: {
		id: 'app.common.header.usermenu.about',
		defaultMessage: 'About',
	},
	lblUserMenuMyProfile: {
		id: 'app.common.header.usermenu.myProfile',
		defaultMessage: 'My Profile',
	},
	lblUserMenuChangePassword: {
		id: 'app.common.header.usermenu.changePassword',
		defaultMessage: 'Change Password',
	},
	lblUserMenuLogout: {
		id: 'app.common.header.usermenu.logout',
		defaultMessage: 'Logout',
	},
	lblUserMenuWelcome: {
		id: 'app.common.header.usermenu.welcome',
		defaultMessage: 'Welcome',
	},
	lblChangePasswordTitle: {
		id: 'app.common.header.changePassword.title',
		defaultMessage: 'Change your password',
	},
});
