import { defineMessages } from 'react-intl';

export default defineMessages({
	lblCountry: {
		id: 'app.common.address.country',
		defaultMessage: 'Country',
	},
	lblCountrySelect: {
		id: 'app.common.address.countrySelect',
		defaultMessage: '--- Select Country ---',
	},
	lblState: {
		id: 'app.common.address.state',
		defaultMessage: 'State',
	},
	lblStateSelect: {
		id: 'app.common.address.stateSelect',
		defaultMessage: '--- Select State ---',
	},
	lblProvince: {
		id: 'app.common.address.province',
		defaultMessage: 'Province',
	},
	lblProvinceSelect: {
		id: 'app.common.address.provinceSelect',
		defaultMessage: '--- Select Province ---',
	},
	lblAddressLine1: {
		id: 'app.common.address.addressLine1',
		defaultMessage: 'Address Line 1',
	},
	lblAddressLine2: {
		id: 'app.common.address.addressLine2',
		defaultMessage: 'Address Line 2',
	},
	lblCity: {
		id: 'app.common.address.city',
		defaultMessage: 'City',
	},
	lblPostalCode: {
		id: 'app.common.address.postalCode',
		defaultMessage: 'Postal Code',
	},
	lblAddressType: {
		id: 'app.common.address.addressType',
		defaultMessage: 'Address Type',
	},
	lblAddressTypeSelect: {
		id: 'app.common.address.addressTypeSelect',
		defaultMessage: '--- Select Type ---',
	},
});
