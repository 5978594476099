import { limeGreen, pelorous, white } from '../../../colors';

export default (theme) => ({
	tabRoot: {
		minWidth: 0,
		color: white,
		[theme.breakpoints.up('md')]: {
			minWidth: 0,
		},
	},
	tabsRoot: {
		backgroundColor: limeGreen,
	},
	tabsIndicator: {
		backgroundColor: pelorous,
	},
	tabsLabel: {
		color: white,
	},
	root: {
		display: 'inline-block',
		position: 'relative',
		width: '100%',
	},
	innerDiv: {
		overflow: 'hidden',
		fontSize: 14,
	},
});
