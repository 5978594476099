import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import { fromJS } from 'immutable';

import DatetimePicker from '../../../../components/DateTimePicker/DateTimePicker';
import DateOfBirth from '../../../../components/DateOfBirth/DateOfBirth';

import messages from './messagesTravelDocs';
import ReactSelectWrapped from '../../../../components/ReactSelectWrapped/ReactSelectWrapped';

const PhotoCardView = ({
	countries,
	travelDocsValues,
	disabled,
	errors,
	idPrefix,
	index,
	intl: { formatMessage },
	...other
}) => {
	const handleChangeField = (event) => {
		other.onChange(event.target.name, event.target.value);
	};

	const handleDOEChange = (date) => {
		other.onChange('documentExpDate', date);
	};

	const countriesList = fromJS([
		{ code: null, country: formatMessage(messages.lblCountrySelect) },
	]).concat(countries);

	const handleChangeCountry = ({ value }) => {
		other.onChange('issueCountry', value);
	};

	return (
		<div className="row">
			<div className="col-6">
				<DateOfBirth
					{...other}
					errorText={errors.DOB}
					dateOfBirth={travelDocsValues.DOB}
				/>
			</div>
			<div className="col-6">
				<TextField
					id={`${idPrefix}DocumentNumber_${index}`}
					name="documentNumber"
					label={<FormattedMessage {...messages.lblPhotoCardNumber} />}
					fullWidth
					onChange={handleChangeField}
					error={!!errors.documentNumber}
					helperText={errors.documentNumber}
					value={travelDocsValues.documentNumber}
					disabled={disabled}
					variant="standard"
				/>
			</div>
			<div className="col-6">
				<DatetimePicker
					id={`${idPrefix}DocumentExpDate_${index}`}
					name="documentExpDate"
					label={<FormattedMessage {...messages.lblDocumentExpDate} />}
					fullWidth
					errorText={errors.documentExpDate}
					value={travelDocsValues.documentExpDate}
					onChange={handleDOEChange}
					disabled={disabled}
				/>
			</div>
			<div className="col-6">
				<ReactSelectWrapped
					id={`${idPrefix}IssueCountry_${index}`}
					label={<FormattedMessage {...messages.lblCountry} />}
					InputLabelProps={{ shrink: true }}
					fullWidth
					onChange={handleChangeCountry}
					error={!!errors.issueCountry}
					helperText={errors.issueCountry}
					value={travelDocsValues.issueCountry}
					disabled={disabled}
					options={countriesList.toJS().map((item) => ({
						value: item.code,
						label: item.country,
					}))}
					hasEmpty
					windowing
				/>
			</div>
		</div>
	);
};

PhotoCardView.propTypes = {
	travelDocsValues: PropTypes.shape({
		DOB: PropTypes.instanceOf(Date),
		documentNumber: PropTypes.string,
		documentExpDate: PropTypes.instanceOf(Date),
		issueCountry: PropTypes.string,
	}).isRequired,
	countries: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	errors: PropTypes.object,
	idPrefix: PropTypes.string,
	index: PropTypes.number,
	intl: PropTypes.object,
};

export { PhotoCardView as PhotoCardViewAlias };
export default injectIntl(PhotoCardView);
