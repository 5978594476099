import fetch from 'isomorphic-fetch';
import qs from 'qs';
import {
	setAccessToken,
	setAccessTokenExpiryTime,
} from './actions/authActions';
import { LOCAL_STORAGE_KEYS } from './constants/constants';
import { store, userLogout } from './store';
import { getHomePageUrl, BASE_PATH_SUFFIX } from './utils';
import { gaEventApiCall } from './utils/googleAnalytics';

const methods = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

const responseTypes = {
	TEXT: 'text',
	JSON: 'json',
	BINARY: 'binary',
};

const endpoints = {
	confirmBookingChange: {
		path: 'seat-reservation-amendments/booking-change',
		method: methods.POST,
	},
	getRefundOffers: {
		path: 'refund-offers',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	getJourneysList: {
		path: 'seat-reservation-amendments/shopping-search',
		method: methods.GET,
	},
	getAccountUsers: {
		path: 'getAccountUsersJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getUserProfile: {
		path: 'getUserProfileAMJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	addUserProfile: {
		path: 'addUserProfileAMJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	updateUserProfile: {
		path: 'modifyUserProfileAMJSONP',
		method: methods.POST,
		noRedirect: true,
		responseType: responseTypes.JSON,
	},
	getUserSettings: {
		path: 'widgets/data/getDataAndPropertiesJSON',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	userSearch: {
		path: 'userSearchJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getRoles: {
		path: 'roles',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	deleteUser: {
		path: 'deleteUserAMJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	accountSearch: {
		path: 'accountSearchJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getAccountGroupData: {
		path: 'accountGroupDataJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getAccountGroupLabels: {
		path: 'accountGroupLabelsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getAccountLabels: {
		path: 'accountLabelsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	submitAccountGroupData: {
		path: 'accountGroupCreateEditJSONP',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	deleteAccountGroup: {
		path: 'deleteAccountGroupAMJSONP',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	getAccountData: {
		path: 'accountDataJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	submitAccountData: {
		path: 'accountCreateEditJSONP',
		method: methods.POST,
		noRedirect: true,
		responseType: responseTypes.JSON,
	},
	deleteAccount: {
		path: 'deleteAccountAMJSONP',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	logOut: {
		path: 'logoutJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	changeUserPassword: {
		path: 'changePasswordAMJSONP',
		method: methods.POST,
		noRedirect: true,
		responseType: responseTypes.JSON,
	},
	stationSearch: {
		path: 'stationSearchJSON',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	refreshUserState: {
		path: 'refreshUserStateJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	shoppingSearch: {
		path: 'shoppingSearchJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	bookingValidate: {
		path: 'bookingValidateJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	tdoSmsCallingCodes: {
		path: 'bookingGetTDOSMSCallingCodesJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	bookingCreate: {
		path: 'bookingCreateJSONP',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	bookingSetTdo: {
		path: 'bookingSetTDOOptionsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	updateCustomInfo: {
		path: 'bookingUpdateCustomInfoJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	bookingSearch: {
		path: 'bookingsSearchJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	loadBookingDetails: {
		path: 'bookingSearchDetailsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	addBookingNote: {
		path: 'bookingAddNoteJSONP',
		method: methods.POST,
		noRedirect: true,
		responseType: responseTypes.JSON,
	},
	addSupplierNote: {
		path: 'bookingAddSupplierNoteJSONP',
		method: methods.POST,
		noRedirect: true,
		responseType: responseTypes.JSON,
	},
	addCustomInfo: {
		path: 'bookingAddCustomInformationJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getPaymentDue: {
		path: 'bookingGetPaymentsDueJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	submitPayment: {
		path: 'bookingAddPaymentJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getOrderDetails: {
		path: 'bookingOrderDetailsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getCancelOrderDetails: {
		path: 'bookingCancellationTicketableFareGroup',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	applyFeeDiscount: {
		path: 'updateBookingApplyFeeDiscountJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	submitCancelBooking: {
		path: 'cancelBooking',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	submitOrderCancel: {
		path: 'bookingSearchCancelOrdersJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getCancellationPenalty: {
		path: 'bookingCancellationPenalty',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getRefundDetails: {
		path: 'bookingSearchRefundablePaymentsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	submitRefund: {
		path: 'updateBookingIssueRefundJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	bookingAddOrder: {
		path: 'bookingAddOrderJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getConfirmDetails: {
		path: 'bookingSearchConfirmDataJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	confirmOrder: {
		path: 'bookingConfirmOrderJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	addFeeDiscount: {
		path: 'updateBookingApplyFeeDiscountJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	amendCTR: {
		path: 'bookingAmmendCTRJSONP',
		method: methods.GET,
		responseType: responseTypes.TEXT,
	},
	loadEmailConfirmation: {
		path: 'bookingGetEmailConfirmationDataJSON',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	sendEmailConfirmation: {
		path: 'bookingEmailConfirmationSend',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getTrackingInfo: {
		path: 'bookingGetTrackingInfoJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	setTrackingInfo: {
		path: 'bookingSetTrackingInfoJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getCoupons: {
		path: 'bookingSearchCouponDetailsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	setCoupons: {
		path: 'bookingReceiveCouponsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getStationTOD: {
		path: 'stationTODRequestJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getSmartCardTdoStations: {
		path: 'smartCardStationsRequestJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getStationData: {
		path: 'stationDataRequestJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getAccessToken: {
		path: 'authenticate',
		method: methods.POST,
		responseType: responseTypes.JSON,
		noRedirect: true,
	},
	getRefreshToken: {
		path: 'authenticate/refresh',
		method: methods.POST,
		responseType: responseTypes.JSON,
		noRedirect: true,
	},
	resetUserPassword: {
		path: 'resetPasswordAMJSONP',
		method: methods.GET,
		noRedirect: true,
	},
	resetUserPasswordTokenCheck: {
		path: 'resetPasswordTokenCheckJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
		noRedirect: true,
	},
	forgotPassword: {
		path: 'forgotPasswordAMJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	isAuth: {
		path: 'isAuthJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getAuthRefunds: {
		path: 'bookingSearchIssuedCouponDetailsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	setAuthRefunds: {
		path: 'bookingAuthorizeRefundJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	acceptTandC: {
		path: 'acceptTandCJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	sendTicket: {
		path: 'bookingSendClaimValueDocumentUrlJSONP',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	resendClaimTicket: {
		path: 'bookingResendClaimVDUrlJSONP',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	getExchangeOrderDetails: {
		path: 'bookingOrderExchangeDataJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	updateCardCollection: {
		path: 'updateCardCollection',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	exchangeSearch: {
		path: 'bookingExchangeSearchJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	exchangeSummary: {
		path: 'exchangeSummaryJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	processExchange: {
		path: 'processExchangeJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getAnalytics: {
		path: 'widgets/data/getAnalyticsJSON',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getPaymentData: {
		path: 'paymentDataJSON',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	deletePersonalInformation: {
		path: 'bookingDeletePersonalInformationJSON',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	submitPassengerInformation: {
		path: 'updateBookingPassengerInformationJSON',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	updatePassengerCustomInformation: {
		path: 'updatePassengerCustomInformationJSON',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	getIntermediateTravelPoints: {
		path: 'intermediateTravelpointsJSONP',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	getExportedUsers: {
		path: 'exportUsersJSONP',
		method: methods.GET,
		responseType: responseTypes.BINARY,
	},
	getFeatureToggles: {
		path: 'whitelabel/allFeatureToggles',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
	toggleFeature: {
		path: 'whitelabel/toggleFeature',
		method: methods.POST,
		responseType: responseTypes.JSON,
	},
	getSeatMap: {
		path: 'whitelabel/seatmap/transactional/v2',
		method: methods.GET,
		responseType: responseTypes.JSON,
	},
};

const resolveResponse = (response, failed, completed, noRedirect) => {
	let result = response;
	if (response.errorResponse) {
		if (
			response.errorResponse.code &&
			response.errorResponse.code === 401 &&
			!noRedirect
		) {
			window.localStorage.clear();
			window.location.href = getHomePageUrl();
			result = null;
		}

		if (failed) {
			result = failed(response);
		}
	} else if (response.error) {
		if (
			window.localStorage.getItem(LOCAL_STORAGE_KEYS.hasTriedRefresh) === 'true'
		) {
			store.dispatch(userLogout());
			// workaround for when 401 code is inaccessible but logging out is required
			setTimeout(() => {
				window.location.href = getHomePageUrl();
				result = null;
			}, 500);
		} else {
			// eslint-disable-next-line no-param-reassign
			response.errorResponse =
				typeof response.error === 'string'
					? { message: response.error }
					: response.error;
			if (failed) {
				result = failed(response);
			}
		}
	} else if (completed) {
		result = completed(response);
	}
	return result;
};

const objectToQueryString = (queryString) => {
	let queryStringSerialized = queryString;
	if (typeof queryStringSerialized === 'object') {
		queryStringSerialized = qs.stringify(queryString, {
			indices: false,
			filter: (prefix, value) =>
				value !== undefined &&
				value !== null &&
				(!Array.isArray(value) || value.length > 0)
					? value
					: undefined,
		});
	}
	return queryStringSerialized;
};

const getTokenDetails = () => {
	const state = store.getState();
	const accessToken = state.getIn(['auth', 'accessToken']) || '';
	const accessTokenExpiryTime = state.getIn(['auth', 'tokenExpiryTime']) || 0;
	return {
		accessToken,
		accessTokenExpiryTime,
	};
};

export const refreshToken = () =>
	new Promise((resolve, reject) =>
		// eslint-disable-next-line no-use-before-define
		apiCall(
			endpoints.getRefreshToken,
			{
				grant_type: 'refresh_token',
			},
			(error) => {
				store.dispatch(userLogout());
				window.location.href = getHomePageUrl();
				window.localStorage.clear();
				reject(error);
			},
			(response) => {
				const newToken = response.successResponse.data.SecurityAccessToken;
				const expiresIn = response.successResponse.data.SecurityExpiresIn;
				const currentTime = Date.now();
				const newExpiryTime = currentTime + expiresIn * 1000;

				store.dispatch(setAccessToken(newToken));
				store.dispatch(setAccessTokenExpiryTime(newExpiryTime));
				resolve(newToken);
			},
		),
	);

const srtFetch = (
	domain,
	path,
	queryString,
	failed,
	completed,
	noRedirect,
	body,
	options,
	responseType,
) => {
	const url = `${domain}${path}${queryString ? `?${objectToQueryString(queryString)}` : ''}`;
	const finalOptions = { ...options };
	const { accessToken } = getTokenDetails();

	finalOptions.headers = {
		...finalOptions.headers,
		...(accessToken &&
		path !== `${BASE_PATH_SUFFIX}${endpoints.getRefreshToken.path}`
			? { Authorization: `Bearer ${accessToken}` }
			: {}),
	};
	if (body) {
		finalOptions.body = JSON.stringify(body);
		finalOptions.headers['Content-Type'] = 'application/json';
	}
	const gaFunctions = gaEventApiCall(
		`/${path}`,
		queryString,
		failed,
		completed,
		body,
	);

	const fetchWithRetry = () =>
		fetch(
			`${url}${url.indexOf('?') === -1 ? '?' : '&'}_=${Date.now()}${Math.random()}`,
			finalOptions,
		).then((response) => {
			// this is to prevent an infinite loop
			if (
				response.status === 401 &&
				window.localStorage.getItem(LOCAL_STORAGE_KEYS.hasTriedRefresh) !==
					'true' &&
				window.localStorage.getItem(LOCAL_STORAGE_KEYS.loggedOut) !== 'true'
			) {
				window.localStorage.setItem(LOCAL_STORAGE_KEYS.hasTriedRefresh, 'true');

				return refreshToken()
					.then((newToken) => {
						if (newToken) {
							finalOptions.headers.Authorization = `Bearer ${newToken}`;
							return fetch(
								`${url}${url.indexOf('?') === -1 ? '?' : '&'}_=${Date.now()}${Math.random()}`,
								finalOptions,
							);
						}
						store.dispatch(userLogout());
						window.location.href = getHomePageUrl();
						window.localStorage.clear();
						return Promise.resolve();
					})
					.catch((error) => {
						store.dispatch(userLogout());
						window.location.href = getHomePageUrl();
						window.localStorage.clear();
						throw error;
					});
			}
			return response;
		});

	return fetchWithRetry()
		.then((response) => {
			if (responseType === responseTypes.TEXT) {
				return response.text();
			}
			if (responseType === responseTypes.JSON) {
				return response.json();
			}
			if (responseType === responseTypes.BINARY) {
				return response.arrayBuffer();
			}
			return response.json();
		})
		.then((response) =>
			resolveResponse(
				response,
				gaFunctions.failed,
				gaFunctions.completed,
				noRedirect,
			),
		)
		.catch((error) =>
			resolveResponse(
				{ error },
				gaFunctions.failed,
				gaFunctions.completed,
				noRedirect,
			),
		);
};

const apiCall = (endpoint, params, failed, completed, abortController) =>
	srtFetch(
		endpoint.domain || process.env.API_HOST,
		`${BASE_PATH_SUFFIX}${endpoint.path}`,
		endpoint.method === methods.GET && params,
		failed,
		completed,
		endpoint.noRedirect,
		endpoint.method !== methods.GET ? params : undefined,
		{
			credentials: 'include',
			method: endpoint.method,
			signal: abortController && abortController.signal,
		},
		endpoint.responseType,
	);

export const confirmBookingChange = (parameters, failed, completed) =>
	apiCall(endpoints.confirmBookingChange, parameters, failed, completed);
export const confirmRefundOffer = (offerId, parameters, failed, completed) =>
	apiCall(
		{
			...endpoints.getRefundOffers,
			path: `${endpoints.getRefundOffers.path}/${offerId}`,
		},
		parameters,
		failed,
		completed,
	);
export const getRefundOffers = (parameters, failed, completed) =>
	apiCall(endpoints.getRefundOffers, parameters, failed, completed);
export const getIntermediateTravelPoints = (parameters, failed, completed) =>
	apiCall(endpoints.getIntermediateTravelPoints, parameters, failed, completed);
export const getJourneysList = (parameters, failed, completed) =>
	apiCall(endpoints.getJourneysList, parameters, failed, completed);
export const getAccountUsers = (parameters, failed, completed) =>
	apiCall(endpoints.getAccountUsers, parameters, failed, completed);
export const getUserProfile = (parameters, failed, completed) =>
	apiCall(endpoints.getUserProfile, parameters, failed, completed);
export const addUserProfile = (parameters, failed, completed) =>
	apiCall(endpoints.addUserProfile, parameters, failed, completed);
export const updateUserProfile = (parameters, failed, completed) =>
	apiCall(endpoints.updateUserProfile, parameters, failed, completed);
export const getUserSettings = (widgetList) =>
	apiCall(endpoints.getUserSettings, { widgetList });
export const userSearch = (parameters, failed, completed) =>
	apiCall(endpoints.userSearch, parameters, failed, completed);
export const getRoles = (failed, completed) =>
	apiCall(endpoints.getRoles, undefined, failed, completed);
export const deleteUser = (parameters, failed, completed) =>
	apiCall(endpoints.deleteUser, parameters, failed, completed);
export const accountSearch = (parameters, failed, completed) =>
	apiCall(endpoints.accountSearch, parameters, failed, completed);
export const getAccountGroupData = (parameters, failed, completed) =>
	apiCall(endpoints.getAccountGroupData, parameters, failed, completed);
export const getAccountGroupLabels = (parameters, failed, completed) =>
	apiCall(endpoints.getAccountGroupLabels, parameters, failed, completed);
export const getAccountLabels = (parameters, failed, completed) =>
	apiCall(endpoints.getAccountLabels, parameters, failed, completed);
export const submitAccountGroupData = (parameters, failed, completed) =>
	apiCall(endpoints.submitAccountGroupData, parameters, failed, completed);
export const deleteAccountGroup = (parameters, failed, completed) =>
	apiCall(endpoints.deleteAccountGroup, parameters, failed, completed);
export const getAccountData = (parameters, failed, completed) =>
	apiCall(endpoints.getAccountData, parameters, failed, completed);
export const submitAccountData = (parameters, failed, completed) =>
	apiCall(endpoints.submitAccountData, parameters, failed, completed);
export const deleteAccount = (parameters, failed, completed) =>
	apiCall(endpoints.deleteAccount, parameters, failed, completed);
export const logOut = (failed, completed) =>
	apiCall(endpoints.logOut, undefined, failed, completed);
export const changeUserPassword = (parameters, failed, completed) =>
	apiCall(endpoints.changeUserPassword, parameters, failed, completed);
// SHOPPING CALLS
export const stationSearch = (parameters, failed, completed, abortController) =>
	apiCall(
		endpoints.stationSearch,
		parameters,
		failed,
		completed,
		abortController,
	);
export const refreshUserState = (failed, completed) =>
	apiCall(endpoints.refreshUserState, undefined, failed, completed);
export const shoppingSearch = (parameters, failed, completed) =>
	apiCall(endpoints.shoppingSearch, parameters, failed, completed);
export const bookingValidate = (parameters, failed, completed) =>
	apiCall(endpoints.bookingValidate, parameters, failed, completed);
export const tdoSmsCallingCodes = (parameters, failed, completed) =>
	apiCall(endpoints.tdoSmsCallingCodes, parameters, failed, completed);
export const bookingCreate = (parameters, failed, completed) =>
	apiCall(endpoints.bookingCreate, parameters, failed, completed);
export const bookingSetTdo = (parameters, failed, completed) =>
	apiCall(endpoints.bookingSetTdo, parameters, failed, completed);
export const updateCustomInfo = (parameters, failed, completed) =>
	apiCall(endpoints.updateCustomInfo, parameters, failed, completed);
// MANAGE CALLS
export const bookingSearch = (parameters, failed, completed) =>
	apiCall(endpoints.bookingSearch, parameters, failed, completed);
export const loadBookingDetails = (parameters, failed, completed) =>
	apiCall(endpoints.loadBookingDetails, parameters, failed, completed);
export const addBookingNote = (parameters, failed, completed) =>
	apiCall(endpoints.addBookingNote, parameters, failed, completed);
export const addSupplierNote = (parameters, failed, completed) =>
	apiCall(endpoints.addSupplierNote, parameters, failed, completed);
export const addCustomInfo = (parameters, failed, completed) =>
	apiCall(endpoints.addCustomInfo, parameters, failed, completed);
export const getPaymentDue = (parameters, failed, completed) =>
	apiCall(endpoints.getPaymentDue, parameters, failed, completed);
export const submitPayment = (parameters, failed, completed) =>
	apiCall(endpoints.submitPayment, parameters, failed, completed);
export const getOrderDetails = (parameters, failed, completed) =>
	apiCall(endpoints.getOrderDetails, parameters, failed, completed);
export const getCancelOrderDetails = (parameters, failed, completed) =>
	apiCall(endpoints.getCancelOrderDetails, parameters, failed, completed);
export const applyFeeDiscount = (parameters, failed, completed) =>
	apiCall(endpoints.applyFeeDiscount, parameters, failed, completed);
export const submitCancelBooking = (parameters, failed, completed) =>
	apiCall(endpoints.submitCancelBooking, parameters, failed, completed);
export const submitOrderCancel = (parameters, failed, completed) =>
	apiCall(endpoints.submitOrderCancel, parameters, failed, completed);
export const getCancellationPenalty = (parameters, failed, completed) =>
	apiCall(endpoints.getCancellationPenalty, parameters, failed, completed);
export const getRefundDetails = (parameters, failed, completed) =>
	apiCall(endpoints.getRefundDetails, parameters, failed, completed);
export const submitRefund = (parameters, failed, completed) =>
	apiCall(endpoints.submitRefund, parameters, failed, completed);
export const bookingAddOrder = (parameters, failed, completed) =>
	apiCall(endpoints.bookingAddOrder, parameters, failed, completed);
export const getConfirmDetails = (parameters, failed, completed) =>
	apiCall(endpoints.getConfirmDetails, parameters, failed, completed);
export const confirmOrder = (parameters, failed, completed) =>
	apiCall(endpoints.confirmOrder, parameters, failed, completed);
export const addFeeDiscount = (parameters, failed, completed) =>
	apiCall(endpoints.addFeeDiscount, parameters, failed, completed);
export const amendCTR = (parameters, failed, completed) =>
	apiCall(endpoints.amendCTR, parameters, failed, completed);
export const loadEmailConfirmation = (parameters, failed, completed) =>
	apiCall(endpoints.loadEmailConfirmation, parameters, failed, completed);
export const sendEmailConfirmation = (parameters, failed, completed) =>
	apiCall(endpoints.sendEmailConfirmation, parameters, failed, completed);
export const getTrackingInfo = (parameters, failed, completed) =>
	apiCall(endpoints.getTrackingInfo, parameters, failed, completed);
export const setTrackingInfo = (parameters, failed, completed) =>
	apiCall(endpoints.setTrackingInfo, parameters, failed, completed);
export const getCoupons = (parameters, failed, completed) =>
	apiCall(endpoints.getCoupons, parameters, failed, completed);
export const setCoupons = (parameters, failed, completed) =>
	apiCall(endpoints.setCoupons, parameters, failed, completed);
export const getStationTOD = (parameters, failed, completed) =>
	apiCall(endpoints.getStationTOD, parameters, failed, completed);
export const getSmartCardTdoStations = (parameters, failed, completed) =>
	apiCall(endpoints.getSmartCardTdoStations, parameters, failed, completed);
export const getStationData = (parameters, failed, completed) =>
	apiCall(endpoints.getStationData, parameters, failed, completed);
export const getAccessToken = (parameters, failed, completed) =>
	apiCall(endpoints.getAccessToken, parameters, failed, completed);
export const getRefreshToken = (parameters, failed, completed) =>
	apiCall(endpoints.getRefreshToken, parameters, failed, completed);
export const resetUserPassword = (parameters, failed, completed) =>
	apiCall(endpoints.resetUserPassword, parameters, failed, completed);
export const resetUserPasswordTokenCheck = (parameters, failed, completed) =>
	apiCall(endpoints.resetUserPasswordTokenCheck, parameters, failed, completed);
export const forgotPassword = (parameters, failed, completed) =>
	apiCall(endpoints.forgotPassword, parameters, failed, completed);
export const isAuth = (failed, completed) =>
	apiCall(endpoints.isAuth, undefined, failed, completed);
export const getAuthRefunds = (parameters, failed, completed) =>
	apiCall(endpoints.getAuthRefunds, parameters, failed, completed);
export const setAuthRefunds = (parameters, failed, completed) =>
	apiCall(endpoints.setAuthRefunds, parameters, failed, completed);
export const acceptTandC = (parameters, failed, completed) =>
	apiCall(endpoints.acceptTandC, parameters, failed, completed);
export const resendClaimTicket = (parameters, failed, completed) =>
	apiCall(endpoints.resendClaimTicket, parameters, failed, completed);
export const sendTicket = (parameters, failed, completed) =>
	apiCall(endpoints.sendTicket, parameters, failed, completed);
export const getExchangeOrderDetails = (parameters, failed, completed) =>
	apiCall(endpoints.getExchangeOrderDetails, parameters, failed, completed);
export const exchangeSearch = (parameters, failed, completed) =>
	apiCall(endpoints.exchangeSearch, parameters, failed, completed);
export const exchangeSummary = (parameters, failed, completed) =>
	apiCall(endpoints.exchangeSummary, parameters, failed, completed);
export const processExchange = (parameters, failed, completed) =>
	apiCall(endpoints.processExchange, parameters, failed, completed);
export const getAnalytics = () => apiCall(endpoints.getAnalytics);
export const getPaymentData = (parameters, failed, completed) =>
	apiCall(endpoints.getPaymentData, parameters, failed, completed);
export const deletePersonalInformation = (parameters, failed, completed) =>
	apiCall(endpoints.deletePersonalInformation, parameters, failed, completed);
export const submitPassengerInformation = (parameters, failed, completed) =>
	apiCall(endpoints.submitPassengerInformation, parameters, failed, completed);
export const updatePassengerCustomInformation = (
	parameters,
	failed,
	completed,
) =>
	apiCall(
		endpoints.updatePassengerCustomInformation,
		parameters,
		failed,
		completed,
	);
export const updateCardCollection = (parameters, failed, completed) =>
	apiCall(endpoints.updateCardCollection, parameters, failed, completed);
export const getFeatureToggles = (failed, completed) =>
	apiCall(endpoints.getFeatureToggles, undefined, failed, completed);
export const toggleFeature = (parameters, failed, completed) =>
	apiCall(endpoints.toggleFeature, parameters, failed, completed);
export const getSeatMap = (parameters, failed, completed) =>
	apiCall(endpoints.getSeatMap, parameters, failed, completed);
export const getExportedUsers = (parameters, failed, completed) =>
	apiCall(endpoints.getExportedUsers, parameters, failed, completed);
