import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './messagesNotes';
import NotesDialog from './NotesDialog';
import SupplierNotesTable from './SupplierNotesTable';

import { addSupplierNoteApi } from '../../apiBooking';

const SupplierNotesDialog = (props) => {
	const { orderId, booking, onClose } = props;
	const { formatMessage } = props.intl;

	const title = formatMessage(messages.lblSupplierNotes);
	const constraints = {
		note: {
			presence: {
				message: formatMessage(messages.errNoteEmpty),
				allowEmpty: false,
			},
			length: {
				maximum: 50,
				tooLong: formatMessage(messages.errSupplierNoteTooLong),
			},
		},
	};
	const noteAddedAlert = formatMessage(messages.okSupplierNoteAdded);

	const bookingOrder = orderId
		? booking.BookingOrders.find((item) => item.BookingOrderID === orderId)
		: booking.BookingOrders[0];
	const supplierNotes = bookingOrder.BookingOrderSupplierNotes ?? [];

	const filterNotes = (filterBy) =>
		supplierNotes.filter((note) => note.toLowerCase().indexOf(filterBy) !== -1);

	const addNote = (queryItems, data, error, success) => {
		addSupplierNoteApi(
			{
				queryItems,
				orderId,
				note: data.get('note'),
			},
			error,
			success,
		);
	};

	return (
		<NotesDialog
			open={!!orderId}
			title={title}
			booking={booking}
			notes={supplierNotes}
			constraints={constraints}
			filterNotes={filterNotes}
			addNote={addNote}
			noteAddedAlert={noteAddedAlert}
			onClose={onClose}
			NotesTableComponent={SupplierNotesTable}
			notesTableId="srtSupplierNotesTable"
			editSupported={
				bookingOrder.BookingOrderSupplierNotesSupported === 'MODIFY'
			}
		/>
	);
};

SupplierNotesDialog.propTypes = {
	intl: PropTypes.object,
	orderId: PropTypes.string,
	booking: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export { SupplierNotesDialog as SupplierNotesDialogAlias };

export default injectIntl(SupplierNotesDialog);
