import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { injectIntl, FormattedMessage } from 'react-intl';

import messages from './messagesIssueRefund';
import BillingExternalProviderFields from './BillingExternalProviderFields';
import RefundAmountFields from './RefundAmountFields';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { formatPrice, getCurrency } from '../../../utils';
import inlineStyles from './styles';

const StyledLabelHighlightedDiv = styled('div')({
	...inlineStyles.labelHighlighted,
});

const IssueRefundCreditDebitCard = ({
	id,
	disabled,
	intl,
	onUpdateRefundAmount,
	receiptDetails,
	refundAmount: amountReturned,
	refundCurrency,
	showWelcomeConfirmation,
}) => {
	const currency = getCurrency(refundCurrency, intl);

	return (
		<div className="container-fluid">
			<div className="row">
				{receiptDetails ? (
					<StyledLabelHighlightedDiv className="col-12">
						{showWelcomeConfirmation ? (
							<FormattedMessage
								{...messages.lblWelcomeConfirmation}
								values={{
									amount: formatPrice(amountReturned, refundCurrency, intl),
								}}
							/>
						) : (
							<>
								<TextField
									id={`${id}_ReceiptNumber`}
									name=""
									label={<FormattedMessage {...messages.lblReceiptNumber} />}
									fullWidth
									value={
										receiptDetails.BookingBillingPaymentReceiptNumber || ''
									}
									disabled
									variant="standard"
								/>
								<TextField
									id={`${id}_PaymentDate`}
									label={<FormattedMessage {...messages.lblPaymentDate} />}
									fullWidth
									value={receiptDetails.BookingBillingPaymentDate || ''}
									disabled
									variant="standard"
								/>
								<TextField
									id={`${id}_AmountPaid`}
									label={<FormattedMessage {...messages.lblAmountPaid} />}
									fullWidth
									value={
										receiptDetails.BookingBillingPaymentDetailCurrency
											? formatPrice(
													receiptDetails.BookingBillingPaymentDetailAmount,
													receiptDetails.BookingBillingPaymentDetailCurrency,
													intl,
												)
											: ''
									}
									disabled
									variant="standard"
								/>
								<TextField
									id={`${id}_Balance`}
									label={<FormattedMessage {...messages.lblBalance} />}
									fullWidth
									value={
										receiptDetails.BookingBillingPaymentDetailCurrency
											? formatPrice(
													receiptDetails.BookingBillingPaymentDetailBalance,
													receiptDetails.BookingBillingPaymentDetailCurrency,
													intl,
												)
											: ''
									}
									disabled
									variant="standard"
								/>
								{receiptDetails.BookingBillingExternalProvider ? (
									<BillingExternalProviderFields
										id={id}
										receiptDetails={receiptDetails}
									/>
								) : (
									<>
										<TextField
											id={`${id}_PaymentProcessor`}
											label={
												<FormattedMessage {...messages.lblPaymentProcessor} />
											}
											fullWidth
											value={
												receiptDetails.BookingBillingPaymentDetailPaymentProcessor ||
												''
											}
											disabled
											variant="standard"
										/>
										<TextField
											id={`${id}_ApprovalNumber`}
											label={
												<FormattedMessage {...messages.lblApprovalNumber} />
											}
											fullWidth
											value={
												receiptDetails.BookingBillingPaymentDetailApprovalNumber ||
												''
											}
											disabled
											variant="standard"
										/>
									</>
								)}
								<TextField
									id={`${id}_PaymentMethod`}
									label={<FormattedMessage {...messages.lblPaymentMethod} />}
									fullWidth
									value={receiptDetails.BookingBillingPaymentDetailMethod || ''}
									disabled
									variant="standard"
								/>
								<RefundAmountFields
									id={id}
									amountReturned={amountReturned}
									currency={currency}
									disabled={disabled}
									onUpdateRefundAmount={onUpdateRefundAmount}
								/>
							</>
						)}
					</StyledLabelHighlightedDiv>
				) : null}
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={disabled ? 'loading' : 'hide'}
					style={inlineStyles.refreshIndicator}
				/>
			</div>
		</div>
	);
};

IssueRefundCreditDebitCard.propTypes = {
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	intl: PropTypes.object,
	onUpdateRefundAmount: PropTypes.func,
	receiptDetails: PropTypes.object,
	refundAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	refundCurrency: PropTypes.string,
	showWelcomeConfirmation: PropTypes.bool.isRequired,
};

export { IssueRefundCreditDebitCard as IssueRefundAlias };
export default injectIntl(IssueRefundCreditDebitCard);
