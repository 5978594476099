import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.login.loginForm.titleSignIn',
		defaultMessage: 'Please sign in',
	},
	lblResetPassword: {
		id: 'app.login.resetPassword.title',
		defaultMessage: 'Reset Your Password',
	},
	lblUsername: {
		id: 'app.login.loginForm.username',
		defaultMessage: 'Username',
	},
	lblPassword: {
		id: 'app.login.loginForm.password',
		defaultMessage: 'Password',
	},
	btnSignIn: {
		id: 'app.login.loginForm.signIn',
		defaultMessage: 'Sign In',
	},
	lblForgotPassword: {
		id: 'app.login.loginForm.forgotPassword',
		defaultMessage: 'Forgot Password',
	},
	errUsernameEmpty: {
		id: 'app.login.loginForm.usernameEmpty',
		defaultMessage: '^Please enter your username.',
	},
	errPasswordEmpty: {
		id: 'app.login.loginForm.passwordEmpty',
		defaultMessage: '^Please enter your password.',
	},
	errPerformLogin: {
		id: 'app.login.loginForm.errPerformLogin',
		defaultMessage:
			'Could not sign in. Please check your credentials and try again.',
	},
	errLoginAcceptTerms: {
		id: 'app.login.loginForm.errLoginAcceptTerms',
		defaultMessage:
			'Internal Application Error. Please contact your SilverAgent system administrator (unexpected return from login).',
	},
});
