import { defineMessages } from 'react-intl';

export default defineMessages({
	lblGender: {
		id: 'app.shopping.createBooking.travelDocs.gender',
		defaultMessage: 'Gender',
	},
	lblDocumentNumber: {
		id: 'app.shopping.createBooking.travelDocs.lblDocumentNumber',
		defaultMessage: 'Document Number',
	},
	lblPhotoCardNumber: {
		id: 'app.shopping.createBooking.travelDocs.lblPhotoCardNumber',
		defaultMessage: 'Photocard Number',
	},
	lblDocumentExpDate: {
		id: 'app.shopping.createBooking.travelDocs.lblDocumentExpDate',
		defaultMessage: 'Expiration Date',
	},
	lblCountry: {
		id: 'app.shopping.createBooking.travelDocs.country',
		defaultMessage: 'Country',
	},
	lblCountrySelect: {
		id: 'app.shopping.createBooking.travelDocs.countrySelect',
		defaultMessage: '--- Issuing Country ---',
	},
	lblDocumentType: {
		id: 'app.shopping.createBooking.travelDocs.documentType',
		defaultMessage: 'Travel Document Type',
	},
	lblDocumentTypeSelect: {
		id: 'app.shopping.createBooking.travelDocs.documentTypeSelect',
		defaultMessage: '--- Travel Document Type ---',
	},
});
