import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import messages from '../messagesReservations';
import '../reservations.css';

const Seats = ({
	price,
	formattedPrice,
	enabled,
	handlePassengerSeatsToggle,
	handleLegAllSeatsToggle,
	handleTravelSegmentAllSeatsToggle,
	formatMessage,
	travelSegmentID,
	passengerId,
	legBasedReservation,
	type,
}) => {
	const id = legBasedReservation
		? `srtRequestSeat${travelSegmentID}`
		: `srtRequestSeat${travelSegmentID}_${passengerId}`;
	let handleToggle;
	if (type === 'passenger') {
		handleToggle = handlePassengerSeatsToggle;
	} else if (type === 'all') {
		handleToggle = legBasedReservation
			? handleLegAllSeatsToggle
			: handleTravelSegmentAllSeatsToggle;
	}

	return (
		<div
			className="row"
			styleName={`toggle${legBasedReservation ? ' legBased' : ''}`}
		>
			<div className="col-12">
				<FormControlLabel
					label={formatMessage(
						legBasedReservation
							? messages.lblRequestSeatsAll
							: messages.lblRequestSeats,
					)}
					control={
						<Switch
							id={id}
							checked={enabled}
							onChange={handleToggle(travelSegmentID)}
							value={price.toString()}
						/>
					}
				/>
			</div>
			<div className="col-12" styleName="rightAlign">
				{formattedPrice}
			</div>
		</div>
	);
};

Seats.propTypes = {
	price: PropTypes.number.isRequired,
	formattedPrice: PropTypes.string.isRequired,
	enabled: PropTypes.bool.isRequired,
	handlePassengerSeatsToggle: PropTypes.func,
	handleLegAllSeatsToggle: PropTypes.func,
	handleTravelSegmentAllSeatsToggle: PropTypes.func,
	formatMessage: PropTypes.func.isRequired,
	travelSegmentID: PropTypes.string.isRequired,
	passengerId: PropTypes.string,
	legBasedReservation: PropTypes.bool,
	type: PropTypes.string.isRequired,
};

export default Seats;
