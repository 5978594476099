import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatetimePicker from '../../../components/DateTimePicker/DateTimePicker';
import CouponsTF from '../Coupons/CouponsTF';

import messages from './messagesReturnCoupons';
import '../../ManageBooking/components/BookingView/styleBookingView.css';
import inlineStyles from './styles';

const ReturnCoupons = ({
	id = 'strReturnCoupons',
	intl: { formatMessage },
	couponData,
	disabled,
	errors,
	onChange,
	values,
	handleToggle,
	isSeasonTrip,
	hasRDG,
}) => {
	const { BookingCouponsWithTF: couponsWithTF, BookingCouponsNumPax: numPax } =
		couponData;

	const handleChangeField = (event) => {
		onChange({ [event.target.name]: event.target.value });
	};

	const handleChangeReceiptDate = (date) => {
		onChange({ receiptDate: date.getTime() });
	};

	const handleChangeCoupon = (number, uniqueTicketNumber, isReturned) => {
		let couponsToReturn = Array.from(values.couponsToReturn);
		let utnToReturn = Array.from(values.utnToReturn);
		if (couponsWithTF) {
			if (!couponsToReturn.includes(number) && isReturned) {
				couponsToReturn.push(number);
				utnToReturn.push(uniqueTicketNumber);
			} else {
				couponsToReturn = couponsToReturn.filter((coupon) => coupon !== number);
				utnToReturn = uniqueTicketNumber
					? utnToReturn.filter((utn) => utn !== uniqueTicketNumber)
					: utnToReturn;
			}
		} else {
			couponsToReturn[number] = isReturned;
		}
		onChange({ couponsToReturn, utnToReturn });
	};

	// combine coupons by coupon number
	const combineCoupons = (allCoupons) => {
		const combinedCoupons = {};
		allCoupons.forEach((coupon) => {
			const {
				BookingCouponsNumber: couponNumber,
				BookingCouponsType: couponType,
				BookingCouponsOrigStationName: origin,
				BookingCouponsDestStationName: destination,
			} = coupon;
			const couponTravelSegment = `${origin} ${formatMessage(messages.lblTravelTo)} ${destination}`;

			if (!combinedCoupons[couponNumber]) {
				combinedCoupons[couponNumber] = coupon;
				combinedCoupons[couponNumber].BookingCouponsSegment = [];
				combinedCoupons[couponNumber].BookingCouponsTypes = [];
			}
			combinedCoupons[couponNumber].BookingCouponsSegment.push(
				couponTravelSegment,
			);

			if (
				!combinedCoupons[couponNumber].BookingCouponsTypes.includes(couponType)
			) {
				combinedCoupons[couponNumber].BookingCouponsTypes.push(couponType);
			}
		});
		return Object.entries(combinedCoupons);
	};

	const combinedCoupons = couponsWithTF
		? combineCoupons(couponData.BookingCouponsTravelSegmentCoupons)
		: [];

	const hasUTN = combinedCoupons.some(
		([, travelSegment]) =>
			travelSegment?.BookingCouponsUniqueTicketNumber !== undefined &&
			travelSegment?.BookingCouponsUniqueTicketNumber !== null,
	);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<DatetimePicker
						id={`${id}_receiptDate`}
						name="receiptDate"
						onChange={handleChangeReceiptDate}
						errorText={errors && errors.receiptDate}
						label={<FormattedMessage {...messages.lblReceiptDate} />}
						fullWidth
						value={values.receiptDate && new Date(values.receiptDate)}
						disabled={disabled}
					/>
					<div style={inlineStyles.tableContainer}>
						{couponsWithTF ? (
							<CouponsTF
								isAuthorize={false}
								coupons={combinedCoupons}
								onChangeCoupon={handleChangeCoupon}
							/>
						) : (
							<Table styleName="bookingTable">
								<TableHead>
									<TableRow>
										<TableCell>
											<FormattedMessage {...messages.lblTravelSegments} />
										</TableCell>
										<TableCell style={inlineStyles.availableCouponsColumn}>
											<FormattedMessage {...messages.lblAvailableCoupons} />
										</TableCell>
										<TableCell style={inlineStyles.returnedCouponsColumn}>
											<FormattedMessage {...messages.lblReturnedCoupons} />
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{couponData.BookingCouponsTravelSegmentCoupons.map(
										(travelSegment, index) => {
											const availableCoupons = Math.abs(
												numPax - travelSegment.BookingCouponsIssuedNumPax,
											);
											const items = Array.from(
												{ length: availableCoupons },
												(value, i) => i + 1,
											);

											return (
												<TableRow key={`segment_${index}`}>
													<TableCell
														id={`srtReturnCouponsTableSegment_${index}`}
														styleName="bookingTableRow bookingTableRowNoScroll"
													>
														{`${travelSegment.BookingCouponsOrigStationName}
                                                    ${formatMessage(messages.lblTravelTo)}
                                                    ${travelSegment.BookingCouponsDestStationName}`}
													</TableCell>
													<TableCell styleName="bookingTableRow bookingTableRowNoScroll">
														{availableCoupons ? (
															<TextField
																id={`srtReturnCouponsTableAvailable_${index}`}
																value={values.couponsToReturn[index]}
																placeholder={formatMessage(
																	messages.lblNumberOfCoupons,
																)}
																fullWidth
																onChange={({ target: { value } }) =>
																	handleChangeCoupon(index, null, value)
																}
																select
																variant="standard"
															>
																<MenuItem
																	id={`srtCoupon_ts${index}_0`}
																	key={`coupon_ts${index}_0`}
																	value={0}
																>
																	0
																</MenuItem>
																{items.map((number) => (
																	<MenuItem
																		id={`srtCoupon_ts${index}_${number}`}
																		key={`coupon_ts${index}_${number}`}
																		value={number}
																	>
																		{number}
																	</MenuItem>
																))}
															</TextField>
														) : (
															0
														)}
													</TableCell>
													<TableCell
														id={`srtReturnCouponsTableReturned_${index}`}
														styleName="bookingTableRow bookingTableRowNoScroll"
													>
														{numPax - availableCoupons}
													</TableCell>
												</TableRow>
											);
										},
									)}
								</TableBody>
							</Table>
						)}
					</div>
					{hasRDG && (
						<div>
							<FormControlLabel
								control={
									<Switch
										id="srtReturnCoupons_forceCancel"
										value="forceCancel"
										onChange={handleToggle('forceCancel')}
									/>
								}
								label={formatMessage(messages.lblForceCancel)}
							/>
						</div>
					)}
					{isSeasonTrip && hasUTN && (
						<div>
							<FormControlLabel
								control={
									<Switch
										defaultChecked
										id="srtReturnCoupons_ReturnbyUtn"
										value="returnByUtn"
										onChange={handleToggle('returnByUtn')}
									/>
								}
								label={formatMessage(messages.lblReturnUtn)}
								id="form-control-utn"
							/>
						</div>
					)}
					<TextField
						id={`${id}_notes`}
						name="notes"
						label={<FormattedMessage {...messages.lblNotes} />}
						fullWidth
						onChange={handleChangeField}
						error={errors && !!errors.notes}
						helperText={errors && errors.notes}
						value={values.notes}
						disabled={disabled}
						multiline
						rows={4}
						rowsMax={10}
						variant="standard"
					/>
				</div>
			</div>
		</div>
	);
};

ReturnCoupons.propTypes = {
	id: PropTypes.string,
	intl: PropTypes.object,
	couponData: PropTypes.object,
	errors: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	values: PropTypes.object,
	handleToggle: PropTypes.func,
	isSeasonTrip: PropTypes.bool,
	hasRDG: PropTypes.bool,
};

export { ReturnCoupons as ReturnCouponsAlias };
export default injectIntl(ReturnCoupons);
