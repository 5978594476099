export const maxConnectionsDropdownOptions = [
	{ label: '', value: null },
	{ label: 0, value: 0 },
	{ label: 1, value: 1 },
	{ label: 2, value: 2 },
	{ label: 3, value: 3 },
	{ label: 4, value: 4 },
	{ label: 5, value: 5 },
];

export const viaAvoidDirection = {
	OUTBOUND: 'OUTBOUND',
	INBOUND: 'INBOUND',
};

export const getViaAvoidSuffix = (direction) =>
	({
		[viaAvoidDirection.OUTBOUND]: 'Outbound',
		[viaAvoidDirection.INBOUND]: 'Inbound',
	})[direction];
