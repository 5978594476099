import { red, lightGray } from '../../../colors';

export default {
	paperContainer: {
		marginBottom: 5,
		padding: '5px 5px 10px 10px',
	},
	subTitle: {
		marginTop: 5,
		marginBottom: 0,
	},
	subTitleIn: {
		marginTop: 5,
		marginBottom: -4,
	},
	columnAdjust: {
		paddingLeft: 10,
		paddingRight: 10,
		marginTop: 5,
	},
	outlineError: {
		outline: `solid ${red} 2px`,
	},
	RefreshIndicator: {
		zIndex: 2000,
		marginTop: '35%',
		marginLeft: '48%',
	},
	dialogPaper: {
		overflowY: 'visible',
		width: '100%',
		maxWidth: 940,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	dialogContent: {
		borderTop: `1px solid ${lightGray}`,
		borderBottom: `1px solid ${lightGray}`,
	},
};
