import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Checkbox from '@mui/material/Checkbox';

import messages from './messagesTermsOfService';
import inlineStyles from './styles';

const targetDBCodes = ['DBB', 'DBN'];

const TermsOfService = ({
    supplierCodes: suppliers,
    cocMap,
    onChange,
    id: containerId = 'srtMakePaymentTermsOfServiceContainer',
    disabled,
    checked,
    intl: { formatMessage },
    hasAmtrak,
}) => {
    const constructLink = (id, url, label) => (<a id={id} href={url} target="_blank" rel="noopener noreferrer" disabled={disabled}>{label}</a>);

    const termsAndConditionsLink = () => {
        const tocUrl = `${process.env.API_HOST}ui-sa/resources/js/templates/SRT-Terms-and-Conditions.html`;
        const query = suppliers.map((item) => `supplier=${item.BookingSupplierCode}`);
        return constructLink(
            'srtToCLink',
            `${tocUrl}${(query.length > 0 ? '?' : '')}${query.join('&')}`,
            formatMessage(messages.lblTermsAndConditionsLink),
        );
    };

    const conditionsOfCarriageLink = () => {
        const cocUrl = suppliers.length > 0 ? cocMap[suppliers[0].BookingSupplierCode] : undefined;
        let cocLink = '';

        if (cocUrl !== undefined) {
            cocLink = constructLink(
                'srtCoCLink',
                cocUrl,
                formatMessage(messages.lblConditionsOfCarriageLink),
            );
        }
        return cocLink;
    };

    const tocLink = termsAndConditionsLink();
    const cocLink = conditionsOfCarriageLink();
    const amtrakLink = constructLink(
        'srtAmtrakLink',
        'https://www.amtrak.com/services/contentService.ibcontentpopup.terms-and-conditions.html',
        formatMessage(messages.lblAmtrakLink),
    );

    const handleCheckField = (event, isInputChecked) => {
        onChange({ termsOfServiceCheck: isInputChecked });
    };

    const renderDBBMessage = (hasCoc) => {
        const message = (suppliers.some((supplier) => targetDBCodes.includes(supplier.BookingSupplierCode)))
            ? `\u00a0${formatMessage(messages.lblTermsAndConditionsDescAddDBB)}`
            : null;

        if (message === null && hasCoc) return '.';
        return message;
    };

    return (
        <div id={containerId}>
            <div>
                <Checkbox
                    id="srtMakePaymentTermsAndServices"
                    checked={checked}
                    onChange={handleCheckField}
                    disabled={disabled}
                    style={inlineStyles.checkbox}
                />
                <div
                    style={inlineStyles.label}
                >
                    <FormattedMessage
                        {...messages.lblTermsAndConditionsDesc}
                        values={{
                            link: tocLink,
                        }}
                    />
                    {hasAmtrak && (
                        <FormattedMessage
                            {...messages.lblTermsAndConditionsAmtrak}
                            values={{
                                link: amtrakLink,
                            }}
                        />
                    )}
                    {
                        (cocLink !== '') ? (
                            <span>
                                {'\u00a0'}
                                <FormattedMessage
                                    {...messages.lblConditionsOfCarriageDesc}
                                    values={{
                                        link: cocLink,
                                    }}
                                />
                            </span>
                        ) : '.'
                    }
                    {renderDBBMessage(cocLink !== '')}
                </div>
            </div>
        </div>
    );
};

TermsOfService.propTypes = {
    id: PropTypes.string,
    intl: PropTypes.object,
    supplierCodes: PropTypes.array.isRequired,
    cocMap: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    hasAmtrak: PropTypes.bool,
};

export { TermsOfService as TermsOfServiceAlias };
export default injectIntl(TermsOfService);
