import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'app.admin.title',
		defaultMessage: 'Admin',
	},
	addUserLabelText: {
		id: 'app.adimin.manageAccounts.addUser.autoComplete.labelText',
		defaultMessage: "Add user to associated user's list",
	},
	lblEmail: {
		id: 'app.admin.email',
		defaultMessage: 'Email Address',
	},
	errEmptyEmail: {
		id: 'app.admin.emptyEmail',
		defaultMessage: '^Please enter an email address',
	},
	errInvalidEmail: {
		id: 'app.admin.invalidEmail',
		defaultMessage:
			'^Please enter a properly formatted email address (for example: anyuser@anycompany.com)',
	},
	lblUserRole: {
		id: 'app.admin.userRole',
		defaultMessage: 'User Role',
	},
	lblUserAllowedVoidException: {
		id: 'app.admin.userAllowedVoidException',
		defaultMessage: 'Allow "Void Exception"',
	},
	lblAcceptTNC: {
		id: 'app.admin.acceptTNC',
		defaultMessage: 'Accepted T & Cs',
	},
	lblUsername: {
		id: 'app.admin.username',
		defaultMessage: 'Username',
	},
	errEmptyUsername: {
		id: 'app.admin.emptyUsername',
		defaultMessage: '^Please enter username',
	},
	errEmptyUserRole: {
		id: 'app.admin.emptyUserRole',
		defaultMessage: '^Please select user role',
	},
	lblFirstName: {
		id: 'app.admin.firstName',
		defaultMessage: 'First Name',
	},
	lblLastName: {
		id: 'app.admin.lastName',
		defaultMessage: 'Last Name',
	},
	lblSendWelcomeEmail: {
		id: 'app.admin.sendWelcomeEmail',
		defaultMessage: 'Send Welcome Email',
	},
	lblSuppliers: {
		id: 'app.admin.availableSuppliers',
		defaultMessage: 'Available Suppliers',
	},
	lblAssocGroups: {
		id: 'app.admin.associatedGroups',
		defaultMessage: 'Associated Account Groups',
	},
	lblAssocGroupsPlaceholder: {
		id: 'app.admin.lblAssocGroupsPlaceholder',
		defaultMessage: 'Add Account Group',
	},
	lblAssocGroupsLabel: {
		id: 'app.admin.lblAssocGroupsLabel',
		defaultMessage: 'Associate Account Group',
	},
	lblAssocAccounts: {
		id: 'app.admin.associatedAccounts',
		defaultMessage: 'Associated Accounts',
	},
	lblAssocAccountsPlaceholder: {
		id: 'app.admin.lblAssocAccountsPlaceholder',
		defaultMessage: 'Add Account',
	},
	lblAssocAccountsLabel: {
		id: 'app.admin.lblAssocAccountsLabel',
		defaultMessage: 'Associate Account',
	},
	btnDeleteUser: {
		id: 'app.deleteUser',
		defaultMessage: 'Delete User',
	},
	btnCancel: {
		id: 'app.cancel',
		defaultMessage: 'Cancel',
	},
	btnClear: {
		id: 'app.admin.clear',
		defaultMessage: 'Clear',
	},
	btnClose: {
		id: 'app.close',
		defaultMessage: 'Close',
	},
	btnSave: {
		id: 'app.save',
		defaultMessage: 'Save',
	},
	lblHome: {
		id: 'app.admin.home',
		defaultMessage: 'Home',
	},
	lblLastLoginTime: {
		id: 'app.common.header.userProfile.lastLoginTime',
		defaultMessage: 'Last Successful Login',
	},
	lblUserStatus: {
		id: 'app.common.header.userProfile.userStatus',
		defaultMessage: 'Status',
	},
	selectUserStatus: {
		id: 'app.common.header.userProfile.selectUserStatus',
		defaultMessage: '{userStatus}',
	},
	lblFeatureToggles: {
		id: 'app.admin.featureToggles',
		defaultMessage: 'Application Feature Toggles',
	},
});
