import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from './messagesTicketDelivery';
import { getTdoByType } from './utils';
import TdoByType from './TdoByType';

export default function TicketDeliveryView(props) {
	const {
		canadianProvinces,
		countries,
		errors,
		isFetching,
		onCloseStationInformationDialog,
		onCloseStationsTicketDialog,
		onOpenStationInformationDialog,
		onOpenStationsTicketDialog,
		onPickUpStationChange,
		onSmartCardNumberChange,
		onSmsCountryChange,
		onSmsNumberChange,
		onTdoAddressChange,
		onTdoChange,
		onTdoMailNameChange,
		onTdoPaperEmailChange,
		onTdoRegionChange,
		passengers,
		regionMaps,
		smartCardStations,
		smsCodeOptions,
		states,
		stationCode,
		stationInformationDialogOpen,
		stationName,
		stationsTicketDialogOpen,
		suppliers,
		tdo,
		tdoMailAddress,
		tdoMailName,
		tdoPickUpStation,
		tdOptions,
		tdoPaperEmail,
		tdoRegion,
		tdoSmartCardNumber,
		tdoSmsCountry,
		tdoSmsNumber,
	} = props;

	const tdoByType = getTdoByType(tdOptions.toJS ? tdOptions.toJS() : []);
	const tdoByMail = tdoRegion
		? tdoByType.mailToOptions.filter(
				(item) => item.BookingTDODestination === tdoRegion,
			)
		: [];
	const tdoTypesToRender = [
		{
			tdos: tdoByType.electronicOptions,
			title: messages.lblTdoElectronicTitle,
		},
		{
			tdos: tdoByType.smartCardOptions,
			title: messages.lblTdoSmartCardTitle,
		},
		{
			tdos: tdoByType.eTicketOptions,
			title: messages.lblTdoETicket,
		},
		{
			tdos: tdoByType.pickupAtStationOptions,
			title: messages.lblTdoPickupTitle,
		},
		{
			tdos: tdoByType.printOptions,
			title: messages.lblTdoPrintTitle,
		},
		{
			tdos: tdoByType.mailToOptions,
			title: messages.lblTdoMailTitle,
			isTdoMail: true,
		},
		{
			tdos: tdoByType.multiTicketOptions,
			title: messages.lblTdoMultiTicketTitle,
		},
	];
	return tdoTypesToRender.map(
		(tdoType, index) =>
			!!tdoType.tdos.length && (
				<TdoByType
					key={index}
					canadianProvinces={canadianProvinces}
					countries={countries}
					errors={errors}
					isFetching={isFetching}
					isTdoMail={tdoType.isTdoMail || false}
					onCloseStationInformationDialog={onCloseStationInformationDialog}
					onCloseStationsTicketDialog={onCloseStationsTicketDialog}
					onOpenStationInformationDialog={onOpenStationInformationDialog}
					onOpenStationsTicketDialog={onOpenStationsTicketDialog}
					onPickUpStationChange={onPickUpStationChange}
					onSmartCardNumberChange={onSmartCardNumberChange}
					onSmsCountryChange={onSmsCountryChange}
					onSmsNumberChange={onSmsNumberChange}
					onTdoAddressChange={onTdoAddressChange}
					onTdoChange={onTdoChange}
					onTdoMailNameChange={onTdoMailNameChange}
					onTdoPaperEmailChange={onTdoPaperEmailChange}
					onTdoRegionChange={onTdoRegionChange}
					passengers={passengers}
					regionMaps={regionMaps}
					smartCardStations={smartCardStations}
					smsCodeOptions={smsCodeOptions}
					states={states}
					stationCode={stationCode}
					stationInformationDialogOpen={stationInformationDialogOpen}
					stationName={stationName}
					stationsTicketDialogOpen={stationsTicketDialogOpen}
					suppliers={suppliers}
					tdo={tdo}
					tdoMailAddress={tdoMailAddress}
					tdoMailName={tdoMailName}
					tdoPaperEmail={tdoPaperEmail}
					tdoPickUpStation={tdoPickUpStation}
					tdOptions={tdoType.isTdoMail ? tdoByMail : tdoType.tdos}
					tdoRegion={tdoRegion}
					tdoRegions={tdoByType.tdoRegions}
					tdoSmartCardNumber={tdoSmartCardNumber}
					tdoSmsCountry={tdoSmsCountry}
					tdoSmsNumber={tdoSmsNumber}
					title={<FormattedMessage {...tdoType.title} />}
				/>
			),
	);
}

TicketDeliveryView.propTypes = {
	canadianProvinces: PropTypes.object,
	countries: PropTypes.object,
	errors: PropTypes.object,
	isFetching: PropTypes.bool,
	onCloseStationInformationDialog: PropTypes.func,
	onCloseStationsTicketDialog: PropTypes.func,
	onOpenStationInformationDialog: PropTypes.func,
	onOpenStationsTicketDialog: PropTypes.func,
	onPickUpStationChange: PropTypes.func,
	onSmartCardNumberChange: PropTypes.func,
	onSmsCountryChange: PropTypes.func,
	onSmsNumberChange: PropTypes.func,
	onTdoAddressChange: PropTypes.func,
	onTdoChange: PropTypes.func,
	onTdoMailNameChange: PropTypes.func,
	onTdoPaperEmailChange: PropTypes.func,
	onTdoRegionChange: PropTypes.func,
	passengers: PropTypes.object,
	preSelectedTdo: PropTypes.object,
	regionMaps: PropTypes.object,
	smartCardStations: PropTypes.object,
	smsCodeOptions: PropTypes.array,
	states: PropTypes.object,
	stationCode: PropTypes.string,
	stationInformationDialogOpen: PropTypes.bool,
	stationName: PropTypes.string,
	stationsTicketDialogOpen: PropTypes.bool,
	suppliers: PropTypes.array,
	tdo: PropTypes.string,
	tdoMailAddress: PropTypes.object,
	tdoMailName: PropTypes.string,
	tdoPaperEmail: PropTypes.string,
	tdoPickUpStation: PropTypes.object,
	tdOptions: PropTypes.object.isRequired,
	tdoRegion: PropTypes.string,
	tdoSmartCardNumber: PropTypes.object,
	tdoSmsCountry: PropTypes.string,
	tdoSmsNumber: PropTypes.string,
};
