import { pelorous } from '../../../../colors';

export default {
	contentContainer: {
		padding: '10px 20px 30px 20px',
		marginTop: 20,
	},
	indicator: {
		marginTop: '15%',
		marginLeft: '49%',
	},
	table: {
		propLink: {
			textDecoration: 'underline',
			cursor: 'pointer',
			color: pelorous,
		},
	},
	cellNoBorderStyle: {
		borderBottom: 'none',
		verticalAlign: 'top',
		paddingBottom: 0,
		marginBottom: 0,
	},
	noBottomMargin: {
		marginBottom: 0,
		paddingBottom: 0,
		borderBottom: 'none',
		marginTop: 0,
		paddingTop: 0,
		borderTop: 'none',
	},
};
