import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';

import messages from './messagesDatetimeUtils';

export function getLocale(intl) {
	return {
		'en-US': enUS,
		'en-GB': enGB,
		'fr-FR': fr,
	}[intl.locale];
}

export function getDateTimeFormat24H(intl, is24h) {
	return {
		'en-US': is24h ? 'MM/DD/YYYY HH:mi:ss' : 'MM/DD/YYYY hh:mi:ss',
		'en-GB': is24h ? 'DD/MM/YYYY HH:mi:ss' : 'MM/DD/YYYY hh:mi:ss',
		'fr-FR': is24h ? 'DD/MM/YYYY HH:mi:ss' : 'MM/DD/YYYY hh:mi:ss',
	}[intl.locale];
}

export const cleanDate = (dateStr) =>
	// in IE react intl formatDate puts LEFT-TO-RIGHT MARK (U+200E) unicode character inside the date string
	// which messes up the regex matching for the date format
	dateStr.replace(/\u200e/g, '');

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
export const dateStringToLocalTimezoneStr = (
	formatDate,
	dateStr,
	dateformatjson,
) => (dateStr ? formatDate(new Date(dateStr), dateformatjson) : '');

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
export const formatDateStrToDDMONYYYYHMSSTZ = (formatDate, dateStr) =>
	dateStringToLocalTimezoneStr(formatDate, dateStr, {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZoneName: 'short',
	});

/**
 * will return a Date object if the passed in dateStr is valid (conforms to the expected format).
 * @param dateStr
 * @returns {*}
 */
export const dateStrToDate = (dateStr, dateFormatString) => {
	const dateFormat = dateFormatString.toLowerCase();
	// in IE react intl formatDate puts LEFT-TO-RIGHT MARK (U+200E) unicode character inside the date string
	// which messes up the regex matching for the date format
	const cleanDateStr = cleanDate(dateStr);
	let composedDate = null;
	let d = 0;
	let m = 0;
	let y = 0;

	if (dateFormat.indexOf('dd/mm/yyyy') !== -1) {
		const matches = /^(\d{2})[-/](\d{2})[-/](\d{4})$/.exec(cleanDateStr);
		if (matches === null) {
			return null;
		}
		d = parseInt(matches[1], 10);
		m = parseInt(matches[2], 10) - 1;
		y = parseInt(matches[3], 10);
		composedDate = new Date();
		composedDate.setFullYear(y, m, d, 0, 0, 0);
	} else if (dateFormat.indexOf('mm/dd/yyyy') !== -1) {
		const matches = /^(\d{2})[-/](\d{2})[-/](\d{4})$/.exec(cleanDateStr);
		if (matches === null) {
			return null;
		}
		d = parseInt(matches[2], 10);
		m = parseInt(matches[1], 10) - 1;
		y = parseInt(matches[3], 10);
		composedDate = new Date();
		composedDate.setFullYear(y, m, d, 0, 0, 0);
	} else if (dateFormat.indexOf('dd.mm.yyyy') !== -1) {
		const matches = /^(\d{2})[-/.](\d{2})[-/.](\d{4})$/.exec(cleanDateStr);
		if (matches === null) {
			return null;
		}
		d = parseInt(matches[1], 10);
		m = parseInt(matches[2], 10) - 1;
		y = parseInt(matches[3], 10);
		composedDate = new Date();
		composedDate.setFullYear(y, m, d, 0, 0, 0);
	} else if (dateFormat.indexOf('yyyy-mm-dd') !== -1) {
		const matches = /^(\d{4})-(\d{2})-(\d{2})$/.exec(cleanDateStr);
		if (matches === null) {
			return null;
		}
		d = parseInt(matches[3], 10);
		m = parseInt(matches[2], 10) - 1;
		y = parseInt(matches[1], 10);
		composedDate = new Date();
		composedDate.setFullYear(y, m, d, 0, 0, 0);
	}
	if (composedDate !== null) {
		if (
			composedDate.getDate() === d &&
			composedDate.getMonth() === m &&
			composedDate.getFullYear() === y
		) {
			return composedDate;
		}
	}
	return null;
};

/**
 * will return a Date object if the passed in dateTimeStr is valid (conforms to the expected format).
 * @param dateTimeStr
 * @returns {*}
 */
export const dateTimeStrToDate = (
	dateTimeStr,
	dateFormat,
	timeFormat,
	timeZoneOffsetHours,
) => {
	// break up the dateTimeStr to its date/time components
	const dateTimeToks = dateTimeStr.split(' ');
	if (dateTimeToks.length === 2) {
		const dateObj = dateStrToDate(dateTimeToks[0], dateFormat);
		if (dateObj !== null) {
			// are there any colon characters in the home_time_format? if so, split on this (extended format)
			let hour = -1;
			let minute = -1;
			if (timeFormat.indexOf(':') !== -1) {
				const timeToks = dateTimeToks[1].split(':');
				if (timeToks.length > 1) {
					[hour, minute] = timeToks;
				}
			} else if (dateTimeToks[1].length >= 4) {
				hour = parseInt(dateTimeToks[1].substring(0, 1), 10);
				minute = parseInt(dateTimeToks[1].substring(2, 3), 10);
			}
			if (hour !== -1 && minute !== -1) {
				const offset =
					timeZoneOffsetHours !== undefined
						? timeZoneOffsetHours * 60 - new Date().getTimezoneOffset()
						: 0;
				// we have a valid time; set the hours, mins, secs, millis
				dateObj.setHours(hour, minute, 0, 0);

				return offset
					? new Date(dateObj.getTime() + offset * 60 * 1000)
					: dateObj;
			}
		}
	}
	return null;
};

/**
 *
 * @param d1
 * @param d2
 * @returns {Number}
 */
export const dayDiff = (d1, d2) => {
	const t2 = d2.getTime();
	const t1 = d1.getTime();

	return parseInt((t2 - t1) / (24 * 3600 * 1000), 10);
};

/**
 * Converts a duration in milliseconds to a string
 * showing days, hours and minutes.
 *
 * @param duration  The duration in milliseconds
 * @returns {string}  The duration as a string
 */
export const convertDurationToMessage = (duration, formatMessage) => {
	let msg = '';
	const s = Math.floor(duration / 1000);
	let m = Math.floor(s / 60);
	let h = Math.floor(m / 60);
	m %= 60;
	const d = Math.floor(h / 24);
	h %= 24;

	if (d > 0) {
		msg += `${formatMessage(messages.lblDays, { value: d })}, `;
	}

	if (h > 0) {
		msg += `${formatMessage(messages.lblHours, { value: h })}, `;
	}

	if (m > 0) {
		msg += `${formatMessage(messages.lblMinutes, { value: m })} `;
	}

	if (msg === '') {
		msg = `${formatMessage(messages.lblMinutes0)} `;
	}

	return msg;
};

export const millisecondsToDuration = (duration) => {
	let m = Math.floor(duration / 60000);
	const h = Math.floor(m / 60);
	m %= 60;

	return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};
