import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';

import { addFeeDiscountApi } from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';

import AddFeeDiscount from './AddFeeDiscount';

import messages from './messagesAddFeeDiscount';
import inlineStyles from './styles';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';

validate.validators.bookingCheck = (value, options, key, attributes) => {
	const { bookingStatus } = attributes;
	if (
		(bookingStatus === 'CLOSED' || bookingStatus === 'RELEASED') &&
		attributes.totalReceipts + value < 0
	) {
		return options.message;
	}
	return undefined;
};

validate.validators.amountCheck = (value, options, key, attributes) => {
	if (attributes.feeDisc === 'fee' && attributes.totalRevenue + value < 0) {
		return options.message;
	}
	return undefined;
};

const StyledDialogActions = styled('div')(() => ({
	...inlineStyles.dialogActionsRoot,
}));

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

class AddFeeDiscountDialog extends Component {
	static propTypes = {
		booking: PropTypes.object.isRequired,
		feeTypes: PropTypes.array,
		id: PropTypes.string,
		intl: PropTypes.object,
		onAddFeeDiscountSuccess: PropTypes.func,
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool,
	};

	static defaultProps = {
		id: 'srtAddFeeDiscount',
		open: false,
	};

	initialState = {
		alertText: '',
		isFetching: false,
		isProcessingSubmit: false,
		type: '',
		amount: '',
		notes: '',
		feeDisc: '',
		errors: {},
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS(this.initialState),
		};
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.open === false && nextProps.open) {
			this.setState((state) => ({
				data: state.data.merge({
					...this.initialState,
					bookingStatus: nextProps.booking.BookingStatus,
					totalRevenue:
						nextProps.booking.BookingBillingSummary?.BookingBillingTotalRevenue,
					totalReceipts:
						nextProps.booking.BookingBillingSummary
							?.BookingBillingTotalReceipts,
				}),
			}));
		}
	};

	constraints = (formatMessage) => ({
		amount: {
			presence: {
				message: formatMessage(messages.errAmountInvalid),
				allowEmpty: false,
			},
			numericality: {
				greaterThan: 0,
				message: formatMessage(messages.errAmountInvalid),
			},
			bookingCheck: (attr, attributes) => ({
				message: formatMessage(messages.errBookingAmountInvalid, {
					amount: attributes.totalRevenue,
				}),
			}),
			amountCheck: (attr, attributes) => ({
				message: formatMessage(messages.errFeeAmountInvalid, {
					amount: attributes.totalReceipts,
				}),
			}),
		},
		type: {
			presence: {
				message: formatMessage(messages.errType),
				allowEmpty: false,
			},
		},
		feeDisc: {
			presence: {
				message: formatMessage(messages.errFeeDisc),
				allowEmpty: false,
			},
			inclusion: {
				within: ['fee', 'disc'],
				message: formatMessage(messages.errFeeDisc),
			},
		},
		notes: {
			presence: {
				message: formatMessage(messages.errNotes),
				allowEmpty: false,
			},
		},
	});

	handleChangeField = (updates) => {
		this.setState((state) => ({ data: state.data.merge(updates) }));
	};

	handleAddFeeDiscount = () => {
		const {
			booking,
			intl,
			onAddFeeDiscountSuccess,
			onClose,
			intl: { formatMessage },
		} = this.props;

		this.setState(
			(state) => ({ data: state.data.merge({ isProcessingSubmit: true }) }),
			() => {
				const data = this.state.data.toJS();
				const errors = validate(data, this.constraints(formatMessage));
				if (!validate.isEmpty(errors)) {
					this.setState((state) => ({
						data: state.data.merge({
							isProcessingSubmit: false,
							errors,
							alertText: errors.feeDisc ? errors.feeDisc[0] : '',
						}),
					}));
					return;
				}

				addFeeDiscountApi(
					{
						queryItems: booking.queryItems,
						feeData: data,
						currency: booking.BookingBillingTotal?.BookingBillingTotalCurrency,
						intl,
					},
					(response) => {
						this.setState((state) => ({
							data: state.data.merge({
								isProcessingSubmit: false,
								alertText: response.errorResponse.message,
							}),
						}));
					},
					() => {
						this.setState((state) => ({
							data: state.data.merge({ isProcessingSubmit: false }),
						}));
						onAddFeeDiscountSuccess(booking.queryItems);
						onClose();
					},
				);
			},
		);
	};

	handleSnackBarClose = () => {
		this.setState((state) => ({ data: state.data.set('alertText', '') }));
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		const data = this.state.data.toJS();

		const closeAction = () => this.props.onClose();
		const actions = (
			<StyledDialogActions className="row">
				<div className="col-12 col-sm-3 offset-sm-6">
					<Button
						variant="contained"
						id="srtAddFeeDiscountClose"
						onClick={closeAction}
						fullWidth
					>
						{formatMessage(messages.btnCancel)}
					</Button>
				</div>
				<div className="col-12 col-sm-3">
					<Button
						variant="contained"
						disabled={data.isProcessingSubmit}
						id="srtAddFeeDiscountSubmit"
						onClick={this.handleAddFeeDiscount}
						color="primary"
						fullWidth
					>
						{formatMessage(messages.btnSubmit)}
					</Button>
				</div>
			</StyledDialogActions>
		);

		return (
			<div>
				<StyledDialog open={this.props.open} onClose={closeAction}>
					<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
					<DialogContent>
						<AddFeeDiscount
							id={this.props.id}
							onChange={this.handleChangeField}
							feeTypes={this.props.feeTypes}
							disabled={data.isProcessingSubmit}
							currency={
								this.props.booking.BookingBillingTotal
									?.BookingBillingTotalCurrency
							}
							values={data}
							errors={data.errors}
						/>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtBookingAddFeeDiscountSnackBar"
					open={data.alertText !== ''}
					message={data.alertText}
					onClose={this.handleSnackBarClose}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	feeTypes: state.getIn(['settings', 'ServiceFeeTypes'])?.toJS(),
});

const mapDispatchToProps = (dispatch) => ({
	onAddFeeDiscountSuccess: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(AddFeeDiscountDialog));
