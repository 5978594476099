export default {
	dialogPaper: {
		overflowY: 'hidden',
		width: 600,
	},
	dialogActionsRoot: {
		width: '100%',
		marginRight: 0,
	},
};
