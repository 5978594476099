import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.confirmDialog.title',
		defaultMessage: 'Confirmation',
	},
	lblMessage: {
		id: 'app.confirmDialog.message',
		defaultMessage: 'Are you sure?',
	},
	btnYes: {
		id: 'app.confirmDialog.yes',
		defaultMessage: 'Yes',
	},
	btnNo: {
		id: 'app.confirmDialog.no',
		defaultMessage: 'No',
	},
});
