export default {
	filterContainer: {
		paddingTop: 10,
		paddingBottom: 15,
		width: 420,
		fontSize: 14,
	},
	checkbox: {
		display: 'flex',
	},
};
