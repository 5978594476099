export default {
	paperContainer: {
		marginTop: 16,
		marginBottom: 10,
		padding: '0 15px 15px 15px',
	},
	tdoDesc: {
		width: '80%',
	},
	buttons: {
		margin: 10,
	},
	rightButtons: {
		float: 'right',
	},
};
