import { pelorous, defaultFareColorClassSchema } from '../../../colors';

export default {
	description: {
		paddingLeft: 5,
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		color: pelorous,
	},
	legColumn: {
		width: 50,
		paddingLeft: 10,
		paddingRight: 10,
	},
	title: {
		fontWeight: 600,
		fontSize: 14,
	},
	titleContainer: {
		marginTop: 15,
	},
	cellNoBorderStyle: {
		borderBottom: 'none',
	},
	fareCellNoBorderStyle: {
		borderBottom: 'none',
		textTransform: 'capitalize',
	},
	icon: {
		color: defaultFareColorClassSchema.orange,
	},
	seatDetailsChevron: {
		marginRight: 4,
	},
	seatDetailsChevronWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	seatDetailsButton: {
		fontSize: 10,
		paddingLeft: 12,
	},
	seatsWrapper: {
		display: 'flex',
		gap: 5,
	},
	seatDetailsWrapper: {
		marginBottom: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	seatContainer: {
		marginBottom: 0,
		width: '100px',
	},
	seatTitle: {
		margin: 0,
		fontWeight: 500,
	},
	seatAttributes: {
		paddingLeft: '20px',
	},
	seatDetail: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'left',
		margin: 0,
		whiteSpace: 'pre-wrap',
	},
	seatDetailText: {
		margin: 0,
	},
};
