import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { forgotPasswordApi } from '../../apiLogin';
import messages from './messagesForgotPassword';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

class ForgotPasswordDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool,
	};

	initialState = {
		processingSubmit: false,
		disableSubmit: false,
		open: false,
		username: '',
		alertText: '',
		errors: {},
	};

	constructor(props) {
		super(props);
		this.state = { data: fromJS(this.initialState) };
	}

	constraints = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		return {
			username: {
				presence: {
					message: formatMessage(messages.errUsernameEmpty),
					allowEmpty: false,
				},
			},
		};
	};

	handleUsernameChange = ({ target: { value } }) => {
		this.setState((state) => ({ data: state.data.merge({ username: value }) }));
	};

	handleSubmit = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		this.setState((state) => ({
			data: state.data.merge({ errors: {}, processingSubmit: true }),
		}));
		const errors = validate(this.state.data.toJS(), this.constraints());

		if (!validate.isEmpty(errors)) {
			this.setState((state) => ({
				data: state.data.merge({ errors, processingSubmit: false }),
			}));
			return;
		}

		forgotPasswordApi(
			this.state.data.get('username'),
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						processingSubmit: false,
					}),
				}));
			},
			(response) => {
				let alertText = '';
				let disableSubmit = false;
				if (response.successResponse) {
					alertText = formatMessage(messages.okSendLink);
					disableSubmit = true;
				} else {
					alertText = formatMessage(messages.errPerformLogin);
				}
				this.setState((state) => ({
					data: state.data.merge({
						alertText,
						processingSubmit: false,
						disableSubmit,
					}),
				}));
			},
		);
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		const { data } = this.state;
		const closeAction = () => {
			this.state = { data: fromJS(this.initialState) };
			this.props.onClose();
		};

		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-12 col-sm-3 offset-sm-4">
					<Button
						variant="contained"
						id="srtForgotPasswordDialogClose"
						style={inlineStyles.button}
						onClick={closeAction}
						fullWidth
					>
						{formatMessage(messages.btnClose)}
					</Button>
				</div>
				<div className="col-12 col-sm-5">
					<Button
						variant="contained"
						id="srtForgotPasswordDialogSubmit"
						style={inlineStyles.button}
						onClick={this.handleSubmit}
						disabled={
							this.state.data.get('processingSubmit') ||
							this.state.data.get('disableSubmit')
						}
						color="primary"
						fullWidth
					>
						{formatMessage(messages.btnSend)}
					</Button>
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={this.props.open}
					onClose={closeAction}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
					<DialogContent>
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">
									<RefreshIndicator
										size={36}
										top={0}
										left={0}
										status={data.get('processingSubmit') ? 'loading' : 'hide'}
										style={inlineStyles.refresh}
									/>
									<div>{formatMessage(messages.lblDescription)}</div>
									<TextField
										id="srtForgotPasswordUsername"
										label={formatMessage(messages.lblUsername)}
										value={this.state.data.get('username')}
										onChange={this.handleUsernameChange}
										fullWidth
										type="text"
										error={!!this.state.data.getIn(['errors', 'username'])}
										helperText={this.state.data.getIn(['errors', 'username'])}
										autoComplete="off"
										variant="standard"
									/>
								</div>
							</div>
						</div>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtForgotPasswordSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

// This alias will be used to access bare component for unit testing
export { ForgotPasswordDialog as ForgotPasswordDialogAlias };

export default injectIntl(ForgotPasswordDialog);
