import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatPrice } from '../../../utils';
import messages from './messagesIssueRefund';

const getTabsTypeDisplayAs = (value) => {
	const types = {
		ON_ACCOUNT: 'On Account',
		TRADITIONAL_CREDIT_DEBIT_CARD: 'Credit/Debit Card',
		BRAINTREE: 'Braintree',
		OFFLINE_SYNC: <FormattedMessage {...messages.lblOfflineSync} />,
	};
	return types[value];
};

class IssueRefundTabSelector extends Component {
	static propTypes = {
		defaultSelecetedTabValue: PropTypes.string.isRequired,
		refundablePayments: PropTypes.array,
		tabChange: PropTypes.func,
		intl: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			tabItems: [],
			tabValue: this.props.defaultSelecetedTabValue,
		};
	}

	static getDerivedStateFromProps(nextProps) {
		if (!nextProps.refundablePayments || !nextProps.refundablePayments.length) {
			return null;
		}

		return {
			tabItems: (nextProps.refundablePayments || []).map(
				(tabItem) =>
					tabItem.BookingBillingPaymentDetailAmount > 0 && (
						<Tab
							label={
								<div
									style={{
										textAlign: 'left',
										textTransform: 'none',
										color: '#666',
									}}
								>
									<div>
										<strong>
											{getTabsTypeDisplayAs(tabItem.BookingBillingPaymentModel)}
										</strong>
									</div>
									<div style={{ marginTop: '3px' }}>
										{formatPrice(
											tabItem.BookingBillingPaymentDetailBalance,
											tabItem.BookingBillingPaymentDetailCurrency,
											nextProps.intl,
										)}
									</div>
								</div>
							}
							value={tabItem.BookingBillingPaymentReceiptNumber}
							key={tabItem.BookingBillingPaymentReceiptNumber}
						/>
					),
			),
		};
	}

	handleChange = (event, newValue) => {
		const selectedRefundablePayments = this.props.refundablePayments.find(
			(x) => x.BookingBillingPaymentReceiptNumber === newValue,
		);
		if (selectedRefundablePayments) {
			this.setState({ tabValue: newValue });
			this.props.tabChange(selectedRefundablePayments);
		}
	};

	render() {
		return this.props.refundablePayments ? (
			<div>
				<Tabs
					TabIndicatorProps={{ style: { background: 'blue' } }}
					value={this.state.tabValue}
					indicatorColor="primary"
					textColor="primary"
					onChange={this.handleChange}
					variant="scrollable"
				>
					{this.state.tabItems}
				</Tabs>
			</div>
		) : null;
	}
}

export default injectIntl(IssueRefundTabSelector);
