export const GET_SETTINGS = 'get_settings';
export const CLEAR_SETTINGS = 'clear_settings';
export const USER_LOGOUT = 'user_logout';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_ACCESS_TOKEN_EXPIRY_TIME = 'SET_ACCESS_TOKEN_EXPIRY_TIME';

export const widgetList = [
	'srtAccountManagement',
	'srtUserProfile',
	'srtShopping',
	'srtBooking',
	'srtAnalytics',
];

export const LOCAL_STORAGE_KEYS = {
	hasTriedRefresh: 'hasTriedRefresh',
	loggedOut: 'loggedOut',
};

export const navigatorLanguagesMap = {
	'en-GB': 'en_GB',
	'fr-BE': 'fr_FR',
	'fr-CA': 'fr_FR',
	'fr-CH': 'fr_FR',
	'fr-LU': 'fr_FR',
	fr: 'fr_FR',
};

export const paymentMethodsTypes = {
	braintree: 'EP',
	onaccount: 'NONE',
};

export const orderStatuses = {
	BOOKED: 'BOOKED',
	CANCELED: 'CANCELED',
	CONFIRMED: 'CONFIRMED',
	REPLACED: 'REPLACED',
	RETURNED: 'RETURNED',
	TICKETED: 'TICKETED',
	VOID_EXCEPTION: 'VOID_EXCEPTION',
};

export const bookingValueDocStatus = {
	ISSUED: 'ISSUED',
	READY: 'READY',
	RETURNED_PARTIAL: 'RETURNED_PARTIAL',
};

export const TDOS = [
	'PAH',
	'EML',
	'SMS',
	'ETK',
	'XVD',
	'MVD',
	'TVM',
	'TOF',
	'PRN',
	'TOP',
	'TVP',
	'MTD',
	'TML',
	'TBC',
	'TBM',
	'TBO',
	'TBX',
	'SCT',
];

export const notAutoSelectableTdos = ['TBM', 'TBO', 'TBX', 'SCT'];

export const TEST_ENVS = ['dev', 'test'];
