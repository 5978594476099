import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import { StyledFareAmenities, SegmentRowTableCell } from './styled';
import openReturnIcon from '../../../images/arrow-u-left-bottom.svg';
import RevisionDialog from '../../ManageBooking/components/RevisionDialog/RevisionDialog';
import { gaEvent } from '../../../utils/googleAnalytics';
import '../../ManageBooking/components/BookingView/styleBookingView.css';
import SupplementDetailsTable from './SupplementDetailsTable';
import messages from './messages';
import inlineStyles from './styles';
import ErrorFallBack from '../../ManageBooking/components/ErrorFallBack';
import SplitFareDialog from '../../ManageBooking/components/SplitFareDialog';
import SeatDetails from './SeatDetails';

const StyledWarningIcon = styled(WarningIcon)({
	...inlineStyles.icon,
});

const SegmentRow = ({
	handleOpenStationInfoDialog,
	bikeReservationDetails,
	isExchange,
	selectedTicketableFareId,
	ticketableFareSelectable,
	onTicketableFareChange,
	travelSegmentId,
	priceRowSpan,
	carrier,
	type,
	designator,
	origin,
	origCode,
	destination,
	destCode,
	depart,
	duration,
	cabinClass,
	fare,
	openReturn,
	seats,
	amenitiesServices,
	onOpenFareRules,
	ticketableFareGroupIndex,
	price,
	intermediateTravelPoints,
	showIntermediateStops,
	radioTitle,
	bookingOrderRevisionsLeg,
	arriveDateTime,
	departDateTime,
	isPartialCancel,
	isSplitTicketFare,
	travelSubSegments,
	ticketableFareGroup,
	intl,
	intl: { formatMessage },
	splitFareGroup,
	showPrice,
	showSplitTitle,
	departStation,
	arriveStation,
}) => {
	const [open, setOpen] = useState(false);
	const [splitFareOpen, setSplitFareOpen] = useState(false);
	const [seatAttributesOpen, setSeatAttributesOpen] = useState(false);

	const renderStationTime = (dateTime, stationWithTime, code) => {
		const station =
			stationWithTime &&
			stationWithTime.substring(0, stationWithTime.indexOf(' @ '));
		return (
			<>
				<a
					styleName="link"
					onClick={handleOpenStationInfoDialog(code)}
					id="station-link"
				>
					{station}
				</a>
				<div>{dateTime}</div>
			</>
		);
	};

	const showRadioSelection = () => {
		if (!isExchange) {
			return (
				<Checkbox
					id={`srtTicketableFare_${selectedTicketableFareId}`}
					disabled={ticketableFareSelectable.disabled || !isPartialCancel}
					checked={ticketableFareSelectable.checked || !isPartialCancel}
					onChange={(e) =>
						onTicketableFareChange(
							ticketableFareSelectable.ticketableFareId,
							e.target.checked,
						)
					}
				/>
			);
		}
		if (ticketableFareSelectable.disabled) {
			return <FormattedMessage {...messages.lblOptionDisabled} />;
		}

		return (
			<Radio
				id={`srtTicketableFare_${ticketableFareSelectable.ticketableFareId}`}
				value={ticketableFareSelectable.ticketableFareId}
				checked={
					ticketableFareSelectable.ticketableFareId === selectedTicketableFareId
				}
				onChange={() =>
					onTicketableFareChange(ticketableFareSelectable.ticketableFareId)
				}
			/>
		);
	};

	const getCellBorderStyle = () =>
		bikeReservationDetails?.length > 0 ? inlineStyles.cellNoBorderStyle : {};
	const getBookingRevisionsStyle = () =>
		bookingOrderRevisionsLeg && Object.keys(bookingOrderRevisionsLeg).length > 0
			? {
					...getCellBorderStyle(),
					display: 'flex',
					alignItems: 'center',
					height: 75,
					paddingRight: 0,
				}
			: getCellBorderStyle();
	const getFareCellBorderStyle = () =>
		bikeReservationDetails?.length > 0
			? inlineStyles.fareCellNoBorderStyle
			: { textTransform: 'capitalize' };
	const stationTimeStyle = { width: '100%' };

	const handleButtonClick = () => {
		gaEvent('viewRevisionData');
		setOpen(true);
	};

	const dialogClose = () => {
		gaEvent('closeRevisionData');
		setOpen(false);
	};

	const handleSplitFareClick = () => {
		gaEvent('splitFareDialogOpen');
		setSplitFareOpen(true);
	};

	const splitFareDialogClose = () => {
		gaEvent('splitFareDialogClose');
		setSplitFareOpen(false);
	};

	const segment =
		ticketableFareGroup?.BookingLegTravelSegments.find(
			(sgmnt) => sgmnt.BookingLegTravelSegmentId === travelSegmentId,
		) || {};

	const hasSeatDetails =
		(segment?.BookingLegTravelSegmentSeatDetails || []).some(
			(seatDetail) =>
				(seatDetail?.BookingLegTravelSegmentSeatAttributes || []).length > 0,
		) || segment?.BookingLegTravelSegmentSeatDirection;

	return (
		<>
			<TableRow
				id={`srtTravelSegments_${travelSegmentId}`}
				data-testid="segment-row"
			>
				{ticketableFareSelectable && showPrice && (
					<TableCell
						styleName="bookingTableRow"
						style={getCellBorderStyle()}
						rowSpan={priceRowSpan + (intermediateTravelPoints.length ? 1 : 0)}
						data-testid="radio-selection"
					>
						{showRadioSelection()}
					</TableCell>
				)}
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					{carrier}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					{type}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					{designator}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					id="station-link"
					styleName="bookingTableRow"
					style={getBookingRevisionsStyle()}
					$splitFareGroup={splitFareGroup}
				>
					<div style={stationTimeStyle}>
						{renderStationTime(departDateTime, origin, origCode)}
					</div>

					{bookingOrderRevisionsLeg &&
						Object.keys(bookingOrderRevisionsLeg).length > 0 && (
							<Button onClick={handleButtonClick} style={{ minWidth: 0 }}>
								<StyledWarningIcon />
							</Button>
						)}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					{renderStationTime(arriveDateTime, destination, destCode)}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					{duration}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					{cabinClass}
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow bookingTableLink"
					style={getFareCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					<div
						onClick={onOpenFareRules(travelSegmentId, ticketableFareGroupIndex)}
					>
						{fare.map((fareName, index) => (
							<div key={index} styleName="fareName">
								{fareName}
							</div>
						))}
						{openReturn && (
							<Tooltip
								title={<FormattedMessage {...messages.lblOpenReturnFare} />}
							>
								<img
									key="openReturnIcon"
									alt={<FormattedMessage {...messages.lblOpenReturnFare} />}
									src={openReturnIcon}
									data-test="openReturnIcon"
								/>
							</Tooltip>
						)}
					</div>
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					<div style={inlineStyles.seatsWrapper}>
						<SeatDetails
							seats={seats}
							segment={segment}
							seatAttributesOpen={seatAttributesOpen}
						/>

						{hasSeatDetails && (
							<Button
								color="secondary"
								size="small"
								style={inlineStyles.seatDetailsButton}
								onClick={() => setSeatAttributesOpen(!seatAttributesOpen)}
							>
								<div style={inlineStyles.seatDetailsChevronWrapper}>
									<div style={inlineStyles.seatDetailsChevron}>
										{seatAttributesOpen ? (
											<ExpandLessIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</div>
									<span>
										{seatAttributesOpen ? (
											<FormattedMessage {...messages.lblHideSeatDetails} />
										) : (
											<FormattedMessage {...messages.lblViewSeatDetails} />
										)}
									</span>
								</div>
							</Button>
						)}
					</div>
				</SegmentRowTableCell>
				<SegmentRowTableCell
					styleName="bookingTableRow"
					style={getCellBorderStyle()}
					$splitFareGroup={splitFareGroup}
				>
					<div>
						{amenitiesServices?.map((amenity) => (
							<span
								key={`orderDetails${amenity.BookingOrderServicesAndAmenitiesDesc}`}
							>
								{amenity.BookingOrderServicesAndAmenitiesDesc}
								<br />
							</span>
						))}
					</div>
				</SegmentRowTableCell>
				{price && showPrice && (
					<SegmentRowTableCell
						styleName="bookingTableRow"
						style={getCellBorderStyle()}
						rowSpan={priceRowSpan * (intermediateTravelPoints?.length ? 2 : 1)}
						data-testid="price"
					>
						{price}
						{isSplitTicketFare && (
							<div onClick={handleSplitFareClick}>
								<StyledFareAmenities
									fareAmenities={[
										{
											code: 'multiple_fares',
											desc: formatMessage(messages.lblMultipleFares),
										},
									]}
								/>
							</div>
						)}
					</SegmentRowTableCell>
				)}
			</TableRow>
			<SupplementDetailsTable
				bikeReservationDetails={bikeReservationDetails}
				radioTitle={radioTitle}
			/>
			{!!intermediateTravelPoints && !!intermediateTravelPoints.length && (
				<TableRow
					style={inlineStyles.intermediateStepsContainer}
					data-testid="intermediate-travel-points-table"
				>
					<TableCell
						style={inlineStyles.intermediateStepsContainer}
						colSpan={12}
					>
						<Collapse in={showIntermediateStops}>
							<Table style={inlineStyles.intermediateStepsTable}>
								<TableHead style={inlineStyles.tableHead}>
									<TableRow>
										<TableCell>
											<FormattedMessage {...messages.lblStation} />
										</TableCell>
										<TableCell>
											<FormattedMessage {...messages.lblArrival} />
										</TableCell>
										<TableCell>
											<FormattedMessage {...messages.lblDeparture} />
										</TableCell>
										<TableCell>
											<FormattedMessage {...messages.lblPlatform} />
										</TableCell>
										<TableCell>
											<FormattedMessage {...messages.lblStatus} />
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{intermediateTravelPoints.map((intermediateTravelPoint) => (
										<TableRow key={intermediateTravelPoint.Station}>
											<TableCell>{intermediateTravelPoint.Station}</TableCell>
											<TableCell>{intermediateTravelPoint.Arrival}</TableCell>
											<TableCell>{intermediateTravelPoint.Departure}</TableCell>
											<TableCell>{intermediateTravelPoint.Platform}</TableCell>
											<TableCell>{intermediateTravelPoint.Status}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
			{bookingOrderRevisionsLeg &&
				Object.keys(bookingOrderRevisionsLeg).length > 0 && (
					<RevisionDialog
						onClose={dialogClose}
						open={open}
						revision={bookingOrderRevisionsLeg}
						origin={origin}
						destination={destination}
						depart={depart}
					/>
				)}
			{isSplitTicketFare && (
				<ErrorBoundary
					fallbackRender={(props) => {
						gaEvent('splitFareDialogrror', ...props);
						return (
							<ErrorFallBack
								{...props}
								messageComponent="Multiple Fares: Price Breakdown"
							/>
						);
					}}
				>
					<SplitFareDialog
						onClose={splitFareDialogClose}
						open={splitFareOpen}
						travelSubSegments={travelSubSegments}
						intl={intl}
						onOpenFareRules={onOpenFareRules}
						ticketableFareGroupIndex={ticketableFareGroupIndex}
						ticketableFareGroup={ticketableFareGroup}
						handleOpenStationInfoDialog={handleOpenStationInfoDialog}
						showSplitTitle={showSplitTitle}
						departStation={departStation}
						arriveStation={arriveStation}
					/>
				</ErrorBoundary>
			)}
		</>
	);
};

SegmentRow.propTypes = {
	bikeReservationDetails: PropTypes.array,
	amenitiesServices: PropTypes.array,
	cabinClass: PropTypes.string,
	carrier: PropTypes.string,
	depart: PropTypes.string,
	designator: PropTypes.string,
	destCode: PropTypes.string,
	destination: PropTypes.string,
	duration: PropTypes.string,
	fare: PropTypes.arrayOf(PropTypes.string),
	openReturn: PropTypes.bool,
	handleOpenStationInfoDialog: PropTypes.func.isRequired,
	intermediateTravelPoints: PropTypes.array,
	onOpenFareRules: PropTypes.func,
	onTicketableFareChange: PropTypes.func,
	origCode: PropTypes.string,
	origin: PropTypes.string,
	price: PropTypes.string,
	priceRowSpan: PropTypes.number,
	seats: PropTypes.string,
	selectedTicketableFareId: PropTypes.string,
	showIntermediateStops: PropTypes.bool,
	showSelectionRadio: PropTypes.bool,
	ticketableFareGroupIndex: PropTypes.number,
	ticketableFareSelectable: PropTypes.object,
	travelSegmentId: PropTypes.string,
	type: PropTypes.string,
	isExchange: PropTypes.bool,
	radioTitle: PropTypes.node,
	bookingOrderRevisionsLeg: PropTypes.object,
	arriveDateTime: PropTypes.string,
	departDateTime: PropTypes.string,
	isPartialCancel: PropTypes.bool,
	isSplitTicketFare: PropTypes.bool,
	travelSubSegments: PropTypes.array,
	ticketableFareGroup: PropTypes.object,
	intl: PropTypes.object,
	splitFareGroup: PropTypes.bool,
	showPrice: PropTypes.bool,
	showSplitTitle: PropTypes.bool,
	departStation: PropTypes.string,
	arriveStation: PropTypes.string,
};

export { SegmentRow as SegmentRowAlias };

export default SegmentRow;
