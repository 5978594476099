import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTdoTitle: {
		id: 'app.common.ticketDelivery.title',
		defaultMessage: 'Ticket Delivery Options',
	},
	lblTdoElectronicTitle: {
		id: 'app.common.ticketDelivery.electronicTitle',
		defaultMessage: 'Electronic',
	},
	lblTdoPickupTitle: {
		id: 'app.common.ticketDelivery.pickupTitle',
		defaultMessage: 'Pickup at Station',
	},
	lblTdoMailTitle: {
		id: 'app.common.ticketDelivery.mailTitle',
		defaultMessage: 'By Mail',
	},
	lblTdoPrintTitle: {
		id: 'app.common.ticketDelivery.printTitle',
		defaultMessage: 'Direct Printing',
	},
	lblTdoSmartCardTitle: {
		id: 'app.common.ticketDelivery.smartCardTitle',
		defaultMessage: 'Smart Card',
	},
	lblTdoMultiTicketTitle: {
		id: 'app.common.ticketDelivery.multiTicketTitle',
		defaultMessage: 'Multi-Ticket',
	},
	lblTdoElectronicNoMail: {
		id: 'app.common.ticketDelivery.electronicNoMail',
		defaultMessage: 'We will send the ticket to the entered email address',
	},
	lblTdoETicket: {
		id: 'app.common.ticketDelivery.eTicket',
		defaultMessage: 'E_TICKET',
	},
	lblTdoElectronicAskedMail: {
		id: 'app.common.ticketDelivery.electronicAskedMail',
		defaultMessage:
			'You will be asked to enter an email address during payment and confirmation',
	},
	lblTdoElectronicDBPrint: {
		id: 'app.common.ticketDelivery.electronicDBPrint',
		defaultMessage: 'Ticket must be printed on DIN A4 paper',
	},
	lblTDOSmsSelect: {
		id: 'app.shopping.createBooking.tdo.smsSelect',
		defaultMessage: '---- Select ----',
	},
	lblTDOSmsNumber: {
		id: 'app.shopping.createBooking.tdo.smsText',
		defaultMessage: 'Phone Number:',
	},
	lblTDOPaperEmail: {
		id: 'app.shopping.createBooking.tdo.paperEmail',
		defaultMessage: 'Email',
	},
	lblTDONoTodOndeparture: {
		id: 'app.shopping.createBooking.tdo.noTodOnDeparture', // eslint-disable-next-line max-len
		defaultMessage:
			'This departure station either does not offer ticket on departure services or the ticketing services are not open at the time of your departure. You will need to pick up your ticket in advance of your journey at another station. For a list of alternate stations where you may pick up your tickets:',
	},
	lblTDONoTvmOndeparture: {
		id: 'app.shopping.createBooking.tdo.noTvmOnDeparture', // eslint-disable-next-line max-len
		defaultMessage:
			'This departure station either does not have a ticket vending machine or it is not open at the time of your departure. You may use the ticket office to retrieve your ticket, or you may pick up your ticket in advance of your journey at another station. For a list of alternate stations where you may pick up your tickets:',
	},
	lblTDONoTofOndeparture: {
		id: 'app.shopping.createBooking.tdo.noTofOnDeparture', // eslint-disable-next-line max-len
		defaultMessage:
			'This departure station either does not have a ticket office or it is not open at the time of your departure. You may use the ticket vending machine to retrieve your ticket, or you may pick up your ticket in advance of your journey at another station. For a list of alternate stations where you may pick up your tickets:',
	},
	lblTDONoTodListStations: {
		id: 'app.shopping.createBooking.tdo.noTodListStations',
		defaultMessage: 'List of stations to retrieve tickets',
	},
	lblTDONoTodStationInfo: {
		id: 'app.shopping.createBooking.tdo.noTodStationInfo',
		defaultMessage: 'For information on your departure station:',
	},
	lblTDORegionDeliver: {
		id: 'app.shopping.createBooking.tdo.regionDeliver',
		defaultMessage: 'Deliver to:',
	},
	lblTDOMailShipping: {
		id: 'app.shopping.createBooking.tdo.mailShipping',
		defaultMessage: 'Shipping Address',
	},
	lblTDOMailName: {
		id: 'app.shopping.createBooking.tdo.mailName',
		defaultMessage: 'Name',
	},
	lblTDOSmartCardNumbers: {
		id: 'app.shopping.createBooking.tdo.SmartCardNumbers',
		defaultMessage: 'Smart Card Numbers',
	},
	lblTDOSmartCardNumber: {
		id: 'app.shopping.createBooking.tdo.smartCardNumber',
		defaultMessage: 'Smart Card Number',
	},
	lblTDOSmartCardPickup: {
		id: 'app.shopping.createBooking.tdo.smartCardPickup',
		defaultMessage: 'Pick Up Station',
	},
	btnClose: {
		id: 'app.common.ticketDelivery.close',
		defaultMessage: 'Close',
	},
	btnSubmit: {
		id: 'app.common.ticketDelivery.submit',
		defaultMessage: 'Submit Ticket Delivery',
	},
	errTdoEmpty: {
		id: 'app.shopping.ticketDelivery.tdoEmpty',
		defaultMessage: '^Please select a ticket delivery method',
	},
	errNameEmpty: {
		id: 'app.shopping.ticketDelivery.nameEmpty',
		defaultMessage: '^Please enter a value for the name to ship to',
	},
	errCountryEmpty: {
		id: 'app.shopping.ticketDelivery.countryEmpty',
		defaultMessage: '^Please choose a country',
	},
	errStateEmpty: {
		id: 'app.shopping.ticketDelivery.stateEmpty',
		defaultMessage: '^Please choose a state',
	},
	errProvinceEmpty: {
		id: 'app.shopping.ticketDelivery.provinceEmpty',
		defaultMessage: '^Please choose a province',
	},
	errAddressLine1Empty: {
		id: 'app.shopping.ticketDelivery.addressEmpty',
		defaultMessage: '^Please enter a value for the address field',
	},
	errCityEmpty: {
		id: 'app.shopping.ticketDelivery.cityEmpty',
		defaultMessage: '^Please enter a value for the city field',
	},
	errPostalCodeEmpty: {
		id: 'app.shopping.ticketDelivery.postalCodeEmpty',
		defaultMessage: '^Please enter a value for the postal/zip code field',
	},
	errPostalCodeInvalid: {
		id: 'app.shopping.ticketDelivery.postalCodeInvalid',
		defaultMessage: '^Please enter a valid postal/zip code',
	},
	errSmsCountryEmpty: {
		id: 'app.shopping.ticketDelivery.smsCountryEmpty',
		defaultMessage: '^Please select a country calling code',
	},
	errSmsNumberEmpty: {
		id: 'app.shopping.ticketDelivery.smsNumberEmpty',
		defaultMessage: '^Please enter a phone number',
	},
	errSmartCardPickupStation: {
		id: 'app.shopping.ticketDelivery.smartCardPickupStation',
		defaultMessage: '^Please select a pick up station',
	},
	errSmartCardNumberEmpty: {
		id: 'app.shopping.ticketDelivery.smartCardNumberEmpty',
		defaultMessage: '^Please enter a valid smart card number',
	},
	errSmartCardNumberFormat: {
		id: 'app.shopping.ticketDelivery.smartCardNumberFormat',
		defaultMessage: '^This value should be a number.',
	},
	errSmartCardNumberLength: {
		id: 'app.shopping.ticketDelivery.smartCardNumberLength',
		defaultMessage: '^Smart card number should be 18 characters long.',
	},
	errPickUpStationsNoData: {
		id: 'app.shopping.ticketDelivery.pickUpStations.noData',
		defaultMessage: 'No Stations for pick up tickets found',
	},
	errPickUpStationsErrorResponse: {
		id: 'app.shopping.ticketDelivery.pickUpStations.errorResponse',
		defaultMessage: 'We could not find an available list of stations',
	},
	errEmptyEmail: {
		id: 'app.shopping.ticketDelivery.paperTicket.emptyEmail',
		defaultMessage: '^Please enter an email address',
	},
	errInvalidEmail: {
		id: 'app.shopping.ticketDelivery.paperTicket.invalidEmail',
		defaultMessage:
			'^Please enter a properly formatted email address (for example: anyuser@anycompany.com)',
	},
});
