import { defineMessages } from 'react-intl';

export default defineMessages({
	lblBillingTotal: {
		id: 'app.shopping.manageBooking.billingTable.billingTotal',
		defaultMessage: 'Total',
	},
	lblBillingDate: {
		id: 'app.shopping.manageBooking.billingTable.billingDate',
		defaultMessage: 'Transaction Date',
	},
	lblBillingOrder: {
		id: 'app.shopping.manageBooking.billingTable.billingOrder',
		defaultMessage: 'Order',
	},
	lblBillingDescription: {
		id: 'app.shopping.manageBooking.billingTable.billingDescription',
		defaultMessage: 'Description',
	},
	lblBillingAmount: {
		id: 'app.shopping.manageBooking.billingTable.billingAmount',
		defaultMessage: 'Amount',
	},
	lblBillingCharge: {
		id: 'app.shopping.manageBooking.billingTable.billingCharge',
		defaultMessage: 'Charge',
	},
	lblBillingPayment: {
		id: 'app.shopping.manageBooking.billingTable.billingPayment',
		defaultMessage: 'Payment',
	},
});
