import { defineMessages } from 'react-intl';

export default defineMessages({
	lblCustomInfo: {
		id: 'app.shopping.manageBooking.customInfo.title',
		defaultMessage: 'Custom Information',
	},
	lblReferenceNo: {
		id: 'app.shopping.manageBooking.customInfo.lblReferenceNo',
		defaultMessage: 'Reference #',
	},
	lblPONumber: {
		id: 'app.shopping.manageBooking.customInfo.lblPONumber',
		defaultMessage: 'PO #',
	},
	lblCustomerNo: {
		id: 'app.shopping.manageBooking.customInfo.lblCustomerNo',
		defaultMessage: 'Customer #',
	},
	lblClose: {
		id: 'app.shopping.manageBooking.customInfo.customInfoClose',
		defaultMessage: 'Close',
	},
	lblSubmit: {
		id: 'app.shopping.manageBooking.customInfo.customInfoSubmit',
		defaultMessage: 'Save Custom Information',
	},
	errCustomInfoType: {
		id: 'app.shopping.manageBooking.customInfo.customInfoTypeEmpty',
		defaultMessage: "Type can't be blank",
	},
	errCustomInfoValue: {
		id: 'app.shopping.manageBooking.customInfo.customInfoValueEmpty',
		defaultMessage: "Value can't be blank when required is checked",
	},
	errCustomInfoTooLong: {
		id: 'app.shopping.manageBooking.customInfo.customInfoTooLong',
		defaultMessage:
			'^Reference Number, PO Number and Customer Number fields must be 25 characters or less.',
	},
	errCustomInfoTypeValueTooLong: {
		id: 'app.shopping.manageBooking.customInfo.customInfoTypeValueTooLong',
		defaultMessage:
			'Custom Information type and value fields must be less than 256 characters long.',
	},
	errCustomInfoTypeDuplicate: {
		id: 'app.shopping.manageBooking.customInfo.customInfoTypeDuplicate',
		defaultMessage:
			'Type {errorType} is already contained on this booking. Please enter a new Type.',
	},
	okCustomInfoAdded: {
		id: 'app.shopping.manageBooking.customInfo.customInfoAdded',
		defaultMessage: 'Success: Custom Information added to booking',
	},
});
