import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { injectIntl } from 'react-intl';
import messages from './messagesAddAddress';

import Address from '../../../../components/Address/Address';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const AddAddressDialog = (props) => {
	const {
		open,
		addressValues,
		states,
		provinces,
		countries,
		errors,
		onContinueWith,
		onContinueWithout,
		onAddressChange,
		addressRequired,
		disabled,
	} = props;
	const { formatMessage } = props.intl;
	const buttonStyle = !addressRequired
		? 'col-5 col-md-4'
		: 'col-5 col-md-4 offset-7 offset-md-8';
	const actions = (
		<div className="row" style={inlineStyles.dialogActionsRoot}>
			{addressRequired || (
				<div className="col-5 offset-2 offset-md-3">
					<Button
						id="srtAddAddressContinueWithout"
						onClick={onContinueWithout}
						fullWidth
						disabled={disabled}
						variant="contained"
					>
						{formatMessage(messages.btnContinueWithout)}
					</Button>
				</div>
			)}
			<div className={buttonStyle}>
				<Button
					id="srtAddAddressContinueWith"
					onClick={onContinueWith}
					color="primary"
					fullWidth
					disabled={disabled}
					variant="contained"
				>
					{formatMessage(messages.btnContinueWith)}
				</Button>
			</div>
		</div>
	);
	const addressMessageWithout = !addressRequired
		? formatMessage(messages.lblMessage3)
		: '';

	return (
		<StyledDialog
			open={open}
			onClose={onContinueWithout}
			disableEnforceFocus
			maxWidth={false}
		>
			<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
			<DialogContent>
				<div id="srtAddAddressMessages" style={inlineStyles.messageText}>
					{formatMessage(messages.lblMessage1)}
					<br />
					{`${formatMessage(messages.lblMessage2)}${addressMessageWithout}.`}
				</div>
				<Address
					idPrefix="srtPaxDialog"
					values={addressValues}
					countries={countries}
					states={states}
					provinces={provinces}
					onChange={onAddressChange}
					errors={errors}
					useDefaults
				/>
			</DialogContent>
			<DialogActions>{actions}</DialogActions>
		</StyledDialog>
	);
};

AddAddressDialog.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool.isRequired,
	addressValues: PropTypes.shape({
		address1: PropTypes.string.isRequired,
		address2: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		postalCode: PropTypes.string.isRequired,
		countryCode: PropTypes.string,
		stateOrProvince: PropTypes.string.isRequired,
		addressType: PropTypes.string,
	}).isRequired,
	countries: PropTypes.object.isRequired,
	states: PropTypes.object.isRequired,
	provinces: PropTypes.object.isRequired,
	onContinueWith: PropTypes.func,
	onContinueWithout: PropTypes.func,
	onAddressChange: PropTypes.func.isRequired,
	errors: PropTypes.object,
	addressRequired: PropTypes.bool,
	disabled: PropTypes.bool,
};

export { AddAddressDialog as AddAddressDialogAlias };
export default injectIntl(AddAddressDialog);
