import { orange, lightGray } from '../../colors';

export default {
	dialogPaper: {
		minHeight: 150,
		maxWidth: 1024,
		minWidth: 500,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	refresh: {
		marginTop: '45%',
		marginLeft: '45%',
	},
	indicator: {
		marginTop: '15%',
		marginLeft: '49%',
	},
	radioButton: {
		marginBottom: 10,
	},
	warningIcon: {
		color: orange,
		width: 20,
		height: 20,
	},
	warning: {
		color: orange,
		fontSize: 14,
	},
	linkTitle: {
		cursor: 'pointer',
	},
	passengersInputWidth: {
		minWidth: 260,
	},
	passengersInputLabel: {
		width: '130%',
	},
	smartCardStations: {
		minWidth: 260,
	},
	smartCardNumbersLabel: {
		opacity: 0.54,
		fontWeight: 400,
		marginTop: 15,
		marginBottom: -10,
	},
	details: {
		borderTop: `1px solid ${lightGray}`,
	},
};
