import { lightGray } from '../../../colors';

export default {
	dialogPaper: {
		overflowY: 'hidden',
	},
	dialogActionsRoot: {
		width: '100%',
	},
	dialogContent: {
		borderTop: `1px solid ${lightGray}`,
		borderBottom: `1px solid ${lightGray}`,
	},
};
