import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import styles from './styles';

import messages from './messages';
import { gaEvent } from '../../../utils/googleAnalytics';

const StyledSpan = styled('span')({
	...styles.title,
});

const StyledTableRow = styled(TableRow)({
	...styles.titleContainer,
});

const PromotionDetailsTable = ({ promotionDetails, radioTitle }) => {
	const [expanded, setExpanded] = React.useState(false);
	const handleExpand = () => {
		gaEvent(
			expanded
				? 'expandPromotionDetailsSection'
				: 'collapsePromotionDetailsSection',
		);
		setExpanded(!expanded);
	};

	const isNotDuplicate = (value, index, arrayObj) =>
		value?.validationCode === null ||
		value?.validationCode === undefined ||
		// if the item has a different index to another item in the array
		// with the same validation code, it's a duplicate
		index ===
			arrayObj.findIndex(
				(item) => item?.validationCode === value?.validationCode,
			);

	const filteredPromDetails = promotionDetails?.filter(isNotDuplicate);

	return (
		!!filteredPromDetails &&
		!!filteredPromDetails.length && (
			<StyledTableRow>
				{radioTitle && <TableCell />}
				<TableCell colSpan={12}>
					<StyledSpan>
						<FormattedMessage {...messages.lblPromotionProducts} />
					</StyledSpan>
					<IconButton onClick={handleExpand}>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
					<Collapse in={expanded}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<FormattedMessage {...messages.lblDiscountType} />
									</TableCell>
									<TableCell>
										<FormattedMessage {...messages.lblDiscountID} />
									</TableCell>
									<TableCell>
										<FormattedMessage {...messages.lblValidationCode} />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredPromDetails.map((promotion, index) => (
									<TableRow key={'promotion'.concat(index)}>
										<TableCell>{promotion.type}</TableCell>
										<TableCell>{promotion.id}</TableCell>
										<TableCell>{promotion.validationCode}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</StyledTableRow>
		)
	);
};

PromotionDetailsTable.propTypes = {
	promotionDetails: PropTypes.array,
	radioTitle: PropTypes.node,
};

export { PromotionDetailsTable as PromotionDetailsTableAlias };

export default PromotionDetailsTable;
