import { defineMessages } from 'react-intl';

export default defineMessages({
	orderSummary: {
		id: 'app.common.orderSummary.orderSummary',
		defaultMessage: 'Order Summary',
	},
	total: {
		id: 'app.common.orderSummary.total',
		defaultMessage: 'Total',
	},
	productSale: {
		id: 'app.common.orderSummary.productSale',
		defaultMessage: 'New Product Sale (Ticket)',
	},
	reservation: {
		id: 'app.common.orderSummary.reservation',
		defaultMessage: 'Reservation',
	},
	ticketedRsv: {
		id: 'app.common.orderSummary.ticketedRsv',
		defaultMessage: 'Ticket/Reservation',
	},
	taxNational: {
		id: 'app.common.orderSummary.taxNational',
		defaultMessage: 'Tax National',
	},
	taxStateProv: {
		id: 'app.common.orderSummary.taxStateProv',
		defaultMessage: 'Tax State/Province',
	},
	fee: {
		id: 'app.common.orderSummary.fee',
		defaultMessage: 'Fee',
	},
	corpDiscount: {
		id: 'app.common.orderSummary.corpDiscount',
		defaultMessage: 'Corporate Discount',
	},
	travelPass: {
		id: 'app.common.orderSummary.travelPass',
		defaultMessage: 'Season Pass',
	},
	transactionFee: {
		id: 'app.common.orderSummary.transactionFee',
		defaultMessage: 'Transaction Fee',
	},
	bookingFee: {
		id: 'app.common.orderSummary.bookingFee',
		defaultMessage: 'Booking Fee',
	},
	tdoFee: {
		id: 'app.common.orderSummary.tdoFee',
		defaultMessage: 'New Ticket Delivery',
	},
	item: {
		id: 'app.common.orderSummary.item',
		defaultMessage: 'Item',
	},
	price: {
		id: 'app.common.orderSummary.price',
		defaultMessage: 'Price',
	},
	outbound: {
		id: 'app.common.orderSummary.outbound',
		defaultMessage: 'Outbound',
	},
	inbound: {
		id: 'app.common.orderSummary.inbound',
		defaultMessage: 'Inbound',
	},
	travelCards: {
		id: 'app.common.orderSummary.travelCards',
		defaultMessage: 'Travelcards',
	},
	plusBusSupplements: {
		id: 'app.common.orderSummary.plusBusSupplements',
		defaultMessage: 'Local Bus Services',
	},
	onBoardServices: {
		id: 'app.common.orderSummary.onBoardServices',
		defaultMessage: 'Onboard Services',
	},
	seatReservations: {
		id: 'app.common.orderSummary.seatReservations',
		defaultMessage: 'Reservation',
	},
	bikeReservations: {
		id: 'app.common.orderSummary.bikeReservations',
		defaultMessage: 'Supplement: Bike Reservation',
	},
	preliminaryExchangeRecap: {
		id: 'app.booking.exchangeOrder.preliminaryExchangeRecap',
		defaultMessage: 'Preliminary Exchange Recap',
	},
	previousRevenue: {
		id: 'app.common.orderSummary.previousRevenue',
		defaultMessage: 'Previous Revenue Total (Ticket and Fees)',
	},
	reversalPreviousProductSale: {
		id: 'app.common.orderSummary.reversalPreviousProductSale',
		defaultMessage: 'Reversal of Previous Product Sale (Ticket)',
	},
	exchangePenalty: {
		id: 'app.common.orderSummary.exchangePenalty',
		defaultMessage: 'Exchange Penalty',
	},
	exchangeLossTotal: {
		id: 'app.common.orderSummary.exchangeLossTotal',
		defaultMessage:
			'Exchange Loss (ticket difference NOT refunded to customer)',
	},
	revenueTotal: {
		id: 'app.common.orderSummary.revenueTotal',
		defaultMessage: 'New Revenue Total (Ticket and Fees)',
	},
	paymentTotal: {
		id: 'app.common.orderSummary.paymentTotal',
		defaultMessage: 'Previous Payment Total',
	},
	balance: {
		id: 'app.common.orderSummary.balance',
		defaultMessage: 'New Balance',
	},
	previousBalance: {
		id: 'app.common.orderSummary.previousBalance',
		defaultMessage: 'Previous Balance',
	},
	toBeDefined: {
		id: 'app.common.orderSummary.toBeDefined',
		defaultMessage: 'TBD',
	},
	lblPromoCode: {
		id: 'app.shopping.search.promoCode',
		defaultMessage: 'Promo Code',
	},
	lblValidationCode: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.validationcode',
		defaultMessage: 'Validation Code',
	},
});
