import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import styles from './styles';

const StyledTableCellOptionsDesc = styled(TableCell)(() => ({
	...styles.optionsDescColumn,
}));

const StyledTableCellOptionsPrice = styled(TableCell)(() => ({
	...styles.optionsPriceColumn,
}));

const OptionRow = ({ formattedPrice, id, type, desc }) => (
	<TableRow>
		<TableCell id={`${id}Type`}>
			<FormattedMessage {...type} />
		</TableCell>
		<StyledTableCellOptionsDesc id={`${id}Desc`}>
			{desc}
		</StyledTableCellOptionsDesc>
		<StyledTableCellOptionsPrice id={`${id}Price`}>
			{formattedPrice}
		</StyledTableCellOptionsPrice>
	</TableRow>
);

OptionRow.propTypes = {
	formattedPrice: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	type: PropTypes.object.isRequired,
	desc: PropTypes.string,
};

export default OptionRow;
