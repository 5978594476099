import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { styled } from '@mui/system';

import MenuList from './MenuList';
import Option from './Option';
import NoOptionsMessage from './NoOptionsMessage';
import Control from './Control';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import MultiValue from './MultiValue';
import DropdownIndicator from './DropdownIndicator';
import { dropdownIndicatorStyle, menuStyle } from './ReactSelectWrappedStyle';
import messages from './messages';

const StyledSelectWrapper = styled('div')(({ theme }) => ({
	'& .input': {
		display: 'flex',
	},
	'& .valueContainer': {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
	},
	'& .chip': {
		margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
	},
	'& .chipFocused': {
		backgroundColor: 'lightgrey',
	},
	'& .noOptionsMessage': {
		fontSize: 16,
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
	},
	'& .singleValue': {
		fontSize: 16,
	},
	'& .placeholder': {
		position: 'absolute',
		left: 2,
		fontSize: 16,
		fontWeight: 200,
	},
	'& .MuiInputBase-input': {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

const components = {
	Option,
	Control,
	NoOptionsMessage,
	Placeholder,
	SingleValue,
	MultiValue,
	ValueContainer,
	MenuList,
	IndicatorSeparator: null,
	DropdownIndicator,
};

class ReactSelectWrapped extends React.PureComponent {
	state = {
		refreshing: false,
	};

	loadOptions = (input, callback) => {
		const { options, getOptionLabel, maxSearchResults, fastFilterSearchValue } =
			this.props;
		this.setState({ refreshing: true });
		setTimeout(() => {
			const filterValueLowerCase = input.toLowerCase();
			let optionsFiltered = options;
			if (filterValueLowerCase) {
				optionsFiltered = optionsFiltered.filter((option) => {
					let searchableString = getOptionLabel(option);
					if (fastFilterSearchValue) {
						const optionValue =
							typeof option.value === 'object'
								? Object.values(option.value).join(' ')
								: option.value;
						searchableString = `${searchableString} ${optionValue}`;
					}
					return (
						searchableString &&
						searchableString.toLowerCase().indexOf(filterValueLowerCase) !== -1
					);
				});
				optionsFiltered = maxSearchResults
					? optionsFiltered.slice(0, maxSearchResults - 1)
					: optionsFiltered;
			}
			callback(optionsFiltered);
			this.setState({ refreshing: false });
		});
	};

	render() {
		const {
			deleteDropdownIndicator,
			disabled,
			fastFilter,
			hasEmpty,
			innerRef,
			isLoading,
			loadOptions,
			maxSearchResults,
			options,
			style,
			value,
			windowing,
			intl: { formatMessage },
			dataTestId,
			...other
		} = this.props;
		const { refreshing } = this.state;

		const SelectComponent = fastFilter || loadOptions ? AsyncSelect : Select;

		const finalComponents = { ...components };
		if (!windowing) delete finalComponents.MenuList;
		if (deleteDropdownIndicator) finalComponents.DropdownIndicator = null;
		let placeholder = formatMessage(messages.lblPlaceholder);
		if (hasEmpty) {
			const emptyOption = options.find(
				(option) => option.value === null || option.value === undefined,
			);
			if (emptyOption) placeholder = emptyOption.label;
		}

		return (
			<StyledSelectWrapper style={style} data-testid={dataTestId}>
				<SelectComponent
					styles={{
						menu: (base) => ({ ...base, ...menuStyle }),
						dropdownIndicator: (base) => ({
							...base,
							...dropdownIndicatorStyle,
						}),
					}}
					classes={{}} // need this
					components={finalComponents}
					filterOption={fastFilter ? null : undefined}
					loadOptions={fastFilter ? this.loadOptions : loadOptions}
					isLoading={isLoading || refreshing}
					defaultOptions={
						maxSearchResults ? options.slice(0, maxSearchResults - 1) : options
					}
					options={fastFilter || loadOptions ? undefined : options}
					value={
						typeof value === 'object'
							? value
							: options.find((option) => option.value === value) || null
					}
					shortRow={windowing}
					isDisabled={disabled}
					placeholder={placeholder}
					ref={innerRef}
					menuShouldScrollIntoView={false}
					captureMenuScroll={!windowing && undefined}
					{...other}
				/>
			</StyledSelectWrapper>
		);
	}
}

ReactSelectWrapped.propTypes = {
	style: PropTypes.object,
	intl: PropTypes.object,
	options: PropTypes.array,
	fastFilter: PropTypes.bool,
	windowing: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.array,
		PropTypes.bool,
		PropTypes.object,
	]),
	disabled: PropTypes.bool,
	hasEmpty: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	maxSearchResults: PropTypes.number,
	deleteDropdownIndicator: PropTypes.bool,
	innerRef: PropTypes.func,
	fastFilterSearchValue: PropTypes.bool,
	isLoading: PropTypes.bool,
	loadOptions: PropTypes.func,
	dataTestId: PropTypes.string,
};

ReactSelectWrapped.defaultProps = {
	fastFilter: false,
	windowing: false,
	hasEmpty: false,
	getOptionLabel: (option) => option.label,
	deleteDropdownIndicator: false,
	fastFilterSearchValue: false,
	isLoading: false,
};

export default injectIntl(ReactSelectWrapped);
