import React from 'react';
import PropTypes from 'prop-types';
// import Typography from '@mui/material/Typography';
import Typography from '@mui/material/Typography';

export default function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.placeholder}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

Placeholder.propTypes = {
	innerProps: PropTypes.object,
	selectProps: PropTypes.object,
	children: PropTypes.node,
};
