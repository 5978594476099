export const getLists = (
	formatMessage,
	seatPrefByType,
	messages,
	selectedDirection,
	selectedPosition,
	selectedCoach,
	selectedLevel,
	selectedLayout,
) => {
	const directionSelect = [
		{ value: '', description: formatMessage(messages.lblSelectDirection) },
	];
	const positionSelect = [
		{ value: '', description: formatMessage(messages.lblSelectPosition) },
	];
	const coachSelect = [
		{ value: '', description: formatMessage(messages.lblSelectCoach) },
	];
	const levelSelect = [
		{ value: '', description: formatMessage(messages.lblSelectLevel) },
	];
	const layoutSelect = [
		{ value: '', description: formatMessage(messages.lblSelectLayout) },
	];
	const directionList =
		seatPrefByType.directionOptions.length > 0
			? directionSelect.concat(seatPrefByType.directionOptions)
			: [];
	const positionList =
		seatPrefByType.positionOptions.length > 0
			? positionSelect.concat(seatPrefByType.positionOptions)
			: [];
	const coachList =
		seatPrefByType.coachOptions.length > 0
			? coachSelect.concat(seatPrefByType.coachOptions)
			: [];
	const levelList =
		seatPrefByType.levelOptions.length > 0
			? levelSelect.concat(seatPrefByType.levelOptions)
			: [];
	const layoutList =
		seatPrefByType.layoutOptions.length > 0
			? layoutSelect.concat(seatPrefByType.layoutOptions)
			: [];

	return [
		{ type: 'direction', list: directionList, selected: selectedDirection },
		{ type: 'position', list: positionList, selected: selectedPosition },
		{ type: 'coach', list: coachList, selected: selectedCoach },
		{ type: 'level', list: levelList, selected: selectedLevel },
		{ type: 'layout', list: layoutList, selected: selectedLayout },
	];
};

export const getSelectedSeatReservations = (
	selectedSeatReservations,
	TravelSegmentID,
	passengerId,
) => {
	const segmentReservation =
		selectedSeatReservations && selectedSeatReservations[TravelSegmentID]
			? selectedSeatReservations[TravelSegmentID]
			: null;
	const enableAll = segmentReservation?.enableAllSeats
		? segmentReservation.enableAllSeats
		: false;
	const selectedReservation =
		segmentReservation && segmentReservation[passengerId]
			? segmentReservation[passengerId]
			: {};
	const selectedDirection = selectedReservation?.direction || '';
	const selectedPosition = selectedReservation?.position || '';
	const selectedCoach = selectedReservation?.coach || '';
	const selectedLevel = selectedReservation?.level || '';
	const selectedLayout = selectedReservation?.layout || '';
	const selectedNearbyCoach = selectedReservation?.nearbyCoach || '';
	const selectedNearbySeat = selectedReservation?.nearbySeat || '';
	const selectedDisabled = selectedReservation?.enablePassengerSeats
		? !selectedReservation.enablePassengerSeats
		: true;

	return {
		enableAll,
		selectedReservation,
		selectedDirection,
		selectedPosition,
		selectedCoach,
		selectedLevel,
		selectedLayout,
		selectedNearbyCoach,
		selectedNearbySeat,
		selectedDisabled,
	};
};

export const getAvailableDeckIndex = (decks) =>
	decks?.findIndex((deck) => deck.numAvailableSeats !== 0);

export const getAvailableDeck = (decks) =>
	decks && decks[getAvailableDeckIndex(decks)];

export const getAvailableCoach = (coaches) =>
	coaches?.find((coach) =>
		getAvailableDeck(
			coach?.decks.filter((deck) => deck?.numAvailableSeats > 0),
		),
	);

export const getDeckLevel = (availableCoach, availableDeck) =>
	availableCoach?.decks?.findIndex((deck) => deck.id === availableDeck.id) === 0
		? 'Upper'
		: 'Lower';

export default getLists;
