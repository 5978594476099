import colors from '../../../../colors';
import commonStyles from '../../../../commonStyles';
import bookingViewStyles from '../BookingView/styles';

export default {
	contentContainer: bookingViewStyles.contentContainer,
	paperContainer: bookingViewStyles.paperContainer,
	tableWrapper: commonStyles.tableWrapper,
	bookingTable: bookingViewStyles.bookingTable,
	bookingTableRow: bookingViewStyles.bookingTableRow,
	bookingTableLink: bookingViewStyles.bookingTableLink,
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	removePassengerButton: {
		color: colors.buttonRed,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	removePassengerIcon: {
		marginRight: 5,
	},
	customInformationIcon: {
		padding: 0,
	},
	passengerRights: {
		color: '#2994A8',
		fontWeight: 400,
		marginLeft: 25,
	},
};
