import { fromJS, Set } from 'immutable';
import {
	FARES_SELECTED,
	INITIATE_SEARCH,
	DISMISS_SEARCH_RESPONSE,
	RECEIVE_SEARCH,
	CLEAR_FARES_SELECTED,
	CLEAR_SEARCH_INITIATE,
	CLEAR_SEARCH_RESULTS,
	SET_CHANGE_JOURNEY,
	CLEAR_CHANGE_JOURNEY,
} from './actionsShoppingResults';
import { toTitleCase } from '../../utils';

// helper functions
const initializeFilters = (response) => {
	const results = response;

	let typeClasses = new Set();
	let cabinClasses = new Set();
	let scheduleClasses = new Set();

	results.ShoppingLegs.forEach((leg) => {
		leg.ShoppingLegSolutions.forEach((legSolution) => {
			legSolution.ShoppingLegFares.forEach((fareOrig) => {
				fareOrig.ShoppingLegFareCodeInfo.forEach((fareCode) => {
					typeClasses = fareCode.fareTypeClass
						? typeClasses.add(fareCode.fareTypeClass)
						: typeClasses;
					cabinClasses = fareCode.ShoppingLegFarePrimaryColumnClass
						? cabinClasses.add(fareCode.ShoppingLegFarePrimaryColumnClass)
						: cabinClasses;
					scheduleClasses = fareCode.scheduleClass
						? scheduleClasses.add(fareCode.scheduleClass)
						: scheduleClasses;
				});
			});
		});
	});

	const filters = {};
	if (!typeClasses.isEmpty()) {
		filters.type = typeClasses.toArray();
	}

	if (!cabinClasses.isEmpty()) {
		filters.cabin = cabinClasses.toArray();
	}

	if (!scheduleClasses.isEmpty()) {
		filters.schedule = scheduleClasses.toArray();
	}
	return filters;
};

const getFareClass = (fCode) => {
	let fareTypeClass = 'Single';

	const displayName = fCode.ShoppingLegFareFareClass.match(
		/Single|Open Return|Return|Travelcard|Season|Carnet|Sleeper/i,
	);
	if (displayName && displayName.length > 0) {
		fareTypeClass = toTitleCase(displayName[0]);
	}
	return fareTypeClass;
};

const prettifyResults = (response) => {
	const results = response;
	results.ShoppingLegs = results.ShoppingLegs.map((legOrig) => {
		const leg = legOrig;
		leg.ShoppingLegSolutions = leg.ShoppingLegSolutions.map(
			(legSolutionOrig) => {
				const legSolution = legSolutionOrig;
				legSolution.ShoppingLegFares = legSolution.ShoppingLegFares.map(
					(fareOrig) => {
						const fare = fareOrig;
						fare.ShoppingLegFareCodeInfo = fare.ShoppingLegFareCodeInfo.map(
							(fareCodeOrig) => {
								const fCode = fareCodeOrig;
								const fareClassDesc = fCode.ShoppingLegFareFareClassDesc;
								fCode.fareTypeClass = getFareClass(fCode);
								const fareDescTemp = fareClassDesc
									.replace(/(.*)\(1st Class\)|\(Online\)(.*)/i, '$1$2')
									.replace(/(\/)/i, '$1 ')
									.trim();
								fCode.scheduleClass = fareDescTemp
									.replace(
										/(.*)Single|Return|Open Return|Travelcard|Season(.*)/i,
										'$1$2',
									)
									.replace(/(\/)/i, '$1 ')
									.trim();
								fCode.expirationDate =
									fCode.ShoppingLegFareExpirationDate || '';

								return fCode;
							},
						);
						return fare;
					},
				);
				return legSolution;
			},
		);
		return leg;
	});

	return results;
};

const initialResultsState = fromJS({
	faresSelected: [],
	isSearchInitiated: false,
	errorResponse: '',
	results: {},
	filterCategories: {},
	sessionToken: '',
	changeJourney: false,
});

export default (state = initialResultsState, action) => {
	switch (action.type) {
		case FARES_SELECTED:
			return state.merge({ faresSelected: action.fares });
		case INITIATE_SEARCH:
			return state.merge({
				isSearchInitiated: true,
				errorResponse: '',
				results: {},
				faresSelected: [],
				sessionToken: '',
			});
		case RECEIVE_SEARCH: {
			const updates = {
				isSearchInitiated: false,
			};
			if (action.error) {
				updates.errorResponse = action.payload;
			} else {
				const response = prettifyResults(action.payload.data);
				updates.sessionToken = action.payload.sessionDataToken;
				updates.results = fromJS(response);
				updates.filterCategories = fromJS(initializeFilters(response));
			}
			return state.merge(updates);
		}
		case DISMISS_SEARCH_RESPONSE:
			return state.merge({
				errorResponse: '',
			});
		case CLEAR_FARES_SELECTED:
			return state.merge({
				faresSelected: [],
			});
		case CLEAR_SEARCH_INITIATE:
			return state.merge({
				isSearchInitiated: false,
			});
		case CLEAR_SEARCH_RESULTS:
			return state.merge({
				isSearchInitiated: false,
				errorResponse: '',
				results: {},
				faresSelected: [],
				sessionToken: '',
			});
		case SET_CHANGE_JOURNEY:
			return state.merge({
				changeJourney: true,
			});
		case CLEAR_CHANGE_JOURNEY:
			return state.merge({
				changeJourney: false,
			});
		default:
			return state;
	}
};
