import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import messages from './messagesTicketDelivery';

function TdoRegionsView({
	intl: { formatMessage },
	onTdoRegionChange,
	tdoRegion,
	tdoRegions,
}) {
	return (
		<div>
			<TextField
				id="srtTdoRegions"
				fullWidth
				placeholder={formatMessage(messages.lblTDORegionDeliver)}
				value={tdoRegion}
				onChange={onTdoRegionChange}
				select
				variant="standard"
			>
				{tdoRegions.map((region, index) => (
					<MenuItem key={index} value={region}>
						{region}
					</MenuItem>
				))}
			</TextField>
		</div>
	);
}

TdoRegionsView.propTypes = {
	intl: PropTypes.object,
	onTdoRegionChange: PropTypes.func,
	tdoRegion: PropTypes.string,
	tdoRegions: PropTypes.array,
};

export { TdoRegionsView as TdoRegionsViewAlias };

export default injectIntl(TdoRegionsView);
