export default {
	menuItem: {
		fontWeight: 400,
	},
	selected: {
		fontWeight: 500,
	},
	shortRow: {
		fontSize: 15,
		paddingTop: 0,
		paddingBottom: 0,
	},
};
