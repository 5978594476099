import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.manageBooking.title',
		defaultMessage: 'Manage Booking',
	},
	lblHomeTab: {
		id: 'app.shopping.manageBooking.homeTab',
		defaultMessage: 'Search booking',
	},
	btnSearchAgain: {
		id: 'app.shopping.manageBooking.searchAgain',
		defaultMessage: 'Search Again',
	},
	btnResetFields: {
		id: 'app.shopping.manageBooking.resetFields',
		defaultMessage: 'Reset Fields',
	},
	btnRetrieveBookings: {
		id: 'app.shopping.manageBooking.retrieveBookings',
		defaultMessage: 'Retrieve Bookings',
	},
	errAtLeastOneField: {
		id: 'app.shopping.manageBooking.search.atLeastOneField',
		defaultMessage: 'At least one field must be entered to search',
	},
	errNoBookings: {
		id: 'app.shopping.manageBooking.search.noBookings',
		defaultMessage:
			'Unable to find a matching booking - please check your search criteria and search again',
	},
	errBookingLocatorInvalid: {
		id: 'app.shopping.manageBooking.search.bookingLocatorInvalid',
		defaultMessage:
			'^Please use a properly formatted Booking Locator (e.g B-DEMO-ABC123456)',
	},
	errPhoneInvalid: {
		id: 'app.shopping.manageBooking.search.phoneInvalid',
		defaultMessage: '^Please enter a valid phone number',
	},
	errPhoneLength: {
		id: 'app.shopping.manageBooking.search.phoneLength',
		defaultMessage: '^Please enter a phone number with less then 32 numbers',
	},
	errEmailInvalid: {
		id: 'app.shopping.manageBooking.search.emailInvalid',
		defaultMessage: '^Please enter a valid email address',
	},
	errDateInvalid: {
		id: 'app.shopping.manageBooking.search.dateInvalid',
		defaultMessage: '^Please enter a valid departure date',
	},
	errDateOnly: {
		id: 'app.shopping.manageBooking.search.dateOnly',
		defaultMessage:
			'Depart date can only be used in conjunction with one or more of the other search fields',
	},
});
