import { defineMessages } from 'react-intl';

export default defineMessages({
	selectLocaleLang: {
		id: 'app.common.selectLocale.lang',
		defaultMessage: '{language}',
	},
	selectLocale: {
		id: 'app.common.selectLocale',
		defaultMessage: 'Locale (Language/Country)',
	},
});
