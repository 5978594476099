import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Paper from '@mui/material/Paper';

import './fareItinerary.css';
import Fare from './Fare';
import inlineStyles from './fareSeasonStyles';

const firstCol = 'col-8 col-lg-7 col-xl-8';

class FareSeason extends Component {
	static propTypes = {
		itinerary: PropTypes.shape({
			legSolutionId: PropTypes.string,
			itineraryNumber: PropTypes.string,
			departStation: PropTypes.string,
			arriveStation: PropTypes.string,
			departTime: PropTypes.string,
			departDaysOffsetNum: PropTypes.number,
			departDaysOffsetLabel: PropTypes.string,
			arriveTime: PropTypes.string,
			arriveDaysOffsetNum: PropTypes.number,
			arriveDaysOffsetLabel: PropTypes.string,
			elapsedTime: PropTypes.string,
			nrChanges: PropTypes.number,
			carrierLogo: PropTypes.string,
			overtaken: PropTypes.bool,
			details: PropTypes.array,
			fares: PropTypes.array,
			marketingCarrier: PropTypes.object,
		}),
		onChangeSelection: PropTypes.func.isRequired,
		onSelection: PropTypes.func.isRequired,
		selectedFare: PropTypes.object,
		singleFareMode: PropTypes.bool,
		openFareRulesMatrix: PropTypes.bool,
		departDate: PropTypes.string,
		endDate: PropTypes.string,
		isCreateBookingPage: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			data: new Map({
				showDetails: false,
			}),
		};
	}

	handleDetails = (event, showDetails) => {
		this.setState(({ data }) => ({
			data: data.merge({ showDetails }),
		}));
	};

	handleFareChangeSelection = (fare) => {
		this.props.onChangeSelection(fare);
	};

	handleFareSelection = (fare) => {
		this.props.onSelection(fare, this.props.itinerary.legSolutionId);
	};

	render() {
		const {
			departStation,
			arriveStation,
			fares,
			marketingCarrier,
			itineraryNumber,
		} = this.props.itinerary;

		const {
			selectedFare,
			singleFareMode,
			openFareRulesMatrix,
			departDate,
			endDate,
			isCreateBookingPage,
		} = this.props;

		const leftColumn = singleFareMode
			? 'col-sm-9 col-md-7 col-lg-8'
			: 'col-sm-6 col-md-5 col-lg-3';
		const rightColumn = singleFareMode
			? 'col-sm-3 col-md-5 col-lg-4'
			: 'col-sm-6 col-md-7 col-lg-9';
		const itineraryNumberCleaned = itineraryNumber.replace('#', '');
		return (
			<div className="col-12" id={`Itinerary_LS_1_${itineraryNumberCleaned}`}>
				<Paper className="row" style={inlineStyles.itinerary} elevation={1}>
					<div className={leftColumn}>
						<div className="row" styleName="station">
							<div className={firstCol}>{departStation}</div>
						</div>
						<div className="row" styleName="station">
							<div className={firstCol}>{arriveStation}</div>
						</div>
						<div className="row" styleName="station">
							<div className={firstCol}>{departDate}</div>
						</div>
						{!endDate || (
							<div className="row" styleName="station">
								<div className={firstCol}>{endDate}</div>
							</div>
						)}
					</div>
					<div className={rightColumn}>
						{fares.map((fare, index) => {
							let fareComp = null;
							const fareSelected = selectedFare
								? selectedFare.fare.priceId === fare.priceId
								: false;
							if (!selectedFare || fareSelected) {
								fareComp = (
									<Fare
										key={index}
										fare={fare}
										marketingCarrier={marketingCarrier}
										onChangeSelection={this.handleFareChangeSelection}
										onSelection={this.handleFareSelection}
										selected={fareSelected}
										hideChangeSelection={singleFareMode}
										onOpenFareDetails={() => {}}
										openFareRulesMatrix={openFareRulesMatrix}
										isCreateBookingPage={isCreateBookingPage}
									/>
								);
							}
							return fareComp;
						})}
					</div>
				</Paper>
			</div>
		);
	}
}

export default FareSeason;
