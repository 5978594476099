import {
	SET_ACCESS_TOKEN,
	SET_ACCESS_TOKEN_EXPIRY_TIME,
} from '../constants/constants';

export const setAccessToken = (token) => ({
	type: SET_ACCESS_TOKEN,
	payload: token,
});

export const setAccessTokenExpiryTime = (time) => ({
	type: SET_ACCESS_TOKEN_EXPIRY_TIME,
	payload: time,
});
