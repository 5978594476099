import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import styles from './styles';

import { getRefundOffersApi, confirmRefundOfferApi } from '../../apiBooking';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';

const OSDMCancelOrderDialog = ({
	open,
	onCloseDialog,
	accountContext,
	bookingRecordLocator,
	refreshBookingOnSuccess,
	refreshBookingParams,
}) => {
	const [osdmData, setOsdmData] = useState(null);
	const [selectedRefundOfferId, setSelectedRefundOfferId] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const [isFetching, setIsFetching] = useState(true);
	const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);

	useEffect(() => {
		if (accountContext && bookingRecordLocator) {
			getRefundOffersApi(
				{
					bookingRecordLocator,
					accountContext,
				},
				(error) => {
					setAlertMessage(error?.errorResponse.message);
					setIsFetching(false);
				},
				(success) => {
					setOsdmData(success.successResponse.data);
					setIsFetching(false);
				},
			);
		}
	}, [bookingRecordLocator, accountContext]);

	const handleDialogClose = () => {
		setSelectedRefundOfferId('');
		setIsFetching(false);
		setIsProcessingSubmit(false);
		setAlertMessage('');
		onCloseDialog();
	};

	const handleRequestCancellation = () => {
		setIsProcessingSubmit(true);

		confirmRefundOfferApi(
			{
				accountContext,
				bookingRecordLocator,
				osdmRecordLocator: osdmData.osdmRecordLocator,
				selectedRefundOfferId,
			},
			(error) => {
				setAlertMessage(error?.errorResponse.message);
				setIsProcessingSubmit(false);
			},
			() => {
				setAlertMessage('Cancellation successfully requested');
				setTimeout(() => {
					refreshBookingOnSuccess(refreshBookingParams);
					handleDialogClose();
				}, 2500);
			},
		);
	};

	const handleRadioChange = (event) => {
		setSelectedRefundOfferId(event.target.value);
	};

	return (
		<Dialog
			open={open}
			onClose={handleDialogClose}
			disableEnforceFocus
			maxWidth={false}
			style={styles.dialogPaper}
		>
			<DialogTitle style={styles.dialogTitle}>Cancel Order</DialogTitle>
			<DialogContent style={styles.dialogContent}>
				{osdmData && (
					<>
						<div style={styles.bookingReferenceContainer}>
							<Typography variant="body1" style={styles.bookingReference}>
								Booking Reference:
							</Typography>
							<Typography variant="body2">
								{osdmData.osdmRecordLocator}
							</Typography>
						</div>

						<Typography variant="body1" style={styles.selectOption}>
							Select a cancellation option:
						</Typography>
						<RadioGroup
							aria-label="osdm-cancellation-options"
							name="osdm-cancellation-options"
							value={selectedRefundOfferId}
							onChange={handleRadioChange}
							style={styles.radioGroup}
						>
							{osdmData.refundOffers.map((offer) => (
								<FormControlLabel
									key={offer.id}
									value={offer.id}
									control={<Radio />}
									label={
										<div style={styles.radioLabel}>
											<span>{offer.id}</span>
											<div style={styles.priceInfo}>
												<Typography variant="body2">
													Ticket Price: £{offer.refundable.amount.toFixed(2)}
												</Typography>
												<Typography variant="body2">
													Cancellation Penalty: £{offer.fee.amount.toFixed(2)}
												</Typography>
												<Typography variant="body2">
													Refund Amount: £
													{(offer.refundable.amount - offer.fee.amount).toFixed(
														2,
													)}
												</Typography>
											</div>
										</div>
									}
								/>
							))}
						</RadioGroup>
					</>
				)}
			</DialogContent>
			<DialogActions style={styles.actions}>
				<Button
					variant="contained"
					id="srtOSDMCancelOrderDialogClose"
					onClick={handleDialogClose}
				>
					CLOSE
				</Button>
				<Button
					variant="contained"
					color="primary"
					id="srtOSDMCancelOrderDialogRequestCancellation"
					onClick={handleRequestCancellation}
					disabled={!selectedRefundOfferId || isProcessingSubmit || isFetching}
				>
					REQUEST CANCELLATION
				</Button>
			</DialogActions>

			<ExtendedSnackbar
				id="osdm-alert-message"
				open={alertMessage !== ''}
				message={alertMessage}
				onClose={() => setAlertMessage('')}
			/>

			<RefreshIndicator
				size={36}
				style={{ top: '50%', left: '50%' }}
				status={isFetching || isProcessingSubmit ? 'loading' : 'hide'}
			/>
		</Dialog>
	);
};

OSDMCancelOrderDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onCloseDialog: PropTypes.func.isRequired,
	accountContext: PropTypes.string.isRequired,
	bookingRecordLocator: PropTypes.string.isRequired,
	refreshBookingParams: PropTypes.object.isRequired,
	refreshBookingOnSuccess: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	refreshBookingOnSuccess: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	null,
	mapDispatchToProps,
)(injectIntl(OSDMCancelOrderDialog));
