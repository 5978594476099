import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import DatetimePicker from '../../../components/DateTimePicker/DateTimePicker';

import messages from './messagesTrackingInfo';

function TrackingInfo(props) {
	const { formatMessage } = props.intl;
	const { id, trackingInfo, disabled, errors, onChange } = props;

	const trackingDetails = trackingInfo.trackingInfoDetails;
	const fulfillmentInfo = trackingDetails.BookingFulfillmentInfo;
	const minShipDate = new Date(new Date().setHours(0, 0, 0, 0));

	const handleChangeField = (event) => {
		onChange({ [event.target.name]: event.target.value });
	};

	const handleChangeShipmentDate = (date) => {
		onChange({ shipmentDate: date.getTime() });
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<h3>{formatMessage(messages.lblShippingAddress)}</h3>
					<TextField
						id={`${id}_trackingCountry`}
						label={formatMessage(messages.lblCountry)}
						fullWidth
						value={
							fulfillmentInfo
								? fulfillmentInfo.BookingFulfillmentShippingCountry
								: ''
						}
						disabled
						variant="standard"
					/>
					{!(
						fulfillmentInfo &&
						fulfillmentInfo.BookingFulfillmentShippingStateProv
					) || (
						<TextField
							id={`${id}_trackingProvinceState`}
							label={formatMessage(messages.lblProvinceState)}
							fullWidth
							value={
								fulfillmentInfo
									? fulfillmentInfo.BookingFulfillmentShippingStateProvr
									: ''
							}
							disabled
							variant="standard"
						/>
					)}
					<TextField
						id={`${id}_trackingName`}
						label={formatMessage(messages.lblName)}
						fullWidth
						value={
							fulfillmentInfo
								? fulfillmentInfo.BookingFulfillmentShippingName
								: ''
						}
						disabled
						variant="standard"
					/>
					{!(
						fulfillmentInfo && fulfillmentInfo.BookingFulfillmentShippingEmail
					) || (
						<TextField
							id={`${id}_trackingEmail`}
							label={formatMessage(messages.lblEmail)}
							fullWidth
							value={
								fulfillmentInfo
									? fulfillmentInfo.BookingFulfillmentShippingEmail
									: ''
							}
							disabled
							variant="standard"
						/>
					)}
					{!(
						fulfillmentInfo && fulfillmentInfo.BookingFulfillmentShippingCompany
					) || (
						<TextField
							id={`${id}_trackingCompany`}
							label={formatMessage(messages.lblCompany)}
							fullWidth
							value={
								fulfillmentInfo
									? fulfillmentInfo.BookingFulfillmentShippingCompany
									: ''
							}
							disabled
							variant="standard"
						/>
					)}
					<TextField
						id={`${id}_trackingAddress1`}
						label={formatMessage(messages.lblAddress1)}
						fullWidth
						value={
							fulfillmentInfo
								? fulfillmentInfo.BookingFulfillmentShippingAddress1
								: ''
						}
						disabled
						variant="standard"
					/>
					{!(
						fulfillmentInfo &&
						fulfillmentInfo.BookingFulfillmentShippingAddress2
					) || (
						<TextField
							id={`${id}_trackingAddress2`}
							label={formatMessage(messages.lblAddress2)}
							fullWidth
							value={
								fulfillmentInfo
									? fulfillmentInfo.BookingFulfillmentShippingAddress2
									: ''
							}
							disabled
							variant="standard"
						/>
					)}
					<TextField
						id={`${id}_trackingCity`}
						label={formatMessage(messages.lblCity)}
						fullWidth
						value={
							fulfillmentInfo
								? fulfillmentInfo.BookingFulfillmentShippingCity
								: ''
						}
						disabled
						variant="standard"
					/>
					<TextField
						id={`${id}_trackingPostalCode`}
						label={formatMessage(messages.lblPostalCode)}
						fullWidth
						value={
							fulfillmentInfo
								? fulfillmentInfo.BookingFulfillmentShippingZipCode
								: ''
						}
						disabled
						variant="standard"
					/>
					<h3>{formatMessage(messages.lblTrackingInfo)}</h3>
					<TextField
						id={`${id}_deliveryMethod`}
						name="deliveryMethod"
						onChange={handleChangeField}
						label={formatMessage(messages.lblDeliveryMethod)}
						fullWidth
						value={trackingInfo.deliveryMethod}
						disabled={disabled}
						variant="standard"
					/>
					<TextField
						id={`${id}_trackingNumber`}
						name="trackingNumber"
						onChange={handleChangeField}
						label={formatMessage(messages.lblTrackingNumber)}
						fullWidth
						value={trackingInfo.trackingNumber}
						disabled={disabled}
						variant="standard"
					/>
					<DatetimePicker
						id={`${id}_shipmentDate`}
						name="shipmentDate"
						onChange={handleChangeShipmentDate}
						errorText={errors && errors.shipmentDate}
						label={formatMessage(messages.lblShipmentDate)}
						fullWidth
						minDate={minShipDate}
						value={
							trackingInfo.shipmentDate && new Date(trackingInfo.shipmentDate)
						}
						disabled={disabled}
					/>
					<TextField
						id={`${id}_shipperUrl`}
						name="shipperUrl"
						onChange={handleChangeField}
						label={formatMessage(messages.lblShipperUrl)}
						error={errors && !!errors.shipperUrl}
						helperText={errors && errors.shipperUrl}
						fullWidth
						value={trackingInfo.shipperUrl}
						disabled={disabled}
						variant="standard"
					/>
				</div>
			</div>
		</div>
	);
}

TrackingInfo.propTypes = {
	id: PropTypes.string,
	intl: PropTypes.object,
	trackingInfo: PropTypes.object,
	errors: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};

TrackingInfo.defaultProps = {
	id: 'strTrackingInfo',
};

export { TrackingInfo as TrackingInfoAlias };
export default injectIntl(TrackingInfo);
