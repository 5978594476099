import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/system';
import validate from 'validate.js';
import { formatPrice } from '../../utils';
import FaresSelectedView from '../../Shopping/components/FaresSelectedView';

import OrderSummaryBalance from './OrderSummaryBalance';
import OrderSummaryItemText from './OrderSummaryItemText';
import messages from './messagesOrderSummary';
import inlineStyles from './styles';

const StyledCardHeaderTitle = styled(CardHeader)(() => ({
	...inlineStyles.cardTitle,
}));

class OrderSummary extends PureComponent {
	static propTypes = {
		intl: PropTypes.object,
		orderDetails: PropTypes.shape({
			orderTotalPrice: PropTypes.number,
			totalPriceTicket: PropTypes.number,
			totalPriceReservation: PropTypes.number,
			totalPriceTicketedRsv: PropTypes.number,
			totalPriceTaxNational: PropTypes.number,
			totalPriceTaxStateProv: PropTypes.number,
			totalPriceFee: PropTypes.number,
			totalPriceCorpDiscount: PropTypes.number,
			totalPriceTravelPass: PropTypes.number,
			totalPriceTransactionFee: PropTypes.number,
			totalFees: PropTypes.number,
			tdoFee: PropTypes.number,
			totalPriceCurrency: PropTypes.string,
		}),
		exchangeSummary: PropTypes.object,
		faresSelected: PropTypes.object,
		departDate: PropTypes.object,
		returnDate: PropTypes.object.isRequired,
		travelCards: PropTypes.object,
		selectedPlusBusSupplements: PropTypes.array,
		onBoardServices: PropTypes.array,
		seatReservationsPrice: PropTypes.number,
		bikeReservationsPrice: PropTypes.number,
		tripType: PropTypes.number,
		addOrderMode: PropTypes.bool,
		exchangeOrderMode: PropTypes.bool,
		onChangeSelection: PropTypes.func,
	};

	static defaultProps = {
		orderDetails: {
			orderTotalPrice: 0.0,
			totalPriceTicket: 0.0,
			totalPriceReservation: 0.0,
			totalPriceTicketedRsv: 0.0,
			totalPriceTaxNational: 0.0,
			totalPriceTaxStateProv: 0.0,
			totalPriceFee: 0.0,
			totalPriceCorpDiscount: 0.0,
			totalPriceTravelPass: 0.0,
			totalPriceTransactionFee: 0.0,
			totalFees: 0.0,
			tdoFee: 0.0,
			totalPriceCurrency: '',
			legs: [],
		},
		exchangeSummary: {},
		departDate: '',
		travelCards: {},
		selectedPlusBusSupplements: [],
	};

	render() {
		const {
			orderTotalPrice,
			totalPriceTicket,
			totalPriceReservation,
			totalPriceTicketedRsv,
			totalPriceTaxNational,
			totalPriceTaxStateProv,
			totalPriceFee,
			totalPriceCorpDiscount,
			totalPriceTravelPass,
			totalPriceTransactionFee,
			totalFees,
			tdoFee,
			totalPriceCurrency,
		} = this.props.orderDetails;
		const {
			intl,
			faresSelected,
			departDate,
			returnDate,
			travelCards,
			onBoardServices,
			seatReservationsPrice,
			bikeReservationsPrice,
			tripType,
			exchangeSummary,
			addOrderMode,
			exchangeOrderMode,
			intl: { formatMessage },
			onChangeSelection,
			selectedPlusBusSupplements,
		} = this.props;

		let bookingTotalPrice = orderTotalPrice;

		const tableData = [];
		const tableFooterData = [];

		if (exchangeSummary && exchangeSummary.size > 0) {
			const currency = exchangeSummary.get('BookingExchangeCurrency');
			tableData.push({
				id: 'srtExchangesPreviousRevenue',
				text: formatMessage(messages.previousRevenue),
				price: formatPrice(
					exchangeSummary.get('BookingExchangePreviousRevenue'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesPaymentTotal',
				text: formatMessage(messages.paymentTotal),
				price: formatPrice(
					exchangeSummary.get('BookingExchangePaymentTotal'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesPreviousBalance',
				text: formatMessage(messages.previousBalance),
				price: formatPrice(
					exchangeSummary.get('BookingExchangePreviousBalance'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesReversalPreviousProductSale',
				text: formatMessage(messages.reversalPreviousProductSale),
				price: formatPrice(
					exchangeSummary.get('BookingExchangeReversalPreviousProductSale'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesProductSale',
				text: formatMessage(messages.productSale),
				price: formatPrice(
					exchangeSummary.get('BookingExchangeProductSale'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesTicketDelivery',
				text: formatMessage(messages.tdoFee),
				price: formatPrice(
					exchangeSummary.get('BookingExchangeTicketDeliveryFee'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesPenalty',
				text: formatMessage(messages.exchangePenalty),
				price: formatPrice(
					exchangeSummary.get('BookingExchangePenalty'),
					currency,
					intl,
				),
			});
			tableData.push({
				id: 'srtExchangesPenalty',
				text: formatMessage(messages.exchangeLossTotal),
				price: formatPrice(
					exchangeSummary.get('BookingExchangeLossTotal', 0),
					currency,
					intl,
				),
			});

			tableFooterData.push({
				id: 'srtExchangesRevenueTotal',
				text: formatMessage(messages.revenueTotal),
				price: formatPrice(
					exchangeSummary.get('BookingExchangeRevenueTotal'),
					currency,
					intl,
				),
			});
			tableFooterData.push({
				id: 'srtExchangesBalance',
				text: formatMessage(messages.balance),
				price: formatPrice(
					exchangeSummary.get('BookingExchangeBalance'),
					currency,
					intl,
				),
			});

			bookingTotalPrice = exchangeSummary.get('BookingExchangeBalance');
		} else {
			if (totalPriceTransactionFee > -1) {
				tableData.push({
					id: 'srtTotalPriceTransactionFee',
					text: formatMessage(messages.transactionFee),
					price: formatPrice(
						totalPriceTransactionFee,
						totalPriceCurrency,
						intl,
					),
				});
			}

			if (totalPriceFee > -1) {
				tableData.push({
					id: 'srtTotalPriceFee',
					text: formatMessage(messages.fee),
					price: formatPrice(totalPriceFee, totalPriceCurrency, intl),
				});
			}

			if (totalPriceTicket > -1) {
				const campaignInfos = faresSelected
					?.toJS()
					?.map((selectedFare) => selectedFare?.fare?.campaignInfo);
				let campaignPrice = null;
				let campaignPromoCode = '';
				let campaignValidationCode = '';
				if (campaignInfos) {
					campaignInfos.forEach((campaignInfo) => {
						const campaignPriceItem =
							!campaignInfo?.message && campaignInfo?.success
								? campaignInfo?.value?.value
								: '';
						if (campaignPriceItem !== '') {
							campaignPrice += parseFloat(campaignPriceItem);
						}
						campaignPromoCode = campaignInfo?.promoCode;
						campaignValidationCode = campaignInfo?.validationCode;
					});
				}
				const resultantPrice =
					campaignPrice !== null ? campaignPrice : totalPriceTicket;
				bookingTotalPrice =
					campaignPrice !== null ? resultantPrice : orderTotalPrice;
				if (campaignPromoCode !== '') {
					tableData.push({
						id: 'srtTotalPriceTicket',
						text: formatMessage(messages.productSale),
						price: formatPrice(resultantPrice, totalPriceCurrency, intl),
						campaignPromoCode,
						campaignValidationCode,
					});
				} else {
					tableData.push({
						id: 'srtTotalPriceTicket',
						text: formatMessage(messages.productSale),
						price: formatPrice(resultantPrice, totalPriceCurrency, intl),
					});
				}
			}

			if (totalPriceReservation > -1) {
				tableData.push({
					id: 'srtTotalPriceReservation',
					text: formatMessage(messages.reservation),
					price: formatPrice(totalPriceReservation, totalPriceCurrency, intl),
				});
			}

			if (totalPriceTicketedRsv > -1) {
				tableData.push({
					id: 'srtTotalPriceTicketedRsv',
					text: formatMessage(messages.ticketedRsv),
					price: formatPrice(totalPriceTicketedRsv, totalPriceCurrency, intl),
				});
			}

			if (totalPriceTaxNational > -1) {
				tableData.push({
					id: 'srtTotalPriceTaxNational',
					text: formatMessage(messages.taxNational),
					price: formatPrice(totalPriceTaxNational, totalPriceCurrency, intl),
				});
			}

			if (totalPriceTaxStateProv > -1) {
				tableData.push({
					id: 'srtTotalPriceTaxStateProv',
					text: formatMessage(messages.taxStateProv),
					price: formatPrice(totalPriceTaxStateProv, totalPriceCurrency, intl),
				});
			}

			if (totalFees > -1) {
				tableData.push({
					id: 'srtTotalFees',
					text: formatMessage(messages.bookingFee),
					price: formatPrice(totalFees, totalPriceCurrency, intl),
				});
			}

			if (totalPriceCorpDiscount > -1) {
				tableData.push({
					id: 'srtTotalPriceCorpDiscount',
					text: formatMessage(messages.corpDiscount),
					price: formatPrice(totalPriceCorpDiscount, totalPriceCurrency, intl),
				});
			}

			if (totalPriceTravelPass > -1) {
				tableData.push({
					id: 'srtTotalPriceTravelPass',
					text: formatMessage(messages.travelPass),
					price: formatPrice(totalPriceTravelPass, totalPriceCurrency, intl),
				});
			}

			if (tdoFee > -1) {
				tableData.push({
					id: 'srtTdoFee',
					text: formatMessage(messages.tdoFee),
					price: formatPrice(tdoFee, totalPriceCurrency, intl),
				});
			}

			if (seatReservationsPrice !== null && seatReservationsPrice > -1) {
				bookingTotalPrice += seatReservationsPrice;
				tableData.push({
					id: 'srtSeatReservationPrice',
					text: formatMessage(messages.seatReservations),
					price: formatPrice(seatReservationsPrice, totalPriceCurrency, intl),
				});
			}

			if (bikeReservationsPrice !== null && bikeReservationsPrice > -1) {
				bookingTotalPrice += bikeReservationsPrice;
				tableData.push({
					id: 'srtBikeReservationPrice',
					text: formatMessage(messages.bikeReservations),
					price: formatPrice(bikeReservationsPrice, totalPriceCurrency, intl),
				});
			}

			if (selectedPlusBusSupplements?.length > 0) {
				bookingTotalPrice += selectedPlusBusSupplements.reduce(
					(sum, plusBusSupplement) => {
						tableData.push({
							id: `srtTravelCardPrice_${plusBusSupplement.id}`,
							text: formatMessage(messages.plusBusSupplements),
							price: formatPrice(
								plusBusSupplement.value,
								totalPriceCurrency,
								intl,
							),
						});
						return sum + Number(plusBusSupplement.value);
					},
					0,
				);
			}

			if (travelCards.size > 0) {
				bookingTotalPrice += travelCards.reduce((sum, travelCard) => {
					tableData.push({
						id: `srtTravelCardPrice_${travelCard.get('ID')}`,
						text: formatMessage(messages.travelCards),
						price: formatPrice(
							travelCard.get('value'),
							totalPriceCurrency,
							intl,
						),
					});
					return sum + Number(travelCard.get('value'));
				}, 0);
			}

			if (!validate.isEmpty(onBoardServices)) {
				const value = onBoardServices.reduce(
					(sum, item) => sum + Number(item.value),
					0,
				);
				bookingTotalPrice += value;
				tableData.push({
					id: 'srtOnBoardServicesPrice',
					text: formatMessage(messages.onBoardServices),
					price: formatPrice(value, totalPriceCurrency, intl),
				});
			}
		}

		const departDateString = this.props.intl.formatDate(new Date(departDate), {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
		const returnDateString = this.props.intl.formatDate(new Date(returnDate), {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
		const formattedTotalPrice = formatPrice(
			bookingTotalPrice,
			totalPriceCurrency,
			intl,
		);

		return (
			<Card style={inlineStyles.cardContainer}>
				<StyledCardHeaderTitle
					title={formatMessage(messages.orderSummary)}
					style={inlineStyles.orderTitle}
				/>
				<StyledCardHeaderTitle
					id="srtBookingTotalAmount"
					title={`${formatMessage(messages.total)}: ${formattedTotalPrice}`}
					style={inlineStyles.orderTotalTitle}
				/>
				<div className="col-12" style={inlineStyles.fareContainer}>
					<div className="col-12">
						{faresSelected && (
							<FaresSelectedView
								id="srtFaresSelectedOrder"
								selectedFares={faresSelected}
								departDateString={departDateString}
								returnDateString={returnDateString}
								inboundMsg={formatMessage(messages.inbound)}
								outboundMsg={formatMessage(messages.outbound)}
								tripType={tripType}
								addOrderMode={addOrderMode}
								exchangeOrderMode={exchangeOrderMode}
								onChangeSelection={onChangeSelection}
								isCreateBookingPage
							/>
						)}
					</div>
				</div>
				<div className="col-12">
					{exchangeSummary && exchangeSummary.size > 0 ? (
						<OrderSummaryBalance
							tableData={tableData}
							tableFooterData={tableFooterData}
						/>
					) : (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={inlineStyles.textColumn}>
										{formatMessage(messages.item)}
									</TableCell>
									<TableCell style={inlineStyles.priceColumn}>
										{formatMessage(messages.price)}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.map((item) => (
									<TableRow key={item.id}>
										<TableCell
											id={`${item.id}_item`}
											style={inlineStyles.textColumn}
										>
											<OrderSummaryItemText
												campaignPromoCode={item?.campaignPromoCode}
												campaignValidationCode={item?.campaignValidationCode}
												text={item.text}
												promoCodeLabel={formatMessage(messages.lblPromoCode)}
												validationCodeLabel={formatMessage(
													messages.lblValidationCode,
												)}
											/>
										</TableCell>
										<TableCell
											id={`${item.id}_price`}
											style={inlineStyles.priceColumn}
										>
											{item.price}
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell style={inlineStyles.textColumn}>
										{formatMessage(messages.total)}
									</TableCell>
									<TableCell
										id="srtTotalBookingAmount"
										style={inlineStyles.priceColumn}
									>
										{formattedTotalPrice}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					)}
				</div>
			</Card>
		);
	}
}

// This alias will be used to access bare component for unit testing
export { OrderSummary as OrderSummaryAlias };

export default injectIntl(OrderSummary);
