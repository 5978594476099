import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import Header, { adminNavItems } from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import SubNavbar from '../components/SubNavbar/SubNavbar';
import { isAdminKey } from '../utils';
import { gaEvent } from '../utils/googleAnalytics';
import styles from './admin.css';
import CreateUser from './CreateUser/CreateUser';
import { FeatureToggles } from './FeatureToggles/FeatureToggles';
import ManageAccounts from './ManageAccounts/ManageAccounts';
import ManageUsers from './ManageUsers/ManageUsers';
import messages from './messagesAdmin';

const Admin = ({ intl: { formatMessage }, loginId, settings, match }) => {
	const handleClick = (item) => {
		const links = {
			srtAdminManageAccounts: 'adminManageAccounts',
			srtAdminCreateUser: 'adminCreateUser',
			srtAdminManageUsers: 'adminManageUsers',
			srtFeatureToggles: 'adminFeatureToggles',
		};
		if (links[item.id]) gaEvent(links[item.id]);
	};

	const hasSettings = !!(loginId && settings);
	const isAdmin = settings?.get(isAdminKey) === 'true';

	return (
		<div styleName="styles.admin">
			{hasSettings && (
				<>
					<Header activeTab={2} />
					<SubNavbar heading={formatMessage(messages.title)} />
					<div className="container-fluid" styleName="styles.container">
						<div styleName="styles.table" className="row">
							<div
								className="hidden-xs hidden-sm col-md-3 col-lg-2"
								styleName="styles.sidebar styles.table-col"
							>
								<StickyBox topOffset={-56} stickyClassName={styles.stickybar}>
									<Sidebar items={adminNavItems} handleClick={handleClick} />
								</StickyBox>
							</div>
							<div
								className="col-12 col-sm-12 col-md-9 col-lg-10"
								styleName="styles.content styles.table-col"
							>
								<Switch>
									<Route
										path={`${match.path}/ManageAccounts`}
										component={ManageAccounts}
									/>
									<Route
										path={`${match.path}/CreateUser`}
										component={CreateUser}
									/>
									<Route
										path={`${match.path}/ManageUsers`}
										component={ManageUsers}
									/>
									{isAdmin && (
										<Route
											path={`${match.path}/FeatureToggles`}
											component={FeatureToggles}
										/>
									)}
									<Redirect
										from={`${match.path}/ManageUsers/EditUser`}
										to={`${match.path}/ManageUsers`}
									/>
									<Redirect from="*" to={`${match.path}/ManageAccounts`} />
								</Switch>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

Admin.propTypes = {
	loginId: PropTypes.string,
	settings: PropTypes.object,
	match: PropTypes.object,
	intl: PropTypes.object,
};

export default injectIntl(
	connect((state) => ({
		loginId: state.getIn(['settings', 'UserLoginId']),
		settings: state.get('settings'),
	}))(Admin),
);
