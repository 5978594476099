import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';

export default function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={classNames(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused,
			})}
			onDelete={(event) => {
				props.removeProps.onClick();
				props.removeProps.onMouseDown(event);
			}}
		/>
	);
}

MultiValue.propTypes = {
	selectProps: PropTypes.object,
	removeProps: PropTypes.object,
	isFocused: PropTypes.bool,
	children: PropTypes.node,
};
