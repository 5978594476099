import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { injectIntl } from 'react-intl';
import messages from './messagesPlusBusSupplements';
import PlusBusSupplementsView from './PlusBusSupplmentsView';
import PlusBusSupplementActions from './PlusBusSupplementActions';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});

const StyledDialogTitle = styled(DialogTitle)({
	...inlineStyles.dialogTitle,
});

const PlusBusSupplementsDialog = ({
	open,
	faresSelected,
	plusBusSupplements,
	passengerNumber,
	onDiscardPlusBusSupplements,
	selectedPlusBusSupplements,
	onChangePlusBusSupplements,
	intl: { formatMessage },
}) => {
	const [prevSelectedPlusBusSupplements, setPrevSelectedPlusBusSupplements] =
		useState(selectedPlusBusSupplements);
	const [isToggled, setIsToggled] = useState([]);
	const [isPrevToggled, setIsPrevToggled] = useState([]);

	const handleCheckPlusBusSupplement = (
		selectedPlusBusSupplementsInSegment,
		isChecked,
		linkedTravelSegment,
		localBusDescription,
	) => {
		setPrevSelectedPlusBusSupplements(selectedPlusBusSupplements);
		if (isChecked) {
			if (
				selectedPlusBusSupplements &&
				selectedPlusBusSupplements.length === 0
			) {
				selectedPlusBusSupplements.push(...selectedPlusBusSupplementsInSegment);
			} else {
				const hasAnyPlusBus = selectedPlusBusSupplements.some(
					(selectedPlusBusSupplement) =>
						selectedPlusBusSupplement.linkedTravelSegments &&
						selectedPlusBusSupplement.linkedTravelSegments[0] ===
							linkedTravelSegment &&
						selectedPlusBusSupplement.description === localBusDescription,
				);
				if (!hasAnyPlusBus) {
					selectedPlusBusSupplements.push(
						...selectedPlusBusSupplementsInSegment,
					);
				}
			}
		}
		if (!isChecked && selectedPlusBusSupplements?.length > 0) {
			const index = selectedPlusBusSupplements.findIndex(
				(selectedPlusBusSupplement) =>
					selectedPlusBusSupplement.linkedTravelSegments[0] ===
						linkedTravelSegment &&
					selectedPlusBusSupplement.description === localBusDescription,
			);
			if (index > -1) {
				selectedPlusBusSupplements.splice(
					index,
					selectedPlusBusSupplementsInSegment?.length,
				);
			}
		}
	};

	const handleDiscard = () => {
		setPrevSelectedPlusBusSupplements(prevSelectedPlusBusSupplements);
		setIsToggled(isPrevToggled);
		onDiscardPlusBusSupplements();
	};

	const handleSave = () => {
		setPrevSelectedPlusBusSupplements(selectedPlusBusSupplements);
		setIsPrevToggled(isToggled);
		onChangePlusBusSupplements(selectedPlusBusSupplements);
	};

	return (
		<StyledDialog
			open={open}
			onClose={handleDiscard}
			disableEnforceFocus
			maxWidth={false}
			scroll="body"
		>
			<StyledDialogTitle>{formatMessage(messages.lblTitle)}</StyledDialogTitle>
			<StyledDialogContent>
				<PlusBusSupplementsView
					onCheckPlusBusSupplement={handleCheckPlusBusSupplement}
					faresSelected={faresSelected}
					plusBusSupplements={plusBusSupplements}
					selectedPlusBusSupplements={selectedPlusBusSupplements}
					passengerNumber={passengerNumber}
					isToggled={isToggled}
					setIsToggled={setIsToggled}
				/>
			</StyledDialogContent>
			<DialogActions>
				<PlusBusSupplementActions
					handleSave={handleSave}
					handleDiscard={handleDiscard}
				/>
			</DialogActions>
		</StyledDialog>
	);
};

PlusBusSupplementsDialog.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool.isRequired,
	faresSelected: PropTypes.object.isRequired,
	plusBusSupplements: PropTypes.array.isRequired,
	onDiscardPlusBusSupplements: PropTypes.func.isRequired,
	onChangePlusBusSupplements: PropTypes.func.isRequired,
	selectedPlusBusSupplements: PropTypes.array.isRequired,
	passengerNumber: PropTypes.number,
};

export { PlusBusSupplementsDialog as PlusBusSupplementsDialogAlias };
export default injectIntl(PlusBusSupplementsDialog);
