import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';

import messages from '../messagesManageAccounts';
import inlineStyles from './accountGroupStyles';

const ExternalPaymentProvider = ({
	intl: { formatMessage },
	merchantAccountId,
	dynamicDescription,
	phoneNumber,
	externalPaymentAuthorization,
	onChangeAuthorization,
	displayDynamicDescriptors,
	handleChangeField,
}) => (
	<div className="col-12" data-testid="ExternalPaymentProvider">
		<ListSubheader style={inlineStyles.subHeader}>
			<FormattedMessage {...messages.lblExternalPaymentProviderDetails} />
		</ListSubheader>
		{onChangeAuthorization && (
			<TextField
				id="srtExtAuthorizationId"
				name="externalPaymentAuthorization"
				label={formatMessage(messages.lblExternalPaymentAuthorization)}
				fullWidth
				value={externalPaymentAuthorization}
				onChange={onChangeAuthorization}
				variant="standard"
			/>
		)}
		<TextField
			id="srtMerchantAccountId"
			name="merchantAccountId"
			label={formatMessage(messages.lblExternalPaymentMerchantAccountId)}
			fullWidth
			value={merchantAccountId}
			onChange={handleChangeField}
			variant="standard"
		/>
		{displayDynamicDescriptors && (
			<>
				<TextField
					id="srtDynamicDescription"
					name="dynamicDescription"
					label={formatMessage(
						messages.lblExternalPaymentDynamicDescriptorName,
					)}
					fullWidth
					value={dynamicDescription}
					onChange={handleChangeField}
					variant="standard"
				/>

				<ListSubheader style={inlineStyles.helpText}>
					<FormattedMessage
						{...messages.lblExternalPaymentDynamicDescriptorNameHelp}
					/>
				</ListSubheader>

				<TextField
					id="srtPhoneNumber"
					name="phoneNumber"
					label={formatMessage(
						messages.lblExternalPaymentDynamicDescriptorPhoneNumber,
					)}
					fullWidth
					value={phoneNumber}
					onChange={handleChangeField}
					variant="standard"
				/>

				<ListSubheader style={inlineStyles.helpText}>
					<FormattedMessage
						{...messages.lblExternalPaymentDynamicDescriptorPhoneNumberHelp}
					/>
				</ListSubheader>
			</>
		)}
	</div>
);

ExternalPaymentProvider.propTypes = {
	merchantAccountId: PropTypes.string,
	dynamicDescription: PropTypes.string,
	phoneNumber: PropTypes.string,
	externalPaymentAuthorization: PropTypes.string,
	onChangeAuthorization: PropTypes.func,
	displayDynamicDescriptors: PropTypes.bool,
	handleChangeField: PropTypes.func.isRequired,
	intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { ExternalPaymentProvider as ExternalPaymentProviderAlias };

export default injectIntl(ExternalPaymentProvider);
