import {
	getUserProfile,
	addUserProfile,
	getAccountUsers,
	getAccountData,
	submitAccountData,
	deleteAccount,
	getAccountGroupData,
	submitAccountGroupData,
	deleteAccountGroup,
	accountSearch,
	updateUserProfile,
	deleteUser,
	userSearch,
	getRoles,
	getAccountGroupLabels,
	getAccountLabels,
	getFeatureToggles,
	toggleFeature,
} from '../api';
import { getServerPath, transformCustomInformation } from '../utils';

export const getUserProfileApi = (loginId, error, success) =>
	getUserProfile(
		{
			srtUserProfileMode: 'edit',
			srtUserLoginId: loginId,
		},
		error,
		success,
	);

export const addUserProfileApi = (formData, error, success) => {
	const params = {
		srtUserProfileMode: 'add',
		srtApplyAcctAssocs: 'true',
		srtUserProfileBrand: 'SilverRail',
		srtUserLoginId: formData.userLoginId,
		srtUserProfileCertFileName: formData.certFileName,
		srtSubsidRole: formData.role,
		srtNewUserName: formData.username,
		srtUserProfileBusinessEmail: formData.businessEmail,
		srtUserProfileLocale: formData.locale,
		srtUserProfileFirstName: formData.firstName,
		srtUserProfileLastName: formData.lastName,
		srtServerPath: `${getServerPath()}login`,
		srtSendWelcomeEmail: formData.sendWelcomeEmail,
		srtUserSuppliers: formData.suppliers,
		srtUserAssocAcct: formData.assocAccounts,
		srtUserAssocAG: formData.assocAccGroups,
		srtUserStatus: formData.userStatus,
		srtUserAllowedVoidException: formData.userAllowedVoidException,
	};

	addUserProfile(params, error, success);
};

export const updateUserProfileApi = (formData, error, success) => {
	const params = {
		srtUserProfileMode: formData.profileMode || 'adminedit',
		srtApplyAcctAssocs: formData.applyAcctAssocs || 'true',
		srtUserProfileBrand: formData.profileBrand || 'SilverRail',
		srtUserLoginId: formData.username,
		srtUserProfileCertFileName: formData.certFileName,
		srtSubsidRole: formData.role,
		srtUserProfileBusinessEmail: formData.businessEmail,
		srtUserProfileLocale: formData.locale,
		srtUserProfileFirstName: formData.firstName,
		srtUserProfileLastName: formData.lastName,
		srtUserSuppliers: formData.suppliers,
		srtUserAssocAcct: formData.assocAccounts,
		srtUserAssocAG: formData.assocAccGroups,
		srtUserStatus: formData.userStatus,
		srtUserAllowedVoidException: formData.userAllowedVoidException,
	};

	updateUserProfile(params, error, success);
};

export const deleteUserApi = (userId, error, success) =>
	deleteUser({ srtUserLoginId: userId }, error, success);

export const getAccountUsersApi = (searchPattern, error, success) =>
	getAccountUsers({ srtSearchPattern: searchPattern }, error, success);

export const getAccountDataApi = (params, error, success) =>
	getAccountData(
		{
			srtAccountName: params.accountName,
			srtAllAccountData: params.getAllData,
		},
		error,
		success,
	);

export const submitAccountDataApi = (account, error, success) => {
	const params = {
		srtAcctEditMode: account.isEditMode,
		srtAddEditOrigAccountName: account.origName,
		srtAddEditAccountName: account.name,
		srtAddEditAccountBrand: account.brand,
		srtAddEditAccountContext: account.context,
		srtAccountAssocUser: account.associatedUsers.map(
			(associatedUsers) => associatedUsers.AccountManagementAssocUserLoginId,
		),
		srtAddEditAccountCountryCode: account.address.countryCode,
		srtAddEditAccountAddress1: account.address.address1,
		srtAddEditAccountAddress2: account.address.address2,
		srtAddEditAccountCity: account.address.city,
		srtAddEditAccountStateOrProv: account.address.stateOrProvince,
		srtAddEditAccountPostalCode: account.address.postalCode,
		srtAddEditAccountPhone: account.contact.phone,
		srtAddEditAccountEmail: account.contact.email,
		srtAddEditAccountFareSearchAllowed: account.fareSearchAllowed,
		srtAddEditAccountMerchantAccountId: account.merchantAccountId,
	};

	if (account.hasCustomInfo) {
		params.srtCustomInfoRaw = transformCustomInformation(
			account.customInformationItems,
		);
	}

	account.fees.forEach((fee, index) => {
		const i = index + 1;
		Object.assign(params, {
			[`srtSelectAccountFeeType${i}`]: fee.type,
			[`srtAccountFeeTypeAmount${i}`]: fee.amount,
			[`srtAccountFeeTypeMinAmount${i}`]: fee.minValue,
		});
	});

	submitAccountData(params, error, success);
};

export const deleteAccountApi = (accountName, error, success) =>
	deleteAccount({ srtAccountId: accountName }, error, success);

export const getAccountGroupDataApi = (accountName, error, success) =>
	getAccountGroupData({ srtAccountGroupName: accountName }, error, success);
export const getAccountGroupLabelsApi = (accountName, error, success) =>
	getAccountGroupLabels({}, error, success);
export const getAccountLabelsApi = (accountName, error, success) =>
	getAccountLabels({}, error, success);

export const submitAccountGroupDataApi = (accountGroup, error, success) => {
	const params = {
		srtEditMode: accountGroup.isEditMode,
		srtAddEditOrigAccountGroupName: accountGroup.origName,
		srtAddEditAccountGroupName: accountGroup.name,
		srtAccountGroupInfoCardholderName:
			accountGroup.onAccountDetails.onAccountCardName,
		srtAccountGroupInfoCardNumber:
			accountGroup.onAccountDetails.onAccountCardNumber,
		srtAccountGroupSecurityCode:
			accountGroup.onAccountDetails.onAccountSecurityCode,
		srtAccountGroupAssocAccount: accountGroup.associatedAccounts.map(
			(associatedAccount) => associatedAccount.value,
		),
		srtAccountGroupAssocUser: accountGroup.associatedUsers.map(
			(associatedUser) => associatedUser.AccountManagementAssocUserLoginId,
		),
		srtAccountGroupMerchantAccountId: accountGroup.merchantAccountId,
		srtAccountGroupBookingSyncAllowed: accountGroup.bookingSyncAllowed,
		srtAccountGroupSyncUrl: accountGroup.syncUrl,
		srtAccountGroupPartnerName: accountGroup.partnerName,
		srtAccountGroupAddOrderAllowed: accountGroup.addOrderAllowed,
		srtAccountGroupFareFilter: accountGroup.srtAccountGroupFareFilter,
		srtAccountGroupSeatmapApiKey: accountGroup.srtAccountGroupSeatmapApiKey,
		srtExternalPaymentAuthToken: accountGroup.externalPaymentAuthorization,
		srtAuthorizeRefundCode: accountGroup.authorizeRefundCode,
		srtAuthorizeRefundDescription: accountGroup.authorizeRefundDescription,
		phoneNumber: accountGroup.phoneNumber,
		dynamicDescription: accountGroup.dynamicDescription,
		splitTicketingEnabled: accountGroup.splitTicketingEnabled,
		maxSplits: accountGroup.maxSplits,
		maxSummatedFares: accountGroup.maxSummatedFares,
		splitAtCallingStations: accountGroup.splitAtCallingStations,
		splitTicketingFareFilters: accountGroup.splitTicketingFareFilters,
	};

	submitAccountGroupData(params, error, success);
};

export const deleteAccountGroupApi = (accountGroupName, error, success) =>
	deleteAccountGroup({ srtAccountGroupId: accountGroupName }, error, success);

export const accountSearchApi = (searchStr, error, success) =>
	accountSearch({ srtAccountSearchStr: searchStr }, error, success);

export const userSearchApi = (
	searchStr,
	searchRole,
	searchAccountGroup,
	searchAccount,
	error,
	success,
) =>
	userSearch(
		{
			srtUserSearchStr: searchStr,
			srtRoleStr: searchRole,
			srtAccountGroupStr: searchAccountGroup,
			srtAccountStr: searchAccount,
		},
		error,
		success,
	);
export const getRolesApi = (error, success) => getRoles(error, success);
export const getFeatureTogglesApi = (error, success) =>
	getFeatureToggles(error, success);
export const toggleFeatureApi = (toggleName, error, success) =>
	toggleFeature({ toggleName }, error, success);
