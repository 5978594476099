import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.booking.confirmOrderDialog.title',
		defaultMessage: 'Confirmation',
	},
	btnCancel: {
		id: 'app.booking.confirmOrderDialog.cancel',
		defaultMessage: 'Cancel',
	},
	btnSubmit: {
		id: 'app.booking.confirmOrderDialog.submit',
		defaultMessage: 'Confirm Order',
	},
	errPaymentMethodEmpty: {
		id: 'app.admin.confirmOrderDialog.paymentMethodEmpty',
		defaultMessage: '^Please select a Payment Method',
	},
	errPaymentCardNameEmpty: {
		id: 'app.admin.confirmOrderDialog.paymentCardNameEmpty',
		defaultMessage: '^Please enter a Name',
	},
	errPaymentCardNumberEmpty: {
		id: 'app.admin.confirmOrderDialog.paymentCardNumberEmpty',
		defaultMessage: '^Please enter a Card Number',
	},
	errPaymentCardNumberInvalid: {
		id: 'app.admin.confirmOrderDialog.paymentCardNumberInvalid',
		defaultMessage: '^Please enter a valid Card Number',
	},
	errDateInvalid: {
		id: 'app.admin.confirmOrderDialog.dateInvalid',
		defaultMessage: '^Please enter a valid Month',
	},
	errYearInvalid: {
		id: 'app.admin.confirmOrderDialog.yearInvalid',
		defaultMessage: 'Please enter a valid Year',
	},
	errMonthInvalid: {
		id: 'app.admin.confirmOrderDialog.monthInvalid',
		defaultMessage: 'Please enter a valid Month',
	},
	errDayInvalid: {
		id: 'app.admin.confirmOrderDialog.dayInvalid',
		defaultMessage: 'Please enter a valid Day',
	},
	errPaymentIssueNumber: {
		id: 'app.admin.confirmOrderDialog.paymentIssueNumberEmpty',
		defaultMessage: '^Please enter an Issue Number',
	},
	errSecurityCode: {
		id: 'app.admin.confirmOrderDialog.paymentSecurityCodeInvalid',
		defaultMessage: '^Please enter a valid Security Code',
	},
	errTermsOfService: {
		id: 'app.admin.confirmOrderDialog.termsOfServiceNotSelected',
		defaultMessage:
			'^You must agree to the Terms and Conditions and National Rail Conditions of Travel to make a confirmed payment.',
	},
	errConfirmationFirstName: {
		id: 'app.admin.confirmOrderDialog.confirmationFirstNameEmpty',
		defaultMessage: '^Please enter a First Name',
	},
	errConfirmationLastName: {
		id: 'app.admin.confirmOrderDialog.confirmationLastNameEmpty',
		defaultMessage: '^Please enter a Last Name',
	},
	errConfirmationSortCode: {
		id: 'app.admin.confirmOrderDialog.confirmationSortCodeEmpty',
		defaultMessage: '^Please enter a valid Sort Code',
	},
	errConfirmationLoyaltyProgram: {
		id: 'app.admin.confirmOrderDialog.confirmationLoyaltyProgramEmpty',
		defaultMessage: '^Please select a Loyalty Card Program',
	},
	errConfirmationLoyaltyCardIdentifier: {
		id: 'app.admin.confirmOrderDialog.confirmationLoyaltyCardIdentifierEmpty',
		defaultMessage: '^Please enter a valid Loyalty Card Identifier',
	},
	errConfirmationEmail: {
		id: 'app.admin.confirmOrderDialog.confirmationEmailEmpty',
		defaultMessage: '^Please enter a valid Email',
	},
	lblEmailSentTo: {
		id: 'app.booking.confirmOrderDialog.emailSentTo',
		defaultMessage: 'Sent booking confirmation to: {email}.',
	},
});
