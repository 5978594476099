import { lightGray2, lightGray3 } from '../../../../colors';

export default {
	dialogPaper: {
		overflowY: 'hidden',
		maxWidth: 768,
	},
	dialogTitle: {
		padding: '20px 24px 15px',
	},
	dialogContent: {
		overflowY: 'initial',
	},
	dialogActionsRoot: {
		width: '100%',
	},
	cardContainer: {
		padding: 12,
		marginTop: 10,
	},
	cardLabelText: {
		lineHeight: '110px',
	},
	cardText: {
		lineHeight: '1.75',
	},
	toggleRoot: {
		width: '100%',
	},
	toggleInput: {
		width: '420%',
		height: '405%',
	},
	toggleLabel: {
		flexGrow: 1,
		fontSize: 16,
	},
	legTitle: {
		margin: 0,
	},
	legSubtitle: {
		margin: '0px 10px',
	},
	legSegment: {
		background: `linear-gradient(${lightGray2}, ${lightGray3})`,
		fontWeight: 500,
		paddingTop: 5,
		paddingBottom: 5,
	},
	legSegmentSummary: {
		margin: 0,
	},
	expandedPanel: {
		margin: 0,
	},
	expandedPanelSummary: {
		margin: 0,
		maxHeight: 54,
	},
};
