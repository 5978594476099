import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { injectIntl } from 'react-intl';

function MuiPickersUtilsProviderLocalized({ children }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{children}
		</LocalizationProvider>
	);
}

MuiPickersUtilsProviderLocalized.propTypes = {
	children: PropTypes.node,
};

export { MuiPickersUtilsProviderLocalized as MuiPickersUtilsProviderLocalizedAlias };
export default injectIntl(MuiPickersUtilsProviderLocalized);
