import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import PlusBusSupplement from './PlusBusSupplement';

const RenderPlusBusSupplements = ({
	plusBusSupplement,
	onCheckPlusBusSupplement,
	searchedPlusBusSupplements,
	passengerLabel,
	setIsToggled,
	isToggled,
}) => {
	const { id, description } = plusBusSupplement;
	const linkedTravelSegment = plusBusSupplement.linkedTravelSegments[0];
	const selectedPlusBusSupplementInLeg = searchedPlusBusSupplements?.filter(
		(selectedPlusBusSupplement) =>
			selectedPlusBusSupplement.linkedTravelSegments &&
			selectedPlusBusSupplement.linkedTravelSegments[0] ===
				linkedTravelSegment &&
			selectedPlusBusSupplement.description === description,
	);
	const getToggledElements = () =>
		selectedPlusBusSupplementInLeg.filter(
			(li) =>
				li.description === description &&
				li.linkedTravelSegments[0] === linkedTravelSegment,
		);

	const isSelected = () => {
		const returnValue = isToggled.some((toggled) => {
			const val =
				toggled?.linkedTravelSegments &&
				toggled.linkedTravelSegments[0] === linkedTravelSegment &&
				toggled.description === description;
			return val;
		});
		return returnValue;
	};

	const discountInfo = plusBusSupplement?.fareQualifierInfo
		? {
				...plusBusSupplement?.fareQualifierInfo,
				discountDescription:
					plusBusSupplement?.fareQualifierInfo?.programDescription,
			}
		: null;

	return (
		<Grid>
			<h3>{passengerLabel}</h3>
			<Grid item xs={false}>
				<PlusBusSupplement
					id={id}
					price={plusBusSupplement.priceDesc}
					description={plusBusSupplement.description}
					discountInfo={discountInfo}
					onCheckPlusBusSupplement={(event, isChecked) => {
						setIsToggled([...isToggled, ...getToggledElements()]);
						onCheckPlusBusSupplement(
							selectedPlusBusSupplementInLeg,
							isChecked,
							linkedTravelSegment,
							description,
						);
						if (!isChecked) {
							setIsToggled(
								isToggled.filter(
									(segment) =>
										!(
											segment.linkedTravelSegments[0] === linkedTravelSegment &&
											segment.description === description
										),
								),
							);
						}
					}}
					selected={isSelected()}
				/>
			</Grid>
		</Grid>
	);
};

RenderPlusBusSupplements.propTypes = {
	plusBusSupplement: PropTypes.object,
	onCheckPlusBusSupplement: PropTypes.func.isRequired,
	searchedPlusBusSupplements: PropTypes.array,
	passengerLabel: PropTypes.string,
	isToggled: PropTypes.array,
	setIsToggled: PropTypes.func,
};

export { RenderPlusBusSupplements as RenderPlusBusSupplementsAlias };

export default RenderPlusBusSupplements;
