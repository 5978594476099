import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import validate from 'validate.js';

import { fromJS } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { getCouponsApi, setCouponsApi } from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { gaEvent } from '../../../utils/googleAnalytics';

import ReturnCoupons from './ReturnCoupons';
import Actions from './Actions';
import messages from './messagesReturnCoupons';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

validate.extend(validate.validators.datetime, {
	parse: (date) => date,
	format: (timestamp) => new Date(timestamp).toLocaleDateString(),
});

const getUniqueCoupons = (coupons) =>
	coupons.filter((coupon, index) => {
		const stringifiedCoupon = JSON.stringify(coupon);
		return (
			index ===
			coupons.findIndex((obj) => JSON.stringify(obj) === stringifiedCoupon)
		);
	});

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});

const initialState = {
	isFetching: false,
	isProcessingSubmit: false,
	alertText: '',
	couponData: {},
	receiptDate: 0,
	couponsToReturn: [],
	utnToReturn: [],
	notes: '',
	errors: {},
	setCouponsSucess: false,
	returnByUtn: true,
	forceCancel: false,
};

const ReturnCouponsDialog = ({
	booking,
	orderId,
	intl,
	intl: { formatMessage },
	onClose,
	onReturnCouponsChanged,
	open,
	availableSuppliers,
}) => {
	const [data, setData] = useState(fromJS(initialState));

	useEffect(() => {
		if (open) {
			setData(
				data.merge({
					isFetching: true,
					alertText: '',
				}),
			);

			getCouponsApi(
				{
					queryItems: booking.queryItems,
					orderId,
				},
				(response) => {
					setData(
						data.merge({
							isFetching: false,
							alertText: response.errorResponse.message,
						}),
					);
				},
				(response) => {
					const { data: responseData } = response.successResponse;
					const uniqueCoupons = getUniqueCoupons(
						responseData.BookingCouponsTravelSegmentCoupons,
					);
					const lengthOfCoupons = uniqueCoupons.length;

					responseData.BookingCouponsTravelSegmentCoupons = uniqueCoupons;

					setData(
						data.merge({
							isFetching: false,
							couponData: responseData,
							receiptDate: new Date(Date.now()),
							notes: '',
							couponsToReturn: !responseData.BookingCouponsWithTF
								? Array.from({ length: lengthOfCoupons }, () => 0)
								: [],
							utnToReturn: !responseData.BookingCouponsWithTF
								? Array.from({ length: lengthOfCoupons }, () => null)
								: [],
							returnByUtn: initialState.returnByUtn,
						}),
					);
				},
			);
		}
	}, []);

	useEffect(() => {
		if (data.toJS().setCouponsSucess) {
			onClose();
			onReturnCouponsChanged(booking.queryItems);
		}
	}, [data]);

	const constraints = () => ({
		receiptDate: {
			presence: {
				message: formatMessage(messages.errNoReceiptDate),
				allowEmpty: false,
			},
		},
	});

	const handleToggle = (key) => (event) => {
		setData(
			data.merge({
				[key]: event.target.checked,
			}),
		);
	};

	const handleSubmitCoupons = () => {
		const dataJs = data.toJS();
		const errors = validate(dataJs, constraints());

		const noCouponsSelected = dataJs.couponData.BookingCouponsWithTF
			? dataJs.couponsToReturn.length === 0
			: dataJs.couponsToReturn.reduce(
					(accumulator, item) => accumulator + item,
				) <= 0;

		if (!validate.isEmpty(errors) || noCouponsSelected) {
			const updates = { errors };
			if (noCouponsSelected) {
				updates.alertText = formatMessage(messages.errNoCouponsSelected);
			} else {
				updates.alertText = formatMessage(messages.errGeneric);
			}

			setData(data.merge(updates));

			return;
		}

		setData(
			data.merge({
				isProcessingSubmit: true,
				alertText: '',
				errors: {},
			}),
		);

		setCouponsApi(
			{
				booking,
				orderId,
				intl,
				returnCouponsData: dataJs,
			},
			(response) => {
				setData(
					data.merge({
						isProcessingSubmit: false,
						alertText: response.errorResponse.message,
					}),
				);
			},
			() => {
				setData(
					data.merge({
						isProcessingSubmit: false,
						setCouponsSucess: true,
					}),
				);
			},
		);
	};

	const handleChangeField = (updates) => {
		setData(data.merge(updates));
	};

	const handleSnackBarClose = () => {
		setData(data.merge({ alertText: '' }));
	};

	const closeAction = () => {
		gaEvent('returnCouponsClose');
		onClose();
	};

	const {
		isFetching,
		isProcessingSubmit,
		alertText,
		couponData,
		errors,
		...values
	} = data.toJS();

	const disabled = isFetching || isProcessingSubmit;

	const order = booking.BookingOrders.find(
		(item) => item.BookingOrderID === orderId,
	);
	const isSeasonTrip = order.BookingLegFareRulesDetails.some((fare) => {
		const fareClass = fare?.fareClasses
			? fare?.fareClasses.toLowerCase()
			: fare?.ShoppingFareRulesFareClasses?.toLowerCase();
		return fareClass.includes('season') || fareClass.includes('carnet');
	});

	const hasRDG = availableSuppliers.includes('ATOC');

	return (
		<div>
			<StyledDialog
				open={open}
				onClose={closeAction}
				disableEnforceFocus
				maxWidth={false}
			>
				<DialogTitle>
					<FormattedMessage {...messages.lblTitle} />
				</DialogTitle>
				<StyledDialogContent>
					{!validate.isEmpty(couponData) ? (
						<ReturnCoupons
							couponData={couponData}
							errors={errors}
							onChange={handleChangeField}
							disabled={disabled}
							values={values}
							handleToggle={handleToggle}
							isSeasonTrip={isSeasonTrip}
							hasRDG={hasRDG}
						/>
					) : null}
					<RefreshIndicator
						size={36}
						top={0}
						left={0}
						status={disabled ? 'loading' : 'hide'}
						style={inlineStyles.refreshIndicator}
					/>
				</StyledDialogContent>
				<DialogActions>
					<Actions
						disabled={disabled}
						onClose={closeAction}
						handleSubmitCoupons={handleSubmitCoupons}
					/>
				</DialogActions>
			</StyledDialog>
			<ExtendedSnackbar
				id="srtReturnCouponsSnackBar"
				open={alertText !== ''}
				message={alertText}
				onClose={handleSnackBarClose}
			/>
		</div>
	);
};

ReturnCouponsDialog.propTypes = {
	intl: PropTypes.object,
	booking: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool,
	orderId: PropTypes.string,
	onReturnCouponsChanged: PropTypes.func,
	availableSuppliers: PropTypes.array,
};

const mapStateToProps = (state) => ({
	availableSuppliers: state
		.getIn(['shopping', 'query', 'availableSuppliers'])
		.toJS(),
});

const mapDispatchToProps = (dispatch) => ({
	onReturnCouponsChanged: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(ReturnCouponsDialog));
