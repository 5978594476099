import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import AccountCircle from '@mui/icons-material/AccountCircle';

import AssocAutoComplete from '../../../components/AssocAutoComplete/AssocAutoComplete';
import { getAccountUsersApi } from '../../apiAdmin';

const USERS_LIMIT = 30;

export default class AssocUsersAutoComplete extends PureComponent {
	state = {
		data: fromJS({
			errorText: '',
			isLoading: false,
		}),
	};

	getUsers = (inputValue, callback) => {
		if (inputValue.length > 2) {
			this.setState((state) => ({ data: state.data.set('isLoading', true) }));
			getAccountUsersApi(
				inputValue,
				() => {
					this.setState((state) => ({
						data: state.data.merge({
							errorText:
								"There was problem getting the user's list. Please try again!",
							isLoading: false,
						}),
					}));
				},
				(response) => {
					const { users: values } = this.props;
					const valuesPlain = values.toJS ? values.toJS() : values;
					const valuesId = valuesPlain.map(
						(user) => user.AccountManagementAssocUserLoginId,
					);
					const results =
						response.successResponse.data.AccountManagementUserSearchResults;
					const filteredUsers = results.filter(
						(user) =>
							!valuesId.includes(user.AccountManagementAssocUserLoginId),
					);
					const users = this.transformUserData(
						filteredUsers.slice(0, USERS_LIMIT - 1),
					);

					callback(users);

					this.setState((state) => ({
						data: state.data.merge({
							errorText: '',
							isLoading: false,
						}),
					}));
				},
			);
		} else {
			callback([]);
		}
	};

	transformUserData = (users) =>
		users.map((user) => ({
			text:
				`${user.AccountManagementAssocUserDataUsernameFirst} ` +
				`${user.AccountManagementAssocUserDataUsernameLast} ` +
				`[${user.AccountManagementAssocUserLoginId}]`,
			value: user,
		}));

	render() {
		const { onChange, placeholder, label, headerTitle, users, dataTestId } =
			this.props;
		const { errorText, isLoading } = this.state.data.toJS();

		return (
			<AssocAutoComplete
				id="strUser"
				dataTestId={dataTestId}
				values={this.transformUserData(users.toJS ? users.toJS() : users)}
				loadOptions={this.getUsers}
				isLoading={isLoading}
				onChange={onChange}
				placeholder={placeholder}
				label={label}
				headerTitle={headerTitle}
				errorText={errorText}
				icon={<AccountCircle />}
				searchValue
			/>
		);
	}
}

AssocUsersAutoComplete.propTypes = {
	users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	headerTitle: PropTypes.string.isRequired,
	dataTestId: PropTypes.string,
};
