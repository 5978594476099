export default {
	refresh: {
		marginTop: '10%',
		marginLeft: '45%',
	},
	buttonFix: {
		marginTop: 10,
	},
	dialogPaper: {
		overflowY: 'hidden',
		minWidth: '90%',
	},
	dialogActionsRoot: {
		width: '100%',
	},
};
