import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

import messages from './messagesOrderSummary';
import inlineStyles from './styles';

const StyledTextColumnTableCell = styled(TableCell)(() => ({
	...inlineStyles.textColumn,
}));

const StyledPriceColumnTableCell = styled(TableCell)(() => ({
	...inlineStyles.priceColumn,
}));

const OrderSummaryBalance = ({
	tableData = [],
	tableFooterData = [],
	preliminaryExchangeRecap = false,
	intl,
}) => (
	<Table>
		<TableHead>
			{preliminaryExchangeRecap ? (
				<TableRow>
					<TableCell colSpan="2">
						<Typography
							variant="title"
							align="center"
							headlineMapping={{ title: 'h2' }}
						>
							{intl.formatMessage(messages.preliminaryExchangeRecap)}
						</Typography>
					</TableCell>
				</TableRow>
			) : null}
			<TableRow>
				<StyledTextColumnTableCell>
					{intl.formatMessage(messages.item)}
				</StyledTextColumnTableCell>
				<StyledPriceColumnTableCell>
					{intl.formatMessage(messages.price)}
				</StyledPriceColumnTableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{tableData.map((item) => (
				<TableRow key={item.text}>
					<StyledTextColumnTableCell
						id={`${item.id}_item`}
						data-testid={`${item.id}_item`}
					>
						{item.text}
					</StyledTextColumnTableCell>
					<StyledPriceColumnTableCell
						id={`${item.id}_price`}
						data-testid={`${item.id}_price`}
					>
						{item.price}
					</StyledPriceColumnTableCell>
				</TableRow>
			))}
			{tableFooterData.map((item) => (
				<TableRow key={item.text}>
					<StyledTextColumnTableCell id={`${item.id}_item`}>
						{item.text}
					</StyledTextColumnTableCell>
					<StyledPriceColumnTableCell id={`${item.id}_price`}>
						{item.price}
					</StyledPriceColumnTableCell>
				</TableRow>
			))}
		</TableBody>
	</Table>
);

OrderSummaryBalance.propTypes = {
	tableData: PropTypes.array,
	tableFooterData: PropTypes.array,
	preliminaryExchangeRecap: PropTypes.bool,
	intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { OrderSummaryBalance as OrderSummaryBalanceAlias };

export default injectIntl(OrderSummaryBalance);
