import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import { formatPrice } from '../../../../utils';
import styles from './styles';
import messages from './messagesTicketDelivery';

const StyledPaper = styled(Paper)(() => ({
	...styles.paperContainer,
}));

const StyledTableCell = styled(TableCell)(() => ({
	...styles.tdoDesc,
}));

const StyledButton = styled(Button)(() => ({
	...styles.buttons,
	...styles.rightButtons,
}));

function TicketDelivery(props) {
	const {
		createBookingData,
		handleOpenTicketDelivery,
		intl,
		tdoFee,
		viewMode,
	} = props;
	const disabled =
		createBookingData.get('isProcessingSubmit') ||
		createBookingData.get('isFetching');
	const tdoDesc = createBookingData.getIn(['selectedTdo', 'tdoDesc']);
	const tdoCurrency = createBookingData.getIn(['selectedTdo', 'tdoCurrency']);
	const formattedTdoFee = tdoCurrency
		? formatPrice(tdoFee, tdoCurrency, intl)
		: tdoFee;
	const activeTdoEvents = tdoDesc ? 'Edit' : 'Add';
	const tdoEvent = viewMode ? 'View' : activeTdoEvents;
	const buttonLabel = `lblTDO${tdoEvent}`;

	return (
		<StyledPaper elevation={1}>
			<div className="row">
				<div className="col-12">
					<h2>
						<FormattedMessage {...messages.lblTDO} />
					</h2>
				</div>
				<div className="col-sm-12">
					<Table>
						<TableHead>
							<TableRow>
								<StyledTableCell>
									<FormattedMessage {...messages.lblSelectedTDO} />
								</StyledTableCell>
								<TableCell>
									<FormattedMessage {...messages.lblTDOPrice} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<StyledTableCell id="srtBookingSelectedTDODesc">
									{tdoDesc}
								</StyledTableCell>
								<TableCell id="srtBookingSelectedTDOFee">
									{formattedTdoFee}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</div>
				<div className="col-sm-12">
					<StyledButton
						variant="contained"
						id="srtAddEditTdo"
						color="secondary"
						onClick={() => handleOpenTicketDelivery(tdoEvent)}
						disabled={disabled}
					>
						<FormattedMessage {...messages[buttonLabel]} />
					</StyledButton>
				</div>
			</div>
		</StyledPaper>
	);
}

TicketDelivery.propTypes = {
	createBookingData: PropTypes.object.isRequired,
	handleOpenTicketDelivery: PropTypes.func.isRequired,
	intl: PropTypes.object,
	tdoFee: PropTypes.number,
	viewMode: PropTypes.bool,
};

export { TicketDelivery as TicketDeliveryAlias };
export default injectIntl(TicketDelivery);
