export default {
	optionsPriceColumn: {
		textAlign: 'right',
	},
	optionsDescColumn: {
		width: '60%',
	},
	paperContainer: {
		marginTop: 16,
		marginBottom: 10,
		padding: '0 15px 15px 15px',
	},
	buttons: {
		margin: 10,
	},
	optionButtons: {
		display: 'inline-block',
	},
};
