const normalTabStyle = {
	color: 'black',
	paddingRight: 40,
	height: '100%',
	minHeight: 60,
	borderBottom: 10,
};

const activeTabStyle = {
	...normalTabStyle,
	color: 'rgb(41, 148, 169)',
	boxShadow: 'rgb(41 148 169) 0px -4px 0px inset',
};
const homeTabStyle = { ...normalTabStyle, paddingRight: 0 };
const activeHomeTabStyle = { ...activeTabStyle, paddingRight: 0 };

export default {
	refresh: {
		marginTop: '40%',
		marginLeft: '45%',
	},
	indicator: {
		marginTop: '15%',
		marginLeft: '49%',
	},
	subNavBarButtons: {
		marginLeft: 15,
	},
	tab: normalTabStyle,
	activeTab: activeTabStyle,
	homeTab: homeTabStyle,
	activeHomeTab: activeHomeTabStyle,
	closeTab: {
		position: 'absolute',
		margin: '18px 0 0 -30px',
		zIndex: 1000,
		cursor: 'pointer',
	},
};
