import React, {
	useState,
	useEffect,
	useCallback,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { injectIntl } from 'react-intl';
import messages from './messagesAddOrder';
import inlineStyles from './styles';
import './stylesAddOrder.css';
import ShoppingSearch from '../../../Shopping/ShoppingSearch/ShoppingSearch';
import ShoppingResults from '../../../Shopping/ShoppingResults/ShoppingResults';
import CreateBooking from '../../../Shopping/CreateBooking/CreateBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { clearSearchResults } from '../../../Shopping/ShoppingResults/actionsShoppingResults';
import { clearFields } from '../../../Shopping/ShoppingSearch/actionsShoppingSearch';
import { TRIP_TYPE_RETURN, TRIP_TYPE_SINGLE } from '../../../Shopping/util';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const AddOrderDialog = React.forwardRef(
	(
		{
			open,
			orderAccount,
			results,
			faresSelected,
			tripType,
			queryItems,
			passengers,
			intl: { formatMessage },
			openReturn,
			onCloseAddOrderDialog,
			onClearSearchResults,
			onClearFields,
			onOrderAdded,
		},
		ref,
	) => {
		const [isProcessing, setIsProcessing] = useState(false);

		const createCompRef = React.useRef(null);
		const searchCompRef = React.useRef(null);

		const clearAddOrder = () => {
			onClearSearchResults();
			onClearFields();
		};

		useEffect(() => {
			clearAddOrder();

			return () => {
				clearAddOrder();
			};
		}, []);

		const handleClose = () => {
			clearAddOrder();
			setIsProcessing(false);
			onCloseAddOrderDialog();
		};

		const handleAddOrder = () => {
			handleClose();
			onOrderAdded(queryItems);
		};

		const handleStatusChange = (status) => {
			setIsProcessing(status);
		};

		const handleSearch = useCallback(() => {
			if (searchCompRef.current && searchCompRef.current.handleSubmitSearch) {
				searchCompRef.current.handleSubmitSearch();
			}
		}, []);

		useImperativeHandle(
			ref,
			() => ({
				handleSearch,
			}),
			[handleSearch],
		);

		const showResults = results && results.size > 0;
		const showAddOrder =
			(faresSelected.size === 1 &&
				(tripType === TRIP_TYPE_SINGLE || openReturn)) ||
			(faresSelected.size === 2 && tripType === TRIP_TYPE_RETURN);

		let componentMainAction = null;
		let componentActionLabel = '';
		let componentActionId;
		let component = null;
		let componentActionInProcess = false;

		// To get References to components we need to use getWrappedInstance twice because the components are wrapped 2 times,
		// once by connect and second by react-intl
		if (!showResults) {
			component = (
				<ShoppingSearch
					ref={searchCompRef}
					addOrderMode
					orderPassengers={passengers}
					orderAccount={orderAccount}
				/>
			);

			componentActionLabel = formatMessage(messages.btnSearch);
			componentActionId = 'srtAddOrderSearch';
			// For Search component we can use props to determine component state
			componentActionInProcess =
				searchCompRef.current &&
				(searchCompRef.current.props.query.get('isFetchingUserState') ||
					searchCompRef.current.props.isSearchInitiated);
		} else {
			component = <ShoppingResults addOrderMode />;
		}

		if (showAddOrder) {
			// since component status in Create Booking is determined by state
			// we need to make a callback on each component status change
			component = (
				<CreateBooking
					ref={createCompRef}
					addOrderMode
					orderPassengers={passengers}
					queryItems={queryItems}
					onAddOrder={handleAddOrder}
					onStatusChange={handleStatusChange}
				/>
			);

			componentMainAction = () => {
				createCompRef.current.handleAddOrder();
			};
			componentActionLabel = formatMessage(messages.btnAddOrder);
			componentActionId = 'srtAddOrderAddOrder';
		}

		const disabled = componentActionInProcess || isProcessing;

		const actions = (
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					gap: '10px',
					padding: '16px',
				}}
			>
				<Button
					variant="contained"
					id="srtAddOrderClose"
					onClick={handleClose}
					disabled={disabled}
				>
					{formatMessage(messages.btnClose)}
				</Button>
				{!showAddOrder && (
					<Button
						variant="contained"
						id={componentActionId}
						onClick={handleSearch}
						color="primary"
						disabled={disabled}
					>
						{componentActionLabel}
					</Button>
				)}
				{showAddOrder &&
					componentMainAction &&
					componentActionLabel?.length && (
						<Button
							variant="contained"
							id={componentActionId}
							onClick={componentMainAction}
							color="primary"
							disabled={disabled}
						>
							{componentActionLabel}
						</Button>
					)}
			</div>
		);

		return (
			<StyledDialog
				open={open}
				onClose={handleClose}
				disableEnforceFocus
				maxWidth={false}
			>
				<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
				<DialogContent>
					<div className="container-fluid" styleName="srtAddOrderContainer">
						{component}
					</div>
				</DialogContent>
				<DialogActions>{actions}</DialogActions>
			</StyledDialog>
		);
	},
);

const mapStateToProps = (state) => ({
	results: state.getIn(['shopping', 'results', 'results']),
	faresSelected: state.getIn(['shopping', 'results', 'faresSelected']),
	tripType: state.getIn(['shopping', 'query', 'tripType']),
	openReturn: state.getIn(['shopping', 'query', 'openReturn']),
});

const mapDispatchToProps = (dispatch) => ({
	onOrderAdded: bindActionCreators(fetchBookingDetails, dispatch),
	onClearSearchResults: bindActionCreators(clearSearchResults, dispatch),
	onClearFields: bindActionCreators(clearFields, dispatch),
});

AddOrderDialog.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool,
	queryItems: PropTypes.object,
	onCloseAddOrderDialog: PropTypes.func.isRequired,
	onOrderAdded: PropTypes.func,
	orderAccount: PropTypes.object,
	results: PropTypes.object,
	faresSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	tripType: PropTypes.number,
	onClearSearchResults: PropTypes.func,
	onClearFields: PropTypes.func,
	passengers: PropTypes.array,
	openReturn: PropTypes.bool,
};

const IntlInjected = injectIntl(AddOrderDialog);
const Connected = connect(mapStateToProps, mapDispatchToProps)(IntlInjected);
export default React.forwardRef((props, ref) => (
	<Connected {...props} forwardedRef={ref} />
));
