import PropTypes from 'prop-types';
import React from 'react';

const OrderSummaryItemText = ({
	text,
	campaignPromoCode,
	campaignValidationCode,
	promoCodeLabel,
	validationCodeLabel,
}) => (
	<>
		{campaignPromoCode ? (
			<div>
				<div>{text}</div>
				<div>
					{`${promoCodeLabel}: ${campaignPromoCode} ${validationCodeLabel}: ${campaignValidationCode}`}
				</div>
			</div>
		) : (
			<div>{text}</div>
		)}
	</>
);

OrderSummaryItemText.propTypes = {
	text: PropTypes.string,
	campaignPromoCode: PropTypes.string,
	campaignValidationCode: PropTypes.string,
	promoCodeLabel: PropTypes.string,
	validationCodeLabel: PropTypes.string,
};

export default OrderSummaryItemText;
