import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';
import { injectIntl } from 'react-intl';

import styles from './styles';
import messages from '../../../components/Fare/messagesFare';
import DicountInformation from '../../../components/Fare/DiscountInformation';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({}));

const PlusBusSupplement = ({
	id,
	price,
	selected,
	onCheckPlusBusSupplement,
	description,
	discountInfo,
	intl: { formatMessage },
}) => (
	<Paper id={`plusBusSupplement_${id}`} style={styles.cardContainer}>
		<StyledFormControlLabel
			label={discountInfo ? `${price} *` : price}
			control={
				<Switch
					id={`plusBusSupplementSwitch_${id}`}
					checked={selected}
					onChange={onCheckPlusBusSupplement}
					inputProps={{ style: styles.toggleInput }}
				/>
			}
		/>
		<div style={styles.cardText}>{description}</div>
		{discountInfo && (
			<DicountInformation
				fare={discountInfo}
				discountHeaderLabel={formatMessage(messages.lblDiscountedFare)}
				discountCardLabel={formatMessage(messages.lblDiscountCard)}
				promoCodeLabel={formatMessage(messages.lblPromoCode)}
				validationCodeLabel={formatMessage(messages.lblValidationCode)}
			/>
		)}
	</Paper>
);

PlusBusSupplement.propTypes = {
	price: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	onCheckPlusBusSupplement: PropTypes.func.isRequired,
	selected: PropTypes.bool,
	id: PropTypes.string,
	discountInfo: PropTypes.object,
	intl: PropTypes.object,
};

export { PlusBusSupplement as PlusBusSupplementAlias };

export default injectIntl(PlusBusSupplement);
