import {
	getAccessToken,
	isAuth,
	forgotPassword,
	acceptTandC,
	logOut,
	changeUserPassword,
	resetUserPassword,
	resetUserPasswordTokenCheck,
	getRefreshToken,
} from '../api';
import { getServerPath } from '../utils';
import { gaSetUserId } from '../utils/googleAnalytics';

export const getAccessTokenApi = (params, error, success) =>
	getAccessToken(
		{
			username: params.username,
			password: params.password,
			grant_type: 'password',
		},
		(...response) => {
			error(...response);
		},
		(...response) => {
			if (response[0].successResponse) {
				const authenticated =
					response[0].successResponse.data.SecurityAuthenticated;
				if (authenticated === 'true') {
					gaSetUserId(params.username);
				}
			}
			success(...response);
		},
	);

export const getRefreshTokenApi = (error, success) =>
	getRefreshToken(
		{
			grant_type: 'refresh_token',
		},
		(...response) => {
			error(...response);
		},
		(...response) => {
			success(...response);
		},
	);

export const isAuthApi = (error, success) => isAuth(error, success);

export const forgotPasswordApi = (username, error, success) => {
	const params = {
		srtForgotPasswordUsername: username,
		srtForgotPasswordServerPath: getServerPath(),
	};

	return forgotPassword(params, error, success);
};

export const acceptTandCApi = (data, error, success) => {
	const params = {
		srtLoginUsername: data.username,
		srtLoginPassword: data.password,
	};

	return acceptTandC(params, error, success);
};

export const logOutApi = (error, success) => {
	logOut(error, (...successArgs) => {
		gaSetUserId('');
		window.localStorage.clear();
		success(...successArgs);
	});
};

export const changeUserPasswordApi = (params, error, success) =>
	changeUserPassword(
		{
			srtCurrPassword: params.currentPassword,
			srtNewPassword: params.newPassword,
			srtConfirmNewPassword: params.confirmNewPassword,
		},
		error,
		success,
	);

export const resetUserPasswordApi = (params, error, success) =>
	resetUserPassword(
		{
			srtResetPasswordUsername: params.username,
			srtResetPasswordToken: params.token,
			srtResetPasswordNew: params.newPassword,
			srtResetPasswordConfirm: params.confirmNewPassword,
		},
		error,
		success,
	);

export const resetUserPasswordTokenCheckApi = (params, error, success) =>
	resetUserPasswordTokenCheck(
		{
			srtResetPasswordUsername: params.username,
			srtResetPasswordToken: params.token,
		},
		error,
		success,
	);
