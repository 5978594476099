import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BillingTable from '../BillingTable/BillingTable';

import messages from './messages';

export default function OrderBillingSummary({ booking, orderId }) {
	const billingSummaryData = booking.BookingBillingSummary?.map(
		(item, index) => ({
			...item,
			index,
		}),
	).filter((row) => row.BookingBillingOrderId === orderId);

	return (
		<div>
			<h2>
				<FormattedMessage {...messages.lblBillingSummary} />
			</h2>
			<BillingTable
				addFooter
				billingItems={billingSummaryData}
				currency={booking.BookingBillingTotal?.BookingBillingTotalCurrency}
				type="charge"
			/>
		</div>
	);
}

OrderBillingSummary.propTypes = {
	booking: PropTypes.object.isRequired,
	orderId: PropTypes.string.isRequired,
};
