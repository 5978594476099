import { defineMessages } from 'react-intl';

export default defineMessages({
	lblLegHeaderTravelCard: {
		id: 'app.common.ticketableFareGroupTitle.legHeaderTravelCard',
		defaultMessage:
			'TravelCard Ticket{hasMarketingCarrierReference, select, true { ({marketingCarrierReference})} other {}}: {description}',
	},
	lblLegHeaderRail: {
		id: 'app.common.ticketableFareGroupTitle.legHeaderRail',
		defaultMessage:
			'Rail Ticket{hasMarketingCarrierReference, select, true { ({marketingCarrierReference})} other {}}: {description}',
	},
	lblLegHeaderSeason: {
		id: 'app.common.ticketableFareGroupTitle.lblLegHeaderSeason',
		defaultMessage: 'Season Ticket: {description}',
	},
	lblPassengers: {
		id: 'app.common.ticketableFareGroupTitle.passengers',
		defaultMessage: 'for Passengers: {passengers}',
	},
});
