import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';

import AccountGroup from '../components/AccountGroup';
import messages from '../messagesManageAccounts';

import {
	getAccountGroupDataApi,
	submitAccountGroupDataApi,
	deleteAccountGroupApi,
} from '../../apiAdmin';
import styles from '../../stylesAdmin';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { adminDataChanged } from '../../../Shopping/ShoppingSearch/actionsShoppingSearch';
import { safeDecode } from '../../../utils';

class AccountGroupForm extends Component {
	static propTypes = {
		match: PropTypes.object,
		intl: PropTypes.object.isRequired,
		onAdminDataChanged: PropTypes.func.isRequired,
		history: PropTypes.object,
	};

	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS({
				accountGroup: {},
				isFetching: true,
				processingSubmit: false,
				alertText: '',
			}),
		};
	}

	componentDidMount() {
		const accountName = this.props.match.params?.accountName
			? safeDecode(this.props.match.params.accountName)
			: '';
		getAccountGroupDataApi(
			accountName,
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						isFetching: false,
					}),
				}));
			},
			(response) => {
				const { data } = response.successResponse;
				this.setState((state) => ({
					data: state.data.merge({
						accountGroup: this.transformAccountData(accountName, data),
						isFetching: false,
					}),
				}));
			},
		);
	}

	handleHomeRedirect = () => {
		this.props.history.goBack();
	};

	handleRedirect = () => {
		setTimeout(() => {
			this.props.history.goBack();
		}, 3000);
	};

	handleDelete = (accountGroupName) => {
		const {
			intl: { formatMessage },
		} = this.props;
		this.setState(
			(state) => ({ data: state.data.merge({ isProcessingDelete: true }) }),
			() => {
				deleteAccountGroupApi(
					accountGroupName,
					(response) => {
						this.setState((state) => ({
							data: state.data.merge({
								alertText: response.errorResponse.message,
								isProcessingDelete: false,
							}),
						}));
					},
					() => {
						this.setState(
							(state) => ({
								data: state.data.merge({
									alertText: formatMessage(messages.groupDeleted),
									isProcessingDelete: false,
								}),
							}),
							() => {
								this.props.onAdminDataChanged();
								this.handleRedirect();
							},
						);
					},
				);
			},
		);
	};

	handleSubmit = (accountGroup) => {
		const {
			intl: { formatMessage },
		} = this.props;
		this.setState((state) => ({
			data: state.data.merge({ isProcessingSubmit: true }),
		}));
		submitAccountGroupDataApi(
			accountGroup,
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						isProcessingSubmit: false,
					}),
				}));
			},
			() => {
				this.setState(
					(state) => ({
						data: state.data.merge({
							alertText: state.data.getIn(['accountGroup', 'isEditMode'])
								? formatMessage(messages.groupSaved)
								: formatMessage(messages.groupCreated),
							isProcessingSubmit: false,
						}),
					}),
					() => {
						this.props.onAdminDataChanged();
						this.handleRedirect();
					},
				);
			},
		);
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	transformAccountData = (accountName, data) => {
		let allAccounts = data.AccountManagementAllAccounts.map((entry) => ({
			text: entry.Label,
			value: entry.Label,
		}));

		const associatedAccounts = data.AccountManagementAssocAccounts
			? data.AccountManagementAssocAccounts.map((entry) => ({
					text: entry.AccountManagementAccountName,
					value: entry.AccountManagementAccountName,
				}))
			: [];
		allAccounts = allAccounts.filter(
			(item) => !associatedAccounts.some((filter) => filter.text === item.text),
		);

		const srtAccountGroupFareFilter =
			data.AccountGroupFareFilter === '?NONE'
				? data.AccountGroupFareFilter.substring(1, 5)
				: data.AccountGroupFareFilter;

		return {
			associatedUsers: data.AccountManagementAssocUsers
				? data.AccountManagementAssocUsers
				: [],
			errors: {
				name: '',
				cardName: '',
				cardNumber: '',
				syncUrl: '',
				partnerName: '',
			},
			associatedAccounts,
			allAccounts,
			name: accountName,
			origName: accountName,
			isEditMode: accountName !== '',
			onAccountDetails: {
				onAccountCardName: data.accountGroupPaymentInfoCardholder || '',
				onAccountCardNumber: data.accountGroupPaymentInfoCardNumber || '',
				onAccountSecurityCode: data.accountGroupPaymentInfoSecurityCode || '',
			},
			merchantAccountId: data.AccountGroupMerchantAccountId ?? '',
			authorizeRefundCode: data.AccountGroupAuthorizeRefundCode ?? '',
			authorizeRefundDescription:
				data.AccountGroupAuthorizeRefundDescription ?? '',
			srtAccountGroupFareFilter: data.AccountGroupFareFilter
				? srtAccountGroupFareFilter
				: '',
			srtAccountGroupSeatmapApiKey: data.AccountGroupSeatmapApiKey ?? '',
			bookingSyncAllowed: data.AccountGroupBookingSyncAllowed ?? false,
			syncUrl: data.AccountGroupSyncUrl ?? '',
			partnerName: data?.AccountGroupPartnerName ?? '',
			addOrderAllowed: data.AccountGroupAddOrderAllowed ?? true,
			externalPaymentAuthorization:
				data.AccountGroupExternalPaymentAuthToken ?? '',
			phoneNumber: data.AccountGroupPhoneNumber ?? '',
			dynamicDescription: data.AccountGroupDynamicDescription ?? '',
			splitTicketingEnabled: data.AccountGroupSplitTicketingEnabled ?? false,
			maxSplits: data.AccountGroupSplitTicketingMaxSplits ?? '3',
			maxSummatedFares: data.AccountGroupSplitTicketingMaxSummatedFares ?? '3',
			splitAtCallingStations:
				data.AccountGroupSplitTicketingSplitAtCallingStations ?? true,
			splitTicketingFareFilters:
				data?.AccountGroupSplitTicketingSummatedFareFilters?.map((filters) => ({
					name: filters.AccountGroupSplitTicketingFilterName,
					fareClass: filters.AccountGroupSplitTicketingFareClass,
					fareCategory: filters.AccountGroupSplitTicketingFareCategory,
					excludeReturnFares:
						filters.AccountGroupSplitTicketingExcludeReturnFares,
				})) ?? [],
		};
	};

	render() {
		return (
			<div id="AccountGroupForm">
				{!this.state.data.get('isFetching') && (
					<AccountGroup
						accountGroup={this.state.data.get('accountGroup').toJS()}
						delete={this.handleDelete}
						submit={this.handleSubmit}
						redirect={this.handleHomeRedirect}
						isProcessingAction={
							this.state.data.get('isProcessingSubmit') ||
							this.state.data.get('isProcessingDelete')
						}
					/>
				)}
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={
						this.state.data.get('isFetching') ||
						this.state.data.get('isProcessingSubmit')
							? 'loading'
							: 'hide'
					}
					style={styles.indicator}
				/>
				<ExtendedSnackbar
					id="srtAccountGroupSnackBar"
					open={this.state.data.get('alertText') !== ''}
					message={this.state.data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

// This alias will be used to access bare component for unit testing
export { AccountGroupForm as AccountGroupFormAlias };

export default injectIntl(
	connect(null, { onAdminDataChanged: adminDataChanged })(AccountGroupForm),
);
