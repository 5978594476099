import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { injectIntl } from 'react-intl';

import messages from './messagesOnboardServices';
import './styleOnboardServices.css';
import { formatPrice } from '../../../../utils';

function OnBoardServicesView(props) {
	const { formatMessage } = props.intl;
	const {
		legs,
		onBoardServices,
		onChangeService,
		passengers,
		selectedOnBoardServices,
	} = props;

	const renderOnBoardServices = (segmentId, services) => (
		<div>
			{passengers.map((passenger, index) => {
				const { paxId } = passenger;
				const servicesPerPassenger = services[paxId];
				const segmentServices = selectedOnBoardServices[segmentId];
				const selectedService =
					segmentServices && segmentServices[paxId]
						? segmentServices[paxId]
						: null;
				const categoryKeys = Object.keys(servicesPerPassenger);

				return (
					<div key={`onBoardServices_${paxId}`} className="row">
						{categoryKeys.map((category) => {
							const serviceCategory = servicesPerPassenger[category];
							const selectedServicePriceId =
								selectedService && selectedService[category][0]
									? selectedService[category][0].ID
									: null;

							const onChangeBoardService = ({
								target: { value: serviceID },
							}) => {
								let service = null;
								if (serviceID !== '') {
									service = serviceCategory.find(
										(serv) => serv.ID === serviceID,
									);
									const price = formatPrice(
										service.value,
										service.currency,
										props.intl,
									);
									service = Object.assign(service, { priceDesc: price });
								}
								onChangeService(segmentId, paxId, category, service);
							};

							return (
								<div
									key={`onBoardService_${category}`}
									className={categoryKeys.length === 2 ? 'col-6' : 'col-12'}
								>
									<TextField
										key={`${paxId}_onBoardService`}
										id={`${paxId}_onBoardService`}
										InputLabelProps={{ shrink: true }}
										label={`${formatMessage(messages.lblPassenger)} ${index + 1}: ${passenger.ageDesc}`}
										value={selectedServicePriceId || ''}
										SelectProps={{
											autoWidth: true,
											displayEmpty: true,
										}}
										select
										fullWidth
										onChange={onChangeBoardService}
										variant="standard"
									>
										<MenuItem key={`Select_${category}_${segmentId}`} value="">
											{category === 'MEAL'
												? formatMessage(messages.lblSelectMeal)
												: formatMessage(messages.lblSelectInternet)}
										</MenuItem>
										{serviceCategory.map((service) => {
											const price = formatPrice(
												service.value,
												service.currency,
												props.intl,
											);
											return (
												<MenuItem key={`${service.ID}`} value={service.ID}>
													{`${service.onBoardServiceDescription} + ${price}`}
												</MenuItem>
											);
										})}
									</TextField>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);

	return (
		<div className="container-fluid" styleName="onBoardServices">
			{legs.map((leg, index) => (
				<div key={`onBoardServiceLeg_${index}`} className="row">
					<div className="col-12" styleName="legHeader">
						<h4 styleName="largeHeader">{`${formatMessage(messages.lblLeg)} ${index + 1}`}</h4>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-2" />
							<div className="col-10">
								{leg.ShoppingLegSolutions[0].ShoppingFareTravelSegments.map(
									(segment, i) => {
										const service =
											onBoardServices[index][segment.TravelSegmentID];
										return (
											<div key={`onBoardServiceRow_${i}`} className="row">
												<div className="col-12" styleName="segmentHeader">
													<h5 styleName="smallHeader">
														{
															// eslint-disable-next-line max-len
															`${segment.ShoppingFareDepartStationSegment} ${formatMessage(messages.lblTo)} ${segment.ShoppingFareArriveStationSegment}`
														}
													</h5>
												</div>
												<div className="col-12">
													{service ? (
														renderOnBoardServices(
															segment.TravelSegmentID,
															service,
														)
													) : (
														<div styleName="noServices">
															{formatMessage(messages.lblNoService)}
														</div>
													)}
												</div>
											</div>
										);
									},
								)}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

OnBoardServicesView.propTypes = {
	intl: PropTypes.object,
	selectedOnBoardServices: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	onBoardServices: PropTypes.array.isRequired,
	passengers: PropTypes.array.isRequired,
	onChangeService: PropTypes.func.isRequired,
	legs: PropTypes.array.isRequired,
};

export { OnBoardServicesView as OnBoardServicesViewAlias };

export default injectIntl(OnBoardServicesView);
