import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import messages from './messagesTermsAndConditions';

import './termsAndConditions.css';
import { acceptTandCApi } from '../../apiLogin';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';
import logoImage from '../../../images/logoSRFirst.png';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

class TermsAndConditions extends Component {
	static propTypes = {
		intl: PropTypes.object.isRequired,
		open: PropTypes.bool,
		onClose: PropTypes.func,
		username: PropTypes.string,
		password: PropTypes.string,
		onAccept: PropTypes.func,
	};

	initialState = {
		processingSubmit: false,
		alertText: '',
	};

	constructor(props) {
		super(props);
		this.state = { data: fromJS(this.initialState) };
	}

	handleSubmit = () => {
		const { username, password } = this.props;
		this.setState((state) => ({
			data: state.data.merge({ errors: {}, processingSubmit: true }),
		}));

		acceptTandCApi(
			{
				username,
				password,
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						processingSubmit: false,
					}),
				}));
			},
			() => {
				this.setState((state) => ({
					data: state.data.merge({
						processingSubmit: false,
					}),
				}));
				this.props.onAccept();
			},
		);
	};

	handleClose = () => {
		this.props.onClose();
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const { data } = this.state;
		const {
			intl: { formatMessage },
		} = this.props;

		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-4 offset-8 col-sm-3 offset-sm-9">
					<Button
						variant="contained"
						onClick={this.handleSubmit}
						fullWidth
						color="primary"
						data-testid="accept-button"
					>
						{formatMessage(messages.lblAccept)}
					</Button>
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={this.props.open}
					onClose={this.handleClose}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
					<DialogContent>
						<div>
							<img styleName="logo" alt="SilverRail" src={logoImage} />
						</div>
						<div styleName="tnc">
							<div data-testid="title" styleName="tnc-main">
								{formatMessage(messages.lblTitle)}
							</div>
							<div data-testid="agreement-title" styleName="tnc-title">
								{formatMessage(messages.lblAgreementTitle)}
							</div>
							<div data-testid="agreement">
								{formatMessage(messages.lblAgreement)}
							</div>
							<div data-testid="toc-title" styleName="tnc-title">
								{formatMessage(messages.lblTocTitle)}
							</div>
							<div data-testid="toc1">{formatMessage(messages.lblToc1)}</div>
							<div data-testid="toc2">{formatMessage(messages.lblToc2)}</div>
							<div data-testid="about-srt-title" styleName="tnc-title">
								{formatMessage(messages.lblAboutSrtTitle)}
							</div>
							<div data-testid="about-srt">
								{formatMessage(messages.lblAboutSrt)}
							</div>
							<div data-testid="use-sa-lite-title" styleName="tnc-title">
								{formatMessage(messages.lblUseSALiteTitle)}
							</div>
							<div data-testid="use-sa-lite-title1">
								{formatMessage(messages.lblUseSALite1)}
							</div>
							<div data-testid="use-sa-lite-title2">
								{formatMessage(messages.lblUseSALite2)}
							</div>
							<div data-testid="fee-charges-title" styleName="tnc-title">
								{formatMessage(messages.lblFeesChargesTitle)}
							</div>
							<div data-testid="fee-charges1">
								{formatMessage(messages.lblFeesCharges1)}
							</div>
							<div data-testid="fee-charges2">
								{formatMessage(messages.lblFeesCharges2)}
							</div>
							<div
								data-testid="liability-disclaimer-title"
								styleName="tnc-title"
							>
								{formatMessage(messages.lblLiabilityDisclaimerTitle)}
							</div>
							<div data-testid="liability-disclaimer1">
								{formatMessage(messages.lblLiabilityDisclaimer1)}
							</div>
							<div data-testid="liability-disclaimer2">
								{formatMessage(messages.lblLiabilityDisclaimer2)}
							</div>
							<div data-testid="liability-disclaimer3">
								{formatMessage(messages.lblLiabilityDisclaimer3)}
							</div>
							<div data-testid="other-terms-title" styleName="tnc-title">
								{formatMessage(messages.lblOtherTermsTitle)}
							</div>
							<div data-testid="other-terms1">
								{formatMessage(messages.lblOtherTerms1)}
							</div>
							<div data-testid="other-terms2">
								{formatMessage(messages.lblOtherTerms2)}
							</div>
							<div data-testid="other-terms3">
								{formatMessage(messages.lblOtherTerms3)}
							</div>
							<div data-testid="other-terms4">
								{formatMessage(messages.lblOtherTerms4)}
							</div>
							<div data-testid="other-terms5">
								{formatMessage(messages.lblOtherTerms5)}
							</div>
							<div data-testid="other-terms6">
								{formatMessage(messages.lblOtherTerms6)}
							</div>
							<div data-testid="privacy-policy-title" styleName="tnc-title">
								{formatMessage(messages.lblPrivacyPolicyTitle)}
							</div>
							<div data-testid="privacy-policy">
								{formatMessage(messages.lblPrivacyPolicy)}
							</div>
							<div data-testid="your-consent-title" styleName="tnc-title">
								{formatMessage(messages.lblYourConsentTitle)}
							</div>
							<div data-testid="your-consent">
								{formatMessage(messages.lblYourConsent)}
							</div>
							<div data-testid="active-collection-title" styleName="tnc-title">
								{formatMessage(messages.lblActiveCollectionTitle)}
							</div>
							<div data-testid="active-collection">
								{formatMessage(messages.lblActiveCollection)}
							</div>
							<div data-testid="passive-collection-title" styleName="tnc-title">
								{formatMessage(messages.lblPassiveCollectionTitle)}
							</div>
							<div data-testid="passive-collection1">
								{formatMessage(messages.lblPassiveCollection1)}
							</div>
							<div data-testid="passive-collection2">
								{formatMessage(messages.lblPassiveCollection2)}
							</div>
							<div data-testid="passive-collection3">
								{formatMessage(messages.lblPassiveCollection3)}
							</div>
							<div data-testid="passive-collection4">
								{formatMessage(messages.lblPassiveCollection4)}
							</div>
							<div data-testid="passive-collection5">
								{formatMessage(messages.lblPassiveCollection5)}
							</div>
							<div data-testid="passive-collection6">
								{formatMessage(messages.lblPassiveCollection6)}
							</div>
							<div data-testid="passive-collection7">
								{formatMessage(messages.lblPassiveCollection7)}
							</div>
							<div data-testid="use-of-info-title" styleName="tnc-title">
								{formatMessage(messages.lblUseOfInfoTitle)}
							</div>
							<div data-testid="use-of-info">
								{formatMessage(messages.lblUseOfInfo)}
							</div>
							<div data-testid="sharing-info-title" styleName="tnc-title">
								{formatMessage(messages.lblSharingOfInfoTitle)}
							</div>
							<div data-testid="sharing-info1">
								{formatMessage(messages.lblSharingOfInfo1)}
							</div>
							<div data-testid="sharing-info2">
								{formatMessage(messages.lblSharingOfInfo2)}
							</div>
							<div data-testid="security-title" styleName="tnc-title">
								{formatMessage(messages.lblSecurityTitle)}
							</div>
							<div data-testid="security">
								{formatMessage(messages.lblSecurity)}
							</div>
							<div
								data-testid="change-privacy-policy-title"
								styleName="tnc-title"
							>
								{formatMessage(messages.lblChangesPrivacyPolicyTitle)}
							</div>
							<div data-testid="change-privacy-policy">
								{formatMessage(messages.lblChangesPrivacyPolicy)}
							</div>
							<div
								data-testid="libability-disclaimer-final-title"
								styleName="tnc-title"
							>
								{formatMessage(messages.lblLiabilityDisclaimerFinalTitle)}
							</div>
							<div data-testid="libability-disclaimer-final1">
								{formatMessage(messages.lblLiabilityDisclaimerFinal1)}
							</div>
							<div data-testid="libability-disclaimer-final2">
								{formatMessage(messages.lblLiabilityDisclaimerFinal2)}
							</div>
							<div data-testid="libability-disclaimer-final3">
								{formatMessage(messages.lblLiabilityDisclaimerFinal3)}
							</div>
							<div data-testid="libability-disclaimer-final4">
								{formatMessage(messages.lblLiabilityDisclaimerFinal4)}
							</div>
							<div data-testid="libability-disclaimer-final5">
								{formatMessage(messages.lblLiabilityDisclaimerFinal5)}
							</div>
							<div data-testid="libability-disclaimer-final6">
								{formatMessage(messages.lblLiabilityDisclaimerFinal6)}
							</div>
							<div data-testid="libability-disclaimer-final7">
								{formatMessage(messages.lblLiabilityDisclaimerFinal7)}
							</div>
						</div>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtAcceptTnCSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

// This alias will be used to access bare component for unit testing
export { TermsAndConditions as TermsAndConditionsAlias };

export default injectIntl(TermsAndConditions);
