import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { isEqual } from 'date-fns';
import { injectIntl } from 'react-intl';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

import TravelCard from './TravelCard';
import messages from './messagesTravelCards';
import { formatPrice } from '../../../../utils';
import styles from './styles';

const StyledAccordion = styled(Accordion)(() => ({
	'&.Mui-expanded': {
		...styles.expandedPanel,
	},
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
	...styles.legSegment,
	'&.Mui-expanded': {
		...styles.expandedPanelSummary,
	},
	'& .MuiAccordionSummary-content': {
		...styles.legSegmentSummary,
	},
}));

const StyledGrid = styled(Grid)(() => ({}));

const TravelCardsView = memo(
	({
		intl,
		onCheckTravelCard,
		faresSelected,
		travelCards,
		selectedTravelCards,
		passengerNumber,
	}) => {
		let legs = faresSelected.toJS().map((item) => item.leg);
		const sameDate =
			legs.length > 1
				? isEqual(
						new Date(legs[0].ShoppingLegDepartDate),
						new Date(legs[1].ShoppingLegDepartDate),
					)
				: false;
		legs = sameDate ? [legs.shift()] : legs;

		const [expandedState, setExpandedState] = React.useState(
			legs[0].ShoppingLegId,
		);

		const handleLegSummaryClick = (legID) => (event, expanded) => {
			setExpandedState(() => expanded && legID);
		};

		const renderTravelCardsByZone = (travelCardsByZone) => {
			const { formatMessage } = intl;

			return Object.entries(travelCardsByZone).map(([zoneIndex, zoneValue]) => {
				const zoneCards = zoneValue.sort((zone1, zone2) => {
					if (zone1.type < zone2.type) return 1;
					if (zone1.type > zone2.type) return -1;
					return 0;
				});
				const anyTimeCard = zoneCards[0];
				const offPeakCard = zoneCards[1];
				const linkedTravelSegment =
					anyTimeCard.linkedTravelSegments[0] ||
					offPeakCard.linkedTravelSegments[0];
				const selectedTravelCardInLeg =
					selectedTravelCards.size > 1
						? selectedTravelCards.filter(
								(travelCard) =>
									travelCard.getIn(['linkedTravelSegments', 0]) ===
									linkedTravelSegment,
							)
						: selectedTravelCards;
				const selectedCardId =
					selectedTravelCardInLeg.size > 0 &&
					selectedTravelCardInLeg.first().get('ID');

				return (
					<StyledGrid
						container
						spacing={8}
						alignItems="center"
						key={`travelCard${zoneIndex}`}
					>
						<Grid item xs={4}>
							<div>{formatMessage(messages[`lblZone${zoneIndex}`])}</div>
						</Grid>
						<Grid item xs={4}>
							{anyTimeCard && (
								<TravelCard
									id={anyTimeCard.ID}
									price={anyTimeCard.priceDesc}
									flexibility={anyTimeCard.typeDesc}
									zone={anyTimeCard.zoneDesc}
									onCheckTravelCard={(event, isChecked) =>
										onCheckTravelCard(
											anyTimeCard,
											isChecked,
											linkedTravelSegment,
										)
									}
									selected={selectedCardId === anyTimeCard.ID}
								/>
							)}
						</Grid>
						<Grid item xs={4} style={{ paddingLeft: '10px' }}>
							{offPeakCard && (
								<TravelCard
									id={offPeakCard.ID}
									price={offPeakCard.priceDesc}
									flexibility={offPeakCard.typeDesc}
									zone={offPeakCard.zoneDesc}
									onCheckTravelCard={(event, isChecked) =>
										onCheckTravelCard(
											offPeakCard,
											isChecked,
											linkedTravelSegment,
										)
									}
									selected={selectedCardId === offPeakCard.ID}
								/>
							)}
						</Grid>
					</StyledGrid>
				);
			});
		};

		const { formatMessage } = intl;

		const travelCardsByZoneAndFare = travelCards.map((fare) =>
			fare.reduce((source, currentValue) => {
				const zoneType = currentValue.travelCardConsumptionRules[0].split('_');
				const zone = zoneType[0];
				const type = zoneType[1];
				const price = parseFloat(currentValue.value) * passengerNumber;
				const travelCard = {
					...currentValue,
					zone,
					type,
					zoneDesc: formatMessage(messages[`lblZone${zone}`]),
					typeDesc:
						type === 'P'
							? formatMessage(messages.lblAnytimeDay)
							: formatMessage(messages.lblOffpeakDay),
					priceDesc: formatPrice(price, currentValue.currency, intl),
					value: price,
					numberOfPassengers: passengerNumber,
				};

				if (!source[zoneType[0]]) {
					source[zoneType[0]] = [travelCard]; // eslint-disable-line no-param-reassign
				} else {
					source[zoneType[0]].push(travelCard);
				}

				return source;
			}, {}),
		);

		const lblLeg = formatMessage(messages.lblLeg);
		const lblTo = formatMessage(messages.lblTo);
		const expandable = !sameDate && travelCards[1] && travelCards[1].length > 0;

		return (
			<div className="container-fluid">
				{legs.map(
					(item, index) =>
						travelCards[index] &&
						travelCards[index].length > 0 && (
							<StyledAccordion
								data-testid="travelCardExpansionPanel"
								key={item.ShoppingLegId}
								expanded={expandedState === item.ShoppingLegId}
								onChange={
									expandable
										? handleLegSummaryClick(item.ShoppingLegId)
										: undefined
								}
							>
								<StyledAccordionSummary
									data-testid="travelCardExpansionPanelSummary"
									expandIcon={
										expandable && (
											<ExpandMoreIcon data-testid="expandMoreIcon" />
										)
									}
								>
									{`${lblLeg} ${index + 1}: ${item.ShoppingLegOrigStationName} ${lblTo} ${item.ShoppingLegDestStationName}`}
								</StyledAccordionSummary>
								<AccordionDetails data-testid="travelCardExpansionPanelDetails">
									<Grid container>
										<Grid item xs={12}>
											<h3 style={styles.legTitle}>
												{formatMessage(messages.lblSubTitle)}
											</h3>
										</Grid>
										<Grid item xs={4} />
										<Grid item xs={4}>
											<h4 style={styles.legSubtitle}>
												{formatMessage(messages.lblAnytimeDay)}
											</h4>
										</Grid>
										<Grid item xs={4}>
											<h4 style={styles.legSubtitle}>
												{formatMessage(messages.lblOffpeakDay)}
											</h4>
										</Grid>
										<Grid item xs={12}>
											{renderTravelCardsByZone(travelCardsByZoneAndFare[index])}
										</Grid>
									</Grid>
								</AccordionDetails>
							</StyledAccordion>
						),
				)}
			</div>
		);
	},
);

TravelCardsView.propTypes = {
	intl: PropTypes.object,
	onCheckTravelCard: PropTypes.func.isRequired,
	faresSelected: PropTypes.object.isRequired,
	travelCards: PropTypes.array.isRequired,
	selectedTravelCards: PropTypes.object.isRequired,
	passengerNumber: PropTypes.number.isRequired,
};

export { TravelCardsView as TravelCardsViewAlias };

export default injectIntl(TravelCardsView);
