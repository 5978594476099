import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';
import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import StationInformationView from './StationInformationView';
import { getStationDataApi } from '../apiComponents';
import { gaEvent } from '../../utils/googleAnalytics';

import messages from './messagesStationInformation';
import ExtendedSnackbar from '../ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});

class StationInformationDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		onClose: PropTypes.func,
		open: PropTypes.bool,
		stationCode: PropTypes.string,
	};

	static defaultProps = {
		open: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS({
				stationInformation: {},
				alertText: '',
				isFetching: false,
			}),
		};
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (!this.props.open && nextProps.open) {
			this.setState(
				(state) => ({
					data: state.data.merge({ isFetching: true, stationInformation: {} }),
				}),
				() => {
					getStationDataApi(
						nextProps.stationCode,
						() => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									alertText: nextProps.intl.formatMessage(
										messages.errStationSearch,
									),
								}),
							}));
						},
						(response) => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									stationInformation:
										response.successResponse.data.StationAttrs,
									alertText: '',
								}),
							}));
						},
					);
				},
			);
		}
	};

	handleSnackBarClose = () => {
		this.setState((state) => ({ data: state.data.set('alertText', '') }));
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		const { data } = this.state;
		const hasData =
			!data.get('isFetching') &&
			!validate.isEmpty(data.get('stationInformation')) &&
			data.get('stationInformation').size > 0;

		const actions = (
			<Button
				id="srStationInformationClose"
				onClick={() => {
					gaEvent('stationInformationClose');
					this.props.onClose();
				}}
				variant="contained"
			>
				{formatMessage(messages.btnClose)}
			</Button>
		);

		return (
			<div data-testid="information-dialog">
				<StyledDialog
					open={this.props.open}
					onClose={this.props.onClose}
					disableEnforceFocus
					maxWidth={false}
					id="information-dialog"
				>
					<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
					<StyledDialogContent>
						<div className="container-fluid" style={inlineStyles.stationInfo}>
							{hasData && (
								<StationInformationView
									stationInformation={data.get('stationInformation')}
								/>
							)}
							<RefreshIndicator
								size={36}
								top={0}
								left={0}
								status={data.get('isFetching') ? 'loading' : 'hide'}
								style={inlineStyles.refresh}
							/>
						</div>
					</StyledDialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtStationInformationSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackBarClose}
				/>
			</div>
		);
	}
}

export default injectIntl(StationInformationDialog);
