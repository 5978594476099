import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { fromJS } from 'immutable';
import validate from 'validate.js';
import { injectIntl } from 'react-intl';
import Account from '@mui/icons-material/AccountBalance';

import AssocUsersAutoComplete from './AssocUsersAutoComplete';
import '../stylesManageAccounts.css';
import messages from '../messagesManageAccounts';
import Confirm from '../../../components/Confirm/Confirm';
import HomeLink from '../../components/HomeLink';
import AssocAutoComplete from '../../../components/AssocAutoComplete/AssocAutoComplete';
import AssocOnAccountDetails from './AssocOnAccountDetails';
import BookingSync from './BookingSync';
import inlineStyles from './accountGroupStyles';
import { gaEvent } from '../../../utils/googleAnalytics';
import ExternalPaymentProvider from './ExternalPaymentProvider';
import { urlRegex } from '../../../utils/regex';
import Permissions from './Permissions';
import FareFilter from './FareFilter';
import SeatMapDetails from './SeatMapDetails';
import SplitTicketing from './SplitTicketing';
import AuthorizeRefundSettings from './AuthorizeRefundSettings';

const AccountGroup = ({
	accountGroup,
	submit,
	intl,
	redirect,
	isProcessingAction,
	delete: deleteAccountGroup,
}) => {
	const [data, setData] = useState(
		fromJS({
			associatedUsers: [],
			associatedAccounts: [],
			name: '',
			origName: '',
			srtAccountGroupFareFilter: '',
			srtAccountGroupSeatmapApiKey: '',
			allAccounts: [],
			isEditMode: false,
			errors: {
				name: '',
				cardName: '',
				cardNumber: '',
				syncUrl: '',
				partnerName: '',
				filtersName: '',
			},
			showConfirm: false,
			onAccountDetails: {
				onAccountCardName: '',
				onAccountCardNumber: '',
				onAccountSecurityCode: '',
			},
			merchantAccountId: '',
			dynamicDescription: '',
			phoneNumber: '',
			authorizeRefundCode: '',
			authorizeRefundDescription: '',
			bookingSyncAllowed: false,
			syncUrl: '',
			partnerName: '',
			addOrderAllowed: true,
			splitTicketingEnabled: false,
			maxSplits: '3',
			maxSummatedFares: '3',
			splitAtCallingStations: true,
			splitTicketingFareFilters: [],
		}),
	);

	const defaultSummatedFareFilters = {
		name: '',
		fareCategory: 'All',
		fareClass: 'All',
		excludeReturnFares: false,
	};

	useEffect(() => {
		if (!validate.isEmpty(accountGroup)) {
			setData((prevState) => prevState.merge(fromJS(accountGroup)));
		}
	}, [accountGroup]);

	const constraints = (formatMessage) => ({
		name: {
			presence: {
				message: formatMessage(messages.errGroupName),
				allowEmpty: false,
			},
		},
		syncUrl: (value, attributes) => {
			if (attributes.bookingSyncAllowed) {
				return {
					presence: {
						message: formatMessage(messages.errSyncUrlInvalid),
						allowEmpty: false,
					},
					format: {
						pattern: urlRegex,
						message: formatMessage(messages.errSyncUrlInvalid),
					},
				};
			}
			return null;
		},
		partnerName: (value, attributes) => {
			if (attributes.bookingSyncAllowed) {
				return {
					presence: {
						message: formatMessage(messages.errPartnerName),
						allowEmpty: false,
					},
				};
			}
			return null;
		},
	});

	const handleChangeName = ({ target: { value } }) => {
		setData(() => data.merge({ name: value }));
	};

	const handleAccountChange = (accounts) => {
		setData(() => data.set('associatedAccounts', fromJS(accounts)));
	};

	const handleUserChange = (users) => {
		setData(() =>
			data.set('associatedUsers', fromJS(users.map((user) => user.value))),
		);
	};

	const handleHideConfirm = (cancelEvent) => {
		if (!cancelEvent) {
			gaEvent('accountGroupDeleteCancel');
		}
		setData(() => data.merge({ showConfirm: false }));
	};

	const handleDeleteGroup = () => {
		handleHideConfirm(true);
		deleteAccountGroup(data.get('origName'));
	};

	const handleShowConfirm = () => {
		gaEvent('accountGroupDeleteConfirmation');
		setData(() => data.merge({ showConfirm: true }));
	};

	const handleChangeFareFilter = ({ target: { value } }) => {
		setData(() => data.merge({ srtAccountGroupFareFilter: value }));
	};

	const handleChangeSeatmapApiKey = ({ target: { value } }) => {
		setData(() => data.merge({ srtAccountGroupSeatmapApiKey: value }));
	};

	const handleClickCancel = () => {
		gaEvent('accountGroupClose', data.get('isEditMode'));
		redirect();
	};

	const handleCardNameChange = ({ target: { value } }) => {
		setData(() =>
			data.mergeIn(['onAccountDetails', 'onAccountCardName'], value),
		);
	};

	const handleCardNumberChange = ({ target: { value } }) => {
		setData(() =>
			data.mergeIn(['onAccountDetails', 'onAccountCardNumber'], value),
		);
	};

	const handleSecurityCodeChange = ({ target: { value } }) => {
		setData(() =>
			data.mergeIn(['onAccountDetails', 'onAccountSecurityCode'], value),
		);
	};

	const handleChangeField = ({ target: { name, value } }) => {
		setData(() => data.set(name, value));
	};

	const handleAuthorizeRefundDescription = ({ target: { value } }) => {
		setData(() => data.set('authorizeRefundDescription', value));
	};

	const handleAuthorizeRefundCode = ({ target: { value } }) => {
		setData(() => data.set('authorizeRefundCode', value));
	};

	const handleBookingSyncAllowedChange = (event, checked) => {
		setData(() => data.set('bookingSyncAllowed', checked));
	};

	const handleSyncUrlChange = (event) => {
		if (typeof event.persist === 'function') {
			event.persist();
		}

		const value = event.target?.value || '';
		setData((prevData) => prevData.set('syncUrl', value));
	};

	const handlePartnerNameChange = ({ target: { value } }) => {
		setData(() => data.merge({ partnerName: value }));
	};

	const handleAddOrderAllowedChange = (event, checked) => {
		setData(() => data.set('addOrderAllowed', checked));
	};

	const handleChangeFilterName =
		(index) =>
		({ target: { value } }) => {
			setData(() =>
				data.mergeIn(['splitTicketingFareFilters', index, 'name'], value),
			);
		};

	const handleCheckBoxChange = (fieldName, index) => (event, checked) => {
		if (fieldName === 'splitAtCallingStations') {
			setData(() => data.set(fieldName, checked === 'true'));
		} else if (index >= 0) {
			setData(() =>
				data.mergeIn(['splitTicketingFareFilters', index, fieldName], checked),
			);
		} else {
			setData(() => data.set(fieldName, checked));
		}
	};

	const handleSelectChange =
		(fieldName, index) =>
		({ target: { value } }) => {
			if (index >= 0) {
				setData(() =>
					data.mergeIn(['splitTicketingFareFilters', index, fieldName], value),
				);
			} else {
				setData(() => data.merge({ [fieldName]: value }));
			}
		};

	const handleAddSummatedFareFilter = () => {
		gaEvent('addSummatedFareFilter');
		setData(() =>
			data.setIn(
				[
					'splitTicketingFareFilters',
					data.get('splitTicketingFareFilters').size,
				],
				fromJS(defaultSummatedFareFilters),
			),
		);
	};

	const handleRemoveSummatedFareFilter = (index) => () => {
		gaEvent('removeSummatedFareFilter');
		setData(() => data.deleteIn(['splitTicketingFareFilters', index]));
	};

	const handleClickSubmit = () => {
		const { formatMessage } = intl;
		const resetErrors = {
			name: '',
			cardName: '',
			cardNumber: '',
			syncUrl: '',
			partnerName: '',
		};

		setData(data.merge({ errors: resetErrors }));
		const dataToValidate = {
			name: data.get('name'),
			cardName: data.getIn(['onAccountDetails', 'onAccountCardName']),
			cardNumber: data.getIn(['onAccountDetails', 'onAccountCardNumber']),
			securityCode: data.getIn(['onAccountDetails', 'onAccountSecurityCode']),
			syncUrl: data.get('syncUrl'),
			partnerName: data.get('partnerName'),
			bookingSyncAllowed: data.get('bookingSyncAllowed'),
		};

		const errors = validate(dataToValidate, constraints(formatMessage)) || {};

		if (data.get('splitTicketingFareFilters').size > 0) {
			const isFiltersNameEmpty = data
				.get('splitTicketingFareFilters')
				.toJS()
				.some((filters) => filters.name === '');
			if (isFiltersNameEmpty) {
				errors.filtersName = 'Summated Fare Filter name should not be empty';
			}
		}

		if (!validate.isEmpty(errors)) {
			setData(data.merge({ errors }));
			return;
		}
		submit(data.toJS());
	};
	const { formatMessage } = intl;

	const deleteButton =
		!data.get('isEditMode') ||
		(data.get('showConfirm') ? (
			<Confirm onYes={handleDeleteGroup} onNo={handleHideConfirm} />
		) : (
			<Button
				variant="contained"
				data-testid="srtAccountGroupDelete"
				id="srtAccountGroupDelete"
				color="primary"
				styleName="button-spacing"
				onClick={handleShowConfirm}
				disabled={isProcessingAction}
			>
				{formatMessage(messages.lblDeleteAccountGroup)}
			</Button>
		));

	return (
		<div id="AccountGroup" data-testid="AccountGroup">
			<div className="row" style={inlineStyles.accountGroup}>
				<HomeLink
					id="srtAccountGroupHome"
					className="col-12"
					text={data.get('name')}
					onClick={redirect}
				/>
				<div className="col-12">
					<TextField
						id="srtAddEditName"
						label={formatMessage(messages.lblGroupName)}
						fullWidth
						value={data.get('name')}
						onChange={handleChangeName}
						error={!!data.getIn(['errors', 'name'])}
						helperText={data.getIn(['errors', 'name'])}
						variant="standard"
					/>
				</div>
				<div className="col-12 col-lg-6">
					<AssocAutoComplete
						id="srtAccount"
						options={data.get('allAccounts').toJS()}
						values={data.get('associatedAccounts').toJS()}
						onChange={handleAccountChange}
						placeholder={formatMessage(messages.lblAddAssocAccount)}
						label={formatMessage(messages.lblAssocAccount)}
						headerTitle={formatMessage(messages.lblAssocAccounts)}
						icon={<Account />}
					/>
				</div>
				<div className="col-12 col-lg-6">
					<AssocUsersAutoComplete
						users={data.get('associatedUsers')}
						onChange={handleUserChange}
						placeholder={formatMessage(messages.lblAddAssocUser)}
						label={formatMessage(messages.lblAssocUser)}
						headerTitle={formatMessage(messages.lblAssocUsers)}
					/>
				</div>
				<div className="col-12 col-lg-6">
					<AssocOnAccountDetails
						values={data.get('onAccountDetails').toJS()}
						onCardNumberChange={handleCardNumberChange}
						onCardNameChange={handleCardNameChange}
						onSecurityCodeChange={handleSecurityCodeChange}
						errors={data.get('errors').toJS()}
					/>
					<SeatMapDetails
						handleChangeSeatmapApiKey={handleChangeSeatmapApiKey}
						srtAccountGroupSeatmapApiKey={data.get(
							'srtAccountGroupSeatmapApiKey',
						)}
					/>
				</div>
				<div className="col-12 col-lg-6">
					<ExternalPaymentProvider
						externalPaymentAuthorization={
							data.get('externalPaymentAuthorization') || ''
						}
						merchantAccountId={data.get('merchantAccountId')}
						dynamicDescription={data.get('dynamicDescription')}
						phoneNumber={data.get('phoneNumber')}
						onChangeAuthorization={handleChangeField}
						handleChangeField={handleChangeField}
						displayDynamicDescriptors
					/>
					<div style={inlineStyles.fareFilter} />
					<FareFilter
						handleChangeFareFilter={handleChangeFareFilter}
						srtAccountGroupFareFilter={data.get('srtAccountGroupFareFilter')}
						accountGroupName={data.get('accountGroupName')}
					/>
					<div style={inlineStyles.fareFilter} />
					<AuthorizeRefundSettings
						authorizeRefundDescription={data.get('authorizeRefundDescription')}
						authorizeRefundCode={data.get('authorizeRefundCode')}
						onChangeAuthorizeRefundDescription={
							handleAuthorizeRefundDescription
						}
						onChangeAuthorizeRefundCode={handleAuthorizeRefundCode}
					/>
				</div>
				<div className="col-12 col-lg-6">
					<BookingSync
						bookingSyncAllowed={
							data.get('bookingSyncAllowed')
								? data.get('bookingSyncAllowed')
								: accountGroup?.bookingSyncAllowed
						}
						onBookingSyncAllowedChange={handleBookingSyncAllowedChange}
						syncUrl={
							data.get('syncUrl') ? data.get('syncUrl') : accountGroup?.syncUrl
						}
						onSyncUrlChange={handleSyncUrlChange}
						partnerName={
							data.get('partnerName')
								? data.get('partnerName')
								: accountGroup?.partnerName
						}
						onPartnerNameChange={handlePartnerNameChange}
						errors={data.get('errors').toJS()}
					/>
				</div>
				<div className="col-12 col-lg-6">
					<SplitTicketing
						splitTicketingEnabled={data.get('splitTicketingEnabled')}
						maxSplits={data.get('maxSplits')?.toString()}
						maxSummatedFares={data.get('maxSummatedFares')?.toString()}
						splitAtCallingStations={data.get('splitAtCallingStations')}
						intl={intl}
						onCheckBoxChange={handleCheckBoxChange}
						onSelectChange={handleSelectChange}
						onAddSummatedFareFilter={handleAddSummatedFareFilter}
						onRemoveSummatedFareFilter={handleRemoveSummatedFareFilter}
						onChangeFilterName={handleChangeFilterName}
						splitTicketingFareFilters={data.get('splitTicketingFareFilters')}
						errors={data.get('errors').toJS()}
					/>
				</div>
				<div className="col-12 col-lg-6">
					<Permissions
						addOrderAllowed={data.get('addOrderAllowed')}
						onAddOrderAllowedChange={handleAddOrderAllowedChange}
					/>
				</div>
			</div>
			<hr />
			<div className="row" styleName="space-fix">
				<div className="col-12 col-sm-6 col-lg-4 col-xl-3">{deleteButton}</div>
				<div className="col-12 col-sm-2 offset-sm-2 offset-lg-4 offset-xl-5">
					<Button
						variant="contained"
						id="srtAccountGroupCancel"
						data-testid="srtAccountGroupCancel"
						styleName="button-spacing"
						fullWidth
						onClick={handleClickCancel}
						disabled={isProcessingAction}
					>
						{formatMessage(messages.lblCancel)}
					</Button>
				</div>
				<div className="col-12 col-sm-2">
					<Button
						data-testid="srtAccountGroupSubmit"
						variant="contained"
						id="srtAccountGroupSubmit"
						styleName="button-spacing"
						fullWidth
						color="primary"
						onClick={handleClickSubmit}
						disabled={isProcessingAction}
					>
						{formatMessage(messages.lblSave)}
					</Button>
				</div>
			</div>
		</div>
	);
};

AccountGroup.propTypes = {
	accountGroup: PropTypes.object.isRequired,
	delete: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	redirect: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	isProcessingAction: PropTypes.bool,
};

// This alias will be used to access bare component for unit testing
export { AccountGroup as AccountGroupAlias };

export default injectIntl(AccountGroup);
