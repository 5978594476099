import React from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@mui/material/ListSubheader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';
import { FormattedMessage } from 'react-intl';

import messages from '../messagesManageAccounts';
import styles from './accountGroupStyles';

const StyledListSubheader = styled(ListSubheader)(() => ({
	...styles.subHeader,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
	...styles.label,
}));

function Permissions({ addOrderAllowed, onAddOrderAllowedChange }) {
	return (
		<>
			<StyledListSubheader>
				<FormattedMessage {...messages.lblGroupPermissions} />
			</StyledListSubheader>
			<StyledFormControlLabel
				control={
					<Switch
						id="srtBookingAddOrderAllowed"
						checked={addOrderAllowed}
						onChange={onAddOrderAllowedChange}
					/>
				}
				label={<FormattedMessage {...messages.lblGroupAddOrderAllowed} />}
				labelPlacement="start"
			/>
		</>
	);
}

Permissions.propTypes = {
	addOrderAllowed: PropTypes.bool,
	onAddOrderAllowedChange: PropTypes.func.isRequired,
};

export { Permissions as PermissionsAlias };
export default Permissions;
