import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import { FormattedMessage } from 'react-intl';

import messages from '../messagesManageAccounts';
import inlineStyles from './accountGroupStyles';

const AssocOnAccountDetails = (props) => {
	const {
		values,
		onCardNameChange,
		onCardNumberChange,
		onSecurityCodeChange,
		errors,
	} = props;

	return (
		<div>
			<ListSubheader style={inlineStyles.subHeader}>
				<FormattedMessage {...messages.lblGroupAssociatedOnAccountDetails} />
			</ListSubheader>
			<TextField
				id="srtOnAccountCardName"
				label={<FormattedMessage {...messages.lblGroupOnAccountName} />}
				fullWidth
				value={values.onAccountCardName}
				onChange={onCardNameChange}
				error={!!errors.cardName}
				helperText={errors.cardName}
				variant="standard"
			/>
			<TextField
				id="srtOnAccountCardNumber"
				label={<FormattedMessage {...messages.lblGroupOnAccountCard} />}
				fullWidth
				value={values.onAccountCardNumber}
				onChange={onCardNumberChange}
				error={!!errors.cardNumber}
				helperText={errors.cardNumber}
				variant="standard"
			/>
			<TextField
				id="srtAccountGroupSecurityCode"
				label={<FormattedMessage {...messages.lblGroupOnAccountSecurityCode} />}
				fullWidth
				value={values.onAccountSecurityCode}
				onChange={onSecurityCodeChange}
				error={!!errors.securityCode}
				helperText={errors.securityCode}
				variant="standard"
			/>
		</div>
	);
};

AssocOnAccountDetails.propTypes = {
	values: PropTypes.object,
	onCardNameChange: PropTypes.func.isRequired,
	onCardNumberChange: PropTypes.func.isRequired,
	onSecurityCodeChange: PropTypes.func.isRequired,
	errors: PropTypes.object,
};

export default AssocOnAccountDetails;
