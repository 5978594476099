import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import SortIcon from '@mui/icons-material/SwapVert';
import TableCell from '@mui/material/TableCell';
import inlineStyles from './styles';

const StyledTableCell = styled(TableCell)(() => ({
	...inlineStyles.rowItemBase,
	...inlineStyles.rowHeadItem,
}));

const StyledTooltip = styled(Tooltip)(() => ({
	...inlineStyles.tooltip,
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
	...inlineStyles.sortLabel,
	'& .MuiTableSortLabel-icon': {
		...inlineStyles.sortLabelIcon,
	},
	'&.Mui-active': {
		...inlineStyles.activeLabel,
	},
}));

const SortableTableCell = ({
	index,
	orderBy,
	order,
	sortByColumn,
	rowObject,
}) => (
	<StyledTableCell
		key={index}
		id={index}
		padding="normal"
		sortDirection={orderBy === index ? order : false}
		onClick={sortByColumn(index)}
		align={rowObject.align}
	>
		<StyledTooltip title="Sort" placement="left-start" enterDelay={300}>
			<StyledTableSortLabel
				id={index}
				active
				direction={orderBy === index ? order : undefined}
				IconComponent={orderBy !== index ? SortIcon : undefined}
			>
				{rowObject.text}
			</StyledTableSortLabel>
		</StyledTooltip>
	</StyledTableCell>
);

SortableTableCell.propTypes = {
	index: PropTypes.number.isRequired,
	orderBy: PropTypes.string,
	order: PropTypes.string.isRequired,
	sortByColumn: PropTypes.func.isRequired,
	rowObject: PropTypes.object.isRequired,
};

export default SortableTableCell;
