import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import '../../ManageBooking/components/BookingView/styleBookingView.css';

import messages from './messagesNotes';
import NotesTable from './NotesTable';

const SupplierNotesTable = (props) => {
	const { intl, id, notes } = props;
	const { formatMessage } = intl;

	const tableHeaders = (
		<TableRow>
			<TableCell>{formatMessage(messages.lblNotes)}</TableCell>
		</TableRow>
	);

	const tableRows = notes.map((note, index) => (
		<TableRow key={`note_${index}`}>
			<TableCell id={`${id}_note_${index}`} styleName="bookingTableRow">
				{note}
			</TableCell>
		</TableRow>
	));

	return <NotesTable tableHeaders={tableHeaders} tableRows={tableRows} />;
};

SupplierNotesTable.propTypes = {
	intl: PropTypes.object,
	id: PropTypes.string,
	notes: PropTypes.array.isRequired,
};

SupplierNotesTable.defaultProps = {
	id: 'srtSupplierNotesTable',
};

export { SupplierNotesTable as SupplierNotesTableAlias };

export default injectIntl(SupplierNotesTable);
