import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import validate from 'validate.js';
import { injectIntl } from 'react-intl';
import messages from './messagesNotes';
import inlineStyles from './styles';
import NotesView from './NotesView';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';

import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

class NotesDialog extends Component {
	static propTypes = {
		addNote: PropTypes.func.isRequired,
		booking: PropTypes.object.isRequired,
		constraints: PropTypes.object.isRequired,
		editSupported: PropTypes.bool,
		filterNotes: PropTypes.func.isRequired,
		intl: PropTypes.object,
		multiline: PropTypes.bool,
		noteAddedAlert: PropTypes.string.isRequired,
		notes: PropTypes.array.isRequired,
		NotesTableComponent: PropTypes.func.isRequired,
		notesTableId: PropTypes.string.isRequired,
		onAddNote: PropTypes.func,
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		selectNoteType: PropTypes.bool,
		title: PropTypes.string.isRequired,
	};

	static defaultProps = {
		editSupported: true,
	};

	static getDerivedStateFromProps(props, state) {
		return { data: state.data.merge({ notes: props.notes || [] }) };
	}

	initialState = {
		notes: [],
		filter: '',
		noteType: 'AGENT_NOTE',
		note: '',
		processingSubmit: false,
		submitDisabled: true,
		alertText: '',
		errors: {},
	};

	constructor(props) {
		super(props);
		this.state = { data: fromJS(this.initialState) };
	}

	handleNoteChange = (updates) => {
		this.setState((state) => {
			let submitDisabled = state.data.get('submitDisabled');
			if (updates.note !== undefined) {
				submitDisabled = updates.note.length === 0;
			}
			return { data: state.data.merge({ submitDisabled, ...updates }) };
		});
	};

	handleFilterChange = ({ target: { value } }) => {
		const { filterNotes } = this.props;
		const filterBy = value.toString().toLowerCase();
		const filteredNotes = filterNotes(filterBy);
		this.setState((state) => ({
			data: state.data.merge({ notes: filteredNotes, filter: value }),
		}));
	};

	handleClose = () => {
		this.setState({ data: fromJS(this.initialState) }, () => {
			this.props.onClose();
		});
	};

	handleSubmit = () => {
		const { data } = this.state;
		const { booking, addNote, onAddNote, constraints, noteAddedAlert } =
			this.props;

		this.setState({ data: data.merge({ errors: {}, processingSubmit: true }) });
		const errors = validate(data.toJS(), constraints);
		if (!validate.isEmpty(errors)) {
			this.setState({ data: data.merge({ errors, processingSubmit: false }) });
			return;
		}

		const { queryItems } = booking;
		const error = (response) => {
			this.setState((state) => ({
				data: state.data.merge({
					alertText: response.errorResponse.message,
					processingSubmit: false,
				}),
			}));
		};
		const success = () => {
			onAddNote(queryItems);
			this.setState((state) => ({
				data: state.data.merge({
					alertText: noteAddedAlert,
					processingSubmit: false,
					submitDisabled: true,
					filter: '',
					noteType: 'AGENT_NOTE',
					note: '',
				}),
			}));
		};

		addNote(queryItems, data, error, success);
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			editSupported,
			multiline,
			NotesTableComponent,
			notesTableId,
			open,
			selectNoteType,
			title,
			intl: { formatMessage },
		} = this.props;
		const { data } = this.state;
		const disabled = data.get('submitDisabled');
		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div
					className={`col-4 ${editSupported ? 'offset-2 offset-md-5' : 'offset-4 offset-md-8'}`}
				>
					<Button
						variant="contained"
						id="srtNotesClose"
						onClick={this.handleClose}
						fullWidth
					>
						{formatMessage(messages.lblClose)}
					</Button>
				</div>
				{editSupported && (
					<div className="col-4 col-md-3">
						{data.get('processingSubmit') ? (
							<RefreshIndicator size={36} left={40} top={0} status="loading" />
						) : (
							<Button
								variant="contained"
								id="srtNotesSubmit"
								onClick={this.handleSubmit}
								fullWidth
								color="primary"
								disabled={disabled}
							>
								{formatMessage(messages.lblSubmit)}
							</Button>
						)}
					</div>
				)}
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={open}
					onClose={this.handleClose}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<NotesView
							notes={data.get('notes').toJS()}
							filter={data.get('filter')}
							noteType={data.get('noteType')}
							note={data.get('note')}
							onNoteChange={this.handleNoteChange}
							onFilterChange={this.handleFilterChange}
							errors={data.get('errors').toJS()}
							NotesTableComponent={NotesTableComponent}
							notesTableId={notesTableId}
							selectNoteType={selectNoteType}
							multiline={multiline}
							editSupported={editSupported}
						/>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtNotesSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

const mapsStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
	onAddNote: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	mapsStateToProps,
	mapDispatchToProps,
)(injectIntl(NotesDialog));
