import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';

import { formatPrice } from '../../../utils';

import {
	confirmationOptions,
	findConfirmationValues,
	shouldDisableCustomerEmail,
} from '../../utils';
import Payment from '../../../components/Payment/Payment';
import Address from '../../../components/Address/Address';
import Confirmation from '../Confirmation/Confirmation';
import TermsOfService from '../TermsOfService/TermsOfService';
import { getPaymentDueApi, getPaymentDataApi } from '../../apiBooking';

import messages from './messagesMakePayment';
import inlineStyles from './styles';

class MakePayment extends Component {
	static propTypes = {
		intl: PropTypes.object,
		booking: PropTypes.object.isRequired,
		addressTypes: PropTypes.object,
		countries: PropTypes.object,
		states: PropTypes.object,
		provinces: PropTypes.object,
		cocMap: PropTypes.object,
		paymentInfo: PropTypes.object,
		showTermsOfService: PropTypes.bool,
		onInit: PropTypes.func.isRequired,
		onChange: PropTypes.func.isRequired,
		disableCustomerEmailSending: PropTypes.bool,
		ignoreDisableEmailSendingSuppliers: PropTypes.array,
		exchangeMode: PropTypes.bool,
		configBasedAuth: PropTypes.bool,
		getBraintreeNonceFunctionRef: PropTypes.func.isRequired,
		exchangeBalance: PropTypes.string,
		braintreeClientToken: PropTypes.string,
		isExchangeValidateFailed: PropTypes.bool,
	};

	static defaultProps = {
		disableCustomerEmailSending: false,
		ignoreDisableEmailSendingSuppliers: [],
		exchangeMode: false,
	};

	componentDidMount() {
		const { booking, onChange, configBasedAuth } = this.props;
		if (!configBasedAuth) {
			onChange({ isFetching: true, alertText: '' });
			getPaymentDataApi(
				booking.BookingAccountName,
				(response) => {
					onChange({
						isFetching: false,
						alertText: response.errorResponse.message,
					});
				},
				(response) => {
					this.callGetPaymentDue(response.successResponse.data);
				},
			);
		} else {
			this.callGetPaymentDue();
		}
	}

	callGetPaymentDue = (account) => {
		const {
			exchangeMode,
			booking,
			onChange,
			onInit,
			exchangeBalance,
			isExchangeValidateFailed,
		} = this.props;

		if (!exchangeMode) {
			onChange({ isFetching: true, alertText: '' });

			getPaymentDueApi(
				booking.queryItems,
				(response) => {
					onChange({
						isFetching: false,
						alertText: response.errorResponse.message,
						errors: { accountGroup: true },
					});
				},
				(response) => {
					const paymentInformation = response.successResponse.data;
					onInit(paymentInformation, account);
				},
			);
		} else if (isExchangeValidateFailed) {
			onChange({ isFetching: true, alertText: '' });
			getPaymentDueApi(
				booking.queryItems,
				(response) => {
					onChange({
						isFetching: false,
						alertText: response.errorResponse.message,
						errors: { accountGroup: true },
					});
				},
				(response) => {
					const paymentInformation = response.successResponse.data;
					const paymentSummary = paymentInformation.BookingPaymentSummary;
					paymentSummary.BookingPaymentSummaryBalanceDue = exchangeBalance;
					paymentSummary.BookingPaymentSummaryMinDepositAmount =
						exchangeBalance;
					onInit(paymentInformation, account);
				},
			);
		} else {
			onInit(booking, account);
		}
	};

	handleChangeConfirmationField = (updates) => {
		this.props.onChange(updates, ['confirmation']);
	};

	checkForAlternativeEmail = () => {
		const ticketDelivery =
			this.props.booking.BookingOrders[0].BookingOrderTicketDelivery;
		return ticketDelivery === 'ETK' || ticketDelivery === 'PAH';
	};

	render() {
		const {
			booking,
			exchangeMode,
			disableCustomerEmailSending,
			ignoreDisableEmailSendingSuppliers,
			paymentInfo,
			intl: { formatMessage },
			getBraintreeNonceFunctionRef,
			braintreeClientToken,
		} = this.props;

		const {
			paymentDue,
			isFetching,
			isProcessingSubmit,
			confirmation,
			errors,
			termsOfServiceCheck,
		} = paymentInfo;

		const isDataReceived = !validate.isEmpty(paymentDue);
		const disabled = isFetching || isProcessingSubmit;

		const paymentMethods = isDataReceived ? paymentDue.paymentMethods : [];
		const paymentSummary = isDataReceived
			? paymentDue.BookingPaymentSummary
			: [];
		const bookingConfirmationInformation = isDataReceived
			? paymentDue.BookingConfirmationInformation
			: [];
		const hideConfirmationOptionsPaper =
			!!bookingConfirmationInformation.BookingPaymentConfirmationOptions &&
			!!findConfirmationValues(
				confirmationOptions.disabled,
				bookingConfirmationInformation,
			);
		const showAlternateEmail = !exchangeMode
			? this.checkForAlternativeEmail()
			: false;
		const suppliers = !exchangeMode
			? booking.BookingOrders[0].BookingOrderTDOData.BookingSupplierCodes
			: booking.BookingSupplierCodes;
		const disableCustomerEmail = shouldDisableCustomerEmail(
			disableCustomerEmailSending,
			ignoreDisableEmailSendingSuppliers,
			suppliers,
		);
		const isSms = !exchangeMode
			? booking.BookingOrders[0].BookingOrderTicketDelivery.indexOf('SMS') === 0
			: false;

		const orderLegDetailsFareGroups = booking.BookingOrders.map(
			(order) => order.BookingOrderLegDetails.ticketableFareGroups,
		);
		const travelSegments = orderLegDetailsFareGroups?.[0]?.map(
			(fareGroup) => fareGroup.BookingLegTravelSegments,
		);
		const hasAmtrak = travelSegments?.[0]?.some((segment) =>
			segment.BookingOrderDetailsLegTravelSegmentCarrier.includes('Amtrak'),
		);

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-12" style={inlineStyles.columnAdjust}>
						<Paper style={inlineStyles.paperContainer} elevation={1}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell data-testid="balance-due">
											{formatMessage(messages.lblBalanceDue)}
										</TableCell>
										<TableCell data-testid="minimum-deposit">
											{formatMessage(messages.lblMinimumDepositAmount)}
										</TableCell>
										<TableCell data-testid="deposit-due">
											{formatMessage(messages.lblDepositDue)}
										</TableCell>
										<TableCell data-testid="payment-amount">
											{formatMessage(messages.lblPaymentAmount)}
										</TableCell>
										<TableCell data-testid="payment-date">
											{formatMessage(messages.lblPaymentDate)}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{isDataReceived && (
										<TableRow>
											<TableCell
												id="srtMakePayment_balanceDue"
												data-testid="balance-due-value"
											>
												{formatPrice(
													Number(
														paymentSummary.BookingPaymentSummaryBalanceDue,
													),
													paymentSummary.BookingPaymentSummaryMinDepositCurrency,
													this.props.intl,
												)}
											</TableCell>
											<TableCell
												id="srtMakePayment_minDepositAmount"
												data-testid="minimum-deposit-value"
											>
												{formatPrice(
													Number(
														paymentSummary.BookingPaymentSummaryMinDepositAmount,
													),
													paymentSummary.BookingPaymentSummaryMinDepositCurrency,
													this.props.intl,
												)}
											</TableCell>
											<TableCell
												id="srtMakePayment_depositDue"
												data-testid="deposit-due-value"
											>
												{paymentSummary.BookingPaymentSummaryDepositDueDate}
											</TableCell>
											<TableCell
												id="srtMakePayment_paymentAmount"
												data-testid="payment-amount-value"
											>
												{formatPrice(
													Number(
														paymentSummary.BookingPaymentSummaryBalanceDue,
													),
													paymentSummary.BookingPaymentSummaryMinDepositCurrency,
													this.props.intl,
												)}
											</TableCell>
											<TableCell
												id="srtMakePayment_paymentDate"
												data-testid="payment-date-value"
											>
												{paymentSummary.BookingPaymentSummaryPaymentDueDate}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Paper>
					</div>
				</div>
				<div className="row">
					<div className="col-12" style={inlineStyles.columnAdjust}>
						<Paper style={inlineStyles.paperContainer} elevation={1}>
							<div className="row">
								<div className="col-12 col-sm-6">
									<h5
										style={inlineStyles.subTitle}
										data-testid="payment-info-title"
									>
										{formatMessage(messages.lblPaymentInformation)}
									</h5>
									<Payment
										values={paymentInfo}
										onChange={this.props.onChange}
										paymentMethods={paymentMethods}
										errors={errors}
										disabled={disabled}
										getBraintreeNonceFunctionRef={getBraintreeNonceFunctionRef}
										braintreeClientToken={braintreeClientToken}
									/>
								</div>
								{paymentInfo.method !== 'NONE' && (
									<div
										className="col-12 col-sm-6"
										data-testid="payment-info-content"
									>
										<h5 style={inlineStyles.subTitle}>
											{formatMessage(messages.lblBillingAddress)}
										</h5>
										<Address
											idPrefix="srtBilling"
											values={paymentInfo}
											countries={this.props.countries}
											states={this.props.states}
											provinces={this.props.provinces}
											addressTypes={this.props.addressTypes}
											errors={errors}
											onChange={this.props.onChange}
											disabled={disabled}
										/>
									</div>
								)}
							</div>
						</Paper>
					</div>
				</div>
				{hideConfirmationOptionsPaper || exchangeMode || (
					<div className="row" data-testid="confirmation-options">
						<div className="col-12" style={inlineStyles.columnAdjust}>
							<Paper style={inlineStyles.paperContainer} elevation={1}>
								{isDataReceived ? (
									<Confirmation
										confirmationInformation={
											paymentDue.BookingConfirmationInformation
										}
										onChange={this.handleChangeConfirmationField}
										confirmationDetails={confirmation}
										errors={errors.confirmation ? errors.confirmation : {}}
										showAlternateEmail={showAlternateEmail}
										emails={paymentDue.BookingEmailForClaimVD}
										disabled={disabled}
										styles={inlineStyles}
										columnClass="col-12 col-sm-6"
										disableCustomerEmailSending={disableCustomerEmail}
										paymentInfo={paymentInfo}
										isSms={isSms}
									/>
								) : null}
							</Paper>
						</div>
					</div>
				)}
				{this.props.showTermsOfService && isDataReceived ? (
					<div className="row">
						<div
							className="col-12"
							style={inlineStyles.columnAdjust}
							data-testid="terms-of-service"
						>
							<Paper
								style={
									errors.termsOfServiceCheck
										? {
												...inlineStyles.outlineError,
												...inlineStyles.paperContainer,
											}
										: inlineStyles.paperContainer
								}
								elevation={1}
							>
								<h5>{formatMessage(messages.lblTermsOfService)}</h5>
								<TermsOfService
									supplierCodes={paymentDue.BookingSupplierCodes}
									cocMap={this.props.cocMap}
									disabled={disabled}
									onChange={this.props.onChange}
									checked={termsOfServiceCheck}
									hasAmtrak={hasAmtrak}
								/>
							</Paper>
						</div>
					</div>
				) : null}
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={disabled ? 'loading' : 'hide'}
					style={inlineStyles.RefreshIndicator}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isExchangeValidateFailed: state.getIn([
		'booking',
		'isExchangeValidateFailed',
	]),
});

export { MakePayment as MakePaymentAlias };

export default connect(mapStateToProps, null)(injectIntl(MakePayment));
