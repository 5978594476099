import React, { useState } from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import { NavLink } from 'react-router-dom';
import { styled } from '@mui/system';

import styles from './styles';

const StyledListItem = styled(ListItem)(() => ({
	...styles.listItem,
}));

const StyledNavLink = styled(NavLink)(() => ({
	...styles.link,
	'&.active': {
		...styles.activeLink,
	},
}));

const StyledIconButton = styled(IconButton)(() => ({
	...styles.icon,
}));

const StyledList = styled(List)(() => ({
	...styles.innerList,
}));

const StyledDiv = styled('div')(() => ({
	...styles.sidebar,
}));

const Sidebar = ({ items, handleClick }) => {
	const [expanded, setExpanded] = useState({});

	const expandCollapseItem = (event, item) => {
		setExpanded((state) => ({ ...state, [item]: !state[item] }));
		event.preventDefault();
		event.stopPropagation();
	};

	const generateLinks = (item, sub) => {
		const IconExpand = expanded[item.value] ? ExpandLess : ExpandMore;
		return (
			<React.Fragment key={item.value}>
				<StyledListItem button>
					<StyledNavLink
						id={sub === true ? `${item.id}_sub` : item.id}
						to={item.value}
						onClick={() => handleClick(item)}
					>
						{item.text}
					</StyledNavLink>
					{item.nestedItems && (
						<StyledIconButton
							onClick={(event) => expandCollapseItem(event, item.value)}
						>
							<IconExpand data-testid="expand-item-button" />
						</StyledIconButton>
					)}
				</StyledListItem>
				{item.nestedItems && (
					<Collapse
						in={!!expanded[item.value]}
						data-testid="nested-items-container"
					>
						<StyledList component="nav">
							{item.nestedItems.map((subItem) => generateLinks(subItem, true))}
						</StyledList>
					</Collapse>
				)}
			</React.Fragment>
		);
	};

	return (
		<StyledDiv>
			<List component="nav">{items.map(generateLinks)}</List>
		</StyledDiv>
	);
};

Sidebar.propTypes = {
	items: PropTypes.array,
	handleClick: PropTypes.func,
};

export { Sidebar as SidebarAlias };
export default Sidebar;
