import { defaultFareColorClassSchema } from '../../../../colors';

export default {
	icon: {
		color: defaultFareColorClassSchema.orange,
	},
	warning: {
		display: 'flex',
		alignItems: 'center',
	},
};
