import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import messages from '../messagesAdmin';

const USER_STATUSES_EN = ['Active', 'Not active'];
const USER_STATUSES_FR = ['Actif', 'Pas actif'];

const SelectUserStatus = ({ value, onChange, id, locale, hideLabel }) => {
	const statusMap = locale === 'fr_FR' ? USER_STATUSES_FR : USER_STATUSES_EN;
	return (
		<TextField
			id={id}
			value={value}
			onChange={onChange}
			label={hideLabel ? '' : <FormattedMessage {...messages.lblUserStatus} />}
			margin={hideLabel ? 'dense' : 'none'}
			fullWidth
			select
			variant="standard"
		>
			{statusMap.map((item, index) => (
				<MenuItem id={`${id}_locale${index}`} key={item} value={item}>
					<FormattedMessage
						{...messages.selectUserStatus}
						values={{
							userStatus: item,
						}}
					/>
				</MenuItem>
			))}
		</TextField>
	);
};

SelectUserStatus.defaultProps = {
	hideLabel: false,
};

SelectUserStatus.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	id: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
	hideLabel: PropTypes.bool,
};

export default SelectUserStatus;
