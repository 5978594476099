import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import inlineStyles from './styles';
import messages from './messages';
import ShoppingSearch from '../../../../Shopping/ShoppingSearch/ShoppingSearch';
import { TRIP_TYPE_SINGLE } from '../../../../Shopping/util';
import ExchangeOrderTitle from '../../../components/ExchangeOrderDialog/ExchangeOrderTitle';
import { getJourneysListApi } from '../../../apiBooking';
import ExtendedSnackbar from '../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import TrainJourneySelector from './components/TrainJourneySelector/TrainJourneySelector';
import RefreshIndicator from '../../../../components/RefreshIndicator/RefreshIndicator';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const extractLocations = (superOrders) => {
	const locations = [];

	superOrders.forEach(([superOrderId, orders]) => {
		orders.forEach((order) => {
			if (order && typeof order === 'object') {
				const departure = order.BookingOrderDepartureLocation;
				const destination = order.BookingOrderDestination;
				if (departure && destination) {
					locations.push({ superOrderId, departure, destination });
				}
			}
		});
	});

	return locations;
};

const AmendSeatReservationsDialog = ({
	superOrders,
	open,
	handleClose,
	booking,
	passengers,
	queryItems,
	orderAccount,
}) => {
	const [displayShoppingSearch, setDisplayShoppingSearch] = useState(false);
	const [showTrainJourneys, setShowTrainJourneys] = useState(false);
	const [trainJourneysData, setTrainJourneysData] = useState(null);
	const [errorText, setErrorText] = useState('');
	const [isFetching, setIsFetching] = useState(false);
	const [confirmSeatsData, setConfirmSeatsData] = useState({});

	const searchCompRef = useRef(null);

	const locations = extractLocations(superOrders);
	const bookingOrder = booking.BookingOrders[0];

	const { BookingOrderSeatReservationAmendmentLegs } =
		bookingOrder?.BookingOrderSeatReservationAmendmentInfo;

	const {
		BookingOrderSeatReservationAmendmentLegCanAdd = false,
		BookingOrderSeatReservationAmendmentLegCanChange = false,
	} = BookingOrderSeatReservationAmendmentLegs?.[0] ?? {};

	const { ticketableFareGroups } = bookingOrder.BookingOrderLegDetails;

	const handleSearchAmendSeatReservationsJourneys = () => {
		setIsFetching(true);
		const departureDateString = ticketableFareGroups[0].departDate;
		const departDateOnly = departureDateString.split(' ')[0];
		const departTimeOnly = departureDateString.split(' ')[1];

		const arrivalDateString = ticketableFareGroups[0].arriveDate;
		const arriveDateOnly = arrivalDateString.split(' ')[0];
		const arriveTimeOnly = arrivalDateString.split(' ')[1];

		const data = {
			shoppingContext: orderAccount.value,
			recordLocator: queryItems.srtRecLocator,
			orderLocator: bookingOrder.BookingOrderID,
			ticketableFareLocators:
				ticketableFareGroups[0].BookingDetailsLegTicketableFareIds,
			legSolutionId: '1', // 1 or 2 are the expected allowed values
			departCode: ticketableFareGroups[0].departStationCode,
			departDate: departDateOnly,
			departTime: departTimeOnly,
			arriveCode: ticketableFareGroups[0].arriveStationCode,
			arriveDate: arriveDateOnly,
			arriveTime: arriveTimeOnly,
		};

		getJourneysListApi(
			data,
			(response) => {
				const errorMsg = response?.errorResponse?.message;
				setErrorText(errorMsg);
				setIsFetching(false);
			},
			(success) => {
				setConfirmSeatsData({
					DataStorageId: success.successResponse.data.DataStorageId,
					shoppingContext: orderAccount.value,
					recordLocator: queryItems.srtRecLocator,
					orderLocator: bookingOrder.BookingOrderID,
					ticketableFareLocators: [
						ticketableFareGroups[0].BookingDetailsLegTicketableFareIds,
					],
				});
				setTrainJourneysData(success.successResponse);
				setDisplayShoppingSearch(false);
				setShowTrainJourneys(true);
				setIsFetching(false);
			},
		);
	};

	const renderActions = () => {
		if (displayShoppingSearch) {
			return (
				<div className="row" style={inlineStyles.dialogActionsRoot}>
					<div
						className="col-4 offset-4 offset-md-8"
						style={inlineStyles.dialogActionsShoppingSearch}
					>
						<Button
							variant="contained"
							id="srtAmendSeatReservationsClose"
							onClick={handleClose}
							fullWidth
						>
							<FormattedMessage {...messages.btnClose} />
						</Button>
						<Button
							variant="contained"
							id="srtAmendSeatReservationsClose"
							onClick={() => {
								handleSearchAmendSeatReservationsJourneys();
							}}
							fullWidth
							color="primary"
							disabled={isFetching}
						>
							<FormattedMessage
								{...messages.lblAmendSeatReservationsDialogSearchForTrains}
							/>
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-4 offset-4 offset-md-8">
					<Button
						variant="contained"
						id="srtAmendSeatReservationsClose"
						onClick={handleClose}
						fullWidth
					>
						<FormattedMessage {...messages.btnClose} />
					</Button>
				</div>
			</div>
		);
	};

	const handleChangeJourneyAndReservationsClick = () => {
		setDisplayShoppingSearch(true);
	};

	const renderJourneyFlows = () => {
		if (displayShoppingSearch) {
			const selectedFareQualifiers = [];
			const supplyChannelCode = '';

			const departStationData = {
				name: ticketableFareGroups[0].departStationLongName,
				code: ticketableFareGroups[0].departStationCode,
				supplier: ticketableFareGroups[0].departStationCodeSuppliers.join(','),
				countryCode: ticketableFareGroups[0].departStationCountryCode,
			};
			const arriveStationData = {
				name: ticketableFareGroups[0].arriveStationLongName,
				code: ticketableFareGroups[0].arriveStationCode,
				supplier: ticketableFareGroups[0].arriveStationCodeSuppliers.join(','),
				countryCode: ticketableFareGroups[0].arriveStationCountryCode,
			};

			const bookingOrderId = bookingOrder.BookingOrderID;

			return (
				<div>
					<ExchangeOrderTitle
						exchangeableTicketableFareIds={
							ticketableFareGroups[0].BookingDetailsLegTicketableFareIds
						}
						isSeasonTrip={false}
						ticketableFareGroups={ticketableFareGroups}
					/>
					<ShoppingSearch
						innerRef={searchCompRef}
						exchangeOrderMode
						amendSeatReservationMode
						orderPassengers={passengers}
						orderAccount={orderAccount}
						departStationData={departStationData}
						arriveStationData={arriveStationData}
						recordLocator={queryItems.srtRecLocator}
						orderId={bookingOrderId}
						exchangeOrderTripType={TRIP_TYPE_SINGLE}
						selectedFareQualifiers={selectedFareQualifiers}
						supplyChannelCode={supplyChannelCode}
					/>
				</div>
			);
		}

		if (showTrainJourneys && trainJourneysData) {
			const journeys =
				trainJourneysData.data.ShoppingLegs[0].ShoppingLegSolutions;
			return (
				<div>
					<ExchangeOrderTitle
						exchangeableTicketableFareIds={
							ticketableFareGroups[0].BookingDetailsLegTicketableFareIds
						}
						isSeasonTrip={false}
						ticketableFareGroups={ticketableFareGroups}
					/>
					<TrainJourneySelector
						journeys={journeys}
						trainJourneysData={trainJourneysData}
						ticketableFareGroups={ticketableFareGroups}
						confirmSeatsData={confirmSeatsData}
						refreshBookingParams={queryItems}
						closeAllDialogs={() => {
							setDisplayShoppingSearch(false);
							handleClose();
						}}
					/>
				</div>
			);
		}

		return (
			<div>
				<div>
					<div>
						{locations?.length && (
							<h2 style={inlineStyles.h2}>
								{locations[0].departure}
								<ArrowForwardIcon style={inlineStyles.arrow} />
								{locations[0].destination}
							</h2>
						)}
					</div>
				</div>
				<div>
					<div style={inlineStyles.seatButtons}>
						<Button
							variant="contained"
							id="srtChangeSeats"
							color="primary"
							fullWidth
							disabled={!BookingOrderSeatReservationAmendmentLegCanChange}
							onClick={() => null}
						>
							<FormattedMessage
								{...messages.lblAmendSeatReservationsDialogChangeSeat}
							/>
						</Button>

						<Button
							variant="contained"
							id="srtAddSeats"
							color="primary"
							fullWidth
							disabled={!BookingOrderSeatReservationAmendmentLegCanAdd}
							onClick={() => null}
						>
							<FormattedMessage
								{...messages.lblAmendSeatReservationsDialogAddSeat}
							/>
						</Button>
					</div>
					<div>
						<Button
							variant="contained"
							id="srtChangeJourneyAndReservations"
							color="primary"
							fullWidth
							onClick={handleChangeJourneyAndReservationsClick}
						>
							<FormattedMessage
								{...messages.lblAmendSeatReservationsDialogChangeTrainAndSeat}
							/>
						</Button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<StyledDialog
				open={open}
				onClose={() => {
					setDisplayShoppingSearch(false);
					handleClose();
				}}
				disableEnforceFocus
				maxWidth={false}
			>
				<DialogTitle>
					<FormattedMessage {...messages.lblAmendSeatReservationsDialogTitle} />
				</DialogTitle>
				<DialogContent>{renderJourneyFlows()}</DialogContent>
				<DialogActions>{renderActions()}</DialogActions>
			</StyledDialog>
			<ExtendedSnackbar
				id="srtAmendSeatReservationsSnackBar"
				open={errorText !== ''}
				message={errorText}
				onClose={() => setErrorText('')}
			/>
			<div className="container-fluid">
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={isFetching ? 'loading' : 'hide'}
					style={{
						marginTop: '10%',
						marginLeft: '45%',
					}}
				/>
			</div>
		</div>
	);
};

AmendSeatReservationsDialog.propTypes = {
	superOrders: PropTypes.array,
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	booking: PropTypes.object,
	passengers: PropTypes.array,
	queryItems: PropTypes.object,
	orderAccount: PropTypes.object,
};

export default injectIntl(AmendSeatReservationsDialog);
