import { homeTab } from './constants';
import { getBasePath } from '../../utils';
import { bookingSearchApi, loadBookingDetailsApi } from '../apiBooking';

export const INITIATE_REQUEST = 'INITIATE_REQUEST';
export const RECEIVE_BOOKING_SEARCH = 'RECEIVE_BOOKING_SEARCH';
export const RECEIVE_BOOKING = 'RECEIVE_BOOKING';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const CLOSE_BOOKING = 'CLOSE_BOOKING';
export const NEW_BOOKING_REQUEST = 'NEW_BOOKING_REQUEST';
export const CLEAR_NEW_BOOKING_REQUEST = 'CLEAR_NEW_BOOKING_REQUEST';
export const SET_LAST_ACTIVE_BOOKING_TAB = 'SET_LAST_ACTIVE_BOOKING_TAB';
export const SET_CANCEL_ORDER_ID = 'SET_CANCEL_ORDER_ID';
export const CLEAR_CANCEL_ORDER_ID = 'CLEAR_CANCEL_ORDER_ID';
export const SET_SEND_TICKET_NEW_ORDER = 'SET_SEND_TICKET_NEW_ORDER';
export const CLEAR_SEND_TICKET_NEW_ORDER = 'CLEAR_SEND_TICKET_NEW_ORDER';
export const SET_OPEN_ISSUE_REFUND_DIALOG = 'SET_OPEN_ISSUE_REFUND_DIALOG';
export const CLEAR_OPEN_ISSUE_REFUND_DIALOG = 'CLEAR_OPEN_ISSUE_REFUND_DIALOG';
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS';
export const SET_SEATMAP_DATA = 'SET_SEATMAP_DATA';
export const SET_EXCHANGE_VALIDATE = 'SET_EXCHANGE_VALIDATE';
export const CLEAR_EXCHANGE_VALIDATE = 'CLEAR_EXCHANGE_VALIDATE';

export const clearOpenIssueRefundDialog = () => ({
	type: CLEAR_OPEN_ISSUE_REFUND_DIALOG,
});

export const setOpenIssueRefundDialog = () => ({
	type: SET_OPEN_ISSUE_REFUND_DIALOG,
});

export const clearCancelOrderId = () => ({
	type: CLEAR_CANCEL_ORDER_ID,
});

export const setCancelOrderId = (cancelOrderId) => ({
	type: SET_CANCEL_ORDER_ID,
	cancelOrderId,
});

export const clearSendTicketNewOrder = () => ({
	type: CLEAR_SEND_TICKET_NEW_ORDER,
});

export const setSendTicketNewOrder = (
	sendTicketNewOrderId,
	isSendTicketPossible,
) => ({
	type: SET_SEND_TICKET_NEW_ORDER,
	sendTicketNewOrderId,
	isSendTicketPossible,
});

export const setLastActiveBookingTab = (activeTab, history) => (dispatch) => {
	const path = `${getBasePath()}booking/Manage/${activeTab !== homeTab ? activeTab : ''}`;
	history.push(path);
	dispatch({
		type: SET_LAST_ACTIVE_BOOKING_TAB,
		activeTab,
	});
};

export const newBookingRequest = (queryItems) => ({
	type: NEW_BOOKING_REQUEST,
	queryItems,
});

export const clearNewBookingRequest = () => ({
	type: CLEAR_NEW_BOOKING_REQUEST,
});

export const initiateRequest = () => ({
	type: INITIATE_REQUEST,
});

export const receiveBookingSearch = (payload, error) => ({
	type: RECEIVE_BOOKING_SEARCH,
	payload,
	error,
});

export const fetchBookingSearch = (searchData, intl) => (dispatch) => {
	dispatch(initiateRequest());
	return new Promise((resolve) => {
		bookingSearchApi(
			{
				searchData,
				intl,
			},
			(response) => {
				dispatch(receiveBookingSearch(response.errorResponse.message, true));
				resolve(null);
			},
			(response) => {
				const results = response.successResponse.data.BookingSearchResults;
				dispatch(receiveBookingSearch(results, false));
				resolve(results);
			},
		);
	});
};

export const receiveBooking = (payload, queryItems, error) => ({
	type: RECEIVE_BOOKING,
	payload,
	queryItems,
	error,
});

export const receiveBookingWithWarning = (
	payload,
	queryItems,
	error,
	warningMessage,
) => ({
	type: RECEIVE_BOOKING,
	payload,
	queryItems,
	error,
	warningMessage,
});

export const fetchBookingDetails = (queryItems) => (dispatch) => {
	dispatch(initiateRequest());
	return loadBookingDetailsApi(
		queryItems,
		(response) => {
			dispatch(receiveBooking(response.errorResponse.message, {}, true));
		},
		(response) => {
			if (response?.warningResponse?.message) {
				dispatch(
					receiveBookingWithWarning(
						response.successResponse.data,
						queryItems,
						false,
						response.warningResponse.message,
					),
				);
				return response.successResponse.data;
			}
			dispatch(
				receiveBooking(response.successResponse.data, queryItems, false),
			);
			return response.successResponse.data;
		},
	);
};

export const clearSearchResults = () => ({ type: CLEAR_SEARCH_RESULTS });

export const clearErrorMessage = () => ({ type: CLEAR_ERROR_MESSAGE });

export const closeBooking = (recordLocator) => ({
	type: CLOSE_BOOKING,
	recordLocator,
});

export const setOrderDetails = (payload) => ({
	type: SET_ORDER_DETAILS,
	payload,
});

export const getSeatMapData = (payload) => ({
	type: SET_SEATMAP_DATA,
	payload,
});

export const setExchangeValidate = (payload) => ({
	type: SET_EXCHANGE_VALIDATE,
	payload,
});

export const clearExchangeValidate = () => ({ type: CLEAR_EXCHANGE_VALIDATE });
