import React from 'react';
import PropTypes from 'prop-types';

const InputComponent = React.forwardRef(({ children, ...props }, ref) => (
	<div ref={ref} {...props}>
		{children}
	</div>
));

InputComponent.propTypes = {
	children: PropTypes.any,
};

export default InputComponent;
