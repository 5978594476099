export default {
	buttons: {
		marginBottom: 10,
	},
	dialogPaper: {
		overflowY: 'hidden',
		maxWidth: 768,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	titleTotal: {
		textAlign: 'right',
	},
};
