import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import validate from 'validate.js';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import TravelCardsDialog from '../TravelCardsDialog/TravelCardsDialog';
import OnboardServicesDialog from '../OnboardServicesDialog/OnboardServicesDialog';
import ReservationsDialog from '../ReservationsDialog/ReservationsDialog';
import OptionRow from './OptionRow';

import { formatPrice } from '../../../../utils';

import styles from './styles';
import messages from './messagesBookingOptions';
import PlusBusSupplementsDialog from '../PlusBusSupplementsDialog/PlusBusSupplementsDialog';

const StyledTableCellOptionsDesc = styled(TableCell)(() => ({
	...styles.optionsDescColumn,
}));

const StyledTableCellOptionsPrice = styled(TableCell)(() => ({
	...styles.optionsPriceColumn,
}));

const StyledPaper = styled(Paper)(() => ({
	...styles.paperContainer,
}));

const StyledOptionButtons = styled('div')(() => ({
	...styles.optionButtons,
}));

const StyledButton = styled(Button)(() => ({
	...styles.buttons,
}));

const BookingOptions = ({
	areReservable,
	createBookingData,
	currency,
	disabled,
	faresSelected,
	handleAddOnboardServices,
	handleAddSeatReservations,
	handleAddBikeReservations,
	handleAddTravelCards,
	handleChangeOnboardServices,
	handleChangeSeatReservations,
	handleChangeBikeReservations,
	handleChangeTravelCards,
	handleCloseOnboardServices,
	handleCloseSeatReservations,
	handleCloseBikeReservations,
	handleCloseTravelCards,
	hasOnBoardServices,
	hasSeatReservations,
	hasBikeReservations,
	hasTravelCards,
	intl,
	onBoardServices,
	bikeReservations,
	passengerInfo,
	selectedOnBoardServices,
	selectedOnBoardServicesGroupBySegment,
	selectedSeatReservations,
	selectedSeatReservationsPrice,
	selectedBikeReservationsPrice,
	selectedBikeReservations,
	selectedTravelCards,
	shoppingPassengers,
	travelCards,
	addOrderMode,
	exchangeOrderMode,
	handleAddPlusBusSupplements,
	handleChangePlusBusSupplements,
	handleClosePlusBusSupplements,
	hasPlusBusSupplements,
	selectedPlusBusSupplements,
	plusBusSupplements,
}) => {
	const renderTravelCardsOptionRows = () =>
		selectedTravelCards
			.toJS()
			.map((selectedTravelCard) => (
				<OptionRow
					key={selectedTravelCard.ID}
					formattedPrice={selectedTravelCard.priceDesc}
					id="srtTravelCardOption"
					type={messages.lblTravelCards}
					desc={`${selectedTravelCard.typeDesc} ${selectedTravelCard.zoneDesc}`}
				/>
			));

	const renderPlusBusSupplementOptionRows = () =>
		selectedPlusBusSupplements.map((selectedPlusBusSupplement) => (
			<OptionRow
				key={selectedPlusBusSupplement.id}
				formattedPrice={selectedPlusBusSupplement.priceDesc}
				id="srtPlusBusSupplementOption"
				type={messages.lblPlusBusSupplements}
				desc={`${selectedPlusBusSupplement.description}}`}
			/>
		));

	const renderOnBoardOptionRow = () =>
		selectedOnBoardServices.map((service, index) => (
			<TableRow key={`options_${service.ID}`}>
				<StyledTableCellOptionsDesc
					id={`srtOnBoardServicesOptionType_${index}`}
				>
					{service.onBoardServiceDescription}
				</StyledTableCellOptionsDesc>
				<TableCell id={`srtOnBoardServicesOptionDesc_${index}`} />
				<StyledTableCellOptionsPrice
					id={`srtOnBoardServicesOptionPrice_${index}`}
				>
					{service.priceDesc}
				</StyledTableCellOptionsPrice>
			</TableRow>
		));

	let formattedSeatResPrice = null;
	if (selectedSeatReservationsPrice !== null) {
		formattedSeatResPrice = formatPrice(
			selectedSeatReservationsPrice,
			currency,
			intl,
		);
	} else if (areReservable === 'MANDATORY') {
		formattedSeatResPrice = <FormattedMessage {...messages.lblIncluded} />;
	}

	const formattedBikeResPrice =
		selectedBikeReservationsPrice || selectedBikeReservationsPrice === 0
			? formatPrice(selectedBikeReservationsPrice, currency, intl)
			: '';

	const legs = faresSelected.map((item) => item.get('leg')).toJS();

	return (
		(hasTravelCards ||
			hasOnBoardServices ||
			hasSeatReservations ||
			hasBikeReservations ||
			hasPlusBusSupplements) && (
			<StyledPaper elevation={1}>
				<div className="row">
					<div className="col-12">
						<h2>
							<FormattedMessage {...messages.lblOptions} />
						</h2>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<FormattedMessage {...messages.lblType} />
									</TableCell>
									<StyledTableCellOptionsDesc>
										<FormattedMessage {...messages.lblDescription} />
									</StyledTableCellOptionsDesc>
									<StyledTableCellOptionsPrice>
										<FormattedMessage {...messages.lblPrice} />
									</StyledTableCellOptionsPrice>
								</TableRow>
							</TableHead>
							<TableBody>
								{formattedSeatResPrice ? (
									<OptionRow
										key="srtSeatReservationOption"
										formattedPrice={formattedSeatResPrice}
										id="srtSeatReservationOption"
										type={messages.lblSeatReservations}
									/>
								) : null}
								{formattedBikeResPrice ? (
									<OptionRow
										key="srtBikeReservationOption"
										formattedPrice={formattedBikeResPrice}
										id="srtBikeReservationOption"
										type={messages.lblSupplement}
										desc={intl.formatMessage(messages.lblBikeReservations)}
									/>
								) : null}
								{!validate.isEmpty(selectedTravelCards)
									? renderTravelCardsOptionRows()
									: null}
								{!validate.isEmpty(selectedPlusBusSupplements)
									? renderPlusBusSupplementOptionRows()
									: null}
								{!validate.isEmpty(selectedOnBoardServices)
									? renderOnBoardOptionRow()
									: null}
							</TableBody>
						</Table>
						{hasTravelCards && (
							<StyledOptionButtons>
								<StyledButton
									variant="contained"
									id="srtAddEditTravelCards"
									color="secondary"
									onClick={handleAddTravelCards}
									disabled={disabled}
								>
									<FormattedMessage {...messages.lblTravelCards} />
								</StyledButton>
								<TravelCardsDialog
									open={createBookingData.get('openTravelCards')}
									faresSelected={faresSelected}
									travelCards={travelCards}
									onChangeTravelCards={handleChangeTravelCards}
									onDiscardTravelCards={handleCloseTravelCards}
									selectedTravelCards={selectedTravelCards}
									passengerNumber={passengerInfo.size}
								/>
							</StyledOptionButtons>
						)}
						{hasPlusBusSupplements && (
							<StyledOptionButtons>
								<StyledButton
									variant="contained"
									id="srtAddEditPlusBusSupplements"
									color="secondary"
									onClick={handleAddPlusBusSupplements}
									disabled={disabled}
								>
									<FormattedMessage {...messages.lblPlusBusSupplementsButton} />
								</StyledButton>
								<PlusBusSupplementsDialog
									open={createBookingData.get('openPlusBusSupplements')}
									faresSelected={faresSelected}
									plusBusSupplements={plusBusSupplements}
									onChangePlusBusSupplements={handleChangePlusBusSupplements}
									onDiscardPlusBusSupplements={handleClosePlusBusSupplements}
									selectedPlusBusSupplements={selectedPlusBusSupplements}
									passengerNumber={passengerInfo.size}
								/>
							</StyledOptionButtons>
						)}
						{hasOnBoardServices && (
							<StyledOptionButtons>
								<StyledButton
									variant="contained"
									id="srtAddEditOnBoardServices"
									color="secondary"
									onClick={handleAddOnboardServices}
									disabled={disabled}
								>
									<FormattedMessage {...messages.lblAddOnboardServices} />
								</StyledButton>
								<OnboardServicesDialog
									open={createBookingData.get('openOnBoardServices')}
									onDiscardOnBoardServices={handleCloseOnboardServices}
									onChangeOnBoardServices={handleChangeOnboardServices}
									selectedOnBoardServices={selectedOnBoardServicesGroupBySegment.toJS()}
									onBoardServices={onBoardServices}
									passengers={passengerInfo.toJS()}
									legs={legs}
								/>
							</StyledOptionButtons>
						)}
						{hasSeatReservations &&
							!!faresSelected &&
							faresSelected.size > 0 && (
								<StyledOptionButtons>
									<StyledButton
										variant="contained"
										id="srtAddEditSeatReservations"
										color="secondary"
										onClick={() =>
											handleAddSeatReservations(formattedSeatResPrice)
										}
										disabled={disabled}
									>
										<FormattedMessage {...messages.lblSeatReservations} />
									</StyledButton>
								</StyledOptionButtons>
							)}
						{hasBikeReservations && (
							<StyledOptionButtons>
								<StyledButton
									variant="contained"
									id="srtAddEditBikeReservations"
									color="secondary"
									onClick={() =>
										handleAddBikeReservations(formattedBikeResPrice)
									}
									disabled={disabled}
								>
									<FormattedMessage {...messages.lblBikeReservations} />
								</StyledButton>
							</StyledOptionButtons>
						)}
						{(hasSeatReservations || hasBikeReservations) && (
							<ReservationsDialog
								open={
									createBookingData.get('openSeatReservations') ||
									createBookingData.get('openBikeReservations')
								}
								faresSelected={faresSelected}
								seatReservation={
									!!createBookingData.get('openSeatReservations')
								}
								bikeReservations={bikeReservations}
								shoppingPassengers={shoppingPassengers}
								onChangeSeatReservations={handleChangeSeatReservations}
								onChangeBikeReservations={handleChangeBikeReservations}
								onDiscardSeatReservations={handleCloseSeatReservations}
								onDiscardBikeReservations={handleCloseBikeReservations}
								selectedSeatReservations={selectedSeatReservations}
								selectedBikeReservations={selectedBikeReservations}
								addOrderMode={addOrderMode}
								exchangeOrderMode={exchangeOrderMode}
							/>
						)}
					</div>
				</div>
			</StyledPaper>
		)
	);
};

BookingOptions.propTypes = {
	areReservable: PropTypes.string,
	createBookingData: PropTypes.object.isRequired,
	currency: PropTypes.string,
	disabled: PropTypes.bool,
	faresSelected: PropTypes.object,
	handleAddOnboardServices: PropTypes.func.isRequired,
	handleAddSeatReservations: PropTypes.func.isRequired,
	handleAddBikeReservations: PropTypes.func.isRequired,
	handleAddTravelCards: PropTypes.func.isRequired,
	handleChangeOnboardServices: PropTypes.func.isRequired,
	handleChangeSeatReservations: PropTypes.func.isRequired,
	handleChangeBikeReservations: PropTypes.func.isRequired,
	handleChangeTravelCards: PropTypes.func.isRequired,
	handleCloseOnboardServices: PropTypes.func.isRequired,
	handleCloseSeatReservations: PropTypes.func.isRequired,
	handleCloseBikeReservations: PropTypes.func.isRequired,
	handleCloseTravelCards: PropTypes.func.isRequired,
	hasOnBoardServices: PropTypes.bool,
	hasSeatReservations: PropTypes.bool,
	hasBikeReservations: PropTypes.bool,
	hasTravelCards: PropTypes.bool,
	hasPlusBusSupplements: PropTypes.bool,
	intl: PropTypes.object,
	onBoardServices: PropTypes.array,
	bikeReservations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	passengerInfo: PropTypes.object,
	selectedOnBoardServices: PropTypes.object,
	selectedOnBoardServicesGroupBySegment: PropTypes.object,
	selectedSeatReservations: PropTypes.object,
	selectedSeatReservationsPrice: PropTypes.object,
	selectedBikeReservationsPrice: PropTypes.number,
	selectedBikeReservations: PropTypes.object,
	selectedTravelCards: PropTypes.object,
	selectedPlusBusSupplements: PropTypes.array,
	shoppingPassengers: PropTypes.object,
	travelCards: PropTypes.array,
	plusBusSupplements: PropTypes.array,
	addOrderMode: PropTypes.bool,
	exchangeOrderMode: PropTypes.bool,
	handleChangePlusBusSupplements: PropTypes.func.isRequired,
	handleClosePlusBusSupplements: PropTypes.func.isRequired,
	handleAddPlusBusSupplements: PropTypes.func.isRequired,
};

export { BookingOptions as BookingOptionsAlias };
export default injectIntl(BookingOptions);
