import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import BookIcon from '@mui/icons-material/Book';

import PassengerInformationDialog from '../../../components/PassengerInformationDialog/PassengerInformation';
import PassengerCustomInformation from '../PassengerCustomInformation/PassengerCustomInformation';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog';
import ExtendedSnackbar from '../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../../utils/googleAnalytics';
import { deletePersonalInformationApi } from '../../../apiBooking';
import { contactType as contactTypes } from '../../../../Shopping/util';

import messages from './messages';
import styles from './styles';

const StyledDivContentContainer = styled('div')(() => ({
	...styles.contentContainer,
}));

const StyledPaper = styled(Paper)(() => ({
	...styles.paperContainer,
	...styles.tableWrapper,
}));

const StyledDivTitleContainer = styled('div')(() => ({
	...styles.titleContainer,
}));

const StyledButton = styled(Button)(() => ({
	...styles.removePassengerButton,
}));

const StyledDeleteIcon = styled(DeleteIcon)(() => ({
	...styles.removePassengerIcon,
}));

const StyledTable = styled(Table)(() => ({
	...styles.bookingTable,
}));

const StyledTableBody = styled(TableBody)(() => ({
	...styles.tableWrapper,
}));

const StyledTableCellLink = styled(TableCell)(() => ({
	...styles.bookingTableRow,
	...styles.bookingTableLink,
}));

const StyledTableCell = styled(TableCell)(() => ({
	...styles.bookingTableRow,
}));

const StyledIconButton = styled(IconButton)(() => ({
	...styles.customInformationIcon,
}));

class OrderPassengers extends React.PureComponent {
	state = {
		alertText: '',
		deletePersonalInformationIsOpen: false,
		customInformationIndex: null,
	};

	handleOpenDeleteCardholderInformation = () => {
		this.setState({ deletePersonalInformationIsOpen: true });
	};

	handleCloseDeleteCardholderInformation = () => {
		this.setState({ deletePersonalInformationIsOpen: false });
	};

	handleDeleteCardholderInformation = () => {
		const { booking, updateIsProcessingSubmit } = this.props;
		updateIsProcessingSubmit(true);
		this.handleCloseDeleteCardholderInformation();
		deletePersonalInformationApi(
			booking.queryItems,
			(response) => {
				this.setState({
					alertText: response.errorResponse.message,
				});
				updateIsProcessingSubmit(false);
			},
			() => {
				updateIsProcessingSubmit(false);
			},
		);
	};

	handleSnackBarClose = () => {
		this.setState({ alertText: '' });
	};

	updatePassengerCustomInformationIndex =
		(index) =>
		(registerEvent = true) => {
			if (registerEvent) {
				gaEvent(
					`bookingPassengerCustomInformation${index === null ? 'Close' : 'Open'}`,
				);
			}
			this.setState({ customInformationIndex: index });
		};

	getTypeLabel = (type) =>
		type &&
		contactTypes.find((contactType) => contactType.value === type)?.label;

	render() {
		const {
			booking,
			handleClosePassengerInfoDialog,
			handleDialogSnackbarError,
			handleOpenPassengerInfoDialog,
			isProcessingAction,
			openPaxInfoDialog,
			passengers,
		} = this.props;
		const {
			alertText,
			deletePersonalInformationIsOpen,
			customInformationIndex,
		} = this.state;

		const passengerRights =
			booking?.BookingOrders?.[0]?.BookingOrderLegDetails
				?.ticketableFareGroups?.[0]?.BookingLegTravelSegments?.[0]
				?.BookingLegTravelSegmentPassengerRights || [];
		const uniquePassengerRights = [...new Set(passengerRights)];
		const allPassengerRights = uniquePassengerRights.map((passengerRight) => (
			<div key={passengerRight}>
				{passengerRight.replace(/\([^)]*\) */, '')}
			</div>
		));

		const customInformationPassenger =
			customInformationIndex !== null ? passengers[customInformationIndex] : {};

		return (
			<StyledDivContentContainer data-testid="order-passengers">
				<StyledPaper elevation={1}>
					<StyledDivTitleContainer>
						<h2>
							<FormattedMessage {...messages.lblPassengers} />
						</h2>
						<StyledButton
							onClick={this.handleOpenDeleteCardholderInformation}
							disabled={isProcessingAction}
							size="small"
						>
							<StyledDeleteIcon />
							<FormattedMessage {...messages.btnDeleteCardholderInformation} />
						</StyledButton>
					</StyledDivTitleContainer>
					<StyledTable>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage {...messages.lblPassengerName} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.lblPassengerEmailAddress} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.lblPassengerPhone} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.lblPassengerAge} />
								</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<StyledTableBody>
							{passengers?.map((pax, index) => (
								<TableRow id={`srtPax_${index}`} key={`pax_${index}`}>
									<StyledTableCellLink id={`srtPax_${index}_name`}>
										<span
											onClick={() => {
												gaEvent('passengerInformationOpen');
												handleOpenPassengerInfoDialog(index);
											}}
										>
											{`${pax.BookingPaxFirstName} ${pax.BookingPaxLastName}`}
										</span>
									</StyledTableCellLink>
									<StyledTableCell id={`srtPax_${index}_email`}>
										{pax.BookingPaxContactEmailAddresses.map(
											(email, emailIndex) => (
												<div key={emailIndex}>
													{this.getTypeLabel(email.BookingPaxContactType)}
													{`: ${email.BookingPaxContactInfo}`}
												</div>
											),
										)}
									</StyledTableCell>
									<StyledTableCell id={`srtPax_${index}_phone`}>
										{pax.BookingPaxContactPhoneNumbers.map(
											(phone, phoneIndex) => (
												<div key={phoneIndex}>
													{this.getTypeLabel(phone.BookingPaxContactType)}
													{`: ${phone.BookingPaxContactInfo}`}
												</div>
											),
										)}
									</StyledTableCell>
									<StyledTableCell id={`srtPax_${index}_age`}>
										{pax.BookingPaxAgeAtTimeOfTravel}
									</StyledTableCell>
									<TableCell id={`srtPax_${index}_custom_information`}>
										<StyledIconButton
											onClick={this.updatePassengerCustomInformationIndex(
												index,
											)}
										>
											<BookIcon id={`srtPax${index}CustomInformationButton`} />
										</StyledIconButton>
									</TableCell>
								</TableRow>
							))}
						</StyledTableBody>
					</StyledTable>
					<div style={styles.passengerRights}>{allPassengerRights}</div>
				</StyledPaper>
				<PassengerInformationDialog
					selectedPax={openPaxInfoDialog}
					passengers={passengers}
					booking={booking}
					onClose={(updating) => {
						gaEvent(
							updating
								? 'updatePassengerInformationAbandon'
								: 'passengerInformationClose',
						);
						handleClosePassengerInfoDialog();
					}}
					handleDialogSnackbarError={handleDialogSnackbarError}
				/>
				<PassengerCustomInformation
					key={
						customInformationIndex !== null
							? customInformationIndex.toString()
							: ''
					}
					customInformation={
						customInformationPassenger.BookingPaxCustomInformation
					}
					open={customInformationIndex !== null}
					dialogClose={this.updatePassengerCustomInformationIndex(null)}
					passengerName={`${customInformationPassenger.BookingPaxFirstName} ${customInformationPassenger.BookingPaxLastName}`}
					passengerId={customInformationPassenger.BookingPassengerID}
					queryItems={booking.queryItems}
					source="MANAGE"
				/>
				<ConfirmDialog
					open={deletePersonalInformationIsOpen}
					message={
						<FormattedMessage
							{...messages.lblDeleteCardholderInformationConfirmation}
						/>
					}
					onYes={this.handleDeleteCardholderInformation}
					onNo={this.handleCloseDeleteCardholderInformation}
				/>
				<ExtendedSnackbar
					id="srtBookingBillingSummarySnackBar"
					open={alertText !== ''}
					message={alertText}
					onClose={this.handleSnackBarClose}
				/>
			</StyledDivContentContainer>
		);
	}
}

OrderPassengers.propTypes = {
	booking: PropTypes.object.isRequired,
	handleClosePassengerInfoDialog: PropTypes.func.isRequired,
	handleDialogSnackbarError: PropTypes.func.isRequired,
	handleOpenPassengerInfoDialog: PropTypes.func.isRequired,
	isProcessingAction: PropTypes.bool.isRequired,
	openPaxInfoDialog: PropTypes.number.isRequired,
	passengers: PropTypes.array.isRequired,
	updateIsProcessingSubmit: PropTypes.func.isRequired,
};

export { OrderPassengers as OrderPassengersAlias };

export default OrderPassengers;
