import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import messages from './messages';
import styles from './styles';

const StyledOrderTitle = styled('b')({
	...styles.orderTitle,
});

const StyledOrderSubtitle = styled('span')({
	...styles.orderSubtitle,
});

const StyledFraudDetected = styled('span')({
	...styles.fraudDetected,
});

const OrderTitle = ({
	order: {
		BookingOrderNumber,
		BookingOrderStatus,
		BookingOrderDetailsHoldExpiration,
		BookingOrderCancelInfo: {
			BookingOrderCancelInfoCanceledDate,
			BookingOrderCancelInfoCancellationReason,
			BookingOrderInternalCancellationReason,
			BookingOrderInternalCancellationDelayedRefundAvailableAtDesc,
		},
	},
	intl: { formatMessage },
}) => {
	const bookingCancelStatus = {
		CANCELED_WITH_FRAUD_PENALTY: {
			message: messages.CANCELED_WITH_FRAUD_PENALTY,
		},
		DELAY_CANCEL: {
			message: {
				...messages.DELAY_CANCEL,
				values: {
					refundAvailable:
						BookingOrderInternalCancellationDelayedRefundAvailableAtDesc,
				},
			},
		},
		CANCELED: {
			message: messages.CANCELED_FRAUD_NOT_FOUND,
		},
		RELEASE_SEAT: {
			message: messages.RELEASE_SEAT,
		},
	}[BookingOrderInternalCancellationReason];
	const bookingCancelInfoStatus = {
		VOID_EXCEPTION: {
			message: messages.VOID_EXCEPTION,
		},
	}[BookingOrderCancelInfoCancellationReason];

	const getInternalCancellationReasonTitleMessage = () => {
		let returnMessage;
		if (
			['RELEASED', 'ABANDONED', 'CANCELED', 'REPLACED', 'WITHDRAWN'].includes(
				BookingOrderStatus,
			) &&
			BookingOrderInternalCancellationReason === 'RELEASE_SEAT'
		) {
			returnMessage = <FormattedMessage {...messages.RELEASE_SEAT_NORMAL} />;
		} else if (
			BookingOrderStatus === 'RETURNED' &&
			BookingOrderInternalCancellationReason === 'RELEASE_SEAT'
		) {
			returnMessage = <FormattedMessage {...messages.RELEASE_SEAT_RETURNED} />;
		} else if (BookingOrderInternalCancellationReason === 'RELEASE_SEAT') {
			returnMessage = <FormattedMessage {...messages.RELEASE_SEAT} />;
		} else {
			returnMessage = <FormattedMessage {...bookingCancelStatus.message} />;
		}
		return returnMessage;
	};
	return (
		<>
			<StyledOrderTitle>
				<FormattedMessage
					{...messages.lblOrderNumber}
					values={{ index: BookingOrderNumber }}
				/>
			</StyledOrderTitle>
			<StyledOrderSubtitle>
				<FormattedMessage {...messages[BookingOrderStatus]} />
				{BookingOrderStatus === 'BOOKED' && (
					<>
						{' '}
						<FormattedMessage
							{...messages.lblOrderConfirmBy}
							values={{
								date: BookingOrderDetailsHoldExpiration.replace('&nbsp;', ' '),
							}}
						/>
					</>
				)}
				{!!BookingOrderCancelInfoCanceledDate &&
					(!!bookingCancelInfoStatus ||
						!!BookingOrderCancelInfoCancellationReason) && (
						<>
							{' '}
							<FormattedMessage
								{...messages.lblCancellationReason}
								values={{
									reason: bookingCancelInfoStatus
										? formatMessage(bookingCancelInfoStatus.message)
										: BookingOrderCancelInfoCancellationReason,
									date: BookingOrderCancelInfoCanceledDate,
								}}
							/>
						</>
					)}
				{bookingCancelStatus && (
					<>
						{': '}
						<StyledFraudDetected>
							{getInternalCancellationReasonTitleMessage()}
						</StyledFraudDetected>
					</>
				)}
			</StyledOrderSubtitle>
		</>
	);
};

OrderTitle.propTypes = {
	order: PropTypes.object.isRequired,
	intl: PropTypes.object,
};

export { OrderTitle as OrderTitleAlias };

export default injectIntl(OrderTitle);
