import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import '../../ManageBooking/components/BookingView/styleBookingView.css';
import { decodeHtml } from '../../../utils';

import messages from './messagesNotes';
import NotesTable from './NotesTable';

const BookingNotesTable = (props) => {
	const { id, notes, isDialog, dateFormat } = props;
	notes.sort((a, b) => a.BookingNoteDateTimeT - b.BookingNoteDateTimeT);

	const tableHeaders = (
		<TableRow>
			<TableCell>
				<FormattedMessage {...messages.lblDate} />
			</TableCell>
			<TableCell>
				<FormattedMessage {...messages.lblAgentId} />
			</TableCell>
			{isDialog && (
				<TableCell>
					<FormattedMessage {...messages.lblNoteType} />
				</TableCell>
			)}
			<TableCell>
				<FormattedMessage {...messages.lblNotes} />
			</TableCell>
		</TableRow>
	);

	const tableRows = notes.map((note, index) => {
		let tableRow = 'bookingTableRow';
		// highlight note if it was added in the last 2 days
		const noteDate = new Date(Number(note.BookingNoteDateTimeT));
		const hourDiff = Math.abs(new Date() - noteDate) / 36e5;
		if (hourDiff < 48) {
			tableRow += ' bookingTableRowRecent';
		}

		const message = decodeHtml(note.BookingNoteMsg);

		return (
			<TableRow key={`note_${index}`}>
				<TableCell id={`${id}_date_${index}`} styleName={tableRow}>
					<FormattedDate value={noteDate} {...dateFormat} />
				</TableCell>
				<TableCell id={`${id}_agent_${index}`} styleName={tableRow}>
					{note.BookingNoteAgent}
				</TableCell>
				{isDialog && (
					<TableCell
						id={`${id}_type_${index}`}
						styleName={tableRow}
						data-testid="booking-note-row"
					>
						{note.BookingNoteType === 'AGENT_NOTE' ? (
							<FormattedMessage {...messages.lblAgentNoteType} />
						) : (
							note.BookingNoteType
						)}
					</TableCell>
				)}
				<TableCell id={`${id}_msg_${index}`} styleName={tableRow}>
					{message.indexOf('[SA]') ? message : <i>{message}</i>}
				</TableCell>
			</TableRow>
		);
	});

	return <NotesTable tableHeaders={tableHeaders} tableRows={tableRows} />;
};

BookingNotesTable.propTypes = {
	id: PropTypes.string,
	notes: PropTypes.array.isRequired,
	isDialog: PropTypes.bool,
	dateFormat: PropTypes.object,
};

BookingNotesTable.defaultProps = {
	id: 'srtBookingNotesTable',
	dateFormat: {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour12: false,
		hour: 'numeric',
		minute: 'numeric',
		timeZoneName: 'short',
		timeZone: 'UTC',
	},
};

export default BookingNotesTable;
