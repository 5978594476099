import React from 'react';
import PropTypes from 'prop-types';

import './fare.css';
import { formatPrice } from '../../../utils';
import messages from './messagesFare';

const MixedFares = ({ currency, fareCode, index, mixedFaresObj, intl }) => {
	const formattedFarePrice = formatPrice(fareCode.farePrice, currency, intl);
	const fareText = intl.formatMessage(messages.lblFare);
	const mixedFarePrice = (
		<div styleName="fareCodePrice">{`${fareText} ${index + 1}: ${formattedFarePrice}`}</div>
	);
	const mixedFareType = !mixedFaresObj.sameFareTypeClass ? (
		<div>{fareCode.fareTypeClass}</div>
	) : null;
	const mixedPrimaryColumn = !mixedFaresObj.samePrimaryColumnClass ? (
		<div>{fareCode.primaryColumnClass}</div>
	) : null;
	const mixedSchedule = !mixedFaresObj.sameScheduleClass ? (
		<div>{fareCode.scheduleClass}</div>
	) : null;

	return (
		<div styleName="fareCodes">
			{mixedFarePrice}
			{mixedFareType}
			{mixedPrimaryColumn}
			{mixedSchedule}
		</div>
	);
};

MixedFares.propTypes = {
	currency: PropTypes.string,
	fareCode: PropTypes.shape({
		farePrice: PropTypes.number,
		fareClass: PropTypes.string,
		fareClassDesc: PropTypes.string,
		cabinClass: PropTypes.string,
		serviceClass: PropTypes.string,
		primaryColumnClass: PropTypes.string,
		openReturn: PropTypes.bool,
		reservableCode: PropTypes.string,
		reservableDesc: PropTypes.string,
		applicabilityCode: PropTypes.string,
		applicabilityDesc: PropTypes.string,
		scheduleClass: PropTypes.string,
		fareTypeClass: PropTypes.string,
		expirationDate: PropTypes.string,
	}),
	index: PropTypes.number,
	mixedFaresObj: PropTypes.shape({
		sameFareTypeClass: PropTypes.bool,
		samePrimaryColumnClass: PropTypes.bool,
		sameScheduleClass: PropTypes.bool,
		isMixedFares: PropTypes.bool,
	}),
	intl: PropTypes.object,
};

export default MixedFares;
