import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';

import messages from './messagesIssueRefund';
import inlineStyles from './styles';

const StyledFixHeightDiv = styled('div')({
	...inlineStyles.fixHeight,
});

const StyledTextBoldDiv = styled('div')({
	...inlineStyles.textBold,
});

const StyledTextBoldRightDiv = styled(StyledTextBoldDiv)({
	...inlineStyles.textRight,
});

const RefundAmountFields = ({
	id,
	amountReturned,
	currency,
	disabled,
	onUpdateRefundAmount,
}) => (
	<StyledFixHeightDiv className="row">
		<StyledTextBoldDiv className="col-5">
			<FormattedMessage {...messages.lblRefundAmount} />
		</StyledTextBoldDiv>
		<StyledTextBoldRightDiv className="col-1" data-testid="currency">
			{currency}
		</StyledTextBoldRightDiv>
		<div className="col-6">
			<TextField
				id={`${id}_RefundAmount`}
				name="refundAmount"
				fullWidth
				defaultValue={parseFloat(amountReturned).toFixed(2)}
				onChange={(event) => onUpdateRefundAmount(event.target.value)}
				disabled={disabled}
				variant="standard"
			/>
		</div>
	</StyledFixHeightDiv>
);

RefundAmountFields.propTypes = {
	id: PropTypes.string.isRequired,
	amountReturned: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	currency: PropTypes.string,
	disabled: PropTypes.bool,
	onUpdateRefundAmount: PropTypes.func,
};

export default RefundAmountFields;
