import validate from 'validate.js';

export const discountOrLoyaltyList = (itemList, paxId, aggregatedList) => {
	if (itemList) {
		itemList.forEach((item) => {
			if (item.get('ShoppingLegFareDiscountLinkedPassenger') === paxId) {
				const alreadyAdded = aggregatedList.find(
					(aggregatedItem) =>
						aggregatedItem.get('ShoppingLegFareDiscountProgram') ===
						item.get('ShoppingLegFareDiscountProgram'),
				);

				if (!alreadyAdded) {
					aggregatedList.push(item);
				}
			}
		});
	}
	return aggregatedList;
};

export const getDiscountAndLoyalty = (legFares, paxId) => {
	// build an Array of discounts or loyalties for the specified pax
	const aggregatedDiscounts = [];
	const aggregatedLoyalties = [];
	// look for entries with paxnum and add to the array
	legFares.forEach((item) => {
		const hasDiscount = item.get(0).has('ShoppingLegFareDiscountsInfo');
		const hasLoyalty = item.get(0).has('ShoppingLegFareLoyalityInfo');
		const discountArray = hasDiscount
			? discountOrLoyaltyList(
					item.get(0).get('ShoppingLegFareDiscountsInfo'),
					paxId,
					aggregatedDiscounts,
				)
			: [];
		const loyaltiesArray = hasLoyalty
			? discountOrLoyaltyList(
					item.get(0).get('ShoppingLegFareLoyalityInfo'),
					paxId,
					aggregatedLoyalties,
				)
			: [];
		aggregatedDiscounts.concat(discountArray);
		aggregatedLoyalties.concat(loyaltiesArray);
	});

	return {
		aggregatedDiscounts,
		aggregatedLoyalties,
	};
};

export const seatOrBike = (isSeatReservation) =>
	isSeatReservation ? 'Seat' : 'Bike';

export const filterObject = (obj, filter, filterValue) => {
	if (!obj) {
		return obj;
	}
	return Object.keys(obj).reduce(
		(acc, val) =>
			obj[val][filter] === filterValue
				? acc
				: {
						...acc,
						[val]: obj[val],
					},
		{},
	);
};

export const setDiscountsLoyalties = (passenger, index, faresSelected) => {
	const legSolutions = faresSelected.map((item) =>
		item.getIn(['leg', 'ShoppingLegSolutions']),
	);
	const legFares = legSolutions.map((item) =>
		item.get(0).get('ShoppingLegFares'),
	);
	const discountsAndLoyalties = legFares
		? getDiscountAndLoyalty(legFares, passenger.ShoppingPassengerRefId)
		: { aggregatedDiscounts: [], aggregatedLoyalties: [] };
	const updates = {
		aggregatedDiscounts: discountsAndLoyalties.aggregatedDiscounts,
		aggregatedLoyalties: discountsAndLoyalties.aggregatedLoyalties,
		discountIdentifier: '',
		discountAuthorization: '',
		corporateIdentifier:
			discountsAndLoyalties.aggregatedDiscounts[0]?.get(
				'ShoppingLegFareDiscountIdentifier',
			) ?? '',
		corporateAuthorization: '',
		loyaltyProgram: null,
		loyaltyIdentifier: '',
	};
	return updates;
};

export const extractPlusBusSupplements = (plusBusItems) => {
	let plusBusSupplements = [];
	if (plusBusItems && plusBusItems.size > 0) {
		plusBusSupplements = plusBusItems?.toJS();
	}
	return plusBusSupplements;
};

export const pushPlusBusSupplementsPerFare = (plusBusSupplements, fare) => {
	const localServiceFares = fare;
	if (localServiceFares.size) {
		const getIds = (item) => item.map((card) => card.get('id')).join(':');
		plusBusSupplements.push(
			localServiceFares
				// .groupBy((item) => item.getIn(['plusBusSupplementsConsumptionRules', 0]))
				.map((item) => ({
					...item.first().toJS(),
					ID: getIds(item),
				}))
				.toArray(),
		);
	}
};

export const pushTravelCardsPerFare = (travelCards, fare) => {
	const localServiceFares = fare
		.getIn([
			'leg',
			'ShoppingLegSolutions',
			0,
			'ShoppingLegFares',
			0,
			'OptionalPrices',
		])
		.filter((item) => item.get('type') === 'LOCAL_SERVICE');
	if (localServiceFares.size) {
		const getIds = (item) => item.map((card) => card.get('ID')).join(':');
		travelCards.push(
			localServiceFares
				.groupBy((item) => item.getIn(['travelCardConsumptionRules', 0]))
				.map((item) => ({ ...item.first().toJS(), ID: getIds(item) }))
				.toArray(),
		);
	}
};

export const extractTravelCards = (faresSelected) => {
	const travelCards = [];
	if (faresSelected && faresSelected.size > 0) {
		if (
			faresSelected.getIn([1, 'fare', 'price']) === 0 &&
			faresSelected.getIn([1, 'fare', 'optionalPrices']).size === 0
		) {
			// fare selections of type "Return" have the optional prices for the second leg within the first fare
			const fare = faresSelected.get(0);
			pushTravelCardsPerFare(travelCards, fare);
		} else {
			faresSelected.map((fare) =>
				pushTravelCardsPerFare(travelCards, fare, true),
			);
		}
	}
	return travelCards;
};

export const groupOnBoardServicesBySegment = (onBoardServices) => {
	let services = onBoardServices;
	if (!services.isEmpty()) {
		services = services
			.groupBy((itemTS) => itemTS.getIn(['linkedTravelSegments', 0]))
			.map((itemPS) =>
				itemPS
					.groupBy((itemBS) => itemBS.getIn(['linkedPassengers', 0]))
					.map((itemOS) =>
						itemOS.groupBy((itemMI) => itemMI.get('onBoardServiceCategory')),
					),
			);
	}

	return services;
};

export const extractOnBoardServices = (faresSelected) => {
	// get optional price for onboard services and group them by linked segment and passenger
	let hasServices = false;
	const onBoardServicesByTravelSegment = faresSelected.map((item) => {
		const filteredItems = item
			.getIn([
				'leg',
				'ShoppingLegSolutions',
				0,
				'ShoppingLegFares',
				0,
				'OptionalPrices',
			])
			.filter((itemOP) => itemOP.get('type') === 'ONBOARD_SERVICES');

		hasServices = hasServices || filteredItems.size > 0;
		return groupOnBoardServicesBySegment(filteredItems);
	});

	if (hasServices) {
		return onBoardServicesByTravelSegment.toJS();
	}

	return null;
};

export const computeRequiredPassengerFields = (selectedFares) => {
	const requiredFieldsForPrimaryPassenger = {
		PASSENGER_NAME: true,
		PASSENGER_EMAIL: true,
		PASSENGER_PHONE: true,
	};
	const requiredFieldsForAllPassengers = {};

	selectedFares.forEach((fare) => {
		fare.getIn(['leg', 'ShoppingLegSolutions']).forEach((leg) => {
			const passengerRequiredInformaton = leg.getIn([
				'PassengerRequiredInformation',
			]);

			if (passengerRequiredInformaton) {
				passengerRequiredInformaton.forEach((requiredField) => {
					const field = requiredField.getIn(['Type']);

					requiredFieldsForPrimaryPassenger[field] =
						requiredFieldsForPrimaryPassenger[field] || true;
					requiredFieldsForAllPassengers[field] =
						requiredFieldsForAllPassengers[field] ||
						requiredField.getIn(['RequiredForAllPassengers']);
				});
			}
		});
	});

	return {
		requiredFieldsForPrimaryPassenger,
		requiredFieldsForAllPassengers,
	};
};

export const updateTdoObj = (
	tdoData,
	tdoSmsCountry,
	tdoSmsNumber,
	tdoPaperEmail,
	tdoRegion,
	tdoMailName,
	tdoMailAddress,
	tdoSmartCards,
	tdoPickUpLocation,
) => ({
	tdoCode: tdoData.BookingTDOCode,
	tdoDesc: tdoData.BookingTDODesc,
	tdoFee: parseFloat(tdoData.BookingTDOFee),
	tdoCurrency: tdoData.BookingTDOFeeCurr,
	tdoSmsCountry,
	tdoSmsNumber,
	tdoPaperEmail,
	tdoRegion,
	tdoMailName,
	tdoMailAddress,
	tdoSmartCards,
	tdoPickUpLocation,
});

export const validatePassengerField = (
	passenger,
	constraints,
	isPrimaryPassenger,
	isFieldRequiredForPrimaryPassenger,
	isFieldRequiredForAllPassengers,
) => {
	let errors = {};

	if (
		(isPrimaryPassenger && isFieldRequiredForPrimaryPassenger) ||
		(!isPrimaryPassenger && isFieldRequiredForAllPassengers)
	) {
		errors = validate(passenger, constraints);
	}

	return errors;
};

export const transformSuppliers = (suppliersTemp) => {
	let suppliers = suppliersTemp;
	const supplierType = typeof suppliers;
	if (supplierType === 'string') {
		suppliers = [{ BookingSupplierCode: suppliers }];
	} else if (supplierType === 'object' && suppliers.length) {
		suppliers = suppliers.map((item) => ({ BookingSupplierCode: item }));
	}
	return suppliers;
};
