/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	lblCancelTitle: {
		id: 'app.shopping.manageBooking.cancelOrder.title',
		defaultMessage: 'Cancel Order',
	},
	lblCancelItem: {
		id: 'app.shopping.manageBooking.cancelOrder.cancelItem',
		defaultMessage: 'Cancel Item?',
	},
	lblItemsToCancel: {
		id: 'app.shopping.manageBooking.cancelOrder.itemsToCancel',
		defaultMessage: 'Items To Cancel',
	},
	lblAmount: {
		id: 'app.shopping.manageBooking.cancelOrder.amount',
		defaultMessage: 'Amount',
	},
	lblNonCancelableItems: {
		id: 'app.shopping.manageBooking.cancelOrder.nonCancelableItems',
		defaultMessage: 'Non-Cancelable Items',
	},
	lblProductSale: {
		id: 'app.shopping.manageBooking.cancelOrder.productSale',
		defaultMessage: 'Product Sale',
	},
	lblTicketingOption: {
		id: 'app.shopping.manageBooking.cancelOrder.ticketingOption',
		defaultMessage: 'Ticketing Option',
	},
	lblTransactionFee: {
		id: 'app.shopping.manageBooking.cancelOrder.transactionFee',
		defaultMessage: 'Transaction Fee',
	},
	lblPenaltyType_TICKET: {
		id: 'app.shopping.manageBooking.cancelOrder.penaltyTypeTICKET',
		defaultMessage: 'Ticket',
	},
	lblPenaltyType_TICKETED_RES: {
		id: 'app.shopping.manageBooking.cancelOrder.penaltyTypeTICKETED_RES',
		defaultMessage: 'Ticketed and seat reservation',
	},
	lblPenaltyType_RESERVATION: {
		id: 'app.shopping.manageBooking.cancelOrder.penaltyTypeRESERVATION',
		defaultMessage: 'Reservation',
	},
	lblPenaltyType_FEE: {
		id: 'app.shopping.manageBooking.cancelOrder.penaltyTypeFEE',
		defaultMessage: 'Fee',
	},
	lblPenaltyType_TRAVEL_PASS: {
		id: 'app.shopping.manageBooking.cancelOrder.penaltyTypeTRAVEL_PASS',
		defaultMessage: 'Travel pass',
	},
	lblTotal: {
		id: 'app.shopping.manageBooking.cancelOrder.total',
		defaultMessage: 'Total',
	},
	lblResultingBalance: {
		id: 'app.shopping.manageBooking.cancelOrder.resultingBalance',
		defaultMessage: 'Resulting Balance',
	},
	lblAddAdditionalPenalty: {
		id: 'app.shopping.manageBooking.cancelOrder.addAdditionalPenalty',
		defaultMessage: 'Add Additional Penalty',
	},
	lblAdditionalPenaltyNotAllowed: {
		id: 'app.shopping.manageBooking.cancelOrder.additionalPenaltyNotAllowed',
		defaultMessage: 'Additional penalty not allowed',
	},
	lblCancelRefundAutoProcess: {
		id: 'app.shopping.manageBooking.cancelOrder.refundAutoProcess',
		defaultMessage:
			'Order successfully cancelled. Refund will be automatically processed',
	},
	lblCancelRefundManualProcess: {
		id: 'app.shopping.manageBooking.cancelOrder.refundManualProcess',
		defaultMessage:
			'Order successfully cancelled. Please enter a refund manually',
	},
	lblOrderSuccessCanceled: {
		id: 'app.shopping.manageBooking.cancelOrder.orderSuccessCanceled',
		defaultMessage: 'Order successfully cancelled',
	},
	lblCancelRefundAutoProcessPartial: {
		id: 'app.shopping.manageBooking.cancelOrder.refundAutoProcessPartial',
		defaultMessage:
			'Order partially cancelled. Refund will be automatically processed',
	},
	lblCancelRefundManualProcessPartial: {
		id: 'app.shopping.manageBooking.cancelOrder.refundManualProcessPartial',
		defaultMessage: 'Order partially cancelled. Please enter a refund manually',
	},
	lblOrderSuccessCanceledPartial: {
		id: 'app.shopping.manageBooking.cancelOrder.orderSuccessCanceledPartial',
		defaultMessage: 'Order partially cancelled',
	},
	btnClose: {
		id: 'app.shopping.manageBooking.cancelOrder.close',
		defaultMessage: 'Close',
	},
	btnCancelSubmit: {
		id: 'app.shopping.manageBooking.cancelOrder.submit',
		defaultMessage: 'Request Cancellation',
	},
	btnViewCancelPenalty: {
		id: 'app.shopping.manageBooking.cancelOrder.viewCancelPenalty',
		defaultMessage: 'View Cancellation Penalty',
	},
	lblCancellationPenaltyTitle: {
		id: 'app.shopping.manageBooking.cancelOrder.cancellationPenaltyTitle',
		defaultMessage: 'Cancellation Penalty',
	},
	lblCancellationPenaltyDisruptionContent: {
		id: 'app.shopping.manageBooking.cancelOrder.cancellationPenaltyDisruptionContent',
		defaultMessage: `Cancelling due to service disruption is at the discretion of the carrier(s). 
        If allowed, the expected cancellation penalty is 0.00`,
	},
	lblCancellationPenaltyAllowedContent: {
		id: 'app.shopping.manageBooking.cancelOrder.cancellationPenaltyAllowedContent',
		defaultMessage: `Cancellation of the selected ticket(s) is allowed. 
        Expected cancellation penalty is`,
	},
	lblCanceledStatusMessage: {
		id: 'app.shopping.manageBooking.cancelOrder.canceledStatusMessage',
		defaultMessage:
			"The refund is delayed but the PNR's passengers seats are now available for resale",
	},
	lblCanceledMessage: {
		id: 'app.shopping.manageBooking.cancelOrder.canceledMessage',
		defaultMessage: 'The order was cancelled.',
	},
	errAdditionalPenaltyEmpty: {
		id: 'app.shopping.manageBooking.cancelOrder.additionalPenaltyEmpty',
		defaultMessage: '^Please enter a value in the Additional Penalty field',
	},
	errAdditionalPenaltyFormat: {
		id: 'app.shopping.manageBooking.cancelOrder.additionalPenaltyFormat',
		defaultMessage:
			'^Unexpected format encountered. Expected format is nn.nn, for example: 9.99',
	},
	errAmountGreaterRevenues: {
		id: 'app.shopping.manageBooking.cancelOrder.amountGreaterRevenues',
		defaultMessage: '^Fee amount must be greater than or equal to -',
	},
	lblCancelInstructionsAll: {
		id: 'app.shopping.manageBooking.cancelOrder.instructionsAll',
		defaultMessage: 'Full order cancellation: all tickets will be cancelled.',
	},
	lblCancelInstructionsPartDisabled: {
		id: 'app.shopping.manageBooking.cancelOrder.instructionsPartDisabled',
		defaultMessage:
			'Note: one or more tickets on this order cannot be cancelled.',
	},
	lblVoidInstructionsAll: {
		id: 'app.shopping.manageBooking.voidOrder.instructionsAll',
		defaultMessage: 'Full order void: all tickets will be voided.',
	},
	lblVoidInstructionsPart: {
		id: 'app.shopping.manageBooking.voidOrder.instructionsPart',
		defaultMessage: 'Partial order void: selected ticket will be voided.',
	},
	lblVoidInstructionsPartDisabled: {
		id: 'app.shopping.manageBooking.voidOrder.instructionsPartDisabled',
		defaultMessage: 'Note: one or more tickets on this order cannot be voided.',
	},
	lblNotes: {
		id: 'app.shopping.manageBooking.cancelOrder.notes',
		defaultMessage:
			'In addition to cancellations due to normal circumstances, cancellation may be possible due to the following exceptional reasons.',
	},
	lblReleaseInventoryOnly: {
		id: 'app.shopping.manageBooking.cancelOrder.releaseInventoryOnly',
		defaultMessage:
			'Release seats only (Use if releasing seat without immediate reimbursement. Reimbursement process can start once customer returns coupons/tickets)',
	},
	lblCannotRelease: {
		id: 'app.shopping.manageBooking.cancelOrder.cannotRelease',
		defaultMessage: 'Seats cannot be released',
	},
	lblCancelDueToStrike: {
		id: 'app.shopping.manageBooking.allPartModeSelect.cancelDueToStrike',
		defaultMessage: 'Cancel due to STRIKE',
	},
	lblCancelAllDisabled: {
		id: 'app.shopping.manageBooking.cancelOrder.allDisabled',
		defaultMessage: 'Full order cancellation is not available for this order.',
	},
	lblVoidAllDisabled: {
		id: 'app.shopping.manageBooking.voidOrder.allDisabled',
		defaultMessage: 'Full void is not available for this order.',
	},
	lblCancelPartDisabled: {
		id: 'app.shopping.manageBooking.cancelOrder.partDisabled',
		defaultMessage: 'Partial cancellation is not available for this order.',
	},
	lblVoidPartDisabled: {
		id: 'app.shopping.manageBooking.voidOrder.partDisabled',
		defaultMessage: 'Partial void is not available for this order.',
	},
	lblCancelBothDisabled: {
		id: 'app.shopping.manageBooking.cancelOrder.bothDisabled',
		defaultMessage: 'Order cannot be fully o partially canceled.',
	},
	lblVoidBothDisabled: {
		id: 'app.shopping.manageBooking.voidOrder.bothDisabled',
		defaultMessage: 'Order cannot be fully o partially voided.',
	},
	lblVoidTitle: {
		id: 'app.shopping.manageBooking.voidOrder.title',
		defaultMessage: 'Void Order',
	},
	lblVoidAllowedUntil: {
		id: 'app.shopping.manageBooking.voidOrder.voidAllowedUntil',
		defaultMessage: 'Allowed Until',
	},
	lblVoidItem: {
		id: 'app.shopping.manageBooking.voidOrder.voidItem',
		defaultMessage: 'Void Item?',
	},
	lblItemsToVoid: {
		id: 'app.shopping.manageBooking.voidOrder.itemsToVoid',
		defaultMessage: 'Items To Void',
	},
	lblNonVoidableItems: {
		id: 'app.shopping.manageBooking.voidOrder.nonVoidableItems',
		defaultMessage: 'Non-Voidable Items',
	},
	lblVoidRefundAutoProcess: {
		id: 'app.shopping.manageBooking.voidOrder.refundAutoProcess',
		defaultMessage:
			'Order successfully voided. Refund will be automatically processed',
	},
	lblVoidRefundManualProcess: {
		id: 'app.shopping.manageBooking.voidOrder.refundManualProcess',
		defaultMessage: 'Order successfully voided. Please enter a refund manually',
	},
	lblOrderSuccessVoided: {
		id: 'app.shopping.manageBooking.voidOrder.orderSuccessVoided',
		defaultMessage: 'Order successfully voided',
	},
	lblVoidRefundAutoProcessPartial: {
		id: 'app.shopping.manageBooking.voidOrder.refundAutoProcessPartial',
		defaultMessage:
			'Order partially voided. Refund will be automatically processed',
	},
	lblVoidRefundManualProcessPartial: {
		id: 'app.shopping.manageBooking.voidOrder.refundManualProcessPartial',
		defaultMessage: 'Order partially voided. Please enter a refund manually',
	},
	lblOrderSuccessVoidedPartial: {
		id: 'app.shopping.manageBooking.voidOrder.orderSuccessVoidedPartial',
		defaultMessage: 'Order partially voided',
	},
	btnVoidSubmit: {
		id: 'app.shopping.manageBooking.voidOrder.submit',
		defaultMessage: 'Request Void',
	},
	btnSubmitExpirationError: {
		id: 'app.shopping.manageBooking.voidOrder.submitExpirationError',
		defaultMessage:
			'The order can no longer be voided as the expiration date/time has passed.',
	},
});
