import { defineMessages } from 'react-intl';

export default defineMessages({
	lblNotes: {
		id: 'app.shopping.manageBooking.notes.notes',
		defaultMessage: 'Notes',
	},
	lblNote: {
		id: 'app.shopping.manageBooking.notes.note',
		defaultMessage: 'Note',
	},
	lblSupplierNotes: {
		id: 'app.shopping.manageBooking.notes.supplierNotes',
		defaultMessage: 'Supplier Notes',
	},
	lblDate: {
		id: 'app.shopping.manageBooking.notes.date',
		defaultMessage: 'Date',
	},
	lblAgentId: {
		id: 'app.shopping.manageBooking.notes.agentId',
		defaultMessage: 'Agent ID',
	},
	lblNoteType: {
		id: 'app.shopping.manageBooking.notes.noteType',
		defaultMessage: 'Note Type',
	},
	lblFilter: {
		id: 'app.shopping.manageBooking.notes.filter',
		defaultMessage: 'Filter',
	},
	lblAddNote: {
		id: 'app.shopping.manageBooking.notes.addNote',
		defaultMessage: 'Add Note',
	},
	lblClose: {
		id: 'app.shopping.manageBooking.notes.close',
		defaultMessage: 'Close',
	},
	lblSubmit: {
		id: 'app.shopping.manageBooking.notes.submitNote',
		defaultMessage: 'Submit Note',
	},
	errNoteTypeEmpty: {
		id: 'app.shopping.manageBooking.notes.noteTypeEmpty',
		defaultMessage: '^Please select a note type',
	},
	errNoteEmpty: {
		id: 'app.shopping.manageBooking.notes.noteEmpty',
		defaultMessage: '^Please enter a note',
	},
	errNoteTooLong: {
		id: 'app.shopping.manageBooking.notes.noteTooLong',
		defaultMessage: '^Note cannot be more than 1500 characters long',
	},
	errSupplierNoteTooLong: {
		id: 'app.shopping.manageBooking.notes.supplierNoteTooLong',
		defaultMessage: '^Note cannot be more than 50 characters long',
	},
	okNoteAdded: {
		id: 'app.shopping.manageBooking.notes.noteAdded',
		defaultMessage: 'Success: Note added to booking',
	},
	okSupplierNoteAdded: {
		id: 'app.shopping.manageBooking.notes.supplierNoteAdded',
		defaultMessage: 'Success: Supplier Note added to order',
	},
	lblAgentNoteType: {
		id: 'app.shopping.manageBooking.notes.agentNoteType',
		defaultMessage: 'Agent Note',
	},
});
