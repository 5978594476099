import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.createBooking.travelCards.title',
		defaultMessage: 'Select a London All Day Travelcard option',
	},
	lblSubTitle: {
		id: 'app.shopping.createBooking.travelCards.subTitle',
		defaultMessage: 'A Travelcard for each passenger is included in the price',
	},
	lblZoneT2: {
		id: 'app.shopping.createBooking.travelCards.zoneT2',
		defaultMessage: 'Zone 1&2 (Zone R12*)',
	},
	lblZoneT4: {
		id: 'app.shopping.createBooking.travelCards.zoneT4',
		defaultMessage: 'Zone 1-4 (Zone R1234*)',
	},
	lblZoneT6: {
		id: 'app.shopping.createBooking.travelCards.zoneT6',
		defaultMessage: 'Zone 1-6 (Zone R1256*)',
	},
	lblAnytimeDay: {
		id: 'app.shopping.createBooking.travelCards.anytimeDay',
		defaultMessage: 'Anytime Day',
	},
	lblOffpeakDay: {
		id: 'app.shopping.createBooking.travelCards.offpeakDay',
		defaultMessage: 'Offpeak Day',
	},
	btnDiscardChanges: {
		id: 'app.shopping.createBooking.travelCards.discardChanges',
		defaultMessage: 'Discard changes',
	},
	btnSaveTravelCard: {
		id: 'app.shopping.createBooking.travelCards.saveTravelCard',
		defaultMessage: 'Save Travelcard',
	},
	lblLeg: {
		id: 'app.shopping.createBooking.travelCards.leg',
		defaultMessage: 'Leg',
	},
	lblTo: {
		id: 'app.shopping.createBooking.travelCards.to',
		defaultMessage: 'to',
	},
});
