import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

// Import i18n messages
import translatedMessages from '../i18n';

export default connect((state) => {
	const langMap = { en_US: 'en-US', en_GB: 'en-GB', fr_FR: 'fr-FR' };
	const localeCountry = state.getIn([
		'settings',
		'ws.system.localization.language_country',
	]);
	let locale = '';

	locale = localeCountry && langMap[localeCountry];

	return { locale, messages: translatedMessages[locale] };
})(IntlProvider);
