import { defineMessages } from 'react-intl';

export default defineMessages({
	lblClose: {
		id: 'app.shopping.manageBooking.splitFare.close',
		defaultMessage: 'Close',
	},
	lblTitle: {
		id: 'app.shopping.manageBooking.splitFare.title',
		defaultMessage: 'Multiple Fares: Price Breakdown',
	},
	lblOrigin: {
		id: 'app.shopping.manageBooking.splitFare.origin',
		defaultMessage: 'Origin',
	},
	lblDestination: {
		id: 'app.shopping.manageBooking.splitFare.destination',
		defaultMessage: 'Destination',
	},
	lblFare: {
		id: 'app.shopping.manageBooking.splitFare.fare',
		defaultMessage: 'Fare',
	},
	lblPrice: {
		id: 'app.shopping.manageBooking.splitFare.price',
		defaultMessage: 'Price',
	},
});
