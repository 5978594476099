import React from 'react';
import PropTypes from 'prop-types';
// import MenuItem from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
import OverflowTooltipText from '../OverflowTooltipText/OverflowTooltipText';
import styles from './optionStyles';

export default function Option(props) {
	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				...styles.menuItem,
				...(props.isSelected ? styles.selected : {}),
				...(props.selectProps.shortRow ? styles.shortRow : {}),
			}}
			{...props.innerProps}
		>
			<OverflowTooltipText>{props.children}</OverflowTooltipText>
		</MenuItem>
	);
}

Option.propTypes = {
	innerRef: PropTypes.func,
	isFocused: PropTypes.bool,
	isSelected: PropTypes.bool,
	innerProps: PropTypes.object,
	children: PropTypes.node,
	selectProps: PropTypes.object,
};
