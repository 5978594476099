import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import FareLegsContainer from './Fare/FareLegsContainer';
import FareLeg from './Fare/FareLeg';

const FaresSelectedView = (props) => {
	const isReturnType =
		props.selectedFares.size > 1 &&
		props.selectedFares.getIn([0, 'fare', 'fareCodes', 0, 'fareTypeClass']) ===
			'Return';
	const selectedFares = isReturnType
		? fromJS([props.selectedFares])
		: props.selectedFares;
	return (
		<div id={props.id}>
			{selectedFares
				.map((item, index) => (
					<FareLegsContainer
						key={index}
						isReturnType={isReturnType}
						addOrderMode={props.addOrderMode}
						exchangeOrderMode={props.exchangeOrderMode}
						onChangeSelection={props.onChangeSelection}
						isCreateBookingPage={props.isCreateBookingPage}
					>
						<FareLeg
							key={index}
							fareIndex={index}
							isReturnType={isReturnType}
							outboundMsg={props.outboundMsg}
							inboundMsg={props.inboundMsg}
							departDateString={props.departDateString}
							returnDateString={props.returnDateString}
							leg={
								isReturnType
									? item.getIn([0, 'leg']).toJS()
									: item.get('leg').toJS()
							}
							legId={
								isReturnType
									? item.getIn([0, 'leg', 'ShoppingLegId'])
									: item.getIn(['leg', 'ShoppingLegId'])
							}
							booking
							selectedFare={!isReturnType && item.toJS()}
							selectedFares={isReturnType && item.toJS()}
							onChangeSelection={props.onChangeSelection}
							onSelection={() => {}}
							tripType={props.tripType}
							addOrderMode={props.addOrderMode}
							exchangeOrderMode={props.exchangeOrderMode}
							isCreateBookingPage={props.isCreateBookingPage}
						/>
					</FareLegsContainer>
				))
				.toJS()}
		</div>
	);
};

FaresSelectedView.propTypes = {
	selectedFares: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	departDateString: PropTypes.string,
	returnDateString: PropTypes.string,
	inboundMsg: PropTypes.string,
	outboundMsg: PropTypes.string,
	id: PropTypes.string,
	tripType: PropTypes.number,
	addOrderMode: PropTypes.bool,
	exchangeOrderMode: PropTypes.bool,
	onChangeSelection: PropTypes.func,
	isCreateBookingPage: PropTypes.bool,
};

export default FaresSelectedView;
