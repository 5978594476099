import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';

import ReactSelectWrapped from '../ReactSelectWrapped/ReactSelectWrapped';

import messages from './messagesTicketDelivery';
import inlineStyles from './styles';

function SmartCardPassengersInfo({
	errors,
	intl: { formatMessage },
	isFetching,
	onPickUpStationChange,
	onSmartCardNumberChange,
	passengers,
	smartCardStations,
	tdoPickUpStation,
	tdoSmartCardNumber,
}) {
	const stationsList =
		smartCardStations && smartCardStations.size > 0
			? smartCardStations.toJS()
			: [];
	const passengerLabels = passengers.map((passenger, index) => {
		let descriptionOrFullName = '';
		// display full name if present, otherwise age description of passenger
		const {
			firstName,
			lastName,
			ageDesc,
			BookingPaxFirstName,
			BookingPaxLastName,
		} = passenger.toJS();
		if (firstName && lastName) {
			descriptionOrFullName = `${firstName} ${lastName}`;
		} else if (ageDesc) {
			descriptionOrFullName = `Passenger #${index + 1} (${ageDesc})`;
		} else {
			descriptionOrFullName = `${BookingPaxFirstName} ${BookingPaxLastName}`;
		}
		return descriptionOrFullName;
	});

	return (
		<div>
			<div>
				<ReactSelectWrapped
					fullWidth
					id="srtSmartCardStations"
					value={tdoPickUpStation ? tdoPickUpStation.get('value') : ''}
					onChange={onPickUpStationChange}
					placeholder={<FormattedMessage {...messages.lblTDOSmartCardPickup} />}
					disabled={isFetching}
					error={!!errors.value}
					helperText={errors.value}
					options={stationsList.map((station) => ({
						value: station.StationSearchResultCode,
						label: station.StationSearchResultName,
					}))}
					fastFilter
					windowing
					required
					style={inlineStyles.smartCardStations}
				/>
			</div>
			<div style={inlineStyles.smartCardNumbersLabel}>
				<FormattedMessage {...messages.lblTDOSmartCardNumbers} />
			</div>
			<div>
				{passengers.map((passenger, index) => (
					<TextField
						key={index}
						id={`srtSmartCardNumber${index}`}
						placeholder={formatMessage(messages.lblTDOSmartCardNumber)}
						style={inlineStyles.passengersInputWidth}
						value={tdoSmartCardNumber.get(index) || ''}
						onChange={onSmartCardNumberChange}
						error={
							errors.passengers
								? !!errors.passengers[index].smartCardNumber
								: false
						}
						helperText={
							errors.passengers ? errors.passengers[index].smartCardNumber : ''
						}
						label={passengerLabels.get(index)}
						fullWidth
						InputLabelProps={{
							shrink: true,
							style: inlineStyles.passengersInputLabel,
						}}
						variant="standard"
					/>
				))}
			</div>
		</div>
	);
}

SmartCardPassengersInfo.propTypes = {
	errors: PropTypes.object,
	intl: PropTypes.object,
	isFetching: PropTypes.bool,
	onPickUpStationChange: PropTypes.func,
	onSmartCardNumberChange: PropTypes.func,
	passengers: PropTypes.object,
	smartCardStations: PropTypes.object,
	tdoPickUpStation: PropTypes.object,
	tdoSmartCardNumber: PropTypes.object,
};

export default injectIntl(SmartCardPassengersInfo);
