import { defineMessages } from 'react-intl';

export default defineMessages({
	lblPaymentCardNumber: {
		id: 'app.common.braintree.cardNumber',
		defaultMessage: 'Card Number',
	},
	lblPaymentSecurityCode: {
		id: 'app.common.braintree.securityCode',
		defaultMessage: 'Security Code',
	},
	lblPaymentExpiryDate: {
		id: 'app.common.braintree.expiryDate',
		defaultMessage: 'Expiry date',
	},
	errPaymentCardNumberEmpty: {
		id: 'app.common.braintree.paymentCardNumberEmpty',
		defaultMessage: 'Please enter a Card Number',
	},
	errPaymentCardNumberInvalid: {
		id: 'app.common.braintree.paymentCardNumberInvalid',
		defaultMessage: 'Please enter a valid Card Number',
	},
	errSecurityCode: {
		id: 'app.common.braintree.paymentSecurityCodeInvalid',
		defaultMessage: 'Please enter a valid Security Code',
	},
	errDateInvalid: {
		id: 'app.common.braintree.dateInvalid',
		defaultMessage: 'Please enter a valid Date',
	},
});
