import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { injectIntl } from 'react-intl';
import messages from './messagesTravelCards';
import TravelCardsView from './TravelCardsView';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
	...inlineStyles.dialogTitle,
}));

const StyledDialogContent = styled(DialogContent)(() => ({
	...inlineStyles.dialogContent,
}));

const TravelCardsDialog = ({
	open,
	faresSelected,
	travelCards,
	passengerNumber,
	intl: { formatMessage },
	selectedTravelCards,
	onChangeTravelCards,
	onDiscardTravelCards,
}) => {
	const [selectedTravelCardsState, setSelectedTravelCardsState] =
		React.useState(selectedTravelCards);

	useEffect(() => {
		setSelectedTravelCardsState(selectedTravelCards);
	}, [selectedTravelCards]);

	const handleCheckTravelCard = (
		travelCard,
		isChecked,
		linkedTravelSegment,
	) => {
		const cleanedSelectionList = selectedTravelCardsState.filter(
			(selectedTravelCard) =>
				selectedTravelCard.getIn(['linkedTravelSegments', 0]) !==
				linkedTravelSegment,
		);
		const updatedSelectedTravelCards = isChecked
			? cleanedSelectionList.push(fromJS(travelCard))
			: cleanedSelectionList;
		setSelectedTravelCardsState(updatedSelectedTravelCards);
	};

	const handleDiscard = () => {
		setSelectedTravelCardsState(selectedTravelCards);
		onDiscardTravelCards();
	};

	const handleSave = () => {
		onChangeTravelCards(selectedTravelCardsState);
	};

	const actions = (
		<div className="row" style={inlineStyles.dialogActionsRoot}>
			<div className="col-4 offset-3 offset-md-4">
				<Button
					variant="contained"
					id="srtTravelCardDiscardChanges"
					onClick={handleDiscard}
					fullWidth
				>
					{formatMessage(messages.btnDiscardChanges)}
				</Button>
			</div>
			<div className="col-5 col-md-4">
				<Button
					variant="contained"
					id="srtSaveTravelCard"
					onClick={handleSave}
					color="primary"
					fullWidth
				>
					{formatMessage(messages.btnSaveTravelCard)}
				</Button>
			</div>
		</div>
	);

	return (
		<StyledDialog
			open={open}
			onClose={handleDiscard}
			disableEnforceFocus
			maxWidth={false}
		>
			<StyledDialogTitle>{formatMessage(messages.lblTitle)}</StyledDialogTitle>
			<StyledDialogContent>
				<TravelCardsView
					onCheckTravelCard={handleCheckTravelCard}
					faresSelected={faresSelected}
					travelCards={travelCards}
					selectedTravelCards={selectedTravelCardsState}
					passengerNumber={passengerNumber}
				/>
			</StyledDialogContent>
			<DialogActions>{actions}</DialogActions>
		</StyledDialog>
	);
};

TravelCardsDialog.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool.isRequired,
	faresSelected: PropTypes.object.isRequired,
	travelCards: PropTypes.array.isRequired,
	onDiscardTravelCards: PropTypes.func.isRequired,
	onChangeTravelCards: PropTypes.func.isRequired,
	selectedTravelCards: PropTypes.object,
	passengerNumber: PropTypes.number,
};

export { TravelCardsDialog as TravelCardsDialogAlias };
export default injectIntl(TravelCardsDialog);
