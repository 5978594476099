import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.booking.bookingEmailConfirmationDialog.title',
		defaultMessage: 'Order Confirmation',
	},
	btnCancel: {
		id: 'app.booking.bookingEmailConfirmationDialog.cancel',
		defaultMessage: 'Close',
	},
	btnSubmit: {
		id: 'app.booking.bookingEmailConfirmationDialog.submit',
		defaultMessage: 'Send Confirmation Email',
	},
	errConfirmationEmail: {
		id: 'app.booking.bookingEmailConfirmationDialog.confirmationEmailEmpty',
		defaultMessage: '^Please enter a valid Email',
	},
	lblNotTicket: {
		id: 'app.booking.bookingEmailConfirmationDialog.notTicket',
		defaultMessage: '***** This is not a travel ticket *****',
	},
	lblSentToEmail: {
		id: 'app.booking.bookingEmailConfirmationDialog.sentToEmail',
		defaultMessage: 'Send an Order Confirmation Email to:',
	},
	lblEmailSentTo: {
		id: 'app.booking.bookingEmailConfirmationDialog.emailSentTo',
		defaultMessage: 'Sent booking confirmation to: {email}.',
	},
});
