import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import messages from './messagesExchangeOrder';
import styles from './styles';

function ExchangeOrderNotification({ cancelledTFPassengers, isConfirmation }) {
	return (
		<div className={styles.warning}>
			<FormattedMessage {...messages.lblCancelledTFFirst} />
			<List component="ul" sx={styles.warningList}>
				{cancelledTFPassengers &&
					cancelledTFPassengers.map((item, index) => (
						<ListItem className={styles.warningListItem} key={index}>
							<ListItemText
								disableTypography
								primary={
									<FormattedMessage
										{...messages.lblCancelledTFFor}
										values={{
											fare: item.get('fareDisplayName'),
											name: item.get('passengerNames').toJS().join(', '),
										}}
									/>
								}
							/>
						</ListItem>
					))}
			</List>
			{!isConfirmation && <FormattedMessage {...messages.lblCancelledTFLast} />}
		</div>
	);
}

ExchangeOrderNotification.propTypes = {
	cancelledTFPassengers: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]).isRequired,
	isConfirmation: PropTypes.bool,
};

export { ExchangeOrderNotification as ExchangeOrderNotificationAlias };

export default ExchangeOrderNotification;
