export const inputStyles = {
	root: {
		height: 19,
	},
};

export const inputBraintreeStyle = {
	input: {
		fontFamily: 'inherit',
		letterSpacing: 'inherit',
		border: '0px',
		boxSizing: 'content-box',
		background: 'none',
		height: '1.4375em',
		margin: '0px',
		display: 'block',
		minWidth: '0px',
		width: '100%',
		padding: '16.5px 14px',
	},
};
