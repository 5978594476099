import { white, defaultFareColorClassSchema } from '../../../colors';

import commonStyles from '../../../commonStyles';

export default {
	tableWrapper: commonStyles.tableWrapper,
	boldText: {
		fontWeight: 600,
		fontSize: 14,
	},
	description: {
		paddingLeft: 5,
	},
	tableHead: {
		whiteSpace: 'nowrap',
	},
	tableHeadCell: {
		backgroundColor: white,
		position: 'sticky',
		top: 0,
	},
	refreshIndicator: {
		zIndex: 2000,
		marginTop: '5%',
		marginLeft: '48%',
	},
	intermediateStepsButton: {
		marginLeft: 10,
	},
	serviceAlert: {
		color: defaultFareColorClassSchema.orange,
	},
};
