import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';

import Linkify from '../../../components/Linkify/Linkify';
import messages from './messages';
import revisionMessages from '../../ManageBooking/components/RevisionDialog/messages';
import styles from './styles';

const StyledAlertRowDiv = styled('div')({
	...styles.alertRow,
});

const StyledAlertHeadingPre = styled('pre')({
	...styles.alertHeading,
});

const StyledWarningIcon = styled(WarningIcon)({
	...styles.icon,
});

const StyledWarningDiv = styled('div')({
	...styles.warning,
});

const StyledWarningContent = styled('div')({
	...styles.content,
});

const StyledWarningSummary = styled('div')({
	...styles.summary,
});

const StyledWarningDescription = styled('div')({
	...styles.description,
});

const getRevisionMessage = (revision) => {
	if (
		revision?.code === '685' &&
		revision?.reason?.includes('delayed by overcrowding')
	) {
		return 'Delay';
	}
	return 'Cancel';
};
const getStatusMessage = (revision) => {
	if (revision?.status === 'DELAYED') {
		return 'Delayed';
	}
	return 'Cancelled';
};
const getMessage = (revision) =>
	revisionMessages[
		`lblRevision${revision?.code === '685' ? getRevisionMessage(revision) : ''}${revision?.code}`
	];

const ServiceAlerts = ({
	onClose,
	open,
	serviceAlerts,
	revisions,
	originStation,
	destinationStation,
	departTime,
	arriveTime,
}) => (
	<Dialog open={open} onClose={onClose}>
		<DialogContent>
			{serviceAlerts && (
				<StyledAlertRowDiv key="serviceAlertsSection" className="col-12">
					<StyledAlertHeadingPre>
						<FormattedMessage {...revisionMessages.lblServiceAlerts} />
					</StyledAlertHeadingPre>
					{serviceAlerts &&
						serviceAlerts.map((serviceAlert) => (
							<div key={serviceAlert.Summary}>
								<StyledWarningIcon />
								<StyledWarningContent>
									<StyledWarningSummary>
										{serviceAlert.Summary}
									</StyledWarningSummary>
									<StyledWarningDescription>
										<Linkify>{serviceAlert.Description}</Linkify>
									</StyledWarningDescription>
								</StyledWarningContent>
							</div>
						))}
				</StyledAlertRowDiv>
			)}
			{revisions && (
				<StyledAlertRowDiv key="revisionsSection" className="col-12">
					<StyledAlertHeadingPre>
						<FormattedMessage {...revisionMessages.lblRevisions} />
					</StyledAlertHeadingPre>
					{revisions &&
						revisions.map((revision, i) => (
							<div key={i}>
								<StyledWarningDiv>
									<StyledWarningIcon />
									<strong>
										<FormattedMessage
											{...revisionMessages[`lbl${getStatusMessage(revision)}`]}
										/>
									</strong>
								</StyledWarningDiv>
								<p>
									{getMessage(revision) ? (
										<>
											{`${revision?.code} : `}
											<FormattedMessage {...getMessage(revision)} />
										</>
									) : (
										revision?.reason
									)}
								</p>
								{revision?.revisedOriginTravelPoint ||
								revision?.revisedDepartureDateTime ? (
									<p>
										<div>
											<FormattedMessage
												{...revisionMessages.lblOriginModifications}
											/>
										</div>
										<div>
											<FormattedMessage
												{...revisionMessages.lblOriginalScheduledDepart}
											/>
											{` ${originStation}, ${departTime}`}
										</div>
										<div>
											<FormattedMessage {...revisionMessages.lblNowDepart} />
											{` ${revision?.revisedOriginTravelPoint || originStation}, ${revision?.revisedDepartureDateTime}`}
										</div>
									</p>
								) : (
									<p>
										<div>
											<FormattedMessage
												{...revisionMessages.lblOriginNoModifications}
											/>
										</div>
										<div>
											<FormattedMessage {...revisionMessages.lblDepart} />
											{` ${originStation}, ${departTime}`}
										</div>
									</p>
								)}
								{revision?.revisedDestinationTravelPoint ||
								revision?.revisedArrivalDateTime ? (
									<p>
										<div>
											<FormattedMessage
												{...revisionMessages.lblDestinationModifications}
											/>
										</div>
										<div>
											<FormattedMessage
												{...revisionMessages.lblOriginalArrive}
											/>
											{` ${destinationStation}, ${arriveTime}`}
										</div>
										<div>
											<FormattedMessage {...revisionMessages.lblNowArriving} />
											{` ${revision?.revisedDestinationTravelPoint || destinationStation}, ${revision?.revisedArrivalDateTime}`}
										</div>
									</p>
								) : (
									<p>
										<div>
											<FormattedMessage
												{...revisionMessages.lblDestinationNoModifications}
											/>
										</div>
										<div>
											{`Scheduled to arrive  ${destinationStation}, ${arriveTime}`}
										</div>
									</p>
								)}
							</div>
						))}
				</StyledAlertRowDiv>
			)}
		</DialogContent>
		<DialogActions>
			<Button
				onClick={onClose}
				variant="contained"
				color="primary"
				id="close-button"
			>
				<FormattedMessage {...messages.lblClose} />
			</Button>
		</DialogActions>
	</Dialog>
);

ServiceAlerts.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	serviceAlerts: PropTypes.array,
	revisions: PropTypes.array,
	originStation: PropTypes.string,
	destinationStation: PropTypes.string,
	departTime: PropTypes.string,
	arriveTime: PropTypes.string,
};

export { ServiceAlerts as ServiceAlertsAlias };

export default ServiceAlerts;
