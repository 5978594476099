import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import messages from './messagesConfirmedCardInfo';

const ConfirmedCardInfoView = (props) => {
	const { formatMessage } = props.intl;
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-6">
					<b data-testid="first-name-title">
						{formatMessage(messages.lblFirstName)}
					</b>
				</div>
				<div className="col-6" data-testid="first-name">
					{props.confirmedCardInfo.BookingOrderConfirmationFirstName}
				</div>
			</div>
			<div className="row">
				<div className="col-6" data-testid="last-name-title">
					<b>{formatMessage(messages.lblLastName)}</b>
				</div>
				<div className="col-6" data-testid="last-name">
					{props.confirmedCardInfo.BookingOrderConfirmationLastName}
				</div>
			</div>
			<div className="row">
				<div className="col-6" data-testid="middle-name-title">
					<b>{formatMessage(messages.lblMiddleName)}</b>
				</div>
				<div className="col-6" data-testid="middle-name">
					{props.confirmedCardInfo.BookingOrderConfirmationMiddleName}
				</div>
			</div>
			{!(
				props.confirmedCardInfo.BookingOrderConfirmationType === 'DC' ||
				props.confirmedCardInfo.BookingOrderConfirmationType === 'CC'
			) || (
				<div>
					<div className="row">
						<div className="col-6">
							<b data-testid="dc-cd-card-number">
								{formatMessage(messages.lblCardNumber)}
							</b>
						</div>
						<div className="col-6" data-testid="dc-cd-confirmation-card-number">
							{props.confirmedCardInfo.BookingOrderConfirmationCardNumber}
						</div>
					</div>
					<div className="row">
						<div className="col-6" data-testid="dc-cd-expiration-date">
							<b>{formatMessage(messages.lblExpirationDate)}</b>
						</div>
						<div
							className="col-6"
							data-testid="dc-cd-confirmation-expiration-date"
						>
							{props.confirmedCardInfo.BookingOrderConfirmationExpirationDate}
						</div>
					</div>
				</div>
			)}
			{props.confirmedCardInfo.BookingOrderConfirmationType === 'LC' && (
				<div>
					<div className="row">
						<div className="col-6">
							<b data-testid="lc-loyalty">
								{formatMessage(messages.lblLoyaltyCardProgram)}
							</b>
						</div>
						<div className="col-6" data-testid="lc-confirmation-program">
							{props.confirmedCardInfo.BookingOrderConfirmationProgram}
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<b data-testid="lc-card-identifier">
								{formatMessage(messages.lblLoyaltyCardIdentifier)}
							</b>
						</div>
						<div className="col-6" data-testid="lc-confirmation-identifier">
							{props.confirmedCardInfo.BookingOrderConfirmationIdentifier}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

ConfirmedCardInfoView.propTypes = {
	intl: PropTypes.object,
	confirmedCardInfo: PropTypes.object,
};

export { ConfirmedCardInfoView as ConfirmedCardInfoViewAlias };
export default injectIntl(ConfirmedCardInfoView);
