import { defineMessages } from 'react-intl';

export default defineMessages({
	btnChangeSelection: {
		id: 'app.shopping.fare.changeSelection',
		defaultMessage: 'Change Selection',
	},
	btnViewFareDetails: {
		id: 'app.shopping.fare.viewFareDetails',
		defaultMessage: 'Additional Details',
	},
	lblDiscountCard: {
		id: 'app.shopping.fare.discountCard',
		defaultMessage: 'Discount Card: ',
	},
	lblPromoCode: {
		id: 'app.shopping.search.promoCode',
		defaultMessage: 'Promo Code',
	},
	lblValidationCode: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.validationcode',
		defaultMessage: 'Validation Code',
	},
	lblDiscountedFare: {
		id: 'app.shopping.fare.discountedFare',
		defaultMessage: '* Discounted Fare',
	},
	lblFareReturnInboundRulesMessage: {
		id: 'app.shopping.fare.fareReturnInboundRulesMessage',
		defaultMessage: 'Same as outbound fare rules.',
	},
	lblFare: {
		id: 'app.shopping.fare.fare',
		defaultMessage: 'Fare',
	},
	lblMultiple: {
		id: 'app.shopping.fare.multiple',
		defaultMessage: 'Multiple',
	},
	lblEnds: {
		id: 'app.shopping.fare.ends',
		defaultMessage: 'Ends {expirationDate}',
	},
	lblOverbooked: {
		id: 'app.shopping.fare.overbooked',
		defaultMessage: 'overbooked',
	},
	lblRefundable: {
		id: 'app.shopping.fare.Refundable',
		defaultMessage: 'Refundable',
	},
	lblNoRefund: {
		id: 'app.shopping.fare.NoRefund',
		defaultMessage: 'No refund',
	},
	lblSplitTickets: {
		id: 'app.shopping.fare.SplitTickets',
		defaultMessage: 'Split Tickets',
	},
});
