import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FareRulesMatrixContent from './FareRulesMatrixContent';

import messages from './messagesFareRulesMatrix';
import withStylesTheme from './fareRulesMatrixStyles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...withStylesTheme.dialogPaper,
	},
}));

function FareRulesMatrix({
	handleClose,
	open,
	fareRulesDetails,
	paxTitle,
	isSeason,
}) {
	return (
		<StyledDialog open={open} onClose={handleClose} maxWidth={false}>
			<DialogTitle>
				<FormattedMessage {...messages.lblFareRulesMatrix} />
			</DialogTitle>
			<DialogContent>
				<FareRulesMatrixContent
					fareRulesDetails={fareRulesDetails}
					paxTitle={paxTitle}
					isSeason={isSeason}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					id="srtFareRuleMatrixClose"
					onClick={handleClose}
				>
					<FormattedMessage {...messages.btnClose} />
				</Button>
			</DialogActions>
		</StyledDialog>
	);
}

FareRulesMatrix.propTypes = {
	intl: PropTypes.object,
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func,
	fareRulesDetails: PropTypes.arrayOf(
		PropTypes.shape({
			paxRefs: PropTypes.string,
			fareClasses: PropTypes.string,
			displayName: PropTypes.string,
			fareCodes: PropTypes.string,
			originTerminals: PropTypes.string,
			destinationTerminals: PropTypes.string,
			fareMatrix: PropTypes.arrayOf(
				PropTypes.shape({
					isTextRule: PropTypes.bool,
					textRuleDesc: PropTypes.array,
					category: PropTypes.string,
					priceType: PropTypes.string,
					applicableTicketingOption: PropTypes.string,
					expirationDateTime: PropTypes.string,
					refundAllowedConfirmed: PropTypes.string,
					refundAllowedConfirmedPenalty: PropTypes.string,
					refundAllowedTicketed: PropTypes.string,
					refundAllowedTicketedPenalty: PropTypes.string,
					exchangeAllowedConfirmed: PropTypes.string,
					exchangeAllowedConfirmedPenalty: PropTypes.string,
					exchangeAllowedTicketed: PropTypes.string,
					exchangeAllowedTicketedPenalty: PropTypes.string,
				}),
			),
		}),
	),
	paxTitle: PropTypes.string.isRequired,
	isSeason: PropTypes.bool,
	isCreateBookingPage: PropTypes.bool,
	isBookingPage: PropTypes.bool,
	addOrderMode: PropTypes.bool,
	exchangeOrderMode: PropTypes.bool,
	isCancelOrderPage: PropTypes.bool,
	isVoidOrderPage: PropTypes.bool,
};

export { FareRulesMatrix as FareRulesMatrixAlias };
export default injectIntl(FareRulesMatrix);
