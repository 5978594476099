export default {
	clearButton: {
		top: 36,
		padding: 0,
		width: 14,
		height: 16,
		opacity: 0.4,
	},
	enddDateContainer: {
		display: 'inline-flex',
	},
	viaAvoidLink: {
		textTransform: 'capitalize',
		fontWeight: 200,
	},
	rowBottomAlign: {
		display: 'inline-block',
		verticalAlign: 'bottom',
		float: 'none',
	},
};
