import { defineMessages } from 'react-intl';

export default defineMessages({
	lblSelectAccount: {
		id: 'app.common.subnav.selectAccount',
		defaultMessage: 'Select an Account',
	},
	lblAccount: {
		id: 'app.common.subnav.account',
		defaultMessage: 'Account',
	},
});
