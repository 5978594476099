export default {
	dialogPaper: {
		overflowY: 'visible',
		maxWidth: '768px',
	},
	dialogActionsRoot: {
		width: '100%',
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	tableHead: {
		whiteSpace: 'nowrap',
	},
};
