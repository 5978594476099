import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { fromJS } from 'immutable';
import queryString from 'query-string';

import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import CancelIcon from '@mui/icons-material/Cancel';

import RefreshIndicator from '../../components/RefreshIndicator/RefreshIndicator';
import SubNavbar from '../../components/SubNavbar/SubNavbar';
import BookingEmailConfirmationDialog from '../components/BookingEmailConfirmationDialog/BookingEmailConfirmationDialog';
import BookingSearch from './components/BookingSearch/BookingSearch';
import { gaEvent, gaSetAccount } from '../../utils/googleAnalytics';
import BookingView from './components/BookingView/BookingView';
import { amendCTRApi, loadBookingDetailsApi } from '../apiBooking';
import {
	fetchBookingDetails,
	fetchBookingSearch,
	clearSearchResults,
	closeBooking,
	clearNewBookingRequest,
	clearErrorMessage,
	setLastActiveBookingTab,
} from './actionsManageBooking';
import messages from './messagesManageBooking';
import './stylesManageBooking.css';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import { homeTab } from './constants';
import inlineStyles from './styles';

// eslint-disable-next-line no-unused-vars
validate.validators.validLocator = (value, options, key, attributes) => {
	if (
		!validate.isEmpty(value) &&
		!/^[bB](-)[a-zA-Z0-9@#$%&*+_(),+':;?.,![\]\\/]{1,19}(-)[a-zA-Z0-9]{1,49}$/.test(
			value,
		)
	) {
		return options.message;
	}
	return null;
};

validate.extend(validate.validators.datetime, {
	parse: (date) => new Date(date),
	format: (timestamp) => new Date(timestamp).toLocaleDateString(),
});

class ManageBooking extends PureComponent {
	static propTypes = {
		id: PropTypes.string,
		intl: PropTypes.object,
		searchResults: PropTypes.array,
		bookingDetails: PropTypes.object,
		onSubmitSearch: PropTypes.func.isRequired,
		onOpenBooking: PropTypes.func.isRequired,
		onClearSearchResults: PropTypes.func.isRequired,
		onCloseBooking: PropTypes.func.isRequired,
		onClearNewRequest: PropTypes.func.isRequired,
		onClearErrorMessage: PropTypes.func.isRequired,
		changeLastActiveTab: PropTypes.func,
		isFetching: PropTypes.bool,
		lastActiveTab: PropTypes.string,
		newBookingDetails: PropTypes.object,
		showConvertToTicketed: PropTypes.bool,
		enableReturns: PropTypes.bool,
		alertMessage: PropTypes.string,
		warningMessage: PropTypes.string,
		configBasedAuth: PropTypes.bool,
		accountContexts: PropTypes.array,
		disableCustomerEmailConfirmation: PropTypes.bool,
		disableCustomerEmailSending: PropTypes.bool,
		ignoreDisableEmailSendingSuppliers: PropTypes.array,
		showExchangeOrderButton: PropTypes.bool,
		showVoidOrderButton: PropTypes.bool,
		enableBookingAddFee: PropTypes.bool,
		match: PropTypes.object,
		history: PropTypes.object,
		location: PropTypes.object,
	};

	constructor(props) {
		super(props);
		const qs = queryString.parse(this.props.location?.search);
		const alertMessage = qs.alertText;
		this.state = {
			data: fromJS(
				this.initialState(
					props.match.params.bookingLocator || '',
					alertMessage || '',
				),
			),
		};
	}

	componentDidMount() {
		const {
			onClearSearchResults,
			newBookingDetails,
			onClearNewRequest,
			changeLastActiveTab,
			history,
		} = this.props;
		const { data } = this.state;
		onClearSearchResults();
		if (!validate.isEmpty(newBookingDetails)) {
			this.openBooking(newBookingDetails, onClearNewRequest);
		} else if (this.state.data.getIn(['searchQuery', 'bookingLocator'])) {
			this.submitSearch();
		} else if (data.get('activeTab') !== homeTab) {
			changeLastActiveTab(data.get('activeTab'), history);
		}
	}

	initialState = (bookingLocator = '', alertMessage = '') => ({
		searchQuery: {
			firstLastName: '',
			email: '',
			phone: '',
			smartCardNumber: '',
			departureDate: null,
			bookingLocator,
			supplierLocator: '',
			carrierLocator: '',
			ticketLocator: '',
			uniqueTicketNumber: '',
			referenceNumber: '',
			customerNumber: '',
		},
		isProcessingSubmit: false,
		alertText: alertMessage,
		activeTab: this.props.lastActiveTab || homeTab,
		emailConfirmationDialogOpen: false,
		emailConfirmationBooking: {},
		errors: {},
	});

	constraints = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		return {
			bookingLocator: {
				validLocator: {
					message: formatMessage(messages.errBookingLocatorInvalid),
				},
			},
			phone: {
				format: {
					pattern: '^[0-9,(,),+,_,#,~,-]{1,45}$',
					flags: 'g',
					message: formatMessage(messages.errPhoneInvalid),
				},
				length: {
					maximum: 32,
					message: formatMessage(messages.errPhoneLength),
				},
			},
			email: {
				email: {
					message: formatMessage(messages.errEmailInvalid),
				},
			},
			departureDate: {
				datetime: {
					notValid: {
						message: formatMessage(messages.errDateInvalid),
					},
				},
			},
		};
	};

	checkFields = (data) =>
		data.firstLastName !== '' ||
		data.email !== '' ||
		data.phone !== '' ||
		data.smartCardNumber !== '' ||
		data.bookingLocator !== '' ||
		data.supplierLocator !== '' ||
		data.carrierLocator !== '' ||
		data.ticketLocator !== '' ||
		data.uniqueTicketNumber !== '' ||
		data.referenceNumber !== '' ||
		data.customerNumber !== '';

	validateFieldsHelper = (data) =>
		data.departureDate !== null || this.checkFields(data);

	openBooking = (queryItems, callback) => {
		const { history, onOpenBooking, changeLastActiveTab } = this.props;
		onOpenBooking(queryItems).then((sucessResponse) => {
			if (sucessResponse) {
				const activeTab = queryItems.srtRecLocator;
				this.setState((state) => ({ data: state.data.merge({ activeTab }) }));
				changeLastActiveTab(activeTab, history);
				if (callback) callback();
			}
		});
	};

	handleClickResetFields = () => {
		this.setState({
			data: fromJS({ ...this.initialState(), activeTab: homeTab }),
		});
	};

	handleClickSearchAgain = () => {
		gaSetAccount(null);
		gaEvent('searchAgainBookingSearch');
		this.props.onClearSearchResults();
		this.handleClickResetFields();
	};

	handleClickRetrieveBookings = () => this.submitSearch();

	handleClickOpenBooking = (queryItems, auto) => {
		gaEvent('openBookingFromSearch', auto);
		this.openBooking(queryItems);
	};

	updateIsProcessingSubmit = (isProcessingSubmit) => {
		this.setState((state) => ({
			data: state.data.merge({ isProcessingSubmit }),
		}));
	};

	handleOpenEmailConfirmationDialog = (queryItems) => {
		this.updateIsProcessingSubmit(true);
		loadBookingDetailsApi(
			queryItems,
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						isProcessingSubmit: false,
						alertText: response.errorResponse.message,
					}),
				}));
			},
			(response) => {
				const emailConfirmationBooking = response.successResponse.data;
				emailConfirmationBooking.queryItems = queryItems;
				this.setState((state) => ({
					data: state.data.merge({
						isProcessingSubmit: false,
						emailConfirmationDialogOpen: true,
						emailConfirmationBooking,
					}),
				}));
			},
		);
	};

	handleCloseEmailConfirmationDialog = () =>
		this.setState((state) => ({
			data: state.data.merge({
				emailConfirmationDialogOpen: false,
				emailConfirmationBooking: {},
			}),
		}));

	handleSubmitSearch = () => this.submitSearch();

	handleChangeFieldQuery = (prop, value) => {
		this.setState((state) => ({
			data: state.data.mergeIn(['searchQuery'], { [prop]: value }),
		}));
	};

	handleClickTab = (activeTab) => {
		const { changeLastActiveTab, history } = this.props;
		this.setState((state) => ({ data: state.data.merge({ activeTab }) }));
		changeLastActiveTab(activeTab, history);
	};

	handleCloseBooking = (recLoc) => {
		const { onCloseBooking, changeLastActiveTab, bookingDetails, history } =
			this.props;
		onCloseBooking(recLoc);
		if (this.state.data.get('activeTab') === recLoc) {
			const bookingDetailsKeys = Object.keys(bookingDetails);
			const closedIndex = bookingDetailsKeys.findIndex(
				(item) => item === recLoc,
			);
			let activeTab = homeTab;
			if (closedIndex === 0 && bookingDetailsKeys.length > 1) {
				activeTab = bookingDetailsKeys[closedIndex + 1];
			} else if (closedIndex > 0) {
				activeTab = bookingDetailsKeys[closedIndex - 1];
			}
			this.setState((state) => ({ data: state.data.merge({ activeTab }) }));
			changeLastActiveTab(activeTab, history);
		}
	};

	handleAmendCTR = (order, queryItems) => {
		if (order && queryItems) {
			this.setState((state) => ({
				data: state.data.merge({
					isProcessingSubmit: true,
					alertText: '',
				}),
			}));

			amendCTRApi(
				{
					queryItems,
					orderId: order.BookingOrderID,
					docLocator: order.BookingValueDocLocator,
				},
				(response) => {
					this.setState((state) => ({
						data: state.data.merge({
							isProcessingSubmit: false,
							alertText: response.errorResponse.message,
						}),
					}));
				},
				() => {
					this.updateIsProcessingSubmit(false);
					this.openBooking(queryItems);
				},
			);
		}
	};

	handleAllowAnyCardCollect = (queryItems) => {
		this.openBooking(queryItems);
	};

	submitSearch = async () => {
		const {
			intl: { formatMessage },
		} = this.props;
		const data = this.state.data.get('searchQuery').toJS();
		const errors = validate(data, this.constraints()) || {};
		const oneFieldConstraint = this.validateFieldsHelper(data);
		let notOnlyDate = true;
		if (data.departureDate !== null) {
			notOnlyDate = this.checkFields(data);
		}
		if (validate.isEmpty(errors) && oneFieldConstraint && notOnlyDate) {
			await this.props
				.onSubmitSearch(data, this.props.intl)
				.then((searchRes) => {
					if (!searchRes && validate.isEmpty(this.props.alertMessage)) {
						this.setState((state) => ({
							data: state.data.set(
								'alertText',
								formatMessage(messages.errNoBookings),
							),
						}));
					} else if (searchRes && searchRes?.length === 1) {
						const booking = searchRes[0];
						this.handleClickOpenBooking(
							{
								srtAgencyCode: booking.BookingAgencyCode,
								srtChannelCode: booking.BookingChannelCode,
								srtCompanyCode: booking.BookingCompanyCode,
								srtDistCode: booking.BookingDistributorCode,
								srtPOSCode: booking.BookingPOSCode,
								srtRecLocator: booking.BookingRecordLoc,
							},
							true,
						);
					}
				});
		} else if (
			!validate.isEmpty(errors) ||
			oneFieldConstraint === false ||
			notOnlyDate === false
		) {
			const updates = { errors };
			if (oneFieldConstraint === false) {
				updates.alertText = formatMessage(messages.errAtLeastOneField);
			}
			if (notOnlyDate === false) {
				updates.alertText = formatMessage(messages.errDateOnly);
			}
			this.setState((state) => ({ data: state.data.merge(updates) }));
		}
	};

	handleSnackBarClose = () => {
		this.props.onClearErrorMessage();
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			searchResults,
			showConvertToTicketed,
			enableReturns,
			configBasedAuth,
			accountContexts,
			disableCustomerEmailConfirmation,
			disableCustomerEmailSending,
			ignoreDisableEmailSendingSuppliers,
			showExchangeOrderButton,
			showVoidOrderButton,
			enableBookingAddFee,
		} = this.props;
		const searchQuery = this.state.data.get('searchQuery').toJS();
		const errors = this.state.data.get('errors').toJS();

		const hasResults = searchResults.length > 0;
		const bookings = this.props.bookingDetails;
		const isProcessingAction =
			this.props.isFetching || this.state.data.get('isProcessingSubmit');
		const alertText =
			this.state.data.get('alertText') ||
			this.props.alertMessage ||
			this.props.warningMessage;
		const activeTab = this.state.data.get('activeTab');

		const showAgain = hasResults || activeTab !== homeTab;

		return (
			<div>
				<SubNavbar heading={<FormattedMessage {...messages.lblTitle} />}>
					{showAgain ? (
						<Button
							variant="contained"
							id="srtBookingSearchAgain"
							data-testid="search-again-button"
							onClick={this.handleClickSearchAgain}
							disabled={isProcessingAction}
						>
							<FormattedMessage {...messages.btnSearchAgain} />
						</Button>
					) : null}
					{!showAgain ? (
						<div>
							<Button
								variant="contained"
								id="srtBookingResetFields"
								data-testid="search-reset-fields"
								onClick={() => {
									gaEvent('resetFieldsBookingSearch');
									this.handleClickResetFields();
								}}
								style={inlineStyles.subNavBarButtons}
								disabled={isProcessingAction}
								sx={{
									backgroundColor: '#e0e0e0',
									color: '#333',
									'&:hover': {
										backgroundColor: '#e0e0e0',
									},
									fontWeight: 500,
								}}
							>
								<FormattedMessage {...messages.btnResetFields} />
							</Button>
							<Button
								variant="contained"
								id="srtBookingRetrieveBookings"
								data-testid="retrieve-bookings"
								onClick={this.handleClickRetrieveBookings}
								disabled={isProcessingAction}
								style={inlineStyles.subNavBarButtons}
								color="primary"
							>
								<FormattedMessage {...messages.btnRetrieveBookings} />
							</Button>
						</div>
					) : null}
				</SubNavbar>
				<div className="container-fluid">
					<div className="row">
						<div className="col-12" styleName="manageBookingTabs">
							<Button
								variant="text"
								id="bookingHomeTab"
								data-testid="bookingHomeTab"
								key={homeTab}
								style={
									activeTab === homeTab
										? inlineStyles.activeHomeTab
										: inlineStyles.homeTab
								}
								value={homeTab}
								onClick={() => {
									gaEvent('searchBookingTab');
									this.handleClickTab(homeTab);
								}}
							>
								<HomeIcon />
								<FormattedMessage {...messages.lblHomeTab} />
							</Button>
							{Object.keys(bookings).map((bookingRecordLocator) => (
								<span key={`srt_${bookingRecordLocator}`}>
									<Button
										variant="text"
										id={`srt_${bookingRecordLocator}`}
										data-testid={`srt_${bookingRecordLocator}`}
										key={`srt_${bookingRecordLocator}`}
										style={
											activeTab === bookingRecordLocator
												? inlineStyles.activeTab
												: inlineStyles.tab
										}
										onClick={() => {
											gaEvent('openBookingTab');
											this.handleClickTab(bookingRecordLocator);
										}}
									>
										{bookingRecordLocator}
									</Button>
									<CancelIcon
										onClick={() => {
											gaEvent('closeBookingTab');
											this.handleCloseBooking(bookingRecordLocator);
										}}
										style={inlineStyles.closeTab}
									/>
								</span>
							))}
						</div>
						<div className="col-12">
							{activeTab === homeTab ? (
								<BookingSearch
									onSubmitSearch={this.handleSubmitSearch}
									onChangeField={this.handleChangeFieldQuery}
									onOpenBooking={this.handleClickOpenBooking}
									onOpenEmailConfirmDialog={
										this.handleOpenEmailConfirmationDialog
									}
									searchResults={searchResults}
									hasResults={hasResults}
									isProcessingAction={isProcessingAction}
									searchQuery={searchQuery}
									errors={errors}
								/>
							) : (
								<BookingView
									id={`bookingDetails_${activeTab}`}
									booking={bookings[activeTab]}
									showAmendCTR={enableReturns && showConvertToTicketed}
									onAmendCTR={this.handleAmendCTR}
									onAllowAnyCardCollect={this.handleAllowAnyCardCollect}
									enableReturns={enableReturns}
									configBasedAuth={configBasedAuth}
									accountContexts={accountContexts}
									disableCustomerEmailConfirmation={
										disableCustomerEmailConfirmation
									}
									disableCustomerEmailSending={disableCustomerEmailSending}
									ignoreDisableEmailSendingSuppliers={
										ignoreDisableEmailSendingSuppliers
									}
									showExchangeOrderButton={showExchangeOrderButton}
									showVoidOrderButton={showVoidOrderButton}
									updateIsProcessingSubmit={this.updateIsProcessingSubmit}
									isProcessingAction={isProcessingAction}
									enableBookingAddFee={enableBookingAddFee}
								/>
							)}
						</div>
					</div>
					<BookingEmailConfirmationDialog
						onClose={this.handleCloseEmailConfirmationDialog}
						open={this.state.data.get('emailConfirmationDialogOpen')}
						booking={this.state.data.get('emailConfirmationBooking').toJS()}
					/>
				</div>
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={isProcessingAction ? 'loading' : 'hide'}
					style={inlineStyles.indicator}
				/>
				<ExtendedSnackbar
					id="srtBookingSearchSnackBar"
					open={alertText !== ''}
					message={alertText}
					onClose={this.handleSnackBarClose}
				/>
			</div>
		);
	}
}

export { ManageBooking as ManageBookingAlias };

const mapStateToProps = (state) => ({
	searchResults: state.getIn(['booking', 'searchResults']).toJS(),
	bookingDetails: state.getIn(['booking', 'bookingDetails']).toJS(),
	alertMessage: state.getIn(['booking', 'errorMessage']),
	warningMessage: state.getIn(['booking', 'warningMessage']),
	isFetching: state.getIn(['booking', 'isFetching']),
	lastActiveTab: state.getIn(['booking', 'lastActiveTab']),
	newBookingDetails: state.getIn(['booking', 'newBookingDetails']).toJS(),
	showConvertToTicketed:
		state.getIn(['settings', 'ws.feature.booking.show_convert_to_ticketed']) ===
		'true',
	enableReturns:
		state.getIn(['settings', 'ws.feature.booking.enable_returns']) === 'true',
	configBasedAuth:
		state.getIn([
			'settings',
			'ws.interface.config_based.authentication_enabled',
		]) === 'true',
	accountContexts:
		state.getIn([
			'settings',
			'ws.interface.config_based.authentication_enabled',
		]) === 'true' && state.getIn(['settings', 'ws.app.contexts'])
			? state
					.getIn(['settings', 'ws.app.contexts'])
					.split(',')
					.map((item) => ({
						text: item,
						value: item,
					}))
			: null,
	disableCustomerEmailConfirmation:
		state.getIn([
			'settings',
			'ws.feature.disable_customer_email_confirmation',
		]) === 'true',
	disableCustomerEmailSending:
		state.getIn(['settings', 'ws.feature.disable_customer_email_sending']) ===
		'true',
	ignoreDisableEmailSendingSuppliers: state.getIn([
		'settings',
		'ws.feature.ignore_disable_email_sending_suppliers',
	])
		? state
				.getIn([
					'settings',
					'ws.feature.ignore_disable_email_sending_suppliers',
				])
				.split(',')
				.map((item) => item)
		: [],
	showExchangeOrderButton:
		state.getIn(['settings', 'ws.feature.booking.show_exchange_order']) ===
		'true',
	showVoidOrderButton:
		state.getIn(['settings', 'ws.feature.booking.show_void_order']) === 'true',
	enableBookingAddFee:
		state.getIn(['settings', 'ws.feature.booking.enable_add_fee']) === 'true',
});

const mapDispatchToProps = {
	onSubmitSearch: fetchBookingSearch,
	onOpenBooking: fetchBookingDetails,
	onClearSearchResults: clearSearchResults,
	onCloseBooking: closeBooking,
	onClearNewRequest: clearNewBookingRequest,
	onClearErrorMessage: clearErrorMessage,
	changeLastActiveTab: setLastActiveBookingTab,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(ManageBooking));
