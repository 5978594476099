import { defineMessages } from 'react-intl';

export default defineMessages({
	lblSelectCoach: {
		id: 'app.shopping.createBooking.seatMap.lblSelectCoach',
		defaultMessage: 'Please select your preferred coach',
	},
	lblRear: {
		id: 'app.shopping.createBooking.seatMap.lblRear',
		defaultMessage: 'rear',
	},
	lblFront: {
		id: 'app.shopping.createBooking.seatMap.lblFront',
		defaultMessage: 'front',
	},
	lblSelectSeat: {
		id: 'app.shopping.createBooking.seatMap.lblSelectSeat',
		defaultMessage: 'Please select your preferred seat(s)',
	},
	lblSelected: {
		id: 'app.shopping.createBooking.seatMap.lblSelected',
		defaultMessage: 'Selected',
	},
	lblAvailable: {
		id: 'app.shopping.createBooking.seatMap.lblAvailable',
		defaultMessage: 'Available',
	},
	lblUnavailable: {
		id: 'app.shopping.createBooking.seatMap.lblUnavailable',
		defaultMessage: 'Unavailable/filtered out',
	},
	lblDirectionOfTravel: {
		id: 'app.shopping.createBooking.seatMap.lblDirectionOfTravel',
		defaultMessage: 'Direction of travel',
	},
	lblSelectedSeat: {
		id: 'app.shopping.createBooking.seatMap.lblSelectedSeat',
		defaultMessage: 'Selected seat',
	},
	lblDining: {
		id: 'app.shopping.createBooking.seatMap.lblDining',
		defaultMessage: 'Dining',
	},
	lblDrinks: {
		id: 'app.shopping.createBooking.seatMap.lblDrinks',
		defaultMessage: 'Drinks',
	},
	lblSnacks: {
		id: 'app.shopping.createBooking.seatMap.lblSnacks',
		defaultMessage: 'Snacks',
	},
	lblBicycles: {
		id: 'app.shopping.createBooking.seatMap.lblBicycles',
		defaultMessage: 'Bicycles',
	},
	lblLuggage: {
		id: 'app.shopping.createBooking.seatMap.lblLuggage',
		defaultMessage: 'Luggage',
	},
	lblCarTransport: {
		id: 'app.shopping.createBooking.seatMap.lblCarTransport',
		defaultMessage: 'Car Transport',
	},
	lblCoach: {
		id: 'app.shopping.createBooking.seatMap.lblCoach',
		defaultMessage: 'Coach',
	},
	lblSeat: {
		id: 'app.shopping.createBooking.seatMap.lblSeat',
		defaultMessage: 'Seat',
	},
	lblSeatMapTitle: {
		id: 'app.shopping.createBooking.seatMap.lblSeatMapTitle',
		defaultMessage:
			'SeatMapper: {departStation} to {arriveStation} for passenger #{selectedPax}',
	},
	lblSelectPreferredCoach: {
		id: 'app.shopping.createBooking.seatMap.lblSelectPreferredCoach',
		defaultMessage: 'Please select your preferred coach',
	},
	lblSelectPreferredSeat: {
		id: 'app.shopping.createBooking.seatMap.lblSelectPreferredSeat',
		defaultMessage: 'Please select your preferred seat',
	},
	lblOtherPassengerSeats: {
		id: 'app.shopping.createBooking.seatMap.lblOtherPassengerSeats',
		defaultMessage: '{deckLevel} Coach {coach}/Seat {seat}',
	},
	lblOtherPassengerSelectedSeats: {
		id: 'app.shopping.createBooking.seatMap.lblOtherPassengerSelectedSeats',
		defaultMessage:
			'Seats selected for this segment for other passenger on this booking: {seats}',
	},
});
