import { shoppingSearchApi, exchangeSearchApi } from '../apiShopping';

export const FARES_SELECTED = 'FARE_SELECTED';
export const INITIATE_SEARCH = 'INITIATE_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const DISMISS_SEARCH_RESPONSE = 'DISMISS_SEARCH_RESPONSE';
export const CLEAR_FARES_SELECTED = 'CLEAR_FARES_SELECTED';
export const CLEAR_SEARCH_INITIATE = 'CLEAR_SEARCH_INITIATE';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_CHANGE_JOURNEY = 'SET_CHANGE_JOURNEY';
export const CLEAR_CHANGE_JOURNEY = 'CLEAR_CHANGE_JOURNEY';

export const onFaresSelected = (fares) => ({
	type: FARES_SELECTED,
	fares,
});

export const initiateSearch = () => ({ type: INITIATE_SEARCH });

export const receiveSearch = (payload, error) => ({
	type: RECEIVE_SEARCH,
	payload,
	error,
});

export const dismissSearchError = () => ({ type: DISMISS_SEARCH_RESPONSE });

export const clearFaresSelected = () => ({ type: CLEAR_FARES_SELECTED });

export const submitSearch = (query, intl, railCards) => (dispatch) => {
	dispatch(initiateSearch());
	return shoppingSearchApi(
		query,
		intl,
		railCards,
		(response) => {
			dispatch(receiveSearch(response.errorResponse.message, true));
			return false;
		},
		(response) => {
			dispatch(receiveSearch(response.successResponse, false));
			return true;
		},
	);
};

export const clearSearchInitiated = () => ({ type: CLEAR_SEARCH_INITIATE });

export const clearSearchResults = () => ({ type: CLEAR_SEARCH_RESULTS });

export const submitExchangeSearch =
	(
		query,
		intl,
		railCards,
		exchangeableTicketableFareIds,
		recordLocator,
		orderId,
		orderPassengers,
	) =>
	(dispatch) => {
		dispatch(initiateSearch());
		return exchangeSearchApi(
			query,
			intl,
			railCards,
			exchangeableTicketableFareIds,
			recordLocator,
			orderId,
			orderPassengers,
			(response) => {
				dispatch(receiveSearch(response.errorResponse.message, true));
				return false;
			},
			(response) => {
				dispatch(receiveSearch(response.successResponse, false));
				return true;
			},
		);
	};

export const setChangeJourney = () => ({ type: SET_CHANGE_JOURNEY });

export const clearChangeJourney = () => ({ type: CLEAR_CHANGE_JOURNEY });
