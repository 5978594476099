import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { styled } from '@mui/system';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import messages from './messagesHeader';
import './header.css';
import inlineStyles from './styles';

const StyledButton = styled(Button)(() => ({
	...inlineStyles.welcomeButton,
	'& .MuiButton-label': {
		...inlineStyles.tab,
	},
}));

function UserMenu(props) {
	const {
		handleOpenAbout,
		handleOpenUserProfile,
		handleOpenChangePassword,
		handleHeaderLogout,
		handleOpenUserMenu,
		handleCloseUserMenu,
		userLoginId,
		openUserMenu,
		menuAnchorEl,
		configBasedAuth,
	} = props;

	const { formatMessage } = props.intl;
	return (
		<div id="srtUserMenuContainer">
			<StyledButton onClick={handleOpenUserMenu}>
				{userLoginId}
				<ExpandMoreIcon />
			</StyledButton>
			<Menu
				styleName="userMenu"
				open={openUserMenu}
				anchorEl={menuAnchorEl}
				onClose={handleCloseUserMenu}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				getcontentanchorel={undefined}
			>
				<MenuItem
					id={`srt_${formatMessage(messages.lblUserMenuAbout)}`}
					style={inlineStyles.userMenuItem}
					onClick={handleOpenAbout}
				>
					{formatMessage(messages.lblUserMenuAbout)}
				</MenuItem>
				<Divider />
				{!configBasedAuth && (
					<MenuItem
						id={`srt_${formatMessage(messages.lblUserMenuMyProfile)}`}
						style={inlineStyles.userMenuItem}
						onClick={handleOpenUserProfile}
					>
						{formatMessage(messages.lblUserMenuMyProfile)}
					</MenuItem>
				)}
				{!configBasedAuth && (
					<MenuItem
						id={`srt_${formatMessage(messages.lblUserMenuChangePassword)}`}
						style={inlineStyles.userMenuItem}
						onClick={handleOpenChangePassword}
					>
						{formatMessage(messages.lblUserMenuChangePassword)}
					</MenuItem>
				)}
				{!configBasedAuth ? <Divider /> : null}
				<MenuItem
					id={`srt_${formatMessage(messages.lblUserMenuLogout)}`}
					style={inlineStyles.userMenuItem}
					onClick={handleHeaderLogout}
				>
					{formatMessage(messages.lblUserMenuLogout)}
				</MenuItem>
			</Menu>
		</div>
	);
}

UserMenu.propTypes = {
	handleOpenAbout: PropTypes.func.isRequired,
	handleOpenUserProfile: PropTypes.func.isRequired,
	handleOpenChangePassword: PropTypes.func.isRequired,
	handleHeaderLogout: PropTypes.func.isRequired,
	handleOpenUserMenu: PropTypes.func.isRequired,
	handleCloseUserMenu: PropTypes.func.isRequired,
	userLoginId: PropTypes.string.isRequired,
	openUserMenu: PropTypes.bool.isRequired,
	menuAnchorEl: PropTypes.object,
	configBasedAuth: PropTypes.bool.isRequired,
	intl: PropTypes.object,
};

export { UserMenu as UserMenuAlias };

export default injectIntl(UserMenu);
