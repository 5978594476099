import validate from 'validate.js';

import {
	textValidCharactersRegex,
	postalCodeRegex,
	phoneNumberRegex,
	contextRegex,
} from '../../../utils/regex';
import messages from '../messagesManageAccounts';

// TODO possibly extract validators to a common file
validate.validators.isValidExpiryDate = (value, options, key, attributes) => {
	const dateTemp = new Date(Date.now());
	if (
		Number(attributes.expiryDateYear) === dateTemp.getFullYear() &&
		Number(value) <= dateTemp.getMonth()
	) {
		return options.message;
	}
	return null;
};

validate.validators.isValidStartDate = (value, options, key, attributes) => {
	const dateTemp = new Date(Date.now());
	if (
		Number(attributes.startDateYear) > dateTemp.getFullYear() ||
		(Number(attributes.startDateYear) === dateTemp.getFullYear() &&
			Number(attributes.startDateMonth) - 1 > dateTemp.getMonth())
	) {
		return options.message;
	}
	return null;
};

validate.validators.containsValidCharacters = (value, options) => {
	if (textValidCharactersRegex.test(value)) {
		return options.message;
	}
	return null;
};

export const constraints = (formatMessage) => ({
	name: {
		presence: {
			message: formatMessage(messages.errNameEmpty),
			allowEmpty: false,
		},
		containsValidCharacters: {
			message: formatMessage(messages.errNameInvalid),
		},
	},
	context: {
		presence: {
			message: formatMessage(messages.errContextEmpty),
			allowEmpty: false,
		},
		format: {
			pattern: contextRegex,
			message: formatMessage(messages.errContextInvalid),
		},
	},
});

// TODO no array validators, should be available on next validate js version
export const customInfoConstraintsValidation = (formatMessage, items) => {
	const errors = [];
	const types = [];
	items.forEach((item, index) => {
		const error = {};
		const noType = validate.isEmpty(item.type);
		let notValid = false;

		if (noType) {
			notValid = true;
			error.type = formatMessage(messages.errCustomInfoType);
		}

		if (item.type.length > 255) {
			notValid = true;
			error.type = formatMessage(messages.errCustomInfoTypeValueTooLong);
		}

		if (item.value.length > 255) {
			notValid = true;
			error.value = formatMessage(messages.errCustomInfoTypeValueTooLong);
		}

		const isDuplicate = types.some((typeItem) => item.type === typeItem);

		if (isDuplicate) {
			notValid = true;
			error.type = formatMessage(messages.errCustomInfoTypeDuplicate, {
				errorType: item.type,
			});
		}

		if (item.type.length > 0) {
			types.push(item.type);
		}

		if (notValid) {
			error.index = index;
			errors.push(error);
		}
	});
	return errors;
};

// TODO no array validators, should be available on next validate js version
export const feesConstraintsValidation = (formatMessage, items) => {
	const errors = [];
	items.forEach((item, index) => {
		const value = Number(item.amount);
		if (item.type === 'percentage' && (value < 0.01 || value > 99.99)) {
			errors.push({
				amount: formatMessage(messages.errPercentageFeeValue),
				index,
			});
		}
	});
	return errors;
};

export const addressConstraints = (formatMessage) => ({
	countryCode: {
		presence: {
			message: formatMessage(messages.errCountryEmpty),
			allowEmpty: false,
		},
	},
	address1: {
		presence: {
			message: formatMessage(messages.errAddressLine1Empty),
			allowEmpty: false,
		},
	},
	city: {
		presence: {
			message: formatMessage(messages.errCityEmpty),
			allowEmpty: false,
		},
	},
	stateOrProvince: (state, attr) => {
		const code = attr.countryCode;
		const isCanada = code === 'CA';
		if (isCanada || code === 'US') {
			const message = isCanada
				? formatMessage(messages.errProvinceEmpty)
				: formatMessage(messages.errStateEmpty);
			return {
				presence: { message, allowEmpty: false },
			};
		}
		return null;
	},
	postalCode: (postalCode, attr) => {
		const code = attr.countryCode;
		const req = {
			presence: {
				message: formatMessage(messages.errPostalCodeEmpty),
				allowEmpty: false,
			},
		};
		switch (code) {
			case 'CA':
			case 'US':
				req.format = {
					pattern: postalCodeRegex[code],
					message: formatMessage(messages.errPostalCodeInvalid),
				};
				break;
			default:
				req.length = {
					maximum: 15,
					wrongLength: formatMessage(messages.errPostalCodeInvalid),
				};
		}
		return req;
	},
});

export const contactConstraints = (formatMessage) => ({
	phone: (phone) => {
		if (!validate.isEmpty(phone)) {
			return {
				format: {
					pattern: phoneNumberRegex,
					message: formatMessage(messages.errPhoneInvalid),
				},
			};
		}
		return null;
	},
	email: (email) => {
		if (!validate.isEmpty(email)) {
			return {
				email: { message: formatMessage(messages.errEmailInvalid) },
			};
		}
		return null;
	},
});
