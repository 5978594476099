import { defineMessages } from 'react-intl';

export default defineMessages({
	userCreated: {
		id: 'app.admin.createUser.userCreated',
		defaultMessage: 'New user successfully created.',
	},
	errCreateUser: {
		id: 'app.admin.createUser.errCreateUser',
		defaultMessage: 'New user could not be created. Please try again.',
	},
});
