import { defineMessages } from 'react-intl';

export default defineMessages({
	lblFilter: {
		id: 'app.common.tableView.filter',
		defaultMessage: 'Filter',
	},
	lblItems: {
		id: 'app.common.tableView.items',
		defaultMessage: 'items',
	},
});
