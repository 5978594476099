import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import Confirmation from '../Confirmation/Confirmation';
import TermsOfService from '../TermsOfService/TermsOfService';
import { getConfirmDetailsApi, confirmOrderApi } from '../../apiBooking';
import {
	confirmationOptions,
	findConfirmationValues,
	getConditionsOfCarriageMap,
	handleAutoEmailConfirmation,
	shouldDisableCustomerEmail,
} from '../../utils';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { gaEvent } from '../../../utils/googleAnalytics';

import messages from './messagesConfirmOrder';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
});

class ConfirmOrderDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool,
		booking: PropTypes.object.isRequired,
		orderId: PropTypes.string,
		cocMap: PropTypes.object,
		onConfirmSuccess: PropTypes.func,
		disableCustomerEmailSending: PropTypes.bool,
		ignoreDisableEmailSendingSuppliers: PropTypes.array,
	};

	static defaultProps = {
		open: false,
		disableCustomerEmailSending: false,
		ignoreDisableEmailSendingSuppliers: [],
	};

	initialState = {
		type: '',
		method: '',
		firstName: '',
		middleName: '',
		lastName: '',
		cardNumber: '',
		expiryDateDay: '1',
		expiryDateMonth: '1',
		expiryDateYear: '',
		sortCode: '',
		loyaltyCardProgram: '',
		loyaltyCardIdentifier: '',
		vdEmail: '',
		showAlternateEmail: false,
		isCardTypeAvailable: false,
		language: '',
		termsOfServiceCheck: false,
		errors: {},
		bookingConfirmWarningMessages: '',
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS({
				alertText: '',
				isFetching: false,
				isProcessingSubmit: false,
				confirmationInformation: {},
				...this.initialState,
			}),
		};
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (!this.props.open && nextProps.open) {
			const { booking } = this.props;

			this.setState((state) => ({
				data: state.data.merge({
					isFetching: true,
					alertText: '',
					termsOfServiceCheck: false,
				}),
			}));

			getConfirmDetailsApi(
				{
					queryItems: booking.queryItems,
					orderId: nextProps.orderId,
				},
				(response) => {
					this.setState((state) => ({
						data: state.data.merge({
							isFetching: false,
							alertText: response.errorResponse.message,
						}),
					}));
				},
				(response) => {
					const { data } = response.successResponse;
					const confirmationValues = this.setConfirmationValues(data);
					this.setState((state) => ({
						data: state.data.merge({
							isFetching: false,
							confirmationInformation: data,
							...Object.assign(this.initialState, confirmationValues),
						}),
					}));
				},
			);
		}
	};

	// TODO make a common function
	setConfirmationValues = (confirmationInfo, type) => {
		const vdEmail =
			confirmationInfo.BookingEmailForClaimVD &&
			confirmationInfo.BookingEmailForClaimVD?.length > 0
				? confirmationInfo.BookingEmailForClaimVD[0]
				: '';
		const availableTypes =
			confirmationInfo.BookingPaymentConfirmationOptions.map(
				(option) => option.BookingPaymentConfirmationType,
			);

		const isCardTypeAvailable =
			availableTypes.indexOf(confirmationOptions.debitCard) !== -1 ||
			availableTypes.indexOf(confirmationOptions.creditCard) !== -1 ||
			availableTypes.indexOf(confirmationOptions.loyaltyCard) !== -1;

		const confirmationType = type || availableTypes[0];

		const optionValues = findConfirmationValues(
			confirmationType,
			confirmationInfo,
		);
		const refDate = new Date(Date.now());
		const cardTypeValue =
			optionValues && optionValues.BookingPaymentConfirmationValues
				? optionValues.BookingPaymentConfirmationValues[0]
						.BookingPaymentConfirmationValueType
				: '';

		return {
			type: confirmationType,
			method:
				optionValues &&
				optionValues.BookingPaymentConfirmationType !==
					confirmationOptions.loyaltyCard
					? cardTypeValue
					: '',
			loyaltyCardProgram:
				optionValues &&
				optionValues.BookingPaymentConfirmationType ===
					confirmationOptions.loyaltyCard
					? cardTypeValue
					: '',
			language: confirmationInfo.BookingPaymentConfirmationTicketLanguages
				.length
				? confirmationInfo.BookingPaymentConfirmationTicketLanguages[0]
						.BookingPaymentConfirmationTicketLanguageType
				: '',
			expiryDateYear: `${refDate.getFullYear()}`,
			expiryDateMonth: `${refDate.getMonth() + 1}`,
			expiryDateDay: `${refDate.getDate() + 1}`,
			isCardTypeAvailable,
			vdEmail,
		};
	};

	validateConfirmationHelper = (type, hasEmail) => {
		const {
			intl: { formatMessage },
		} = this.props;
		let constraints = {
			vdEmail: hasEmail
				? {
						presence: {
							message: formatMessage(messages.errConfirmationEmail),
							allowEmpty: false,
						},
						email: {
							message: formatMessage(messages.errConfirmationEmail),
						},
					}
				: null,
			termsOfServiceCheck: {
				inclusion: {
					message: formatMessage(messages.errTermsOfService),
					within: [true],
				},
			},
		};
		switch (type) {
			case confirmationOptions.creditCard:
			case confirmationOptions.debitCard:
				constraints = Object.assign(constraints, {
					firstName: {
						presence: {
							message: formatMessage(messages.errConfirmationFirstName),
							allowEmpty: false,
						},
					},
					lastName: {
						presence: {
							message: formatMessage(messages.errConfirmationLastName),
							allowEmpty: false,
						},
					},
					cardNumber: this.cardNumberConstraints,
					expiryDateMonth: {
						presence: {
							message: formatMessage(messages.errYearInvalid),
							allowEmpty: false,
						},
						isValidExpiryDate: {
							message: formatMessage(messages.errDateInvalid),
						},
					},
					expiryDateYear: {
						presence: {
							message: formatMessage(messages.errYearInvalid),
							allowEmpty: false,
						},
						isValidExpiryDate: {
							message: formatMessage(messages.errDateInvalid),
						},
					},
					sortCode:
						type === confirmationOptions.debitCard
							? {
									presence: formatMessage(messages.errConfirmationSortCode),
									allowEmpty: false,
								}
							: null,
				});
				break;
			case confirmationOptions.loyaltyCard:
				constraints = Object.assign(constraints, {
					expiryDateDay: {
						presence: {
							message: formatMessage(messages.errDayInvalid),
							allowEmpty: false,
						},
						isValidExpiryDate: {
							message: formatMessage(messages.errDateInvalid),
						},
					},
					expiryDateMonth: {
						presence: {
							message: formatMessage(messages.errMonthInvalid),
							allowEmpty: false,
						},
						isValidExpiryDate: {
							message: formatMessage(messages.errDateInvalid),
						},
					},
					expiryDateYear: {
						presence: {
							message: formatMessage(messages.errYearInvalid),
							allowEmpty: false,
						},
						isValidExpiryDate: {
							message: formatMessage(messages.errDateInvalid),
						},
					},
					loyaltyCardProgram: {
						presence: {
							message: formatMessage(messages.errConfirmationLoyaltyProgram),
							allowEmpty: false,
						},
					},
					loyaltyCardIdentifier: {
						presence: {
							message: formatMessage(
								messages.errConfirmationLoyaltyCardIdentifier,
							),
							allowEmpty: false,
						},
					},
				});
				break;
			default:
		}
		return constraints;
	};

	checkForAlternativeEmail = () => {
		const ticketDelivery =
			this.props.booking.BookingOrders[0].BookingOrderTicketDelivery;
		return ticketDelivery === 'ETK' || ticketDelivery === 'PAH';
	};

	isSMS = () => {
		const ticketDelivery =
			this.props.booking.BookingOrders[0].BookingOrderTicketDelivery;
		return ticketDelivery === 'SMS';
	};

	handleChangeField = (updates) => {
		this.setState((state) => ({
			data: state.data.merge(updates),
		}));
	};

	handleSnackBarClose = () => {
		this.setState((state) => ({
			data: state.data.set('alertText', ''),
		}));
	};

	handleCloseConfirmDialog = (queryItems) => {
		this.props.onConfirmSuccess(queryItems).then(() => {
			const delay = this.state.data.get('bookingConfirmWarningMessages').length
				? 6000
				: 0;

			setTimeout(() => {
				this.setState((state) => ({
					data: state.data.merge({ isProcessingSubmit: false, errors: {} }),
				}));
				this.props.onClose();
			}, delay);
		});
	};

	handleSubmitConfirmOrder = () => {
		const { orderId, booking, disableCustomerEmailSending } = this.props;
		const data = this.state.data.toJS();
		this.setState((state) => ({
			data: state.data.merge({
				isProcessingSubmit: true,
				errors: {},
			}),
		}));
		const hasEmail =
			data.confirmationInformation.BookingEmailForClaimVD &&
			data.confirmationInformation.BookingEmailForClaimVD?.length > 0;

		const confirmationConstraints = this.validateConfirmationHelper(
			data.type,
			hasEmail,
		);
		const confirmationErrors = validate(data, confirmationConstraints, {
			fullMessages: false,
		});

		if (!validate.isEmpty(confirmationErrors)) {
			const updates = { errors: confirmationErrors, isProcessingSubmit: false };
			if (updates.errors.termsOfServiceCheck) {
				updates.alertText = updates.errors.termsOfServiceCheck;
			}

			this.setState((state) => ({
				data: state.data.merge(updates),
			}));
		} else {
			confirmOrderApi(
				{
					confirmation: data,
					orderId,
					booking,
				},
				(response) => {
					this.setState((state) => ({
						data: state.data.merge({
							isProcessingSubmit: false,
							errors: {},
							alertText: response.errorResponse.message,
						}),
					}));
				},
				(successRes) => {
					const { queryItems } = booking;

					const stateUpdates = {
						isProcessingSubmit: false,
					};

					if (
						successRes.successResponse?.data.BookingConfirmWarningMessages
							?.length
					) {
						const joinedMsg =
							successRes.successResponse.data.BookingConfirmWarningMessages.join(
								'\n',
							);
						stateUpdates.bookingConfirmWarningMessages = joinedMsg;
					}

					const updateUserState = (additionalUpdates = {}) => {
						this.setState((state) => ({
							data: state.data.merge({
								...stateUpdates,
								...additionalUpdates,
							}),
						}));
					};

					if (!disableCustomerEmailSending) {
						const userEmail = data.vdEmail;
						handleAutoEmailConfirmation(userEmail, booking, orderId).then(
							(emailResponse) => {
								updateUserState(emailResponse);
								this.handleCloseConfirmDialog(queryItems);
							},
						);
					} else {
						updateUserState();
						this.handleCloseConfirmDialog(queryItems);
					}
				},
			);
		}
	};

	render() {
		const {
			cocMap,
			disableCustomerEmailSending,
			ignoreDisableEmailSendingSuppliers,
			open,
			onClose,
			intl: { formatMessage },
		} = this.props;
		const data = this.state.data.toJS();
		const { confirmationInformation } = data;
		const disabled = data.isProcessingSubmit || data.isFetching;

		const suppliers = confirmationInformation.BookingSupplierCodes;
		const disableCustomerEmail = shouldDisableCustomerEmail(
			disableCustomerEmailSending,
			ignoreDisableEmailSendingSuppliers,
			suppliers,
		);

		const closeAction = () => {
			gaEvent('confirmOrderClose');
			onClose();
		};
		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-12 col-sm-3 offset-sm-6">
					<Button
						variant="contained"
						id="srtConfirmClose"
						onClick={closeAction}
						fullWidth
						disabled={disabled}
					>
						{formatMessage(messages.btnCancel)}
					</Button>
				</div>
				<div className="col-12 col-sm-3">
					<Button
						variant="contained"
						id="srtConfirmSubmit"
						onClick={this.handleSubmitConfirmOrder}
						color="primary"
						fullWidth
						disabled={disabled}
					>
						{formatMessage(messages.btnSubmit)}
					</Button>
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={open}
					onClose={closeAction}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
					<DialogContent>
						{!validate.isEmpty(confirmationInformation) && (
							<div>
								<Confirmation
									confirmationInformation={confirmationInformation}
									onChange={this.handleChangeField}
									confirmationDetails={data}
									errors={data.errors ? data.errors : {}}
									showAlternateEmail={this.checkForAlternativeEmail()}
									emails={confirmationInformation.BookingEmailForClaimVD}
									disabled={disabled}
									styles={inlineStyles}
									confirmationOnly
									columnClass="col-12"
									disableCustomerEmailSending={disableCustomerEmail}
									isSms={this.isSMS()}
								/>
								<TermsOfService
									supplierCodes={confirmationInformation.BookingSupplierCodes}
									cocMap={cocMap}
									disabled={disabled}
									onChange={this.handleChangeField}
									checked={data.termsOfServiceCheck}
								/>
							</div>
						)}
						<RefreshIndicator
							size={36}
							top={0}
							left={0}
							status={disabled ? 'loading' : 'hide'}
							style={inlineStyles.refreshIndicator}
						/>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtBookingConfirmOrderSnackBar"
					open={
						data.alertText !== '' && data.bookingConfirmWarningMessages === ''
					}
					message={data.alertText}
					onClose={this.handleSnackBarClose}
				/>
				{data.bookingBillingWarningMessages !== '' && (
					<ExtendedSnackbar
						id="srtBookingConfirmWarningMessages"
						open={data.bookingConfirmWarningMessages !== ''}
						message={data.bookingConfirmWarningMessages}
						onClose={() => {
							this.setState((state) => ({
								data: state.data.merge({ bookingConfirmWarningMessages: '' }),
							}));
						}}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	cocMap: getConditionsOfCarriageMap(
		state.getIn(['settings', 'ws.interface.conditionsofcarriage_fqps']),
		state.getIn(['shopping', 'query', 'locale']),
	),
	disableCustomerEmailSending:
		state.getIn(['settings', 'ws.feature.disable_customer_email_sending']) ===
		'true',
	ignoreDisableEmailSendingSuppliers: state.getIn([
		'settings',
		'ws.feature.ignore_disable_email_sending_suppliers',
	])
		? state
				.getIn([
					'settings',
					'ws.feature.ignore_disable_email_sending_suppliers',
				])
				.split(',')
				.map((item) => item)
		: null,
});

const mapDispatchToProps = (dispatch) => ({
	onConfirmSuccess: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(ConfirmOrderDialog));
