import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import {
	usePopupState,
	bindTrigger,
	bindPopover,
} from 'material-ui-popup-state/hooks';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import { styled } from '@mui/system';
import TwoColRow from './TwoColRow';
import ElapsedTime from './ElapsedTime';
import './fareItinerary.css';
import messages from './messagesFareItinerary';
import IntermediateStops from './IntermediateStops';
import { gaEvent } from '../../../utils/googleAnalytics';

const StyledTypography = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2),
}));

const ItineraryDetails = ({
	travelSegmentID,
	departStation,
	arriveStation,
	departTime,
	arriveTime,
	equipmentDesignator,
	carrierLogo,
	connectionTimeText,
	unconfirmedSchedule,
	duration,
	carbonOffset,
	children,
	intermediateTravelPoints,
	warnings,
}) => {
	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'needHelpPopover',
	});
	const carrierLogoUrl = `${process.env.API_HOST}${process.env.BASE_PATH_SUFFIX}resources/images/logos/${carrierLogo}`;

	const elapsedTime = (
		<ElapsedTime
			duration={duration}
			unconfirmedSchedule={unconfirmedSchedule}
		/>
	);

	return (
		<div id={`ItineraryDetails_${travelSegmentID}`}>
			{!!connectionTimeText && (
				<div styleName="connectionTime">{connectionTimeText}</div>
			)}
			{!!departStation && (
				<TwoColRow firstColLabel={departStation} secondColLabel={departTime} />
			)}
			{!!arriveStation && (
				<TwoColRow firstColLabel={arriveStation} secondColLabel={arriveTime} />
			)}
			{carbonOffset ? (
				<TwoColRow firstColLabel={children} secondColLabel={elapsedTime} />
			) : (
				<div styleName="rightAlign">{elapsedTime}</div>
			)}
			<TwoColRow
				firstColLabel={equipmentDesignator}
				secondColLabel={
					<img role="presentation" src={carrierLogoUrl} styleName="logo" />
				}
				warnings={warnings}
				styleNameRow="equipmentDesignator"
			/>
			{intermediateTravelPoints?.length > 0 && (
				<>
					<Button
						variant="contained"
						id={`srtViewStops_${travelSegmentID}`}
						onClick={popupState.isOpen ? gaEvent('shoppingViewStops') : ''}
						{...bindTrigger(popupState)}
					>
						<FormattedMessage {...messages.btnViewStops} />
					</Button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<StyledTypography component="span" variant="body2">
							<IntermediateStops intermediateStops={intermediateTravelPoints} />
						</StyledTypography>
					</Popover>
				</>
			)}
		</div>
	);
};

ItineraryDetails.propTypes = {
	travelSegmentID: PropTypes.string,
	departStation: PropTypes.string,
	arriveStation: PropTypes.string,
	departTime: PropTypes.string,
	arriveTime: PropTypes.string,
	equipmentDesignator: PropTypes.string,
	carrierLogo: PropTypes.string,
	connectionTimeText: PropTypes.string,
	unconfirmedSchedule: PropTypes.bool,
	duration: PropTypes.string,
	children: PropTypes.node,
	carbonOffset: PropTypes.string,
	intermediateTravelPoints: PropTypes.array,
	warnings: PropTypes.array,
};

export default ItineraryDetails;
