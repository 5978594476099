import { defineMessages } from 'react-intl';

export default defineMessages({
	lblSelectEmail: {
		id: 'app.booking.sendToEmail.selectEmail',
		defaultMessage: 'Select Email',
	},
	lblEmailWillBeSentStatement: {
		id: 'app.booking.sendToEmail.emailWillBeSent',
		defaultMessage:
			'An email containing ticket information will be sent to the following email address:',
	},
	lblAlternateEmail: {
		id: 'app.booking.sendToEmail.alternateEmail',
		defaultMessage: 'Alternate Email',
	},
});
