import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messagesShopping';

export const TRIP_TYPE_RETURN = 0;
export const TRIP_TYPE_SINGLE = 2;
export const TRIP_TYPE_SEASON = 4;
export const AUTOCOMPLETE_MIN_LENGTH = 3;
export const ADULT_PAX_AGE = 30;
export const CORPORATE = 'CORPORATE';
export const UK_CORPORATE = 'UK_CORPORATE';
export const UK_TADA = 'UK_TADA';
export const PROMOTION = 'PROMOTION';

/*
TripTypeEnum.RoundTrip=Round Trip
TripTypeEnum.OpenReturn=Return
TripTypeEnum.OneWay=One Way
TripTypeEnum.MultiCity=MultiCity
TripTypeEnum.SeasonPass=Season Pass
 */
export const determineTripType = (strTripType) => {
	let type = -1;
	switch (strTripType) {
		case 'Round Trip':
			type = TRIP_TYPE_RETURN;
			break;
		case 'Return':
			type = TRIP_TYPE_RETURN;
			break;
		case 'One Way':
			type = TRIP_TYPE_SINGLE;
			break;
		case 'MultiCity':
			// This is not implemented yet so send -1 to indicate invalid type
			type = -1;
			break;
		case 'Season Pass':
			type = TRIP_TYPE_SEASON;
			break;
		default:
			type = -1;
	}
	return type;
};

export const extractOrderDetails = (
	selectedFares,
	shoppingLegsTransactionFee,
	accountDetails,
	tdoFee,
) => {
	let orderTotalPrice = 0;
	let totalPriceTicket = -1;
	let totalPriceReservation = -1;
	let totalPriceTicketedRsv = -1;
	let totalPriceTax = -1;
	let totalPriceTaxNational = -1;
	let totalPriceTaxStateProv = -1;
	let totalPriceFee = -1;
	let totalPriceCorpDiscount = -1;
	let totalPriceTravelPass = -1;
	let totalPriceTransactionFee = -1;
	let totalPriceCurrency = null;

	selectedFares.forEach((selectedFare) => {
		const leg = selectedFare.get('leg');
		const campaignInfp = selectedFare.getIn(['fare', 'campaignInfo'])?.toJS();
		const campaignPrice =
			!campaignInfp?.message && campaignInfp?.success
				? campaignInfp?.value?.value
				: '';
		leg.get('ShoppingLegSolutions').forEach((item) => {
			item.get('ShoppingLegFares').forEach((legFare) => {
				legFare.get('ShoppingLegFareItinInfo').forEach((itin) => {
					itin.get('ShoppingLegFarePricesInfo').forEach((currPrice) => {
						const pricesValue = currPrice.get('ShoppingLegFarePricesValue');
						const resultantPrice =
							campaignPrice && campaignPrice !== '0.00' && campaignPrice !== ''
								? campaignPrice
								: pricesValue;
						switch (currPrice.get('ShoppingLegFarePricesType')) {
							case 'TICKET':
								totalPriceTicket =
									totalPriceTicket === -1
										? resultantPrice
										: totalPriceTicket + resultantPrice;
								break;
							case 'RESERVATION':
								totalPriceReservation =
									totalPriceReservation === -1
										? pricesValue
										: totalPriceReservation + pricesValue;
								break;
							case 'TICKETED_RES':
								totalPriceTicketedRsv =
									totalPriceTicketedRsv === -1
										? pricesValue
										: totalPriceTicketedRsv + pricesValue;
								break;
							case 'TAX':
								totalPriceTax =
									totalPriceTax === -1
										? pricesValue
										: totalPriceTax + pricesValue;
								break;
							case 'TAX_NATIONAL':
								totalPriceTaxNational =
									totalPriceTaxNational === -1
										? pricesValue
										: totalPriceTaxNational + pricesValue;
								break;
							case 'TAX_STATEPROVINCE':
								totalPriceTaxStateProv =
									totalPriceTaxStateProv === -1
										? pricesValue
										: totalPriceTaxStateProv + pricesValue;
								break;
							case 'FEE':
								totalPriceFee =
									totalPriceFee === -1
										? pricesValue
										: totalPriceFee + pricesValue;
								break;
							case 'CORP_DISCOUNT':
								totalPriceCorpDiscount =
									totalPriceCorpDiscount === -1
										? pricesValue
										: totalPriceCorpDiscount + pricesValue;
								break;
							case 'TRAVEL_PASS':
								totalPriceTravelPass =
									totalPriceTravelPass === -1
										? pricesValue
										: totalPriceTravelPass + pricesValue;
								break;
							default:
						}
					});
				});
				orderTotalPrice += parseFloat(
					campaignPrice && campaignPrice !== '0.00' && campaignPrice !== ''
						? campaignPrice
						: legFare.get('ShoppingLegFareTotalPrice'),
				);
				totalPriceCurrency = legFare.get('ShoppingLegFareTotalPriceCurrency');
			});
		});
	});

	if (shoppingLegsTransactionFee || shoppingLegsTransactionFee === 0) {
		totalPriceTransactionFee = shoppingLegsTransactionFee;
		orderTotalPrice += totalPriceTransactionFee;
	}

	let totalFees = 0.0;
	let bookingFee;

	if (accountDetails) {
		const feeType1 = accountDetails.get('AccountManagementFeeTypeEnumVal1');
		const feeAmount1 = accountDetails.get('AccountManagementFeeTypeAmount1');
		const feeMinAmount1 = accountDetails.get(
			'AccountManagementFeeTypeMinAmount1',
		);
		const feeType2 = accountDetails.get('AccountManagementFeeTypeEnumVal2');
		const feeAmount2 = accountDetails.get('AccountManagementFeeTypeAmount2');
		const feeMinAmount2 = accountDetails.get(
			'AccountManagementFeeTypeMinAmount2',
		);

		if (feeType1 && feeAmount1) {
			if (feeType1 === 'fixed_amount') {
				bookingFee = parseFloat(feeAmount1);
				orderTotalPrice += bookingFee;
				totalFees += bookingFee;
			} else if (feeType1 === 'percentage') {
				bookingFee = Math.max(
					orderTotalPrice / (1.0 - feeAmount1 / 100.0) - orderTotalPrice,
					feeMinAmount1,
				);
				orderTotalPrice += bookingFee;
				totalFees += bookingFee;
			}
		}
		if (feeType2 && feeAmount2) {
			if (feeType2 === 'fixed_amount') {
				bookingFee = parseFloat(feeAmount2);
				orderTotalPrice += bookingFee;
				totalFees += bookingFee;
			} else if (feeType2 === 'percentage') {
				bookingFee = Math.max(
					orderTotalPrice / (1.0 - feeAmount2 / 100.0) - orderTotalPrice,
					feeMinAmount2,
				);
				orderTotalPrice += bookingFee;
				totalFees += bookingFee;
			}
		}
	}

	if (tdoFee) {
		orderTotalPrice += tdoFee;
	}

	return {
		orderTotalPrice,
		totalPriceTicket,
		totalPriceReservation,
		totalPriceTicketedRsv,
		totalPriceTaxNational,
		totalPriceTaxStateProv,
		totalPriceFee,
		totalPriceCorpDiscount,
		totalPriceTravelPass,
		totalPriceTransactionFee,
		totalFees,
		totalPriceCurrency,
		tdoFee,
	};
};

export const checkAccommodations = (optionalPrices) =>
	optionalPrices.map((item) => item.type === 'ACCOMMODATION');

export const consolidateReservable = (seatPreferences) => {
	// build consolidated seat assignments based on the intersection of outbound and inbound
	let reservable = null;

	const arrIncluded = seatPreferences.filter(
		(item) => item.reservable === 'INCLUDED',
	);

	const arrMandatory = seatPreferences.filter(
		(item) => item.reservable === 'MANDATORY',
	);

	const arrNotPossible = seatPreferences.filter(
		(item) => item.reservable === 'NOT_POSSIBLE',
	);

	if (seatPreferences.length === 1) {
		reservable = seatPreferences[0].reservable;
	} else if (arrIncluded.length === seatPreferences.length) {
		reservable = 'INCLUDED';
	} else if (arrMandatory.length === seatPreferences.length) {
		reservable = 'MANDATORY';
	} else if (arrNotPossible.length === seatPreferences.length) {
		reservable = 'NOT_POSSIBLE';
	} else {
		reservable = 'OPTIONAL';
	}

	return reservable;
};

export const getSeatPreferencesByType = (seatPreferences) => {
	const preferences = {
		directionOptions: [],
		positionOptions: [],
		coachOptions: [],
		levelOptions: [],
		layoutOptions: [],
		otherOptions: [],
		seatmapOptions: [],
	};

	if (seatPreferences.preferences) {
		seatPreferences.preferences.forEach(({ name, description, type }) => {
			const pref = {
				value: name,
				description,
			};
			const options = preferences[`${type.toLowerCase()}Options`];
			if (options) {
				options.push(pref);
			}
		});
	}

	return preferences;
};

export const filterSeatPreferencesByCarrierAndClass = (
	seatPreferences,
	marketingCarrierName,
	equipmentType,
	serviceClass,
	travelSegmentID,
) => {
	const filteredPreferences =
		seatPreferences.preferences?.filter(
			(preference) =>
				(preference.marketingCarrier === marketingCarrierName ||
					marketingCarrierName === '') &&
				(marketingCarrierName === '' ||
					preference.equipmentType === equipmentType) &&
				(preference.travelSegmentIDRef === null ||
					preference.travelSegmentIDRef === travelSegmentID) &&
				preference.serviceClass === serviceClass,
		) ?? [];

	return { ...seatPreferences, preferences: filteredPreferences };
};

export const determineReservationScenario = (
	reservables,
	optionalPrices,
	paxLength,
) => {
	let legBasedReservation = false;
	let travelSegmentBasedReservation = false;
	let passengerBasedReservation = false;
	let mixedBasedReservation = false;
	const reservableTravelSegments = [];

	reservables.forEach((reservable) => {
		if (
			reservable.reservable === 'OPTIONAL' &&
			reservable.linkedPasseger === 'PAX_SPEC_0'
		) {
			reservableTravelSegments.push(reservable.linkedTravelSegment);
		}
	});

	optionalPrices.forEach((optionalPrice) => {
		if (optionalPrice.type === 'RESERVATION') {
			// Look for Optional price valid for all passengers (suggesting a ticketable fare supporting all)
			if (optionalPrice.linkedPassengers.length === paxLength) {
				// Either applies to whole leg or segment only
				if (optionalPrice.maxQuantity === optionalPrice.minQuantity) {
					if (optionalPrice.maxQuantity === 1) {
						travelSegmentBasedReservation = true;
					} else if (
						optionalPrice.maxQuantity === reservableTravelSegments.length
					) {
						legBasedReservation = true;
					}
				}
				// Either per Person reservation or Mixed
			} else if (optionalPrice.linkedPassengers.length > 1) {
				mixedBasedReservation = true;
			} else {
				passengerBasedReservation = true;
			}
		}
	});

	return {
		legBasedReservation,
		travelSegmentBasedReservation,
		mixedBasedReservation,
		passengerBasedReservation,
	};
};

export const calculateReservationPrice = (
	reservationStrategy,
	optionalPrices,
	paxID,
	tsID,
	paxLength,
) => {
	let reservationPrice = 0.0;
	optionalPrices.forEach((optionalPrice) => {
		if (optionalPrice.type === 'RESERVATION') {
			if (
				reservationStrategy.passengerBasedReservation ||
				reservationStrategy.travelSegmentBasedReservation
			) {
				if (paxID === -1) {
					const matchedSegment = optionalPrice.linkedTravelSegments.filter(
						(linkedSegment) => linkedSegment.travelSegment === tsID,
					);
					const price = reservationStrategy.passengerBasedReservation
						? paxLength * parseFloat(optionalPrice.value)
						: optionalPrice.value;
					reservationPrice = matchedSegment ? price : 0.0;
				} else {
					const matchedPassenger = optionalPrice.linkedPassengers.filter(
						(linkedPassenger) => linkedPassenger.passenger === paxID,
					);

					const matchedSegment = optionalPrice.linkedTravelSegments.filter(
						(linkedSegment) => linkedSegment.travelSegment === tsID,
					);

					reservationPrice =
						matchedPassenger && matchedSegment ? optionalPrice.value : 0.0;
				}
			} else if (reservationStrategy.legBasedReservation) {
				reservationPrice = optionalPrice.value;
			}
		}
	});
	return reservationPrice;
};

export const getReservable = (seatReservables, segmentId) => {
	let reservable = seatReservables.find(
		(res) => res.linkedTravelSegment === segmentId,
	);

	reservable = !reservable ? { reservable: 'NOT_POSSIBLE' } : reservable;

	return reservable;
};

export const getTravelCardIds = (travelCards) =>
	!travelCards.isEmpty()
		? travelCards.map((travelCard) => travelCard.get('ID')).join(':')
		: undefined;

export const passengerAges = (formatMessage) => {
	const ages = [];
	for (let i = 1; i <= 65; ++i) {
		switch (i) {
			case 26:
				ages.push({
					label: formatMessage(messages.lblAdult26_54),
					value: ADULT_PAX_AGE,
				});
				i += 28; // we increase by 28 to loop from 55
				break;
			case 65:
				ages.push({ label: '65+', value: 65 });
				break;
			default:
				ages.push({ label: i, value: i });
		}
	}
	return ages;
};

export const contactType = [
	{
		label: <FormattedMessage {...messages.lblContactTypeBusiness} />,
		value: 'BUSINESS',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeHome} />,
		value: 'HOME',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeMobile} />,
		value: 'MOBILE',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeAgency} />,
		value: 'AGENCY',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeHotel} />,
		value: 'HOTEL',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeCommercial} />,
		value: 'COMMERCIAL',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeDestination} />,
		value: 'DESTINATION',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeAssistant} />,
		value: 'ASSISTANT',
	},
	{
		label: <FormattedMessage {...messages.lblContactTypeUnknown} />,
		value: 'UNKNOWN',
	},
];

export const genderType = [
	{ label: <FormattedMessage {...messages.lblFemale} />, value: 'FEMALE' },
	{ label: <FormattedMessage {...messages.lblMale} />, value: 'MALE' },
];

export const additionalRailcards = (intl) => {
	const { formatMessage } = intl;
	return {
		ATOC_UK_CORPORATE: {
			RailCardProgram: 'UK_CORPORATE',
			RailCardType: 'DISCOUNT_CARD',
			RailCardDesc: formatMessage(messages.lblUKCorporateFare),
			RailCardSupplier: 'ATOC',
			RailCardIsIDRequired: true,
		},
		ATOC_UK_TADA: {
			RailCardProgram: 'UK_TADA',
			RailCardType: 'DISCOUNT_CARD',
			RailCardDesc: formatMessage(messages.lblTravelAgentRate),
			RailCardSupplier: 'ATOC',
			RailCardIsIDRequired: true,
		},
	};
};

export const constructFareFilterFunction =
	(activeFilters) =>
	({ ShoppingLegFareCodeInfo }) => {
		const categories = [];
		if (activeFilters.type.length > 0) {
			activeFilters.type.forEach((item) => {
				categories.push(item);
			});
		}

		if (activeFilters.cabin.length > 0) {
			activeFilters.cabin.forEach((item) => {
				categories.push(item);
			});
		}

		if (activeFilters.schedule.length > 0) {
			activeFilters.schedule.forEach((item) => {
				categories.push(item);
			});
		}

		if (categories.length > 0) {
			const isShown = ShoppingLegFareCodeInfo.some((item) => {
				let isShownFareCode = true;
				if (activeFilters.type.length > 0) {
					isShownFareCode = categories.indexOf(item.fareTypeClass) > -1;
				}

				if (activeFilters.cabin.length > 0) {
					isShownFareCode =
						isShownFareCode &&
						categories.indexOf(item.ShoppingLegFarePrimaryColumnClass) > -1;
				}

				if (activeFilters.schedule.length > 0) {
					isShownFareCode =
						isShownFareCode && categories.indexOf(item.scheduleClass) > -1;
				}
				return isShownFareCode;
			});

			return isShown;
		}
		return true;
	};
