import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function DropdownIndicator(props) {
	const { getStyles, innerProps } = props; // eslint-disable-line react/prop-types
	return (
		<div {...innerProps} style={getStyles('dropdownIndicator', props)}>
			<ArrowDropDownIcon />
		</div>
	);
}
