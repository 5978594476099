import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ErrorBoundary } from 'react-error-boundary';

import OrderBillingSummary from '../OrderBillingSummary/OrderBillingSummary';
import FulfillmentInformation from '../FulfillmentInformation/FulfillmentInformation';
import OrderDetailsDetails from '../../../components/OrderDetailsDetails/OrderDetailsDetails';
import OrderTitle from '../OrderTitle/OrderTitle';
import { checkCurrentTDO } from '../../utils';
import { gaEvent } from '../../../../utils/googleAnalytics';
import { getFeatureTogglesApi } from '../../../../Admin/apiAdmin';

import {
	orderStatuses,
	bookingValueDocStatus,
} from '../../../../constants/constants';

import messages from './messages';
import styles from './styles';
import ErrorFallBack from '../ErrorFallBack';

const StyledAccordion = styled(Accordion)(() => ({
	...styles.expansionPanel,
	'&.Mui-expanded': {
		...styles.expansionPanelExpanded,
	},
	'.MuiAccordionSummary-content': {
		alignItems: 'center',
	},
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
	...styles.summary,
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
	...styles.panelDetails,
}));

const StyledDiv = styled('div')(() => ({
	...styles.tableWrapper,
}));

const StyledTable = styled(Table)(() => ({
	...styles.bookingTable,
}));

const StyledLink = styled('a')(() => ({
	...styles.linkTitle,
}));

const StyledGrid = styled(Grid)(() => ({
	...styles.buttons,
}));

const StyledRedButton = styled(Button)(() => ({
	...styles.redButton,
}));

const StyledOrangeButton = styled(Button)(() => ({
	...styles.orangeButton,
}));

const OrderDetails = ({
	order,
	booking,
	enableReturns,
	disableAllowAnyCardCollectButton,
	disableCustomerEmailConfirmation,
	disableCustomerEmailSending,
	ignoreDisableEmailSendingSuppliers,
	getTDOType,
	handleOpenOSDMCancelOrderDialog,
	handleAllowAnyCardCollect,
	handleOpenAuthorizeRefundDialog,
	handleOpenCancelOrderDialog,
	handleOpenConfirmedCardDialog,
	handleOpenConfirmOrderDialog,
	handleOpenEmailConfirmationDialog,
	handleOpenExchangeOrderDialog,
	handleOpenResendTicketDialog,
	handleOpenReturnCouponsDialog,
	handleOpenStationInfoDialog,
	handleOpenTicketDelivery,
	handleOpenTrackingInfoDialog,
	handleOpenVoidOrderDialog,
	handleOpenAmendSeatReservationDialog,
	handleSupplierNotesDialogDisplay,
	onAmendCTR,
	showAmendCTR,
	showExchangeOrderButton,
	showVoidOrderButton,
	voidIsExpired,
	isProcessingAction,
	intl: { formatMessage },
	availableSuppliers,
}) => {
	const [expanded, setExpanded] = useState(
		order.BookingOrderStatus !== orderStatuses.REPLACED,
	);
	const [data, setData] = useState({ alertText: '', isProcessing: false });
	const [seasonChangeoverFeature, setSeasonChangeoverFeature] = useState({});

	const hasSNCF = availableSuppliers?.includes('SNCF');

	const getFeatureToggles = () => {
		setData({ ...data, isProcessing: true });
		getFeatureTogglesApi(
			(response) => {
				setData((prev) => ({
					...prev,
					alertText: response,
					isProcessing: false,
				}));
			},
			(response) => {
				setSeasonChangeoverFeature(response);
				setData((prev) => ({ ...prev, alertText: '', isProcessing: false }));
			},
		);
	};

	useEffect(() => {
		getFeatureToggles();
	}, []);

	const isSeasonChangeoverFeatureActive =
		seasonChangeoverFeature['season.changeover.workflow.feature'];

	const getCancelObj = () => {
		const cancelObj = {
			cancelMessage: '',
			cancelable: false,
			cancelButtonText: '',
		};
		cancelObj.cancelable =
			order.BookingOrderCancelInfo.BookingOrderCancelIsCancel ||
			order.BookingOrderCancelInfo.BookingOrderCancelIsPartialCancel;

		if (order.BookingOrderInventoryCanceled) {
			cancelObj.cancelMessage = (
				<FormattedMessage {...messages.lblOrderCanceled} />
			);
		} else if (
			!order.BookingOrderCancelInfo.BookingOrderCancelInfoRefundEligible &&
			!order.BookingOrderCancelInfo.BookingOrderCancelInfoCancelReqForRefund
		) {
			cancelObj.cancelMessage = (
				<FormattedMessage {...messages.lblOrderCantCancel} />
			);
		}

		cancelObj.cancelButtonText = (
			<FormattedMessage {...messages.btnCancelOrder} />
		);

		return cancelObj;
	};

	const checkConfirmOrder = () => {
		let enableConfirmOrder = false;
		const isBalanced = booking.BookingStatus === 'BALANCED';
		const isOrderNotConfirmed = validate.isEmpty(order.BookingValueDocLocator);
		if (
			isBalanced &&
			isOrderNotConfirmed &&
			order.BookingOrderStatus === orderStatuses.BOOKED
		)
			enableConfirmOrder = true;

		return enableConfirmOrder;
	};

	const checkTrackingInfo = () => {
		const { BookingOrderStatus } = order;
		const tdo = checkCurrentTDO(order);
		const validBooking = [
			orderStatuses.BOOKED,
			orderStatuses.TICKETED,
			orderStatuses.CONFIRMED,
		].includes(BookingOrderStatus);
		const isMailTdo = !!tdo && ['TBM', 'TBO', 'TBX'].includes(tdo.Code);
		return validBooking && isMailTdo;
	};

	const determineConfType = () => {
		let confType = null;
		if (
			[orderStatuses.CONFIRMED, orderStatuses.TICKETED].includes(
				order.BookingOrderStatus,
			)
		) {
			confType = {
				message: <FormattedMessage {...messages.lblConfirmationNotRequired} />,
				hasInfo: false,
			};

			if (
				order.BookingOrderConfirmationTypeInfo &&
				order.BookingOrderConfirmationTypeInfo.BookingOrderConfirmationType
					.length
			) {
				let cardType = '';
				switch (
					order.BookingOrderConfirmationTypeInfo.BookingOrderConfirmationType
				) {
					case 'CC':
						cardType = (
							<FormattedMessage {...messages.lblConfirmationCreditCard} />
						);
						confType.hasInfo = true;
						break;
					case 'DC':
						cardType = (
							<FormattedMessage {...messages.lblConfirmationDebitCard} />
						);
						confType.hasInfo = true;
						break;
					case 'LC':
						cardType = (
							<FormattedMessage {...messages.lblConfirmationLoyaltyCard} />
						);
						confType.hasInfo = true;
						break;
					default:
				}
				confType.message = (
					<FormattedMessage
						{...messages.lblConfirmationUsing}
						values={{ cardType }}
					/>
				);
			}
		}
		return confType;
	};

	const checkReturnCoupons = () => {
		const isOrderCompatible =
			enableReturns &&
			[orderStatuses.TICKETED, orderStatuses.CONFIRMED].includes(
				order.BookingOrderStatus,
			);
		const valDocStatusCompatible = [
			bookingValueDocStatus.ISSUED,
			bookingValueDocStatus.RETURNED_PARTIAL,
			bookingValueDocStatus.READY,
		].includes(order.BookingValueDocStatus);
		const isCarnetFare = order?.BookingLegFareRulesDetails?.some((item) =>
			item?.ShoppingFareRulesFareClasses?.toLowerCase().includes('carnet'),
		);
		const cancelReason =
			order?.BookingOrderCancelInfo?.BookingOrderInternalCancellationReason;
		// currently cannot check if the jounery is SNCF or not by station's suppliers
		// const fareGroups = order?.BookingOrderLegDetails?.ticketableFareGroups;
		// eslint-disable-next-line max-len
		// const isSNCF = fareGroups?.some((faregroup) => faregroup.arriveStationCodeSuppliers.includes('SNCF') && faregroup.departStationCodeSuppliers.includes('SNCF'));

		return (
			isOrderCompatible &&
			valDocStatusCompatible &&
			!isCarnetFare &&
			((cancelReason === 'RELEASE_SEAT' && hasSNCF) || !hasSNCF)
		);
	};

	const checkAuthorizeRefund = () => {
		const { BookingOrderStatus, BookingValueDocStatus } = order;
		const isOrderCompatible = BookingOrderStatus === orderStatuses.RETURNED;
		const valDocStatusCompatible =
			BookingOrderStatus === orderStatuses.TICKETED &&
			BookingValueDocStatus === bookingValueDocStatus.RETURNED_PARTIAL;
		const isCarnetFare = order?.BookingLegFareRulesDetails?.some((item) =>
			item?.ShoppingFareRulesFareClasses?.toLowerCase().includes('carnet'),
		);

		return (isOrderCompatible || valDocStatusCompatible) && !isCarnetFare;
	};

	const handleExpanded = (event, isExpanded) => {
		gaEvent(
			isExpanded ? 'expandOrderDetailsSection' : 'collapseOrderDetailsSection',
		);
		setExpanded(isExpanded);
	};

	const handleOpenCancelDialogs = (orderId) =>
		order.BookingOrderCancelInfo.BookingOrderOsdmCancellationFlow
			? handleOpenOSDMCancelOrderDialog()
			: handleOpenCancelOrderDialog(orderId);

	const {
		BookingOrderVoidInfo: voidInfo,
		BookingOrderInventoryCanceled: inventoryCanceled,
		BookingOrderStatus: orderStatus,
		BookingOrderID: orderId,
	} = order;
	const cancelObj = getCancelObj();
	const isVoidable =
		!inventoryCanceled &&
		orderStatus !== orderStatuses.CANCELED &&
		voidInfo &&
		voidInfo.BookingOrderIsVoidable;
	const voidExpired = voidIsExpired(voidInfo);
	const needConfirmation = checkConfirmOrder();
	const tdoButtonLabel = getTDOType(order);
	const canSetTrackingInfo = checkTrackingInfo();
	const currTDO = checkCurrentTDO(order) || {};
	const confType = determineConfType();

	return (
		<StyledAccordion
			id={`orderDetails${order.BookingOrderID}`}
			data-testid="accordion"
			expanded={expanded}
			onChange={handleExpanded}
		>
			<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
				<OrderTitle order={order} />
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<StyledDiv>
					<StyledTable>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage {...messages.lblDateBooked} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.lblCarrierLocator} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.lblExternalSystemLocator} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.lblConfirmationInformation} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell data-testid="date-booked">
									{order.BookingOrderDateBooked}
								</TableCell>
								<TableCell data-testid="carrier-locator">
									{order.BookingOrderCarrierLocator}
								</TableCell>
								<TableCell data-testid="system-locator">
									{order.BookingOrderExternalSystemLocator}
								</TableCell>
								<TableCell>
									{confType &&
										(confType.hasInfo ? (
											<StyledLink
												onClick={() => {
													gaEvent('confirmationInformationOpen');
													handleOpenConfirmedCardDialog(orderId);
												}}
											>
												{confType.message}
											</StyledLink>
										) : (
											confType.message
										))}
								</TableCell>
							</TableRow>
						</TableBody>
					</StyledTable>
				</StyledDiv>
				<ErrorBoundary
					fallbackRender={(props) => (
						<ErrorFallBack
							{...props}
							messageComponent={
								<FormattedMessage {...messages.lblOrderDetailsTitle} />
							}
						/>
					)}
				>
					<OrderDetailsDetails
						id={`srtViewDetails_${orderId}`}
						orderId={orderId}
						booking={booking}
						expanded={expanded}
					/>
				</ErrorBoundary>
				<StyledGrid container spacing={2} alignItems="center">
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="remove-padding-top"
					>
						{cancelObj.cancelable ? (
							<StyledRedButton
								variant="contained"
								data-testid="cancel-button"
								id={`srtBookingCancelOrder_${orderId}`}
								fullWidth
								onClick={() => handleOpenCancelDialogs(orderId)}
							>
								{cancelObj.cancelButtonText}
							</StyledRedButton>
						) : (
							cancelObj.cancelMessage
						)}
					</Grid>
					{showVoidOrderButton && isVoidable && (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="remove-padding-top"
						>
							{voidExpired ? (
								<FormattedMessage {...messages.lblOrderCantVoid} />
							) : (
								<StyledOrangeButton
									variant="contained"
									data-testid="void-button"
									id={`srtBookingVoidOrder_${orderId}`}
									fullWidth
									onClick={() => {
										handleOpenVoidOrderDialog(orderId, voidInfo);
									}}
								>
									<FormattedMessage {...messages.btnVoidOrder} />
								</StyledOrangeButton>
							)}
						</Grid>
					)}
					{order.BookingOrderExchangeableTFLocators &&
						showExchangeOrderButton && (
							<>
								{order.BookingSeasonFareDetails &&
									isSeasonChangeoverFeatureActive && (
										<Grid
											item
											xs={12}
											sm={6}
											md={4}
											lg={3}
											className="remove-padding-top"
										>
											<Button
												variant="contained"
												data-testid="srtBookingExchangeOrder"
												id={`srtBookingExchangeOrder_${orderId}`}
												color="primary"
												fullWidth
												onClick={() => {
													handleOpenExchangeOrderDialog(orderId);
												}}
											>
												<FormattedMessage
													{...messages.btnExchangeSeasonTicket}
												/>
											</Button>
										</Grid>
									)}
								{!order.BookingSeasonFareDetails && (
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										lg={3}
										className="remove-padding-top"
									>
										<Button
											variant="contained"
											data-testid="srtBookingExchangeOrder"
											id={`srtBookingExchangeOrder_${orderId}`}
											color="primary"
											fullWidth
											onClick={() => {
												handleOpenExchangeOrderDialog(orderId);
											}}
										>
											<FormattedMessage {...messages.btnExchangeOrder} />
										</Button>
									</Grid>
								)}
							</>
						)}
					{needConfirmation && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Button
								variant="contained"
								id={`srtBookingConfirmOrder_${orderId}`}
								color="primary"
								fullWidth
								onClick={() => {
									handleOpenConfirmOrderDialog(orderId);
								}}
							>
								<FormattedMessage {...messages.btnConfirmOrder} />
							</Button>
						</Grid>
					)}
					{!disableCustomerEmailConfirmation &&
						!disableCustomerEmailSending &&
						[orderStatuses.CONFIRMED, orderStatuses.TICKETED].includes(
							order.BookingOrderStatus,
						) && (
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
								className="remove-padding-top"
							>
								<Button
									variant="contained"
									data-testid="email-confirmation"
									id={`srtBookingEmailConfirmationOrder_${orderId}`}
									fullWidth
									onClick={() => {
										handleOpenEmailConfirmationDialog(orderId);
									}}
								>
									<FormattedMessage {...messages.btnEmailOrderConfirmation} />
								</Button>
							</Grid>
						)}
					{canSetTrackingInfo && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Button
								variant="contained"
								id={`srtBookingEditTrackingInfo_${orderId}`}
								fullWidth
								onClick={() => {
									handleOpenTrackingInfoDialog(orderId);
								}}
							>
								<FormattedMessage {...messages.btnEditTrackingInfo} />
							</Button>
						</Grid>
					)}
					{order.BookingOrderSupplierNotesSupported !== 'NONE' && (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className="remove-padding-top"
						>
							<Button
								variant="contained"
								id={`srtBookingSupplierNotes_${orderId}`}
								color="secondary"
								fullWidth
								onClick={() => {
									handleSupplierNotesDialogDisplay(orderId);
								}}
							>
								<FormattedMessage {...messages.btnSupplierNotes} />
							</Button>
						</Grid>
					)}
				</StyledGrid>

				{order?.BookingOrderSeatReservationAmendmentInfo
					?.BookingOrderSeatReservationAmendmentIsAvailable && (
					<StyledGrid item xs={12} sm={6} md={4} lg={3}>
						<Button
							variant="contained"
							id={`srtAmendSeatReservation_${orderId}`}
							color="primary"
							onClick={() => {
								handleOpenAmendSeatReservationDialog(orderId);
							}}
						>
							<FormattedMessage {...messages.btnAmendSeatReservation} />
						</Button>
					</StyledGrid>
				)}

				<ErrorBoundary
					fallbackRender={(props) => (
						<ErrorFallBack
							{...props}
							messageComponent={
								<FormattedMessage {...messages.lblFulfillmentTitle} />
							}
						/>
					)}
				>
					<FulfillmentInformation
						currTDO={currTDO}
						handleOpenStationInfoDialog={handleOpenStationInfoDialog}
						order={order}
						disableAllowAnyCardCollectButton={disableAllowAnyCardCollectButton}
						showAmendCTR={
							showAmendCTR &&
							order.BookingOrderStatus === orderStatuses.CONFIRMED
						}
						onAmendCTR={onAmendCTR}
						bookingItem={booking.queryItems}
						tdoButtonLabel={tdoButtonLabel}
						amendCTRText={formatMessage(messages.btnAmendCTR)}
						isProcessingAction={isProcessingAction}
						returnCoupons={checkReturnCoupons()}
						authorizeRefund={checkAuthorizeRefund()}
						handleOpenReturnCouponsDialog={handleOpenReturnCouponsDialog}
						handleOpenAuthorizeRefundDialog={handleOpenAuthorizeRefundDialog}
						handleOpenResendTicketDialog={handleOpenResendTicketDialog}
						handleOpenTicketDelivery={handleOpenTicketDelivery}
						handleAllowAnyCardCollect={handleAllowAnyCardCollect}
						disableCustomerEmailSending={disableCustomerEmailSending}
						ignoreDisableEmailSendingSuppliers={
							ignoreDisableEmailSendingSuppliers
						}
					/>
				</ErrorBoundary>
				<ErrorBoundary
					fallbackRender={(props) => (
						<ErrorFallBack
							{...props}
							messageComponent={
								<FormattedMessage {...messages.lblBillingSummary} />
							}
						/>
					)}
				>
					<OrderBillingSummary booking={booking} orderId={orderId} />
				</ErrorBoundary>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
};

OrderDetails.propTypes = {
	booking: PropTypes.object.isRequired,
	disableAllowAnyCardCollectButton: PropTypes.object.isRequired,
	disableCustomerEmailConfirmation: PropTypes.bool.isRequired,
	disableCustomerEmailSending: PropTypes.bool.isRequired,
	enableReturns: PropTypes.bool.isRequired,
	getTDOType: PropTypes.func.isRequired,
	handleAllowAnyCardCollect: PropTypes.func.isRequired,
	handleOpenAuthorizeRefundDialog: PropTypes.func.isRequired,
	handleOpenCancelOrderDialog: PropTypes.func.isRequired,
	handleOpenConfirmedCardDialog: PropTypes.func.isRequired,
	handleOpenConfirmOrderDialog: PropTypes.func.isRequired,
	handleOpenEmailConfirmationDialog: PropTypes.func.isRequired,
	handleOpenExchangeOrderDialog: PropTypes.func.isRequired,
	handleOpenResendTicketDialog: PropTypes.func.isRequired,
	handleOpenReturnCouponsDialog: PropTypes.func.isRequired,
	handleOpenStationInfoDialog: PropTypes.func.isRequired,
	handleOpenTicketDelivery: PropTypes.func.isRequired,
	handleOpenTrackingInfoDialog: PropTypes.func.isRequired,
	handleOpenVoidOrderDialog: PropTypes.func.isRequired,
	handleSupplierNotesDialogDisplay: PropTypes.func.isRequired,
	handleOpenAmendSeatReservationDialog: PropTypes.func.isRequired,
	ignoreDisableEmailSendingSuppliers: PropTypes.array.isRequired,
	intl: PropTypes.object,
	onAmendCTR: PropTypes.func.isRequired,
	order: PropTypes.object.isRequired,
	showAmendCTR: PropTypes.bool.isRequired,
	showExchangeOrderButton: PropTypes.bool.isRequired,
	showVoidOrderButton: PropTypes.bool.isRequired,
	voidIsExpired: PropTypes.func.isRequired,
	isProcessingAction: PropTypes.bool.isRequired,
	availableSuppliers: PropTypes.array,
	handleOpenOSDMCancelOrderDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	availableSuppliers: state
		.getIn(['shopping', 'query', 'availableSuppliers'])
		.toJS(),
});

export { OrderDetails as OrderDetailsAlias };

export default connect(mapStateToProps, null)(injectIntl(OrderDetails));
