export default {
	buttons: {
		margin: 10,
	},
	paperContainer: {
		marginTop: 16,
		marginBottom: 10,
		padding: '0 15px 15px 15px',
	},
};
