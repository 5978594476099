import { lightBlue, midBlue, white } from '../../../colors';

export default {
	fareDetails: {
		width: 340,
		maxWidth: 340,
		borderRadius: 5,
		minHeight: 20,
		backgroundColor: lightBlue,
		marginTop: 5,
	},
	fareStyles: {
		marginLeft: 10,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	seasonFareStyles: {
		marginTop: 0,
		marginBottom: 0,
	},
	splitTicketLogoStyle: {
		position: 'absolute',
		top: 70,
		right: 10,
	},
	splitTicketLogoContainerStyle: {
		position: 'relative',
	},
	changeSelectionButton: {
		paddingLeft: 20,
		paddingRight: 1,
		textTransform: 'capitalize',
	},
	fareDetailsButton: {
		paddingLeft: 1,
		paddingRight: 1,
		textTransform: 'capitalize',
	},
	popoverCloseButton: {
		position: 'absolute',
		right: 10,
		minWidth: 42,
		padding: 5,
	},
	popper: {
		zIndex: 1350,
	},
	amenity: {
		fontSize: '18px !important',
		margin: '0 2px',
		color: midBlue,
	},
	amenityBadge: {
		borderRadius: '15%',
		backgroundColor: midBlue,
		color: white,
		fontSize: 10,
		position: 'relative',
		top: -4,
		padding: 2,
		fontWeight: 700,
	},
	amenityBadgeContainer: {
		display: 'inline-flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	amenities: {
		marginBottom: 3,
	},
};
