import React from 'react';
import PropTypes from 'prop-types';

import DateOfBirth from '../../../../components/DateOfBirth/DateOfBirth';
import PhotoCardView from './PhotoCardView';
import PassportView from './PassportView';

const TravelDocs = (props) => {
	const { travelDocTypes, onChange, errors, travelDocsValues, ...other } =
		props;
	let viewContent = null;

	const handleChange = (name, value) => {
		const updates = { [name]: value };
		onChange(updates);
	};

	if (!travelDocTypes || travelDocTypes.length === 0) {
		viewContent = (
			<div className="row">
				<div className="col-6">
					<DateOfBirth
						{...other}
						onChange={handleChange}
						errorText={errors.DOB}
						dateOfBirth={travelDocsValues.DOB}
					/>
				</div>
			</div>
		);
	} else if (travelDocTypes.get(0).includes('PHOTOCARD')) {
		viewContent = (
			<PhotoCardView
				{...other}
				onChange={handleChange}
				travelDocsValues={travelDocsValues}
				errors={errors}
			/>
		);
	} else {
		viewContent = (
			<PassportView
				{...other}
				onChange={handleChange}
				travelDocsValues={travelDocsValues}
				errors={errors}
				travelDocTypes={travelDocTypes}
			/>
		);
	}

	return viewContent;
};

TravelDocs.propTypes = {
	travelDocsValues: PropTypes.shape({
		gender: PropTypes.string,
		DOB: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
		documentType: PropTypes.string,
		documentNumber: PropTypes.string,
		issueCountry: PropTypes.string,
		documentExpDate: PropTypes.instanceOf(Date),
	}).isRequired,
	travelDocTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
		.isRequired,
	countries: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	errors: PropTypes.object,
	idPrefix: PropTypes.string,
	index: PropTypes.number,
	intl: PropTypes.object,
};

export default TravelDocs;
