import { lightGray, midGray, darkGray, red } from '../../../colors';

export default {
	refresh: {
		marginTop: '10%',
		marginLeft: '45%',
	},
	buttonFix: {
		marginTop: 10,
	},
	dialogPaper: {
		overflowY: 'visible',
		minWidth: '90%',
		margin: 48,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	headerSelectedLabel: {
		paddingRight: 10,
		whiteSpace: 'nowrap',
	},
	headerSelectedRow: {
		height: 'auto',
	},
	headerSelectedAppBar: {
		marginTop: 10,
		marginBottom: 10,
	},
	headerTable: {
		maxWidth: '78vw',
		tableLayout: 'fixed',
	},
	distinctiveText: {
		color: midGray,
	},
	tableRight: {
		textAlign: 'right',
	},
	table: {
		textAlign: 'left',
	},
	passengers: {
		color: darkGray,
		paddingLeft: 23,
	},
	legColumn: {
		width: 50,
		paddingLeft: 10,
		paddingRight: 10,
	},
	borderBalance: {
		borderTop: `1px solid ${lightGray}`,
	},
	balanceMargin: {
		marginTop: 15,
	},
	warningList: {
		listStyle: 'disc outside none',
		marginLeft: 15,
		display: 'list-item',
	},
	warningListItem: {
		padding: 0,
	},
	warning: {
		color: red,
		fontSize: 14,
		fontWeight: 500,
	},
};
