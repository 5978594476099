import { defineMessages } from 'react-intl';

export default defineMessages({
	lblViewDetails: {
		id: 'app.shopping.fare.fareItinerary.viewDetails',
		defaultMessage: 'View Details',
	},
	lblHideDetails: {
		id: 'app.shopping.fare.fareItinerary.hideDetails',
		defaultMessage: 'Hide Details',
	},
	lblMultiple: {
		id: 'app.shopping.fare.fareItinerary.multiple',
		defaultMessage: 'Multiple Carriers',
	},
	lblChanges: {
		id: 'app.shopping.fare.fareItinerary.changes',
		defaultMessage: 'Changes: {nrChanges}',
	},
	lblOverTaken: {
		id: 'app.shopping.fare.fareItinerary.overTaken',
		defaultMessage: '(o)',
	},
	lblUnconfirmedSchedule: {
		id: 'app.shopping.fare.fareItinerary.unconfirmedSchedule',
		defaultMessage: '(u)',
	},
	lblElapsedTime: {
		id: 'app.shopping.fare.fareItinerary.elapsedTime',
		defaultMessage: 'Duration: {elapsedTime}',
	},
	lblCarbonOffset: {
		id: 'app.shopping.fare.fareItinerary.carbonOffset',
		defaultMessage: 'Carbon Offset: {carbonOffset}',
	},
	lblStation: {
		id: 'app.shopping.manageBooking.search.station',
		defaultMessage: 'Station',
	},
	lblArrival: {
		id: 'app.shopping.manageBooking.search.arrival',
		defaultMessage: 'Arrival',
	},
	lblDeparture: {
		id: 'app.shopping.manageBooking.search.departure',
		defaultMessage: 'Departure',
	},
	lblPlatform: {
		id: 'app.shopping.manageBooking.search.platform',
		defaultMessage: 'Platform',
	},
	lblStatus: {
		id: 'app.shopping.manageBooking.search.status',
		defaultMessage: 'Status',
	},
	btnViewStops: {
		id: 'app.shopping.manageBooking.search.viewmessagesStops',
		defaultMessage: 'View Stops',
	},
});
