import validate from 'validate.js';

import {
	addBookingNote,
	addCustomInfo,
	addFeeDiscount,
	addSupplierNote,
	amendCTR,
	applyFeeDiscount,
	bookingSearch,
	confirmOrder,
	deletePersonalInformation,
	getAuthRefunds,
	getCancelOrderDetails,
	getConfirmDetails,
	getCoupons,
	getExchangeOrderDetails,
	getOrderDetails,
	getPaymentData,
	getPaymentDue,
	getCancellationPenalty,
	getRefundDetails,
	getTrackingInfo,
	loadBookingDetails,
	loadEmailConfirmation,
	processExchange,
	resendClaimTicket,
	sendEmailConfirmation,
	sendTicket,
	setAuthRefunds,
	setCoupons,
	setTrackingInfo,
	submitCancelBooking,
	submitOrderCancel,
	submitPayment,
	submitRefund,
	submitPassengerInformation,
	updateCardCollection,
	updatePassengerCustomInformation,
	getJourneysList,
	getRefundOffers,
	confirmRefundOffer,
	confirmBookingChange,
} from '../api';
import { transformCustomInformation } from '../utils';
// eslint-disable-next-line import/no-cycle
import { confirmationOptions, findCard } from './utils';
import { MODE_TYPES } from './components/AllPartModeSelect/constants';
import { getTravelCardIds } from '../Shopping/util';

const dateFormat = {
	day: 'numeric',
	month: 'numeric',
	year: 'numeric',
};

const getConfirmationData = (confirmation) => {
	const confirmationOptionsValues = {
		[confirmationOptions.creditCard]: {
			srtConfirmationCCFirstName: confirmation.firstName,
			srtConfirmationCCLastName: confirmation.lastName,
			srtConfirmationCCMiddleName: confirmation.middleName,
			srtConfirmationCCFirstDigitsCardNumber:
				confirmation.cardNumberFirstSixDigits,
			srtConfirmationCCLastDigitsCardNumber:
				confirmation.cardNumberLastFourDigits,
			srtConfirmationCCExpirationMonth: confirmation.expiryDateMonth,
			srtConfirmationCCExpirationYear: confirmation.expiryDateYear,
		},
		[confirmationOptions.debitCard]: {
			srtConfirmationDCFirstName: confirmation.firstName,
			srtConfirmationDCLastName: confirmation.lastName,
			srtConfirmationDCMiddleName: confirmation.middleName,
			srtConfirmationDCFirstDigitsCardNumber:
				confirmation.cardNumberFirstSixDigits,
			srtConfirmationDCLastDigitsCardNumber:
				confirmation.cardNumberLastFourDigits,
			srtConfirmationDCExpirationMonth: confirmation.expiryDateMonth,
			srtConfirmationDCExpirationYear: confirmation.expiryDateYear,
			srtConfirmationDCSortCode: confirmation.sortCode,
		},
		[confirmationOptions.loyaltyCard]: {
			srtConfirmationLCProgram: confirmation.loyaltyCardProgram,
			srtConfirmationLCIdentifier: confirmation.loyaltyCardIdentifier,
			srtConfirmationLCExpirationMonth: confirmation.expiryDateMonth,
			srtConfirmationLCExpirationDay: confirmation.expiryDateDay,
			srtConfirmationLCExpirationYear: confirmation.expiryDateYear,
		},
	};

	return confirmationOptionsValues[confirmation.type];
};

export const sendEmailConfirmationApi = (data, error, success) => {
	const { booking, email, orderId } = data;
	const order = orderId
		? booking.BookingOrders.find(
				(orderItem) => orderItem.BookingOrderID === orderId,
			)
		: booking.BookingOrders[0];
	const params = {
		...booking.queryItems,
		srtOrderId: orderId || order.BookingOrderID,
		srtBookingRefId: order.BookingValueDocLocator,
		srtEmail: email,
		orderNum: orderId
			? booking.BookingOrders.findIndex(
					(orderItem) => orderItem.BookingOrderID === orderId,
				) + 1
			: 1,
	};

	return sendEmailConfirmation(params, error, success);
};

export const addBookingNoteApi = (data, error, success) => {
	const { queryItems, noteType, noteData } = data;
	const params = {
		...queryItems,
		srtBookingNoteType: noteType,
		srtBookingNoteData: noteData,
	};

	return addBookingNote(params, error, success);
};

export const getRefundOffersApi = (data, error, success) => {
	const params = {
		bookingContext: data.accountContext,
		recordLocator: data.bookingRecordLocator,
	};

	return getRefundOffers(params, error, success);
};

export const confirmRefundOfferApi = (data, error, success) => {
	const params = {
		bookingContext: data.accountContext,
		recordLocator: data.bookingRecordLocator,
		osdmRecordLocator: data.osdmRecordLocator,
	};
	return confirmRefundOffer(data.selectedRefundOfferId, params, error, success);
};

export const confirmBookingChangeApi = (data, error, success) =>
	confirmBookingChange(data, error, success);

export const getJourneysListApi = (data, error, success) =>
	getJourneysList(data, error, success);

export const addSupplierNoteApi = (data, error, success) => {
	const { queryItems, orderId, note } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtSupplierNote: note,
	};

	return addSupplierNote(params, error, success);
};

export const addFeeDiscountApi = (data, error, success) => {
	const { queryItems, feeData, currency, intl } = data;
	const params = {
		...queryItems,
		srtIsFee: feeData.feeDisc === 'fee',
		srtFeeDiscountType: feeData.type,
		srtFeeOrDiscountAmount: feeData.amount,
		srtNotes: feeData.notes,
		srtCurrency: currency,
		srtPostDate: intl.formatDate(new Date(Date.now()), dateFormat),
	};

	return addFeeDiscount(params, error, success);
};

export const bookingSearchApi = (data, error, success) => {
	const { searchData, intl } = data;
	const params = {
		srtBookingFirstName: searchData.firstName,
		srtBookingLastName: searchData.firstLastName,
		srtBookingEmailAddress: searchData.email,
		srtBookingsSearchPhone: searchData.phone,
		srtSmartCard: searchData.smartCardNumber,
		srtBookingSearchDepartDate: !validate.isEmpty(searchData.departureDate)
			? intl.formatDate(searchData.departureDate, dateFormat)
			: '',
		srtRecordLocator: searchData.bookingLocator,
		srtSupplyChannelLocator: searchData.supplierLocator,
		srtCarrierLocator: searchData.carrierLocator,
		srtUniqueTicketNumber: searchData.uniqueTicketNumber,
		srtValueDocumentLocator: searchData.ticketLocator,
		srtReferenceNumber: searchData.referenceNumber,
		srtCustomerNumber: searchData.customerNumber,
		srtPageNumber: 1,
	};

	return bookingSearch(params, error, success);
};

export const loadBookingDetailsApi = (params, error, success) =>
	loadBookingDetails(params, error, success);

export const getAuthRefundsApi = (data, error, success) => {
	const { queryItems, orderId } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
	};

	return getAuthRefunds(params, error, success);
};

export const setAuthRefundsApi = (data, error, success) => {
	const { queryItems, orderId, authRefundData, intl, docLocator } = data;
	const coupons = [];
	const allCoupons =
		authRefundData.authRefundDetails.BookingCouponsTravelSegmentCoupons;
	const couponsWithTF = authRefundData.authRefundDetails.BookingCouponsWithTF;

	Object.entries(authRefundData.authRefunds).forEach(([tsCodeId, count]) => {
		let i = count;
		allCoupons.forEach((segment) => {
			const segTsCodeId = !couponsWithTF
				? segment.BookingCouponsOrigCode + segment.BookingCouponsDestCode
				: segment.BookingCouponsNumber;
			if (
				segTsCodeId === tsCodeId &&
				!segment.BookingCouponsIsAuthorized &&
				(couponsWithTF || i-- > 0)
			) {
				coupons.push(segment.BookingCouponsNumber);
			}
		});
	});

	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtDocLocator: docLocator,
		srtAuthRefundNotes: authRefundData.notes,
		srtAuthRefundCode: authRefundData.code,
		srtAuthRefundDesc: authRefundData.description,
		srtAuthRefundAmount: authRefundData.refundAmount,
		srtAuthRefundDescForRefund: authRefundData.refundDescription,
		srtAuthRefundCancelAmount: authRefundData.penaltyAmount,
		srtAuthRefundCancelCurrency: authRefundData.penaltyCurrency,
		srtCouponId: coupons,
		srtAuthRefundDate: intl.formatDate(new Date(authRefundData.receiptDate)),
	};

	return setAuthRefunds(params, error, success);
};

export const loadEmailConfirmationApi = (data, error, success) => {
	const { queryItems, orderId, bookingOrders } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId || bookingOrders[0].BookingOrderID,
		orderNum: orderId
			? bookingOrders.findIndex(
					(orderItem) => orderItem.BookingOrderID === orderId,
				) + 1
			: 1,
		noScript: true,
	};

	return loadEmailConfirmation(params, error, success);
};

export const getCancelOrderDetailsApi = (data, error, success) => {
	const { queryItems, orderId, voidMode } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtVoidMode: voidMode,
	};

	return getCancelOrderDetails(params, error, success);
};

export const submitCancelBookingApi = (data, error, success) => {
	const {
		queryItems,
		orderId,
		voidMode,
		srtSelectedTickatableFares,
		srtCancellationReason,
		srtReleaseSeat,
		srtOrderCurrency,
	} = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtVoidMode: voidMode,
		srtSelectedTickatableFares,
		srtCancellationReason,
		srtReleaseSeat,
		srtOrderCurrency,
	};
	return submitCancelBooking(params, error, success);
};

export const applyFeeDiscountApi = (data, error, success) => {
	const { queryItems, type, currency, feeOrDiscount } = data;
	const params = {
		...queryItems,
		srtFeeDiscountType: type,
		srtCurrency: currency,
		srtFeeOrDiscountAmount: feeOrDiscount,
		srtIsFee: true,
		srtIsCancel: true,
	};

	return applyFeeDiscount(params, error, success);
};

export const submitOrderCancelApi = (data, error, success) => {
	const {
		cancelCurrency,
		cancelledDueToStrike,
		feeAmount,
		feeType,
		infoRefundPenalty,
		mode,
		orderId,
		penalties,
		queryItems,
		releaseSeat,
		selectedTicketableFareId,
		ticketableFares,
		ticketableFaresCancelable,
		voidMode,
		origin,
		destination,
	} = data;

	let cancelPenalty =
		penalties.size > 0 && infoRefundPenalty ? infoRefundPenalty : 0;

	let tfPenaltyAmount = 0;
	const ticketableFareIdArray = [];
	ticketableFaresCancelable.forEach((item) => {
		const cancelable = voidMode
			? item.get('voidable')
			: mode === MODE_TYPES.ALL ||
				item.get('ticketableFareId') === selectedTicketableFareId;
		tfPenaltyAmount += cancelable
			? Number(item.get('ticketableFarePenalty'))
			: 0;
		if (cancelable) ticketableFareIdArray.push(item.get('ticketableFareId'));
	});

	let isPartialCancel = false;
	let ticketableFareIds;
	const ticketableFaresSize = ticketableFares.size;
	if (
		ticketableFareIdArray.length > 0 &&
		ticketableFareIdArray.length !== ticketableFaresSize
	) {
		isPartialCancel = true;
		cancelPenalty = tfPenaltyAmount;
		ticketableFareIds = ticketableFareIdArray.join(',');
	}
	const balanceDue = `${cancelPenalty}${cancelCurrency}`;
	const orderIdToCancelSpecifier = `${orderId}:${balanceDue}:false`;

	const params = {
		...queryItems,
		srtOrigin: origin,
		srtDestination: destination,
		srtFeeAmount: feeAmount || '0.00',
		feetypes: feeType,
		srtTicketableFareIds: ticketableFareIds,
		srtOrderIdsAndExpectedFees: orderIdToCancelSpecifier,
		srtVoidMode: voidMode,
		srtReleaseSeat: releaseSeat,
		cancelDueToStrike: cancelledDueToStrike,
	};

	return submitOrderCancel(params, error, (response) => {
		if (success) success(response, isPartialCancel);
	});
};

export const getConfirmDetailsApi = (data, error, success) => {
	const { queryItems, orderId } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
	};

	return getConfirmDetails(params, error, success);
};

export const confirmOrderApi = (data, error, success) => {
	const { confirmation, orderId, booking } = data;
	const bookingOrder = booking.BookingOrders.filter(
		(order) => order.BookingOrderID === orderId,
	)[0];
	const legs = bookingOrder.BookingOrderLegDetails.BookingOrderLegs;
	const haveLegs = legs.length > 0;
	const params = {
		...booking.queryItems,
		srtBookingConfType: confirmation.type,
		srtOrderId: orderId,
		...getConfirmationData(confirmation),
		srtPaymentClaimVDEmail: confirmation.vdEmail,
		srtConfirmationTicketLanguage: confirmation.language,
		srtOrigin: haveLegs
			? legs[0].BookingOrderDetailsLegTravelSegmentOrigStationName
			: bookingOrder.BookingOrderDepartureLocation,
		srtDestination: haveLegs
			? legs[0].BookingOrderDetailsLegTravelSegmentDestStationName
			: bookingOrder.BookingOrderDestination,
		srtAreStationCodes: haveLegs ? undefined : true,
	};

	return confirmOrder(params, error, success);
};

export const addCustomInfoApi = (data, error, success) => {
	const {
		queryItems,
		customInformationItems,
		referenceNumber,
		purchaseOrderNumber,
		customerNumber,
	} = data;
	const params = {
		...queryItems,
		srtCustomInfoRaw: transformCustomInformation(customInformationItems),
		srtReferenceNumber: referenceNumber,
		srtPurchaseOrderNumber: purchaseOrderNumber,
		srtCustomerNumber: customerNumber,
	};

	return addCustomInfo(params, error, success);
};

export const getExchangeOrderDetailsApi = (data, error, success) => {
	const { queryItems, orderId } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
	};

	return getExchangeOrderDetails(params, error, success);
};

export const processExchangeApi = (data, error, success) => {
	const {
		shoppingContext,
		tripType,
		faresSelected,
		sessionToken,
		selectedTdo,
		exchangeableTicketableFareIds,
		travelCard,
		seatReservations,
		exchangeSummary,
		recordLocator,
		orderId,
		payment,
		passengers,
	} = data;

	const params = {
		srtTicketableFareIds:
			exchangeableTicketableFareIds.length > 0
				? exchangeableTicketableFareIds.join(',')
				: '',
		srtRecLocator: recordLocator,
		srtOrderId: orderId,
		srtPriceIds: faresSelected
			.map((selectedFare) => selectedFare.getIn(['fare', 'priceId']))
			.toJS()
			.join(','),
		srtTripType: tripType,
		srtAccountName: shoppingContext,
		requestorId: `srtExchangeBooking.${Date.now()}`,
		dataStorageId: sessionToken,
		srtSelectedTDO: selectedTdo.tdoCode,
		srtSelectedTDODesc: selectedTdo.tdoDesc,
		srtSelectedTDOCurrency: selectedTdo.tdoCurrency,
		srtSelectedTDOFee: selectedTdo.tdoFee,
		srtTravelCardOptPriceID: getTravelCardIds(travelCard),
		srtChkSeatRes: [],
		srtNearbyCoach: [],
		srtNearbySeat: [],
		srtSeatResOptions: [],
		srtMakePaymentExchangeType: exchangeSummary
			? exchangeSummary.BookingExchangeOptionType
			: undefined,
		srtMakePaymentExchangeTotal: exchangeSummary
			? exchangeSummary.BookingExchangeOptionTotal
			: undefined,
		bookingExchangePassengers: passengers
			?.filter((passenger) => passenger.discountsLoyalties.discountIdentifier)
			.map(
				(passenger) =>
					`${passenger.passengerId},${passenger.discountsLoyalties.discountIdentifier}`,
			)
			.join(';'),
	};

	if (payment) {
		const card = findCard(
			payment.method,
			payment.paymentDue.BookingPaymentAcceptablePaymentTypes,
		);

		params.srtMakePaymentCardType = card.BookingPaymentType;
		params.srtMakePaymentAmount =
			payment.paymentDue.BookingPaymentSummary.BookingPaymentSummaryBalanceDue;
		params.srtMakePaymentMethod = payment.method;
		params.srtMakePaymentCardNumber = payment.cardNumber;
		params.srtMakePaymentExpirationMonth = payment.expiryDateMonth;
		params.srtMakePaymentExpirationYear = payment.expiryDateYear;
		params.srtCardSecurityCode = payment.securityCode;
		params.srtDebitCardStartMonth = payment.startDateMonth || '';
		params.srtDebitCardStartYear = payment.startDateYear || '';
		params.srtDebitCardIssueNumber = payment.issueNumber || '';
		params.srtPaymentToken = payment.token || '';
		params.srtMakePaymentCardholderName = payment.cardName;
		params.srtMakePaymentBillingCountry = payment.countryCode;
		params.srtMakePaymentStreet = payment.address1;
		params.srtMakePaymentStreet2 = payment.address2;
		params.srtMakePaymentCity = payment.city;
		params.srtMakePaymentState = payment.stateOrProvince;
		params.srtMakePaymentProv = payment.stateOrProvince;
		params.srtMakePaymentPostalCode = payment.postalCode;
		params.srtMakePaymentAddressType = payment.addressType;
		params.srtCurrencyCode =
			payment.paymentDue.BookingPaymentSummary.BookingPaymentSummaryMinDepositCurrency;
		if (payment.paymentDue.BookingPaymentHasCreditCardSurchargeFee) {
			params.srtMakePaymentSurchargeFee =
				card.BookingPaymentCreditCardSurchargeFeeValue;
			params.srtMakePaymentSurchargeFeeCurrency =
				card.BookingPaymentCreditCardSurchargeFeeCurrency;
		}
		params.srtBraintreeToken = payment.braintreeNonce;
	}

	if (!seatReservations.isEmpty()) {
		const travelSegments = seatReservations.keySeq().toList();
		travelSegments.forEach((segment) => {
			const segmentPassengers = seatReservations.get(segment).keySeq().toList();
			segmentPassengers.forEach((passenger) => {
				if (passenger !== 'enableAllSeats' && passenger !== 'price') {
					const items = seatReservations
						.getIn([segment, passenger])
						.keySeq()
						.toList();
					items.forEach((item) => {
						if (item !== 'price') {
							if (
								item === 'direction' ||
								item === 'position' ||
								item === 'coach' ||
								item === 'enablePassengerSeats' ||
								item === 'nearbyCoach' ||
								item === 'nearbySeat'
							) {
								const value = seatReservations.getIn([
									segment,
									passenger,
									item,
								]);
								const option = `${value}:${passenger}:${segment}`;

								if (item === 'enablePassengerSeats') {
									params.srtChkSeatRes.push(option);
								} else if (item === 'nearbyCoach') {
									params.srtNearbyCoach.push(option);
								} else if (item === 'nearbySeat') {
									params.srtNearbySeat.push(option);
								} else {
									params.srtSeatResOptions.push(option);
								}
							} else {
								const option = `${item}:${passenger}:${segment}`;
								params.srtSeatResOptions.push(option);
							}
						}
					});
				}
			});
		});
	}

	return processExchange(params, error, success);
};

export const getCancellationPenaltyApi = (queryItems, error, success) =>
	getCancellationPenalty(queryItems, error, success);

export const getRefundDetailsApi = (queryItems, error, success) =>
	getRefundDetails(queryItems, error, success);

export const submitRefundApi = (data, error, success) => {
	const {
		queryItems,
		currency,
		receiptNumber,
		amount,
		externalProvider,
		externalReference,
		reverseRefundMutation,
	} = data;
	const params = {
		...queryItems,
		srtCurrency: currency,
		srtReceiptNumber: receiptNumber,
		srtRefundAmount: amount,
		srtExternalProvider: externalProvider,
		srtExternalReference: externalReference,
		srtReverseRefundMutation: reverseRefundMutation,
	};

	return submitRefund(params, error, success);
};

export const getPaymentDueApi = (queryItems, error, success) =>
	getPaymentDue(queryItems, error, success);

export const submitPaymentApi = (data, error, success) => {
	const { payment, booking } = data;

	const card = findCard(
		payment.method,
		payment.paymentDue.BookingPaymentAcceptablePaymentTypes,
	);
	const { confirmation } = payment;

	const params = {
		...booking.queryItems,
		srtMakePaymentCardType: card.BookingPaymentType,
		srtMakePaymentAmount:
			payment.paymentDue.BookingPaymentSummary.BookingPaymentSummaryBalanceDue,
		srtMakePaymentMethod: payment.method,
		srtMakePaymentCardNumber: payment.cardNumber,
		srtMakePaymentExpirationMonth: payment.expiryDateMonth,
		srtMakePaymentExpirationYear: payment.expiryDateYear,
		srtCardSecurityCode: payment.securityCode,
		srtDebitCardStartMonth: payment.startDateMonth || '',
		srtDebitCardStartYear: payment.startDateYear || '',
		srtDebitCardIssueNumber: payment.issueNumber || '',
		srtPaymentToken: payment.token || '',
		srtMakePaymentCardholderName: payment.cardName,
		srtMakePaymentBillingCountry: payment.countryCode || '',
		srtMakePaymentStreet: payment.address1 || '',
		srtMakePaymentStreet2: payment.address2,
		srtMakePaymentCity: payment.city || '',
		srtMakePaymentState: payment.stateOrProvince,
		srtMakePaymentProv: payment.stateOrProvince,
		srtMakePaymentPostalCode: payment.postalCode || '',
		srtMakePaymentAddressType: payment.addressType,
		srtConfType: confirmation.type,
		...getConfirmationData(confirmation),
		srtPaymentClaimVDEmail: confirmation.vdEmail || undefined,
		srtConfirmationTicketLanguage: confirmation.language || undefined,
		srtCurrencyCode:
			payment.paymentDue.BookingPaymentSummary
				.BookingPaymentSummaryMinDepositCurrency,
		srtBraintreeToken: payment.braintreeNonce,
	};

	if (
		booking.BookingOrders[0].BookingOrderLegDetails.BookingOrderLegs.length > 0
	) {
		params.srtOrigin =
			booking.BookingOrders.length > 1
				? ''
				: booking.BookingOrders[0].BookingOrderLegDetails.BookingOrderLegs[0]
						.BookingOrderDetailsLegTravelSegmentOrigStationName;
		params.srtDestination =
			booking.BookingOrders.length > 1
				? ''
				: booking.BookingOrders[0].BookingOrderLegDetails.BookingOrderLegs[0]
						.BookingOrderDetailsLegTravelSegmentDestStationName;
	} else {
		params.srtOrigin =
			booking.BookingOrders.length > 1
				? ''
				: booking.BookingOrders[0].BookingOrderDepartureLocation;
		params.srtDestination =
			booking.BookingOrders.length > 1
				? ''
				: booking.BookingOrders[0].BookingOrderDestination;
		params.srtAreStationCodes = true;
	}

	if (payment.paymentDue.BookingPaymentHasCreditCardSurchargeFee) {
		params.srtMakePaymentSurchargeFee =
			card.BookingPaymentCreditCardSurchargeFeeValue;
		params.srtMakePaymentSurchargeFeeCurrency =
			card.BookingPaymentCreditCardSurchargeFeeCurrency;
	}

	return submitPayment(params, error, success);
};

export const getOrderDetailsApi = (data, error, success) => {
	const { queryItems, orderId } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
	};

	return getOrderDetails(params, error, success);
};

export const sendTicketApi = (data, error, success) => {
	const { queryItems, orderId, email, vdUrl, vdSms, tdo } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtResendClaimVDEmail: email,
		srtURLForClaimValueDocument: vdUrl,
		srtResendClaimVDSms: vdSms,
		srtTicketDeliveryOption: tdo,
	};

	return sendTicket(params, error, success);
};

export const resendClaimTicketApi = (data, error, success) => {
	const { queryItems, orderId, email, vdUrl, vdSms, isClaim, tdo } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtResendClaimVDEmail: email,
		srtURLForClaimValueDocument: vdUrl,
		srtResendClaimVDSms: vdSms,
		srtClaimVDMode: isClaim,
		srtTicketDeliveryOption: tdo,
	};

	return resendClaimTicket(params, error, success);
};

export const getCouponsApi = (data, error, success) => {
	const { queryItems, orderId } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
	};

	return getCoupons(params, error, success);
};

export const setCouponsApi = (data, error, success) => {
	const { booking, orderId, intl, returnCouponsData } = data;
	const {
		couponData,
		couponsToReturn,
		notes,
		receiptDate,
		returnByUtn,
		utnToReturn,
		forceCancel,
	} = returnCouponsData;
	const {
		BookingCouponsTravelSegmentCoupons: allCoupons,
		BookingCouponsWithTF: couponsWithTF,
		BookingCouponsNumPax: numPax,
	} = couponData;
	const order = booking.BookingOrders.find(
		(item) => item.BookingOrderID === orderId,
	);
	const isSeasonTrip = order.BookingLegFareRulesDetails.some(
		(fare) =>
			fare?.ShoppingFareRulesFareClasses?.toLowerCase().includes('season') ||
			fare?.ShoppingFareRulesFareClasses?.toLowerCase().includes('carnet'),
	);
	const params = {
		...booking.queryItems,
		srtOrderId: orderId,
		srtDocLocator: order.BookingValueDocLocator,
		srtCouponsNotes: notes,
		srtCouponReceivedDate: intl.formatDate(new Date(receiptDate)),
		returnByUtn: couponsWithTF ? isSeasonTrip && returnByUtn : false,
		srtForceCancel: forceCancel,
	};

	if (couponsWithTF) {
		const allCouponNumbers = [
			...new Set(allCoupons.map((coupon) => coupon.BookingCouponsNumber)),
		];
		const returnedCouponNumbers = [
			...new Set(
				allCoupons
					.filter((coupon) => coupon.BookingCouponsReceivedDate)
					.map((coupon) => coupon.BookingCouponsNumber),
			),
		];

		const returnedUniqueTicketNumbers = [
			...new Set(
				allCoupons
					.filter(
						(coupon) =>
							coupon.BookingCouponsUniqueTicketNumber &&
							coupon.BookingCouponsReceivedDate,
					)
					.map((coupon) => coupon.BookingCouponsUniqueTicketNumber),
			),
		];

		params.srtCouponNumbers = allCoupons
			.filter(
				(coupon) =>
					coupon.BookingCouponsNumber &&
					couponsToReturn.includes(coupon.BookingCouponsNumber),
			)
			.map((coupon) => coupon.BookingCouponsNumber);
		params.srtReturnFull =
			couponsToReturn.length + returnedCouponNumbers.length ===
			allCouponNumbers.length;
		params.srtUniqueTicketNumbers = allCoupons
			.filter(
				(coupon) =>
					coupon.BookingCouponsNumber &&
					coupon.BookingCouponsUniqueTicketNumber &&
					utnToReturn.includes(coupon.BookingCouponsUniqueTicketNumber),
			)
			.map(
				(coupon) =>
					`${coupon.BookingCouponsNumber}:${coupon.BookingCouponsUniqueTicketNumber}`,
			);
		params.returnByUtn =
			params.srtUniqueTicketNumbers &&
			params.srtUniqueTicketNumbers.length === 0
				? false
				: params.returnByUtn;
		if (params.returnByUtn) {
			params.srtReturnFull =
				utnToReturn?.length + returnedUniqueTicketNumbers?.length ===
				allCouponNumbers.length;
		}
	} else {
		let couponsAdded = 0;
		let couponsIssued = 0;
		params.srtOrigStationCode = [];
		params.srtDestStationCode = [];
		params.srtReceiveCouponCount = [];

		allCoupons.forEach((segment, index) => {
			const couponsNumber = couponsToReturn[index];
			if (couponsNumber) {
				params.srtOrigStationCode.push(segment.BookingCouponsOrigCode);
				params.srtDestStationCode.push(segment.BookingCouponsDestCode);
				params.srtReceiveCouponCount.push(couponsNumber);
				couponsAdded += couponsNumber;
				couponsIssued += segment.BookingCouponsIssuedNumPax;
			}
		});
		if (couponsAdded) params.srtNewCouponStartIndex = couponsIssued + 1;
		params.srtReturnFull =
			couponsAdded + couponsIssued >= allCoupons.length * numPax;
	}

	return setCoupons(params, error, success);
};

export const getTrackingInfoApi = (data, error, success) => {
	const { queryItems, orderId } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
	};

	return getTrackingInfo(params, error, success);
};

export const setTrackingInfoApi = (data, error, success) => {
	const { queryItems, orderId, trackingData, intl } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtTrackInfoMethod: trackingData.deliveryMethod,
		srtTrackInfoUrl: trackingData.shipperUrl,
		srtTrackInfoDate: intl.formatDate(new Date(trackingData.shipmentDate)),
		srtTrackInfoNumber: trackingData.trackingNumber,
	};

	return setTrackingInfo(params, error, success);
};

export const amendCTRApi = (data, error, success) => {
	const { queryItems, orderId, docLocator } = data;
	const params = {
		...queryItems,
		srtOrderId: orderId,
		srtAmmendCtrID: docLocator,
	};

	return amendCTR(params, error, success);
};

export const getPaymentDataApi = (accountName, error, success) =>
	getPaymentData({ accountName }, error, success);

export const deletePersonalInformationApi = (queryItems, error, success) =>
	deletePersonalInformation(queryItems, error, success);

export const submitPassengerInformationApi = (data, error, success) => {
	const { queryItems, passengers } = data;
	const params = {
		...queryItems,
		passengers,
	};

	return submitPassengerInformation(params, error, success);
};

export const updatePassengerCustomInformationApi = (data, error, success) => {
	const { queryItems, passengerId, passengerName, passengerCustomInformation } =
		data;
	const params = {
		bookingContextDTO: queryItems,
		passengerId,
		passengerName,
		passengerCustomInformation: passengerCustomInformation.map(
			(customInfo) => ({
				type: customInfo.type,
				value: customInfo.value,
			}),
		),
	};

	return updatePassengerCustomInformation(params, error, success);
};

export const updateCardCollectionApi = (data, error, success) => {
	const params = {
		bookingContextDTO: { ...data },
	};

	return updateCardCollection(params, error, success);
};
