import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import validate from 'validate.js';

import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { dateStrToDate } from '../../../utils/datetimeUtils';
import { getTrackingInfoApi, setTrackingInfoApi } from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import localeSettings from '../../../localeSettings';
import { gaEvent } from '../../../utils/googleAnalytics';

import TrackingInfo from './TrackingInfo';
import messages from './messagesTrackingInfo';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

validate.extend(validate.validators.datetime, {
	parse: (date) => date,
	format: (timestamp) => new Date(timestamp).toLocaleDateString(),
});

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)(() => ({
	...inlineStyles.dialogContent,
}));

class TrackingInfoDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		booking: PropTypes.object.isRequired,
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool,
		orderId: PropTypes.string,
		onTrackingInfoChanged: PropTypes.func,
	};

	initialState = {
		isFetching: false,
		isProcessingSubmit: false,
		alertText: '',
		trackingInfoDetails: {},
		deliveryMethod: '',
		trackingNumber: '',
		shipmentDate: 0,
		shipperUrl: '',
	};

	constructor(props) {
		super(props);
		this.state = { data: fromJS(this.initialState) };
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillMount() {
		const {
			open,
			booking,
			orderId,
			intl: { formatMessage },
		} = this.props;
		if (open) {
			this.setState((state) => ({
				data: state.data.merge({
					isFetching: true,
					alertText: '',
				}),
			}));

			getTrackingInfoApi(
				{
					queryItems: booking.queryItems,
					orderId,
				},
				(response) => {
					this.setState((state) => ({
						data: state.data.merge({
							isFetching: false,
							alertText: response.errorResponse.message,
						}),
					}));
				},
				(response) => {
					const { data } = response.successResponse;
					const trackingInfo = data.BookingTrackingInfo;
					this.setState((state) => ({
						data: state.data.merge({
							isFetching: false,
							trackingInfoDetails: data,
							shipmentDate: trackingInfo.BookingTrackingDate
								? dateStrToDate(
										trackingInfo.BookingTrackingDate.split(' ')[0],
										formatMessage(localeSettings.dateFormat),
									).getTime()
								: Date.now(),
							deliveryMethod: trackingInfo.BookingTrackingMethod
								? trackingInfo.BookingTrackingMethod
								: '',
							trackingNumber: trackingInfo.BookingTrackingNumber
								? trackingInfo.BookingTrackingNumber
								: '',
							shipperUrl: trackingInfo.BookingTrackingURL
								? trackingInfo.BookingTrackingURL
								: '',
						}),
					}));
				},
			);
		}
	}

	constraints = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		return {
			shipmentDate: {
				datetime: {
					message: formatMessage(messages.errInvalidShipDate),
				},
			},
			shipperUrl: {
				url: {
					message: formatMessage(messages.errInvalidShipperUrl),
				},
			},
		};
	};

	handleSubmitTrackingInfo = () => {
		const { booking, orderId, intl, onClose, onTrackingInfoChanged } =
			this.props;
		const data = this.state.data.toJS();
		const errors = validate(data, this.constraints());

		if (!validate.isEmpty(errors)) {
			this.setState((state) => ({
				data: state.data.merge({
					errors,
				}),
			}));
			return;
		}

		const { queryItems } = booking;
		this.setState((state) => ({
			data: state.data.merge({
				isProcessingSubmit: true,
				alertText: '',
				errors: {},
			}),
		}));

		setTrackingInfoApi(
			{
				queryItems,
				orderId,
				trackingData: data,
				intl,
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						isProcessingSubmit: false,
						alertText: response.errorResponse.message,
					}),
				}));
			},
			() => {
				this.setState(
					(state) => ({
						data: state.data.merge({
							isProcessingSubmit: false,
						}),
					}),
					() => {
						onClose();
						onTrackingInfoChanged(queryItems);
					},
				);
			},
		);
	};

	handleChangeField = (updates) => {
		this.setState((state) => ({ data: state.data.merge(updates) }));
	};

	handleSnackBarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		const data = this.state.data.toJS();
		const disabled = data.isFetching || data.isProcessingSubmit;

		const closeAction = () => {
			gaEvent('editTrackingInfoClose');
			this.props.onClose();
		};
		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-12 col-sm-2 offset-sm-6">
					<Button
						variant="contained"
						id="srtTrackingInfoClose"
						onClick={closeAction}
						fullWidth
						disabled={disabled}
					>
						{formatMessage(messages.btnClose)}
					</Button>
				</div>
				<div className="col-12 col-sm-4">
					<Button
						variant="contained"
						id="srtTrackingInfoSubmit"
						onClick={this.handleSubmitTrackingInfo}
						fullWidth
						color="primary"
						disabled={disabled}
					>
						{formatMessage(messages.btnSubmit)}
					</Button>
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={this.props.open}
					onClose={closeAction}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{formatMessage(messages.lblTitle)}</DialogTitle>
					<StyledDialogContent>
						<TrackingInfo
							trackingInfo={data}
							errors={data.errors}
							onChange={this.handleChangeField}
						/>
						<RefreshIndicator
							size={36}
							top={0}
							left={0}
							status={disabled ? 'loading' : 'hide'}
							style={inlineStyles.refreshIndicator}
						/>
					</StyledDialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtTrackingInfoSnackBar"
					open={data.alertText !== ''}
					message={data.alertText}
					onClose={this.handleSnackBarClose}
				/>
			</div>
		);
	}
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
	onTrackingInfoChanged: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(TrackingInfoDialog));
