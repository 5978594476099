import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';

import './styles/bootstrapCustomized.scss';
import './styles/app.css'; // eslint-disable-line

import routes from './routes';
import muiThemeV1 from './muiThemeV1';
import FloatingButton from './components/FloatingButton';
import ConnectedIntlProvider from './components/ConnectedIntlProvider';
import MuiPickersUtilsProviderLocalized from './components/MuiPickersUtilsProviderLocalized';
import TestModeRedBar from './utils/TestModeRedBar';
import { isDevEnvironment } from './utils';

function App() {
	return (
		<>
			{isDevEnvironment() && <TestModeRedBar />}
			<Provider store={store}>
				<ThemeProvider theme={muiThemeV1}>
					<ConnectedIntlProvider>
						<MuiPickersUtilsProviderLocalized>
							<BrowserRouter>
								{routes}
								<FloatingButton />
							</BrowserRouter>
						</MuiPickersUtilsProviderLocalized>
					</ConnectedIntlProvider>
				</ThemeProvider>
			</Provider>
		</>
	);
}

export default App;
