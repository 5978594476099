import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import LinearProgress from '@mui/material/LinearProgress';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import messages from './messagesEditUser';
import {
	getUserProfileApi,
	updateUserProfileApi,
	deleteUserApi,
} from '../apiAdmin';
import { safeDecode } from '../../utils';
import User from '../components/User';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import { adminDataChanged } from '../../Shopping/ShoppingSearch/actionsShoppingSearch';

const initialState = {
	isFetching: true,
	processingAction: false,
	alertText: '',
	user: {},
};

const transformUserData = (data) => {
	const userData = {};

	userData.username = data.UserManagementUserLoginId || '';
	userData.businessEmail = data.UserManagementProfileBusinessEmail || '';
	userData.firstName = data.UserManagementProfileFirstName || '';
	userData.lastName = data.UserManagementProfileLastName || '';
	userData.lastSuccessfulLogin = data.SecurityLastSuccessfulLogin || '';
	userData.userStatus = data.SecurityUserStatus || 'Active';
	userData.userAllowedVoidException = data.SecurityUserAllowedVoidException;

	if (data.UserManagementProfileRoleInfo) {
		if (
			data.UserManagementProfileRoleInfo
				.UserManagementProfileRoleInfoAllDescendantRoles
		) {
			userData.userRoles =
				data.UserManagementProfileRoleInfo.UserManagementProfileRoleInfoAllDescendantRoles;
			if (
				data.UserManagementProfileRoleInfo
					.UserManagementProfileRoleInfoAssignedRoles[0] &&
				userData.userRoles.indexOf(
					data.UserManagementProfileRoleInfo
						.UserManagementProfileRoleInfoAssignedRoles[0],
				) === -1
			) {
				userData.userRoles.push(
					data.UserManagementProfileRoleInfo
						.UserManagementProfileRoleInfoAssignedRoles[0],
				);
			}
		}

		userData.role =
			data.UserManagementProfileRoleInfo
				.UserManagementProfileRoleInfoAssignedRoles[0] || 'Everyone';
	}

	// We are assigning default locale from the parent user
	if (data.UserManagementProfileSrtLocale) {
		userData.locale = data.UserManagementProfileSrtLocale;
		userData.originalLocale = data.UserManagementProfileSrtLocale;
	}

	// We are assigning groups from the parent user
	if (data.UserManagementAssocAccountGroups) {
		userData.assocAccGroups = data.UserManagementAssocAccountGroups;
	}

	// We are assigning accounts from the parent user
	if (data.UserManagementAssocAccounts) {
		userData.assocAccounts = data.UserManagementAssocAccounts;
	}

	if (data.UserManagementUserProfileSuppliers) {
		userData.suppliers = data.UserManagementUserProfileSuppliers.split(',');
	} else {
		userData.suppliers = [];
	}

	if (data.UserManagementUserProfileTnCsDate) {
		userData.acceptTNC = data.UserManagementUserProfileTnCsDate;
	}

	if (data.UserManagementUserProfileCNRef) {
		userData.certFileName = data.UserManagementUserProfileCNRef;
	}

	if (data.AccountManagementAllAccounts) {
		userData.allAccounts = data.AccountManagementAllAccounts;
	}

	if (data.AccountManagementAllAccountsGroup) {
		userData.allAccountsGroup = data.AccountManagementAllAccountsGroup;
	}

	return userData;
};

const EditUser = ({
	intl: { formatMessage },
	match: { params },
	history,
	loginId,
	onAdminDataChanged,
	suppliers,
}) => {
	const [data, setData] = useState(fromJS(initialState));

	useEffect(() => {
		if (!(params && params.userId)) return;
		getUserProfileApi(
			safeDecode(params.userId),
			(error) => {
				setData((prevData) =>
					prevData.merge({
						alertText:
							error?.errorResponse?.message ||
							formatMessage(messages.errGetUser),
						user: {},
						isFetching: false,
					}),
				);
			},
			(response) => {
				setData((prevData) =>
					prevData.merge({
						alertText: '',
						user: transformUserData(response.successResponse.data),
						isFetching: false,
					}),
				);
			},
		);
	}, [params, formatMessage]);

	const handleSnackbarClose = () => {
		setData((prevData) => prevData.merge({ alertText: '' }));
	};

	const handleCancel = () => {
		history.goBack();
	};

	const handleSubmit = (formData) => {
		setData((prevData) =>
			prevData.merge({ processingAction: true, user: formData }),
		);

		updateUserProfileApi(
			formData.toJS(),
			(response) => {
				setData((prevData) =>
					prevData.merge({
						alertText: response.errorResponse.message,
						processingAction: false,
					}),
				);
			},
			(response) => {
				let alertText = '';

				if (response.successResponse) {
					alertText =
						formData.get('locale') === formData.get('originalLocale')
							? formatMessage(messages.userUpdated)
							: formatMessage(messages.userUpdatedLocale);
				} else {
					alertText = formatMessage(messages.errEditUser);
				}
				setData((prevData) =>
					prevData.merge({
						alertText,
						processingAction: false,
						user: { originalLocale: data.get(['user', 'locale']) },
					}),
				);
				if (loginId === safeDecode(params.userId)) onAdminDataChanged();
			},
		);
	};

	const handleDelete = () => {
		setData((prevData) => prevData.merge({ processingAction: true }));

		deleteUserApi(
			safeDecode(params.userId),
			(response) => {
				setData((prevData) =>
					prevData.merge({
						alertText: response.errorResponse.message,
						processingAction: false,
					}),
				);
			},
			(response) => {
				let alertText = '';

				if (response.successResponse) {
					alertText = formatMessage(messages.userDeleted);
				} else {
					alertText = formatMessage(messages.errDeleteUser);
				}
				setData((prevData) =>
					prevData.merge({ alertText, processingAction: false }),
				);
			},
		);
	};

	return (
		<div className="container-fluid">
			<div>{data.get('isFetching') && <LinearProgress />}</div>
			<div className="row">
				<div className="col-12">
					{!data.get('isFetching') && (
						<User
							suppliers={suppliers}
							user={data.get('user')}
							processingAction={data.get('processingAction')}
							handleCancel={handleCancel}
							handleSubmit={handleSubmit}
							handleDelete={handleDelete}
						/>
					)}
					<ExtendedSnackbar
						id="srtEditUserSnackBar"
						open={data.get('alertText') !== ''}
						message={data.get('alertText')}
						onClose={handleSnackbarClose}
					/>
				</div>
			</div>
		</div>
	);
};

EditUser.propTypes = {
	intl: PropTypes.object.isRequired,
	suppliers: PropTypes.object,
	match: PropTypes.object,
	loginId: PropTypes.string.isRequired,
	onAdminDataChanged: PropTypes.func.isRequired,
	history: PropTypes.object,
};

EditUser.contextTypes = {
	router: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { EditUser as EditUserAlias };

export default injectIntl(
	connect(
		(state) => ({
			suppliers: state
				.getIn(['settings', 'SupplierList', 'supplyChannelList'])
				?.map((item) =>
					fromJS({
						value: item.get('0'),
						label: item.get('1'),
					}),
				),
			loginId: state.getIn(['settings', 'UserLoginId']),
		}),
		{ onAdminDataChanged: adminDataChanged },
	)(EditUser),
);
