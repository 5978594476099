import { Component } from 'react';
import { fromJS } from 'immutable';

const events = [
	'mousemove',
	'keydown',
	'wheel',
	'DOMMouseScroll',
	'mouseWheel',
	'mousedown',
	'touchstart',
	'touchmove',
	'MSPointerDown',
	'MSPointerMove',
];

class SessionMgt extends Component {
	intervalCheck = 30000;

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS({
				actionCounter: 0,
				interval: null,
				pageX: 0,
				pageY: 0,
				lastActive: 0,
			}),
		};
	}

	componentDidMount() {
		events.forEach((e) =>
			document.addEventListener(e, this.handleUserActivity, { passive: true }),
		);
		this.reset();
	}

	componentWillUnmount() {
		clearInterval(this.state.data.get('interval'));
		events.forEach((e) =>
			document.removeEventListener(e, this.handleUserActivity),
		);
	}

	toggleIdleState = () => {
		if (this.state.data.get('actionCounter') > 0) {
			this.setState((state) => ({
				data: state.data.merge({ actionCounter: 0 }),
			}));
		}
	};

	handleUserActivity = (e) => {
		if (e.type === 'mousemove') {
			const elapsed = new Date().getTime() - this.state.data.get('lastActive');
			if (elapsed > 2000) {
				if (
					e.pageX === this.state.data.get('pageX') &&
					e.pageY === this.state.data.get('pageY')
				)
					return;
				if (typeof e.pageX === 'undefined' && typeof e.pageY === 'undefined')
					return;
			} else {
				return;
			}
		}

		this.setState((state) => ({
			data: state.data.merge({
				lastActive: new Date().getTime(),
				pageX: e.pageX,
				pageY: e.pageY,
				actionCounter: state.data.get('actionCounter') + 1,
			}),
		}));
	};

	reset = () => {
		this.setState((state) => ({
			data: state.data.merge({
				actionCounter: 0,
				lastActive: new Date().getTime(),
				interval: setInterval(this.toggleIdleState, this.intervalCheck),
			}),
		}));
	};

	render() {
		return null;
	}
}

export default SessionMgt;
