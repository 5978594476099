import { defineMessages } from 'react-intl';

export default defineMessages({
	lblCustomInformation: {
		id: 'app.shopping.manageBooking.customInformation.customInformation',
		defaultMessage: 'Custom Information',
	},
	lblCustomInformationType: {
		id: 'app.shopping.manageBooking.customInformation.customInformationType',
		defaultMessage: 'Type',
	},
	lblCustomInformationValue: {
		id: 'app.shopping.manageBooking.customInformation.customInformationValue',
		defaultMessage: 'Value',
	},
	lblCustomInformationReferenceNumber: {
		id: 'app.shopping.manageBooking.customInformation.customInformationReferenceNumber',
		defaultMessage: 'Reference #',
	},
	lblCustomInformationPONumber: {
		id: 'app.shopping.manageBooking.customInformation.customInformationPONumber',
		defaultMessage: 'PO #',
	},
	lblCustomInformationCustomerNumber: {
		id: 'app.shopping.manageBooking.customInformation.customInformationCustomerNumber',
		defaultMessage: 'Customer #',
	},
	btnCustomInformationEdit: {
		id: 'app.shopping.manageBooking.customInformation.customInformationEdit',
		defaultMessage: 'Edit custom information',
	},
});
