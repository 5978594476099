import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';

import messages from '../messagesManageAccounts';
import inlineStyles from './accountGroupStyles';

const AuthorizeRefundSettings = ({
	intl: { formatMessage },
	authorizeRefundCode,
	onChangeAuthorizeRefundCode,
	authorizeRefundDescription,
	onChangeAuthorizeRefundDescription,
}) => (
	<div className="col-12">
		<ListSubheader style={inlineStyles.headerFare}>
			<FormattedMessage {...messages.lblAuthorizeRefundSettings} />
		</ListSubheader>
		<TextField
			id="srtauthorizeRefundCode"
			name="authorizeRefundCode"
			label={formatMessage(messages.lblAuthorizeRefundCode)}
			fullWidth
			value={authorizeRefundCode || ''}
			onChange={onChangeAuthorizeRefundCode}
			inputProps={{ maxLength: 50 }}
			variant="standard"
		/>
		<TextField
			id="srtauthorizeRefundDescription"
			name="authorizeRefundDescription"
			label={formatMessage(messages.lblAuthorizeRefundDescription)}
			fullWidth
			value={authorizeRefundDescription || ''}
			onChange={onChangeAuthorizeRefundDescription}
			inputProps={{ maxLength: 255 }}
			variant="standard"
		/>
	</div>
);

AuthorizeRefundSettings.propTypes = {
	authorizeRefundCode: PropTypes.string,
	authorizeRefundDescription: PropTypes.string,
	onChangeAuthorizeRefundCode: PropTypes.func.isRequired,
	onChangeAuthorizeRefundDescription: PropTypes.func.isRequired,
	intl: PropTypes.object,
};

// This alias will be used to access bare component for unit testing
export { AuthorizeRefundSettings as AuthorizeRefundSettingsAlias };

export default injectIntl(AuthorizeRefundSettings);
