import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import messages from './messagesFilterList';
import inlineStyles from './styles';

const FilterList = (props) => {
	const { onFilterCheck, id, filterCategories, activeFilters } = props;
	const { formatMessage } = props.intl;

	return (
		<div className="container-fluid" style={inlineStyles.filterContainer}>
			<div className="row">
				{!filterCategories.has('type') || (
					<div className="col-6">
						<b>{formatMessage(messages.lblType)}</b>
						{filterCategories
							.get('type')
							.toJS()
							.map((category) => (
								<FormControlLabel
									control={
										<Checkbox
											id={`${id}_${category}`}
											name={`type!${category}`}
											onChange={onFilterCheck}
											checked={activeFilters.get('type').includes(category)}
										/>
									}
									style={inlineStyles.checkbox}
									key={`srtFilter_${category}`}
									label={category}
								/>
							))}
					</div>
				)}
				{!filterCategories.has('cabin') || (
					<div className="col-6">
						<b>{formatMessage(messages.lblCabin)}</b>
						{filterCategories
							.get('cabin')
							.toJS()
							.map((category) => (
								<FormControlLabel
									control={
										<Checkbox
											id={`${id}_${category}`}
											name={`cabin!${category}`}
											onChange={onFilterCheck}
											checked={activeFilters.get('cabin').includes(category)}
										/>
									}
									style={inlineStyles.checkbox}
									key={`srtFilter_${category}`}
									label={category}
								/>
							))}
					</div>
				)}
				{!filterCategories.has('schedule') || (
					<div className="col-6">
						<b>{formatMessage(messages.lblSchedule)}</b>
						{filterCategories
							.get('schedule')
							.toJS()
							.map((category) => (
								<FormControlLabel
									control={
										<Checkbox
											id={`${id}_${category}`}
											name={`schedule!${category}`}
											onChange={onFilterCheck}
											checked={activeFilters.get('schedule').includes(category)}
										/>
									}
									style={inlineStyles.checkbox}
									key={`srtFilter_${category}`}
									label={category}
								/>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

FilterList.propTypes = {
	intl: PropTypes.object,
	id: PropTypes.string,
	filterCategories: PropTypes.object,
	onFilterCheck: PropTypes.func,
	activeFilters: PropTypes.object,
};

const mapStateToProps = (state) => ({
	filterCategories: state.getIn(['shopping', 'results', 'filterCategories']),
});

export { FilterList as FilterListAlias };
export default connect(mapStateToProps)(injectIntl(FilterList));
