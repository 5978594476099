import React, { Component } from 'react';
import PropTypes from 'prop-types';
import braintree from 'braintree-web';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { inputBraintreeStyle } from './styles';

import Input from './Input';
import messages from './messages';

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputBase-root': {
		height: 'auto',
	},
	'& .MuiInputBase-input': {
		height: '19px',
		padding: '8px 0',
	},
}));

class Braintree extends Component {
	static propTypes = {
		braintreeClientToken: PropTypes.string.isRequired, // must be changed to isRequired when we receive that info from BE
		disabled: PropTypes.bool,
		getNonceFunctionRef: PropTypes.func,
		showEmptyError: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false,
		showEmptyError: false,
	};

	state = {
		fetching: true,
	};

	componentDidMount() {
		const { braintreeClientToken } = this.props;
		braintree.client
			.create({ authorization: braintreeClientToken })
			.then((clientInstance) => {
				const options = {
					client: clientInstance,
					styles: {
						inputBraintreeStyle,
					},
					fields: {
						number: {
							selector: '#braintree-number',
						},
						cvv: {
							selector: '#braintree-cvv',
						},
						expirationDate: {
							selector: '#braintree-expirationDate',
							placeholder: 'MM/YYYY',
						},
					},
				};

				return braintree.hostedFields.create(options);
			})
			.then(this.getHostedFieldsInstance);
	}

	getHostedFieldsInstance = (hostedFieldsInstance) => {
		const { getNonceFunctionRef } = this.props;

		this.hostedFieldsInstance = hostedFieldsInstance;

		if (getNonceFunctionRef) {
			getNonceFunctionRef(
				(options) =>
					new Promise((resolve, reject) => {
						hostedFieldsInstance
							.tokenize(options)
							.then((payload) => {
								resolve(payload);
							})
							.catch((err) => {
								const invalids = {};
								if (err.details && err.details.invalidFieldKeys) {
									err.details.invalidFieldKeys.forEach((field) => {
										invalids[field] = 'invalid';
									});
								}
								this.setState(invalids);
								reject(err);
							});
					}),
			);
		}

		hostedFieldsInstance.on('empty', (event) => {
			this.setState({ [event.emittedBy]: null });
		});

		hostedFieldsInstance.on('notEmpty', (event) => {
			this.setState({ [event.emittedBy]: 'valid' });
		});

		hostedFieldsInstance.on('validityChange', (event) => {
			const field = event.fields[event.emittedBy];
			this.setState({
				[event.emittedBy]: field.isPotentiallyValid ? 'valid' : 'invalid',
			});
		});
		this.setState({ fetching: false });
	};

	renderTextField = (field) => {
		const { showEmptyError, disabled } = this.props;
		const { fetching } = this.state;
		const fieldMessages = {
			number: {
				label: messages.lblPaymentCardNumber,
				empty: messages.errPaymentCardNumberEmpty,
				invalid: messages.errPaymentCardNumberInvalid,
			},
			cvv: {
				label: messages.lblPaymentSecurityCode,
				empty: messages.errSecurityCode,
				invalid: messages.errSecurityCode,
			},
			expirationDate: {
				label: messages.lblPaymentExpiryDate,
				empty: messages.errDateInvalid,
				invalid: messages.errDateInvalid,
			},
		}[field];
		const showError =
			this.state[field] === 'invalid' || (showEmptyError && !this.state[field]);
		return (
			<StyledTextField
				disabled={fetching || disabled}
				error={showError}
				fullWidth
				helperText={
					showError ? (
						<FormattedMessage
							{...fieldMessages[
								this.state[field] === 'invalid' ? 'invalid' : 'empty'
							]}
						/>
					) : (
						''
					)
				}
				id={`braintree-${field}`}
				label={<FormattedMessage {...fieldMessages.label} />}
				InputProps={{
					inputComponent: Input,
					inputProps: {
						hostedFieldsInstance: this.hostedFieldsInstance,
						style: {
							...inputBraintreeStyle.input,
						},
					},
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		);
	};

	render() {
		return (
			<div id="braintree-container">
				{this.renderTextField('number')}
				{this.renderTextField('cvv')}
				{this.renderTextField('expirationDate')}
			</div>
		);
	}
}

export { Braintree as BraintreeAlias };

export default Braintree;
