import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import TicketableFareGroupTitle from '../TicketableFareGroupTitle/TicketableFareGroupTitle';
import messages from './messages';
import styles from './styles';

const StyledDiv = styled('div')(() => ({
	...styles.tdosTitle,
}));
function TicketDeliveryDetails({ details }) {
	return (
		<Table>
			<TableBody>
				{details.map((detail) => (
					<TableRow key={detail.referenceCode}>
						<TableCell>
							<TicketableFareGroupTitle ticketableFareGroup={detail} />
							<StyledDiv>
								<FormattedMessage {...messages.lblTdosTitle} />
							</StyledDiv>
							{detail.ticketDetailList.map((tdo) => (
								<div key={tdo.code}>{tdo.description}</div>
							))}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

TicketDeliveryDetails.propTypes = {
	details: PropTypes.array,
};
export { TicketDeliveryDetails as TicketDeliveryDetailsAlias };

export default TicketDeliveryDetails;
