import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import WarningIcon from '@mui/icons-material/Warning';

import StationsTicketDialog from './StationsTicketDialog';
import StationInformationDialog from '../StationInformation/StationInformationDialog';

import { isTDOAvailAtDepartureStation } from './utils';
import messages from './messagesTicketDelivery';
import inlineStyles from './styles';

function PickupWarningMessage(props) {
	const {
		onCloseStationInformationDialog,
		onCloseStationsTicketDialog,
		onOpenStationInformationDialog,
		onOpenStationsTicketDialog,
		stationCode,
		stationInformationDialogOpen,
		stationName,
		stationsTicketDialogOpen,
		suppliers,
		tdo,
		tdOptions,
	} = props;
	let warningMessagePart = null;

	const tofFound = tdOptions.some((item) => item.BookingTDOCode === 'TOF');
	const tvmFound = tdOptions.some((item) => item.BookingTDOCode === 'TVM');

	// look at the various TVM possibilities
	if (
		tdo === 'TVM' &&
		(!isTDOAvailAtDepartureStation(tdOptions, 'TOF') || !tofFound)
	) {
		warningMessagePart = (
			<FormattedMessage {...messages.lblTDONoTodOndeparture} />
		);
	} else if (
		tdo === 'TVM' &&
		tofFound &&
		isTDOAvailAtDepartureStation(tdOptions, 'TOF')
	) {
		warningMessagePart = (
			<FormattedMessage {...messages.lblTDONoTvmOndeparture} />
		);
	}

	// and now, look at the various TOF possibilities
	if (
		tdo === 'TOF' &&
		(!isTDOAvailAtDepartureStation(tdOptions, 'TVM') || !tvmFound)
	) {
		warningMessagePart = (
			<FormattedMessage {...messages.lblTDONoTodOndeparture} />
		);
	} else if (
		tdo === 'TOF' &&
		tvmFound &&
		isTDOAvailAtDepartureStation(tdOptions, 'TVM')
	) {
		warningMessagePart = (
			<FormattedMessage {...messages.lblTDONoTofOndeparture} />
		);
	}

	return (
		warningMessagePart && (
			<div style={inlineStyles.warning}>
				<WarningIcon style={inlineStyles.warningIcon} />
				<span>{warningMessagePart} </span>
				<a style={inlineStyles.linkTitle} onClick={onOpenStationsTicketDialog}>
					<FormattedMessage {...messages.lblTdoElectronicNoMail} />
				</a>
				<StationInformationDialog
					stationCode={stationCode}
					open={stationInformationDialogOpen}
					onClose={onCloseStationInformationDialog}
				/>
				<FormattedMessage {...messages.lblTDONoTodStationInfo} />
				&nbsp;
				<a
					style={inlineStyles.linkTitle}
					onClick={onOpenStationInformationDialog}
				>
					{stationName}
				</a>
				<StationsTicketDialog
					suppliers={suppliers}
					stationCode={stationCode}
					open={stationsTicketDialogOpen}
					onClose={onCloseStationsTicketDialog}
				/>
			</div>
		)
	);
}

PickupWarningMessage.propTypes = {
	onCloseStationInformationDialog: PropTypes.func,
	onCloseStationsTicketDialog: PropTypes.func,
	onOpenStationInformationDialog: PropTypes.func,
	onOpenStationsTicketDialog: PropTypes.func,
	stationCode: PropTypes.string,
	stationInformationDialogOpen: PropTypes.bool,
	stationName: PropTypes.string,
	stationsTicketDialogOpen: PropTypes.bool,
	suppliers: PropTypes.array,
	tdo: PropTypes.string,
	tdOptions: PropTypes.array,
};

export default PickupWarningMessage;
