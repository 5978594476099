import { midBlue, midGray6 } from '../../../../../../colors';

const styles = {
	root: {
		padding: 16,
		fontWeight: 'unset',
	},
	paper: {
		padding: 16,
		marginBottom: 16,
		width: '100%',
		maxWidth: 555,
	},
	journeyHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 8,
	},
	journeyNumber: {
		fontSize: 21,
		marginRight: 8,
	},
	journeyInfo: {
		color: midBlue,
		fontSize: 12,
	},
	viewDetails: {
		color: midBlue,
		textTransform: 'none',
		fontWeight: 300,
	},
	journeyDetails: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	stationColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	stationName: {
		fontSize: '1rem',
		marginBottom: 4,
		fontWeight: 200,
	},
	timeText: {
		fontSize: '1rem',
		fontWeight: 200,
	},
	logo: {
		width: 60,
		height: 30,
		marginTop: 8,
	},
	toggleRoot: {
		marginRight: '0px !important',
	},
	toggleLabel: {
		width: '100%',
		color: midBlue,
	},
	toggleIcon: {
		display: 'none',
	},
	journeysWrap: {
		display: 'flex',
		gap: '10px',
		flexWrap: 'wrap',
	},
	selectButton: {
		marginTop: 10,
	},
	legTypeBooking: {
		backgroundColor: midGray6,
		fontSize: 16,
		color: 'white',
		lineHeight: 2.14,
		marginBottom: 15,
		paddingLeft: 10,
	},
};

export default styles;
