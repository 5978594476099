import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.tos.title',
		defaultMessage: 'Terms of Service',
	},
	lblConditionsOfCarriageLink: {
		id: 'app.shopping.tos.cocLink',
		defaultMessage: 'National Rail Conditions of Travel',
	},
	lblTermsAndConditionsLink: {
		id: 'app.shopping.tos.tocLink',
		defaultMessage: 'Terms and Conditions',
	},
	lblOnlineDisputeResolutionLink: {
		id: 'app.shopping.tos.onrLink',
		defaultMessage: 'here',
	},
	lblAmtrakLink: {
		id: 'app.shopping.tos.amtrakLink',
		defaultMessage: "Amtrak's Terms and Conditions",
	},
	lblTermsAndConditionsDesc: {
		id: 'app.shopping.tos.tocDesc',
		defaultMessage: 'I agree to the {link}',
	},
	lblTermsAndConditionsAmtrak: {
		id: 'app.shopping.tos.amtrakDesc',
		defaultMessage: ' of this booking platform and {link}',
	},
	lblConditionsOfCarriageDesc: {
		id: 'app.shopping.tos.cocDesc',
		defaultMessage: 'and {link}.',
	},
	lblTermsAndConditionsDescAddDBB: {
		id: 'app.shopping.tos.tocCocDescAlt',
		// eslint-disable-next-line max-len
		defaultMessage:
			'and I confirm that the identity card given for the booking is valid and is the identity card of the traveler. The aforementioned identity card has to be shown together with the online ticket in the train during ticket control. The online ticket cannot be used by another person and is only valid for the specified traveler (and fellow travelers in case of a ticket for more than one person).',
	},
});
