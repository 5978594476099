import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FareTerminals from './FareTerminals';

import { decodeHtml } from '../../../utils';
import Linkify from '../../../components/Linkify/Linkify';
import messages from './messagesFareRulesMatrixContent';
import inlineStyles from './fareRulesMatrixContentStyles';

const StyledFareRulesRuleCategory = styled('div')(() => ({
	...inlineStyles.fareRulesRuleCategory,
}));

const StyledRuleRow = styled('div')(() => ({
	...inlineStyles.ruleRow,
}));

const StyledFareRulesMatrixContainer = styled('div')(() => ({
	...inlineStyles.fareRulesMatrixContainer,
}));

const StyledFareRulesMatrixTitle = styled('div')(() => ({
	...inlineStyles.fareRulesMatrixTitle,
}));

const StyledTab = styled(Tab)(() => ({
	...inlineStyles.tabRoot,
}));

function FareRulesMatrixContent({
	intl: { formatMessage },
	fareRulesDetails,
	paxTitle,
	isSeason,
}) {
	const [fareRuleSelected, setFareRuleSelected] = useState(0);
	const changeTab = (event, newFareRuleSelected) => {
		setFareRuleSelected(newFareRuleSelected);
	};

	const fareMatrixTitle = fareRulesDetails[0]?.paxRefs
		? formatMessage(messages.lblPaxTitle)
		: paxTitle;

	const getFareRuleDetail = (index) => {
		const fareRulesDetail = fareRulesDetails[index];
		if (!fareRulesDetail) return false;
		const {
			fareClasses,
			fareCodes,
			originTerminals,
			destinationTerminals,
			fareMatrix,
			fareOrigins,
			fareDestinations,
		} = fareRulesDetail;

		const formatFareRulesText = (rule) => (
			<div key={`rule_${rule.category}`}>
				{rule.category && (
					<StyledFareRulesRuleCategory>
						{rule.category}
					</StyledFareRulesRuleCategory>
				)}
				{rule.textRuleDesc.map((ruleDescription) => (
					<div key={`ruleDescription_${ruleDescription}`}>
						<Linkify>{decodeHtml(ruleDescription)}</Linkify>
					</div>
				))}
			</div>
		);

		const textRules =
			fareMatrix
				?.filter((item) => item.isTextRule)
				.map((item) => formatFareRulesText(item)) ?? [];

		return (
			<>
				<StyledRuleRow id="srtFareRulesMatrixTextRules" className="col-12">
					{textRules}
				</StyledRuleRow>
				{fareOrigins && (
					<StyledRuleRow id="srtFareOriginTerminals" className="col-12">
						<b>
							<FormattedMessage {...messages.lblFareValidityTitle} />
						</b>{' '}
						<br />
						<FormattedMessage {...messages.lblFareValidityText} />
						{fareOrigins.replace(/ *\([^)]*\) */g, '')}
						<FareTerminals fareTerminals={originTerminals} />
						{fareDestinations && (
							<div id="srtFareDestinationTerminals">
								{' '}
								<br />
								<FormattedMessage {...messages.lblFareValidityToText} />
								{fareDestinations.replace(/ *\([^)]*\) */g, '')}
								<FareTerminals fareTerminals={destinationTerminals} />
							</div>
						)}
					</StyledRuleRow>
				)}
				{fareClasses?.indexOf('Anytime') >= 0 && (
					<StyledRuleRow id="srtFareAnytimeFAQ" className="col-12">
						<b>
							<FormattedMessage {...messages.lblAnytimeFareTicketFAQPart1} />
						</b>
						<br />
						<br />
						<b>
							<FormattedMessage
								{...messages.lblAnytimeFareTicketFAQPart2}
							/>{' '}
						</b>
						<FormattedMessage {...messages.lblAnytimeFareTicketFAQPart2Desc} />
						<br />
						<b>
							<FormattedMessage
								{...messages.lblAnytimeFareTicketFAQPart3}
							/>{' '}
						</b>
						<FormattedMessage {...messages.lblAnytimeFareTicketFAQPart3Desc} />
						<br />
						<b>
							<FormattedMessage
								{...messages.lblAnytimeFareTicketFAQPart4}
							/>{' '}
						</b>
						<FormattedMessage {...messages.lblAnytimeFareTicketFAQPart4Desc} />
						<br />
					</StyledRuleRow>
				)}
				<div>{fareCodes}</div>
			</>
		);
	};

	return (
		<>
			<StyledFareRulesMatrixContainer>
				<StyledFareRulesMatrixTitle id="srtFareRulesMatrixPaxHeading">
					{isSeason ? (
						<FormattedMessage {...messages.lblSeasonTitle} />
					) : (
						fareMatrixTitle
					)}
				</StyledFareRulesMatrixTitle>
				<Tabs
					value={fareRuleSelected}
					onChange={changeTab}
					variant="scrollable"
				>
					{fareRulesDetails?.map(({ displayName }, fareRulesDetailIndex) => (
						<StyledTab
							label={
								<FormattedMessage
									{...messages.lblFare}
									values={{ displayName }}
								/>
							}
							value={fareRulesDetailIndex}
							id="srtFareRulesMatrixFareClass"
							key={fareRulesDetailIndex}
						/>
					))}
				</Tabs>
			</StyledFareRulesMatrixContainer>
			{getFareRuleDetail(fareRuleSelected)}
		</>
	);
}

FareRulesMatrixContent.propTypes = {
	intl: PropTypes.object,
	fareRulesDetails: PropTypes.array,
	paxTitle: PropTypes.string,
	isSeason: PropTypes.bool,
};

export { FareRulesMatrixContent as FareRulesMatrixContentAlias };
export default injectIntl(FareRulesMatrixContent);
