import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';

import CustomInfoItem from './CustomInfoItem';
import messages from './messagesCustomInfo';
import styles from './styles';

const CustomInfo = ({
	customInfoItems,
	showRemove,
	intl: { formatMessage },
	showAddButton,
	disabled,
	errors,
	disableRequired,
	changeCustomInfo,
	removeCustomInfo,
	hideRequired,
	addCustomInfo,
}) => {
	const labels = {
		type: formatMessage(messages.lblCustomInfoType),
		value: formatMessage(messages.lblCustomInfoValue),
		required: formatMessage(messages.lblCustomInfoRequired),
		remove: formatMessage(messages.lblCustomInfoRemove),
	};

	const isVisible = customInfoItems.length > 0 || showAddButton;

	return (
		<div
			id="CustomInfoContainer"
			style={isVisible ? styles.customInfoContainer : undefined}
			data-testid="CustomInfo"
		>
			<div>
				{customInfoItems.map((item, index) => {
					const error =
						errors && errors.length > 0
							? errors.find((searchItem) => searchItem.index === index)
							: {};
					return (
						<CustomInfoItem
							key={index}
							index={index}
							type={item.type}
							value={item.value}
							required={item.required}
							disableRequired={disableRequired}
							typeReadOnly={item.typeReadOnly}
							disabled={disabled}
							handleChange={changeCustomInfo}
							handleRemoveCI={removeCustomInfo}
							showRemove={
								customInfoItems.length > 1 && showRemove && !item.typeReadOnly
							}
							labels={labels}
							errors={error}
							hideRequired={hideRequired}
						/>
					);
				})}
			</div>
			{showAddButton && (
				<div className="col-sm-3 offset-sm-9">
					<Button
						variant="contained"
						id="srtCIAddButton"
						onClick={addCustomInfo}
						color="primary"
						fullWidth
						disabled={disabled}
					>
						{formatMessage(messages.lblCustomInfoAdd)}
					</Button>
				</div>
			)}
		</div>
	);
};

CustomInfo.propTypes = {
	customInfoItems: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			required: PropTypes.bool.isRequired,
		}),
	),
	changeCustomInfo: PropTypes.func,
	removeCustomInfo: PropTypes.func,
	addCustomInfo: PropTypes.func,
	showAddButton: PropTypes.bool,
	showRemove: PropTypes.bool,
	disabled: PropTypes.bool,
	disableRequired: PropTypes.bool,
	errors: PropTypes.array,
	intl: PropTypes.object,
	hideRequired: PropTypes.bool,
};

// This alias will be used to access bare component for unit testing
export { CustomInfo as CustomInfoAlias };

export default injectIntl(CustomInfo);
