import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import validate from 'validate.js';

import { injectIntl } from 'react-intl';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import CustomInfo from '../../../components/CustomInfo/CustomInfo';
import messages from './messagesCustomInfoDialog';
import { addCustomInfoApi } from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../utils/googleAnalytics';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
});

class CustomInfoDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		open: PropTypes.bool,
		booking: PropTypes.object.isRequired,
		onCloseCustomInfoDialog: PropTypes.func.isRequired,
		onSaveCustomInfo: PropTypes.func.isRequired,
	};

	initialState = {
		processingSubmit: false,
		alertText: '',
		errors: [],
		customInformationItems: [],
		customerNumber: '',
		purchaseOrderNumber: '',
		referenceNumber: '',
	};

	constructor(props) {
		super(props);
		this.state = { data: fromJS(this.initialState) };
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState((state) => ({
			data: state.data.merge({
				referenceNumber:
					nextProps.booking.BookingExternalIdentification
						.BookingExternalIdReferenceNumber,
				purchaseOrderNumber:
					nextProps.booking.BookingExternalIdentification
						.BookingExternalIdPoNumber,
				customerNumber:
					nextProps.booking.BookingExternalIdentification
						.BookingExternalIdCustomerNumber,
				customInformationItems: nextProps.booking.BookingCustomInformation.map(
					(item) => ({
						type: item.BookingCustomInformationType,
						value: item.BookingCustomInformationValue,
						required: item.BookingCustomInformationRequired,
						typeReadOnly: true,
					}),
				),
			}),
		}));
	}

	customInfoConstraints = (formatMessage) => ({
		referenceNumber: {
			length: {
				maximum: 25,
				tooLong: formatMessage(messages.errCustomInfoTooLong),
			},
		},
		purchaseOrderNumber: {
			length: {
				maximum: 25,
				tooLong: formatMessage(messages.errCustomInfoTooLong),
			},
		},
		customerNumber: {
			length: {
				maximum: 25,
				tooLong: formatMessage(messages.errCustomInfoTooLong),
			},
		},
	});

	// TODO no array validators, should be available on next validate js version
	customInfoConstraintsValidation = (items) => {
		const {
			intl: { formatMessage },
		} = this.props;
		const errors = [];
		const types = [];
		items.forEach((item, index) => {
			const error = {};
			const noType = validate.isEmpty(item.type);
			let notValid = false;

			if (noType) {
				notValid = true;
				error.type = formatMessage(messages.errCustomInfoType);
			}

			if (item.type.length > 255) {
				notValid = true;
				error.type = formatMessage(messages.errCustomInfoTypeValueTooLong);
			}

			if (item.value.length > 255) {
				notValid = true;
				error.value = formatMessage(messages.errCustomInfoTypeValueTooLong);
			}

			if (item.required && validate.isEmpty(item.value)) {
				notValid = true;
				error.value = formatMessage(messages.errCustomInfoValue);
			}

			const isDuplicate = types.some((typeItem) => item.type === typeItem);

			if (isDuplicate) {
				notValid = true;
				error.type = formatMessage(messages.errCustomInfoTypeDuplicate, {
					errorType: item.type,
				});
			}

			if (item.type.length > 0) {
				types.push(item.type);
			}

			if (notValid) {
				error.index = index;
				errors.push(error);
			}
		});
		return errors;
	};

	changeState = (propPath, value) => {
		this.setState((state) => ({ data: state.data.setIn(propPath, value) }));
	};

	handleChangeField = (event, parentPath) => {
		const propPath = parentPath;
		propPath.push(event.target.name);
		this.changeState(propPath, event.target.value);
	};

	handleAddCustomInfo = () => {
		gaEvent('bookingCustomInformationAdd');
		this.setState((state) => ({
			data: state.data.setIn(
				[
					'customInformationItems',
					state.data.get('customInformationItems').size,
				],
				fromJS({
					type: '',
					typeReadOnly: false,
					value: '',
					required: false,
				}),
			),
		}));
	};

	handleRemoveCustomInfo = (index) => {
		gaEvent('bookingCustomInformationRemove');
		this.setState((state) => ({
			data: state.data.deleteIn(['customInformationItems', index]),
		}));
	};

	handleChangeCustomInfo = (index, updates) => {
		if (!this.state.data.get('customInformationItems')) {
			this.setState((state) => ({
				data: state.data.merge({ customInformationItems: fromJS([]) }),
			}));
		}
		this.setState((state) => ({
			data: state.data.mergeIn(['customInformationItems', index], updates),
		}));
	};

	handleClose = () => {
		gaEvent('bookingCustomInformationClose');
		this.setState({ data: fromJS(this.initialState) }, () => {
			this.props.onCloseCustomInfoDialog();
		});
	};

	handleSubmit = () => {
		const { data } = this.state;
		const {
			booking,
			onSaveCustomInfo,
			intl: { formatMessage },
		} = this.props;
		this.setState({ data: data.merge({ errors: [], processingSubmit: true }) });
		const errors = {
			...validate(data.toJS(), this.customInfoConstraints(formatMessage)),
		};
		const customInfoErrors = this.customInfoConstraintsValidation(
			data.get('customInformationItems').toJS(),
		);
		if (!validate.isEmpty(customInfoErrors)) {
			errors.customInformationItems = customInfoErrors;
		}

		if (!validate.isEmpty(errors)) {
			this.setState({ data: data.merge({ errors, processingSubmit: false }) });
			return;
		}

		const { queryItems } = booking;

		addCustomInfoApi(
			{
				queryItems,
				customInformationItems: data.get('customInformationItems').toJS(),
				referenceNumber: data.get('referenceNumber'),
				purchaseOrderNumber: data.get('purchaseOrderNumber'),
				customerNumber: data.get('customerNumber'),
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						processingSubmit: false,
					}),
				}));
			},
			() => {
				onSaveCustomInfo(queryItems);
				this.setState((state) => ({
					data: state.data.merge({
						alertText: formatMessage(messages.okCustomInfoAdded),
						processingSubmit: false,
					}),
				}));
			},
		);
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			open,
			intl: { formatMessage },
		} = this.props;
		const { data } = this.state;
		const disabled = data.get('isProcessingSubmit');

		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-4 offset-md-3">
					<Button
						variant="contained"
						id="srtCustomInfoClose"
						onClick={this.handleClose}
						fullWidth
					>
						{formatMessage(messages.lblClose)}
					</Button>
				</div>
				<div className="col-8 col-md-5">
					{data.get('processingSubmit') ? (
						<RefreshIndicator size={36} left={40} top={0} status="loading" />
					) : (
						<Button
							variant="contained"
							id="srtCustomInfoSubmit"
							onClick={this.handleSubmit}
							fullWidth
							color="primary"
						>
							{formatMessage(messages.lblSubmit)}
						</Button>
					)}
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={open}
					onClose={this.handleClose}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>{formatMessage(messages.lblCustomInfo)}</DialogTitle>
					<DialogContent>
						<TextField
							id="srtReferenceNo"
							name="referenceNumber"
							label={formatMessage(messages.lblReferenceNo)}
							disabled={disabled}
							fullWidth
							onChange={(event) => this.handleChangeField(event, [])}
							value={data.get('referenceNumber')}
							error={!!data.getIn(['errors', 'referenceNumber'])}
							helperText={data.getIn(['errors', 'referenceNumber'])}
							variant="standard"
						/>
						<TextField
							id="srtPONumber"
							name="purchaseOrderNumber"
							label={formatMessage(messages.lblPONumber)}
							disabled={disabled}
							fullWidth
							onChange={(event) => this.handleChangeField(event, [])}
							value={data.get('purchaseOrderNumber')}
							error={!!data.getIn(['errors', 'purchaseOrderNumber'])}
							helperText={data.getIn(['errors', 'purchaseOrderNumber'])}
							variant="standard"
						/>
						<TextField
							id="srtCustomerNo"
							name="customerNumber"
							label={formatMessage(messages.lblCustomerNo)}
							disabled={disabled}
							fullWidth
							onChange={(event) => this.handleChangeField(event, [])}
							value={data.get('customerNumber')}
							error={!!data.getIn(['errors', 'customerNumber'])}
							helperText={data.getIn(['errors', 'customerNumber'])}
							variant="standard"
						/>
						<CustomInfo
							customInfoItems={data.get('customInformationItems').toJS()}
							changeCustomInfo={this.handleChangeCustomInfo}
							addCustomInfo={this.handleAddCustomInfo}
							removeCustomInfo={this.handleRemoveCustomInfo}
							disabled={disabled}
							disableRequired
							showAddButton
							showRemove
							errors={
								data.hasIn(['errors', 'customInformationItems'])
									? data.getIn(['errors', 'customInformationItems']).toJS()
									: []
							}
						/>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtCustomInfoSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

const mapsStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
	onSaveCustomInfo: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(
	mapsStateToProps,
	mapDispatchToProps,
)(injectIntl(CustomInfoDialog));
