import { combineReducers } from 'redux-immutable';
import settingsReducer from './settingsReducer';
import authReducer from './authReducer';
import shoppingReducer from './shoppingReducer';
import bookingReducer from '../Booking/ManageBooking/reducersManageBooking';

const rootReducer = combineReducers({
	settings: settingsReducer,
	shopping: shoppingReducer,
	booking: bookingReducer,
	auth: authReducer,
});

export default rootReducer;
