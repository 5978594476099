import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.login.termsConditions.title',
		defaultMessage: 'Terms and Conditions',
	},
	lblAgreementTitle: {
		id: 'app.login.termsConditions.agreementTitle',
		defaultMessage:
			'AGREEMENT BETWEEN THE CUSTOMER AND SILVERRAIL TECHNOLOGIES',
	},
	lblAgreement: {
		id: 'app.login.termsConditions.agreement',
		// eslint-disable-next-line max-len
		defaultMessage:
			'The SilverAgent Lite application ("SA Lite") is offered as a service to you, the user, by us, SilverRail Technologies Inc. and our subsidiaries ("SilverRail Technologies").',
	},
	lblTocTitle: {
		id: 'app.login.termsConditions.tocTitle',
		defaultMessage: 'Train Operating Companies',
	},
	lblToc1: {
		id: 'app.login.termsConditions.toc1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'All cancellations and refunds made through the Booking Service are subject to the applicable terms and conditions of the underlying rail operator and any specific restrictions imposed by the relevant train operating companies. Terms and conditions may vary by ticket type.',
	},
	lblToc2: {
		id: 'app.login.termsConditions.toc2',
		// eslint-disable-next-line max-len
		defaultMessage:
			'SilverRail Technologies is therefore not responsible for any of the terms and conditions, including eligibility for refunds, of the various ticket types.',
	},
	lblAboutSrtTitle: {
		id: 'app.login.termsConditions.aboutSrtTitle',
		defaultMessage: 'About SilverRail Technologies',
	},
	lblAboutSrt: {
		id: 'app.login.termsConditions.aboutSrt',
		// eslint-disable-next-line max-len
		defaultMessage:
			'SilverRail Technologies connects the world of rail. We specialize in building best-in-class technical solutions that helps Railways and Travel Distributors easily connect with each other.',
	},
	lblUseSALiteTitle: {
		id: 'app.login.termsConditions.useSALiteTitle',
		defaultMessage: 'Use of SA Lite',
	},
	lblUseSALite1: {
		id: 'app.login.termsConditions.useSALite1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'SA Lite is designed to provide you with travel information, to assist you in canceling and refunding the original form of payment. You confirm that you are at least 18 years old and have authority to refund monies to the original payment method or billing account. You also promise that all information supplied by you in using SA Lite is accurate and that you will not make any speculative, false or fraudulent refunds. You further promise that you will only use SA Lite to process cancellations and refunds for yourself or for another person on whose behalf you are legally entitled to act.',
	},
	lblUseSALite2: {
		id: 'app.login.termsConditions.useSALite2',
		// eslint-disable-next-line max-len
		defaultMessage:
			'You acknowledge that you will be financially responsible for all reasonable and foreseeable losses which we suffer as a result of your breach of these terms or your negligence when SA Lite (including where you deliberately or negligently let others use your account).',
	},
	lblFeesChargesTitle: {
		id: 'app.login.termsConditions.feesChargesTitle',
		defaultMessage: 'Fees and Charges',
	},
	lblFeesCharges1: {
		id: 'app.login.termsConditions.feesCharges1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'There may be certain fees and charges payable in respect of cancellations and refunds made via SA Lite. If applicable, these will be identified during the cancellation process. Some of these fees are levied by the relevant train operating company which requires us to pass them on to the customer.',
	},
	lblFeesCharges2: {
		id: 'app.login.termsConditions.feesCharges2',
		defaultMessage:
			'You will be responsible for all charges and taxes payable as a result of your use of SA Lite.',
	},
	lblLiabilityDisclaimerTitle: {
		id: 'app.login.termsConditions.liabilityDisclaimerTitle',
		defaultMessage: 'Terms and Conditions',
	},
	lblLiabilityDisclaimer1: {
		id: 'app.login.termsConditions.liabilityDisclaimer1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We are not responsible for losses that result from, but are not directly caused by, any breach of these terms and conditions, or that are not reasonable or foreseeable by you and us (such as loss of profits or opportunity) even if we are made aware of the possibility of such losses. Our liability shall not, in any event, include losses related to any business of yours, such as lost data, lost profits or business interruption. We will be liable for any reasonable and foreseeable direct costs you incur which are caused as a result of our negligence or a breach of these terms up to a limit of the value of the relevant ticket(s), which costs could not have been reduced or avoided by you taking reasonable steps. We will in no circumstances pay or be responsible for any loss of profit, loss of opportunity, loss of business, loss of revenue, wasted time, wasted costs, indirect, incidental, or consequential loss arising out of or in any way connected with the use of SA Lite with the delay or inability to use SA Lite, or for any information and services obtained through SA Lite, or otherwise arising out of the use of SA Lite.',
	},
	lblLiabilityDisclaimer2: {
		id: 'app.login.termsConditions.liabilityDisclaimer2',
		defaultMessage:
			'Nothing in these terms limits our liability for death or personal injury directly caused by us.',
	},
	lblLiabilityDisclaimer3: {
		id: 'app.login.termsConditions.liabilityDisclaimer3',
		// eslint-disable-next-line max-len
		defaultMessage:
			'All third-party suppliers of products, services or content through which we link to from SA Lite are independent entities and we are not responsible or liable for any wrongful act or omission on their part or for any of the content of their web sites, including but not limited to any product liability claims.',
	},
	lblOtherTermsTitle: {
		id: 'app.login.termsConditions.otherTermsTitle',
		defaultMessage: 'Other Terms',
	},
	lblOtherTerms1: {
		id: 'app.login.termsConditions.otherTerms1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We may change these terms from time to time. However, any changes will not affect existing terms accepted by you when making a cancellation or refund through SA Lite.',
	},
	lblOtherTerms2: {
		id: 'app.login.termsConditions.otherTerms2',
		// eslint-disable-next-line max-len
		defaultMessage:
			'These terms are intended by us to set out the whole agreement between us and you and any prior communications between us are not included in this agreement. We recommend that you read them carefully to protect your own interests. If you do not think they set out the whole agreement between us, please make sure you ask for any additional terms to be put in writing prior to using this service. In that way we can avoid any problems surrounding what you expect us to do. We cannot accept any liability for any reliance placed by you on any statement or representation on SA Lite, whether made by us or a third-party, except to the extent a statement or representation on SA Lit is made negligently by us.',
	},
	lblOtherTerms3: {
		id: 'app.login.termsConditions.otherTerms3',
		// eslint-disable-next-line max-len
		defaultMessage:
			'If any of these terms are found to be invalid by a court or at law then the remainder of these terms shall continue to apply to the extent that they still make sense without the unenforceable term(s).',
	},
	lblOtherTerms4: {
		id: 'app.login.termsConditions.otherTerms4',
		// eslint-disable-next-line max-len
		defaultMessage:
			'You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these terms or your use of SA Lite.',
	},
	lblOtherTerms5: {
		id: 'app.login.termsConditions.otherTerms5',
		// eslint-disable-next-line max-len
		defaultMessage:
			'Neither you nor we intend any third party to be able to enforce any of these terms pursuant to the Contracts (Rights of Third Parties) Act 1999.',
	},
	lblOtherTerms6: {
		id: 'app.login.termsConditions.otherTerms6',
		// eslint-disable-next-line max-len
		defaultMessage:
			'You and we agree that English law applies to these terms and conditions and that any dispute between us regarding SA Lite or arising out of or in connection with these terms and conditions will only be dealt with by the English courts, unless you are resident in another part of the United Kingdom, in which case the applicable law of that part of the United Kingdom will apply and any dispute will be brought before the courts there.',
	},
	lblPrivacyPolicyTitle: {
		id: 'app.login.termsConditions.privacyPolicyTitle',
		defaultMessage: 'PRIVACY POLICY',
	},
	lblPrivacyPolicy: {
		id: 'app.login.termsConditions.privacyPolicy',
		// eslint-disable-next-line max-len
		defaultMessage:
			'Your privacy is important to us. This Privacy Policy sets out the procedures SilverRail Technologies has in place in relation to the collection, use and disclosure of information you may provide or we may collect via SA Lite.',
	},
	lblYourConsentTitle: {
		id: 'app.login.termsConditions.yourConsentTitle',
		defaultMessage: 'Your Consent',
	},
	lblYourConsent: {
		id: 'app.login.termsConditions.yourConsent',
		// eslint-disable-next-line max-len
		defaultMessage:
			'By using SA Lite, you agree with the terms of this Privacy Policy and whenever you submit information via SA Lite, you consent to the collection, use and disclosure of that information in accordance with this Privacy Policy.',
	},
	lblActiveCollectionTitle: {
		id: 'app.login.termsConditions.activeCollectionTitle',
		defaultMessage: 'Active Collection of Information',
	},
	lblActiveCollection: {
		id: 'app.login.termsConditions.activeCollection',
		// eslint-disable-next-line max-len
		defaultMessage:
			'Some areas of SA Lite require you to actively submit information in order for you to benefit from specific features. Some of this information may be personal (namely, information that can identify you. We only collect such information when you choose to supply it to us. You confirm that you will only enter information about yourself and that such information is true.',
	},
	lblPassiveCollectionTitle: {
		id: 'app.login.termsConditions.passiveCollectionTitle',
		defaultMessage: 'Passive Collection of Information',
	},
	lblPassiveCollection1: {
		id: 'app.login.termsConditions.passiveCollection1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We may collect and process anonymous information about your visit to SA Lite, such as some of the pages you visit and some of the searches you perform. Such information is used by us to help us improve the contents of the site and to compile, for internal market research purposes, aggregate statistics about individuals using it. This kind of anonymous information can be obtained by our use of "cookies". A "cookie" is a small piece of information that is sent to your browser and stored on your computer\'s hard drive. We use cookies in three ways:',
	},
	lblPassiveCollection2: {
		id: 'app.login.termsConditions.passiveCollection2',
		// eslint-disable-next-line max-len
		defaultMessage:
			'(a) "per-session cookies" are used while you are visiting SA Lite to keep track of your progress through the admin process. These do not store any personal information and are deleted when you close your web browser;',
	},
	lblPassiveCollection3: {
		id: 'app.login.termsConditions.passiveCollection3',
		// eslint-disable-next-line max-len
		defaultMessage:
			'(b) "auto logon cookies" store some personal information in order to allow you to log on automatically; and',
	},
	lblPassiveCollection4: {
		id: 'app.login.termsConditions.passiveCollection4',
		// eslint-disable-next-line max-len
		defaultMessage:
			'(c) "looker cookies" store historic data about your visits to SA Lite and allow us to personalize your experience and make recommendations to you about products and services that we think may be of interest to you.',
	},
	lblPassiveCollection5: {
		id: 'app.login.termsConditions.passiveCollection5',
		// eslint-disable-next-line max-len
		defaultMessage:
			"This Privacy Policy only covers the use of cookies by us and does not cover the use of cookies by any advertisers. We do not have control, nor access to the information contained in advertisers' cookies, nor can they access any personal data held by us.",
	},
	lblPassiveCollection6: {
		id: 'app.login.termsConditions.passiveCollection6',
		// eslint-disable-next-line max-len
		defaultMessage:
			'With most web browsers it is possible to configure your Internet browser so that it can refuse new cookies, prompt you to accept a cookie or disable cookies altogether. How you do this will depend on the Internet browser you use - however, refusing to accept "per-session cookies" may result in SA Lite not operating correctly. For further details on cookies, we recommend that you visit www.allaboutcookies.org.',
	},
	lblPassiveCollection7: {
		id: 'app.login.termsConditions.passiveCollection7',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We may also use Internet tags in combination with "cookies" on SA Lite. These tags are placed on both on-line advertisements that bring users to SA Lite and on different pages of SA Lite. We use this technology to measure visitor response to our sites and the effectiveness of our advertising campaigns. The third-party service provider involved may be able to collect anonymous aggregated data about visitors to other sites because of these Internet tags and cookies, and the servers on which this data is stored may either be located within or outside the EU.',
	},
	lblUseOfInfoTitle: {
		id: 'app.login.termsConditions.useOfInfoTitle',
		defaultMessage: 'Use of Information Collected',
	},
	lblUseOfInfo: {
		id: 'app.login.termsConditions.useOfInfo',
		// eslint-disable-next-line max-len
		defaultMessage:
			'Your personal information submitted to us is used for operational purposes, for example, processing refunds, and to personalize your experience by using your browsing activity to make recommendations to you about products and services that we think may be of interest to you.',
	},
	lblSharingOfInfoTitle: {
		id: 'app.login.termsConditions.sharingOfInfoTitle',
		defaultMessage: 'Sharing of Information',
	},
	lblSharingOfInfo1: {
		id: 'app.login.termsConditions.sharingOfInfo1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We may share your personal information with third-party service providers (including service providers outside of the EU) for the purpose of processing a reservation, hotel or travel insurance product or otherwise in relation to fulfilling a change made via SA Lite. These service providers will only have access to the personal information needed to perform the relevant service and may not use your personal information for any other purpose. They will also be required to use your personal information strictly in accordance with data protection laws, including maintaining adequate security measures to protect such personal information.',
	},
	lblSharingOfInfo2: {
		id: 'app.login.termsConditions.sharingOfInfo2',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We may also share your personal information (a) with your employer; (b) with any person who takes over our business to use your personal information on the same basis as we do; (c) with the train operator with whose services you interact; or (d) with legal or regulatory authorities where we are lawfully requested or required to do so, or voluntarily to the Police and similar law enforcement agencies (including fraud prevention and detection), other government agencies or third parties.',
	},
	lblSecurityTitle: {
		id: 'app.login.termsConditions.securityTitle',
		defaultMessage: 'Security',
	},
	lblSecurity: {
		id: 'app.login.termsConditions.security',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We take the security of your data very seriously. We employ physical, electronic and administrative security measures to protect the information that we collect about you from access by unauthorised persons and against unlawful processing, accidental loss, destruction and damage. You acknowledge and agree that we shall not be responsible for any unauthorised use, distribution, damage or destruction of personal data, except to the extent we are required to accept such responsibility by the data protection laws.',
	},
	lblChangesPrivacyPolicyTitle: {
		id: 'app.login.termsConditions.changesPrivacyPolicyTitle',
		defaultMessage: 'Changes to this Privacy Policy',
	},
	lblChangesPrivacyPolicy: {
		id: 'app.login.termsConditions.changesPrivacyPolicy',
		// eslint-disable-next-line max-len
		defaultMessage:
			'There may be developments in how we use your data. All such developments will be notified to you by updating this Privacy Policy.',
	},
	lblLiabilityDisclaimerFinalTitle: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinalTitle',
		defaultMessage: 'LIABILITY DISCLAIMER',
	},
	lblLiabilityDisclaimerFinal1: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal1',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We will use reasonable care and skill to carry out the services contained in SA Lite within a reasonable time, in accordance with these terms. We do not make any other promises and no other promises shall apply in respect of the information, products, and/or services contained on SA Lite.',
	},
	lblLiabilityDisclaimerFinal2: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal2',
		// eslint-disable-next-line max-len
		defaultMessage:
			'While we try to ensure that all content provided by us is correct at the time of publication, certain information is compiled and provided by third parties, and no responsibility is accepted by us or on our behalf for any errors, omissions or inaccurate content on SA Lite. If you believe that you have noticed an error, please write to us to let us know.',
	},
	lblLiabilityDisclaimerFinal3: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal3',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We take measures to keep SA Lite free from computer viruses and other malicious programs. However, we cannot accept responsibility for any computer viruses or other malicious programs which are transmitted to your computer as a result of your use of SA Lite, or a third-party web site, unless this was due to our negligence or a breach of these terms by us. You acknowledge that it is your responsibility to implement sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy your particular requirements for the accuracy of data input and output.',
	},
	lblLiabilityDisclaimerFinal4: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal4',
		// eslint-disable-next-line max-len
		defaultMessage:
			'We cannot guarantee the availability of SA Lite. As with any service over the Internet there are factors over which we have no control, for which we cannot accept liability. You agree that we may take SA Lite offline where we consider that this is necessary for routine or emergency maintenance without liability to you.',
	},
	lblLiabilityDisclaimerFinal5: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal5',
		// eslint-disable-next-line max-len
		defaultMessage:
			'To the extent permitted by law, we exclude all representations and warranties (whether express or implied by law), including the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy. We do not guarantee the timeliness, completeness or performance of SA Lite or any of the content.',
	},
	lblLiabilityDisclaimerFinal6: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal6',
		defaultMessage:
			'Nothing in these terms limits our liability for death or personal injury directly caused by us.',
	},
	lblLiabilityDisclaimerFinal7: {
		id: 'app.login.termsConditions.liabilityDisclaimerFinal7',
		// eslint-disable-next-line max-len
		defaultMessage:
			'All third-party suppliers of products, services or content which we link to from SA Lite (which may include our selected partners such as providers of hotels, travel insurance companies and car hire companies) are independent entities and we are not responsible or liable for any wrongful act or omission on their part or for any of the content of their web sites, including but not limited to any product liability claims. You should read the terms and conditions of any third-party suppliers that are displayed on or linked to SA Lite carefully.',
	},
	lblAccept: {
		id: 'app.login.termsConditions.accept',
		defaultMessage: 'Accept',
	},
});
