import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.manageBooking.addOrder.title',
		defaultMessage: 'Add Order to Booking',
	},
	btnClose: {
		id: 'app.shopping.manageBooking.addOrder.close',
		defaultMessage: 'Close',
	},
	btnSearch: {
		id: 'app.shopping.manageBooking.addOrder.searchForFares',
		defaultMessage: 'Search for Fares',
	},
	btnAddOrder: {
		id: 'app.shopping.manageBooking.addOrder.addOrder',
		defaultMessage: 'Add Order',
	},
});
