import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.createBooking.plusBusSupplements.title',
		defaultMessage: 'Select a Local Bus Service option',
	},
	lblSubTitle: {
		id: 'app.shopping.createBooking.plusBusSupplements.subTitle',
		defaultMessage: 'A ticket for each passenger is included in the price',
	},
	btnDiscardChanges: {
		id: 'app.shopping.createBooking.plusBusSupplements.discardChanges',
		defaultMessage: 'Discard changes',
	},
	btnSavePlusBusSupplements: {
		id: 'app.shopping.createBooking.plusBusSupplements.savePlusBusSupplement',
		defaultMessage: 'Save Local Bus Ticket',
	},
	lblLeg: {
		id: 'app.shopping.createBooking.plusBusSupplements.leg',
		defaultMessage: 'Leg',
	},
	lblTo: {
		id: 'app.shopping.createBooking.plusBusSupplements.to',
		defaultMessage: 'to',
	},
});
