import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import SessionMgt from '../../components/SessionMgt';
import { isAuthApi } from '../apiLogin';
import { getBasePath } from '../../utils';
import { getSettings, clearSettings } from '../../actions/settingsActions';

export const getAuthorizedRoutes = (accessCategories) => {
	const allCategories = {
		shopping: 'shopping',
		managebookings: 'booking',
		admin: 'admin',
	};
	return accessCategories
		.split(',')
		.filter((category) => allCategories[category])
		.map((category) => allCategories[category]);
};

function SecureRoute({
	getSettings: onGetSettings,
	history,
	clearSettings: onClearSettings,
	settings,
	children,
	...rest
}) {
	const [isAuthenticated, setIsAuthenticated] = useState();

	useEffect(() => {
		isAuthApi(
			() => {
				setIsAuthenticated(false);
			},
			(response) => {
				if (response?.successResponse?.data?.SecurityAuthenticated === 'true') {
					onGetSettings();
					const routes = getAuthorizedRoutes(
						response.successResponse.data.SecurityAuthorizedCategories,
					);
					setIsAuthenticated(true);
					if (routes.indexOf('shopping') === -1) {
						history.replace(
							window.location.state?.from || `${getBasePath()}${routes[0]}`,
						);
					}
				} else {
					onClearSettings();
					setIsAuthenticated(false);
				}
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		!!settings.size &&
		isAuthenticated !== undefined && (
			<>
				<SessionMgt />
				<Route
					history={history}
					{...rest}
					render={({ location }) =>
						isAuthenticated ? (
							children
						) : (
							<Redirect
								to={{
									pathname: `${getBasePath()}login`,
									state: { from: location },
								}}
							/>
						)
					}
				/>
			</>
		)
	);
}

SecureRoute.propTypes = {
	children: PropTypes.node,
	getSettings: PropTypes.func,
	clearSettings: PropTypes.func,
	settings: PropTypes.object,
	history: PropTypes.object,
};

const mapStateToProps = (state) => ({
	settings: state.get('settings'),
	accessCategories: state.get('ws.system.security.auth_access_categories'),
});

const mapDispatchToProps = {
	getSettings,
	clearSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecureRoute);
