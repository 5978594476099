import PropTypes from 'prop-types';
import React from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

const MenuList = ({ children }) => {
	/* eslint-disable react/prop-types */
	const rowRenderer = ({ index, key, style }) => (
		/* eslint-enable react/prop-types */
		<div key={key} style={style}>
			{children[index]}
		</div>
	);

	return (
		<AutoSizer disableHeight>
			{({ width }) => (
				<List
					height={Math.min((children.length || 0) * 32, 400)}
					overscanRowCount={20}
					rowCount={children.length || 0}
					rowHeight={32}
					rowRenderer={rowRenderer}
					width={width}
				/>
			)}
		</AutoSizer>
	);
};

MenuList.propTypes = {
	children: PropTypes.node,
};

export default MenuList;
