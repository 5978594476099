import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.paxViewDialog.title',
		defaultMessage: 'Passenger Information',
	},
	btnClose: {
		id: 'app.shopping.paxViewDialog.close',
		defaultMessage: 'Close',
	},
	btnSave: {
		id: 'app.shopping.paxViewDialog.save',
		defaultMessage: 'Save',
	},
	lblPaxTitle: {
		id: 'app.shopping.paxViewDialog.paxTitle',
		defaultMessage: 'Title',
	},
	lblPaxSuffix: {
		id: 'app.shopping.paxViewDialog.paxSuffix',
		defaultMessage: 'Suffix',
	},
	lblPaxIdentity: {
		id: 'app.shopping.paxViewDialog.paxIdentity',
		defaultMessage: 'Identification',
	},
	lblPaxFirstName: {
		id: 'app.shopping.paxViewDialog.paxFirstName',
		defaultMessage: 'First Name',
	},
	lblPaxLastName: {
		id: 'app.shopping.paxViewDialog.paxLastName',
		defaultMessage: 'Last Name',
	},
	lblPaxMiddleName: {
		id: 'app.shopping.paxViewDialog.paxMiddleName',
		defaultMessage: 'Middle Name',
	},
	lblPaxAge: {
		id: 'app.shopping.paxViewDialog.paxAge',
		defaultMessage: 'Age',
	},
	lblPaxGender: {
		id: 'app.shopping.paxViewDialog.paxGender',
		defaultMessage: 'Gender',
	},
	lblPaxSmartcardNumber: {
		id: 'app.shopping.paxViewDialog.SmartcardNumber',
		defaultMessage: 'Smart Card Number',
	},
	lblPaxAddress1: {
		id: 'app.shopping.paxViewDialog.paxAddress1',
		defaultMessage: 'Address 1',
	},
	lblPaxAddress2: {
		id: 'app.shopping.paxViewDialog.paxAddress2',
		defaultMessage: 'Address 2',
	},
	lblPaxCity: {
		id: 'app.shopping.paxViewDialog.paxCity',
		defaultMessage: 'City',
	},
	lblPaxPostalCode: {
		id: 'app.shopping.paxViewDialog.paxPostalCode',
		defaultMessage: 'Postal Code',
	},
	lblPaxCountry: {
		id: 'app.shopping.paxViewDialog.paxCountry',
		defaultMessage: 'Country',
	},
	lblPaxStateProvince: {
		id: 'app.shopping.paxViewDialog.paxStateProvince',
		defaultMessage: 'State/Province',
	},
	lblPaxPhone: {
		id: 'app.shopping.paxViewDialog.paxPhone',
		defaultMessage: 'Phone',
	},
	lblPaxEmail: {
		id: 'app.shopping.paxViewDialog.paxEmail',
		defaultMessage: 'Email',
	},
	lblPaxAddress: {
		id: 'app.shopping.paxViewDialog.paxAddress',
		defaultMessage: 'Address',
	},
	lblPaxDOB: {
		id: 'app.shopping.paxViewDialog.paxDOB',
		defaultMessage: 'Date of Birth',
	},
	lblType: {
		id: 'app.shopping.paxViewDialog.type',
		defaultMessage: 'Type',
	},
	lblValue: {
		id: 'app.shopping.paxViewDialog.value',
		defaultMessage: 'Value',
	},
	lblTravelDocs: {
		id: 'app.shopping.paxViewDialog.travelDocs',
		defaultMessage: 'Travel Documents',
	},
	lblTravelDocType: {
		id: 'app.shopping.paxViewDialog.travelDocType',
		defaultMessage: 'Travel Document Type',
	},
	lblTravelDocNumber: {
		id: 'app.shopping.paxViewDialog.travelDocNumber',
		defaultMessage: 'Document Number',
	},
	lblTravelDocExpirationDate: {
		id: 'app.shopping.paxViewDialog.travelDocExpirationDate',
		defaultMessage: 'Expiration Date',
	},
	lblTravelDocExpirationDateInvalid: {
		id: 'app.shopping.paxViewDialog.travelDocExpirationDateInvalid',
		defaultMessage: '^Please enter a valid Expiration Date.',
	},
	lblLoyaltyCards: {
		id: 'app.shopping.paxViewDialog.loyaltyCards',
		defaultMessage: 'Fare Discounts and Loyalty Information',
	},
	lblLoyaltyCard: {
		id: 'app.shopping.paxViewDialog.loyaltyCard',
		defaultMessage: 'Loyalty Card:',
	},
	lblLoyaltyProgram: {
		id: 'app.shopping.paxViewDialog.loyaltyProgram',
		defaultMessage: 'Loyalty Program',
	},
	lblLoyaltyCardNumber: {
		id: 'app.shopping.paxViewDialog.loyaltyCardNumber',
		defaultMessage: 'Loyalty Card Number',
	},
	lblPaxInformationUpdateSuccessAndNoteLogSuccess: {
		id: 'app.shopping.paxViewDialog.paxInfoUpdateSuccessAndNoteOk',
		defaultMessage:
			'Passenger information has been updated and a note added to the booking.',
	},
	lblPaxInformationUpdateError: {
		id: 'app.shopping.paxViewDialog.paxInfoUpdateError',
		defaultMessage:
			'Passenger information could not be updated. Please contact your SilverAgent system administrator.',
	},
	lblPaxInformationFieldEmptyError: {
		id: 'app.shopping.paxViewDialog.paxInformationFieldEmptyError',
		defaultMessage: '^This field cannot be left empty.',
	},
	lblBookingLocator: {
		id: 'app.shopping.paxViewDialog.otherBookings.bookingLocator',
		defaultMessage: 'Booking Locator',
	},
	lblDepartDate: {
		id: 'app.shopping.paxViewDialog.otherBookings.departDate',
		defaultMessage: 'Depart Date',
	},
	lblFrom: {
		id: 'app.shopping.paxViewDialog.otherBookings.from',
		defaultMessage: 'From',
	},
	lblOrderStatus: {
		id: 'app.shopping.paxViewDialog.otherBookings.orderStatus',
		defaultMessage: 'Order Status',
	},
	lblOtherBookings: {
		id: 'app.shopping.paxViewDialog.otherBookings.otherBookings',
		defaultMessage: 'Other Bookings',
	},
});
