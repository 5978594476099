import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/system';
import validate from 'validate.js';
import ItineraryDetails from './ItineraryDetails';
import './fareItinerary.css';
import messages from './messagesFareItinerary';
import Fare from './Fare';
import CarbonOffset from './CarbonOffset';
import TwoColRow from './TwoColRow';
import inlineStyles from './fareItineraryStyles';
import { gaEvent } from '../../../utils/googleAnalytics';
import { getIntermediateTravelPointsApi } from '../../apiShopping';
import ServiceAlerts from '../../../Booking/components/ServiceAlerts/ServiceAlerts';

const StyledHeader = styled('div')(() => ({
    ...inlineStyles.header,
}));

const StyledItineraryNumber = styled('div')(() => ({
    ...inlineStyles.itineraryNumber,
}));

const StyledElapsed = styled('span')(() => ({
    ...inlineStyles.elapsed,
}));

const StyledServiceAlertIcon = styled(WarningIcon)(() => ({
    ...inlineStyles.serviceAlert,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    '& .MuiFormControlLabel-label': {
        ...inlineStyles.toggleLabel,
    },
}));

const StyledSwitch = styled(Switch)(() => ({
    ...inlineStyles.toggleIcon,
}));

const StyledItineraryDetailsContainer = styled('div')(() => ({
    ...inlineStyles.itineraryDetailsContainer,
}));

const StyledPaper = styled(Paper)(() => ({
    ...inlineStyles.itinerary,
}));

const StyledLeftRow = styled('div')(() => ({
    ...inlineStyles.leftRow,
}));

const StyledNoMarginRow = styled('div')(() => ({
    ...inlineStyles.noMargin,
}));

const getFareCompatList = (explicitFareCompats, priceId, fareCompatListOtherFare) => {
    const compatiblePrice = explicitFareCompats.find((item) => (item.get('ShoppingLegFareLinkedCompatiblePriceId') === priceId));
    if (!validate.isEmpty(compatiblePrice)) {
        return compatiblePrice.get('ShoppingLegFareLinkedCompatiblePriceIdCompatPrices').toJS();
    }
    if (fareCompatListOtherFare) {
        return -1;
    }
    return null;
};

const isFareCompatible = (currFare, selectedFare, fareCompatibilityList, fareMatchingBasedOnSameCabinClass) => {
    let enableFare = false;

    if (selectedFare) {
        const selFare = selectedFare.fare;

        const isSingleFare = currFare.priceId.indexOf('VP_') !== 0;
        const selectedFareId = selFare.priceId;
        const isSelectedFareSingle = selectedFareId.indexOf('VP_') !== 0;

        // logic for determining which Single fares can be combined (enabled for selection)

        // 1. if fareMatchingBasedOnSameCabinClass is enabled, we enable this fare if this fare and the selected fare
        // have the same cabinClass
        // 2. if there are explicit 'compatible' fares present, we make sure the selected fare is in our
        // 'compatible' fare list
        // 3. otherwise, enable ourself

        // if we are a single fare and the selected fare is single, enable ourself, unless there are
        if (isSelectedFareSingle && isSingleFare) {
            // Enable Singles by default unless specifically disabled by one of the conditions below
            enableFare = true;
            // 1. check if fareMatchingBasedOnSameCabinClass is enable; if so, we make sure that if our
            // cabinClass matches the opposing selection cabinClass, we will enable our fare
            if (fareMatchingBasedOnSameCabinClass) {
                enableFare = currFare.fareCodes[0].cabinClass === selFare.fareCodes[0].cabinClass;
            } else if (fareCompatibilityList === -1) {
                enableFare = false;
            } else if (fareCompatibilityList !== null) {
                // 2. handle case where there is no fareCompatibilityList for this fare and the selected fare
                // check that the selected fare is in this fares fareCompatibilityList
                enableFare = fareCompatibilityList.some((elem) => elem === selectedFareId);
            }
        } else if (!isSelectedFareSingle && !isSingleFare) {
            // only enable the fare if it is in our linked return price list
            enableFare = currFare.linkedReturnPrices.some((elem) => elem === selectedFareId);
        }
    } else {
        enableFare = true;
    }
    return enableFare;
};

class FareItinerary extends Component {
    static propTypes = {
        itinerary: PropTypes.shape({
            legSolutionId: PropTypes.string,
            shoppingContext: PropTypes.string,
            itineraryNumber: PropTypes.string,
            departStation: PropTypes.string,
            arriveStation: PropTypes.string,
            departTime: PropTypes.string,
            departDaysOffsetNum: PropTypes.number,
            departDaysOffsetLabel: PropTypes.string,
            arriveTime: PropTypes.string,
            arriveDaysOffsetNum: PropTypes.number,
            arriveDaysOffsetLabel: PropTypes.string,
            elapsedTime: PropTypes.string,
            nrChanges: PropTypes.number,
            carrierLogo: PropTypes.string,
            overtaken: PropTypes.bool,
            unconfirmedSchedule: PropTypes.bool,
            details: PropTypes.array,
            fares: PropTypes.array,
            marketingCarrier: PropTypes.object,
            serviceAlerts: PropTypes.array,
            revisions: PropTypes.array,
            legSolutionWarnings: PropTypes.array,
        }),
        onChangeSelection: PropTypes.func.isRequired,
        onSelection: PropTypes.func.isRequired,
        selectedFare: PropTypes.object,
        otherFareSelected: PropTypes.object,
        explicitFareCompats: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
        ]),
        fareMatchingBasedOnSameCabinClass: PropTypes.bool,
        singleFareMode: PropTypes.bool,
        openFareRulesMatrix: PropTypes.bool,
        isReturnType: PropTypes.bool,
        legTitle: PropTypes.string,
        addOrderMode: PropTypes.bool,
        exchangeOrderMode: PropTypes.bool,
        isCreateBookingPage: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            data: new Map({
                showDetails: false,
                shoppingContext: props.itinerary.shoppingContext,
                legSolutionId: props.itinerary.legSolutionId,
                serviceAlertsOpen: false,
            }),
        };
    }

    setStateData(showDetails, intermediateTravelPoints) {
        const {
            addOrderMode,
            exchangeOrderMode,
            singleFareMode,
        } = this.props;

        if (addOrderMode) {
            gaEvent(showDetails ? 'addOrderViewDetails' : 'addOrderHideDetails');
        } else if (exchangeOrderMode) {
            gaEvent(showDetails ? 'exchangeOrderViewDetails' : 'exchangeOrderHideDetails');
        } else if (singleFareMode) {
            gaEvent(showDetails ? 'createBookingViewDetails' : 'createBookingHideDetails');
        } else {
            gaEvent(showDetails ? 'fareSearchViewDetails' : 'fareSearchHideDetails');
        }
        this.setState(({ data }) => ({
            data: data.merge({ showDetails }, { intermediateTravelPoints }),
        }));
    }

    handleDetails = (event, showDetails, shoppingContext, legSolutionId) => {
        if (showDetails) {
            getIntermediateTravelPointsApi(shoppingContext, legSolutionId,
                () => {
                    this.setStateData(showDetails, []);
                },
                (response) => {
                    const intermediateTravelPoints = response?.successResponse?.data || [];
                    this.setStateData(showDetails, intermediateTravelPoints);
                });
        } else {
            this.setStateData(showDetails, []);
        }
    };

    handleFareChangeSelection = (fare) => {
        this.props.onChangeSelection(fare);
    };

    handleFareSelection = (fare) => {
        this.props.onSelection(fare, this.props.itinerary.legSolutionId);
    };

    getIntermediateTravelPointByTravelSegmentId = (travelSegmentId) => {
        let itpInTS = [];
        const itpArray = this.state.data.get('intermediateTravelPoints')?.toJS();
        const itpByTravelSegment = itpArray?.filter((itp) => itp.TravelSegmentId === travelSegmentId);
        if (itpByTravelSegment) {
            itpInTS = itpByTravelSegment[0]?.IntermediateTravelPoints;
        }
        return itpInTS;
    }

    switchServiceAlertsOpen = () => {
        const {
            isCreateBookingPage,
            addOrderMode,
            exchangeOrderMode,
        } = this.props;

        let gaCategory = 'Shopping';
        let gaAction = '​Fare Search';

        if (isCreateBookingPage && !addOrderMode && !exchangeOrderMode) {
            gaCategory = 'Shopping';
            gaAction = 'Create Booking';
        }
        if (addOrderMode) {
            gaCategory = 'Booking';
            gaAction = 'Add Order';
        }
        if (exchangeOrderMode) {
            gaCategory = 'Order';
            gaAction = 'Exchange Order';
        }
        this.setState((state) => {
            if (!state.data.get('serviceAlertsOpen')) {
                gaEvent('serviceAlerts', gaCategory, gaAction);
            }
            return { data: state.data.set('serviceAlertsOpen', !state.data.get('serviceAlertsOpen')) };
        });
    }

    renderLeftColumn = () => {
        const {
            itineraryNumber,
            shoppingContext,
            legSolutionId,
            departStation,
            arriveStation,
            departTime,
            arriveTime,
            elapsedTime,
            nrChanges,
            carrierLogo,
            overtaken,
            unconfirmedSchedule,
            details,
            serviceAlerts,
            legSolutionWarnings,
            /* eslint-disable no-unused-vars */
            revisions,
            /* eslint-enable no-unused-vars */
        } = this.props.itinerary;
        const {
            isReturnType,
            legTitle,
        } = this.props;
        const msgHideDetails = <FormattedMessage {...messages.lblHideDetails} />;
        const msgViewDetails = <FormattedMessage {...messages.lblViewDetails} />;
        const carrierLogoUrl = carrierLogo ? `${process.env.API_HOST}ui-sa/${carrierLogo}` : '';

        const passengerRights = details.map((detail) => (Array.isArray(detail.passengerRights) ? detail.passengerRights : []));
        const uniquePassengerRights = [...new Set(passengerRights.flat(1))];
        const allPassengerRights = uniquePassengerRights?.map((passengerRight) => (
            typeof passengerRight === 'string'
                ? (
                    <div styleName="passengerRight" key={passengerRight}>
                        {passengerRight.replace(/\([^)]*\) */, '')}
                    </div>
                )
                : null
        )).filter(Boolean); // removes any null values

        return (
            <>
                <StyledHeader>
                    <StyledItineraryNumber>
                        {isReturnType && `${legTitle}: `}
                        {itineraryNumber}
                        {' '}
                        <StyledElapsed>
                            <FormattedMessage {...messages.lblElapsedTime} values={{ elapsedTime }} />
                            {' '}
                            <FormattedMessage {...messages.lblChanges} values={{ nrChanges }} />
                            {overtaken && (
                                <>
                                    {' '}
                                    <FormattedMessage {...messages.lblOverTaken} />
                                </>
                            )}
                            {unconfirmedSchedule && (
                                <>
                                    {' '}
                                    <FormattedMessage {...messages.lblUnconfirmedSchedule} />
                                </>
                            )}
                        </StyledElapsed>
                        {
                            (serviceAlerts?.length > 0) && (
                                <IconButton onClick={this.switchServiceAlertsOpen}>
                                    <StyledServiceAlertIcon />
                                </IconButton>
                            )
                        }
                    </StyledItineraryNumber>
                    <div>
                        <StyledFormControlLabel
                            onChange={(event, showDetails) => this.handleDetails(event, showDetails, shoppingContext, legSolutionId)}
                            control={<StyledSwitch />}
                            label={this.state.data.get('showDetails') ? msgHideDetails : msgViewDetails}
                        />
                    </div>
                </StyledHeader>
                <StyledItineraryDetailsContainer>
                    {this.state.data.get('showDetails') ? (
                        <div styleName="details">
                            {details.map((detail, index) => (
                                <ItineraryDetails
                                    key={index}
                                    travelSegmentID={detail.travelSegmentID}
                                    departStation={detail.departStation}
                                    arriveStation={detail.arriveStation}
                                    departTime={detail.departTime}
                                    arriveTime={detail.arriveTime}
                                    equipmentDesignator={detail.equipmentDesignator}
                                    carrierLogo={detail.carrierLogo}
                                    connectionTimeText={detail.connectionTimeText}
                                    unconfirmedSchedule={detail.unconfirmedSchedule}
                                    warnings={detail.warnings}
                                    duration={detail.duration}
                                    passengerRights={detail.passengerRights}
                                    carbonOffset={detail.carbonOffset}
                                    intermediateTravelPoints={this.getIntermediateTravelPointByTravelSegmentId(detail.travelSegmentID) ?? []}
                                >
                                    {detail.carbonOffset && <CarbonOffset value={detail.carbonOffset} />}
                                </ItineraryDetails>
                            ))}
                        </div>
                    ) : (
                        <>
                            <TwoColRow firstColLabel={departStation} secondColLabel={departTime} />
                            <TwoColRow firstColLabel={arriveStation} secondColLabel={arriveTime} />
                        </>
                    )}
                </StyledItineraryDetailsContainer>
                <div>
                    {carrierLogoUrl
                        ? <img role="presentation" src={carrierLogoUrl} styleName="logo" />
                        : <span styleName="multiple"><FormattedMessage {...messages.lblMultiple} /></span>}
                </div>
                {allPassengerRights}
                {
                    legSolutionWarnings?.map((warning) => <div styleName="warning" key={warning}>{warning.warningMessage}</div>)
                }
                {
                    (serviceAlerts?.length > 0) && (
                        <ServiceAlerts
                            open={this.state.data.get('serviceAlertsOpen')}
                            serviceAlerts={serviceAlerts}
                            onClose={this.switchServiceAlertsOpen}
                            originStation={departStation}
                            destinationStation={arriveStation}
                            departTime={departTime}
                            arriveTime={arriveTime}
                        />
                    )
                }
            </>
        );
    }

    render() {
        const {
            legSolutionId,
            fares,
            marketingCarrier,
        } = this.props.itinerary;
        const {
            selectedFare,
            otherFareSelected,
            explicitFareCompats,
            fareMatchingBasedOnSameCabinClass,
            singleFareMode,
            openFareRulesMatrix,
            isReturnType,
            addOrderMode,
            exchangeOrderMode,
            isCreateBookingPage,
        } = this.props;
        const leftColumn = singleFareMode ? 'col-sm-9 col-md-7 col-lg-8' : 'col-sm-6 col-md-5 col-lg-3';
        const rightColumn = singleFareMode ? 'col-sm-3 col-md-5 col-lg-4' : 'col-sm-6 col-md-7 col-lg-9';

        return (
            <div
                className="col-12"
                id={`Itinerary_${legSolutionId}`}
                style={isReturnType ? inlineStyles.returnJourneyFares : inlineStyles.noPadding}
            >
                {!isReturnType
                    ? (
                        <StyledPaper className="row" elevation={1}>
                            <StyledLeftRow className={leftColumn}>
                                {this.renderLeftColumn()}
                            </StyledLeftRow>
                            <div className={rightColumn}>
                                {fares.map((fare, index) => {
                                    let fareComp = null;
                                    const fareSelected = !!selectedFare && selectedFare.fare.priceId === fare.priceId;
                                    let disableFare = false;
                                    if (!selectedFare && otherFareSelected) {
                                        const otherPriceId = otherFareSelected.fare.priceId;
                                        const fareCompatListOther = explicitFareCompats
                                            && explicitFareCompats.find((item) => (item.get('ShoppingLegFareLinkedCompatiblePriceId') === otherPriceId));
                                        const fareCompatList = explicitFareCompats
                                            && getFareCompatList(explicitFareCompats, fare.priceId, fareCompatListOther);
                                        disableFare = !isFareCompatible(fare, otherFareSelected, fareCompatList, fareMatchingBasedOnSameCabinClass);
                                    }
                                    if (!selectedFare || fareSelected) {
                                        fareComp = (
                                            <Fare
                                                key={index}
                                                fare={fare}
                                                marketingCarrier={marketingCarrier}
                                                onChangeSelection={this.handleFareChangeSelection}
                                                onSelection={this.handleFareSelection}
                                                selected={fareSelected}
                                                disabled={disableFare}
                                                onOpenFareDetails={() => { }}
                                                openFareRulesMatrix={openFareRulesMatrix}
                                                addOrderMode={addOrderMode}
                                                exchangeOrderMode={exchangeOrderMode}
                                                isCreateBookingPage={isCreateBookingPage}
                                            />
                                        );
                                    }
                                    return fareComp;
                                })}
                            </div>
                        </StyledPaper>
                    )
                    : (
                        <StyledNoMarginRow className="row">
                            <div className={leftColumn}>
                                {this.renderLeftColumn()}
                            </div>
                        </StyledNoMarginRow>
                    )}
            </div>
        );
    }
}

export { FareItinerary as FareItineraryAlias };

export default FareItinerary;
