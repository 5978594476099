import { lightGray } from '../../../colors';

export default {
	dialogActionsRoot: {
		width: '100%',
	},
	dialogContent: {
		borderTop: `1px solid ${lightGray}`,
		borderBottom: `1px solid ${lightGray}`,
	},
	refresh: {
		marginTop: '10%',
		marginLeft: '45%',
	},
	tableRight: {
		textAlign: 'right',
	},
	legColumn: {
		width: 50,
		paddingLeft: 10,
		paddingRight: 10,
	},
	borderBalance: {
		borderTop: `1px solid ${lightGray}`,
	},
	balanceMargin: {
		marginTop: 15,
	},
	subHeader: {
		marginTop: 30,
	},
	box: {
		marginTop: 30,
		padding: 15,
		backgroundColor: '#fff3cd',
		borderTop: '1px solid #E0E0E0',
		borderBottom: '1px solid #E0E0E0',
	},
};
