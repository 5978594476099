import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import messages from './messagesOrderDetails';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import OrderDetailsTable from '../OrderDetailsTable/OrderDetailsTable';
import SeasonTable from '../OrderDetailsTable/SeasonTable';
import { formatPrice } from '../../../utils';
import '../../ManageBooking/components/BookingView/styleBookingView.css';
import inlineStyles from './styles';

const getTotalPrice = (priceLabel, price, currency, intl) =>
	`${priceLabel} ${formatPrice(Number(price), currency, intl)}`;

const OrderDetailsView = ({
	bookingDetails,
	fareRulesDetails,
	isSingleTicketableFares,
	seasonFareDetails,
	showIntermediateStops,
	promotionDetails,
	id,
	disabled,
	intl,
	bookingOrderRevisions,
	isSplitTicketFare,
}) => {
	let totalPrice = null;

	if (seasonFareDetails) {
		totalPrice = `${intl.formatMessage(messages.lblSeasonPrice)} ${seasonFareDetails.farePrice}`;
	} else if (bookingDetails?.BookingDetailsOrderTotalPrice) {
		totalPrice = getTotalPrice(
			intl.formatMessage(messages.lblOrderTotalPrice),
			bookingDetails.BookingDetailsOrderTotalPrice,
			bookingDetails.BookingDetailsOrderTotalPriceCurrency,
			intl,
		);
	}

	return (
		<>
			{!seasonFareDetails ? (
				<OrderDetailsTable
					promotionDetails={promotionDetails}
					fareRulesDetails={fareRulesDetails}
					isSingleTicketableFares={isSingleTicketableFares}
					orderDetails={bookingDetails}
					showIntermediateStops={showIntermediateStops}
					orderPriceKey="ticketableFarePrice"
					origin="BOOKING"
					bookingOrderRevisions={bookingOrderRevisions}
					isSplitTicketFare={isSplitTicketFare}
				/>
			) : (
				<SeasonTable
					fareRulesDetails={fareRulesDetails}
					orderDetails={seasonFareDetails}
					origin="BOOKING"
				/>
			)}
			<div
				id={`srtBookingTotal_${id}`}
				data-testid="total-price"
				style={inlineStyles.boldText}
			>
				{totalPrice}
			</div>
			<RefreshIndicator
				size={36}
				top={0}
				left={0}
				status={disabled ? 'loading' : 'hide'}
				style={inlineStyles.refreshIndicator}
			/>
		</>
	);
};

OrderDetailsView.propTypes = {
	bookingDetails: PropTypes.object,
	disabled: PropTypes.bool,
	fareRulesDetails: PropTypes.array,
	id: PropTypes.string.isRequired,
	intl: PropTypes.object,
	isSingleTicketableFares: PropTypes.bool,
	seasonFareDetails: PropTypes.object,
	showIntermediateStops: PropTypes.bool,
	promotionDetails: PropTypes.array,
	bookingOrderRevisions: PropTypes.array,
	isSplitTicketFare: PropTypes.bool,
};

export { OrderDetailsView as OrderDetailsViewAlias };
export default injectIntl(OrderDetailsView);
