import { defineMessages } from 'react-intl';

export default defineMessages({
	btnViewTicketDelivery: {
		id: 'app.shopping.manageBooking.superOrderView.viewTicketDelivery',
		defaultMessage: 'View Ticket Delivery',
	},
	btnEditTicketDelivery: {
		id: 'app.shopping.manageBooking.superOrderView.editTicketDelivery',
		defaultMessage: 'Edit Ticket Delivery',
	},
	btnAddTicketDelivery: {
		id: 'app.shopping.manageBooking.superOrderView.addTicketDelivery',
		defaultMessage: 'Add Ticket Delivery',
	},
	lblTrackingInfoDialogTitle: {
		id: 'app.shopping.trackingInfoDialog.title',
		defaultMessage: 'Tracking Info',
	},
	lblReturnCouponsDialogTitle: {
		id: 'app.shopping.returnCouponsDialog.title',
		defaultMessage: 'Return Coupons',
	},
	lblConfirmedCardInfoDialogTitle: {
		id: 'app.shopping.confirmedCardViewDialog.title',
		defaultMessage: 'Selected Credit Card Confirmation Information',
	},
	lblAuthorizeRefundDialogTitle: {
		id: 'app.shopping.authorizeRefundDialog.title',
		defaultMessage: 'Authorize Refund',
	},
	lblCancelTitle: {
		id: 'app.shopping.manageBooking.cancelOrder.title',
		defaultMessage: 'Cancel Order',
	},
	lblVoidTitle: {
		id: 'app.shopping.manageBooking.voidOrder.title',
		defaultMessage: 'Void Order',
	},
	lblConfirmOrderDialogTitle: {
		id: 'app.booking.confirmOrderDialog.title',
		defaultMessage: 'Confirmation',
	},
	lblTdoTitle: {
		id: 'app.common.ticketDelivery.title',
		defaultMessage: 'Ticket Delivery Options',
	},
	lblOrderConfirmationTitle: {
		id: 'app.booking.bookingEmailConfirmationDialog.title',
		defaultMessage: 'Order Confirmation',
	},
	lblStationInformationDialogTitle: {
		id: 'app.common.stationInformation.title',
		defaultMessage: 'Station Information',
	},
	lblSendTicketTitle: {
		id: 'app.booking.resendTicketDialog.titleClaim',
		defaultMessage: 'Send Ticket',
	},
	lblExchangeOrderDialogTitle: {
		id: 'app.booking.exchangeOrder.title',
		defaultMessage: 'Exchange Order',
	},
	lblSupplierNotes: {
		id: 'app.shopping.manageBooking.notes.supplierNotes',
		defaultMessage: 'Supplier Notes',
	},
	lblAmendSeatReservations: {
		id: 'app.booking.amendSeatReservationsDialog.title',
		defaultMessage: 'Amend Seat Reservations',
	},
});
