import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import Header from '../components/Header/Header';

import CreateBooking from './CreateBooking/CreateBooking';
import ShoppingSearch from './ShoppingSearch/ShoppingSearch';
import ShoppingResults from './ShoppingResults/ShoppingResults';

const Shopping = ({ loginId, settings, match }) => {
	const hasSettings = loginId && settings;

	return (
		<div>
			{hasSettings && (
				<>
					<Header activeTab={0} />
					<Switch>
						<Route path={`${match.path}/Search`} component={ShoppingSearch} />
						<Route
							path={`${match.path}/ShoppingResults`}
							component={ShoppingResults}
						/>
						<Route
							path={`${match.path}/CreateBooking`}
							component={CreateBooking}
						/>
						<Redirect path="*" to={`${match.path}/Search`} />
					</Switch>
				</>
			)}
		</div>
	);
};

Shopping.propTypes = {
	loginId: PropTypes.string,
	settings: PropTypes.object,
	match: PropTypes.object,
};

export default connect((state) => ({
	loginId: state.getIn(['settings', 'UserLoginId']),
	settings: state.get('settings'),
}))(Shopping);
