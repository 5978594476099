import { midGray5, darkGray } from '../../colors';

export default {
	refresh: {
		marginTop: '40%',
		marginLeft: '45%',
	},
	buttons: {
		margin: 10,
	},
	buttonsContainer: {
		display: 'flex',
	},
	subheaderBorder: {
		marginBottom: 10,
		marginTop: -10,
		border: 0,
		height: 1,
		background: darkGray,
		backgroundImage: `linear-gradient(to right, ${midGray5}, ${darkGray}, ${midGray5})`,
	},
	paperContainer: {
		marginTop: 16,
		marginBottom: 10,
		padding: '0 15px 15px 15px',
	},
	cardTitle: {
		fontSize: 20,
	},
	indicator: {
		marginTop: '15%',
		marginLeft: '49%',
		zIndex: 1301,
	},
	amtrakTandC: {
		marginLeft: -10,
		marginRight: -10,
		padding: 15,
		fontWeight: 400,
		textAlign: 'right',
	},
};
