import { fromJS } from 'immutable';
import {
	SET_ACCESS_TOKEN,
	SET_ACCESS_TOKEN_EXPIRY_TIME,
	USER_LOGOUT,
} from '../constants/constants';

const initialState = fromJS({
	accessToken: null,
	tokenExpiryTime: null,
});

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACCESS_TOKEN:
			return state.set('accessToken', action.payload);
		case SET_ACCESS_TOKEN_EXPIRY_TIME:
			return state.set('tokenExpiryTime', action.payload);
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default authReducer;
