import { defineMessages } from 'react-intl';

export default defineMessages({
	lblPaymentMethod: {
		id: 'app.common.payment.method',
		defaultMessage: 'Payment Method',
	},
	lblPaymentSelect: {
		id: 'app.common.payment.select',
		defaultMessage: '--- Select Method ---',
	},
	lblPaymentCardName: {
		id: 'app.common.payment.cardName',
		defaultMessage: 'Name on card',
	},
	lblPaymentFirstName: {
		id: 'app.common.payment.FirstName',
		defaultMessage: 'First Name',
	},
	lblPaymentMiddleName: {
		id: 'app.common.payment.MiddleName',
		defaultMessage: 'Middle Name',
	},
	lblPaymentLastName: {
		id: 'app.common.payment.LastName',
		defaultMessage: 'Last Name',
	},
	lblPaymentCardNumber: {
		id: 'app.common.payment.cardNumber',
		defaultMessage: 'Card Number',
	},
	lblPaymentCardNumberFirstSixDigits: {
		id: 'app.common.payment.firstSixDigits',
		defaultMessage: 'First 6 Digits',
	},
	lblPaymentCardNumberLastFourDigits: {
		id: 'app.common.payment.lastFourDigits',
		defaultMessage: 'Last 4 Digits',
	},
	lblPaymentExpiryDate: {
		id: 'app.common.payment.expiryDate',
		defaultMessage: 'Expiry date',
	},
	lblMonth: {
		id: 'app.common.payment.month',
		defaultMessage: 'Month',
	},
	lblYear: {
		id: 'app.common.payment.year',
		defaultMessage: 'Year',
	},
	lblPaymentStartDate: {
		id: 'app.common.payment.startDate',
		defaultMessage: 'Start date',
	},
	lblPaymentIssueNumber: {
		id: 'app.common.payment.issueNumber',
		defaultMessage: 'Issue Number',
	},
	lblPaymentSecurityCode: {
		id: 'app.common.payment.securityCode',
		defaultMessage: 'Security Code',
	},
	lblPaymentSortCode: {
		id: 'app.common.payment.sortCode',
		defaultMessage: 'Sort Code',
	},
	lblEndingNumber: {
		id: 'app.common.payment.endingNumber',
		defaultMessage:
			'Payment will be made with account ending in {endingNumber}',
	},
});
