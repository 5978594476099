import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import messages from '../messagesReservations';
import SeatSelectorDialog from './SeatSelectorDialog/SeatSelectorDialog';
import Seats from './Seats';
import '../reservations.css';
import {
	getSeatPreferencesByType,
	determineReservationScenario,
	calculateReservationPrice,
	filterSeatPreferencesByCarrierAndClass,
	getReservable,
} from '../../../../util';
import { formatPrice } from '../../../../../utils';
import { gaEvent } from '../../../../../utils/googleAnalytics';
import { getLists, getSelectedSeatReservations } from './util';
import DropDownList from './DropDownList';
import SeatForAll from './SeatForAll';
import SeatSelector from './SeatSelector';

const SEAT_VALUES = {
	SPECIFIC_SEAT: 'specificSeat',
	SEAT_ATTRIBUTES: 'seatAttributes',
};

const SeatPreferences = ({
	travelSegments,
	seatPreferences,
	optionalPrices,
	fareCodes,
	intl,
	selectedSeatReservations,
	passengerId,
	paxLength,
	onChangeReservation,
	onChangeAllSegmentReservation,
	onChangeAllLegReservation,
	onChangePassengerReservation,
	exchangeOrderMode,
	selectedPax,
	shoppingContext,
	seatMapData,
	useSeatAttributes = false,
}) => {
	const { formatMessage } = intl;

	const state = travelSegments.reduce(
		(obj, item) => ({
			...obj,
			[item.TravelSegmentID]: useSeatAttributes
				? SEAT_VALUES.SEAT_ATTRIBUTES
				: SEAT_VALUES.SPECIFIC_SEAT,
		}),
		{},
	);
	const [seatChoice, setSeatChoice] = useState(state);
	const [seatSelectorOpen, setSeatSelectorOpen] = useState(false);
	const [selectedTravelSegmentID, setSelectedTravelSegmentID] = useState();
	const [selectedStations, setSelectedStations] = useState({
		departStation: '',
		arriveStation: '',
	});
	const [seatMapOption, setSeatMapOption] = useState(false);

	const seatMapOptionSet = useRef(false);

	useEffect(() => {
		if (seatMapOption) {
			if (exchangeOrderMode) {
				gaEvent('exchangeOrderSeatMapApplicable');
			} else {
				gaEvent('seatMapUxApplicable');
			}
		} else if (exchangeOrderMode) {
			gaEvent('exchangeOrderSeatMapNotApplicable');
		} else {
			gaEvent('seatMapUxNotApplicable');
		}
	}, [seatMapOption, exchangeOrderMode]);

	const handleChange =
		(type, travelSegmentId) =>
		({ target: { value } }) => {
			onChangeReservation(travelSegmentId, passengerId, {
				[type]: value || null,
			});
		};

	const handleOtherCheck = (travelSegmentId) => (event, checked) => {
		let other = { [event.target.name]: checked };
		if (event.target.name === 'NEARBY' && !checked) {
			other = { [event.target.name]: checked, nearbyCoach: '', nearbySeat: '' };
		}
		onChangeReservation(travelSegmentId, passengerId, other);
	};

	const handlePassengerSeatsToggle = (travelSegmentId) => (event, value) => {
		onChangePassengerReservation(
			travelSegmentId,
			passengerId,
			value,
			parseFloat(event.target.value),
		);
	};

	const handleTravelSegmentAllSeatsToggle =
		(travelSegmentId) => (event, value) => {
			onChangeAllSegmentReservation(
				travelSegmentId,
				value,
				parseFloat(event.target.value),
			);
		};

	const handleLegAllSeatsToggle = (travelSegmentId) => (event, value) => {
		const reservables =
			seatPreferences.reservables && seatPreferences.reservables.length > 0
				? seatPreferences.reservables
				: [];
		onChangeAllLegReservation(
			travelSegments,
			travelSegmentId,
			reservables,
			value,
			parseFloat(event.target.value),
		);
	};

	const handleCheckField = (travelSegmentId, allOptions) => (event, value) => {
		if (
			value === SEAT_VALUES.SPECIFIC_SEAT ||
			value === SEAT_VALUES.SEAT_ATTRIBUTES
		) {
			setSeatChoice((prevState) => ({
				...prevState,
				[travelSegmentId]: value,
			}));
		}
		if (value === SEAT_VALUES.SPECIFIC_SEAT) {
			onChangeReservation(travelSegmentId, passengerId, {
				...allOptions,
				SPECIFICSEAT: true,
				nearbyCoach: '',
				nearbySeat: '',
				deckLevel: '',
			});
		} else if (value === SEAT_VALUES.SEAT_ATTRIBUTES) {
			onChangeReservation(travelSegmentId, passengerId, {
				SPECIFICSEAT: false,
				nearbyCoach: '',
				nearbySeat: '',
				deckLevel: '',
			});
		}
	};

	const onDiscardSeatSelector = () => {
		setSeatSelectorOpen(false);
	};

	const onRequestSeats = (
		travelSegmentId,
		coachSelected,
		seatSelected,
		selectedDeckLevel,
		selectedSeat,
	) => {
		setSeatSelectorOpen(false);
		onChangeReservation(travelSegmentId, passengerId, {
			SPECIFICSEAT: true,
			nearbyCoach: coachSelected,
			nearbySeat: seatSelected,
			deckLevel: selectedDeckLevel,
			selectedSeat,
		});
	};

	const handleClick =
		(travelSegmentID, departStation, arriveStation) => (e) => {
			e.preventDefault();
			if (exchangeOrderMode) {
				gaEvent('exchangeOrderInitiateSeatMap');
			} else {
				gaEvent('initiatesSeatmapComponent');
			}
			setSeatSelectorOpen(true);
			setSelectedTravelSegmentID(travelSegmentID);
			setSelectedStations({ departStation, arriveStation });
		};

	return (
		<div className="row">
			<div className="col-12">
				{travelSegments.map((item, index) => {
					const {
						TravelSegmentID,
						ShoppingFareDepartStationSegment,
						ShoppingFareArriveStationSegment,
					} = item;

					const {
						enableAll,
						selectedReservation,
						selectedDirection,
						selectedPosition,
						selectedCoach,
						selectedLevel,
						selectedLayout,
						selectedNearbyCoach,
						selectedNearbySeat,
						selectedDisabled,
					} = getSelectedSeatReservations(
						selectedSeatReservations,
						TravelSegmentID,
						passengerId,
					);

					let disabled = selectedDisabled;

					const isFareCodeLengthMatch =
						fareCodes.length / paxLength === travelSegments.length;

					let serviceClass;

					if (isFareCodeLengthMatch) {
						serviceClass = fareCodes[index * paxLength]
							? fareCodes[index * paxLength].serviceClass
							: {};
					} else {
						serviceClass = fareCodes[0] ? fareCodes[0].serviceClass : {};
					}

					// Seat Preferences calculations
					const filteredSeatPreferences =
						filterSeatPreferencesByCarrierAndClass(
							seatPreferences,
							item.ShoppingFareMarketingCarrier,
							item.ShoppingFareSupplierEquipmentType,
							serviceClass,
							TravelSegmentID,
						);

					const seatPrefByType = getSeatPreferencesByType(
						filteredSeatPreferences,
					);

					const lists = getLists(
						formatMessage,
						seatPrefByType,
						messages,
						selectedDirection,
						selectedPosition,
						selectedCoach,
						selectedLevel,
						selectedLayout,
					);

					const otherList = seatPrefByType.otherOptions;
					const seatMap = seatPrefByType.seatmapOptions;
					const hasSeatMap =
						seatMapData?.find(
							(segment) => segment.TravelSegmentID === TravelSegmentID,
						)?.SeatMapInformation || false;

					if ((seatMap.length > 0 || hasSeatMap) && !seatMapOptionSet.current) {
						setSeatMapOption(true);
						seatMapOptionSet.current = true;
					}
					const travelSegment = seatMapData?.find(
						(segment) => segment.TravelSegmentID === TravelSegmentID,
					);
					const isSeatMapAPIKeyAccessible =
						travelSegment?.SeatMapInformation?.SeatMapAPIKeyAccessible || false;

					let currency = '';
					const seatReservables =
						filteredSeatPreferences.reservables &&
						filteredSeatPreferences.reservables.length > 0
							? filteredSeatPreferences.reservables
							: [];
					let fareOptionalPrices = [];
					const prefPresentString =
						filteredSeatPreferences.preferences &&
						filteredSeatPreferences.preferences.length >= 1
							? `: ${formatMessage(messages.lblChoosePref)}`
							: '.';
					const reservable = getReservable(seatReservables, TravelSegmentID);

					if (
						reservable.reservable === 'OPTIONAL' ||
						reservable.reservable === 'MANDATORY' ||
						reservable.reservable === 'INCLUDED'
					) {
						fareOptionalPrices = optionalPrices;
						currency =
							fareOptionalPrices.length > 0
								? fareOptionalPrices[0].currency
								: '';
					}

					const strategy = determineReservationScenario(
						seatReservables,
						fareOptionalPrices,
						paxLength,
					);

					// Seats for All section
					const numTravelSegments =
						strategy.legBasedReservation && travelSegments.length === 1
							? 0
							: Math.round(travelSegments.length / 2);

					// Seats for Passenger
					let seatChoiceSelection =
						seatChoice[TravelSegmentID] || SEAT_VALUES.SPECIFIC_SEAT;
					let showSeatAssignments = false;
					let seatsForPassenger = null;
					if (
						reservable.reservable === 'OPTIONAL' &&
						fareOptionalPrices.length > 0
					) {
						if (strategy.passengerBasedReservation) {
							const price = calculateReservationPrice(
								strategy,
								fareOptionalPrices,
								passengerId,
								TravelSegmentID,
								paxLength,
							);
							seatsForPassenger = (
								<Seats
									price={price}
									formattedPrice={formatPrice(price, currency, intl)}
									enabled={!disabled}
									handlePassengerSeatsToggle={handlePassengerSeatsToggle}
									formatMessage={formatMessage}
									travelSegmentID={TravelSegmentID}
									passengerId={passengerId}
									type="passenger"
								/>
							);
						}
						showSeatAssignments = true;
					} else if (
						reservable.reservable === 'MANDATORY' ||
						reservable.reservable === 'INCLUDED'
					) {
						disabled = false;
						showSeatAssignments = !seatsForPassenger;
					} else {
						seatsForPassenger = (
							<span>{formatMessage(messages.lblPrefNotSpec)}</span>
						);
						showSeatAssignments = false;
					}

					let showSeatChoice = false;

					if (
						!selectedReservation.NEARBY &&
						!selectedReservation.SPECIFICSEAT
					) {
						showSeatChoice = true;
						showSeatAssignments = !seatsForPassenger;
						seatChoiceSelection =
							seatChoice[TravelSegmentID] || SEAT_VALUES.SPECIFIC_SEAT;
					} else if (
						(selectedReservation.SPECIFICSEAT &&
							selectedReservation.nearbyCoach &&
							selectedReservation.nearbySeat) ||
						seatChoice[TravelSegmentID] === SEAT_VALUES.SPECIFIC_SEAT
					) {
						showSeatChoice = true;
						showSeatAssignments = false;
						seatChoiceSelection = SEAT_VALUES.SPECIFIC_SEAT;
					} else {
						showSeatChoice = false;
						showSeatAssignments = !seatsForPassenger;
						seatChoiceSelection =
							seatChoice[TravelSegmentID] || SEAT_VALUES.SPECIFIC_SEAT;
					}

					const lblTo = formatMessage(messages.lblTo);

					const listsOptions = lists.reduce((obj, itemList) => {
						if (itemList.list.length > 0) {
							return { ...obj, [itemList.type]: null };
						}
						return obj;
					}, {});

					const otherListOptions = otherList.reduce(
						(obj, itemList) => ({ ...obj, [itemList.value]: false }),
						{},
					);

					const allOptions = { ...listsOptions, ...otherListOptions };

					return (
						<div className="row" key={TravelSegmentID}>
							<div className="col-4" styleName="leftContent leftContentBorder">
								<SeatForAll
									numTravelSegments={numTravelSegments}
									index={index}
									strategy={strategy}
									fareOptionalPrices={fareOptionalPrices}
									paxLength={paxLength}
									currency={currency}
									intl={intl}
									enableAll={enableAll}
									handleLegAllSeatsToggle={handleLegAllSeatsToggle}
									handleTravelSegmentAllSeatsToggle={
										handleTravelSegmentAllSeatsToggle
									}
									TravelSegmentID={TravelSegmentID}
									reservable={reservable}
									prefPresentString={prefPresentString}
								/>
							</div>
							<div className="col-8">
								<div className="row" styleName="rightSection">
									<div className="col-12" styleName="segment journeySegment">
										{`${item.ShoppingFareDepartStationSegment} ${lblTo} ${item.ShoppingFareArriveStationSegment}`}
										<span styleName="seatsAvailable">
											{item.isOverbooked && (
												<FormattedMessage {...messages.lblOverbooked} />
											)}
											{item.TravelSegmentSeatsAvailable &&
												item.isOverbooked &&
												', '}
											{item.TravelSegmentSeatsAvailable}
										</span>
									</div>
									<div className="col-12" styleName="rightContent">
										{(seatMap.length > 0 || hasSeatMap) && (
											<div styleName="seatSelector">
												<RadioGroup
													name="seatChoice"
													onChange={handleCheckField(
														TravelSegmentID,
														allOptions,
													)}
													value={seatChoiceSelection}
												>
													<FormControlLabel
														id="srtSpecificSeat"
														key="srtSpecificSeat"
														value="specificSeat"
														control={<Radio />}
														label={formatMessage(messages.lblSpecificSeat)}
													/>
													{showSeatChoice && (
														<div>
															{isSeatMapAPIKeyAccessible && (
																<div>
																	<FormattedMessage
																		{...messages.lblSeatSelectorLink}
																		values={{
																			SeatSelectorLink: (
																				<a
																					href="#"
																					onClick={handleClick(
																						TravelSegmentID,
																						ShoppingFareDepartStationSegment,
																						ShoppingFareArriveStationSegment,
																					)}
																				>
																					{intl.formatMessage(
																						messages.lblSeatSelectorText,
																					)}
																				</a>
																			),
																		}}
																	/>
																</div>
															)}
															<SeatSelector
																formatMessage={formatMessage}
																handleChange={handleChange}
																TravelSegmentID={TravelSegmentID}
																passengerId={passengerId}
																other={{
																	description: 'Specificseat',
																	value: 'SPECIFICSEAT',
																}}
																selectedNearbyCoach={selectedNearbyCoach}
																selectedReservation={selectedReservation}
																selectedNearbySeat={selectedNearbySeat}
															/>
														</div>
													)}
													<FormControlLabel
														id="srtSeatAttributes"
														key="srtSeatAttributes"
														value="seatAttributes"
														control={<Radio />}
														label={formatMessage(messages.lblSeatAttributes)}
													/>
												</RadioGroup>
											</div>
										)}
										{seatsForPassenger}
										{showSeatAssignments &&
										seatChoiceSelection !== SEAT_VALUES.SPECIFIC_SEAT ? (
											<div className="row">
												<div className="col-6">
													{lists.map(
														(itemList) =>
															!!itemList.list.length && (
																<DropDownList
																	key={itemList.type}
																	travelSegmentId={TravelSegmentID}
																	passengerId={passengerId}
																	handleChange={handleChange}
																	disabled={disabled}
																	itemList={itemList}
																/>
															),
													)}
												</div>
												<div className="col-6">
													{!!otherList.length &&
														otherList.map((other) => (
															<div
																className="row"
																key={`${TravelSegmentID}_${passengerId}_${other.value}`}
															>
																<div className="col-12">
																	<FormControlLabel
																		control={
																			<Checkbox
																				id={`srt${TravelSegmentID}_${passengerId}_${other.value}`}
																				onChange={handleOtherCheck(
																					TravelSegmentID,
																				)}
																				name={other.value}
																				disabled={disabled}
																				checked={Boolean(
																					selectedReservation[other.value],
																				)}
																			/>
																		}
																		label={other.description}
																	/>
																</div>
																{Boolean(selectedReservation[other.value]) &&
																	other.description ===
																		formatMessage(messages.lblNearby) && (
																		<SeatSelector
																			formatMessage={formatMessage}
																			handleChange={handleChange}
																			TravelSegmentID={TravelSegmentID}
																			passengerId={passengerId}
																			other={other}
																			selectedNearbyCoach={selectedNearbyCoach}
																			selectedReservation={selectedReservation}
																			selectedNearbySeat={selectedNearbySeat}
																		/>
																	)}
															</div>
														))}
												</div>
											</div>
										) : (
											<div />
										)}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<SeatSelectorDialog
				open={seatSelectorOpen}
				intl={intl}
				onDiscardSeatSelector={onDiscardSeatSelector}
				onRequestSeats={onRequestSeats}
				exchangeOrderMode={exchangeOrderMode}
				travelSegmentID={selectedTravelSegmentID}
				departStation={selectedStations.departStation}
				arriveStation={selectedStations.arriveStation}
				selectedPax={selectedPax === 0 ? 1 : selectedPax}
				passengerId={passengerId}
				selectedSeatReservations={selectedSeatReservations}
				paxLength={paxLength}
				shoppingContext={shoppingContext}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const seatMapData = state.getIn(['booking', 'seatMap']);
	return {
		seatMapData: seatMapData ? seatMapData.toJS() : seatMapData,
	};
};

SeatPreferences.propTypes = {
	intl: PropTypes.object,
	travelSegments: PropTypes.array.isRequired,
	seatPreferences: PropTypes.object,
	optionalPrices: PropTypes.array,
	fareCodes: PropTypes.array,
	selectedSeatReservations: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	passengerId: PropTypes.string,
	paxLength: PropTypes.number,
	onChangeReservation: PropTypes.func,
	onChangeAllSegmentReservation: PropTypes.func,
	onChangeAllLegReservation: PropTypes.func,
	onChangePassengerReservation: PropTypes.func,
	exchangeOrderMode: PropTypes.bool,
	selectedPax: PropTypes.number,
	shoppingContext: PropTypes.string,
	seatMapData: PropTypes.array,
	useSeatAttributes: PropTypes.bool,
};

export { SeatPreferences as SeatPreferencesAlias };

export default connect(mapStateToProps, null)(injectIntl(SeatPreferences));
