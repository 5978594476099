import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import messages from './messagesStationInformation';
import './stationInformation.css';

function DisplayLatLong(props) {
	return (
		<div className="row">
			<div className="col-12">
				<div
					className="row"
					styleName={props.indexValue % 2 === 0 ? 'odd' : 'even'}
				>
					<div className="col-6" styleName="stationInfoName">
						{props.intl.formatMessage(messages.lblLatitude)}
					</div>
					<div className="col-6">{props.latitude}</div>
				</div>
				<div
					className="row"
					styleName={props.indexValue % 2 === 0 ? 'even' : 'odd'}
				>
					<div className="col-6" styleName="stationInfoName">
						{props.intl.formatMessage(messages.lblLongitude)}
					</div>
					<div className="col-6">{props.longitude}</div>
				</div>
			</div>
		</div>
	);
}

DisplayLatLong.propTypes = {
	intl: PropTypes.object,
	indexValue: PropTypes.number,
	latitude: PropTypes.number,
	longitude: PropTypes.number,
};

export { DisplayLatLong as DisplayLatLongAlias };

export default injectIntl(DisplayLatLong);
