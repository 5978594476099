import { defineMessages } from 'react-intl';

export default defineMessages({
	lblCustomInfoType: {
		id: 'app.common.customInfo.type',
		defaultMessage: 'Type',
	},
	lblCustomInfoValue: {
		id: 'app.common.customInfo.value',
		defaultMessage: 'Value',
	},
	lblCustomInfoRequired: {
		id: 'app.common.customInfo.required',
		defaultMessage: 'Required',
	},
	lblCustomInfoAdd: {
		id: 'app.common.customInfo.add',
		defaultMessage: 'Add',
	},
	lblCustomInfoRemove: {
		id: 'app.common.customInfo.remove',
		defaultMessage: 'Remove',
	},
});
