import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { injectIntl } from 'react-intl';

import { TYPES } from './constants';
import CancelVoidOrderMode from './CancelVoidOrderMode';
import CancelPenaltyDialog from './CancelPenaltyDialog';
import messages from './messages';

import {
	getCancellationPenaltyApi,
	submitCancelBookingApi,
} from '../../apiBooking';
import { fetchUserData } from '../../../Shopping/ShoppingSearch/actionsShoppingSearch';
import {
	fetchBookingDetails,
	setSendTicketNewOrder,
} from '../../ManageBooking/actionsManageBooking';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../../utils/googleAnalytics';
import { MODE_TYPES } from '../AllPartModeSelect/constants';

class CancelVoidOrderDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		onCloseDialog: PropTypes.func.isRequired,
		onSubmit: PropTypes.func,
		open: PropTypes.bool.isRequired,
		orderId: PropTypes.string,
		queryItems: PropTypes.object,
		type: PropTypes.string.isRequired,
		onSetSendTicketNewOrder: PropTypes.func,
		orders: PropTypes.array,
		availableSuppliers: PropTypes.array,
		onFetchUserData: PropTypes.func,
		bookingDetails: PropTypes.object,
		lastActiveTab: PropTypes.string,
		cancelOrderId: PropTypes.string,
	};

	// eslint-disable-next-line react/sort-comp
	initialState = {
		alertText: '',
		errors: {},
		expirationDate: null,
		isSingleTicketableFares: false,
		mode: null,
		open: false,
		processingSubmit: false,
		releaseSeat: false,
		orderCanBeCancelDueToStrike: false,
		selectedTicketableFareId: null,
		ticketableFaresState: [],
		seasonFareOrderDetails: null,
		cancelledDueToStrike: false,
		isSeasonBooking: false,
		orderCurrency: '',
		openCancelPenaltyDialog: false,
		penalty: 0,
		penaltyCurrency: '',
		isFetching: false,
		isReleasable: false,
		isSplitTicketFare: false,
	};

	state = { data: fromJS(this.initialState) };

	componentDidMount() {
		const { onFetchUserData } = this.props;
		onFetchUserData();
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps(nextProps) {
		const { open, availableSuppliers } = this.props;

		const currentOrder = this.props.orders.filter(
			(order) => order.BookingOrderID === nextProps.orderId,
		);

		if (!open && nextProps.open) {
			const voidMode = nextProps.type === TYPES.VOID;
			const hasSNCF = availableSuppliers.includes('SNCF');
			const ticketableFaresState = this.getTicketableFaresState(
				currentOrder[0].BookingOrderLegDetails.ticketableFareGroups,
				voidMode,
			);
			const isSingleTicketableFares =
				currentOrder[0].BookingOrderIsSingleTicketableFares ||
				currentOrder[0].BookingOrderLegDetails.ticketableFareGroups.length ===
					1;
			const isReleasable = currentOrder[0].BookingOrderCoupons?.some(
				(coupon) => coupon.isReleasable === true,
			);

			this.setState((state) => ({
				data: state.data.merge({
					isSingleTicketableFares,
					orderDetails: {
						...currentOrder[0].BookingOrderLegDetails,
						seasonFareOrderDetails: currentOrder[0].BookingSeasonFareDetails,
						BookingLegFareRulesDetails:
							currentOrder[0].BookingLegFareRulesDetails,
					},
					orderCanBeCancelDueToStrike: hasSNCF && !voidMode,
					releaseSeat: false,
					seasonFareOrderDetails: currentOrder[0].BookingSeasonFareDetails,
					isSeasonBooking: !!currentOrder[0].BookingSeasonFareDetails,
					selectedTicketableFareId: null,
					ticketableFaresState,
					orderCurrency:
						currentOrder[0].BookingOrderLegDetails.ticketableFareGroups[0]
							?.ticketableFareCurrency,
					mode: voidMode ? MODE_TYPES.ALL : MODE_TYPES.PART,
					isReleasable,
					bookingOrderRevisions: currentOrder[0]?.BookingOrderRevisions,
					isSplitTicketFare: currentOrder[0]?.BookingOrderIsSplitTicketFare,
				}),
			}));
		}
	}

	getTicketableFaresState = (ticketableFareGroups, voidMode) =>
		ticketableFareGroups.map((ticketableFareGroup) => ({
			ticketableFareId: ticketableFareGroup.BookingDetailsLegTicketableFareIds,
			disabled: this.getIsVoidOrSeason(voidMode),
			checked: this.getIsVoidOrSeason(voidMode),
		}));

	getIsVoidOrSeason = (voidMode) => voidMode;

	onChangeCancelledDueToStrike = ({ target: { checked } }) => {
		this.setState((state) => ({
			data: state.data.set('cancelledDueToStrike', checked),
		}));
	};

	handleClose = () => {
		const { type, onCloseDialog } = this.props;
		const { data } = this.state;
		gaEvent(
			`${type === TYPES.VOID ? 'void' : 'cancel'}OrderClose`,
			data.get('mode'),
		);
		this.setState({ data: fromJS(this.initialState) }, onCloseDialog);
	};

	handleViewCancelPenalty = () => {
		const { data } = this.state;
		const { orderId: srtOrderId, queryItems } = this.props;
		const cancelledDueToStrike = data.get('cancelledDueToStrike');
		const selectedTicketableFaresIds = data
			.get('ticketableFaresState')
			.toJS()
			.filter((x) => x.checked)
			.map((x) => x.ticketableFareId);
		const sendTicketableFaresIds =
			selectedTicketableFaresIds.length ===
			data.get('ticketableFaresState').toJS().length
				? []
				: selectedTicketableFaresIds;
		const srtSelectedTickatableFares = sendTicketableFaresIds.join(',');

		gaEvent('cancelPenaltyOpen');

		if (!cancelledDueToStrike && !this.state.data.get('isFetching')) {
			this.setState(
				(state) => ({ data: state.data.set('isFetching', true) }),
				() => {
					getCancellationPenaltyApi(
						{
							...queryItems,
							srtOrderId,
							srtSelectedTickatableFares,
						},
						(response) => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									alertText:
										response.errorResponse.errorMessage ||
										response.errorResponse.message,
								}),
							}));
							gaEvent('cancelPenaltyError', response);
						},
						(response) => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									openCancelPenaltyDialog: true,
									penalty: response.successResponse.data.penalty,
									penaltyCurrency: response.successResponse.data.currency,
								}),
							}));
						},
					);
				},
			);
		} else {
			this.setState((state) => ({
				data: state.data.merge({
					openCancelPenaltyDialog: true,
				}),
			}));
		}
	};

	handleSubmit = () => {
		const { data } = this.state;
		const {
			type,
			orderId,
			queryItems,
			onCloseDialog,
			onSubmit,
			intl: { formatMessage },
			onSetSendTicketNewOrder,
		} = this.props;
		const selectedTicketableFaresIds = data
			.get('ticketableFaresState')
			.toJS()
			.filter((x) => x.checked)
			.map((x) => x.ticketableFareId);
		const sendTicketableFaresIds =
			selectedTicketableFaresIds.length ===
			data.get('ticketableFaresState').toJS().length
				? []
				: selectedTicketableFaresIds;
		let srtSelectedTickatableFares = sendTicketableFaresIds.join(',');
		const srtReleaseSeat = data.get('releaseSeat');
		const srtCancellationReason = data.get('cancelledDueToStrike');
		const srtOrderCurrency = data.get('orderCurrency');
		const voidMode = type === TYPES.VOID;
		this.setState((state) => ({
			data: state.data.merge({ processingSubmit: true }),
		}));

		if (data.get('isSplitTicketFare')) {
			const ticketableFaresState = data.get('ticketableFaresState').toJS();

			const fareIdIndex = ticketableFaresState.reduce(
				(acc, ticketableFare, ticketableFareIndex) => {
					const ticketableFareIds = ticketableFare.ticketableFareId.split(',');
					const ticketableFareIds2 =
						ticketableFaresState[
							ticketableFareIndex + 1
						]?.ticketableFareId.split(',');

					const hasMatchFareId = ticketableFareIds.some((r) =>
						ticketableFareIds2?.includes(r),
					);

					if (!hasMatchFareId) {
						acc.push(ticketableFareIndex);
					}

					return acc;
				},
				[],
			);

			const combinedSelectedTicketableFaresIds =
				selectedTicketableFaresIds.reduce((acc, selectedTicketableFare) => {
					const matchIndex = ticketableFaresState.findIndex(
						(ticketableFare) =>
							ticketableFare.ticketableFareId === selectedTicketableFare,
					);
					const splitFareEndIndex = fareIdIndex.find(
						(index) => index >= matchIndex,
					);
					for (let i = matchIndex; i <= splitFareEndIndex; i++) {
						acc.push(ticketableFaresState[i].ticketableFareId);
					}
					return acc;
				}, []);

			const uniqueSelectedTicketableFaresIds = [
				...new Set(combinedSelectedTicketableFaresIds.join(',').split(',')),
			].join(',');

			srtSelectedTickatableFares = uniqueSelectedTicketableFaresIds;
		}

		submitCancelBookingApi(
			{
				queryItems,
				orderId,
				voidMode,
				srtSelectedTickatableFares,
				srtCancellationReason,
				srtReleaseSeat,
				srtOrderCurrency,
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText:
							response.errorResponse.errorMessage ||
							response.errorResponse.message,
						processingSubmit: false,
					}),
				}));
			},
			(response) => {
				const cancelMessage = response?.successResponse?.data?.Status?.message
					? messages.lblCanceledStatusMessage
					: messages.lblCanceledMessage;
				onSubmit(queryItems).then(() => {
					this.setState((state) => ({
						data: state.data.merge({
							...this.initialState,
							alertText: formatMessage(cancelMessage),
							processingSubmit: false,
						}),
					}));
					onSetSendTicketNewOrder(
						response.successResponse.data.SendTicketNewOrderId,
						response.successResponse.data.BookingOrderIsSendTicketPossible,
					);
					onCloseDialog(
						response.successResponse.data.BookingBillingTotal
							.BookingBillingRefundPossible,
						response.successResponse.data.BookingOrderIsSendTicketPossible,
					);
				});
			},
		);
	};

	handleTicketableFareChange = (ticketableFareId, checked) => {
		const { data } = this.state;
		const selectedTicketableFareIndex = data
			.get('ticketableFaresState')
			.toJS()
			.findIndex((x) => x.ticketableFareId === ticketableFareId);
		const ticketableFareNewState = data
			.setIn(
				['ticketableFaresState', selectedTicketableFareIndex, 'checked'],
				checked,
			)
			.toJS().ticketableFaresState;
		this.setState((state) => ({
			data: state.data.merge({ ticketableFaresState: ticketableFareNewState }),
		}));
	};

	handleSelectAll = (selected) => {
		const { data } = this.state;
		const selectedTicketableFareIndex = data.get('ticketableFaresState').toJS();
		const ticketableFareNewState = selectedTicketableFareIndex.map((item) => {
			const newState = item;
			newState.checked = selected;
			return newState;
		});
		this.setState((state) => ({
			data: state.data.merge({ ticketableFaresState: ticketableFareNewState }),
		}));
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	handleReleaseSeatChange = (event) => {
		const { checked } = event.target;
		this.setState((state) => ({
			data: state.data.merge({ releaseSeat: checked }),
		}));
	};

	handleSetMode = (mode) => {
		const { type } = this.props;
		gaEvent(`${type === TYPES.VOID ? 'void' : 'cancel'}OrderSelectMode`, mode);
		this.setState((state) => ({ data: state.data.set('mode', mode) }));
	};

	handleCancelPenaltyClose = () => {
		gaEvent('cancelPenaltyClose');
		this.setState((state) => ({
			data: state.data.set('openCancelPenaltyDialog', false),
		}));
	};

	render() {
		const {
			open,
			type,
			intl: { formatMessage },
			bookingDetails,
			lastActiveTab,
			cancelOrderId,
		} = this.props;
		const { data } = this.state;
		const isVoid = type === TYPES.VOID;
		const mode = data.get('mode');
		const ticketableFaresState = data.get('ticketableFaresState');
		const cancelBookingOrder = bookingDetails[
			lastActiveTab
		]?.BookingOrders.find(
			(booking) => booking.BookingOrderID === cancelOrderId,
		);

		return (
			<div>
				<Dialog
					open={open}
					onClose={this.handleClose}
					disableEnforceFocus
					maxWidth={false}
				>
					{data.get('isFetching') && (
						<div style={{ position: 'absolute', top: '50%', left: '50%' }}>
							<CircularProgress />
						</div>
					)}
					{mode ? (
						<CancelVoidOrderMode
							isVoid={isVoid}
							mode={mode}
							formatMessage={formatMessage}
							handleTicketableFareChange={this.handleTicketableFareChange}
							data={data}
							ticketableFaresState={ticketableFaresState}
							handleSelectAll={this.handleSelectAll}
							onChangeCancelledDueToStrike={this.onChangeCancelledDueToStrike}
							handleReleaseSeatChange={this.handleReleaseSeatChange}
							handleClose={this.handleClose}
							handleViewCancelPenalty={this.handleViewCancelPenalty}
							handleSubmit={this.handleSubmit}
							bookingOrderRevisions={data.get('bookingOrderRevisions') || []}
							isPartialCancel={
								cancelBookingOrder?.BookingOrderCancelInfo
									.BookingOrderCancelIsPartialCancel
							}
						/>
					) : (
						<CircularProgress />
					)}
					<CancelPenaltyDialog
						open={data.get('openCancelPenaltyDialog')}
						handleClose={this.handleCancelPenaltyClose}
						penalty={data.get('penalty')}
						penaltyCurrency={data.get('penaltyCurrency')}
						cancelledDueToStrike={data.get('cancelledDueToStrike')}
					/>
				</Dialog>
				<ExtendedSnackbar
					id={`srt${isVoid ? 'Void' : 'Cancel'}OrderSnackBar`}
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

export { CancelVoidOrderDialog as CancelVoidOrderDialogAlias };

const mapStateToProps = (state) => ({
	orderDetails: state.getIn(['booking', 'orderDetails']).toJS(),
	availableSuppliers: state
		.getIn(['shopping', 'query', 'availableSuppliers'])
		.toJS(),
	lastActiveTab: state.getIn(['booking', 'lastActiveTab']),
	bookingDetails: state.getIn(['booking', 'bookingDetails']).toJS(),
	cancelOrderId: state.getIn(['booking', 'cancelOrderId']),
});

const mapDispatchToProps = (dispatch) => ({
	onSubmit: bindActionCreators(fetchBookingDetails, dispatch),
	onSetSendTicketNewOrder: bindActionCreators(setSendTicketNewOrder, dispatch),
	onFetchUserData: bindActionCreators(fetchUserData, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(CancelVoidOrderDialog));
