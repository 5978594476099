import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import inlineStyles from './accountGroupStyles';
import messages from '../messagesManageAccounts';

function FareFilter({
	handleChangeFareFilter,
	srtAccountGroupFareFilter,
	intl: { formatMessage },
}) {
	return (
		<div className="col-12">
			<ListSubheader style={inlineStyles.headerFare}>
				<FormattedMessage {...messages.lblFareFilter} />
			</ListSubheader>
			<FormControl fullWidth>
				<InputLabel shrink htmlFor="select" style={{ fontSize: 22 }}>
					<FormattedMessage {...messages.lblFareFilterTitle} />
				</InputLabel>
				<Select
					id="selectFareFilter"
					value={srtAccountGroupFareFilter}
					displayEmpty
					onChange={(event) => handleChangeFareFilter(event)}
					variant="standard"
				>
					<MenuItem value="">{formatMessage(messages.lblNull)}</MenuItem>
					<MenuItem value="CHEAPEST_WITH_TC_GROUPS">
						{formatMessage(messages.lblCheapestTravelCards)}
					</MenuItem>
					<MenuItem value="CHEAPEST_ONEWAY_AND_ROUNDTRIP">
						{formatMessage(messages.lblCheapestOneWayRoundTrip)}
					</MenuItem>
					<MenuItem value="CHEAPEST_ONEWAY_AND_ROUNDTRIP_WITH_TC_GROUPS">
						{formatMessage(messages.lblCheapestOneWayRoundTripTravelCard)}
					</MenuItem>
					<MenuItem value="CHEAPEST_ONEWAY_AND_ROUNDTRIP_WITH_TC_GROUPS_AND_CORP_FARES">
						{formatMessage(messages.lblCheapestOneWayCorporateFares)}
					</MenuItem>
					<MenuItem value="CHEAPEST">
						{formatMessage(messages.lblCheapestOnly)}
					</MenuItem>
					<MenuItem value="CHEAPEST_OPEN">
						{formatMessage(messages.lblCheapestOnlyOpen)}
					</MenuItem>
					<MenuItem value="OPEN">
						{formatMessage(messages.lblopenFaresOnly)}
					</MenuItem>
					<MenuItem value="GB_FARES">
						{formatMessage(messages.lblGBFares)}
					</MenuItem>
					<MenuItem value="NONE">
						{formatMessage(messages.lblNoneText)}
					</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}

FareFilter.propTypes = {
	intl: PropTypes.object,
	handleChangeFareFilter: PropTypes.func,
	srtAccountGroupFareFilter: PropTypes.string,
};

// This alias will be used to access bare component for unit testing
export { FareFilter as FareFilterAlias };

export default injectIntl(FareFilter);
