import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from '@mui/system';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import styles from './styles';

import { formatPrice } from '../../../utils';
import messages from './messages';
import { gaEvent } from '../../../utils/googleAnalytics';

const StyledTableRow = styled(TableRow)({
	...styles.titleContainer,
});

const StyledSpan = styled('span')({
	...styles.title,
});

const SupplementDetailsTable = ({ bikeReservationDetails, radioTitle }) => {
	const [expanded, setExpanded] = React.useState(false);
	const handleExpand = () => {
		gaEvent(
			expanded
				? 'expandSupplementDetailsSection'
				: 'collapseSupplementDetailsSection',
		);
		setExpanded(!expanded);
	};
	const intl = useIntl();

	return (
		!!bikeReservationDetails &&
		!!bikeReservationDetails.length && (
			<StyledTableRow>
				{radioTitle && <TableCell />}
				<TableCell colSpan={12}>
					<StyledSpan>
						<FormattedMessage {...messages.lblSupplementAdditionalProducts} />
					</StyledSpan>
					<IconButton onClick={handleExpand}>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
					<Collapse in={expanded}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<FormattedMessage {...messages.lblSupplementDesc} />
									</TableCell>
									<TableCell>
										<FormattedMessage {...messages.lblSupplementPassengers} />
									</TableCell>
									<TableCell>
										<FormattedMessage
											{...messages.lblSupplementAdditionalDetails}
										/>
									</TableCell>
									<TableCell>
										<FormattedMessage {...messages.lblSupplementPrice} />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{bikeReservationDetails.map((bikeReservation, index) => (
									<TableRow key={'bikeReservation'.concat(index)}>
										<TableCell data-testid="bike-description">
											{bikeReservation.Description}
										</TableCell>
										<TableCell data-testid="bike-passenger-name">
											{bikeReservation.PassengerName}
										</TableCell>
										<TableCell data-testid="bike-additional-details">
											{bikeReservation.AdditionalDetails}
										</TableCell>
										<TableCell data-testid="bike-price-formatted">
											{formatPrice(
												bikeReservation.Price,
												bikeReservation.Currency,
												intl,
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</StyledTableRow>
		)
	);
};

SupplementDetailsTable.propTypes = {
	bikeReservationDetails: PropTypes.array,
	radioTitle: PropTypes.node,
};

export { SupplementDetailsTable as SupplementDetailsTableAlias };

export default SupplementDetailsTable;
