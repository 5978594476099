import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { is, fromJS } from 'immutable';
import validate from 'validate.js';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { fetchBookingDetails } from '../../Booking/ManageBooking/actionsManageBooking';
import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import TicketDeliveryView from './TicketDeliveryView';
import messages from './messagesTicketDelivery';
import { isTDOMail, isTDOSmartCard } from './utils';
import { bookingSetTdoApi, getSmartCardTdoStationsApi } from '../apiComponents';
import ExtendedSnackbar from '../ExtendedSnackbar/ExtendedSnackbar';
import { gaEvent } from '../../utils/googleAnalytics';
import inlineStyles from './styles';
import {
	constraints,
	smsConstraints,
	mailConstraints,
	addressConstraints,
	paperEmailConstraints,
	pickUpStationConstraints,
	smartCardNumberConstraints,
} from './ticketDeliveryDialogConstraints';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));
class TicketDeliveryDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		open: PropTypes.bool.isRequired,
		handleClose: PropTypes.func,
		handleSubmit: PropTypes.func,
		tdOptions: PropTypes.object.isRequired,
		suppliers: PropTypes.array,
		smsCodeOptions: PropTypes.array,
		stationCode: PropTypes.string,
		stationName: PropTypes.string,
		preSelectedTdo: PropTypes.object,
		countries: PropTypes.object,
		states: PropTypes.object,
		canadianProvinces: PropTypes.object,
		regionMaps: PropTypes.object,
		booking: PropTypes.object,
		order: PropTypes.object,
		onSetTDOSuccess: PropTypes.func,
		viewMode: PropTypes.bool,
		passengers: PropTypes.object,
		gaType: PropTypes.string,
		addOrderMode: PropTypes.bool,
		exchangeOrderMode: PropTypes.bool,
	};

	initialState = {
		tdo: '',
		tdoSmsCountry: null,
		tdoSmsNumber: '',
		tdoPaperEmail: '',
		tdoRegion: '',
		tdoMailName: '',
		address: {
			countryCode: null,
			address1: '',
			address2: '',
			city: '',
			stateOrProvince: '',
			postalCode: '',
		},
		smartCard: {
			pickUpStation: { value: '', label: '' },
			passengerNumbers: [],
		},
		errors: {},
		passengers: [],
		alertText: '',
		isProcessingSubmit: false,
		stationsTicketDialogOpen: false,
		stationInformationDialogOpen: false,
		isFetching: false,
		smartCardStations: {
			SmartCardSearchResultTDOStations: {},
		},
	};

	constructor(props) {
		super(props);
		this.state = this.populatePreselectedTdo(props, {
			data: fromJS(this.initialState),
		});
	}

	componentDidMount() {
		const tdOptions = this.props.tdOptions.toJS();
		// check if tdOptions contains the smart card
		if (
			tdOptions &&
			tdOptions.some((tdOption) => tdOption.BookingTDOCode === 'SCT')
		) {
			this.handleGetAllSmartCardStations({
				suppliers: this.props.suppliers.map((item) => item.BookingSupplierCode),
				stationCode: this.props.stationCode,
			});
		}
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState((state) => this.populatePreselectedTdo(nextProps, state));
	}

	populatePreselectedTdo = (nextProps, prevState) => {
		let state = prevState;
		// populate all data for preselected TDO
		const selectedTdo =
			nextProps.preSelectedTdo || nextProps.booking.preSelectedTdo;
		const numberOfPassengers =
			nextProps.passengers || nextProps.booking.BookingPassengers;
		// populate passengerNumbers List with empty strings for each passengers
		const emptyArrayWithStrings = selectedTdo.tdoSmartCards
			? ['']
			: numberOfPassengers.map(() => '');

		const tdoSmartCard = {
			pickUpStation: { value: selectedTdo.tdoPickUpLocation || '', label: '' },
			passengerNumbers:
				selectedTdo.tdoSmartCards && selectedTdo.tdoSmartCards.length > 0
					? selectedTdo.tdoSmartCards
					: emptyArrayWithStrings,
		};

		if (selectedTdo) {
			state = {
				data: prevState.data.merge({
					tdo: selectedTdo.tdoCode,
					tdoPaperEmail:
						selectedTdo.tdoPaperEmail || this.initialState.tdoPaperEmail,
					tdoSmsCountry:
						selectedTdo.tdoSmsCountry || this.initialState.tdoSmsCountry,
					tdoSmsNumber:
						selectedTdo.tdoSmsNumber || this.initialState.tdoSmsNumber,
					tdoRegion: selectedTdo.tdoRegion || this.initialState.tdoRegion,
					tdoMailName: selectedTdo.tdoMailName || this.initialState.tdoMailName,
					address: selectedTdo.tdoMailAddress || this.initialState.address,
					smartCard: tdoSmartCard,
					errors: {},
				}),
			};
		}

		return state;
	};

	handleGetAllSmartCardStations = (params) => {
		const {
			intl: { formatMessage },
		} = this.props;
		this.setState((state) => ({
			data: state.data.merge({
				isFetching: true,
			}),
		}));
		getSmartCardTdoStationsApi(
			params,
			() => {
				this.setState((state) => ({
					data: state.data.merge({
						isFetching: false,
						alertText: formatMessage(messages.errPickUpStationsErrorResponse),
					}),
				}));
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						isFetching: false,
						smartCardStations: response.successResponse.data,
						passengers: this.props.passengers
							? this.props.passengers.toJS()
							: this.props.booking.BookingPassengers,
						alertText: response.successResponse.data
							? ''
							: formatMessage(messages.errPickUpStationsNoData),
					}),
				}));
			},
		);
	};

	handleTdoChange = (tdo, countryCode) => {
		this.setState((state) => ({
			data: state.data.mergeIn(['address'], { countryCode }).merge({ tdo }),
		}));
	};

	handleTdoPaperEmailChange = ({ target: { value: tdoPaperEmail } }) => {
		this.setState((state) => ({
			data: state.data.set('tdoPaperEmail', tdoPaperEmail),
		}));
	};

	handleTdoSmsCountryChange = ({ target: { value: tdoSmsCountry } }) => {
		this.setState((state) => ({
			data: state.data.set('tdoSmsCountry', tdoSmsCountry),
		}));
	};

	handleTdoSmsNumberChange = ({ target: { value: tdoSmsNumber } }) => {
		this.setState((state) => ({
			data: state.data.set('tdoSmsNumber', tdoSmsNumber),
		}));
	};

	handleTdoRegionChange = ({ target: { value: tdoRegion } }) => {
		const tdoByMail = tdoRegion
			? this.props.tdOptions
					.toJS()
					.filter((item) => item.BookingTDODestination === tdoRegion)
			: [];
		const tdoTemp = isTDOMail(this.state.data.get('tdo'))
			? ''
			: this.state.data.get('tdo');
		const tdo = tdoByMail.length === 1 ? tdoByMail[0].BookingTDOCode : tdoTemp;
		this.setState((state) => ({
			data: state.data.merge({ tdoRegion, tdo }),
		}));
	};

	handleTdoMailNameChange = ({ target: { value: tdoMailName } }) => {
		this.setState((state) => ({
			data: state.data.set('tdoMailName', tdoMailName),
		}));
	};

	handlePickUpStationChange = (value) => {
		this.setState((state) => ({
			data: state.data
				.mergeIn(
					['smartCard', 'pickUpStation'],
					value.length === 0 ? { value: '', label: '' } : value,
				)
				.deleteIn(['errors', 'value']),
		}));
	};

	handleSmartCardNumberChange = (event) => {
		const {
			target: { id, value },
		} = event;
		this.setState((state) => {
			const numbersList = state.data.getIn(['smartCard', 'passengerNumbers']);
			// event.target.id example: "srtSmartCardNumber01234". We need to stripe chars and leave only numbers
			return {
				data: state.data
					.mergeIn(
						['smartCard', 'passengerNumbers'],
						numbersList.set(id.substring(18), value),
					)
					.deleteIn(['errors', 'passengers', id, 'smartCardNumber']),
			};
		});
	};

	handleChangeAddress = (updates) => {
		this.setState((state) => {
			let newStateData = state.data.mergeIn(['address'], updates);
			if (updates.countryCode) {
				newStateData = newStateData.deleteIn([
					'errors',
					'address',
					'stateOrProvince',
				]);
			}
			return { data: newStateData };
		});
	};

	handleSubmitTdo = () => {
		const { data } = this.state;
		const {
			handleClose,
			preSelectedTdo,
			intl: { formatMessage },
		} = this.props;
		const tdo = data.get('tdo');
		let tdoPaperEmail = data.get('tdoPaperEmail');
		let tdoSmsCountry = data.get('tdoSmsCountry');
		let tdoSmsNumber = data.get('tdoSmsNumber');
		let tdoRegion = data.get('tdoRegion');
		let tdoMailName = data.get('tdoMailName');
		let tdoMailAddress = data.get('address');
		let errors = { ...validate(data.toJS(), constraints(formatMessage)) };
		let tdoSmartCards = data.getIn(['smartCard', 'passengerNumbers']);
		let tdoPickUpLocation = data.getIn(['smartCard', 'pickUpStation', 'value']);

		if (validate.isEmpty(errors)) {
			if (tdo.indexOf('SMS') !== -1) {
				errors = { ...validate(data.toJS(), smsConstraints(formatMessage)) };
			}

			if (isTDOMail(tdo)) {
				errors = { ...validate(data.toJS(), mailConstraints(formatMessage)) };
				const addressErrors = validate(
					data.get('address').toJS(),
					addressConstraints(formatMessage),
				);
				errors = Object.assign(
					errors,
					!validate.isEmpty(addressErrors) ? { address: addressErrors } : {},
				);
			}
			if (tdo === 'SCT') {
				// validate pickup station input
				errors = {
					...validate(
						data.getIn(['smartCard', 'pickUpStation']).toJS(),
						pickUpStationConstraints(formatMessage),
					),
				};

				// validate smart card number inputs
				const passengerNumberValues = data
					.getIn(['smartCard', 'passengerNumbers'])
					.toJS();
				const passengerNumberErrors = { passengers: [] };

				passengerNumberErrors.passengers = passengerNumberValues.map(
					(value) =>
						validate(
							{ smartCardNumber: value },
							smartCardNumberConstraints(formatMessage),
						) || {},
				);
				errors = Object.assign(errors, passengerNumberErrors);
			}
			if (tdo === 'PRN') {
				errors = {
					...validate(data.toJS(), paperEmailConstraints(formatMessage)),
				};
			}
		} else {
			this.setState({ data: data.merge({ alertText: errors.tdo }) });
			return;
		}

		if (!validate.isEmpty(errors)) {
			let hasErrors = true;
			// Check for smart card option
			if (tdo === 'SCT') {
				// If even one object inside passengers is not empty -> there are errors
				const smartCardHasErrors = errors.passengers.some(
					(item) => !validate.isEmpty(item),
				);

				if (!smartCardHasErrors && !errors.value) hasErrors = false;
			}

			if (hasErrors) {
				this.setState({ data: data.merge({ errors }) });
				return;
			}
		}

		if (tdo.indexOf('SMS') === -1) {
			tdoSmsCountry = this.initialState.tdoSmsCountry;
			tdoSmsNumber = this.initialState.tdoSmsNumber;
		}

		if (!isTDOMail(tdo)) {
			tdoRegion = this.initialState.tdoRegion;
			tdoMailName = this.initialState.tdoMailName;
			tdoMailAddress = fromJS(this.initialState.address);
		}

		if (!isTDOSmartCard(tdo)) {
			tdoSmartCards = this.initialState.smartCard.passengerNumbers;
			tdoPickUpLocation = this.initialState.smartCard.pickUpStation.value;
		}

		if (tdo !== 'PRN') {
			tdoPaperEmail = this.initialState.tdoPaperEmail;
		}

		if (this.props.booking && this.props.order) {
			const tdoData = this.props.tdOptions.find(
				(tdoOption) => tdoOption.get('BookingTDOCode') === tdo,
			);

			// if selected TDO is not modified do not call API and close Dialog
			if (
				preSelectedTdo.tdoCode === tdoData.get('BookingTDOCode') &&
				// Smart Card data is the same
				((isTDOSmartCard(tdoData.get('BookingTDOCode')) &&
					preSelectedTdo.tdoPickUpLocation === tdoPickUpLocation &&
					is(fromJS(preSelectedTdo.tdoSmartCards), tdoSmartCards)) ||
					// Mail data is the same
					(isTDOMail(tdoData.get('BookingTDOCode')) &&
						preSelectedTdo.tdoRegion === tdoRegion &&
						preSelectedTdo.tdoMailName === tdoMailName &&
						is(
							fromJS(this.props.preSelectedTdo.tdoMailAddress),
							tdoMailAddress,
						)) ||
					// SMS data is the same
					(tdoData.get('BookingTDOCode').includes('SMS') &&
						preSelectedTdo.tdoSmsCountry === tdoSmsCountry &&
						preSelectedTdo.tdoSmsNumber === tdoSmsNumber) ||
					// Paper Email is the same
					(tdoData.get('BookingTDOCode') === 'PRN' &&
						preSelectedTdo.tdoPaperEmail === tdoPaperEmail) ||
					// Other ones
					(!isTDOSmartCard(tdoData.get('BookingTDOCode')) &&
						!isTDOMail(tdoData.get('BookingTDOCode')) &&
						!tdoData.get('BookingTDOCode').includes('SMS')))
			) {
				handleClose();
				return;
			}

			this.setState((state) => ({
				data: state.data.merge({
					isProcessingSubmit: true,
				}),
			}));

			gaEvent(`${this.props.gaType}TDOSubmit`);

			bookingSetTdoApi(
				{
					tdoCode: tdoData.get('BookingTDOCode'),
					tdoDesc: tdoData.get('BookingTDODesc'),
					tdoFee: parseFloat(tdoData.get('BookingTDOFee')),
					tdoCurrency: tdoData.get('BookingTDOFeeCurr'),
					tdoSmsCountry,
					tdoSmsNumber,
					tdoPaperEmail,
					tdoRegion,
					tdoMailName,
					tdoMailAddress: tdoMailAddress.toJS(),
					tdoSmartCards,
					tdoPickUpLocation,
				},
				this.props.booking,
				this.props.order,
				(updateResponse) => {
					gaEvent(`${this.props.gaType}TDOError`, updateResponse);
					this.setState((state) => ({
						data: state.data.merge({
							alertText: updateResponse.errorResponse.message,
							isProcessingSubmit: false,
						}),
					}));
				},
				() => {
					const { queryItems } = this.props.booking;
					this.props.onSetTDOSuccess(queryItems).then(() => {
						handleClose();
						this.setState((state) => ({
							data: state.data.merge({
								isProcessingSubmit: false,
							}),
						}));
					});
				},
			);
		} else {
			this.props.handleSubmit(
				tdo,
				tdoSmsCountry,
				tdoSmsNumber,
				tdoPaperEmail,
				tdoRegion,
				tdoMailName,
				tdoMailAddress.toJS(),
				tdoSmartCards,
				tdoPickUpLocation,
				this.props.gaType,
			);
		}
	};

	handleCloseStationsTicketDialog = () => {
		this.setState((state) => ({
			data: state.data.merge({ stationsTicketDialogOpen: false }),
		}));
	};

	handleOpenStationsTicketDialog = () => {
		this.setState((state) => ({
			data: state.data.merge({ stationsTicketDialogOpen: true }),
		}));
	};

	handleCloseStationInformationDialog = () => {
		this.setState((state) => ({
			data: state.data.merge({ stationInformationDialogOpen: false }),
		}));
	};

	handleOpenStationInformationDialog = () => {
		this.setState((state) => ({
			data: state.data.merge({ stationInformationDialogOpen: true }),
		}));
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			handleClose,
			open,
			tdOptions,
			suppliers,
			smsCodeOptions,
			stationCode,
			stationName,
			countries,
			states,
			canadianProvinces,
			regionMaps,
			viewMode,
			passengers,
			booking,
			order,
			gaType,
			addOrderMode,
			exchangeOrderMode,
		} = this.props;

		const { data } = this.state;
		const tdo = data.get('tdo');
		const tdoSmsCountry = data.get('tdoSmsCountry');
		const tdoSmsNumber = data.get('tdoSmsNumber');
		const tdoRegion = data.get('tdoRegion');
		const tdoMailName = data.get('tdoMailName');
		const tdoMailAddress = data.get('address').toJS();
		const errors = data.get('errors').toJS();
		const stationsTicketDialogOpen = data.get('stationsTicketDialogOpen');
		const stationInformationDialogOpen = data.get(
			'stationInformationDialogOpen',
		);
		const smartCardStations = data.getIn([
			'smartCardStations',
			'SmartCardSearchResultTDOStations',
		]);
		const isFetching = data.get('isFetching');
		const tdoSmartCardNumber = data.getIn(['smartCard', 'passengerNumbers']);
		const tdoPickUpStation = data.getIn(['smartCard', 'pickUpStation']);
		const tdoPaperEmail = data.get('tdoPaperEmail');

		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div className="col-6">
					<Button
						variant="contained"
						id="srtTicketDeliveryClose"
						onClick={() => {
							if (addOrderMode) gaEvent('addOrderCloseTicketDelivery');
							else if (exchangeOrderMode)
								gaEvent('exchangeOrderCloseTicketDelivery');
							else if (booking && order) gaEvent(`${gaType}TDOClose`);
							else gaEvent('createBookingEditTDOClose');
							handleClose();
						}}
						fullWidth
					>
						<FormattedMessage {...messages.btnClose} />
					</Button>
				</div>
				<div className="col-6">
					<Button
						variant="contained"
						id="srtTicketDeliverySubmit"
						onClick={this.handleSubmitTdo}
						color="primary"
						fullWidth
						disabled={viewMode}
					>
						<FormattedMessage {...messages.btnSubmit} />
					</Button>
				</div>
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={open}
					onClose={handleClose}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>
						<FormattedMessage {...messages.lblTdoTitle} />
					</DialogTitle>
					<DialogContent>
						<TicketDeliveryView
							tdOptions={tdOptions}
							suppliers={suppliers}
							smsCodeOptions={smsCodeOptions}
							stationCode={stationCode}
							stationName={stationName}
							countries={countries}
							states={states}
							canadianProvinces={canadianProvinces}
							regionMaps={regionMaps}
							tdo={tdo}
							tdoPaperEmail={tdoPaperEmail}
							tdoSmsCountry={tdoSmsCountry}
							tdoSmsNumber={tdoSmsNumber}
							tdoRegion={tdoRegion}
							tdoMailName={tdoMailName}
							tdoMailAddress={tdoMailAddress}
							errors={errors}
							onTdoChange={this.handleTdoChange}
							onSmsCountryChange={this.handleTdoSmsCountryChange}
							onSmsNumberChange={this.handleTdoSmsNumberChange}
							onTdoPaperEmailChange={this.handleTdoPaperEmailChange}
							onTdoRegionChange={this.handleTdoRegionChange}
							onTdoMailNameChange={this.handleTdoMailNameChange}
							onTdoAddressChange={this.handleChangeAddress}
							stationsTicketDialogOpen={stationsTicketDialogOpen}
							stationInformationDialogOpen={stationInformationDialogOpen}
							onCloseStationsTicketDialog={this.handleCloseStationsTicketDialog}
							onOpenStationsTicketDialog={this.handleOpenStationsTicketDialog}
							onCloseStationInformationDialog={
								this.handleCloseStationInformationDialog
							}
							onOpenStationInformationDialog={
								this.handleOpenStationInformationDialog
							}
							passengers={passengers || fromJS(booking.BookingPassengers)}
							onPickUpStationChange={this.handlePickUpStationChange}
							onSmartCardNumberChange={this.handleSmartCardNumberChange}
							smartCardStations={smartCardStations}
							isFetching={isFetching}
							tdoSmartCardNumber={tdoSmartCardNumber}
							tdoPickUpStation={tdoPickUpStation}
						/>
						<RefreshIndicator
							size={36}
							top={0}
							left={0}
							status={
								data.get('isFetching') || data.get('isProcessingSubmit')
									? 'loading'
									: 'hide'
							}
							style={inlineStyles.indicator}
						/>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtTicketDeliverySnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	countries: state.getIn(['settings', 'GlobalCountryList']),
	states: state.getIn(['settings', 'StateProvinceList']),
	canadianProvinces: state.getIn(['settings', 'CanadianProvincesList']),
	regionMaps: state.getIn(['settings', 'RegionMaps']),
});

const mapDispatchToProps = {
	onSetTDOSuccess: fetchBookingDetails,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(TicketDeliveryDialog));
