export default {
	subTitle: {
		marginTop: 5,
		marginBottom: 0,
	},
	subTitleIn: {
		marginTop: 5,
		marginBottom: -4,
	},
	dialogPaper: {
		overflowY: 'visible',
		maxWidth: 768,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	refreshIndicator: {
		zIndex: 2000,
		marginTop: '5%',
		marginLeft: '48%',
	},
};
