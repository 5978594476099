import bookingViewStyles from '../BookingView/styles';

export default {
	title: {
		fontWeight: 600,
		fontSize: 14,
		marginRight: 15,
	},
	titleContainer: {
		marginTop: 15,
	},
	redButton: bookingViewStyles.redButton,
	buttons: {
		marginTop: 10,
	},
};
