import React from 'react';
import { FormattedMessage } from 'react-intl';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import messages from './messagesFareItinerary';

const IntermediateStops = ({ intermediateStops }) => (
	<Table>
		<TableHead>
			<TableRow>
				<TableCell>
					<FormattedMessage {...messages.lblStation} />
				</TableCell>
				<TableCell>
					<FormattedMessage {...messages.lblArrival} />
				</TableCell>
				<TableCell>
					<FormattedMessage {...messages.lblDeparture} />
				</TableCell>
				<TableCell>
					<FormattedMessage {...messages.lblPlatform} />
				</TableCell>
				<TableCell>
					<FormattedMessage {...messages.lblStatus} />
				</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{intermediateStops.map((intermediateStop, index) => (
				<TableRow key={index}>
					<TableCell>{intermediateStop?.Station}</TableCell>
					<TableCell>{intermediateStop?.Arrival}</TableCell>
					<TableCell>{intermediateStop?.Departure}</TableCell>
					<TableCell>{intermediateStop?.Platform}</TableCell>
					<TableCell>{intermediateStop?.Status}</TableCell>
				</TableRow>
			))}
		</TableBody>
	</Table>
);
IntermediateStops.propTypes = {
	intermediateStops: PropTypes.array,
};
export default IntermediateStops;
