import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'app.shopping.title',
		defaultMessage: 'Search for fares',
	},
	lblResetFields: {
		id: 'app.shopping.resetFields',
		defaultMessage: 'Reset Fields',
	},
	errGetAccount: {
		id: 'app.shopping.errGetAccount',
		defaultMessage:
			"There was a problem getting the user's account list. Please try again!",
	},
	lblRecentSearches: {
		id: 'app.shopping.search.recentSearches',
		defaultMessage: 'Recent Searches',
	},
	lblBy: {
		id: 'app.shopping.by',
		defaultMessage: 'by',
	},
	lblSingle: {
		id: 'app.shopping.single',
		defaultMessage: 'Single',
	},
	lblSeason: {
		id: 'app.shopping.season',
		defaultMessage: 'Season Pass',
	},
	msgNoRecentSearches: {
		id: 'app.shopping.msgNoRecentSearches',
		defaultMessage: 'No recent searches',
	},
	lblAdult26_54: {
		id: 'app.shopping.search.adult26_54',
		defaultMessage: 'Adult 26-54',
	},
	lblUKCorporateFare: {
		id: 'app.shopping.search.discountsUKCorporateFare',
		defaultMessage: 'UK Corporate Fare',
	},
	lblTravelAgentRate: {
		id: 'app.shopping.search.discountsTravelAgentRate',
		defaultMessage: 'Travel Agent Rate',
	},
	lblContactTypeBusiness: {
		id: 'app.shopping.contactType.business',
		defaultMessage: 'Business',
	},
	lblContactTypeHome: {
		id: 'app.shopping.contactType.home',
		defaultMessage: 'Home',
	},
	lblContactTypeMobile: {
		id: 'app.shopping.contactType.mobile',
		defaultMessage: 'Mobile',
	},
	lblContactTypeAgency: {
		id: 'app.shopping.contactType.agency',
		defaultMessage: 'Agency',
	},
	lblContactTypeHotel: {
		id: 'app.shopping.contactType.hotel',
		defaultMessage: 'Hotel',
	},
	lblContactTypeCommercial: {
		id: 'app.shopping.contactType.commercial',
		defaultMessage: 'Commercial',
	},
	lblContactTypeDestination: {
		id: 'app.shopping.contactType.destination',
		defaultMessage: 'Destination',
	},
	lblContactTypeAssistant: {
		id: 'app.shopping.contactType.assistant',
		defaultMessage: 'Assistant',
	},
	lblContactTypeUnknown: {
		id: 'app.shopping.contactType.unknown',
		defaultMessage: 'Unknown',
	},
	lblFemale: {
		id: 'app.shopping.female',
		defaultMessage: 'Female',
	},
	lblMale: {
		id: 'app.shopping.male',
		defaultMessage: 'Male',
	},
});
