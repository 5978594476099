import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import styles from '../../styles';
import ExchangeOrderTitle from '../../../../../components/ExchangeOrderDialog/ExchangeOrderTitle';

import { confirmBookingChangeApi } from '../../../../../apiBooking';
import ExtendedSnackbar from '../../../../../../components/ExtendedSnackbar/ExtendedSnackbar';
import RefreshIndicator from '../../../../../../components/RefreshIndicator/RefreshIndicator';
import { fetchBookingDetails } from '../../../../actionsManageBooking';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...styles.dialogPaper,
	},
}));

const PreviewDialog = ({
	open,
	handleDialogClose,
	ticketableFareGroups,
	selectedSeats,
	confirmSeatsData,
	refreshBookingOnSuccess,
	refreshBookingParams,
	closeAllDialogs,
}) => {
	const [isFetching, setIsFetching] = useState(false);
	const [alertText, setAlertText] = useState('');

	const getSelectedSeatInfo = (selectedSeats_) => {
		if (!selectedSeats_ || !Object.values(selectedSeats_).length) {
			return null;
		}

		const [segmentId, segmentValue] = Object.entries(selectedSeats_)[0];
		const [passengerId, seatInfo] = Object.entries(segmentValue)[0];
		const { nearbyCoach, nearbySeat } = seatInfo;

		if (nearbyCoach && nearbySeat) {
			return {
				formattedSeat: `Coach ${nearbyCoach} Seat ${nearbySeat}`,
				nearbyCoach,
				nearbySeat,
				segmentId,
				passengerId,
			};
		}
		return null;
	};

	const {
		formattedSeat: selectedSeat,
		nearbyCoach,
		nearbySeat,
		segmentId,
		passengerId,
	} = getSelectedSeatInfo(selectedSeats) || {};

	const handleConfirmSeats = () => {
		setIsFetching(true);
		setAlertText('');

		confirmBookingChangeApi(
			{
				dataStorageId: confirmSeatsData.DataStorageId,
				shoppingContext: confirmSeatsData.shoppingContext,
				orderLocator: confirmSeatsData.orderLocator,
				recordLocator: confirmSeatsData.recordLocator,
				ticketableFareLocators: confirmSeatsData.ticketableFareLocators,
				srtNearbyCoach: [`${nearbyCoach}:${passengerId}:${segmentId}`],
				srtNearbySeat: [`${nearbySeat}:${passengerId}:${segmentId}`],
				srtSeatResOptions: [],
			},
			(error) => {
				const errorMsg = error?.errorResponse?.message;
				setAlertText(errorMsg);
				setIsFetching(false);
			},
			(success) => {
				console.log('success confirming booking change seats: ', success);
				setIsFetching(false);
				setAlertText('Successfully updated seats');

				setTimeout(() => {
					refreshBookingOnSuccess(refreshBookingParams);
					closeAllDialogs();
					window.location.reload();
				}, 1500);
			},
		);
	};

	return (
		<StyledDialog
			open={open}
			onClose={handleDialogClose}
			disableEnforceFocus
			maxWidth={false}
		>
			<DialogTitle>Seat Amendments Summary</DialogTitle>
			<DialogContent>
				<div>
					<h2>Previous Reservation</h2>
					<ExchangeOrderTitle
						exchangeableTicketableFareIds={
							ticketableFareGroups[0].BookingDetailsLegTicketableFareIds
						}
						isSeasonTrip={false}
						ticketableFareGroups={ticketableFareGroups}
						showSeatsAndServices
					/>
				</div>
				<br />
				<div>
					<h2>Amended Reservation</h2>
					<ExchangeOrderTitle
						exchangeableTicketableFareIds={
							ticketableFareGroups[0].BookingDetailsLegTicketableFareIds
						}
						isSeasonTrip={false}
						ticketableFareGroups={ticketableFareGroups}
						showSeatsAndServices
						selectedSeat={selectedSeat}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<div style={styles.buttonActions}>
					<Button variant="contained" onClick={handleDialogClose}>
						Change Seats
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={styles.confirmButton}
						onClick={handleConfirmSeats}
					>
						Confirm
					</Button>
				</div>
			</DialogActions>
			<ExtendedSnackbar
				id="srtAmendSeatReservationsPreviewSnackBar"
				open={alertText !== ''}
				message={alertText}
				onClose={() => setAlertText('')}
			/>
			<div className="container-fluid">
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={isFetching ? 'loading' : 'hide'}
					style={{
						marginTop: '10%',
						marginLeft: '45%',
					}}
				/>
			</div>
		</StyledDialog>
	);
};

PreviewDialog.propTypes = {
	open: PropTypes.bool,
	handleDialogClose: PropTypes.func,
	ticketableFareGroups: PropTypes.array,
	selectedSeats: PropTypes.any,
	confirmSeatsData: PropTypes.object,
	refreshBookingOnSuccess: PropTypes.func,
	refreshBookingParams: PropTypes.any,
	closeAllDialogs: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
	refreshBookingOnSuccess: bindActionCreators(fetchBookingDetails, dispatch),
});

export default connect(null, mapDispatchToProps)(PreviewDialog);
