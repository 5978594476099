import { lightGray, darkGrayShadow } from '../../colors';

export default {
	refresh: {
		marginTop: '10%',
		marginLeft: '45%',
	},
	stationInfo: {
		marginBottom: 15,
	},
	dialogPaper: {
		overflowY: 'visible',
		maxWidth: 768,
	},
	dialogActionsRoot: {
		width: '100%',
	},
	dialogContent: {
		borderTop: `1px solid ${lightGray}`,
		borderBottom: `1px solid ${lightGray}`,
	},
	refreshView: {
		marginTop: '45%',
		marginLeft: '45%',
	},
	refreshViewContainer: {
		height: '100%',
	},
	stationInfoView: {
		fontSize: 13,
	},
	mapHeight: {
		height: 400,
	},
	mapElement: {
		height: '100%',
	},
	expansion: {
		color: darkGrayShadow,
	},
};
