import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';

import styles from './styles';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
	'& .MuiFormControlLabel-label': {
		...styles.toggleLabel,
	},
	'& .MuiFormControlLabel-root': {
		...styles.toggleRoot,
	},
}));

const TravelCard = (props) => (
	<Paper
		id={`travelCard_${props.id}`}
		style={styles.cardContainer}
		data-testid="travelCard"
	>
		<StyledFormControlLabel
			label={props.price}
			control={
				<Switch
					id={`travelCardSwitch_${props.id}`}
					checked={props.selected}
					onChange={props.onCheckTravelCard}
					inputProps={{ style: styles.toggleInput }}
				/>
			}
		/>
		<div style={styles.cardText}>{props.flexibility}</div>
		<div style={styles.cardText}>{props.zone}</div>
	</Paper>
);

TravelCard.propTypes = {
	price: PropTypes.string.isRequired,
	flexibility: PropTypes.string.isRequired,
	zone: PropTypes.string.isRequired,
	onCheckTravelCard: PropTypes.func.isRequired,
	selected: PropTypes.bool,
	id: PropTypes.string,
};

export { TravelCard as TravelCardAlias };

export default TravelCard;
