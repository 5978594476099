import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.title',
		defaultMessage: 'Custom Information for {passenger}',
	},
	lblCloseButton: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.closeButton',
		defaultMessage: 'Close',
	},
	lblSaveButton: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.saveButton',
		defaultMessage: 'Save Custom Information',
	},
	errCustomInfoType: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.customInfoTypeEmpty',
		defaultMessage: "^Type can't be blank",
	},
	errCustomInfoValue: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.customInfoValueEmpty',
		defaultMessage: "^Value can't be blank",
	},
	errCustomInfoTypeValueTooLong: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.customInfoTypeValueTooLong',
		defaultMessage:
			'^Custom Information type and value fields must be less than 256 characters long.',
	},
	errCustomInfoTypeDuplicate: {
		id: 'app.shopping.manageBooking.passengerCustomInformation.customInfoTypeDuplicate',
		defaultMessage: '^Type is already contained. Please enter a new Type.',
	},
});
