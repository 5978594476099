import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { FormattedMessage } from 'react-intl';

import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import messages from './messages';
import OrderDetailsTable from '../OrderDetailsTable/OrderDetailsTable';
import SeasonTable from '../OrderDetailsTable/SeasonTable';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { ORIGINS } from '../OrderDetailsTable/constants';
import { MODE_TYPES } from '../AllPartModeSelect/constants';

import inlineStyles from './styles';

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});

const CancelVoidOrderMode = ({
	isVoid,
	mode,
	formatMessage,
	handleTicketableFareChange,
	data,
	ticketableFaresState,
	handleSelectAll,
	onChangeCancelledDueToStrike,
	handleReleaseSeatChange,
	handleClose,
	handleViewCancelPenalty,
	handleSubmit,
	bookingOrderRevisions,
	isPartialCancel,
}) => {
	const processingSubmit = data.get('processingSubmit');
	const orderDetails = data.get('orderDetails');
	const promotionDetails = orderDetails.get('BookingOrderSilverPromos')?.toJS();
	const seasonFareOrderDetails = orderDetails
		.get('seasonFareOrderDetails')
		?.toJS();
	const fareRules = orderDetails.get('BookingLegFareRulesDetails')?.toJS();
	const isSeasonBooking = data.get('isSeasonBooking');
	const isSingleTicketableFares = data.get('isSingleTicketableFares');
	const isReleasable = data.get('isReleasable');
	const enableCancellation =
		ticketableFaresState.some((x) => x.toJS().checked) ||
		isSeasonBooking ||
		!isPartialCancel;
	const isSplitTicketFare = data.get('isSplitTicketFare');
	const expirationDate = !!data.get('expirationDate') && (
		<>
			{' '}
			<FormattedMessage {...messages.lblVoidAllowedUntil} />
			{` ${data.get('expirationDate')}`}
		</>
	);
	const voidCancelClasses = isVoid
		? 'col-4 offset-2 offset-md-4'
		: 'col-4 col-md-4';

	useEffect(() => {
		if (!isPartialCancel) {
			handleSelectAll();
		}
	}, [isPartialCancel]);

	return (
		<>
			<DialogTitle>
				<FormattedMessage
					{...messages[`lbl${isVoid ? 'Void' : 'Cancel'}Title`]}
				/>
				<Typography variant="caption">{expirationDate}</Typography>
			</DialogTitle>
			<StyledDialogContent>
				{!isSeasonBooking ? (
					<OrderDetailsTable
						fareRulesDetails={fareRules}
						isSingleTicketableFares={isSingleTicketableFares}
						orderDetails={orderDetails.toJS()}
						origin={ORIGINS[isVoid ? 'VOID' : 'CANCEL']}
						defaultSelectionState={mode === MODE_TYPES.ALL}
						onTicketableFareChange={handleTicketableFareChange}
						radioTitle={formatMessage(
							messages[isVoid ? 'lblVoidItem' : 'lblCancelItem'],
						)}
						selectedTicketableFareId={data.get('selectedTicketableFareId')}
						ticketableFaresSelectable={ticketableFaresState.toJS()}
						orderPriceKey="ticketableFarePrice"
						handleSelectAll={handleSelectAll}
						promotionDetails={promotionDetails}
						bookingOrderRevisions={bookingOrderRevisions?.toJS() || []}
						isPartialCancel={isPartialCancel}
						isSplitTicketFare={isSplitTicketFare}
					/>
				) : (
					<SeasonTable
						fareRulesDetails={fareRules}
						orderDetails={seasonFareOrderDetails}
						origin="BOOKING"
					/>
				)}

				{!isVoid && (
					<>
						{data.get('orderCanBeCancelDueToStrike') && (
							<div className="col-12" style={inlineStyles.subHeader}>
								<div className="col-12" style={inlineStyles.box}>
									<FormattedMessage {...messages.lblNotes} />
								</div>
								<div className="col-12" style={inlineStyles.subHeader}>
									<Switch
										id="cancelledDueToStrike"
										onChange={onChangeCancelledDueToStrike}
									/>
									<FormattedMessage {...messages.lblCancelDueToStrike} />
								</div>
								<div className="col-12" style={inlineStyles.subHeader}>
									{isReleasable === true ? (
										<>
											<Switch
												id="releaseSeatChange"
												onChange={handleReleaseSeatChange}
											/>
											<FormattedMessage {...messages.lblReleaseInventoryOnly} />
										</>
									) : (
										<FormattedMessage {...messages.lblCannotRelease} />
									)}
								</div>
							</div>
						)}
					</>
				)}
			</StyledDialogContent>
			<DialogActions>
				<div className="row" style={inlineStyles.dialogActionsRoot}>
					<div className={voidCancelClasses}>
						<Button
							variant="contained"
							id={`srt${isVoid ? 'Void' : 'Cancel'}OrderClose`}
							onClick={handleClose}
							fullWidth
							disabled={processingSubmit}
						>
							<FormattedMessage {...messages.btnClose} />
						</Button>
					</div>
					{!isVoid && (
						<div className="col-4 col-md-4">
							<Button
								variant="contained"
								id="ViewCancellationPenalty"
								onClick={handleViewCancelPenalty}
								fullWidth
								color="secondary"
								disabled={!enableCancellation || processingSubmit}
							>
								<FormattedMessage {...messages.btnViewCancelPenalty} />
							</Button>
						</div>
					)}
					<div className="col-4 col-md-4">
						{data.get('processingSubmit') ? (
							<RefreshIndicator size={36} left={40} top={0} status="loading" />
						) : (
							<Button
								variant="contained"
								id={`srtRequest${isVoid ? 'Void' : 'Cancellation'}`}
								onClick={handleSubmit}
								fullWidth
								color="primary"
								disabled={!enableCancellation}
							>
								<FormattedMessage
									{...messages[`btn${isVoid ? 'Void' : 'Cancel'}Submit`]}
								/>
							</Button>
						)}
					</div>
				</div>
			</DialogActions>
		</>
	);
};

CancelVoidOrderMode.propTypes = {
	isVoid: PropTypes.bool.isRequired,
	mode: PropTypes.string.isRequired,
	formatMessage: PropTypes.func.isRequired,
	handleTicketableFareChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	ticketableFaresState: PropTypes.object.isRequired,
	handleSelectAll: PropTypes.func.isRequired,
	onChangeCancelledDueToStrike: PropTypes.func.isRequired,
	handleReleaseSeatChange: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleViewCancelPenalty: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	bookingOrderRevisions: PropTypes.object,
	isPartialCancel: PropTypes.bool,
};

export default CancelVoidOrderMode;
