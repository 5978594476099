import ReactGA from 'react-ga4';
import Sha256 from 'sha.js/sha256';
import gaGetEventData from './googleAnalyticsEvents';

export const gaParameters = { // exported for unit test purposes
    initialized: false,
};

export const gaPageView = (location = window.location) => {
    const pageView = location.pathname + location.search;
    if (gaParameters.initialized && gaParameters.trackPageViews && gaParameters.lastPageView !== pageView) {
        ReactGA.set({
            page: location.pathname,
            location: `${window.location.origin}${pageView}`,
        });

        ReactGA.pageview(pageView);
        gaParameters.lastPageView = pageView;
    }
};

export const gaInitialize = (settings) => {
    if (settings['ws.system.analytics.enabled'] === 'true' && settings['ws.system.analytics.provider_tracking_id']) {
        const config = [
            { trackingId: settings['ws.system.analytics.provider_tracking_id'] },
            { trackingId: settings['ws.system.analytics.secondary_provider_tracking_id'] },
        ];

        ReactGA.initialize(config);

        gaParameters.initialized = true;
        gaParameters.environmentLabel = settings['ws.system.analytics.environment_label'];
        gaParameters.jsonExcludeList = settings['ws.system.analytics.json_exclude_list'].split(',');
        gaParameters.trackPageViews = settings['ws.system.analytics.track_page_views'] === 'true';
        gaParameters.trackJsonCalls = settings['ws.system.analytics.track_json_calls'] === 'true';
        gaPageView();
    }
};

export const gaSetUserId = (userId) => {
    const userIdHashed = new Sha256().update(userId).digest('hex');
    gaParameters.userId = userIdHashed;
    ReactGA.set({ userId: userIdHashed });
};

export const gaSetAccount = (account) => {
    gaParameters.account = account;
};

export const gaEventApiCall = (path, queryString, failed, completed, body) => {
    let failedMiddleware = failed;
    let completedMiddleware = completed;

    if (gaParameters.initialized && gaParameters.trackJsonCalls) {
        const endpointSplit = path.split('/');

        if (gaParameters.jsonExcludeList.indexOf(endpointSplit[endpointSplit.length - 1]) === -1) {
            const sendDate = (new Date()).getTime();

            const events = gaGetEventData(path, queryString, body, gaParameters);

            if (events.call) ReactGA.event(events.call());
            const saveTiming = () => {
                ReactGA.ga('send', 'timing', 'Api Call', path, (new Date()).getTime() - sendDate);
            };

            failedMiddleware = (error) => {
                if (events.fail) ReactGA.event(events.fail(error));
                saveTiming();
                return failed ? failed(error) : error;
            };

            completedMiddleware = (response) => {
                if (events.success) ReactGA.event(events.success());
                saveTiming();
                return completed ? completed(response) : response;
            };
        }
    }

    return {
        failed: failedMiddleware,
        completed: completedMiddleware,
    };
};

export const gaEvent = (action, ...rest) => {
    if (gaParameters.initialized) {
        const events = gaGetEventData(action, undefined, undefined, gaParameters);
        if (events.call) ReactGA.event(events.call(...rest));
    }
};
