import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';

import { FormattedMessage } from 'react-intl';

import messages from '../messagesManageAccounts';
import styles from './accountGroupStyles';
import '../stylesManageAccounts.css';

const StyledListSubheader = styled(ListSubheader)(() => ({
	...styles.subHeader,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
	...styles.label,
}));

const SplitTicketing = ({
	splitTicketingEnabled,
	onCheckBoxChange,
	onSelectChange,
	onAddSummatedFareFilter,
	onRemoveSummatedFareFilter,
	onChangeFilterName,
	maxSplits,
	maxSummatedFares,
	splitAtCallingStations,
	splitTicketingFareFilters,
	errors,
	intl: { formatMessage },
}) => {
	const numOfSplits = [];
	for (let i = 1; i <= 10; i++) {
		numOfSplits.push(
			<MenuItem id={`srtNumOfSplits${i}`} value={i} key={i}>
				{i}
			</MenuItem>,
		);
	}

	const summatedFares = [];
	for (let i = 1; i <= 10; i++) {
		summatedFares.push(
			<MenuItem id={`srtSummatedFares${i}`} value={i} key={i}>
				{i}
			</MenuItem>,
		);
	}

	return (
		<>
			<StyledListSubheader>
				<FormattedMessage {...messages.lblSplitTicketingDetails} />
			</StyledListSubheader>
			<StyledFormControlLabel
				control={
					<Switch
						id="srtSplitTicketingAllowed"
						checked={splitTicketingEnabled}
						onChange={onCheckBoxChange('splitTicketingEnabled')}
					/>
				}
				label={<FormattedMessage {...messages.lblSplitTicketingAllowed} />}
				labelPlacement="start"
			/>
			{splitTicketingEnabled && (
				<div>
					<FormControl fullWidth>
						<InputLabel shrink htmlFor="select" style={{ fontSize: 22 }}>
							<FormattedMessage {...messages.lblNumOfSplitsTitle} />
						</InputLabel>
						<Select
							id="selectNumOfSplits"
							value={maxSplits}
							displayEmpty
							onChange={onSelectChange('maxSplits')}
							variant="standard"
						>
							{numOfSplits}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<InputLabel shrink htmlFor="select" style={{ fontSize: 22 }}>
							<FormattedMessage {...messages.lblSummatedFaresTitle} />
						</InputLabel>
						<Select
							id="selectSummatedFares"
							value={maxSummatedFares}
							displayEmpty
							onChange={onSelectChange('maxSummatedFares')}
							variant="standard"
						>
							{summatedFares}
						</Select>
					</FormControl>
					<FormControl
						fullWidth
						style={{ display: 'flex', flexDirection: 'row' }}
					>
						<FormLabel
							htmlFor="select"
							style={{ marginRight: 20, alignSelf: 'center' }}
						>
							<FormattedMessage {...messages.lblSplitAtTitle} />
						</FormLabel>
						<RadioGroup
							name="selectSplitAt"
							onChange={onCheckBoxChange('splitAtCallingStations')}
							value={String(splitAtCallingStations)}
							row
						>
							<FormControlLabel
								id="srtSplitAtAnyCallingStations"
								key={formatMessage(messages.lblAnyCallingStations)}
								control={<Radio />}
								value="true"
								label={formatMessage(messages.lblAnyCallingStations)}
							/>
							<FormControlLabel
								id="srtSplitAtAnyInterchangeStations"
								key={formatMessage(messages.lblAnyInterchangeStations)}
								control={<Radio />}
								value="false"
								label={formatMessage(messages.lblAnyInterchangeStations)}
							/>
						</RadioGroup>
					</FormControl>
					{splitTicketingFareFilters?.map((filter, index) => (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
							}}
							key={index}
						>
							<TextField
								id="srtSummatedFareFilterName"
								label={formatMessage(messages.lblFilterName)}
								style={{ minWidth: 100 }}
								value={filter.get('name')}
								onChange={onChangeFilterName(index)}
								error={!!errors.filtersName && filter.get('name') === ''}
								helperText={errors.filtersName}
								variant="standard"
							/>
							<FormControl style={{ minWidth: 150 }}>
								<InputLabel shrink htmlFor="select" style={{ fontSize: 22 }}>
									<FormattedMessage {...messages.lblFareCategoryTitle} />
								</InputLabel>
								<Select
									id="selectFareCategory"
									value={filter.get('fareCategory')}
									displayEmpty
									onChange={onSelectChange('fareCategory', index)}
									variant="standard"
								>
									<MenuItem id="srtAllCategory" value="All">
										<FormattedMessage {...messages.lblAll} />
									</MenuItem>
									<MenuItem id="srtWalkOn" value="WalkOn">
										<FormattedMessage {...messages.lblWalkOn} />
									</MenuItem>
									<MenuItem id="srtAdvance" value="Advance">
										<FormattedMessage {...messages.lblAdvance} />
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl style={{ minWidth: 150 }}>
								<InputLabel shrink htmlFor="select" style={{ fontSize: 22 }}>
									<FormattedMessage {...messages.lblFareClassTitle} />
								</InputLabel>
								<Select
									id="selectFareClass"
									value={filter.get('fareClass')}
									displayEmpty
									onChange={onSelectChange('fareClass', index)}
									variant="standard"
								>
									<MenuItem id="srtFareClassAll" value="All">
										<FormattedMessage {...messages.lblAll} />
									</MenuItem>
									<MenuItem id="srtStandard" value="Standard">
										<FormattedMessage {...messages.lblStandard} />
									</MenuItem>
									<MenuItem id="srtFirst" value="First">
										<FormattedMessage {...messages.lblFirst} />
									</MenuItem>
								</Select>
							</FormControl>
							<StyledFormControlLabel
								control={
									<Switch
										id="srtExcludeReturnFares"
										checked={filter.get('excludeReturnFares')}
										onChange={onCheckBoxChange('excludeReturnFares', index)}
									/>
								}
								label={
									<FormattedMessage {...messages.lblExcludeReturnFaresTitle} />
								}
								labelPlacement="start"
							/>
							<div style={{ alignSelf: 'center' }}>
								<IconButton onClick={onRemoveSummatedFareFilter(index)}>
									<CloseIcon />
								</IconButton>
							</div>
						</div>
					))}
					<div className="col-12 col-sm-6" style={{ marginTop: '10px' }}>
						<Button
							variant="contained"
							id="srtAccountGroupSummatedFareFilter"
							fullWidth
							color="primary"
							onClick={onAddSummatedFareFilter}
						>
							{formatMessage(messages.lblAddSummatedFareFilter)}
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

SplitTicketing.propTypes = {
	splitTicketingEnabled: PropTypes.bool,
	syncUrl: PropTypes.string,
	maxSplits: PropTypes.string,
	maxSummatedFares: PropTypes.string,
	splitAtCallingStations: PropTypes.bool,
	splitTicketingFareFilters: PropTypes.object,
	onCheckBoxChange: PropTypes.func.isRequired,
	onSelectChange: PropTypes.func.isRequired,
	onAddSummatedFareFilter: PropTypes.func.isRequired,
	onRemoveSummatedFareFilter: PropTypes.func.isRequired,
	onChangeFilterName: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	errors: PropTypes.object,
};

export { SplitTicketing as SplitTicketingAlias };
export default SplitTicketing;
