import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import inlineStyles from '../styles';

const renderPrefDropOptions = (options, travelSegmentId) =>
	options.map((item) => (
		<MenuItem
			id={`srt${travelSegmentId}_${item.value}`}
			key={`${travelSegmentId}_${item.value}`}
			value={item.value}
			style={inlineStyles.content}
		>
			{item.description}
		</MenuItem>
	));

const capitalize = (s) =>
	typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1);

const DropDownList = ({
	travelSegmentId,
	passengerId,
	handleChange,
	disabled,
	itemList: { list, selected, type },
}) => (
	<TextField
		id={`srt${travelSegmentId}_${passengerId}_select${capitalize(type)}`}
		InputProps={{ style: inlineStyles.content }}
		fullWidth
		onChange={handleChange(type, travelSegmentId)}
		value={selected || ''}
		disabled={disabled}
		select
		SelectProps={{ displayEmpty: true }}
		variant="standard"
	>
		{renderPrefDropOptions(list, travelSegmentId)}
	</TextField>
);

DropDownList.propTypes = {
	travelSegmentId: PropTypes.string.isRequired,
	passengerId: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	itemList: PropTypes.object.isRequired,
};

export default DropDownList;
