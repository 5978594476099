import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Switch from '@mui/material/Switch';
import messages from './messagesCoupons';
import inlineStyles from './styles';
import { getReleaseStatus } from '../../ManageBooking/utils';

const StyledTableList = styled('ul')({
	...inlineStyles.tableList,
});

const CouponsTF = ({ isAuthorize, coupons, onChangeCoupon }) => {
	const mergeTravelSegments = (segments) => (
		<StyledTableList>
			{segments.map((segment, index) => (
				<li key={index}>{segment}</li>
			))}
		</StyledTableList>
	);

	const couponSentReceivedMessage = (
		isAuthorizeDialog,
		bookingCouponsAuthorizedDate,
		bookingCouponsReceivedDate,
	) => {
		let message;

		if (isAuthorizeDialog) {
			if (bookingCouponsAuthorizedDate) {
				message = {
					label: messages.lblPrevAuthorized,
					date: bookingCouponsAuthorizedDate,
				};
			} else if (!bookingCouponsReceivedDate) {
				message = { label: messages.lblNotYetReturned };
			}
		} else if (bookingCouponsReceivedDate) {
			message = {
				label: messages.lblPrevReturned,
				date: bookingCouponsReceivedDate,
			};
		}

		return (
			message && (
				<StyledTableList>
					<li>
						<FormattedMessage {...message.label} />
					</li>
					<li>{message.date}</li>
				</StyledTableList>
			)
		);
	};

	const handleToggle =
		(bookingCouponsNumber, uniqueTicketNumber) => (event, value) => {
			onChangeCoupon(bookingCouponsNumber, uniqueTicketNumber, value);
		};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<FormattedMessage
							{...messages[
								isAuthorize ? 'lblAuthorizeRefund' : 'lblReturnCoupon'
							]}
						/>
					</TableCell>
					<TableCell>
						<FormattedMessage {...messages.lblCouponNumber} />
					</TableCell>
					<TableCell>
						<FormattedMessage {...messages.lblCouponType} />
					</TableCell>
					<TableCell>
						<FormattedMessage {...messages.lblReleaseStatus} />
					</TableCell>
					<TableCell>
						<FormattedMessage {...messages.lblUniqueTicketNumber} />
					</TableCell>
					<TableCell>
						<FormattedMessage {...messages.lblTravelSegments} />
					</TableCell>
					<TableCell>
						<FormattedMessage {...messages.lblOriginalCouponNumber} />
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{coupons.map(([, travelSegment], index) => (
					<TableRow key={`segment_${index}`}>
						<TableCell>
							{couponSentReceivedMessage(
								isAuthorize,
								travelSegment.BookingCouponsAuthorizedDate,
								travelSegment.BookingCouponsReceivedDate,
							) || (
								<Switch
									id={`srtCoupon_${travelSegment.BookingCouponsNumber}`}
									value={travelSegment.BookingCouponsNumber}
									onChange={handleToggle(
										travelSegment.BookingCouponsNumber,
										travelSegment.BookingCouponsUniqueTicketNumber,
									)}
									disabled={!travelSegment.BookingCouponsIsReleasable}
								/>
							)}
						</TableCell>
						<TableCell>{travelSegment.BookingCouponsNumber}</TableCell>
						<TableCell>
							{travelSegment.BookingCouponsTypes.join(' & ')}
						</TableCell>
						<TableCell>
							{getReleaseStatus(
								travelSegment.BookingCouponsIsReleasable,
								travelSegment.BookingCouponsReleasedAt,
							) || ''}
						</TableCell>
						<TableCell>
							{travelSegment.BookingCouponsUniqueTicketNumber}
						</TableCell>
						<TableCell>
							{mergeTravelSegments(travelSegment.BookingCouponsSegment)}
						</TableCell>
						<TableCell>
							{travelSegment.BookingCouponsOriginalCouponNumber}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

CouponsTF.propTypes = {
	isAuthorize: PropTypes.bool,
	coupons: PropTypes.array,
	onChangeCoupon: PropTypes.func,
};

CouponsTF.defaultProps = {
	isAuthorize: false,
};

export { CouponsTF as CouponsTFAlias };
export default CouponsTF;
