import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import AccountGroupForm from './containers/AccountGroupForm';
import AccountForm from './containers/AccountForm';
import AccountSearch from './containers/AccountSearch';

function ManageAccounts({ match }) {
	return (
		<div className="container-fluid">
			<div className="col-12">
				<Switch>
					<Route
						path={`${match.path}/Search/:searchStr?`}
						render={AccountSearch}
					/>
					<Route
						path={`${match.path}/Account/:accountName?`}
						render={AccountForm}
					/>
					<Route
						path={`${match.path}/AccountGroup/:accountName?`}
						render={AccountGroupForm}
					/>
					<Redirect path="*" to={`${match.path}/Search`} />
				</Switch>
			</div>
		</div>
	);
}

ManageAccounts.propTypes = {
	match: PropTypes.object,
};

export default ManageAccounts;
