import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messagesFareItinerary';

const ElapsedTime = ({ duration, unconfirmedSchedule }) => (
	<div style={{ fontSize: 12 }}>
		<FormattedMessage
			{...messages.lblElapsedTime}
			values={{ elapsedTime: duration }}
		/>
		{unconfirmedSchedule && (
			<>
				{' '}
				<FormattedMessage {...messages.lblUnconfirmedSchedule} />
			</>
		)}
	</div>
);

ElapsedTime.propTypes = {
	duration: PropTypes.string,
	unconfirmedSchedule: PropTypes.bool,
};

export default ElapsedTime;
