export const textValidCharactersRegex =
	/[^a-zA-z0-9\u2010\2012\u2013\u2014_\\.@ :-]+/;
export const postalCodeRegex = {
	CA: /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/,
	US: /^\d{5}(-\d{4})?$/,
};
export const cardNumberMockedRegex = /^\*{11,12}\d{3,4}$/;
export const phoneNumberRegex = /^[0-9,(,),+,_,#,~,-]{1,45}$/;
export const nameRegex =
	/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/i;
export const urlRegex =
	/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
export const contextRegex = /^[\w\d]+(:[\w\d]+){2,4}$/;
