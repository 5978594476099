import { defineMessages } from 'react-intl';

export default defineMessages({
	lblExchangeItem: {
		id: 'app.booking.exchangeOrder.exchangeItem',
		defaultMessage: 'Exchange Item?',
	},
	lblCarrier: {
		id: 'app.shopping.manageBooking.orderDetailsTable.carrier',
		defaultMessage: 'Carrier',
	},
	lblType: {
		id: 'app.shopping.manageBooking.orderDetailsTable.type',
		defaultMessage: 'Type',
	},
	lblSelectAll: {
		id: 'app.shopping.manageBooking.orderDetailsTable.selectAll',
		defaultMessage: 'Select All',
	},
	lblDeselectAll: {
		id: 'app.shopping.manageBooking.orderDetailsTable.deselectAll',
		defaultMessage: 'Deselect All',
	},
	lblDesignator: {
		id: 'app.shopping.manageBooking.orderDetailsTable.designator',
		defaultMessage: 'Designator',
	},
	lblOrigin: {
		id: 'app.shopping.manageBooking.orderDetailsTable.origin',
		defaultMessage: 'Origin',
	},
	lblDestination: {
		id: 'app.shopping.manageBooking.orderDetailsTable.destination',
		defaultMessage: 'Destination',
	},
	lblDepart: {
		id: 'app.shopping.manageBooking.orderDetailsTable.depart',
		defaultMessage: 'Depart',
	},
	lblDuration: {
		id: 'app.shopping.manageBooking.orderDetailsTable.duration',
		defaultMessage: 'Duration',
	},
	lblCabinClass: {
		id: 'app.shopping.manageBooking.orderDetailsTable.cabinClass',
		defaultMessage: 'Cabin Class',
	},
	lblFare: {
		id: 'app.shopping.manageBooking.orderDetailsTable.fare',
		defaultMessage: 'Fare',
	},
	lblSeats: {
		id: 'app.shopping.manageBooking.orderDetailsTable.seats',
		defaultMessage: 'Seats',
	},
	lblAmenitiesServices: {
		id: 'app.shopping.manageBooking.orderDetailsTable.amenitiesServices',
		defaultMessage: 'Amenities and Services',
	},
	lblFareRules: {
		id: 'app.shopping.manageBooking.orderDetailsTable.fareRules',
		defaultMessage: 'Fare Rules',
	},
	lblTotalPasses: {
		id: 'app.shopping.manageBooking.orderDetailsTable.totalPasses',
		defaultMessage: 'Total Passes',
	},
	lblUnusedPasses: {
		id: 'app.shopping.manageBooking.orderDetailsTable.unusedPasses',
		defaultMessage: 'Unused Passes',
	},
	lblPrice: {
		id: 'app.shopping.manageBooking.orderDetailsTable.price',
		defaultMessage: 'Price',
	},
	lblOptionDisabled: {
		id: 'app.shopping.manageBooking.orderDetailsTable.optionDisabled',
		defaultMessage: 'Not Allowed',
	},
	lblStation: {
		id: 'app.shopping.manageBooking.orderDetailsTable.station',
		defaultMessage: 'Station',
	},
	lblArrival: {
		id: 'app.shopping.manageBooking.orderDetailsTable.arrival',
		defaultMessage: 'Arrival',
	},
	lblDeparture: {
		id: 'app.shopping.manageBooking.orderDetailsTable.departure',
		defaultMessage: 'Departure',
	},
	lblPlatform: {
		id: 'app.shopping.manageBooking.orderDetailsTable.platform',
		defaultMessage: 'Platform',
	},
	lblStatus: {
		id: 'app.shopping.manageBooking.orderDetailsTable.status',
		defaultMessage: 'Status',
	},
	lblStartDate: {
		id: 'app.shopping.manageBooking.orderDetailsTable.startDate',
		defaultMessage: 'Season Start Date',
	},
	lblEndDate: {
		id: 'app.shopping.manageBooking.orderDetailsTable.endDate',
		defaultMessage: 'Season End Date',
	},
	lblSupplementDesc: {
		id: 'app.shopping.manageBooking.orderDetailsTable.supplement.description',
		defaultMessage: 'Description',
	},
	lblSupplementPassengers: {
		id: 'app.shopping.manageBooking.orderDetailsTable.supplement.passengers',
		defaultMessage: 'Passenger(s)',
	},
	lblSupplementAdditionalDetails: {
		id: 'app.shopping.manageBooking.orderDetailsTable.supplement.additionaldetails',
		defaultMessage: 'Additional Details',
	},
	lblSupplementPrice: {
		id: 'app.shopping.manageBooking.orderDetailsTable.supplement.price',
		defaultMessage: 'Price',
	},
	lblSupplementAdditionalProducts: {
		id: 'app.shopping.manageBooking.orderDetailsTable.supplement.additionalproducts',
		defaultMessage: 'Additional Products',
	},
	lblPromotionProducts: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.promotionproducts',
		defaultMessage: 'Discounts',
	},
	lblDiscountType: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.discounttype',
		defaultMessage: 'Discount Type',
	},
	lblDiscountID: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.discountid',
		defaultMessage: 'Discount ID',
	},
	lblValidationCode: {
		id: 'app.shopping.manageBooking.orderDetailsTable.campaigns.validationcode',
		defaultMessage: 'Validation Code',
	},
	lblOpenReturnFare: {
		id: 'app.shopping.manageBooking.orderDetailsTable.openReturnFare',
		defaultMessage: 'Open Return Fare',
	},
	lblAllTicketsCancel: {
		id: 'app.shopping.manageBooking.orderDetailsTable.allTicketsCancel',
		defaultMessage:
			'All tickets on the order must be cancelled together. It is not permitted to cancel individual tickets on the order.',
	},
	lblMultipleFares: {
		id: 'app.shopping.manageBooking.orderDetailsTable.multipleFares',
		defaultMessage: 'Multiple Fares',
	},
	lblHideSeatDetails: {
		id: 'app.shopping.manageBooking.orderDetailsTable.hideSeatDetails',
		defaultMessage: 'Hide Seat Details',
	},
	lblViewSeatDetails: {
		id: 'app.shopping.manageBooking.orderDetailsTable.viewSeatDetails',
		defaultMessage: 'View Seat Details',
	},
});
