import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { injectIntl, FormattedMessage } from 'react-intl';
import messages from './messagesExchangeOrder';
import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';

import ExchangeOrderView from './ExchangeOrderView';
import ShoppingSearch from '../../../Shopping/ShoppingSearch/ShoppingSearch';
import ShoppingResults from '../../../Shopping/ShoppingResults/ShoppingResults';
import CreateBooking from '../../../Shopping/CreateBooking/CreateBooking';
import MakePaymentDialog from '../MakePaymentDialog/MakePaymentDialog';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import { transformFareRulesDetails } from '../OrderDetailsDetails/OrderDetailsDetails';

import './stylesExchangeOrder.css';
import {
	getExchangeOrderDetailsApi,
	processExchangeApi,
} from '../../apiBooking';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { clearSearchResults } from '../../../Shopping/ShoppingResults/actionsShoppingResults';
import {
	clearFields,
	changeDiscountProgram,
	changeDiscountNumber,
	changeRailcardId,
} from '../../../Shopping/ShoppingSearch/actionsShoppingSearch';
import {
	TRIP_TYPE_SINGLE,
	TRIP_TYPE_SEASON,
	additionalRailcards,
} from '../../../Shopping/util';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import ExchangeOrderTitle from './ExchangeOrderTitle';
import ExchangeOrderNotification from './ExchangeOrderNotification';

import { gaEvent } from '../../../utils/googleAnalytics';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
});

class ExchangeOrderDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		open: PropTypes.bool.isRequired,
		orderId: PropTypes.string,
		queryItems: PropTypes.object,
		bookingBillingTotal: PropTypes.object,
		onCloseExchangeOrderDialog: PropTypes.func.isRequired,
		onOrderExchanged: PropTypes.func,
		orderAccount: PropTypes.object,
		passengers: PropTypes.array,
		results: PropTypes.object,
		faresSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		onClearFields: PropTypes.func,
		onClearSearchResults: PropTypes.func,
		onExchangeNeedPay: PropTypes.func,
		orderTicketDeliveryOption: PropTypes.string,
		bookingBillingSummary: PropTypes.array.isRequired,
		booking: PropTypes.object,
		isExchangeValidateFailed: PropTypes.bool,
		seasonFareDetails: PropTypes.object,
		railCards: PropTypes.object,
		onChangeDiscountProgram: PropTypes.func,
		onChangeDiscountNumber: PropTypes.func,
		onChangeRailcardId: PropTypes.func,
	};

	initialState = {
		alertText: '',
		errors: {},
		exchangeBreakdown: true,
		exchangeSummaryResponse: {},
		faresSelected: {},
		isFetching: false,
		hasExchangeError: false,
		mode: null,
		open: false,
		openConfirmDialog: false,
		openMakePayment: false,
		orderDetails: {},
		processingSubmit: false,
		seatReservations: {},
		selectedTdo: {},
		sessionToken: '',
		shoppingContext: '',
		ticketableFaresExchangeable: [],
		travelCard: {},
		tripType: '',
		validateBooking: {},
	};

	constructor(props) {
		super(props);
		this.state = { data: fromJS(this.initialState) };
		this.clearExchangeOrder(props);
		this.searchComp = createRef(null);
	}

	componentDidUpdate(prevProps) {
		const {
			open,
			queryItems,
			orderId,
			intl: { formatMessage },
		} = this.props;
		if (!prevProps.open && open) {
			try {
				this.setState((state) => ({
					// eslint-disable-line react/no-did-update-set-state
					data: state.data.merge({
						isFetching: true,
						orderDetails: {},
					}),
				}));

				getExchangeOrderDetailsApi(
					{ queryItems, orderId },
					(response) => {
						this.setState((state) => ({
							data: state.data.merge({
								alertText: response.errorResponse.message,
								isFetching: false,
							}),
						}));
					},
					(response) => {
						const orderDetails = response.successResponse.data;
						orderDetails.BookingOrders[0] = transformFareRulesDetails(
							orderDetails.BookingOrders[0],
						);
						const { ticketableFareGroups } = orderDetails.BookingOrders[0];

						const ticketableFaresExchangeable = ticketableFareGroups.map(
							(ticketableFareGroup) => ({
								ticketableFareId:
									ticketableFareGroup.BookingDetailsLegTicketableFareIds,
								disabled:
									!ticketableFareGroup.ticketableFareIsPartialCancelOrExchangeable,
								ticketableFarePenalty:
									ticketableFareGroup.ticketableFarePenalty,
							}),
						);

						this.setState((state) => ({
							data: state.data.merge({
								isFetching: false,
								orderDetails,
								selectedTicketableFareId:
									ticketableFaresExchangeable.length === 1 &&
									!ticketableFaresExchangeable[0].disabled
										? ticketableFaresExchangeable[0].ticketableFareId
										: null,
								ticketableFaresExchangeable,
							}),
						}));
					},
				);
			} catch (e) {
				this.setState((state) => ({
					// eslint-disable-line react/no-did-update-set-state
					data: state.data.merge({
						alertText: formatMessage(messages.errExchangeSummary),
					}),
				}));
			}
		}
	}

	componentWillUnmount() {
		this.clearExchangeOrder(this.props);
	}

	clearExchangeOrder = (props) => {
		this.createComp = null;
		props.onClearSearchResults();
		props.onClearFields();
	};

	handleProcessExchange = (
		shoppingContext,
		tripType,
		faresSelected,
		sessionToken,
		selectedTdo,
		travelCard,
		seatReservations,
		exchangeSummary,
		passengers,
		payment,
	) => {
		const { data } = this.state;
		const { queryItems, orderId, onOrderExchanged } = this.props;
		this.setState({
			data: data.merge({ isProcessingSubmit: true, errors: {} }),
		});

		const selectedTicketableFareId = data.get('selectedTicketableFareId');
		const exchangeableTicketableFareIds = selectedTicketableFareId
			? [selectedTicketableFareId]
			: [];

		processExchangeApi(
			{
				shoppingContext,
				tripType,
				faresSelected,
				sessionToken,
				selectedTdo,
				exchangeableTicketableFareIds,
				travelCard,
				seatReservations,
				exchangeSummary: exchangeSummary.toJS(),
				recordLocator: queryItems.srtRecLocator,
				orderId,
				payment,
				passengers,
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						isProcessingSubmit: false,
						openMakePayment: false,
						openConfirmDialog: false,
					}),
				}));
			},
			() => {
				this.setState(
					(state) => ({
						data: state.data.merge({
							isProcessingSubmit: false,
						}),
					}),
					() => {
						this.handleClose();
						onOrderExchanged(queryItems);
					},
				);
			},
		);
	};

	handleRequestExchange = () => {
		gaEvent('requestExchange');
		const { railCards } = this.props;
		const bookingOrderSilverPromos = this.state.data.getIn([
			'orderDetails',
			'BookingOrderSilverPromos',
		]);

		if (bookingOrderSilverPromos) {
			const promos = bookingOrderSilverPromos.toJS();
			const selectedTicketableFareId = this.state.data
				.get('selectedTicketableFareId')
				.split(',');
			const railCardInfo = promos.filter((promo) =>
				selectedTicketableFareId.includes(promo.ticketableFareId),
			);
			const railCardId = railCardInfo.map(({ id }) => id);
			Object.assign(railCards, additionalRailcards(this.props.intl));
			const selectedCard = Object.entries(railCards).filter(function (card) {
				return this.indexOf(card[1].RailCardDesc) > -1;
			}, railCardId);
			const getValidationCode = (railcard) =>
				railCardInfo.find((rc) => rc.id === railcard[1].RailCardDesc)
					.validationCode;

			selectedCard.forEach((card, index) => {
				this.props.onChangeDiscountProgram(card[1].RailCardProgram, index);
				this.props.onChangeDiscountNumber(1, index);
				this.props.onChangeRailcardId(getValidationCode(card), index);
			});
		}
		this.setState((state) => ({
			data: state.data.merge({ exchangeBreakdown: false }),
		}));
	};

	handleClose = (action) => {
		if (action) gaEvent(action);
		this.clearExchangeOrder(this.props);
		this.setState({ data: fromJS(this.initialState) }, () => {
			this.props.onCloseExchangeOrderDialog();
		});
	};

	handleCloseAction = (action) => () => {
		this.handleClose(action);
	};

	handleTicketableFareChange = (ticketableFareId) => {
		this.setState((state) => ({
			data: state.data.set('selectedTicketableFareId', ticketableFareId),
		}));
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	handleExchange = (
		shoppingContext,
		tripType,
		faresSelected,
		sessionToken,
		selectedTdo,
		validateBooking,
		travelCard,
		seatReservations,
		exchangeSummaryResponse,
		passengersInfo,
		needPay,
	) => {
		const passengers = passengersInfo
			.toJS()
			.map(({ passengerId, discountsLoyalties }) => ({
				passengerId,
				discountsLoyalties,
			}));

		if (needPay) {
			this.setState((state) => ({
				data: state.data.merge({
					openMakePayment: true,
					shoppingContext,
					tripType,
					faresSelected,
					sessionToken,
					selectedTdo,
					validateBooking,
					travelCard,
					seatReservations,
					exchangeSummaryResponse,
					passengers,
				}),
			}));
		} else {
			this.handleProcessExchange(
				shoppingContext,
				tripType,
				faresSelected,
				sessionToken,
				selectedTdo,
				travelCard,
				seatReservations,
				exchangeSummaryResponse,
				passengers,
				null,
			);
		}
	};

	handleExchangePay = (payment) => {
		const { data } = this.state;
		this.handleProcessExchange(
			data.get('shoppingContext'),
			data.get('tripType'),
			data.get('faresSelected'),
			data.get('sessionToken'),
			data.get('selectedTdo').toJS(),
			data.get('travelCard'),
			data.get('seatReservations'),
			data.get('exchangeSummaryResponse'),
			data.get('passengers').toJS(),
			payment,
		);
	};

	handleCloseMakePaymentDialog = () => {
		this.setState((state) => ({
			data: state.data.merge({
				openMakePayment: false,
				openConfirmDialog: false,
			}),
		}));
	};

	handleOpenConfirmDialog = () => {
		gaEvent('exchangeOrder');
		this.setState((state) => ({
			data: state.data.merge({ openConfirmDialog: true }),
		}));
	};

	handleCloseConfirmDialog = () => {
		gaEvent('exchangeOrderModalClose');
		this.setState((state) => ({
			data: state.data.merge({ openConfirmDialog: false }),
		}));
	};

	handleExchangeError = () => {
		this.setState((state) => ({
			data: state.data.merge({ hasExchangeError: true }),
		}));
	};

	handleSearch = () => {
		this.searchComp?.current?.handleSubmitSearch();
	};

	render() {
		const {
			booking: originalBooking,
			bookingBillingSummary,
			faresSelected,
			open,
			orderAccount,
			orderId,
			orderTicketDeliveryOption,
			passengers,
			queryItems,
			results,
			seasonFareDetails,
		} = this.props;
		const { data } = this.state;
		let componentMainAction;
		let componentCloseAction = this.handleClose;
		let componentActionLabel;
		let component = null;
		let componentActionInProcess = false;
		const exchangeableTicketableFareIds = [
			data.get('selectedTicketableFareId'),
		];
		let headerExchangeItem;

		const isSeasonTrip =
			originalBooking.BookingOrders[0].BookingLegFareRulesDetails.some(
				(fare) => {
					const fareClass = fare?.fareClasses
						? fare?.fareClasses.toLowerCase()
						: fare?.ShoppingFareRulesFareClasses?.toLowerCase();
					return fareClass?.includes('season') || fareClass?.includes('carnet');
				},
			);

		const openConfirmDialog = data.get('openConfirmDialog');
		const openMakePaymentDialog = data.get('openMakePayment');
		const ticketableFaresExchangeable = data.get('ticketableFaresExchangeable');

		const showBreakdown = data.get('exchangeBreakdown');
		const showExchangeOrder = faresSelected.size === 1;
		const showResults = results && results.size > 0;

		const warningMessage = openConfirmDialog &&
			results.get('ShoppingExchangeCancelledTF') && (
				<ExchangeOrderNotification
					cancelledTFPassengers={results.get('ShoppingExchangeCancelledTF')}
					isConfirmation
				/>
			);

		// To get References to components we need to use getWrappedInstance twice because the components are wrapped 2 times,
		// once by connect and second by react-intl
		// TODO: currently we are only exchanging one ticketable fare.
		// In the future we should be able to support exchange of multiple ticketable fares at a time.
		// We will need to send array of depart, arrive station data and ticketable fares
		if (!showBreakdown) {
			headerExchangeItem = (
				<ExchangeOrderTitle
					exchangeableTicketableFareIds={exchangeableTicketableFareIds}
					orderDetails={data.get('orderDetails')}
					isSeasonTrip={isSeasonTrip}
					seasonFareDetails={seasonFareDetails}
				/>
			);
			if (showExchangeOrder) {
				// since component status in Create Booking is determined by state
				// we need to make a callback on each component status change
				const exchangeTdoOptions = originalBooking.BookingOrders.find(
					(order) => order.BookingOrderID === orderId,
				).BookingOrderTDOData.BookingTDOs;
				component = (
					<CreateBooking
						ref={(comp) => {
							this.createComp = comp;
						}}
						exchangeOrderMode
						orderPassengers={passengers}
						queryItems={queryItems}
						onStatusChange={this.handleStatusChange}
						recordLocator={queryItems.srtRecLocator}
						orderId={orderId}
						exchangeableTicketableFareIds={exchangeableTicketableFareIds}
						onExchange={this.handleExchange}
						orderTicketDeliveryOption={orderTicketDeliveryOption}
						cancelledTFPassengers={results.get('ShoppingExchangeCancelledTF')}
						exchangeTdoOptions={exchangeTdoOptions}
						handleExchangeError={this.handleExchangeError}
					/>
				);

				componentMainAction = () => {
					this.handleOpenConfirmDialog();
				};
				componentCloseAction = 'exchangeOrderClose';
				componentActionLabel = messages.btnExchangeOrder;
			} else if (showResults) {
				component = <ShoppingResults exchangeOrderMode />;
				componentCloseAction = 'exchangeSelectFareClose';
			} else {
				let departStationData = {};
				let arriveStationData = {};
				let selectedFareQualifiers = [];
				let filteredPassengers = passengers;
				let supplyChannelCode = '';
				if (data.get('selectedTicketableFareId')) {
					const ticketableFareGroups = data.getIn([
						'orderDetails',
						'BookingOrders',
						0,
						'ticketableFareGroups',
					]);
					const selectedTicketableFareGroup = ticketableFareGroups.find(
						(ticketableFareGroup) =>
							ticketableFareGroup.get('BookingDetailsLegTicketableFareIds') ===
							data.get('selectedTicketableFareId'),
					);
					if (selectedTicketableFareGroup) {
						departStationData = {
							name: selectedTicketableFareGroup.get('departStationLongName'),
							code: selectedTicketableFareGroup.get('departStationCode'),
							supplier: selectedTicketableFareGroup
								.get('departStationCodeSuppliers')
								.join(','),
							countryCode: selectedTicketableFareGroup.get(
								'departStationCountryCode',
							),
						};
						arriveStationData = {
							name: selectedTicketableFareGroup.get('arriveStationLongName'),
							code: selectedTicketableFareGroup.get('arriveStationCode'),
							supplier: selectedTicketableFareGroup
								.get('arriveStationCodeSuppliers')
								.join(','),
							countryCode: selectedTicketableFareGroup.get(
								'arriveStationCountryCode',
							),
						};

						const booking = this.props.isExchangeValidateFailed
							? originalBooking
							: data.get('validateBooking').toJS();

						selectedFareQualifiers = booking?.BookingFareQualifiers?.filter(
							(fareQualifier) =>
								selectedTicketableFareGroup
									?.toJS()
									.BookingDetailsLegTicketableFareIds?.includes(
										fareQualifier.BookingFareQualifiersTFId,
									),
						);
						supplyChannelCode = booking?.BookingOrders?.filter(
							(order) => order.BookingOrderID === orderId,
						)[0]?.BookingOrderTDOData?.BookingSupplierCodes[0]
							?.BookingSupplierCode;
						selectedFareQualifiers = selectedFareQualifiers?.sort((a, b) => {
							if (
								a?.BookingFareQualifiersPassengerRef &&
								b?.BookingFareQualifiersPassengerRef
							) {
								return (
									parseInt(
										a.BookingFareQualifiersPassengerRef[
											a.BookingFareQualifiersPassengerRef.length - 1
										],
										2,
									) -
									parseInt(
										b.BookingFareQualifiersPassengerRef[
											b.BookingFareQualifiersPassengerRef.length - 1
										],
										2,
									)
								);
							}
							return 0;
						});

						const selectedTicketableFareGroupPassengers =
							selectedTicketableFareGroup
								.get('passengers')
								.map((pax) => pax.get('passengerId'))
								.toJS();

						filteredPassengers = passengers.filter((passenger) =>
							selectedTicketableFareGroupPassengers.includes(
								passenger.BookingPassengerID,
							),
						);
					}
				}
				component = (
					<ShoppingSearch
						ref={this.searchComp}
						exchangeOrderMode
						orderPassengers={filteredPassengers}
						orderAccount={orderAccount}
						departStationData={departStationData}
						arriveStationData={arriveStationData}
						recordLocator={queryItems.srtRecLocator}
						orderId={orderId}
						exchangeableTicketableFareIds={exchangeableTicketableFareIds}
						exchangeOrderTripType={
							isSeasonTrip ? TRIP_TYPE_SEASON : TRIP_TYPE_SINGLE
						}
						selectedFareQualifiers={selectedFareQualifiers}
						supplyChannelCode={supplyChannelCode}
						isSeasonTrip={isSeasonTrip}
					/>
				);

				componentMainAction = this.handleSearch;
				componentCloseAction = 'exchangeSearchFareClose';
				componentActionLabel = messages.btnSearch;
				// For Search component we can use props to determine component state
				componentActionInProcess =
					!!this.searchComp?.current &&
					(this.searchComp?.current?.props?.query?.get('isFetchingUserState') ||
						this.searchComp?.current?.props?.isSearchInitiated);

				if (componentActionInProcess)
					setTimeout(() => {
						this.forceUpdate();
					}, 1000);
			}
		} else {
			componentMainAction = () => {
				this.handleRequestExchange();
			};
			componentCloseAction = 'requestExchangeClose';
			componentActionLabel = messages.btnRequestExchange;
			const someDisabled = ticketableFaresExchangeable.some((item) =>
				item.get('disabled'),
			);
			headerExchangeItem = (
				<Typography variant="caption">
					{someDisabled && (
						<>
							{' '}
							<FormattedMessage {...messages.lblInstructionsPartDisabled} />
						</>
					)}
				</Typography>
			);

			component = (
				<ExchangeOrderView
					orderDetails={data.get('orderDetails')}
					onTicketableFareChange={this.handleTicketableFareChange}
					ticketableFaresExchangeable={ticketableFaresExchangeable}
					selectedTicketableFareId={data.get('selectedTicketableFareId')}
					bookingBillingSummary={bookingBillingSummary}
					isSeasonTrip={isSeasonTrip}
				/>
			);
		}

		const disabled =
			componentActionInProcess ||
			data.get('isProcessingSubmit') ||
			!data.get('selectedTicketableFareId') ||
			data.get('hasExchangeError');
		const columnOffset = componentMainAction
			? 'offset-sm-4 offset-md-6'
			: 'offset-sm-8 offset-md-9';

		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div
					className={`col-12 col-sm-4 col-md-3 ${columnOffset}`}
					style={inlineStyles.buttonFix}
				>
					<Button
						variant="contained"
						id="srtExchangeOrderClose"
						onClick={
							componentCloseAction &&
							this.handleCloseAction(componentCloseAction)
						}
						fullWidth
					>
						<FormattedMessage {...messages.btnClose} />
					</Button>
				</div>
				{componentMainAction && (
					<div
						className="col-12 col-sm-4 col-md-3"
						style={inlineStyles.buttonFix}
					>
						<Button
							variant="contained"
							id="srtExchangeOrder"
							onClick={componentMainAction}
							fullWidth
							color="primary"
							disabled={disabled}
						>
							{componentActionLabel && (
								<FormattedMessage {...componentActionLabel} />
							)}
						</Button>
					</div>
				)}
			</div>
		);

		return (
			<div>
				<StyledDialog
					open={open}
					onClose={
						componentCloseAction && this.handleCloseAction(componentCloseAction)
					}
					disableEnforceFocus
					maxWidth={false}
					scroll={showExchangeOrder ? 'body' : 'paper'}
				>
					<DialogTitle>
						<FormattedMessage {...messages.lblTitle} />
						{headerExchangeItem}
					</DialogTitle>
					<DialogContent>
						<div
							className="container-fluid"
							styleName="srtExchangeOrderContainer"
						>
							{component}
						</div>
						<div className="container-fluid">
							<RefreshIndicator
								size={36}
								top={0}
								left={0}
								status={
									data.get('isFetching') || data.get('isProcessingSubmit')
										? 'loading'
										: 'hide'
								}
								style={inlineStyles.refresh}
							/>
						</div>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				{openConfirmDialog && (
					<ConfirmDialog
						open={open}
						title={<FormattedMessage {...messages.lblConfirmDialogTitle} />}
						message={
							<>
								<FormattedMessage {...messages.lblConfirmDialogMessage} />
								{warningMessage}
							</>
						}
						yesButtonLabel={
							<FormattedMessage {...messages.btnConfirmDialogYes} />
						}
						noButtonLabel={
							<FormattedMessage {...messages.btnConfirmDialogNo} />
						}
						onYes={() => {
							gaEvent('exchangeOrderModalContinue');
							this.createComp.handleExchangeOrder();
						}}
						onNo={this.handleCloseConfirmDialog}
						isProcessingSubmit={data.get('isProcessingSubmit')}
					/>
				)}
				{openMakePaymentDialog && (
					<MakePaymentDialog
						onClose={this.handleCloseMakePaymentDialog}
						open={openMakePaymentDialog}
						exchangeMode
						booking={
							this.props.isExchangeValidateFailed
								? originalBooking
								: data.get('validateBooking').toJS()
						}
						exchangeBalance={data.getIn([
							'exchangeSummaryResponse',
							'BookingExchangeBalance',
						])}
						onExchangePay={this.handleExchangePay}
					/>
				)}
				<ExtendedSnackbar
					id="srtExchangeOrderSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

const mapsStateToProps = (state) => ({
	results: state.getIn(['shopping', 'results', 'results']),
	faresSelected: state.getIn(['shopping', 'results', 'faresSelected']),
	isExchangeValidateFailed: state.getIn([
		'booking',
		'isExchangeValidateFailed',
	]),
	seasonFareDetails: state.getIn([
		'booking',
		'orderDetails',
		'BookingSeasonFareDetails',
	]),
	railCards: state
		.getIn(['settings', 'Railcards'])
		?.get('RailCardTypes')
		.reduce(
			(source, item) =>
				Object.assign(source, { [item.get('RailCardProgram')]: item.toJS() }),
			{},
		),
});
const mapDispatchToProps = (dispatch) => ({
	onOrderExchanged: bindActionCreators(fetchBookingDetails, dispatch),
	onClearSearchResults: bindActionCreators(clearSearchResults, dispatch),
	onClearFields: bindActionCreators(clearFields, dispatch),
	onChangeDiscountProgram: bindActionCreators(changeDiscountProgram, dispatch),
	onChangeDiscountNumber: bindActionCreators(changeDiscountNumber, dispatch),
	onChangeRailcardId: bindActionCreators(changeRailcardId, dispatch),
});

export default connect(
	mapsStateToProps,
	mapDispatchToProps,
)(injectIntl(ExchangeOrderDialog));
