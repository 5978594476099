import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.shopping.confirmationOptions.title',
		defaultMessage: 'Confirmation Options',
	},
	lblConfirmOrderUponPayment: {
		id: 'app.shopping.confirmationOptions.confirmOrderUponPayment',
		defaultMessage: 'Confirm Order Upon Payment',
	},
	lblDoNotConfirmOrderUponPayment: {
		id: 'app.shopping.confirmationOptions.doNotConfirmOrder',
		defaultMessage: 'Do Not Confirm Order Upon Payment',
	},
	lblAcceptableConfirmationOptions: {
		id: 'app.shopping.confirmationOptions.acceptableConfirmationOptions',
		defaultMessage: 'Acceptable forms of confirmation',
	},
	lblCreditCard: {
		id: 'app.shopping.confirmationOptions.creditCard',
		defaultMessage: 'Credit Card',
	},
	lblLoyaltyCard: {
		id: 'app.shopping.confirmationOptions.loyaltyCard',
		defaultMessage: 'Loyalty Card',
	},
	lblDebitCard: {
		id: 'app.shopping.confirmationOptions.debitCard',
		defaultMessage: 'Debit Card',
	},
	lblTicketLanguage: {
		id: 'app.shopping.confirmationOptions.ticketLanguage',
		defaultMessage: 'Ticket Language',
	},
	lblConfirmationRequired: {
		id: 'app.shopping.confirmationOptions.confirmationRequired',
		defaultMessage:
			'Travelers must carry their Confirmation Card on the train.',
	},
	lblConfirmationDetails: {
		id: 'app.shopping.confirmationOptions.confirmationDetails',
		defaultMessage: 'Confirmation Details',
	},
	lblCreditCardOptions: {
		id: 'app.shopping.confirmationOptions.creditCardOptions',
		defaultMessage: 'Credit Card Options',
	},
	lblDebitCardOptions: {
		id: 'app.shopping.confirmationOptions.debitCardOptions',
		defaultMessage: 'Debit Card Options',
	},
	lblLoyaltyCardOptions: {
		id: 'app.shopping.confirmationOptions.loyaltyCardOptions',
		defaultMessage: 'Loyalty Card Options',
	},
	lblLoyaltyCardProgram: {
		id: 'app.shopping.confirmationOptions.loyaltyCardProgram',
		defaultMessage: 'Loyalty Card Program',
	},
	lblLoyaltyIdentifier: {
		id: 'app.shopping.confirmationOptions.loyaltyIdentifier',
		defaultMessage: 'Identifier',
	},
	lblConfDay: {
		id: 'app.shopping.confirmationOptions.confDay',
		defaultMessage: 'Day',
	},
	lblConfMonth: {
		id: 'app.shopping.confirmationOptions.confMonth',
		defaultMessage: 'Month',
	},
	lblConfYear: {
		id: 'app.shopping.confirmationOptions.confYear',
		defaultMessage: 'Year',
	},
	lblConfExpiryDate: {
		id: 'app.shopping.confirmationOptions.confExpiryDate',
		defaultMessage: 'Expiry Date',
	},
	lblConfirmAfterMessage: {
		id: 'app.shopping.confirmationOptions.confirmAfterMessage',
		defaultMessage: 'Please confirm the order after payment is complete.',
	},
	lblConfirmAutomaticMessage: {
		id: 'app.shopping.confirmationOptions.confirmAutomaticMessage',
		defaultMessage:
			'Your booking will be automatically confirmed upon your payment.',
	},
	lblConfirmEachMessage: {
		id: 'app.shopping.confirmationOptions.confirmEachMessage',
		defaultMessage:
			'Please confirm each order individually after payment is complete.',
	},
	lblConfirmedMessage: {
		id: 'app.shopping.confirmationOptions.confirmedMessage',
		defaultMessage: 'Orders have been confirmed.',
	},
	lblConfirmCarryCard: {
		id: 'app.shopping.confirmationOptions.confirmCarryCard',
		defaultMessage:
			'Travelers must carry their Confirmation Card on the train.',
	},
	lblConfirmationNotRequired: {
		id: 'app.shopping.confirmationOptions.confirmationNotRequired',
		defaultMessage:
			'Confirmation Information is not required to confirm the order.',
	},
	lblUsePaymentCard: {
		id: 'app.shopping.confirmationOptions.usePaymentCard',
		defaultMessage: 'Use payment card',
	},
});
