import colors, { pelorous } from '../../../../colors';

export default {
	viewContainerPadding: {
		paddingTop: 15,
	},
	refresh: {
		marginTop: '40%',
		marginLeft: '45%',
	},
	paperContainer: {
		marginBottom: 5,
		padding: '5px 5px 10px 10px',
		overflow: 'hidden',
	},
	contentContainer: {
		padding: 4,
	},
	bookingTable: {
		tableLayout: 'auto',
		marginBottom: 15,
		'& div': {
			overflowX: 'auto',
		},
	},
	bookingTableLink: {
		textDecoration: 'underline',
		cursor: 'pointer',
		color: pelorous,
	},
	bookingTableRow: {
		whiteSpace: 'normal',
	},
	indicator: {
		marginTop: '15%',
		marginLeft: '49%',
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	redButton: {
		backgroundColor: colors.buttonRed,
		color: '#fff',
		'&:hover': {
			backgroundColor: colors.buttonRed,
		},
	},
	orangeButton: {
		backgroundColor: colors.buttonOrange,
		color: '#fff',
		'&:hover': {
			backgroundColor: colors.buttonOrange,
		},
	},
	titleContainer: {
		marginTop: 15,
	},
};
