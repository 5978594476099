import { fromJS } from 'immutable';
import {
	navigatorLanguagesMap,
	GET_SETTINGS,
	CLEAR_SETTINGS,
	USER_LOGOUT,
} from '../constants/constants';

const navigatorLanguage =
	(navigator.languages && navigator.languages[0]) ||
	navigator.language ||
	navigator.userLanguage;

const initialState = fromJS({
	'ws.system.localization.language_country':
		navigatorLanguagesMap[navigatorLanguage] || 'en_GB',
});

const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SETTINGS:
			return state.merge(action.payload);
		case CLEAR_SETTINGS:
			return initialState;
		case USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default settingsReducer;
