import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.common.stationInformation.title',
		defaultMessage: 'Station Information',
	},
	lblLatitude: {
		id: 'app.common.stationInformation.latitude',
		defaultMessage: 'Latitude',
	},
	lblLongitude: {
		id: 'app.common.stationInformation.longitude',
		defaultMessage: 'Longitude',
	},
	btnClose: {
		id: 'app.common.stationInformation.close',
		defaultMessage: 'Close',
	},
	errStationSearch: {
		id: 'app.common.stationInformation.errorSearch',
		defaultMessage:
			'Detailed station information is not yet available for this station',
	},
});
