import { defineMessages } from 'react-intl';

export default defineMessages({
	lblCurrentPassword: {
		id: 'app.common.passwordUpdate.currentPassword',
		defaultMessage: 'Current Password',
	},
	lblNewPassword: {
		id: 'app.common.passwordUpdate.newPassword',
		defaultMessage: 'New Password',
	},
	lblConfirmNewPassword: {
		id: 'app.common.passwordUpdate.confirmNewPassword',
		defaultMessage: 'Confirm New Password',
	},
	lblPasswordRules: {
		id: 'app.common.passwordUpdate.passwordRules',
		defaultMessage: 'A password must contain a minimum of:',
	},
	lblPasswordSix: {
		id: 'app.common.passwordUpdate.ruleSix',
		defaultMessage: 'Six characters (symbols are ok to use)',
	},
	lblPasswordLowercase: {
		id: 'app.common.passwordUpdate.ruleLowercase',
		defaultMessage: 'One lowercase letter',
	},
	lblPasswordUppercase: {
		id: 'app.common.passwordUpdate.ruleUppercase',
		defaultMessage: 'One uppercase letter',
	},
	lblPasswordNumber: {
		id: 'app.common.passwordUpdate.ruleNumber',
		defaultMessage: 'One number',
	},
	lblPasswordSymbol: {
		id: 'app.common.passwordUpdate.ruleSymbol',
		defaultMessage: 'Symbols are ok to use',
	},
	btnClose: {
		id: 'app.common.passwordUpdate.close',
		defaultMessage: 'Close',
	},
	btnSave: {
		id: 'app.common.passwordUpdate.save',
		defaultMessage: 'Save',
	},
	lblRequestNewLink: {
		id: 'app.common.passwordUpdate.lblRequestNewLink',
		defaultMessage:
			'The link used has expired or is invalid. Click below to request a new link.',
	},
	btnRequestNewLink: {
		id: 'app.common.passwordUpdate.btnRequestNewLink',
		defaultMessage: 'Request New Link',
	},
	errChangePassword: {
		id: 'app.common.passwordUpdate.errorChangePassword',
		defaultMessage: 'There was a problem changing password. Please try again!',
	},
	okChangePassword: {
		id: 'app.common.passwordUpdate.successChangePassword',
		defaultMessage: 'Success: Password updated',
	},
	errCurrentPasswordEmpty: {
		id: 'app.common.passwordUpdate.currentPasswordEmpty',
		defaultMessage: '^Please enter a correct current password.',
	},
	errNewPasswordEmpty: {
		id: 'app.common.passwordUpdate.newPasswordEmpty',
		defaultMessage: '^Please enter a correct new password.',
	},
	errNewPasswordMinReq: {
		id: 'app.common.passwordUpdate.newPasswordMinReq',
		defaultMessage: '^New password does not contain the minimum requirements.',
	},
	errNewPasswordDontMatch: {
		id: 'app.common.passwordUpdate.newPasswordDontMatch',
		defaultMessage: '^New passwords do not match.',
	},
});
