export default {
	cardContainer: {
		marginTop: 16,
	},
	fareContainer: {
		paddingBottom: 16,
	},
	cardTitle: {
		fontSize: 20,
	},
	orderTitle: {
		display: 'inline-block',
		padding: '16px 16px 0 16px',
	},
	orderTotalTitle: {
		float: 'right',
		display: 'inline-block',
		padding: '16px 16px 0 16px',
	},
	textColumn: {
		textAlign: 'left',
		fontSize: 13,
	},
	priceColumn: {
		textAlign: 'right',
		fontSize: 13,
	},
};
