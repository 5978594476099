import { defineMessages } from 'react-intl';

export default defineMessages({
	lblType: {
		id: 'app.shopping.filters.type',
		defaultMessage: 'Type',
	},
	lblCabin: {
		id: 'app.shopping.filters.cabin',
		defaultMessage: 'Cabin',
	},
	lblSchedule: {
		id: 'app.shopping.filters.schedule',
		defaultMessage: 'Schedule',
	},
	lblFlexibility: {
		id: 'app.shopping.filters.flexibility',
		defaultMessage: 'Flexibility',
	},
});
