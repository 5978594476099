import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import styles from './styles';

import SegmentRow from './SegmentRow';
import TicketableFareGroupTitle from '../../../components/TicketableFareGroupTitle/TicketableFareGroupTitle';

import { getFareRules } from '../../utils';
import { formatPrice } from '../../../utils';
import { ORIGINS } from './constants';
import PromotionDetailsTable from './PromotionDetailsTable';

const StyledTableCell = styled(TableCell)({
	...styles.description,
});

const TravelSegments = ({
	fareRulesDetails,
	intl,
	isSingleTicketableFares,
	orderDetails,
	showIntermediateStops,
	orderPriceKey,
	defaultSelectionState,
	onTicketableFareChange,
	selectedTicketableFareId,
	ticketableFaresSelectable,
	origin,
	handleOpenFareRulesMatrix,
	handleOpenStationInfoDialog,
	promotionDetails,
	radioTitle,
	bookingOrderRevisions,
	isPartialCancel,
	isSplitTicketFare,
}) => {
	const ticketableFareGroups = orderDetails?.ticketableFareGroups ?? [];

	const rowsCount = ticketableFareGroups.reduce(
		(count, ticketableFareGroup) =>
			count +
			ticketableFareGroup.BookingLegTravelSegments?.reduce(
				(travelSegmentCount, travelSegment) =>
					travelSegmentCount +
					(travelSegment.IntermediateTravelPoints?.length ? 2 : 1) +
					(travelSegment.BookingOrderBikeReservationDetails?.length
						? travelSegmentCount + 1
						: travelSegmentCount),
				0,
			),
		0,
	);

	const clearNbsp = (string) => string.replace(/&nbsp;/g, ' ');

	const fareIdIndex = [];

	const foundMatchFareId = ticketableFareGroups.map(
		(ticketableFareGroup, ticketableFareGroupIndex) => {
			const combinedTicketableFareSetId =
				ticketableFareGroup.BookingDetailsLegCombinedTicketableFareSetId;
			const combinedTicketableFareSetId2 =
				ticketableFareGroups[ticketableFareGroupIndex + 1]
					?.BookingDetailsLegCombinedTicketableFareSetId;
			const hasMatchFareId =
				combinedTicketableFareSetId === combinedTicketableFareSetId2 &&
				combinedTicketableFareSetId !== null;

			if (!hasMatchFareId) {
				fareIdIndex.push(ticketableFareGroupIndex);
			}

			return hasMatchFareId;
		},
	);

	let count = 0;
	let checkTiltle = 0;

	return ticketableFareGroups.map(
		(ticketableFareGroup, ticketableFareGroupIndex) => {
			let rowSpan;
			const filteredPromotionDetails = promotionDetails?.filter(
				(bookingOrderSilverPromo) =>
					ticketableFareGroup.BookingDetailsLegTicketableFareIds?.indexOf(
						bookingOrderSilverPromo.ticketableFareId,
					) !== -1,
			);

			let departStation = '';
			let arriveStation = '';
			let showSplitTitle = false;

			if (
				checkTiltle === 0 &&
				foundMatchFareId[ticketableFareGroupIndex] &&
				ticketableFareGroupIndex === 0
			) {
				departStation = ticketableFareGroup.departStationLongName;
				arriveStation =
					ticketableFareGroups[fareIdIndex[checkTiltle]]?.arriveStationLongName;
				showSplitTitle = true;
			} else if (
				!foundMatchFareId[ticketableFareGroupIndex] &&
				fareIdIndex[checkTiltle] === ticketableFareGroupIndex
			) {
				checkTiltle += 1;
				if (!foundMatchFareId[ticketableFareGroupIndex - 1]) {
					showSplitTitle = false;
				} else {
					showSplitTitle = true;
					departStation = ticketableFareGroup.departStationLongName;
					arriveStation =
						ticketableFareGroups[fareIdIndex[checkTiltle]]
							?.arriveStationLongName;
				}
			}

			return (
				<Fragment key={ticketableFareGroup.BookingDetailsLegTicketableFareIds}>
					{!foundMatchFareId[ticketableFareGroupIndex - 1] && (
						<TableRow>
							<StyledTableCell
								id={`srtBookingTicketableFareGroup_${ticketableFareGroupIndex}`}
								data-testid="fare-title"
								colSpan={defaultSelectionState ? 14 : 13}
							>
								<TicketableFareGroupTitle
									ticketableFareGroup={ticketableFareGroup}
									showSplitTitle={showSplitTitle}
									departStation={departStation}
									arriveStation={arriveStation}
								/>
							</StyledTableCell>
						</TableRow>
					)}
					{ticketableFareGroup.BookingLegTravelSegments?.map(
						(travelSegment, travelSegmentIndex) => {
							const fareRules = getFareRules(
								travelSegment.BookingLegTravelSegmentId,
								fareRulesDetails,
							);
							const fareNames = fareRules?.map(
								(rule) => rule.displayName || rule.ShoppingLegFareDisplayName,
							) ?? [travelSegment.BookingLegTravelSegmentFare];
							const uniqueFareName = Array.from(new Set(fareNames));
							const bookingOrderRevisionsLeg = bookingOrderRevisions?.length
								? bookingOrderRevisions.find(
										(revision) =>
											revision.travelSegmentIDRef ===
											travelSegment.BookingLegTravelSegmentId,
									)
								: {};
							const openReturn =
								travelSegment.BookingLegTravelSegmentOpenReturn === 'YES';

							let price;
							let showPrice = true;
							let travelSubSegments =
								ticketableFareGroup.BookingLegTravelSubSegments;
							if (
								(!isSingleTicketableFares || ticketableFareGroupIndex === 0) &&
								travelSegmentIndex === 0
							) {
								if (isSingleTicketableFares) {
									rowSpan = rowsCount;
									price = ticketableFareGroup[orderPriceKey];
								} else {
									rowSpan = ticketableFareGroup.BookingLegTravelSegments.length;
									showPrice =
										ticketableFareGroupIndex === 0
											? true
											: fareIdIndex[count - 1] + 1 === ticketableFareGroupIndex;
									if (foundMatchFareId[ticketableFareGroupIndex]) {
										price =
											ticketableFareGroups[fareIdIndex[count]]
												?.ticketableFarePrice;
										travelSubSegments =
											ticketableFareGroups[fareIdIndex[count]]
												?.BookingLegTravelSubSegments;
										rowSpan =
											count === 0
												? fareIdIndex[count] + 1
												: fareIdIndex[count] - fareIdIndex[count - 1];
									} else {
										price = ticketableFareGroup.ticketableFarePrice;
										count += 1;
									}
								}

								price = formatPrice(
									Number(price) || 0,
									orderDetails.BookingDetailsOrderTotalPriceCurrency ||
										ticketableFareGroup.ticketableFareCurrency,
									intl,
								);
							}

							return (
								<SegmentRow
									key={travelSegment.BookingLegTravelSegmentId}
									ticketableFareGroupIndex={ticketableFareGroupIndex}
									travelSegmentId={travelSegment.BookingLegTravelSegmentId}
									carrier={
										travelSegment.BookingOrderDetailsLegTravelSegmentCarrier
									}
									type={travelSegment.BookingOrderDetailsLegTravelSegmentType}
									designator={travelSegment.BookingLegTravelSegmentDesignator}
									origin={clearNbsp(
										travelSegment.BookingOrderDetailsLegTravelSegmentOrigCodeAndTime,
									)}
									destination={clearNbsp(
										travelSegment.BookingOrderDetailsLegTravelSegmentDestCodeAndTime,
									)}
									depart={travelSegment.BookingLegTravelSegmentDepartDate}
									duration={travelSegment.BookingLegTravelSegmentDuration}
									cabinClass={travelSegment.BookingLegTravelSegmentCabinClass}
									fare={uniqueFareName}
									openReturn={openReturn}
									seats={travelSegment.BookingLegTravelSegmentSeats}
									amenitiesServices={
										travelSegment.BookingOrderServicesAndAmenities
									}
									onOpenFareRules={handleOpenFareRulesMatrix}
									intermediateTravelPoints={
										travelSegment.IntermediateTravelPoints
									}
									showIntermediateStops={showIntermediateStops}
									price={price}
									priceRowSpan={rowSpan}
									handleOpenStationInfoDialog={handleOpenStationInfoDialog}
									destCode={
										travelSegment.BookingOrderDetailsLegTravelSegmentDestCode
									}
									origCode={
										travelSegment.BookingOrderDetailsLegTravelSegmentOrigCode
									}
									showSelectionRadio={defaultSelectionState}
									onTicketableFareChange={onTicketableFareChange}
									selectedTicketableFareId={selectedTicketableFareId}
									isExchange={origin === 'EXCHANGE'}
									bikeReservationDetails={
										travelSegment.BookingOrderBikeReservationDetails
									}
									radioTitle={radioTitle}
									ticketableFareSelectable={ticketableFaresSelectable?.find(
										(ticketableFare) =>
											ticketableFare.ticketableFareId ===
												ticketableFareGroup.BookingDetailsLegTicketableFareIds &&
											!travelSegmentIndex,
									)}
									bookingOrderRevisionsLeg={bookingOrderRevisionsLeg}
									arriveDateTime={
										travelSegment.BookingLegTravelSegmentArriveDateTime
									}
									departDateTime={
										travelSegment.BookingLegTravelSegmentDepartDateTime
									}
									isPartialCancel={isPartialCancel}
									isSplitTicketFare={isSplitTicketFare}
									travelSubSegments={travelSubSegments}
									ticketableFareGroup={ticketableFareGroup}
									intl={intl}
									splitFareGroup={foundMatchFareId[ticketableFareGroupIndex]}
									showPrice={showPrice}
									showSplitTitle={showSplitTitle}
									departStation={departStation}
									arriveStation={arriveStation}
								/>
							);
						},
					)}
					<PromotionDetailsTable
						promotionDetails={filteredPromotionDetails}
						radioTitle={radioTitle}
					/>
				</Fragment>
			);
		},
	);
};

TravelSegments.propTypes = {
	fareRulesDetails: PropTypes.array,
	intl: PropTypes.object,
	isSingleTicketableFares: PropTypes.bool,
	onTicketableFareChange: PropTypes.func,
	orderDetails: PropTypes.object,
	orderPriceKey: PropTypes.string,
	origin: PropTypes.oneOf(Object.values(ORIGINS)),
	selectedTicketableFareId: PropTypes.string,
	showIntermediateStops: PropTypes.bool,
	defaultSelectionState: PropTypes.bool,
	ticketableFaresSelectable: PropTypes.array,
	handleOpenFareRulesMatrix: PropTypes.func,
	handleOpenStationInfoDialog: PropTypes.func,
	promotionDetails: PropTypes.array,
	radioTitle: PropTypes.node,
	bookingOrderRevisions: PropTypes.array,
	isPartialCancel: PropTypes.bool,
	isSplitTicketFare: PropTypes.bool,
};

export default TravelSegments;
