import { defineMessages } from 'react-intl';

export default defineMessages({
	lblClose: {
		id: 'app.shopping.manageBooking.revision.close',
		defaultMessage: 'Close',
	},
	lblRevision113: {
		id: 'app.shopping.manageBooking.revision.text113',
		defaultMessage:
			'This train has been cancelled because of a problem at a level crossing',
	},
	lblRevision564: {
		id: 'app.shopping.manageBooking.revision.text564',
		defaultMessage:
			'This train has been cancelled because of a security alert at a station',
	},
	lblRevision907: {
		id: 'app.shopping.manageBooking.revision.text907',
		defaultMessage:
			'This train has been delayed by the train departing late to maintain customer connections',
	},
	lblRevisionDelay685: {
		id: 'app.shopping.manageBooking.revision.textDelay685',
		defaultMessage: 'This train has been delayed by overcrowding',
	},
	lblRevisionCancel685: {
		id: 'app.shopping.manageBooking.revision.textCancel685',
		defaultMessage: 'This train has been cancelled by overcrowding',
	},
	lblOriginModifications: {
		id: 'app.shopping.manageBooking.revision.OriginModifications',
		defaultMessage: 'Origin: Modifications to station and departure date/time.',
	},
	lblOriginNoModifications: {
		id: 'app.shopping.manageBooking.revision.OriginNoModifications',
		defaultMessage: 'Origin: no modifications',
	},
	lblDestinationModifications: {
		id: 'app.shopping.manageBooking.revision.DestinationModifications',
		defaultMessage:
			'Destination: Modifications to station and arrival date/time.',
	},
	lblDestinationNoModifications: {
		id: 'app.shopping.manageBooking.revision.DestinationNoModifications',
		defaultMessage: 'Destination: no modifications',
	},
	lblOriginalScheduledDepart: {
		id: 'app.shopping.manageBooking.revision.OriginalScheduledDepart',
		defaultMessage: 'Originally scheduled to depart',
	},
	lblNowDepart: {
		id: 'app.shopping.manageBooking.revision.NowDepart',
		defaultMessage: 'Now departing',
	},
	lblDepart: {
		id: 'app.shopping.manageBooking.revision.Depart',
		defaultMessage: 'Scheduled to depart',
	},
	lblOriginalArrive: {
		id: 'app.shopping.manageBooking.revision.OriginalArrive',
		defaultMessage: 'Originally scheduled to arrive',
	},
	lblNowArriving: {
		id: 'app.shopping.manageBooking.revision.NowArriving',
		defaultMessage: 'Now arriving',
	},
	lblDelayed: {
		id: 'app.shopping.manageBooking.revision.Delayed',
		defaultMessage: 'Delayed',
	},
	lblCancelled: {
		id: 'app.shopping.manageBooking.revision.Cancelled',
		defaultMessage: 'Cancelled',
	},
	lblBoardingNotPossible: {
		id: 'app.shopping.manageBooking.revision.BoardingNotPossible',
		defaultMessage: 'Boarding Not Possible',
	},
	lblAlightingNotPossible: {
		id: 'app.shopping.manageBooking.revision.AlightingNotPossible',
		defaultMessage: 'Alighting Not Possible',
	},
	lblRescheduled: {
		id: 'app.shopping.manageBooking.revision.Rescheduled',
		defaultMessage: 'Rescheduled',
	},
	lblRevisions: {
		id: 'app.shopping.manageBooking.revision.Revisions',
		defaultMessage: 'Revisions',
	},
	lblServiceAlerts: {
		id: 'app.shopping.manageBooking.revision.ServiceAlerts',
		defaultMessage: 'Service Alerts',
	},
});
