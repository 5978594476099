import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import StationAutoComplete from '../../components/StationAutoComplete/StationAutoComplete';
import messages from '../messagesShoppingSearch';
import '../shoppingSearch.css';
import inlineStyles from './styles';
import { viaAvoidDirection } from './constants';

function ViaAvoid(props) {
	const {
		direction,
		disabled,
		errors,
		id,
		intl: { formatMessage },
		journey,
		onChangeViaAvoidStation,
		onChangeViaAvoidType,
		onClearStation,
		onQuickSearchSwitch,
		onViaAvoidToggle,
		quickSearch,
		showViaAvoid,
	} = props;
	const viaAvoidDisabled = journey.departStation.code === '';

	const directionMessages = {
		[viaAvoidDirection.OUTBOUND]: {
			viaAvoidLink: messages.lblViaAvoidOutbound,
			selectViaAvoid: messages.lblSelectViaAvoidOutbound,
		},
		[viaAvoidDirection.INBOUND]: {
			viaAvoidLink: messages.lblViaAvoidInbound,
			selectViaAvoid: messages.lblSelectViaAvoidInbound,
		},
	}[direction];

	const viaAvoidLink = (
		<div className="row">
			<div className="col-12">
				<Button
					id={`${id}Button`}
					variant="text"
					color="secondary"
					onClick={() => onViaAvoidToggle(direction)}
					disabled={disabled}
					style={inlineStyles.viaAvoidLink}
				>
					<FormattedMessage {...directionMessages.viaAvoidLink} />
				</Button>
			</div>
		</div>
	);

	const otherStationCode = journey.departStation && journey.departStation.code;

	const viaAvoidContent = (
		<div className="row">
			<div className="col-12 col-sm-4" onClick={onQuickSearchSwitch}>
				<TextField
					id="srtViaAvoidType"
					value={journey.viaAvoidType[direction]}
					label={<FormattedMessage {...directionMessages.selectViaAvoid} />}
					fullWidth
					onChange={({ target: { value } }) => {
						onChangeViaAvoidType(value, direction);
					}}
					disabled={disabled}
					styleName={quickSearch ? 'quickSearch' : ''}
					select
					variant="standard"
				>
					<MenuItem id="srtViaOption" value={1}>
						<FormattedMessage {...messages.lblVia} />
					</MenuItem>
					<MenuItem id="srtAvoidOption" value={2}>
						<FormattedMessage {...messages.lblAvoid} />
					</MenuItem>
				</TextField>
			</div>
			<div className="col-12 col-sm-6" onClick={onQuickSearchSwitch}>
				<StationAutoComplete
					id="srtViaAvoidStationAutoComplete"
					hintText={formatMessage(messages.lblStationSelect)}
					floatingLabelText={formatMessage(messages.lblStation)}
					station={journey.viaAvoidStation[direction]}
					clearStation={(searchText) => onClearStation(searchText, direction)}
					otherStationCode={otherStationCode}
					onNewRequest={(station) =>
						onChangeViaAvoidStation(station, direction)
					}
					disabled={viaAvoidDisabled || disabled}
					errorText={errors.viaAvoidCode}
					styleName={quickSearch ? 'quickSearch' : ''}
					includeTravelCards={false}
				/>
			</div>
			<div className="col-12 col-sm-2" styleName="viaAvoid-verticalAlign">
				{quickSearch || (
					<Button
						variant="contained"
						id="srtRemoveViaAvoid"
						fullWidth
						onClick={() => onViaAvoidToggle(direction)}
						disabled={disabled}
					>
						<FormattedMessage {...messages.lblViaAvoidRemove} />
					</Button>
				)}
			</div>
		</div>
	);

	return (
		!(!showViaAvoid && quickSearch) && (
			<div className="row">
				{!showViaAvoid ? (
					<div className="col-12">{viaAvoidLink}</div>
				) : (
					<div className="col-12">{viaAvoidContent}</div>
				)}
			</div>
		)
	);
}

ViaAvoid.propTypes = {
	id: PropTypes.string,
	disabled: PropTypes.bool,
	errors: PropTypes.object,
	intl: PropTypes.object,
	journey: PropTypes.object,
	onChangeViaAvoidStation: PropTypes.func,
	onChangeViaAvoidType: PropTypes.func,
	onViaAvoidToggle: PropTypes.func,
	onClearStation: PropTypes.func,
	showViaAvoid: PropTypes.bool,
	onQuickSearchSwitch: PropTypes.func,
	quickSearch: PropTypes.bool,
	direction: PropTypes.oneOf([
		viaAvoidDirection.INBOUND,
		viaAvoidDirection.OUTBOUND,
	]).isRequired,
};

export { ViaAvoid as ViaAvoidAlias };

export default injectIntl(ViaAvoid);
