const creditCardNumberTypes = {
	VI: {
		regex: /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/,
		length: { is: 16 },
	},
	CA: {
		regex:
			/^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}$/,
		length: { is: 16 },
	},
	DI: {
		regex: /^6(011|5\d{2})-?\d{4}-?\d{4}-?\d{4}$/,
		length: { is: 16 },
	},
	AX: {
		regex: /^3[4,7]\d{13}$/,
		length: { is: 15 },
	},
	SD: {
		regex: /^6334[5-9]\d{11}$|\d{13,15}$|^6767\d{12}$/,
		length: {
			minimum: 13,
			maximum: 16,
		},
	},
	MD: {
		regex:
			/(^(49030)[2-9](\d{10}$|\d{12,13}$)) |(^(49033)[5-9](\d{10}$|\d{12,13}$)) |(^(49110)[1-2](\d{10}$|\d{12,13}$)) |(^(49117)[4-9](\d{10}$|\d{12,13}$)) |(^(49118)[0-2](\d{10}$|\d{12,13}$)) |(^(4936)(\d{12}$|\d{14,15}$)) |(^(564182)(\d{11}$|\d{13,14}$)) |(^(6333)[0-4](\d{11}$|\d{13,14}$)) |(^(6759)(\d{12}$|\d{14,15}$))|(^(5[0678])\d{11,18}$) |(^(6[^0357])\d{11,18}$) |(^(601)[^1]\d{9,16}$) |(^(6011)\d{9,11}$) |(^(6011)\d{13,16}$) |(^(65)\d{11,13}$) |(^(65)\d{15,18}$) |(^(633)[^34](\d{9,16}$)) |(^(6333)[0-4](\d{8,10}$)) |(^(6333)[0-4](\d{12}$)) |(^(6333)[0-4](\d{15}$)) |(^(6333)[5-9](\d{8,10}$)) |(^(6333)[5-9](\d{12}$)) |(^(6333)[5-9](\d{15}$)) |(^(6334)[0-4](\d{8,10}$)) |(^(6334)[0-4](\d{12}$)) |(^(6334)[0-4](\d{15}$)) |(^(67)[^(59)](\d{9,16}$)) |(^(6759)](\d{9,11}$)) |(^(6759)](\d{13}$)) |(^(6759)](\d{16}$)) |(^(67)[^(67)](\d{9,16}$)) |(^(6767)](\d{9,11}$)) |(^(6767)](\d{13}$)) |(^(6767)](\d{16}$))/, // eslint-disable-line max-len
	},
	VD: {
		regex:
			/(^4\d{12}$)|(^4[0-8]\d{14}$)|(^(49)[^013]\d{13}$)|(^(49030)[0-1]\d{10}$)|(^(49033)[0-4]\d{10}$)|(^(49110)[^12]\d{10}$)|(^(49117)[0-3]\d{10}$)|(^(49118)[^0-2]\d{10}$)|(^(493)[^6]\d{12}$)/, // eslint-disable-line max-len
	},
	CD: {
		regex: /^[1-9]\d{6}$/,
		length: { is: 7 },
	},
	NONE: {
		regex: /^\d{16}$/,
		length: { is: 16 },
	},
	else: {
		regex: /^[1-9]\d{9,19}$/,
	},
};

export const creditCardNumberRegex = (type) => {
	const creditCardNumberType =
		creditCardNumberTypes[type] || creditCardNumberTypes.else;
	return creditCardNumberType.regex;
};

export const creditCardNumberLength = (type) => {
	const creditCardNumberType =
		creditCardNumberTypes[type] || creditCardNumberTypes.else;
	return creditCardNumberType.length;
};
