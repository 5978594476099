import { defineMessages } from 'react-intl';

export default defineMessages({
	dateFormat: {
		id: 'app.dateFormat',
		defaultMessage: 'mm/dd/yyyy',
	},
	timeFormat: {
		id: 'app.timeFormat',
		defaultMessage: 'HH:mm',
	},
});
