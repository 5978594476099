import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorBoundary } from 'react-error-boundary';

import { shouldDisableCustomerEmail } from '../../../utils';
import { checkCurrentTDO } from '../../utils';
import TableView from '../../../../components/TableView/TableView';
import TicketDeliveryDetails from '../../../../components/TicketDeliveryDetails/TicketDeliveryDetails';
import ValueDocumentCoupons from '../ValueDocumentCoupons/ValueDocumentCoupons';
import { gaEvent } from '../../../../utils/googleAnalytics';
import { orderStatuses } from '../../../../constants/constants';
import messages from './messages';
import orderDetailsMessages from '../OrderDetails/messages';
import ticketIcon from '../../../../images/ticket.svg';
import styles from './styles';
import ErrorFallBack from '../ErrorFallBack';

const StyledLink = styled('a')({
	...styles.linkTitle,
});

const WrapperDiv = styled('div')({
	...styles.wrapper,
});

const ButtonsGrid = styled(Grid)({
	marginLeft: 'unset !important',
	marginTop: 'unset !important',
	'.remove-padding-top': {
		paddingTop: 0,
		paddingLeft: 4,
	},
});

const TitleH2 = styled('h2')({
	...styles.title,
});

const SpinnerDiv = styled('div')({
	...styles.spinner,
});

const FulfillmentInformation = ({
	currTDO,
	handleOpenStationInfoDialog,
	order,
	disableAllowAnyCardCollectButton,
	showAmendCTR,
	onAmendCTR,
	bookingItem,
	tdoButtonLabel,
	amendCTRText,
	isProcessingAction,
	returnCoupons,
	authorizeRefund,
	disableCustomerEmailSending,
	ignoreDisableEmailSendingSuppliers,
	handleOpenReturnCouponsDialog,
	handleOpenAuthorizeRefundDialog,
	handleOpenResendTicketDialog,
	handleOpenTicketDelivery,
	handleAllowAnyCardCollect,
}) => {
	const {
		BookingOrderID: orderId,
		BookingOrderStatus,
		BookingOrderTDOData: { BookingSupplierCodes },
		BookingResponseValueDocumentUrl,
	} = order;
	const {
		BookingOrderPickUpStation: pickUpStation,
		BookingOrderPickUpStationName: pickUpStationName,
		BookingFulfillmentPaperEmail: paperEmail,
		BookingFulfillmentPhoneCountryCode: smsPhoneCode,
		BookingFulfillmentPhoneNumber: smsPhoneNumber,
	} = order.BookingOrderTDOData.BookingFulfillmentInfo;
	const [isTicketingDetailsExpanded, setIsTicketingDetailsExpanded] =
		useState(false);

	const disableCustomerEmail = shouldDisableCustomerEmail(
		disableCustomerEmailSending,
		ignoreDisableEmailSendingSuppliers,
		BookingSupplierCodes,
	);
	const currentTdo = checkCurrentTDO(order);

	const displayResendTicket =
		[orderStatuses.TICKETED, orderStatuses.CONFIRMED].includes(
			BookingOrderStatus,
		) &&
		currentTdo &&
		currentTdo.Code &&
		BookingResponseValueDocumentUrl &&
		!disableCustomerEmail &&
		!order.BookingOrderInventoryCanceled;

	const handleExpandTicketingDetails = () => {
		gaEvent(
			isTicketingDetailsExpanded
				? 'collapseTDOAdditionalDetails'
				: 'expandTDOAdditionalDetails',
		);
		setIsTicketingDetailsExpanded(!isTicketingDetailsExpanded);
	};

	const tdo = (
		<>
			<div key="description">
				{currTDO.Description}
				{currTDO.MarketingCarrierReferences && (
					<IconButton
						key="TicketingDetailsExpand"
						onClick={handleExpandTicketingDetails}
					>
						{isTicketingDetailsExpanded ? <ExpandLess /> : <ExpandMore />}
					</IconButton>
				)}
			</div>
			{smsPhoneCode && (
				<div key="smsPhone">
					<FormattedMessage
						{...messages.lblSMSPhone}
						values={{ smsPhone: `${smsPhoneCode} ${smsPhoneNumber}` }}
					/>
				</div>
			)}
			{paperEmail && (
				<div key="paperEmail">
					<FormattedMessage
						{...messages.lblPaperEmail}
						values={{ email: paperEmail }}
					/>
				</div>
			)}
			{pickUpStationName && (
				<div key="station">
					<b>
						<FormattedMessage {...messages.lblPickUpStation} />
					</b>{' '}
					<StyledLink
						onClick={() => {
							handleOpenStationInfoDialog(pickUpStation);
						}}
					>
						{pickUpStationName}
					</StyledLink>
				</div>
			)}
			{order.BookingOrderShippingName && (
				<div key="shippingName">
					<FormattedMessage
						{...messages.lblShippingName}
						values={{ name: order.BookingOrderShippingName }}
					/>
				</div>
			)}
			{order.BookingOrderShippingAddress && (
				<div key="shippingAddress">
					<FormattedMessage
						{...messages.lblShippingAddress}
						values={{
							address: order.BookingOrderShippingAddress.address1,
							city: order.BookingOrderShippingAddress.city,
							country: order.BookingOrderShippingAddress.country,
							zipCode: order.BookingOrderShippingAddress.zipCode,
						}}
					/>
				</div>
			)}
		</>
	);
	const data = [
		[
			{
				id: `ticketDeliveryOption_${order.BookingOrderID}`,
				value: tdo,
			},
			{
				id: `ticketLocatorID_${order.BookingOrderID}`,
				value: order.BookingValueDocLocator,
			},
			{
				id: `ticketLocatorStatus_${order.BookingOrderID}`,
				value:
					order.BookingValueDocStatus &&
					order.BookingValueDocStatus.toLowerCase(),
			},
			{
				id: `ticketIssuedDate_${order.BookingOrderID}`,
				value:
					order.BookingResponseValueDocumentIssuedDate &&
					order.BookingResponseValueDocumentIssuedDate,
			},
			{
				id: `retrievalInformation_${order.BookingOrderID}`,
				value: (
					<>
						<div key="customer">
							<FormattedMessage
								{...messages.lblRetrievalInformationCustomer}
								values={{ value: order.BookingOrderRetrievalCustomer }}
							/>
						</div>
						<div key="creditCard">
							<FormattedMessage
								{...messages.lblRetrievalInformationCreditCard}
								values={{ value: order.BookingOrderRetrievalCreditCard }}
							/>
						</div>
						<div key="code">
							<FormattedMessage
								{...messages.lblRetrievalInformationCode}
								values={{ value: order.BookingOrderRetrievalCode }}
							/>
						</div>
					</>
				),
			},
		],
	];

	const tableHeaders = [
		<FormattedMessage {...messages.lblTicketDeliveryOption} />,
		<FormattedMessage {...messages.lblTicketLocator} />,
		<FormattedMessage {...messages.lblTicketLocatorStatus} />,
		<FormattedMessage {...messages.lblIssuedDate} />,
		<FormattedMessage {...messages.lblRetrievalInformation} />,
	];

	if (showAmendCTR) {
		data[0].push({
			id: 'amendCTRIcon',
			value: (
				<Tooltip title={<FormattedMessage {...messages.lblAmendCTR} />}>
					<button
						id="amendCTRBtn"
						key="amendCTRBtn"
						style={styles.buttonStyle}
						type="button"
						disabled={isProcessingAction}
						onClick={() => onAmendCTR(order, bookingItem)}
					>
						<img key="amendCTRIcon" alt={amendCTRText} src={ticketIcon} />
					</button>
				</Tooltip>
			),
		});
		tableHeaders.push('');
	}

	const hasReleasableCoupons = order.BookingOrderCoupons?.some(
		(c) => c.BookingCouponsIsReleasable || c.isReleasable,
	);

	return (
		<WrapperDiv>
			<ButtonsGrid container spacing={8} alignItems="center">
				<TitleH2>
					<FormattedMessage {...messages.lblTitle} />
				</TitleH2>
				{displayResendTicket && (
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="remove-padding-top"
					>
						<Button
							variant="contained"
							id={`srtBookingResendTicket_${orderId}`}
							fullWidth
							onClick={() => {
								gaEvent('resendTicketOpen');
								handleOpenResendTicketDialog(orderId);
							}}
						>
							<FormattedMessage
								{...orderDetailsMessages[
									`btn${
										order.BookingOrderStatus !== orderStatuses.CONFIRMED ||
										order.BookingOrderTicketDelivery === 'EML'
											? 'Res'
											: 'S'
									}endTicket`
								]}
							/>
						</Button>
					</Grid>
				)}
				{tdoButtonLabel && (
					<Grid
						item
						xs={12}
						sm={6}
						md={4}
						lg={3}
						className="remove-padding-top"
					>
						<Button
							variant="contained"
							id={`srtBookingAddTicketDelivery_${orderId}`}
							color="primary"
							fullWidth
							onClick={() => {
								gaEvent(`${tdoButtonLabel.event}TDOOpen`);
								handleOpenTicketDelivery(order);
							}}
						>
							<FormattedMessage {...tdoButtonLabel.message} />
						</Button>
					</Grid>
				)}
				{order.IsAllowAnyCardCollect && (
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Button
							variant="contained"
							id={`srtAllowAnyCardCollect_${orderId}`}
							data-testid="srtAllowAnyCardCollect"
							color="primary"
							fullWidth
							disabled={disableAllowAnyCardCollectButton.get(orderId)}
							onClick={() => {
								handleAllowAnyCardCollect(orderId);
							}}
						>
							<FormattedMessage
								{...orderDetailsMessages.btnAllowAnyCardCollect}
							/>
						</Button>
					</Grid>
				)}
			</ButtonsGrid>
			<TableView
				id="fulfillmentInformation"
				tableHeaders={tableHeaders}
				data={data}
				showFilter={false}
				style={{ rowItemStyle: styles.rowItemStyle }}
				wrapperHeight="auto"
			/>
			{currTDO.MarketingCarrierReferences && (
				<Collapse in={isTicketingDetailsExpanded}>
					<TicketDeliveryDetails details={currTDO.MarketingCarrierReferences} />
				</Collapse>
			)}
			<ErrorBoundary
				fallbackRender={(props) => (
					<ErrorFallBack
						{...props}
						messageComponent={
							<FormattedMessage {...messages.lblValueDocumentCouponsTitle} />
						}
					/>
				)}
			>
				<ValueDocumentCoupons
					coupons={order.BookingOrderCoupons}
					orderId={orderId}
					returnCoupons={returnCoupons && hasReleasableCoupons}
					authorizeRefund={authorizeRefund}
					handleOpenReturnCouponsDialog={handleOpenReturnCouponsDialog}
					handleOpenAuthorizeRefundDialog={handleOpenAuthorizeRefundDialog}
				/>
			</ErrorBoundary>
			{isProcessingAction && (
				<SpinnerDiv>
					<CircularProgress />
				</SpinnerDiv>
			)}
		</WrapperDiv>
	);
};

FulfillmentInformation.propTypes = {
	currTDO: PropTypes.object.isRequired,
	handleOpenStationInfoDialog: PropTypes.func.isRequired,
	order: PropTypes.object.isRequired,
	disableAllowAnyCardCollectButton: PropTypes.object.isRequired,
	showAmendCTR: PropTypes.bool.isRequired,
	onAmendCTR: PropTypes.func.isRequired,
	bookingItem: PropTypes.object.isRequired,
	tdoButtonLabel: PropTypes.object,
	amendCTRText: PropTypes.string,
	isProcessingAction: PropTypes.bool.isRequired,
	returnCoupons: PropTypes.bool.isRequired,
	authorizeRefund: PropTypes.bool.isRequired,
	handleOpenReturnCouponsDialog: PropTypes.func.isRequired,
	handleOpenAuthorizeRefundDialog: PropTypes.func.isRequired,
	handleOpenResendTicketDialog: PropTypes.func.isRequired,
	handleOpenTicketDelivery: PropTypes.func.isRequired,
	handleAllowAnyCardCollect: PropTypes.func.isRequired,
	disableCustomerEmailSending: PropTypes.bool.isRequired,
	ignoreDisableEmailSendingSuppliers: PropTypes.array.isRequired,
};

export { FulfillmentInformation as FulfillmentInformationAlias };

export default FulfillmentInformation;
