import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import validate from 'validate.js';

import { getStationTODApi } from '../apiComponents';

import RefreshIndicator from '../RefreshIndicator/RefreshIndicator';
import TableView from '../TableView/TableView';

import messages from './messagesStationsTicket';
import ExtendedSnackbar from '../ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

class StationsTicketDialog extends Component {
	static propTypes = {
		intl: PropTypes.object,
		onClose: PropTypes.func,
		open: PropTypes.bool,
		suppliers: PropTypes.array,
		stationCode: PropTypes.string,
	};

	static defaultProps = {
		open: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			data: fromJS({
				stations: {},
				alertText: '',
				isFetching: false,
			}),
		};
	}

	// eslint-disable-next-line camelcase,react/sort-comp
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (!this.props.open && nextProps.open) {
			this.setState(
				(state) => ({ data: state.data.set('isFetching', true) }),
				() => {
					getStationTODApi(
						{
							suppliers: nextProps.suppliers.map(
								(supplier) => supplier.BookingSupplierCode,
							),
							stationCode: nextProps.stationCode,
						},
						() => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									alertText: nextProps.intl.formatMessage(
										messages.errStationsTicketErrorResponse,
									),
								}),
							}));
						},
						(response) => {
							this.setState((state) => ({
								data: state.data.merge({
									isFetching: false,
									stations: response.successResponse.data,
									alertText: response.successResponse.data
										? ''
										: nextProps.intl.formatMessage(
												messages.errStationsTicketNoData,
											),
								}),
							}));
						},
					);
				},
			);
		}
	};

	tableHeaders = () => {
		const {
			intl: { formatMessage },
		} = this.props;
		return [
			formatMessage(messages.lblStationsTicketName),
			formatMessage(messages.lblStationsTicketOffice),
			formatMessage(messages.lblStationsTicketVendingMachine),
		];
	};

	transformStationData = (data) => {
		const {
			intl: { formatMessage },
		} = this.props;
		const tableData = [];
		if (data.getIn(['stations', 'StationSearchResultTODStations'])) {
			const yesLabel = formatMessage(messages.lblStationsTicketYes);
			const noLabel = formatMessage(messages.lblStationsTicketNo);
			data.getIn(['stations', 'StationSearchResultTODStations']).map((item) =>
				tableData.push([
					{ value: item.get('StationSearchResultName') },
					{
						value: item.get('StationSearchResultTypeHasTOF')
							? yesLabel
							: noLabel,
					},
					{
						value: item.get('StationSearchResultTypeHasTVM')
							? yesLabel
							: noLabel,
					},
				]),
			);
		}
		return tableData;
	};

	handleSnackBarClose = () => {
		this.setState((state) => ({ data: state.data.set('alertText', '') }));
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;
		const { data } = this.state;
		const hasData =
			!data.get('isFetching') && !validate.isEmpty(data.get('stations'));
		const tableData = this.transformStationData(data);

		const actions = (
			<Button
				id="srStationsTicketClose"
				onClick={this.props.onClose}
				variant="contained"
			>
				{formatMessage(messages.btnStationsTicketClose)}
			</Button>
		);

		return (
			<div>
				<StyledDialog
					open={this.props.open}
					onClose={this.props.onClose}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>
						{formatMessage(messages.lblStationsTicketTitle)}
					</DialogTitle>
					<DialogContent>
						<div className="container-fluid">
							<TableView
								id="srtStationsTicketTable"
								tableHeaders={this.tableHeaders()}
								data={hasData ? tableData : []}
								fixedHeader
								isSortable
								wrapperHeight="400px"
							/>
							<RefreshIndicator
								size={36}
								top={0}
								left={0}
								status={data.get('isFetching') ? 'loading' : 'hide'}
								style={inlineStyles.refresh}
							/>
						</div>
					</DialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtStationsTicketSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackBarClose}
				/>
			</div>
		);
	}
}

export default injectIntl(StationsTicketDialog);
