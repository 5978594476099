import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ReactSelectWrapped from '../ReactSelectWrapped/ReactSelectWrapped';

import messages from './messagesAddress';

class Address extends PureComponent {
	static propTypes = {
		values: PropTypes.shape({
			address1: PropTypes.string.isRequired,
			address2: PropTypes.string.isRequired,
			city: PropTypes.string.isRequired,
			postalCode: PropTypes.string.isRequired,
			countryCode: PropTypes.string,
			stateOrProvince: PropTypes.string.isRequired,
			addressType: PropTypes.string,
		}).isRequired,
		countries: PropTypes.object.isRequired,
		states: PropTypes.object.isRequired,
		provinces: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		addressTypes: PropTypes.object,
		errors: PropTypes.object,
		useDefaults: PropTypes.bool,
		idPrefix: PropTypes.string,
		intl: PropTypes.object,
		index: PropTypes.number,
		disabled: PropTypes.bool,
		dataTestId: PropTypes.string,
	};

	static defaultProps = {
		useDefaults: false,
		index: 0,
	};

	handleChange = (name, value) => {
		const updates = { [name]: value };
		if (name === 'countryCode') {
			updates.stateOrProvince = '';
		} else if (name === 'postalCode') {
			updates.postalCode = updates.postalCode.toUpperCase();
		}
		this.props.onChange(updates);
	};

	handleChangeField = (event) => {
		this.handleChange(event.target.name, event.target.value);
	};

	handleChangeCountry = ({ value }) => {
		this.handleChange('countryCode', value);
	};

	handleChangeState = ({ value }) => {
		this.handleChange('stateOrProvince', value);
	};

	handleChangeAddressType = ({ target: { value } }) => {
		this.handleChange('addressType', value);
	};

	render() {
		const address = this.props.values;
		let addressTypeDiv = false;
		const {
			addressTypes,
			states,
			provinces,
			countries,
			disabled,
			intl: { formatMessage },
		} = this.props;
		let countriesList = countries;

		if (this.props.useDefaults) {
			countriesList = fromJS([
				{ code: null, country: formatMessage(messages.lblCountrySelect) },
			]).concat(countries);
		}

		if (addressTypes) {
			const addressTypesKeys = addressTypes.keySeq();
			addressTypeDiv = (
				<TextField
					id={`${this.props.idPrefix}AddressType_${this.props.index}`}
					label={formatMessage(messages.lblAddressType)}
					placeholder={formatMessage(messages.lblAddressTypeSelect)}
					fullWidth
					onChange={this.handleChangeAddressType}
					error={!!this.props.errors.addressType}
					helperText={this.props.errors.addressType}
					value={address.addressType}
					disabled={disabled}
					select
					variant="standard"
				>
					{addressTypesKeys.map((item, index) => (
						<MenuItem id={`srtAddressTypes_${index}`} key={index} value={item}>
							{addressTypes.get(item)}
						</MenuItem>
					))}
				</TextField>
			);
		}

		return (
			<div className="row" data-testid={this.props.dataTestId}>
				<div className="col-12">
					<ReactSelectWrapped
						id={`${this.props.idPrefix}Country_${this.props.index}`}
						label={formatMessage(messages.lblCountry)}
						InputLabelProps={{
							shrink: true,
						}}
						fullWidth
						onChange={this.handleChangeCountry}
						error={!!this.props.errors.countryCode}
						helperText={this.props.errors.countryCode}
						value={address.countryCode}
						disabled={disabled}
						options={countriesList.toJS().map((country) => ({
							value: country.code,
							label: country.country,
						}))}
						hasEmpty
						windowing
					/>
					{address.countryCode === 'US' && (
						<ReactSelectWrapped
							id={`${this.props.idPrefix}CountryState_${this.props.index}`}
							label={formatMessage(messages.lblState)}
							placeholder={formatMessage(messages.lblStateSelect)}
							fullWidth
							onChange={this.handleChangeState}
							error={!!this.props.errors.stateOrProvince}
							helperText={this.props.errors.stateOrProvince}
							value={address.stateOrProvince}
							disabled={disabled}
							options={states.toJS().map((item) => ({
								value: item.code,
								label: item.country,
							}))}
							windowing
						/>
					)}
					{address.countryCode === 'CA' && (
						<ReactSelectWrapped
							id={`${this.props.idPrefix}Province_${this.props.index}`}
							label={formatMessage(messages.lblProvince)}
							placeholder={formatMessage(messages.lblProvinceSelect)}
							fullWidth
							onChange={this.handleChangeState}
							error={!!this.props.errors.stateOrProvince}
							helperText={this.props.errors.stateOrProvince}
							value={address.stateOrProvince}
							disabled={disabled}
							options={provinces.toJS().map((item) => ({
								value: item.code,
								label: item.country,
							}))}
							windowing
						/>
					)}
					<TextField
						id={`${this.props.idPrefix}Address1_${this.props.index}`}
						name="address1"
						label={formatMessage(messages.lblAddressLine1)}
						fullWidth
						onChange={this.handleChangeField}
						error={!!this.props.errors.address1}
						helperText={this.props.errors.address1}
						value={address.address1}
						disabled={disabled}
						variant="standard"
					/>
					<TextField
						id={`${this.props.idPrefix}Address2_${this.props.index}`}
						name="address2"
						label={formatMessage(messages.lblAddressLine2)}
						fullWidth
						onChange={this.handleChangeField}
						error={!!this.props.errors.address2}
						helperText={this.props.errors.address2}
						value={address.address2}
						disabled={disabled}
						variant="standard"
					/>
					<TextField
						id={`${this.props.idPrefix}City_${this.props.index}`}
						name="city"
						label={formatMessage(messages.lblCity)}
						fullWidth
						onChange={this.handleChangeField}
						error={!!this.props.errors.city}
						helperText={this.props.errors.city}
						value={address.city}
						disabled={disabled}
						variant="standard"
					/>
					<TextField
						id={`${this.props.idPrefix}PostalCode_${this.props.index}`}
						name="postalCode"
						label={formatMessage(messages.lblPostalCode)}
						fullWidth
						onChange={this.handleChangeField}
						error={!!this.props.errors.postalCode}
						helperText={this.props.errors.postalCode}
						value={address.postalCode}
						disabled={disabled}
						variant="standard"
					/>
					{addressTypeDiv}
				</div>
			</div>
		);
	}
}

// This alias will be used to access bare component for unit testing
export { Address as AddressAlias };

export default injectIntl(Address);
