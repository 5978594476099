import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fare from './Fare';
import FareRulesMatrix from './FareRulesMatrix';
import messages from './messagesFareLeg';

import inlineStyles from './fareLegStyles';
import { gaEvent } from '../../../utils/googleAnalytics';

class FareLegsContainer extends PureComponent {
	static propTypes = {
		isReturnType: PropTypes.bool,
		children: PropTypes.node,
		intl: PropTypes.object,
		addOrderMode: PropTypes.bool,
		exchangeOrderMode: PropTypes.bool,
		shoppingResultsMode: PropTypes.bool,
		legType: PropTypes.string,
		onChangeSelection: PropTypes.func,
		isCreateBookingPage: PropTypes.bool,
	};

	state = {
		openFareRulesMatrix: false,
	};

	handleCloseFareRulesMatrix = () => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderCloseFareRules');
		else if (exchangeOrderMode) gaEvent('exchangeOrderCloseFareRules');
		else gaEvent('createBookingCloseFareRules');

		this.setState({ openFareRulesMatrix: false });
	};

	handleOpenFareRulesMatrix = () => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderViewFareRules');
		else if (exchangeOrderMode) gaEvent('exchangeOrderViewFareRules');
		else gaEvent('createBookingViewFareRules');

		this.setState({ openFareRulesMatrix: true });
	};

	render() {
		const {
			isReturnType,
			shoppingResultsMode,
			legType,
			children,
			addOrderMode,
			exchangeOrderMode,
			intl: { formatMessage },
			onChangeSelection,
			isCreateBookingPage,
		} = this.props;
		const {
			props: {
				booking,
				selectedFare,
				selectedFares,
				outboundMsg,
				inboundMsg,
				fareIndex,
				legId,
			},
		} = children;
		const legTitle = shoppingResultsMode ? (
			legType
		) : (
			<FormattedMessage
				{...messages.lblFareRules}
				values={{ legType: fareIndex === 0 ? outboundMsg : inboundMsg }}
			/>
		);
		const fare = shoppingResultsMode ? null : selectedFare || selectedFares[0];
		const showButton = shoppingResultsMode
			? false
			: booking &&
				fare.fare.fareRulesDetails &&
				fare.fare.fareRulesDetails.length > 0;
		let renderChildren = children;
		const marketingCarrier = fare
			? {
					name: fare.leg.ShoppingLegOrigStationSuppliers,
					supplier: fare.leg.ShoppingLegSolutionCarrierSupplyChannelCode || '',
				}
			: null;
		if (isReturnType) {
			renderChildren = (
				<Paper
					className="col-12"
					style={isReturnType ? inlineStyles.returnJourney : {}}
					elevation={1}
				>
					{renderChildren}
					<div style={inlineStyles.returnFareCard}>
						<Fare
							key={`fare_${fareIndex}`}
							fare={fare.fare}
							marketingCarrier={marketingCarrier}
							onChangeSelection={onChangeSelection}
							onSelection={() => {}}
							onOpenFareDetails={() => {}}
							selected
							hideChangeSelection
							isCreateBookingPage={isCreateBookingPage}
						/>
					</div>
				</Paper>
			);
		}

		return (
			<div
				className="row"
				id={isReturnType ? 'Legs_return_fare' : `Leg_${legId}_head`}
			>
				<Paper
					className="col-12"
					style={booking ? inlineStyles.legTypeBooking : inlineStyles.legType}
					elevation={1}
				>
					<div className="row">
						<div className="col-6">
							{isReturnType ? (
								<FormattedMessage {...messages.lblJourney} />
							) : (
								legTitle
							)}
						</div>
						<div className="col-6 text-right">
							{showButton && (
								<Button
									variant="text"
									style={inlineStyles.fareRulesButton}
									onClick={this.handleOpenFareRulesMatrix}
								>
									{isReturnType ? (
										<FormattedMessage
											{...messages.lblFareRules}
											values={{ legType: '' }}
										/>
									) : (
										legTitle
									)}
								</Button>
							)}
						</div>
					</div>
				</Paper>
				{renderChildren}
				{fare && (
					<FareRulesMatrix
						open={this.state.openFareRulesMatrix}
						handleClose={this.handleCloseFareRulesMatrix}
						fareRulesDetails={fare?.fare?.fareRulesDetails}
						paxTitle={formatMessage(messages.lblPassenger)}
						addOrderMode={addOrderMode}
						exchangeOrderMode={exchangeOrderMode}
						isCreateBookingPage={isCreateBookingPage}
					/>
				)}
			</div>
		);
	}
}

// This alias will be used to access bare component for unit testing
export { FareLegsContainer as FareLegsContainerAlias };

export default injectIntl(FareLegsContainer);
