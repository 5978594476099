import { defineMessages } from 'react-intl';

export default defineMessages({
	lblNote: {
		id: 'app.shopping.createBooking.reservations.note',
		defaultMessage:
			'NOTE: Individual carriers consider seat preferences as requests and as such, are not guaranteed.',
	},
	lblTitle: {
		id: 'app.shopping.createBooking.reservations.title',
		defaultMessage: 'Seat Reservations',
	},
	lblBikeTitle: {
		id: 'app.shopping.createBooking.reservations.bikeTitle',
		defaultMessage: 'Bike Reservations',
	},
	lblRequestSeatsAll: {
		id: 'app.shopping.createBooking.reservations.requestSeatsAll',
		defaultMessage: 'Request seat(s)?',
	},
	lblCommingSoon: {
		id: 'app.shopping.createBooking.reservations.commingSoon',
		defaultMessage: 'Coming soon!',
	},
	lblSeatSelector: {
		id: 'app.shopping.createBooking.reservations.seatSelector',
		defaultMessage: 'The ability to select seats using our Seat Selector',
	},
	lblSpecificSeat: {
		id: 'app.shopping.createBooking.reservations.specificSeat',
		defaultMessage: 'Select a specific seat',
	},
	lblSeatAttributes: {
		id: 'app.shopping.createBooking.reservations.seatAttributes',
		defaultMessage: 'Select seat attributes',
	},
	btnClearAll: {
		id: 'app.shopping.createBooking.reservations.clearAll',
		defaultMessage: 'Clear All Selections',
	},
	btnDiscard: {
		id: 'app.shopping.createBooking.reservations.discard',
		defaultMessage: 'Discard',
	},
	btnReqestSeats: {
		id: 'app.shopping.createBooking.reservations.requestSeats',
		defaultMessage: 'Request Seats',
	},
	btnReqestBikes: {
		id: 'app.shopping.createBooking.bikeReservations.requestBikes',
		defaultMessage: 'Request Bikes',
	},
	lblLeg: {
		id: 'app.shopping.createBooking.reservations.leg',
		defaultMessage: 'Leg',
	},
	lblPassenger: {
		id: 'app.shopping.createBooking.reservations.passenger',
		defaultMessage: 'Passenger',
	},
	lblSeat: {
		id: 'app.shopping.createBooking.reservations.seat',
		defaultMessage: 'Seat',
	},
	lblCoach: {
		id: 'app.shopping.createBooking.reservations.coach',
		defaultMessage: 'Coach',
	},
	lblTo: {
		id: 'app.shopping.createBooking.reservations.to',
		defaultMessage: 'to',
	},
	lblPrefNotSpec: {
		id: 'app.shopping.createBooking.reservations.prefNotSpec',
		defaultMessage: 'Preferences may not be specified',
	},
	lblBikeNotSpec: {
		id: 'app.shopping.createBooking.reservations.bikeNotSpec',
		defaultMessage:
			'Bike reservation is not available for this part of the journey.',
	},
	lblIncluded: {
		id: 'app.shopping.createBooking.reservations.included',
		defaultMessage: 'Seat reservation included',
	},
	lblChoosePref: {
		id: 'app.shopping.createBooking.reservations.choosePref',
		defaultMessage: 'Choose your preference(s)',
	},
	lblSelectCoach: {
		id: 'app.shopping.createBooking.reservations.selectCoach',
		defaultMessage: 'Select coach',
	},
	lblSelectLevel: {
		id: 'app.shopping.createBooking.reservations.selectLevel',
		defaultMessage: 'Select level',
	},
	lblSelectLayout: {
		id: 'app.shopping.createBooking.reservations.selectLayout',
		defaultMessage: 'Select layout',
	},
	lblSelectPosition: {
		id: 'app.shopping.createBooking.reservations.selectPosition',
		defaultMessage: 'Select position',
	},
	lblSelectDirection: {
		id: 'app.shopping.createBooking.reservations.selectDirection',
		defaultMessage: 'Select direction',
	},
	lblRequestSeats: {
		id: 'app.shopping.createBooking.reservations.requestSeats',
		defaultMessage: 'Request Seats',
	},
	lblRequestBike: {
		id: 'app.shopping.createBooking.bikeReservations.requestBike',
		defaultMessage: 'Request bike reservation',
	},
	lblPassengerChild: {
		id: 'app.shopping.createBooking.reservations.passengerChild',
		defaultMessage: 'Child',
	},
	lblPassengerYouth: {
		id: 'app.shopping.createBooking.reservations.passengerYouth',
		defaultMessage: 'Youth',
	},
	lblPassengerAdult: {
		id: 'app.shopping.createBooking.reservations.passengerAdult',
		defaultMessage: 'Adult',
	},
	lblPassengerSenior: {
		id: 'app.shopping.createBooking.reservations.passengerSenior',
		defaultMessage: 'Senior',
	},
	lblOverbooked: {
		id: 'app.shopping.createBooking.reservations.overbooked',
		defaultMessage: 'overbooked',
	},
	lblNearby: {
		id: 'app.shopping.createBooking.reservations.nearby',
		defaultMessage: 'Nearby',
	},
	lblSeatSelectorText: {
		id: 'app.shopping.createBooking.reservations.seatSelectorText',
		defaultMessage: 'Seat Selector',
	},
	lblSeatSelectorLink: {
		id: 'app.shopping.createBooking.reservations.seatSelectorLink',
		defaultMessage: 'Use {SeatSelectorLink} or manually enter a coach and seat',
	},
});
