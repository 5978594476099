import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import {
	usePopupState,
	bindTrigger,
	bindPopover,
} from 'material-ui-popup-state/hooks';
import GetHelpTable from './GetHelpTable';
import messages from './messagesBookingSearch';
import { gaEvent } from '../../../../utils/googleAnalytics';

const StyledTypography = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(2),
}));

const BookingSearchNeedHelp = () => {
	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'needHelpPopover',
	});

	return (
		<div>
			<Button
				variant="contained"
				id="srtBookingSearchHelpBtn"
				onClick={popupState.isOpen ? gaEvent('bookingSearchLocatorsHelp') : ''}
				{...bindTrigger(popupState)}
				sx={{
					backgroundColor: '#e0e0e0',
					color: '#333',
					'&:hover': {
						backgroundColor: '#e0e0e0',
					},
					fontWeight: 500,
				}}
			>
				<FormattedMessage {...messages.btnNeedHelp} />
			</Button>
			<Popover
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<StyledTypography component="span" variant="body1">
					<GetHelpTable
						headerLabel={messages.lblBookingLocator}
						helpText={messages.lblAllMarkets}
					/>
					<GetHelpTable
						headerLabel={messages.lblSupplierLocator}
						helpText={messages.lblAllMarkets}
					/>
					<GetHelpTable
						headerLabel={messages.lblCarrierLocator}
						helpText={messages.lblFrenchMarket}
					/>
					<GetHelpTable
						headerLabel={messages.lblTicketLocator}
						helpText={messages.lblAllMarkets}
					/>
					<GetHelpTable
						headerLabel={messages.lblUniqueTicketNumber}
						helpText={messages.lblHelpUTNToolText}
					/>
				</StyledTypography>
			</Popover>
		</div>
	);
};

export default BookingSearchNeedHelp;
