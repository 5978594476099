import { defineMessages } from 'react-intl';

export default defineMessages({
	lblTitle: {
		id: 'app.login.forgotPassword.title',
		defaultMessage: 'Forgot Your Password',
	},
	lblDescription: {
		id: 'app.login.forgotPassword.description',
		defaultMessage:
			'Enter your username below and a reset password link with instructions will be sent to your email address on file.',
	},
	lblUsername: {
		id: 'app.login.forgotPassword.username',
		defaultMessage: 'Username',
	},
	btnClose: {
		id: 'app.login.forgotPassword.close',
		defaultMessage: 'Close',
	},
	btnSend: {
		id: 'app.login.forgotPassword.send',
		defaultMessage: 'Send Instructions',
	},
	errUsernameEmpty: {
		id: 'app.login.forgotPassword.usernameEmpty',
		defaultMessage: '^Please enter your username.',
	},
	okSendLink: {
		id: 'app.login.forgotPassword.okSendLink',
		// eslint-disable-next-line max-len
		defaultMessage:
			'A reset password link has been generated and will be sent to you via email. Please follow the instructions to change your password.',
	},
});
