import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import RefreshIndicator from '../../../components/RefreshIndicator/RefreshIndicator';
import { fetchBookingDetails } from '../../ManageBooking/actionsManageBooking';
import { submitPassengerInformationApi } from '../../apiBooking';
import { gaEvent } from '../../../utils/googleAnalytics';
import PassengerInformationView from './PassengerInformationView';
import messages from './messagesPassengerInformation';
import ExtendedSnackbar from '../../../components/ExtendedSnackbar/ExtendedSnackbar';
import inlineStyles from './styles';

const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		...inlineStyles.dialogPaper,
	},
}));

const StyledDialogContent = styled(DialogContent)({
	...inlineStyles.dialogContent,
});
class PassengerInformationDialog extends Component {
	static propTypes = {
		booking: PropTypes.object.isRequired,
		countries: PropTypes.object,
		handleDialogSnackbarError: PropTypes.func.isRequired,
		id: PropTypes.string,
		intl: PropTypes.object,
		onClose: PropTypes.func.isRequired,
		onSaveSuccess: PropTypes.func,
		passengers: PropTypes.array.isRequired,
		selectedPax: PropTypes.number,
	};

	static defaultProps = {
		id: 'srtPassengerInformation',
	};

	constructor(props) {
		super(props);
		this.state = {
			passengers: fromJS(
				props.passengers.map((passenger) => {
					const fixedPax = passenger;
					fixedPax.BookingPaxContactAddressType =
						passenger.BookingPaxContactAddressType || 'HOME';
					return fixedPax;
				}),
			),
			updateable: props.booking.BookingPassengerInformationUpdateable || {},
			updating: false,
			processingSubmit: false,
			formErrors: [],
			invalid: false,
			data: fromJS({
				alertText: '',
			}),
		};
	}

	onSubmitError = () => {
		const {
			intl: { formatMessage },
			handleDialogSnackbarError,
		} = this.props;
		handleDialogSnackbarError(
			formatMessage(messages.lblPaxInformationUpdateError),
		);
		this.setState({ processingSubmit: false });
	};

	onSubmitSuccess = () => {
		const {
			intl: { formatMessage },
			handleDialogSnackbarError,
			booking: { queryItems },
			onSaveSuccess,
			onClose,
		} = this.props;

		handleDialogSnackbarError(
			formatMessage(messages.lblPaxInformationUpdateSuccessAndNoteLogSuccess),
		);

		onSaveSuccess(queryItems);

		this.setState({
			updating: false,
			processingSubmit: false,
		});

		onClose();
	};

	onCloseClick = () => {
		const { onClose } = this.props;
		const { updating } = this.state;
		this.setState({
			updating: false,
			passengers: fromJS(this.props.passengers),
		});
		onClose(updating);
	};

	onFetchingBookingsError = (error) => {
		this.setState((state) => ({
			data: state.data.merge({
				alertText: error.message,
			}),
		}));
	};

	handleChange = (path, value) => {
		const { updating } = this.state;
		if (!updating) gaEvent('updatePassengerInformationInitiated');
		this.setState((state) => ({
			updating: true,
			passengers: state.passengers.setIn([...path], value),
			invalid: false,
		}));
	};

	handleSubmitChanges = () => {
		const {
			booking: { queryItems },
			intl: { formatMessage },
			passengers: propsPassengers,
		} = this.props;
		const { passengers } = this.state;

		validate.validators.array = (arrayItems, itemConstraints) => {
			const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
				const itemErrors = [...errors];
				const error = validate(item, itemConstraints);
				if (error) {
					itemErrors[index] = error;
				}
				return itemErrors;
			}, []);

			return validate.isEmpty(arrayItemErrors)
				? null
				: { errors: arrayItemErrors };
		};

		const emptyErrorMessage = formatMessage(
			messages.lblPaxInformationFieldEmptyError,
		);
		const paxGeneralConstraints = {
			BookingResponsePassengerTravelDocuments: {
				array: {
					DocumentNumber: {
						presence: { message: emptyErrorMessage, allowEmpty: false },
					},
					ExpirationDate: {
						presence: { message: emptyErrorMessage, allowEmpty: false },
						datetime: {
							earliest: new Date().setDate(new Date().getDate() + 2),
							message: formatMessage(
								messages.lblTravelDocExpirationDateInvalid,
							),
						},
					},
				},
			},
			BookingPaxLoyaltyCards: {
				array: {
					BookingLoyaltyValue: {
						presence: { message: emptyErrorMessage, allowEmpty: false },
					},
				},
			},
		};

		const paxAddressConstraints = {
			BookingPaxContactAddress1: {
				presence: { message: emptyErrorMessage, allowEmpty: false },
			},
			BookingPaxContactAddressCity: {
				presence: { message: emptyErrorMessage, allowEmpty: false },
			},
			BookingPaxContactAddressCountry: {
				presence: { message: emptyErrorMessage, allowEmpty: false },
			},
		};

		const paxErrors = passengers.toJS().map((passenger, index) => {
			let paxConstraints = paxGeneralConstraints;
			if (
				propsPassengers[index].BookingPaxContactAddress1 ||
				passenger.BookingPaxContactAddress1 ||
				propsPassengers[index].BookingPaxContactAddressCity ||
				passenger.BookingPaxContactAddressCity ||
				propsPassengers[index].BookingPaxContactAddressCountry ||
				passenger.BookingPaxContactAddressCountry
			) {
				paxConstraints = {
					...paxConstraints,
					...paxAddressConstraints,
				};
			}

			return validate(passenger, paxConstraints);
		});
		if (paxErrors.some((value) => value !== undefined)) {
			this.setState({
				formErrors: paxErrors,
				processingSubmit: false,
				invalid: true,
			});
		} else {
			this.setState({
				formErrors: [],
				processingSubmit: true,
			});

			submitPassengerInformationApi(
				{
					queryItems,
					passengers: passengers.toJS(),
				},
				this.onSubmitError,
				this.onSubmitSuccess,
			);
		}
	};

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	render() {
		const {
			booking,
			onClose,
			selectedPax,
			countries,
			passengers: propsPassengers,
		} = this.props;
		const {
			passengers,
			updateable,
			updating,
			processingSubmit,
			formErrors,
			invalid,
			data,
		} = this.state;
		const {
			LoyaltyCard,
			PassengerAddress,
			PassengerContactInfo,
			PassengerIdentity,
			TravelDocument,
		} = updateable;
		const editable =
			LoyaltyCard ||
			PassengerAddress ||
			PassengerContactInfo ||
			PassengerIdentity ||
			TravelDocument;
		const passengerInformationViewDisabled = booking.BookingOrders.some(
			(order) => order.BookingOrderStatus !== 'BOOKED',
		);

		const actions = (
			<div className="row" style={inlineStyles.dialogActionsRoot}>
				<div
					className={`col-4 ${editable ? 'offset-2 offset-md-4' : 'offset-4 offset-md-8'}`}
				>
					<Button
						variant="contained"
						id="srtPaxViewClose"
						onClick={this.onCloseClick}
						fullWidth
					>
						<FormattedMessage {...messages.btnClose} />
					</Button>
				</div>
				{editable && (
					<div className="col-4">
						{processingSubmit ? (
							<RefreshIndicator size={36} left={40} top={0} status="loading" />
						) : (
							<Button
								variant="contained"
								id="srtPaxViewSubmit"
								onClick={this.handleSubmitChanges}
								color="primary"
								fullWidth
								disabled={
									!updating || invalid || passengerInformationViewDisabled
								}
							>
								<FormattedMessage {...messages.btnSave} />
							</Button>
						)}
					</div>
				)}
			</div>
		);

		return (
			<>
				<StyledDialog
					open={selectedPax !== -1}
					onClose={onClose}
					disableEnforceFocus
					maxWidth={false}
				>
					<DialogTitle>
						<FormattedMessage {...messages.lblTitle} />
					</DialogTitle>
					<StyledDialogContent>
						<PassengerInformationView
							passengers={passengers.toJS()}
							selectedPax={selectedPax}
							onChange={this.handleChange}
							onFetchingBookingsError={this.onFetchingBookingsError}
							updateable={updateable}
							countries={countries}
							errors={formErrors}
							initialPassengers={propsPassengers}
							disabled={passengerInformationViewDisabled}
						/>
					</StyledDialogContent>
					<DialogActions>{actions}</DialogActions>
				</StyledDialog>
				<ExtendedSnackbar
					id="srtPassengerInformationSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText')}
					onClose={this.handleSnackbarClose}
				/>
			</>
		);
	}
}

const mapsStateToProps = (state) => ({
	countries: state.getIn(['settings', 'BillingCountries']),
});
const mapDispatchToProps = {
	onSaveSuccess: fetchBookingDetails,
};

export { PassengerInformationDialog as PassengerInformationDialogAlias };
export default connect(
	mapsStateToProps,
	mapDispatchToProps,
)(injectIntl(PassengerInformationDialog));
