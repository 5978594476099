import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

import { red, darkRed, lightRed } from '../../colors';

// eslint-disable-next-line no-unused-vars
const StyledPaper = styled(Paper)(({ theme, severity }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: '6px 16px',
	margin: '10px 0',
	...(severity === 'error' && {
		backgroundColor: lightRed,
		color: darkRed,
	}),
}));

const StyledIcon = styled('span')(({ severity }) => ({
	marginRight: 10,
	...(severity === 'error' && {
		color: red,
	}),
}));

function Alert({ children, severity = 'error' }) {
	const IconComponent = severity === 'error' ? ErrorIcon : null;

	return (
		<StyledPaper elevation={0} severity={severity}>
			{IconComponent && (
				<StyledIcon severity={severity}>
					<IconComponent />
				</StyledIcon>
			)}
			{children}
		</StyledPaper>
	);
}

Alert.propTypes = {
	children: PropTypes.node.isRequired,
	severity: PropTypes.oneOf(['error']),
};

export { Alert as AlertAlias };
export default Alert;
