import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromJS, is } from 'immutable';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import validate from 'validate.js';
import get from 'lodash/get';
import { notAutoSelectableTdos, TDOS } from '../../constants/constants';

import RefreshIndicator from '../../components/RefreshIndicator/RefreshIndicator';
import {
	clearFaresSelected,
	setChangeJourney,
} from '../ShoppingResults/actionsShoppingResults';
import {
	newBookingRequest,
	getSeatMapData,
	setExchangeValidate,
} from '../../Booking/ManageBooking/actionsManageBooking';
import {
	bookingValidateApi,
	tdoSmsCallingCodesApi,
	bookingCreateApi,
	bookingSetTdoApi,
	bookingAddOrderApi,
	exchangeSummaryApi,
	updateBookingCustomInfo,
} from '../apiShopping';
import messages from './messagesCreateBooking';

import SubNavbar from '../../components/SubNavbar/SubNavbar';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import TicketDeliveryDialog from '../../components/TicketDelivery/TicketDeliveryDialog';
import TicketDelivery from './components/TicketDelivery/TicketDelivery';
import Passenger from './components/Passenger/Passenger';
import BookingOptions from './components/BookingOptions/BookingOptions';
import CustomFields from './components/CustomFields/CustomFields';

import {
	setDiscountsLoyalties,
	extractPlusBusSupplements,
	extractTravelCards,
	groupOnBoardServicesBySegment,
	extractOnBoardServices,
	computeRequiredPassengerFields,
	updateTdoObj,
	validatePassengerField,
	transformSuppliers,
} from './utils';
import {
	extractOrderDetails,
	checkAccommodations,
	consolidateReservable,
	determineTripType,
	ADULT_PAX_AGE,
} from '../util';
import {
	findTDOSMSBookingCode,
	isTDOSmartCard,
	isTDOMail,
} from '../../components/TicketDelivery/utils';
import { getBasePath } from '../../utils';
import { gaEvent } from '../../utils/googleAnalytics';

import './createBooking.css';
import inlineStyles from './styles';
import constraintsObjects from './constraintsCreateBooking';
import ExtendedSnackbar from '../../components/ExtendedSnackbar/ExtendedSnackbar';
import ExchangeOrderNotification from '../../Booking/components/ExchangeOrderDialog/ExchangeOrderNotification';

validate.validators.containsValidCharacters = (value, options) => {
	if (/[^a-zA-z0-9\u2010\2012\u2013\u2014_\\.@ :-]+/.test(value)) {
		return options.message;
	}
	return null;
};

const defaultTdo = {
	tdoCode: '',
	tdoDesc: '',
	tdoFee: 0.0,
	tdoCurrency: '',
	tdoSmsCountry: null,
	tdoSmsNumber: '',
	tdoRegion: '',
	tdoMailName: '',
	tdoMailAddress: {
		countryCode: null,
		address1: '',
		address2: '',
		city: '',
		stateOrProvince: '',
		postalCode: '',
	},
	tdoSmartCards: [],
	tdoPickUpLocation: '',
};
class CreateBooking extends PureComponent {
	static propTypes = {
		intl: PropTypes.object,
		countries: PropTypes.object,
		states: PropTypes.object,
		canadianProvinces: PropTypes.object,
		faresSelected: PropTypes.object,
		shoppingPassengers: PropTypes.object,
		shoppingContext: PropTypes.string,
		departDate: PropTypes.object,
		returnDate: PropTypes.object,
		shoppingLegsTransactionFee: PropTypes.number,
		onChangeSelection: PropTypes.func,
		onBookingCreated: PropTypes.func,
		tripType: PropTypes.string,
		sessionToken: PropTypes.string,
		enableSeatReservations: PropTypes.string,
		addOrderMode: PropTypes.bool,
		exchangeOrderMode: PropTypes.bool,
		onAddOrder: PropTypes.func,
		queryItems: PropTypes.object,
		orderPassengers: PropTypes.array,
		onStatusChange: PropTypes.func,
		configBasedAuth: PropTypes.bool,
		exchangeableTicketableFareIds: PropTypes.array,
		recordLocator: PropTypes.string,
		orderId: PropTypes.string,
		onExchange: PropTypes.func,
		orderTicketDeliveryOption: PropTypes.string,
		cancelledTFPassengers: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object,
		]),
		exchangeTdoOptions: PropTypes.array,
		history: PropTypes.object,
		handleExchangeError: PropTypes.func,
		seatMapData: PropTypes.func,
		exchangeValidate: PropTypes.func,
		onChangeJourney: PropTypes.func,
	};

	static contextTypes = {
		router: PropTypes.object,
	};

	static defaultProps = {
		addOrderMode: false,
		exchangeOrderMode: false,
		orderPassengers: [],
		exchangeableTicketableFareIds: [],
		recordLocator: null,
		orderId: null,
		cancelledTFPassengers: null,
		exchangeTdoOptions: [],
	};

	constructor(props) {
		super(props);
		const {
			intl: { formatMessage },
		} = props;
		const initialTravelDocs = this.initialPassengerInfo.travelDocs;
		if (props.tripType === formatMessage(messages.lblSeason)) {
			initialTravelDocs.documentType = 'PHOTOCARD';
			initialTravelDocs.issueCountry = 'GB';
		}

		this.initialState.passengerInfo =
			props.shoppingPassengers &&
			props.shoppingPassengers.toJS().map((item, index) => {
				const orderPassenger = this.getOrderPassenger(
					item.ShoppingPassengerRefId,
				);
				return {
					...this.initialPassengerInfo,
					title: this.getOrderPassengerItem(
						orderPassenger,
						'BookingPaxTitle',
						index,
					),
					firstName: this.getOrderPassengerItem(
						orderPassenger,
						'BookingPaxFirstName',
						index,
					),
					lastName: this.getOrderPassengerItem(
						orderPassenger,
						'BookingPaxLastName',
						index,
					),
					phoneNumber: this.getOrderPassengerItem(
						orderPassenger,
						'BookingPaxContactPhoneNumbers[0].BookingPaxContactInfo',
						index,
					),
					email: this.getOrderPassengerItem(
						orderPassenger,
						'BookingPaxContactEmailAddresses[0].BookingPaxContactInfo',
						index,
					),
					age: item.ShoppingPassengerAge,
					ageDesc: this.formatAge(item.ShoppingPassengerAge),
					paxId: item.ShoppingPassengerRefId,
					discountsLoyalties: setDiscountsLoyalties(
						item,
						index,
						props.faresSelected,
					),
					travelDocs: this.updateTravelDocs(
						initialTravelDocs,
						item.ShoppingPassengerRefId,
					),
					passengerId: this.getOrderPassengerItem(
						orderPassenger,
						'BookingPassengerID',
						index,
					),
					requiredPassengerFields: computeRequiredPassengerFields(
						props.faresSelected,
					),
				};
			});
		this.state = { data: fromJS(this.initialState) };
	}

	componentDidMount() {
		const {
			shoppingPassengers,
			shoppingContext,
			tripType,
			faresSelected,
			sessionToken,
			exchangeOrderMode,
			exchangeTdoOptions,
			history,
		} = this.props;

		if (!shoppingPassengers || !shoppingPassengers.size) {
			history.push(`${getBasePath()}shopping/Search`);
		} else if (shoppingContext) {
			this.validateBooking(
				shoppingContext,
				tripType,
				faresSelected,
				sessionToken,
				exchangeOrderMode,
				exchangeTdoOptions,
			);
		}
	}

	componentWillUnmount() {
		if (this.state.data.get('changeJourney')) {
			this.props.onChangeSelection();
		}
	}

	getOrderPassenger = (passengerRefId) =>
		this.props.orderPassengers.find(
			(passenger) => passenger.BookingPassengerID === passengerRefId,
		);

	getOrderPassengerItem = (orderPassenger, itemId, index) => {
		let item = '';
		if (this.props.exchangeOrderMode) {
			item = get(orderPassenger, itemId, '');
		} else if (this.props.addOrderMode) {
			item = get(this.props, `orderPassengers[${index}].${itemId}`, '');
		}

		return item;
	};

	updateTravelDocs = (initialTravelDocs, passengerRefId) => ({
		...initialTravelDocs,
		DOB: this.getOrderPassenger(passengerRefId)?.BookingPaxDOB ?? null,
	});

	setSelectedTdo = (
		tdo,
		tdoSmsCountry,
		tdoSmsNumber,
		tdoPaperEmail,
		tdoRegion,
		tdoMailName,
		tdoMailAddress,
		tdoSmartCards,
		tdoPickUpLocation,
	) => {
		let selectedTdo = defaultTdo;
		let tdoOptions = this.state.data
			.getIn(['validateBooking', 'BookingTDOs'])
			.toJS();

		if (this.props.exchangeOrderMode && !tdoOptions.size) {
			tdoOptions = this.state.data.get('tdoOptions').toJS();
		}
		const tdoData = tdoOptions.find(
			(tdoOption) => tdoOption.BookingTDOCode === tdo,
		);
		if (tdoData) {
			selectedTdo = updateTdoObj(
				tdoData,
				tdoSmsCountry,
				tdoSmsNumber,
				tdoPaperEmail,
				tdoRegion,
				tdoMailName,
				tdoMailAddress,
				tdoSmartCards,
				tdoPickUpLocation,
			);
		}
		return selectedTdo;
	};

	getTdoSmsCallingCodes = (tdoCode) => {
		tdoSmsCallingCodesApi(
			tdoCode,
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						isFetching: false,
					}),
				}));
			},
			(response) => {
				const smsCodeOptions = response.successResponse.data.codes;
				this.setState((state) => ({
					data: state.data.merge({
						smsCodeOptions,
					}),
				}));
			},
		);
	};

	// eslint-disable-next-line react/sort-comp
	initialPassengerInfo = {
		title: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		address: {
			countryCode: null,
			address1: '',
			address2: '',
			city: '',
			stateOrProvince: '',
			postalCode: '',
		},
		travelDocs: {
			gender: '',
			DOB: null,
			documentType: null,
			documentNumber: '',
			issueCountry: null,
			documentExpDate: null,
		},
		discountsLoyalties: {
			discountIdentifier: '',
			discountAuthorization: '',
			corporateIdentifier: '',
			corporateAuthorization: '',
			loyaltyProgram: null,
			loyaltyIdentifier: '',
			aggregatedDiscounts: [],
			aggregatedLoyalties: [],
		},
		age: '',
		ageDesc: '',
		paxId: '',
		addAddress: false,
		addressRequired: false,
		passengerId: '',
		customInformation: [],
	};

	initialState = {
		alertText: '',
		passengerInfo: [],
		customInformationItems: [],
		customerNumber: '',
		purchaseOrderNumber: '',
		referenceNumber: '',
		accountData: {},
		tdoOptions: [],
		validateBooking: {},
		openTicketDelivery: false,
		selectedTdo: defaultTdo,
		smsCodeOptions: [],
		hasCustomInfo: false,
		isProcessingSubmit: false,
		isFetching: false,
		changeJourney: false,
		openTravelCards: false,
		travelCards: [],
		openOnBoardServices: false,
		onBoardServices: [],
		openSeatReservations: false,
		seatReservations: {},
		errors: {},
		openAddAddressDialog: false,
		exchangeSummaryResponse: {},
		openPassengerCustomInformation: null,
		bikeReservations: [],
		openBikeReservations: false,
		selectedBikeReservations: {},
		plusBusSupplements: [],
		selectedPlusBusSupplements: [],
		openPlusBusSupplements: false,
	};

	changeState = (propPath, value) => {
		this.setState((state) => ({ data: state.data.setIn(propPath, value) }));
	};

	handleChangeField = (event, parentPath) => {
		const propPath = parentPath;
		propPath.push(event.target.name);
		this.changeState(propPath, event.target.value);
	};

	handleChangeAddress = (updates, index) => {
		this.setState((state) => {
			let newDataState = state.data.mergeIn(
				['passengerInfo', index, 'address'],
				updates,
			);
			if (updates.countryCode) {
				newDataState = newDataState.deleteIn([
					'errors',
					'passengerInfo',
					index,
					'address',
					'stateOrProvince',
				]);
			}

			return { data: newDataState };
		});
	};

	handleChangeCustomInformation = (customInformation, index) => {
		gaEvent('shoppingPassengerCustomInformationSave');
		this.setState((state) => ({
			data: state.data.setIn(
				['passengerInfo', index, 'customInformation'],
				fromJS(customInformation),
			),
		}));
	};

	updateOpenPassengerCustomInformation = (
		openPassengerCustomInformation,
		registerEvent = true,
	) => {
		if (registerEvent) {
			gaEvent(
				`shoppingPassengerCustomInformation${openPassengerCustomInformation === null ? 'Close' : 'Open'}`,
			);
		}
		this.setState((state) => ({
			data: state.data.set(
				'openPassengerCustomInformation',
				openPassengerCustomInformation,
			),
		}));
	};

	handleAddAddress = (index) => {
		gaEvent('addAddress');
		this.setState((state) => ({
			data: state.data.setIn(['passengerInfo', index, 'addAddress'], true),
		}));
	};

	handleWithoutAddress = (index) =>
		this.setState(
			(state) => ({
				data: state.data.setIn(
					['passengerInfo', index, 'address'],
					fromJS(this.initialPassengerInfo.address),
				),
			}),
			() => {
				this.handleCreateBooking();
			},
		);

	// Plusbus handlers
	handleAddPlusBusSupplements = () => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderEditPlusBusSupplements');
		else if (exchangeOrderMode) gaEvent('exchangeOrderPlusBusSupplements');
		else gaEvent('createBookingEditPlusBusSupplements');
		this.setState((state) => ({
			data: state.data.merge({ openPlusBusSupplements: true }),
		}));
	};

	handleChangePlusBusSupplements = (selectedPlusBusItems) => {
		const {
			addOrderMode,
			exchangeOrderMode,
			shoppingContext,
			tripType,
			faresSelected,
			sessionToken,
		} = this.props;
		const { data } = this.state;
		const valueHasChanged = !is(
			selectedPlusBusItems,
			data.get('plusBusSupplements'),
		);
		if (valueHasChanged) {
			if (addOrderMode) gaEvent('addOrderSavePlusBusSupplements');
			else if (exchangeOrderMode)
				gaEvent('exchangeOrderSavePlusBusSupplements');
			else gaEvent('createBookingSavePlusBusSupplements');
		}

		this.setState(
			(state) => ({
				data: state.data.merge({
					selectedPlusBusSupplements: selectedPlusBusItems,
					openPlusBusSupplements: false,
				}),
			}),
			() => {
				if (exchangeOrderMode && valueHasChanged) {
					this.exchangeSummary(
						shoppingContext,
						tripType,
						faresSelected,
						sessionToken,
						data.get('selectedTdo').toJS(),
						selectedPlusBusItems,
						data.get('seatReservations'),
					);
				}
			},
		);
	};

	handleClosePlusBusSupplements = () => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderDiscardPlusBusSupplements');
		else if (exchangeOrderMode)
			gaEvent('exchangeOrderDiscardPlusBusSupplements');
		else gaEvent('createBookingDiscardPlusBusSupplements');
		this.setState((state) => ({
			data: state.data.merge({ openPlusBusSupplements: false }),
		}));
	};

	// travel card handlers
	handleAddTravelCards = () => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderEditTravelcards');
		else if (exchangeOrderMode) gaEvent('exchangeOrderEditTravelcards');
		else gaEvent('createBookingEditTravelcards');
		this.setState((state) => ({
			data: state.data.merge({ openTravelCards: true }),
		}));
	};

	handleChangeTravelCards = (travelCards) => {
		const {
			addOrderMode,
			exchangeOrderMode,
			shoppingContext,
			tripType,
			faresSelected,
			sessionToken,
		} = this.props;
		const { data } = this.state;
		const valueHasChanged = !is(travelCards, data.get('travelCards'));
		if (valueHasChanged) {
			if (addOrderMode) gaEvent('addOrderSaveTravelcards');
			else if (exchangeOrderMode) gaEvent('exchangeOrderSaveTravelcards');
			else gaEvent('createBookingSaveTravelcards');
		}

		this.setState(
			(state) => ({
				data: state.data.merge({ travelCards, openTravelCards: false }),
			}),
			() => {
				if (exchangeOrderMode && valueHasChanged) {
					this.exchangeSummary(
						shoppingContext,
						tripType,
						faresSelected,
						sessionToken,
						data.get('selectedTdo').toJS(),
						data.get('validateBooking').toJS(),
						travelCards,
						data.get('seatReservations'),
					);
				}
			},
		);
	};

	handleCloseTravelCards = () => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderDiscardTravelcards');
		else if (exchangeOrderMode) gaEvent('exchangeOrderDiscardTravelcards');
		else gaEvent('createBookingDiscardTravelcards');
		this.setState((state) => ({
			data: state.data.merge({ openTravelCards: false }),
		}));
	};

	// additional service handlers
	handleAddOnboardServices = () => {
		gaEvent('createBookingOnboardServicesAdd');
		this.setState((state) => ({
			data: state.data.merge({ openOnBoardServices: true }),
		}));
	};

	handleChangeOnboardServices = (onBoardServices) =>
		this.setState((state) => ({
			data: state.data.merge({ onBoardServices, openOnBoardServices: false }),
		}));

	handleCloseOnboardServices = () =>
		this.setState((state) => ({
			data: state.data.merge({ openOnBoardServices: false }),
		}));

	handleSnackbarClose = () => {
		this.setState((state) => ({ data: state.data.merge({ alertText: '' }) }));
	};

	// TODO think about seperating these action handlers into the CustomInfo component since it is always the same
	// Custom information handlers
	handleAddCustomInfo = () => {
		gaEvent('createBookingCustomInformationAdd');
		this.setState((state) => ({
			data: state.data.setIn(
				[
					'customInformationItems',
					state.data.get('customInformationItems').size,
				],
				fromJS({
					type: '',
					typeReadOnly: false,
					value: '',
					required: false,
				}),
			),
		}));
	};

	handleRemoveCustomInfo = (index) => {
		gaEvent('createBookingCustomInformationRemove');
		this.setState((state) => ({
			data: state.data.deleteIn(['customInformationItems', index]),
		}));
	};

	handleChangeCustomInfo = (index, updates) => {
		if (!this.state.data.get('customInformationItems')) {
			this.setState((state) => ({
				data: state.data.merge({ customInformationItems: fromJS([]) }),
			}));
		}
		this.setState((state) => ({
			data: state.data.mergeIn(['customInformationItems', index], updates),
		}));
	};

	handleChangeJourney = () => {
		gaEvent('changeJourney');
		this.props.onChangeJourney();
		// use state to store 'change journey' selection
		// if we dont then order summary will be blank momentarily since 'selectedFares' will be cleared
		// before the component unmount
		this.setState(
			(state) => ({ data: state.data.set('changeJourney', true) }),
			() => {
				this.props.history.goBack();
			},
		);
	};

	formatAge = (paxAge) => {
		const {
			intl: { formatMessage },
		} = this.props;
		let ageText = `${formatMessage(messages.lblAge)} ${paxAge}`;

		if (paxAge === ADULT_PAX_AGE) ageText = formatMessage(messages.lblAgeAdult);
		else if (paxAge >= 65) ageText = `${formatMessage(messages.lblAge)} 65+`;
		return ageText;
	};

	handleChangeTravelDocs = (index, updates) => {
		this.setState((state) => ({
			data: state.data.mergeIn(['passengerInfo', index, 'travelDocs'], updates),
		}));
	};

	handleChangeDiscountsAndLoyalties = (index, updates) => {
		this.setState((state) => ({
			data: state.data.mergeIn(
				['passengerInfo', index, 'discountsLoyalties'],
				updates,
			),
		}));
	};

	preSetTdo = (validateBooking) => {
		let tdoOptions = validateBooking;
		if (this.props.exchangeOrderMode) {
			this.setState((state) => ({
				data: state.data.set('tdoOptions', fromJS(validateBooking)),
			}));
		} else {
			tdoOptions = validateBooking.BookingTDOs;
		}

		let preSelectedTDO = {};
		let selectedTdo = defaultTdo;

		if (this.props.exchangeOrderMode) {
			preSelectedTDO = tdoOptions.find(
				(tdoOption) =>
					tdoOption.BookingTDOCode.substring(0, 3) ===
					this.props.orderTicketDeliveryOption,
			);
		} else {
			TDOS.some((item) => {
				preSelectedTDO = tdoOptions.find(
					(tdoOption) =>
						tdoOption.BookingTDOCode.substring(0, 3) === item &&
						!notAutoSelectableTdos.includes(item),
				);
				return preSelectedTDO;
			});
		}

		if (preSelectedTDO) {
			selectedTdo = updateTdoObj(preSelectedTDO);
		}

		if (this.props.exchangeOrderMode) {
			this.exchangeSummary(
				this.props.shoppingContext,
				this.props.tripType,
				this.props.faresSelected,
				this.props.sessionToken,
				selectedTdo,
				validateBooking,
				this.state.data.get('travelCards'),
				this.state.data.get('seatReservations'),
			);
		} else {
			this.setState(
				(state) => ({
					data: state.data.merge({
						validateBooking,
						selectedTdo,
						isFetching: false,
					}),
				}),
				() => this.handleStatusChange(false),
			);
		}
	};

	validateBooking = (
		shoppingContext,
		tripType,
		faresSelected,
		sessionToken,
		exchangeOrderMode,
		exchangeTdoOptions,
	) => {
		this.setState(
			(state) => ({
				data: state.data.merge({ isFetching: true }),
			}),
			() => this.handleStatusChange(true),
		);

		const priceIds = faresSelected.map((selectedFare) =>
			selectedFare.getIn(['fare', 'priceId']),
		);

		bookingValidateApi(
			{
				priceIds: priceIds.toJS(),
				tripType,
				exchangeMode: this.props.exchangeOrderMode ? true : undefined,
				accountName: shoppingContext,
				requestorId: `srtValidateBooking.${Date.now()}`,
				storageId: sessionToken,
			},
			(response) => {
				if (exchangeOrderMode) {
					this.setState(
						(state) => ({
							data: state.data.merge({
								isFetching: false,
							}),
						}),
						() => this.handleStatusChange(false),
					);
					this.preSetTdo(exchangeTdoOptions);
					this.props.exchangeValidate(true);
				} else {
					this.setState(
						(state) => ({
							data: state.data.merge({
								alertText: response.errorResponse.message,
								isFetching: false,
							}),
						}),
						() => this.handleStatusChange(false),
					);
				}
			},
			(response) => {
				const validateBooking = response.successResponse.data;

				if (!exchangeOrderMode) {
					this.props.seatMapData(validateBooking.TravelSegments);
				}

				if (validateBooking.SupplementOptionalPrices) {
					this.updateSupplements(validateBooking.SupplementOptionalPrices);
				}

				this.setTravelDocsRequired(
					validateBooking.BookingValidateIsTravelDocumentRequired,
				);

				if (!this.props.configBasedAuth)
					this.updateAccountData(validateBooking);
				const smsTdoCode = findTDOSMSBookingCode(validateBooking.BookingTDOs);
				if (smsTdoCode) {
					this.getTdoSmsCallingCodes(smsTdoCode);
				}
				this.preSetTdo(validateBooking);
			},
		);
	};

	updateSupplements = (supplementOptionalPrices) => {
		this.setState((state) => ({
			data: state.data.merge({
				bikeReservations: supplementOptionalPrices.filter(
					(supplementOptionalPrice) => supplementOptionalPrice.code === 'BIK',
				),
				plusBusSupplements: supplementOptionalPrices.filter(
					(supplementOptionalPrice) =>
						supplementOptionalPrice.category === 'LOCAL_BUS',
				),
			}),
		}));
	};

	setTravelDocsRequired = (bookingValidateIsTravelDocumentRequired) => {
		this.setState((state) => ({
			data: state.data.merge({
				isTravelDocsRequired: bookingValidateIsTravelDocumentRequired,
			}),
		}));
	};

	updateAccountData = (validateBooking) => {
		const {
			AccountCustomData,
			AccountManagementFeeTypeAmount1,
			AccountManagementFeeTypeAmount2,
			AccountManagementFeeTypeEnumVal1,
			AccountManagementFeeTypeEnumVal2,
			AccountManagementFeeTypeMinAmount1,
			AccountManagementFeeTypeMinAmount2,
		} = validateBooking;
		let customInformationItems = [];
		if (AccountCustomData) {
			customInformationItems = AccountCustomData.sort((a, b) => {
				if (a.AccountCustomDataType < b.AccountCustomDataType) return -1;
				if (a.AccountCustomDataType > b.AccountCustomDataType) return 1;
				return 0;
			}).map((item) => ({
				type: item.AccountCustomDataType,
				typeReadOnly: true,
				value: item.AccountCustomDataValue,
				required: item.AccountCustomDataRequired,
			}));
		}
		this.setState((state) => ({
			data: state.data.merge(
				fromJS({
					customInformationItems,
					accountData: {
						AccountManagementFeeTypeAmount1,
						AccountManagementFeeTypeAmount2,
						AccountManagementFeeTypeEnumVal1,
						AccountManagementFeeTypeEnumVal2,
						AccountManagementFeeTypeMinAmount1,
						AccountManagementFeeTypeMinAmount2,
					},
				}),
			),
		}));
	};

	exchangeSummary = (
		shoppingContext,
		tripType,
		faresSelected,
		sessionToken,
		selectedTdo,
		validateBooking,
		travelCards,
		seatReservations,
	) => {
		exchangeSummaryApi(
			{
				shoppingContext,
				tripType,
				faresSelected,
				sessionToken,
				selectedTdo,
				exchangeableTicketableFareIds: this.props.exchangeableTicketableFareIds,
				travelCards,
				seatReservations,
				recordLocator: this.props.recordLocator,
				orderId: this.props.orderId,
			},
			(response) => {
				this.setState(
					(state) => ({
						data: state.data.merge({
							alertText: response.errorResponse.message,
							isFetching: false,
						}),
					}),
					() => this.handleStatusChange(false),
				);
				this.props.handleExchangeError();
			},
			(response) => {
				const exchangeSummaryResponse = response.successResponse.data;
				const updatedValidateBooking = validateBooking;
				const paymentSummary = updatedValidateBooking?.BookingPaymentSummary;
				if (paymentSummary) {
					paymentSummary.BookingPaymentSummaryBalanceDue =
						exchangeSummaryResponse.BookingExchangeBalance;
					paymentSummary.BookingPaymentSummaryMinDepositAmount =
						exchangeSummaryResponse.BookingExchangeBalance;
					paymentSummary.BookingPaymentSummaryPaymentDueDate =
						validateBooking.BookingPaymentSummary.BookingPaymentSummaryDepositDueDate;
				}

				this.props.seatMapData(exchangeSummaryResponse.TravelSegments);

				this.setState(
					(state) => ({
						data: state.data.merge({
							isFetching: false,
							validateBooking: updatedValidateBooking,
							selectedTdo,
							exchangeSummaryResponse,
						}),
					}),
					() => this.handleStatusChange(false),
				);
			},
		);
	};

	handleStatusChange = (status) => {
		if (this.props.onStatusChange) {
			this.props.onStatusChange(status);
		}
	};

	handleCreateBooking = () => {
		const { data } = this.state;
		const {
			faresSelected,
			tripType,
			shoppingContext,
			sessionToken,
			intl: { formatMessage },
			history,
		} = this.props;
		const constraintsWithIntl = constraintsObjects(formatMessage, data);
		const suppliers = transformSuppliers(
			faresSelected.getIn([0, 'leg', 'ShoppingLegOrigStationSuppliers']),
		);
		const customInfoErrors =
			constraintsWithIntl.customInfoConstraintsValidation(
				data.get('customInformationItems').toJS(),
			);
		const errors = {
			...validate(data.toJS(), constraintsWithIntl.customInfoConstraints),
		};
		const passengerErrors = [];
		const isSelectedTdoError = {
			...validate(
				data.get('selectedTdo').toJS(),
				constraintsWithIntl.ticketDeliveryConstraints,
			),
		};
		let openAddAddressDialog = false;

		const basicConstraints =
			constraintsWithIntl.constructBasicConstraints(tripType);

		data.get('passengerInfo').forEach((item, index) => {
			const isPrimaryPassenger = index === 0;
			const address = item.get('address').toJS();
			const travelDocs = item.get('travelDocs').toJS();
			const discountsLoyalties = item.get('discountsLoyalties').toJS();
			const requiredFieldsForAllPassengers = item
				.getIn(['requiredPassengerFields', 'requiredFieldsForAllPassengers'])
				.toJS();
			const requiredFieldsForPrimaryPassenger = item
				.getIn(['requiredPassengerFields', 'requiredFieldsForPrimaryPassenger'])
				.toJS();

			const travelDocsRequired =
				requiredFieldsForPrimaryPassenger.TRAVEL_DOCUMENTS ||
				data.get('isTravelDocsRequired');
			const passengerAddressRequired =
				requiredFieldsForPrimaryPassenger.PASSENGER_ADDRESS;

			let indexPassengerErrors = passengerErrors[index] || {};

			const nameErrors = validatePassengerField(
				item.toJS(),
				basicConstraints,
				isPrimaryPassenger,
				requiredFieldsForPrimaryPassenger.PASSENGER_NAME,
				requiredFieldsForAllPassengers.PASSENGER_NAME,
			);
			indexPassengerErrors = Object.assign(indexPassengerErrors, nameErrors);

			const emailErrors = validatePassengerField(
				item.toJS(),
				constraintsWithIntl.passengersEmail,
				isPrimaryPassenger,
				requiredFieldsForPrimaryPassenger.PASSENGER_EMAIL,
				requiredFieldsForAllPassengers.PASSENGER_EMAIL,
			);
			indexPassengerErrors = Object.assign(indexPassengerErrors, emailErrors);

			const contactErrors = validatePassengerField(
				item.toJS(),
				constraintsWithIntl.contactConstraints,
				isPrimaryPassenger,
				requiredFieldsForPrimaryPassenger.PASSENGER_PHONE,
				requiredFieldsForAllPassengers.PASSENGER_PHONE,
			);
			indexPassengerErrors = Object.assign(indexPassengerErrors, contactErrors);

			const addressErrors = validate(
				address,
				constraintsWithIntl.addressConstraints,
			);
			const addAddress =
				(item.get('addAddress') || passengerAddressRequired) &&
				(address.countryCode ||
					address.address1 ||
					address.city ||
					address.stateOrProvince ||
					address.postalCode);
			if (isPrimaryPassenger && !passengerAddressRequired) {
				openAddAddressDialog = Boolean(addAddress);
			}

			if (
				(addAddress || passengerAddressRequired) &&
				!validate.isEmpty(addressErrors)
			) {
				indexPassengerErrors.address = addressErrors;
			}

			const travelDocsErrors = validate(
				travelDocs,
				constraintsWithIntl.constructTravelDocsConstraints(
					tripType,
					travelDocsRequired,
				),
			);
			if (
				(requiredFieldsForAllPassengers.DATE_OF_BIRTH || travelDocsRequired) &&
				!validate.isEmpty(travelDocsErrors)
			) {
				indexPassengerErrors.travelDocs = travelDocsErrors;
			}

			const discountsLoyaltiesErrors = validate(
				discountsLoyalties,
				constraintsWithIntl.constructDiscountsLoyaltiesConstraints(suppliers),
			);
			if (!validate.isEmpty(discountsLoyaltiesErrors)) {
				indexPassengerErrors.discountsLoyalties = discountsLoyaltiesErrors;
			}

			if (!validate.isEmpty(indexPassengerErrors)) {
				passengerErrors[index] = indexPassengerErrors;
			}
		});

		if (!validate.isEmpty(passengerErrors)) {
			errors.passengerInfo = passengerErrors;
		}

		if (!validate.isEmpty(customInfoErrors)) {
			errors.customInformationItems = customInfoErrors;
		}

		if (!validate.isEmpty(isSelectedTdoError)) {
			[errors.selectedTdo] = isSelectedTdoError.tdoCode;
		}

		if (!validate.isEmpty(errors)) {
			// there is TDO error - specific message
			if (Object.keys(errors).length === 1 && errors.selectedTdo) {
				this.setState((state) => ({
					data: state.data.merge({
						errors,
						alertText: errors.selectedTdo,
					}),
				}));
				// other errors - general message
			} else {
				this.setState((state) => ({
					data: state.data.merge({
						errors,
						openAddAddressDialog,
						alertText: formatMessage(messages.errGeneralText),
					}),
				}));
			}
			return;
		}

		this.setState((state) => ({
			data: state.data.merge({ isProcessingSubmit: true, errors: {} }),
		}));

		bookingCreateApi(
			{
				intl: this.props.intl,
				data,
				faresSelected,
				tripType,
				accountName: shoppingContext,
				sessionToken,
			},
			(response) => {
				this.setState((state) => ({
					data: state.data.merge({
						alertText: response.errorResponse.message,
						isProcessingSubmit: false,
					}),
				}));
			},
			(response) => {
				const createBooking = response.successResponse.data;
				let alertText;
				updateBookingCustomInfo(
					data,
					createBooking,
					faresSelected,
					'createBooking',
					sessionToken,
					(updateResponse) => {
						alertText = updateResponse?.errorResponse?.message;
						gaEvent('createBookingError', updateResponse);
						this.handleUpdateCustomInfo(
							data,
							createBooking,
							faresSelected,
							sessionToken,
							alertText,
							history,
						);
					},
					() => {
						this.handleUpdateCustomInfo(
							data,
							createBooking,
							faresSelected,
							sessionToken,
							alertText,
							history,
						);
					},
				);
			},
		);
	};

	handleUpdateCustomInfo = (
		data,
		createBooking,
		faresSelected,
		sessionToken,
		alertText,
		history,
	) => {
		bookingSetTdoApi(
			data,
			createBooking,
			faresSelected,
			'createBooking',
			sessionToken,
			(updateTdoResponse) => {
				const alertMessage = alertText
					? `${alertText}, ${updateTdoResponse?.errorResponse?.message}`
					: updateTdoResponse?.errorResponse?.message;
				gaEvent('createBookingError', updateTdoResponse);
				this.handleCreateBookingSuccessResponse(
					createBooking,
					history,
					alertMessage,
				);
			},
			() => {
				if (alertText) {
					this.setState((state) => ({
						data: state.data.merge({
							alertText,
						}),
					}));
				}
				this.handleCreateBookingSuccessResponse(
					createBooking,
					history,
					alertText,
				);
			},
		);
	};

	handleCreateBookingSuccessResponse = (
		createBooking,
		history,
		alertMessage,
	) => {
		this.setState(
			(state) => ({
				data: state.data.merge({
					alertText: alertMessage,
					isProcessingSubmit: false,
				}),
			}),
			() => {
				this.props.onBookingCreated({
					srtAgencyCode: createBooking.BookingAgencyCode,
					srtChannelCode: createBooking.BookingChannelCode,
					srtCompanyCode: createBooking.BookingCompanyCode,
					srtDistCode: createBooking.BookingDistributorCode,
					srtPOSCode: createBooking.BookingPOSCode,
					srtRecLocator: createBooking.recordLocator,
				});
				history.push(
					alertMessage
						? `${getBasePath()}booking/Manage?alertText=${alertMessage}`
						: `${getBasePath()}booking/Manage`,
				);
			},
		);
	};

	handleAddOrder = () => {
		const { data } = this.state;
		const {
			queryItems,
			faresSelected,
			tripType,
			shoppingContext,
			sessionToken,
		} = this.props;

		gaEvent('addOrderAddOrderButton');

		this.setState(
			(state) => ({
				data: state.data.merge({ isProcessingSubmit: true, errors: {} }),
			}),
			() => this.handleStatusChange(true),
		);

		bookingAddOrderApi(
			data,
			queryItems,
			faresSelected,
			tripType,
			shoppingContext,
			sessionToken,
			(response) => {
				this.setState(
					(state) => ({
						data: state.data.merge({
							alertText: response.errorResponse.message,
							isProcessingSubmit: false,
						}),
					}),
					() => this.handleStatusChange(false),
				);
			},
			(response) => {
				const bookingResponse = response.successResponse.data;
				bookingSetTdoApi(
					data,
					{
						...bookingResponse,
						...queryItems,
					},
					faresSelected,
					'addOrder',
					sessionToken,
					(updateResponse) => {
						this.setState(
							(state) => ({
								data: state.data.merge({
									alertText: updateResponse.errorResponse.message,
									isProcessingSubmit: false,
								}),
							}),
							() => this.handleStatusChange(false),
						);
					},
					() => {
						this.setState(
							(state) => ({
								data: state.data.merge({
									isProcessingSubmit: false,
								}),
							}),
							() => {
								this.handleStatusChange(false);
								this.props.onAddOrder();
							},
						);
					},
				);
			},
		);
	};

	handleExchangeOrder = () => {
		const { data } = this.state;
		const needPay =
			data.getIn(['exchangeSummaryResponse', 'BookingExchangeBalance']) > 0;

		this.props.onExchange(
			this.props.shoppingContext,
			this.props.tripType,
			this.props.faresSelected,
			this.props.sessionToken,
			data.get('selectedTdo').toJS(),
			data.get('validateBooking'),
			data.get('travelCards'),
			data.get('seatReservations'),
			data.get('exchangeSummaryResponse'),
			data.get('passengerInfo'),
			needPay,
		);
	};

	handleCloseTicketDelivery = () => {
		this.setState(({ data }) => ({
			data: data.merge({ openTicketDelivery: false }),
		}));
	};

	handleSubmitTicketDelivery = (
		tdo,
		tdoSmsCountry,
		tdoSmsNumber,
		tdoPaperEmail,
		tdoRegion,
		tdoMailName,
		tdoMailAddress,
		tdoSmartCards,
		tdoPickUpLocation,
	) => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent('addOrderSubmitTicketDelivery');
		else if (exchangeOrderMode) gaEvent('exchangeOrderTicketDeliverySubmit');
		else gaEvent('createBookingEditTDOSubmit');

		const selectedTdo = this.setSelectedTdo(
			tdo,
			tdoSmsCountry,
			tdoSmsNumber,
			tdoPaperEmail,
			tdoRegion,
			tdoMailName,
			tdoMailAddress,
			tdoSmartCards,
			tdoPickUpLocation,
		);

		const { data } = this.state;
		const valueHasChanged = !is(fromJS(selectedTdo), data.get('selectedTdo'));
		this.setState(
			(state) => ({
				data: state.data.merge({ selectedTdo, openTicketDelivery: false }),
			}),
			() => {
				if (this.props.exchangeOrderMode && valueHasChanged) {
					this.exchangeSummary(
						this.props.shoppingContext,
						this.props.tripType,
						this.props.faresSelected,
						this.props.sessionToken,
						selectedTdo,
						data.get('validateBooking').toJS(),
						data.get('travelCards'),
						data.get('seatReservations'),
					);
				}
			},
		);
	};

	handleOpenTicketDelivery = (tdoEvent) => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode) gaEvent(`addOrderTicketDelivery${tdoEvent}`);
		else if (exchangeOrderMode)
			gaEvent(`exchangeOrderTicketDelivery${tdoEvent}`);
		else gaEvent('createBookingEditTDOOpen');

		this.setState(({ data }) => ({
			data: data.merge({ openTicketDelivery: true }),
		}));
	};

	// seat reservations handlers
	handleAddSeatReservations = (isEditButton) => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode)
			gaEvent(
				isEditButton
					? 'addOrderEditSeatReservations'
					: 'addOrderAddSeatReservations',
			);
		else if (exchangeOrderMode)
			gaEvent(
				isEditButton
					? 'exchangeOrderEditSeatReservations'
					: 'exchangeOrderAddSeatReservations',
			);
		else gaEvent('createBookingAddSeatReservations');

		this.setState((state) => ({
			data: state.data.merge({ openSeatReservations: true }),
		}));
	};

	handleChangeSeatReservations = (seatReservations) => {
		const { data } = this.state;
		const valueHasChanged = !is(
			fromJS(seatReservations),
			data.get('seatReservations'),
		);

		this.setState(
			(state) => ({
				data: state.data.merge({
					seatReservations,
					openSeatReservations: false,
				}),
			}),
			() => {
				if (this.props.exchangeOrderMode && valueHasChanged) {
					this.exchangeSummary(
						this.props.shoppingContext,
						this.props.tripType,
						this.props.faresSelected,
						this.props.sessionToken,
						data.get('selectedTdo').toJS(),
						data.get('validateBooking').toJS(),
						data.get('travelCards'),
						fromJS(seatReservations),
					);
				}
			},
		);
	};

	handleChangeBikeReservations = (selectedBikeReservations) => {
		const { data } = this.state;
		const valueHasChanged = !is(
			fromJS(selectedBikeReservations),
			data.get('selectedBikeReservations'),
		);
		this.setState(
			(state) => ({
				data: state.data.merge({
					selectedBikeReservations,
					openBikeReservations: false,
				}),
			}),
			() => {
				if (this.props.exchangeOrderMode && valueHasChanged) {
					// TODO: update exchangeSummary call with Bike Reservations similar to Seat Reservation above
					this.exchangeSummary(
						this.props.shoppingContext,
						this.props.tripType,
						this.props.faresSelected,
						this.props.sessionToken,
						data.get('selectedTdo').toJS(),
						data.get('travelCards'),
						data.get('seatReservations'),
					);
				}
			},
		);
	};

	handleCloseSeatReservations = () =>
		this.setState((state) => ({
			data: state.data.merge({ openSeatReservations: false }),
		}));

	handleCloseBikeReservations = () =>
		this.setState((state) => ({
			data: state.data.merge({ openBikeReservations: false }),
		}));

	// bike reservations handlers
	handleAddBikeReservations = (isEditButton) => {
		const { addOrderMode, exchangeOrderMode } = this.props;

		if (addOrderMode)
			gaEvent(
				isEditButton
					? 'addOrderEditBikeReservations'
					: 'addOrderAddBikeReservations',
			);
		else if (exchangeOrderMode)
			gaEvent(
				isEditButton
					? 'exchangeOrderEditBikeReservations'
					: 'exchangeOrderAddBikeReservations',
			);
		else gaEvent('createBookingAddBikeReservations');

		this.setState((state) => ({
			data: state.data.merge({ openBikeReservations: true }),
		}));
	};

	renderPassenger = (paxInfo, index, requiredFields) => {
		const {
			addOrderMode,
			canadianProvinces,
			countries,
			exchangeOrderMode,
			faresSelected,
			states,
			tripType,
		} = this.props;
		const { data } = this.state;
		return (
			<Passenger
				key={index}
				addOrderMode={addOrderMode}
				canadianProvinces={canadianProvinces}
				countries={countries}
				createBookingData={data}
				exchangeOrderMode={exchangeOrderMode}
				faresSelected={faresSelected}
				handleAddAddress={this.handleAddAddress}
				handleChangeAddress={this.handleChangeAddress}
				handleChangeDiscountsAndLoyalties={
					this.handleChangeDiscountsAndLoyalties
				}
				handleChangeField={this.handleChangeField}
				handleChangeTravelDocs={this.handleChangeTravelDocs}
				handleCreateBooking={this.handleCreateBooking}
				handleWithoutAddress={this.handleWithoutAddress}
				index={index}
				paxInfo={paxInfo}
				states={states}
				tripType={tripType}
				requiredFields={requiredFields}
				updateOpenPassengerCustomInformation={
					this.updateOpenPassengerCustomInformation
				}
				handleChangeCustomInformation={this.handleChangeCustomInformation}
			/>
		);
	};

	render() {
		const { data } = this.state;
		const {
			shoppingContext,
			faresSelected,
			shoppingPassengers,
			departDate,
			returnDate,
			tripType,
			shoppingLegsTransactionFee,
			enableSeatReservations,
			addOrderMode,
			exchangeOrderMode,
			intl: { formatMessage },
			cancelledTFPassengers,
		} = this.props;

		// Passenger Forms with address, travel docs, discounts and loyalties
		const passengerInfo = data.get('passengerInfo');
		const disabled = data.get('isProcessingSubmit') || data.get('isFetching');

		const requiredFieldsPrimaryPassenger = passengerInfo
			.first()
			.getIn(['requiredPassengerFields', 'requiredFieldsForPrimaryPassenger'])
			.toJS();
		const firstPassengerInfo =
			passengerInfo &&
			this.renderPassenger(
				passengerInfo.first(),
				0,
				requiredFieldsPrimaryPassenger,
			);

		const requiredFieldsForAllPassengers = passengerInfo
			.first()
			.getIn(['requiredPassengerFields', 'requiredFieldsForAllPassengers'])
			.toJS();
		const restPassengerInfo =
			passengerInfo &&
			passengerInfo.map(
				(item, index) =>
					!!index &&
					this.renderPassenger(item, index, requiredFieldsForAllPassengers),
			);

		// TICKET DELIVERY
		const tdoFee = data.getIn(['selectedTdo', 'tdoFee']);
		let tdOptions = data.getIn(['validateBooking', 'BookingTDOs']);
		const suppliers = transformSuppliers(
			faresSelected.getIn([0, 'leg', 'ShoppingLegOrigStationSuppliers']),
		);
		const smsCodeOptions = data.get('smsCodeOptions');
		const stationCode = faresSelected.getIn([
			0,
			'leg',
			'ShoppingLegOrigStationCode',
		]);
		const stationName = faresSelected.getIn([
			0,
			'leg',
			'ShoppingLegOrigStationName',
		]);
		let viewMode = false;
		// for Exchange mode do not allow all TDOs, only 1 TDO from original Order
		if (exchangeOrderMode && tdOptions) {
			tdOptions = tdOptions.filter(
				(item) =>
					item.get('BookingTDOCode') === this.props.orderTicketDeliveryOption,
			);
		}

		// viewMode sets disabled "Submit" button on TicketDeliveryDialog. Set to true if only 1 TDO
		if (tdOptions && tdOptions.size === 1) {
			const ticketDeliveryOnlyOption = tdOptions.getIn([0, 'BookingTDOCode']);
			// Even though there is only 1 TDO we should not disable button for Mail or SCT option
			// User should be able to insert additional data for Mail or SCT option
			if (
				!isTDOSmartCard(ticketDeliveryOnlyOption) &&
				!isTDOMail(ticketDeliveryOnlyOption)
			) {
				viewMode = true;
			}
		}
		const gaType = viewMode ? 'view' : 'edit';

		// PLUSBUS RESERVATIONS
		const plusBusSupplements = extractPlusBusSupplements(
			data.get('plusBusSupplements'),
		);
		const hasPlusBusSupplements = plusBusSupplements.length > 0;
		const selectedPlusBusSupplements = hasPlusBusSupplements
			? extractPlusBusSupplements(data.get('selectedPlusBusSupplements'))
			: null;

		// TravelCards
		const travelCards = extractTravelCards(faresSelected);
		const hasTravelCards = travelCards.length > 0;
		const selectedTravelCards = hasTravelCards
			? data.get('travelCards')
			: undefined;

		// OnBoardService
		const onBoardServices = extractOnBoardServices(faresSelected);
		const hasOnBoardServices = !validate.isEmpty(onBoardServices);
		let selectedOnBoardServices = null;
		let selectedOnBoardServicesGroupBySegment = null;
		if (hasOnBoardServices) {
			selectedOnBoardServices = data.get('onBoardServices').toJS();
			selectedOnBoardServicesGroupBySegment = groupOnBoardServicesBySegment(
				data.get('onBoardServices'),
			);
		}

		// SEAT RESERVATIONS
		let currency = null;
		const isSeasonPass = tripType === formatMessage(messages.lblSeason);
		const hasOptionalPrices = faresSelected.toJS().map((item) => {
			const { optionalPrices } = item.fare;
			currency = item.fare.currency;
			return (
				optionalPrices &&
				optionalPrices.length !== 0 &&
				!checkAccommodations(optionalPrices)
			);
		});
		const faresWithSeatPrefs = faresSelected
			.toJS()
			.filter((item) => item.fare.seatPreferences);
		const seatPrefArray = faresWithSeatPrefs
			? faresWithSeatPrefs.map((item) => item.fare.seatPreferences)
			: [];
		const areReservable =
			seatPrefArray.length > 0
				? consolidateReservable(seatPrefArray)
				: 'NOT_POSSIBLE';
		const hasSeatReservations =
			!(areReservable === 'NOT_POSSIBLE' && !hasOptionalPrices) &&
			!isSeasonPass &&
			enableSeatReservations === 'true';

		let selectedSeatReservations = null;
		let selectedSeatReservationsPrice = null;
		if (hasSeatReservations) {
			selectedSeatReservations = data.get('seatReservations').toJS();
			if (!validate.isEmpty(selectedSeatReservations)) {
				// list by segment
				Object.keys(selectedSeatReservations).forEach((segmentKey) => {
					const itemSegment = selectedSeatReservations[segmentKey];
					if (itemSegment.enableAllSeats) {
						selectedSeatReservationsPrice += itemSegment.price;
					}
					// list by passenger
					Object.keys(selectedSeatReservations[segmentKey]).forEach(
						(paxPrefKey) => {
							if (!itemSegment.enableAllSeats) {
								const itemPassenger =
									selectedSeatReservations[segmentKey][paxPrefKey];
								if (itemPassenger.enablePassengerSeats) {
									selectedSeatReservationsPrice += itemPassenger.price;
								}
							}
						},
					);
				});
			}
		}

		// BIKE RESERVATIONS
		const bikeReservations = data.get('bikeReservations');
		const hasBikeReservations = bikeReservations.size > 0;
		const selectedBikeReservations = hasBikeReservations
			? data.get('selectedBikeReservations').toJS()
			: null;
		let selectedBikeReservationsPrice = null;

		if (
			selectedBikeReservations &&
			!validate.isEmpty(selectedBikeReservations)
		) {
			// list by segment
			Object.keys(selectedBikeReservations).forEach((segmentKey) => {
				// list by passenger
				Object.keys(selectedBikeReservations[segmentKey]).forEach(
					(paxPrefKey) => {
						const itemPassenger =
							selectedBikeReservations[segmentKey][paxPrefKey];
						if (itemPassenger.enablePassengerBikes) {
							selectedBikeReservationsPrice += itemPassenger.price;
						}
					},
				);
			});
		}

		const faresSelectedLeg = faresSelected.map((item) => item.get('leg'));
		const faresSelectedLegSolutions = faresSelectedLeg
			.map((item) => item.get('ShoppingLegSolutions'))
			.toJS()
			.flat(1);
		const hasAmtrak = faresSelectedLegSolutions.some((item) =>
			item.ShoppingLegSolutionMarketingCarriers.includes('Amtrak'),
		);

		return (
			<div>
				<SubNavbar
					heading={
						!addOrderMode && !exchangeOrderMode
							? formatMessage(messages.title)
							: ''
					}
					selectedAccount={
						shoppingContext || formatMessage(messages.lblUnknownContext)
					}
					addOrderMode={addOrderMode}
					exchangeOrderMode={exchangeOrderMode}
				>
					{!addOrderMode && !exchangeOrderMode ? (
						<div style={inlineStyles.buttonsContainer}>
							<Button
								variant="contained"
								id="srtChangeJourney"
								style={inlineStyles.buttons}
								onClick={this.handleChangeJourney}
								disabled={disabled}
							>
								{formatMessage(messages.lblChangeJourney)}
							</Button>
							<Button
								variant="contained"
								id="srtCreateBooking"
								color="primary"
								style={inlineStyles.buttons}
								onClick={this.handleCreateBooking}
								disabled={disabled}
							>
								{formatMessage(messages.lblCreateBooking)}
							</Button>
						</div>
					) : null}
				</SubNavbar>
				<div
					className={addOrderMode || exchangeOrderMode ? '' : 'container'}
					styleName="container-marginAdjust"
				>
					{hasAmtrak && (
						<Paper style={inlineStyles.amtrakTandC} elevation={1} id="amtrak">
							<FormattedMessage
								{...messages.lblAmtrakTandC}
								values={{
									TermsAndConditionsLink: (
										<a
											href="https://www.amtrak.com/services/contentService.ibcontentpopup.terms-and-conditions.html"
											target="_blank"
											rel="noreferrer"
										>
											{formatMessage(messages.lblTermsAndConditions)}
										</a>
									),
								}}
							/>
						</Paper>
					)}
					<div className="row" styleName="contentContainer">
						<div className="col-sm-12 col-md-6" styleName="contentColumn">
							{firstPassengerInfo}
							<TicketDelivery
								tdoFee={tdoFee}
								createBookingData={data}
								handleOpenTicketDelivery={(tdoEvent) =>
									this.handleOpenTicketDelivery(tdoEvent)
								}
								viewMode={viewMode}
							/>
							<BookingOptions
								areReservable={areReservable}
								createBookingData={data}
								currency={currency}
								disabled={disabled}
								faresSelected={faresSelected}
								handleAddOnboardServices={this.handleAddOnboardServices}
								handleAddSeatReservations={(isEditButton) =>
									this.handleAddSeatReservations(isEditButton)
								}
								handleAddBikeReservations={(isEditButton) =>
									this.handleAddBikeReservations(isEditButton)
								}
								handleAddTravelCards={this.handleAddTravelCards}
								handleChangeOnboardServices={this.handleChangeOnboardServices}
								handleChangeSeatReservations={this.handleChangeSeatReservations}
								handleChangeBikeReservations={this.handleChangeBikeReservations}
								handleChangeTravelCards={this.handleChangeTravelCards}
								handleCloseOnboardServices={this.handleCloseOnboardServices}
								handleCloseSeatReservations={this.handleCloseSeatReservations}
								handleCloseBikeReservations={this.handleCloseBikeReservations}
								handleCloseTravelCards={this.handleCloseTravelCards}
								hasOnBoardServices={hasOnBoardServices}
								hasSeatReservations={hasSeatReservations}
								hasBikeReservations={hasBikeReservations}
								hasTravelCards={hasTravelCards}
								onBoardServices={onBoardServices}
								passengerInfo={passengerInfo}
								bikeReservations={bikeReservations}
								selectedOnBoardServices={selectedOnBoardServices}
								selectedOnBoardServicesGroupBySegment={
									selectedOnBoardServicesGroupBySegment
								}
								selectedSeatReservations={selectedSeatReservations}
								selectedBikeReservations={selectedBikeReservations}
								selectedSeatReservationsPrice={selectedSeatReservationsPrice}
								selectedBikeReservationsPrice={selectedBikeReservationsPrice}
								selectedTravelCards={selectedTravelCards}
								shoppingPassengers={shoppingPassengers}
								travelCards={travelCards}
								addOrderMode={addOrderMode}
								exchangeOrderMode={exchangeOrderMode}
								handleAddPlusBusSupplements={this.handleAddPlusBusSupplements}
								handleChangePlusBusSupplements={
									this.handleChangePlusBusSupplements
								}
								handleClosePlusBusSupplements={
									this.handleClosePlusBusSupplements
								}
								hasPlusBusSupplements={hasPlusBusSupplements}
								selectedPlusBusSupplements={selectedPlusBusSupplements}
								plusBusSupplements={plusBusSupplements}
							/>
							{addOrderMode || exchangeOrderMode || (
								<CustomFields
									createBookingData={data}
									disabled={disabled}
									handleAddCustomInfo={this.handleAddCustomInfo}
									handleChangeCustomInfo={this.handleChangeCustomInfo}
									handleChangeField={this.handleChangeField}
									handleRemoveCustomInfo={this.handleRemoveCustomInfo}
								/>
							)}
							{restPassengerInfo}
						</div>
						<div className="col-sm-12 col-md-6" styleName="contentColumn">
							<OrderSummary
								orderDetails={extractOrderDetails(
									faresSelected,
									shoppingLegsTransactionFee,
									data.get('accountData'),
									tdoFee,
								)}
								exchangeSummary={data.get('exchangeSummaryResponse')}
								departDate={departDate}
								returnDate={returnDate}
								faresSelected={faresSelected}
								travelCards={selectedTravelCards}
								selectedPlusBusSupplements={selectedPlusBusSupplements}
								onBoardServices={selectedOnBoardServices}
								seatReservationsPrice={selectedSeatReservationsPrice}
								bikeReservationsPrice={selectedBikeReservationsPrice}
								tripType={determineTripType(tripType)}
								addOrderMode={addOrderMode}
								exchangeOrderMode={exchangeOrderMode}
								onChangeSelection={this.handleChangeJourney}
							/>
						</div>
						{exchangeOrderMode && cancelledTFPassengers && (
							<div
								className="col-sm-12 col-md-6 offset-md-6"
								styleName="contentColumn"
							>
								<ExchangeOrderNotification
									cancelledTFPassengers={cancelledTFPassengers}
									isConfirmation={false}
								/>
							</div>
						)}
					</div>
				</div>
				{tdOptions && (
					<TicketDeliveryDialog
						open={data.get('openTicketDelivery')}
						handleClose={this.handleCloseTicketDelivery}
						handleSubmit={this.handleSubmitTicketDelivery}
						tdOptions={tdOptions}
						suppliers={suppliers}
						passengers={passengerInfo}
						smsCodeOptions={smsCodeOptions ? smsCodeOptions.toJS() : []}
						stationCode={stationCode}
						stationName={stationName}
						preSelectedTdo={data.get('selectedTdo').toJS()}
						viewMode={viewMode}
						addOrderMode={addOrderMode}
						exchangeOrderMode={exchangeOrderMode}
						gaType={gaType}
					/>
				)}
				<RefreshIndicator
					size={36}
					top={0}
					left={0}
					status={disabled ? 'loading' : 'hide'}
					style={inlineStyles.indicator}
				/>
				<ExtendedSnackbar
					id="srtCreateBookingSnackBar"
					open={data.get('alertText') !== ''}
					message={data.get('alertText') || ''}
					onClose={this.handleSnackbarClose}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const queryStateLast =
		!ownProps.addOrderMode && !ownProps.exchangeOrderMode
			? state.getIn(['shopping', 'recentSearches', 'searches']).first()
			: state.getIn(['shopping', 'query']);
	return {
		countries: state.getIn(['settings', 'GlobalCountryList']),
		states: state.getIn(['settings', 'StateProvinceList']),
		canadianProvinces: state.getIn(['settings', 'CanadianProvincesList']),
		regionMaps: state.getIn(['settings', 'RegionMaps']),
		faresSelected: state.getIn(['shopping', 'results', 'faresSelected']),
		shoppingPassengers: state.getIn([
			'shopping',
			'results',
			'results',
			'ShoppingPassengers',
		]),
		shoppingContext: queryStateLast.get('accountName'),
		departDate: new Date(queryStateLast.get('departDate')),
		returnDate: new Date(queryStateLast.get('returnDate')),
		shoppingLegsTransactionFee: state.getIn([
			'shopping',
			'results',
			'results',
			'ShoppingLegsTransactionFee',
			'value',
		]),
		tripType: state.getIn([
			'shopping',
			'results',
			'results',
			'ShoppingTripData',
			'ShoppingTripDataType',
		]),
		sessionToken: state.getIn(['shopping', 'results', 'sessionToken']),
		enableSeatReservations: state.getIn([
			'settings',
			'ws.feature.bookingpaxinfo.enable_seat_reservations',
		]),
		configBasedAuth:
			state.getIn([
				'settings',
				'ws.interface.config_based.authentication_enabled',
			]) === 'true',
	};
};

// Nothing to update the store yet, from create booking page
const mapDispatchToProps = {
	onChangeSelection: clearFaresSelected,
	onBookingCreated: newBookingRequest,
	seatMapData: getSeatMapData,
	exchangeValidate: setExchangeValidate,
	onChangeJourney: setChangeJourney,
};

// This alias will be used to access bare component for unit testing
export { CreateBooking as CreateBookingAlias };

// need to use forwardRef: true so addOrder can capture component reference correctly
export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(injectIntl(CreateBooking, { forwardRef: true }));
