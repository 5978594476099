import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'app.shopping.results.title',
		defaultMessage: 'Search for fares',
	},
	lblToggleFilters: {
		id: 'app.shopping.results.toggleFilters',
		defaultMessage: 'Filter Fares',
	},
	lblSearchAgain: {
		id: 'app.shopping.results.searchAgain',
		defaultMessage: 'Search Again',
	},
	lblOutbound: {
		id: 'app.shopping.results.outbound',
		defaultMessage: 'Outbound',
	},
	lblInbound: {
		id: 'app.shopping.results.inbound',
		defaultMessage: 'Inbound',
	},
	lblSeasonPass: {
		id: 'app.shopping.results.seasonPass',
		defaultMessage: 'Season Passes',
	},
	lblJourneyDetails: {
		id: 'app.shopping.results.journeyDetails',
		defaultMessage: 'Journey Details',
	},
});
