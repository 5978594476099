const styles = {
	dialogPaper: {
		minWidth: '500px',
	},
	dialogTitle: {
		backgroundColor: '#f5f5f5',
		padding: '16px 24px',
	},
	dialogContent: {
		padding: '24px',
	},
	bookingReference: {
		color: '#4caf50',
	},
	bookingReferenceContainer: {
		marginBottom: 16,
	},
	radioGroup: {
		width: '100%',
	},
	selectOption: {
		color: 'grey',
	},
	formControlLabel: {
		marginRight: 0,
		justifyContent: 'space-between',
		width: '100%',
		marginBottom: 8,
	},
	radioLabel: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'center',
	},
	priceInfo: {
		textAlign: 'right',
	},
	actions: {
		borderTop: '1px solid #f5f5f5',
		padding: '16px 20px',
	},
};

export default styles;
