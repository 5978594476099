import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import EditUser from './EditUser';
import SearchUsers from './SearchUsers';

const ManageUsers = ({ match }) => (
	<div className="container-fluid">
		<Switch>
			<Route
				path={`${match.path}/Search/:searchStr?`}
				component={SearchUsers}
			/>
			<Route path={`${match.path}/EditUser/:userId`} component={EditUser} />
			<Redirect path="*" to={`${match.path}/Search`} />
		</Switch>
	</div>
);

ManageUsers.propTypes = {
	match: PropTypes.object,
};

export default ManageUsers;
