import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { getFromPath } from '../../../utils';
import { genderType, contactType } from '../../../Shopping/util';

import messages from './messagesPassengerInformation';
import inlineStyles, { withStylesTheme } from './styles';
import OtherBookings from './OtherBookings';

const StyledTabs = styled(Tabs)(({ theme }) => ({
	backgroundColor: withStylesTheme(theme).tabsRoot.backgroundColor,
	fontSize: withStylesTheme(theme).tabsRoot.fontSize,
	'& .MuiTabs-indicator': {
		backgroundColor: withStylesTheme(theme).tabsIndicator.backgroundColor,
	},
}));

const StyledTab = styled(Tab)(({ theme, hasErrors }) => ({
	...withStylesTheme(theme).tabRoot,
	color: hasErrors ? 'red' : withStylesTheme(theme).tabRoot.color,
}));

class PassengerInformationView extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { tab: props.selectedPax };
	}

	onChangeField =
		(path) =>
		({ target: { value: fieldValue } }) => {
			const { onChange } = this.props;
			const { tab } = this.state;
			onChange([tab, ...path], fieldValue);
		};

	onChangeDate = (path) => (date) => {
		const {
			onChange,
			intl: { formatDate },
		} = this.props;
		const { tab } = this.state;
		const dateFormat = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		};
		onChange([tab, ...path], formatDate(date, dateFormat));
	};

	onChangeTab = (event, tab) => {
		this.setState({ tab });
	};

	tabContent = () => {
		const {
			passengers,
			updateable: { PassengerAddress },
		} = this.props;
		const { tab } = this.state;
		const passenger = passengers[tab];
		const showAddress =
			(passenger.BookingPaxContactAddressCountry &&
				passenger.BookingPaxContactAddressPostalCode) ||
			PassengerAddress;
		const showTravelDocs =
			Array.isArray(passenger.BookingResponsePassengerTravelDocuments) &&
			passenger.BookingResponsePassengerTravelDocuments.length > 0;
		const showLoyaltyCards =
			Array.isArray(passenger.BookingPaxLoyaltyCards) &&
			passenger.BookingPaxLoyaltyCards.length > 0;

		const passengerEmail =
			passenger.BookingPaxContactEmailAddresses[0] &&
			passenger.BookingPaxContactEmailAddresses[0].BookingPaxContactInfo;

		return (
			<div>
				<div className="row" style={inlineStyles.tabContainer}>
					<div className="col-12">
						{this.renderPassengerIdentity(passenger, tab)}
					</div>
					{showAddress && (
						<div className="col-12 col-sm-6">
							{this.renderPassengerAddress(passenger, tab)}
						</div>
					)}
					<div className="col-12 col-sm-6">
						{this.renderPassengerContactInfo(passenger, tab)}
					</div>
					{showTravelDocs && (
						<div className="col-12">
							{this.renderPassengerTravelDocs(passenger, tab)}
						</div>
					)}
					{showLoyaltyCards && (
						<div className="col-12">
							{this.renderPassengerLoyaltyCards(passenger, tab)}
						</div>
					)}
				</div>

				<div className="row" style={inlineStyles.tabContainer}>
					<div className="col-12">
						<h3 style={inlineStyles.subtitle}>
							<FormattedMessage {...messages.lblOtherBookings} />
						</h3>
						<OtherBookings
							firstName={passenger.BookingPaxFirstName}
							lastName={passenger.BookingPaxLastName}
							email={passengerEmail}
							onFetchingError={this.props.onFetchingBookingsError}
						/>
					</div>
				</div>
			</div>
		);
	};

	renderPassengerIdentity = (passenger, tab) => {
		const {
			intl: { formatDate },
			updateable: { PassengerIdentity },
			disabled,
		} = this.props;
		const dateFormat = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		};

		return (
			<div>
				<h3 style={inlineStyles.subtitle}>
					<FormattedMessage {...messages.lblPaxIdentity} />
				</h3>
				<div className="col-12 col-sm-6">
					{passenger.BookingPaxFirstName && (
						<TextField
							id={`srtPax_${tab}_firstName`}
							label={<FormattedMessage {...messages.lblPaxFirstName} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxFirstName"
							value={passenger.BookingPaxFirstName}
							disabled
							variant="standard"
						/>
					)}
					{passenger.BookingPaxMiddleName && (
						<TextField
							id={`srtPax_${tab}_middleName`}
							label={<FormattedMessage {...messages.lblPaxMiddleName} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxMiddleName"
							value={passenger.BookingPaxMiddleName}
							disabled
							variant="standard"
						/>
					)}
					{(passenger.BookingPaxTitle || PassengerIdentity) && (
						<TextField
							id={`srtPax_${tab}_title`}
							label={<FormattedMessage {...messages.lblPaxTitle} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxTitle"
							value={passenger.BookingPaxTitle || ''}
							onChange={this.onChangeField(['BookingPaxTitle'])}
							disabled={!PassengerIdentity || disabled}
							variant="standard"
						/>
					)}
					{passenger.BookingPaxAgeAtTimeOfTravel && (
						<TextField
							id={`srtPax_${tab}_age`}
							label={<FormattedMessage {...messages.lblPaxAge} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxAgeAtTimeOfTravel"
							value={passenger.BookingPaxAgeAtTimeOfTravel}
							disabled
							variant="standard"
						/>
					)}
					{passenger.BookingPaxDOB && (
						<TextField
							id={`srtPax_${tab}_DOB`}
							label={<FormattedMessage {...messages.lblPaxDOB} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxDOB"
							value={formatDate(
								passenger.BookingPaxDOB.replace(/-/g, '/'),
								dateFormat,
							)}
							disabled
							variant="standard"
						/>
					)}
				</div>
				<div className="col-12 col-sm-6">
					{passenger.BookingPaxLastName && (
						<TextField
							id={`srtPax_${tab}_lastName`}
							label={<FormattedMessage {...messages.lblPaxLastName} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxLastName"
							value={passenger.BookingPaxLastName}
							disabled
							variant="standard"
						/>
					)}
					{(passenger.BookingPaxSuffix || PassengerIdentity) && (
						<TextField
							id={`srtPax_${tab}_suffix`}
							label={<FormattedMessage {...messages.lblPaxSuffix} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxSuffix"
							value={passenger.BookingPaxSuffix || ''}
							onChange={this.onChangeField(['BookingPaxSuffix'])}
							disabled={!PassengerIdentity || disabled}
							variant="standard"
						/>
					)}
					{(passenger.BookingPaxGender || PassengerIdentity) && (
						<TextField
							id={`srtPax_${tab}_gender`}
							label={<FormattedMessage {...messages.lblPaxGender} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxGender"
							value={passenger.BookingPaxGender || ''}
							onChange={this.onChangeField(['BookingPaxGender'])}
							disabled={!PassengerIdentity || disabled}
							select
							variant="standard"
						>
							{' '}
							{genderType.map((type, ix) => (
								<MenuItem
									id={`srtPassenger${ix}_${type.value}`}
									key={`${ix}_${type.value}`}
									value={type.value}
								>
									{type.label}
								</MenuItem>
							))}
						</TextField>
					)}
					{passenger.BookingSmartCardNumber && (
						<TextField
							id={`srtPax_${tab}_smartcardNumber`}
							label={<FormattedMessage {...messages.lblPaxSmartcardNumber} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingSmartCardNumber"
							value={passenger.BookingSmartCardNumber}
							disabled
							variant="standard"
						/>
					)}
				</div>
			</div>
		);
	};

	renderPassengerAddress = (passenger, tab) => {
		const { countries, errors, initialPassengers, disabled } = this.props;
		const { PassengerAddress } = this.props.updateable;

		const required = !!(
			PassengerAddress &&
			(initialPassengers.BookingPaxContactAddress1 ||
				passenger.BookingPaxContactAddress1 ||
				initialPassengers.BookingPaxContactAddressCity ||
				passenger.BookingPaxContactAddressCity ||
				initialPassengers.BookingPaxContactAddressCountry ||
				passenger.BookingPaxContactAddressCountry)
		);

		return (
			<div>
				<h3 style={inlineStyles.subtitle}>
					<FormattedMessage {...messages.lblPaxAddress} />
					{passenger.BookingPaxContactAddressType
						? ` - ${passenger.BookingPaxContactAddressType}`
						: ''}
				</h3>
				<div>
					<TextField
						id={`srtPax_${tab}_address1`}
						label={<FormattedMessage {...messages.lblPaxAddress1} />}
						InputLabelProps={{ style: inlineStyles.floatingLabel }}
						fullWidth
						name="BookingPaxContactAddress1"
						value={passenger.BookingPaxContactAddress1}
						onChange={this.onChangeField(['BookingPaxContactAddress1'])}
						disabled={!PassengerAddress || disabled}
						required={required}
						error={errors[tab] && !!errors[tab].BookingPaxContactAddress1}
						helperText={errors[tab] && errors[tab].BookingPaxContactAddress1}
						variant="standard"
					/>
					{(passenger.BookingPaxContactAddress2 || PassengerAddress) && (
						<TextField
							id={`srtPax_${tab}_address2`}
							label={<FormattedMessage {...messages.lblPaxAddress2} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxContactAddress2"
							value={passenger.BookingPaxContactAddress2 || ''}
							onChange={this.onChangeField(['BookingPaxContactAddress2'])}
							disabled={!PassengerAddress || disabled}
							variant="standard"
						/>
					)}
					<TextField
						id={`srtPax_${tab}_city`}
						label={<FormattedMessage {...messages.lblPaxCity} />}
						InputLabelProps={{ style: inlineStyles.floatingLabel }}
						fullWidth
						name="BookingPaxContactAddressCity"
						value={passenger.BookingPaxContactAddressCity}
						onChange={this.onChangeField(['BookingPaxContactAddressCity'])}
						disabled={!PassengerAddress || disabled}
						required={required}
						error={errors[tab] && !!errors[tab].BookingPaxContactAddressCity}
						helperText={errors[tab] && errors[tab].BookingPaxContactAddressCity}
						variant="standard"
					/>
					<TextField
						id={`srtPax_${tab}_postalCode`}
						label={<FormattedMessage {...messages.lblPaxPostalCode} />}
						InputLabelProps={{ style: inlineStyles.floatingLabel }}
						fullWidth
						name="BookingPaxContactAddressPostalCode"
						value={passenger.BookingPaxContactAddressPostalCode}
						onChange={this.onChangeField([
							'BookingPaxContactAddressPostalCode',
						])}
						disabled={!PassengerAddress || disabled}
						error={
							errors[tab] && !!errors[tab].BookingPaxContactAddressPostalCode
						}
						helperText={
							errors[tab] && errors[tab].BookingPaxContactAddressPostalCode
						}
						variant="standard"
					/>
					<TextField
						id={`srtPax_${tab}_country`}
						label={<FormattedMessage {...messages.lblPaxCountry} />}
						InputLabelProps={{ style: inlineStyles.floatingLabel }}
						fullWidth
						name="BookingPaxContactAddressCountry"
						value={passenger.BookingPaxContactAddressCountry}
						onChange={this.onChangeField(['BookingPaxContactAddressCountry'])}
						disabled={!PassengerAddress || disabled}
						required={required}
						error={errors[tab] && !!errors[tab].BookingPaxContactAddressCountry}
						helperText={
							errors[tab] && errors[tab].BookingPaxContactAddressCountry
						}
						select
						variant="standard"
					>
						{' '}
						{countries.toJS().map((country) => (
							<MenuItem
								id={`srtPax_${tab}_country_${country.code}`}
								key={`srtPax_${tab}_country_${country.code}`}
								value={country.code}
							>
								{country.country}
							</MenuItem>
						))}
					</TextField>
					{(passenger.BookingPaxContactAddressCountry === 'US' ||
						passenger.BookingPaxContactAddressCountry === 'CA') && (
						<TextField
							id={`srtPax_${tab}_StateProvince`}
							label={<FormattedMessage {...messages.lblPaxStateProvince} />}
							InputLabelProps={{ style: inlineStyles.floatingLabel }}
							fullWidth
							name="BookingPaxContactAddressStateProv"
							value={passenger.BookingPaxContactAddressStateProv || ''}
							onChange={this.onChangeField([
								'BookingPaxContactAddressStateProv',
							])}
							disabled={!PassengerAddress || disabled}
							variant="standard"
						/>
					)}
				</div>
			</div>
		);
	};

	renderPassengerContactInfo = (passenger, tab) => {
		const {
			updateable: { PassengerContactInfo },
			disabled,
		} = this.props;

		return (
			<div>
				<h3 style={inlineStyles.subtitle}>
					<FormattedMessage {...messages.lblPaxPhone} />
				</h3>
				<div>
					{passenger.BookingPaxContactPhoneNumbers.map((phone, i) => (
						<Fragment key={`srtPax_${tab}_phone`}>
							<TextField
								id={`srtPax_${tab}_phoneType${i}`}
								label={<FormattedMessage {...messages.lblType} />}
								InputLabelProps={{ style: inlineStyles.floatingLabel }}
								fullWidth
								name="BookingPaxContactType"
								value={phone.BookingPaxContactType}
								onChange={this.onChangeField([
									'BookingPaxContactPhoneNumbers',
									i,
									'BookingPaxContactType',
								])}
								disabled={!PassengerContactInfo || disabled}
								select
								variant="standard"
							>
								{contactType.map((type, ix) => (
									<MenuItem
										id={`srtPassenger${ix}_${type.value}`}
										key={`${ix}_${type.value}`}
										value={type.value}
									>
										{type.label}
									</MenuItem>
								))}
							</TextField>
							<TextField
								id={`srtPax_${tab}_phone${i}`}
								label={<FormattedMessage {...messages.lblValue} />}
								InputLabelProps={{ style: inlineStyles.floatingLabel }}
								fullWidth
								name="BookingPaxContactInfo"
								value={phone.BookingPaxContactInfo}
								onChange={this.onChangeField([
									'BookingPaxContactPhoneNumbers',
									i,
									'BookingPaxContactInfo',
								])}
								disabled={!PassengerContactInfo || disabled}
								variant="standard"
							/>
						</Fragment>
					))}
				</div>
				<h3 style={inlineStyles.subtitle}>
					<FormattedMessage {...messages.lblPaxEmail} />
				</h3>
				<div>
					{passenger.BookingPaxContactEmailAddresses.map((email, i) => (
						<Fragment key={`srtPax_${tab}_email`}>
							<TextField
								id={`srtPax_${tab}_emailType${i}`}
								label={<FormattedMessage {...messages.lblType} />}
								InputLabelProps={{ style: inlineStyles.floatingLabel }}
								fullWidth
								name="BookingPaxContactType"
								value={email.BookingPaxContactType}
								onChange={this.onChangeField([
									'BookingPaxContactEmailAddresses',
									i,
									'BookingPaxContactType',
								])}
								disabled={!PassengerContactInfo || disabled}
								select
								variant="standard"
							>
								{contactType.map((type, ix) => (
									<MenuItem
										id={`srtPassenger${ix}_${type.value}`}
										key={`${ix}_${type.value}`}
										value={type.value}
									>
										{type.label}
									</MenuItem>
								))}
							</TextField>
							<TextField
								id={`srtPax_${tab}_email${i}`}
								label={<FormattedMessage {...messages.lblValue} />}
								InputLabelProps={{ style: inlineStyles.floatingLabel }}
								fullWidth
								name="BookingPaxContactInfo"
								value={email.BookingPaxContactInfo}
								onChange={this.onChangeField([
									'BookingPaxContactEmailAddresses',
									i,
									'BookingPaxContactInfo',
								])}
								disabled={!PassengerContactInfo || disabled}
								variant="standard"
							/>
						</Fragment>
					))}
				</div>
			</div>
		);
	};

	renderPassengerTravelDocs = (passenger, tab) => {
		const { countries, errors, disabled } = this.props;
		const { TravelDocument } = this.props.updateable;

		return (
			<div>
				<h3 style={inlineStyles.subtitle}>
					<FormattedMessage {...messages.lblTravelDocs} />
				</h3>
				<div>
					{passenger.BookingResponsePassengerTravelDocuments.map(
						(travelDoc, i) => (
							<div
								key={`srtPax_${tab}_travelDoc${i}`}
								className="row"
								style={inlineStyles.travelDoc}
							>
								<div className="col-12 col-sm-6">
									<TextField
										id={`srtPax_${tab}_travelDocType${i}`}
										label={<FormattedMessage {...messages.lblTravelDocType} />}
										InputLabelProps={{ style: inlineStyles.floatingLabel }}
										fullWidth
										name="DocumentType"
										value={travelDoc.DocumentType}
										disabled
										variant="standard"
									/>
									<TextField
										id={`srtPax_${tab}_travelDocCountry${i}`}
										label={<FormattedMessage {...messages.lblPaxCountry} />}
										InputLabelProps={{ style: inlineStyles.floatingLabel }}
										fullWidth
										name="IssuingAuthority.CountryCode"
										value={travelDoc?.IssuingAuthority?.CountryCode}
										onChange={this.onChangeField([
											'BookingResponsePassengerTravelDocuments',
											i,
											'IssuingAuthority',
											'CountryCode',
										])}
										disabled={!TravelDocument || disabled}
										select
										variant="standard"
									>
										{countries.toJS().map((country) => (
											<MenuItem
												id={`srtPax_${tab}_travelDocCountry${i}_${country.code}`}
												key={`srtPax_${tab}_travelDocCountry${i}_${country.code}`}
												value={country.code}
											>
												{country.country}
											</MenuItem>
										))}
									</TextField>
								</div>
								<div className="col-12 col-sm-6">
									<TextField
										id={`srtPax_${tab}_travelDocNumber${i}`}
										label={
											<FormattedMessage {...messages.lblTravelDocNumber} />
										}
										InputLabelProps={{ style: inlineStyles.floatingLabel }}
										fullWidth
										name="DocumentNumber"
										value={travelDoc.DocumentNumber}
										onChange={this.onChangeField([
											'BookingResponsePassengerTravelDocuments',
											i,
											'DocumentNumber',
										])}
										disabled={!TravelDocument || disabled}
										error={
											!!getFromPath(
												[
													tab,
													'BookingResponsePassengerTravelDocuments',
													0,
													'errors',
													i,
													'DocumentNumber',
												],
												errors,
											)
										}
										helperText={getFromPath(
											[
												tab,
												'BookingResponsePassengerTravelDocuments',
												0,
												'errors',
												i,
												'DocumentNumber',
											],
											errors,
										)}
										variant="standard"
									/>
									<DateTimePicker
										id={`srtPax_${tab}_travelDocExpDate${i}`}
										label={
											<FormattedMessage
												{...messages.lblTravelDocExpirationDate}
											/>
										}
										InputLabelProps={{ style: inlineStyles.floatingLabel }}
										fullWidth
										name="ExpirationDate"
										value={travelDoc.ExpirationDate}
										onChange={this.onChangeDate([
											'BookingResponsePassengerTravelDocuments',
											i,
											'ExpirationDate',
										])}
										disabled={!TravelDocument || disabled}
										errorText={getFromPath(
											[
												tab,
												'BookingResponsePassengerTravelDocuments',
												0,
												'errors',
												i,
												'ExpirationDate',
											],
											errors,
										)}
									/>
								</div>
							</div>
						),
					)}
				</div>
			</div>
		);
	};

	renderPassengerLoyaltyCards = (passenger, tab) => {
		const { errors, disabled } = this.props;
		const { LoyaltyCard } = this.props.updateable;

		return (
			<div>
				<h3 style={inlineStyles.subtitle}>
					<FormattedMessage {...messages.lblLoyaltyCards} />
				</h3>
				<div>
					{passenger.BookingPaxLoyaltyCards.map((loyaltyCard, i) => (
						<div
							key={`srtPax_${tab}_loyaltyCard${i}`}
							className="row"
							style={inlineStyles.loyaltyCard}
						>
							<div className="col-12">
								<FormattedMessage {...messages.lblLoyaltyCard} />
							</div>
							<div className="col-12 col-sm-6">
								<TextField
									id={`srtPax_${tab}_loyaltyProgram${i}`}
									label={<FormattedMessage {...messages.lblLoyaltyProgram} />}
									InputLabelProps={{ style: inlineStyles.floatingLabel }}
									fullWidth
									name="BookingLoyaltyProgram"
									value={loyaltyCard.BookingLoyaltyProgram}
									disabled
									variant="standard"
								/>
							</div>
							<div className="col-12 col-sm-6">
								<TextField
									id={`srtPax_${tab}_loyaltyCardNumber${i}`}
									label={
										<FormattedMessage {...messages.lblLoyaltyCardNumber} />
									}
									InputLabelProps={{ style: inlineStyles.floatingLabel }}
									fullWidth
									name="BookingLoyaltyValue"
									value={loyaltyCard.BookingLoyaltyValue || ''}
									onChange={this.onChangeField([
										'BookingPaxLoyaltyCards',
										i,
										'BookingLoyaltyValue',
									])}
									disabled={!LoyaltyCard || disabled}
									variant="standard"
									error={
										!!getFromPath(
											[
												tab,
												'BookingPaxLoyaltyCards',
												0,
												'errors',
												i,
												'BookingLoyaltyValue',
											],
											errors,
										)
									}
									helperText={getFromPath(
										[
											tab,
											'BookingPaxLoyaltyCards',
											0,
											'errors',
											i,
											'BookingLoyaltyValue',
										],
										errors,
									)}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	render() {
		const { passengers, errors } = this.props;
		const { tab } = this.state;
		return (
			<div style={inlineStyles.containerOverflow}>
				<StyledTabs value={tab} onChange={this.onChangeTab} variant="fullWidth">
					{passengers.map((passenger, index) => {
						const hasErrors =
							errors[index] && Object.keys(errors[index]).length > 0;
						let name = `${passenger.BookingPaxFirstName} ${passenger.BookingPaxLastName}`;
						if (hasErrors) name += ' *';
						return (
							<StyledTab
								id={`srtPassengerTab_${index}`}
								key={`srtPassengerWindow_${index}`}
								label={name}
								value={index}
							/>
						);
					})}
				</StyledTabs>
				{this.tabContent()}
			</div>
		);
	}
}

PassengerInformationView.propTypes = {
	countries: PropTypes.object,
	disabled: PropTypes.bool,
	errors: PropTypes.array,
	id: PropTypes.string,
	initialPassengers: PropTypes.array,
	intl: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onFetchingBookingsError: PropTypes.func.isRequired,
	passengers: PropTypes.array,
	selectedPax: PropTypes.number,
	updateable: PropTypes.object.isRequired,
};

export { PassengerInformationView as PassengerInformationViewAlias };
export default injectIntl(PassengerInformationView);
