import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import validate from 'validate.js';

import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { injectIntl, FormattedMessage } from 'react-intl';

import '../stylesManageAccounts.css';
import CustomInfo from '../../../components/CustomInfo/CustomInfo';
import Address from '../../../components/Address/Address';
import Contact from '../../../components/Contact/Contact';
import Confirm from '../../../components/Confirm/Confirm';
import HomeLink from '../../components/HomeLink';
import { gaEvent } from '../../../utils/googleAnalytics';

import AssocUsersAutoComplete from './AssocUsersAutoComplete';
import FeeInfo from './FeeInfo';
import messages from '../messagesManageAccounts';
import inlineStyles from './accountStyles';
import {
	constraints,
	customInfoConstraintsValidation,
	feesConstraintsValidation,
	addressConstraints,
	contactConstraints,
} from './accountConstraints';
import ExternalPaymentProvider from './ExternalPaymentProvider';

// styling
const columnClass = 'col-12 col-lg-6';

const Account = memo(
	({
		account,
		settings,
		submit,
		delete: deleteAccount,
		redirect,
		intl,
		isProcessingAction,
	}) => {
		const initialState = {
			origName: '',
			name: '',
			context: '',
			brand: 'SilverRail',
			associatedUsers: [],
			customInformationItems: [{ type: '', value: '', required: false }],
			fees: [
				{ type: 'none', amount: '', minValue: '' },
				{ type: 'none', amount: '', minValue: '' },
			],
			address: {
				countryCode: null,
				address1: '',
				address2: '',
				city: '',
				stateOrProvince: '',
				postalCode: '',
			},
			contact: {
				phone: '',
				email: '',
			},
			fareSearchAllowed: true,
			isEditMode: false,
			errors: {},
			hasCustomInfo: false,
			useContactAddress: false,
			hasFees: false,
			showConfirm: false,
			isValid: true,
			merchantAccountId: '',
		};

		const [data, setData] = useState(fromJS(initialState));

		useEffect(() => {
			if (!validate.isEmpty(account)) {
				setData(data.merge(account));
			}
		}, [account]);

		const validateAddressHelper = (address) =>
			!(
				validate.isEmpty(address.countryCode) &&
				validate.isEmpty(address.address1) &&
				validate.isEmpty(address.address2) &&
				validate.isEmpty(address.city) &&
				validate.isEmpty(address.stateOrProvince) &&
				validate.isEmpty(address.postalCode)
			);

		const changeState = (name, value) => {
			setData((prevState) => prevState.merge({ [name]: value }));
		};

		const handleChangeField = (event) => {
			changeState(event.target.name, event.target.value);
		};

		const handleChangeContact = (updates) => {
			setData((prevState) => prevState.mergeIn(['contact'], updates));
		};

		const handleChangeAddress = (updates) => {
			setData((prevState) => {
				let newStateData = prevState.mergeIn(['address'], updates);
				if (updates.countryCode)
					newStateData = newStateData.deleteIn([
						'errors',
						'address',
						'stateOrProvince',
					]);
				return newStateData;
			});
		};

		const handleRemoveCustomInfo = (index) => {
			gaEvent('accountRemoveAdditionalCustomInfo', data.get('isEditMode'));
			setData((prevState) => {
				let newData = prevState.deleteIn(['customInformationItems', index]);
				if (newData.hasIn(['errors', 'customInformationItems'])) {
					newData = newData.setIn(
						['errors', 'customInformationItems'],
						fromJS([]),
					);
				}
				return newData;
			});
		};

		const handleAddCustomInfo = () => {
			gaEvent('accountAddAdditionalCustomInfo', data.get('isEditMode'));
			setData((prevState) => {
				let newData = prevState.setIn(
					['customInformationItems', data.get('customInformationItems').size],
					fromJS({
						type: '',
						typeReadOnly: false,
						value: '',
						required: false,
					}),
				);
				if (data.hasIn(['errors', 'customInformationItems'])) {
					newData = newData.setIn(
						['errors', 'customInformationItems'],
						fromJS([]),
					);
				}
				return newData;
			});
		};

		const handleChangeCustomInfo = (index, updates) => {
			setData((prevState) => {
				let newData = prevState;
				if (!prevState.get('customInformationItems')) {
					newData = newData.merge({ customInformationItems: fromJS([]) });
				}
				return newData.mergeIn(['customInformationItems', index], updates);
			});
		};

		const handleChangeAssociatedUser = (users) => {
			changeState('associatedUsers', fromJS(users.map((user) => user.value)));
		};

		const handleChangeFees = (index, updates) => {
			setData((prevState) => prevState.mergeIn(['fees', index], updates));
		};

		const handleClickAddCustomInfo = () => {
			gaEvent('accountAddCustomInfo', data.get('isEditMode'));
			changeState('hasCustomInfo', true);
		};

		const handleClickRemoveCustomInfo = () => {
			gaEvent('accountRemoveCustomInfo', data.get('isEditMode'));
			setData((prevState) =>
				prevState.merge({
					hasCustomInfo: false,
					customInformationItems: initialState.customInformationItems,
				}),
			);
		};

		const handleClickAddFees = () => {
			gaEvent('accountAddFees');
			changeState('hasFees', true);
		};

		const handleClickRemoveFees = () => {
			gaEvent('accountRemoveFees');
			setData((prevState) =>
				prevState.merge({ hasFees: false, fees: initialState.fees }),
			);
		};

		const handleHideConfirm = (cancelEvent) => {
			if (!cancelEvent) {
				gaEvent('accountDeleteCancel');
			}
			changeState('showConfirm', false);
		};

		const handleDeleteAccount = () => {
			handleHideConfirm(true);
			deleteAccount(data.get('origName'));
		};

		const handleShowConfirm = () => {
			gaEvent('accountDeleteConfirmation');
			changeState('showConfirm', true);
		};

		const handleClickCancel = () => {
			gaEvent('accountClose', data.get('isEditMode'));
			redirect();
		};

		const handleClickSubmit = () => {
			const { formatMessage } = intl;

			setData(data.merge({ errors: {} }));

			let errors = { ...validate(data.toJS(), constraints(formatMessage)) };
			if (validateAddressHelper(data.get('address').toJS())) {
				const addressErrors = validate(
					data.get('address').toJS(),
					addressConstraints(formatMessage),
				);
				errors = Object.assign(
					errors,
					!validate.isEmpty(addressErrors) ? { address: addressErrors } : {},
				);
			}

			const contactErrors = validate(
				data.get('contact').toJS(),
				contactConstraints(formatMessage),
			);
			errors = Object.assign(
				errors,
				!validate.isEmpty(contactErrors) ? { contact: contactErrors } : {},
			);

			if (data.get('hasCustomInfo')) {
				const customInfoErrors = customInfoConstraintsValidation(
					formatMessage,
					data.get('customInformationItems').toJS(),
				);
				if (!validate.isEmpty(customInfoErrors))
					errors.customInformationItems = customInfoErrors;
			}

			if (data.get('hasFees')) {
				const feesErrors = feesConstraintsValidation(
					formatMessage,
					data.get('fees').toJS(),
				);
				if (!validate.isEmpty(feesErrors)) errors.fees = feesErrors;
			}

			if (!validate.isEmpty(errors)) {
				setData(data.merge({ errors }));
			} else {
				submit(data.toJS());
			}
		};

		const { formatMessage } = intl;
		const showAdditionalOptions =
			data.get('hasCustomInfo') && data.get('hasFees');
		const deleteButton =
			data.get('isEditMode') &&
			(data.get('showConfirm') ? (
				<Confirm onYes={handleDeleteAccount} onNo={handleHideConfirm} />
			) : (
				<Button
					data-testid="DeleteButton"
					variant="contained"
					id="srtAccountDeleteButton"
					color="primary"
					styleName="button-spacing"
					onClick={handleShowConfirm}
					disabled={isProcessingAction}
				>
					<FormattedMessage {...messages.lblDeleteAccount} />
				</Button>
			));
		const hasSiloAccess =
			settings.get('ws.system.security.allowsiloacctmgt') === 'true';

		const customInfo = data.get('hasCustomInfo') && (
			<>
				<ListSubheader data-testid="AccountDetailsCustomInfoListSubheader">
					<FormattedMessage {...messages.lblCustomInfoHeader} />
				</ListSubheader>
				<hr
					style={inlineStyles.subheaderBorder}
					data-testid="AccountDetailsCustomInfoHr"
				/>
				<CustomInfo
					customInfoItems={
						data.get('customInformationItems').toJS &&
						data.get('customInformationItems').toJS()
					}
					changeCustomInfo={handleChangeCustomInfo}
					addCustomInfo={handleAddCustomInfo}
					removeCustomInfo={handleRemoveCustomInfo}
					showAddButton
					showRemove
					errors={
						data.hasIn(['errors', 'customInformationItems'])
							? data.getIn(['errors', 'customInformationItems']).toJS()
							: []
					}
				/>
				<div>
					<div className="row">
						<div className="col-12 col-sm-4 col-lg-3">
							<Button
								data-testid="RemoveCustomInfoButton"
								variant="contained"
								id="srtAccountRemoveCISec"
								styleName="removeButton-spacingCI"
								fullWidth
								onClick={handleClickRemoveCustomInfo}
							>
								<FormattedMessage {...messages.lblRemoveCustomInfo} />
							</Button>
						</div>
					</div>
				</div>
			</>
		);

		const feesDiv = data.get('hasFees') && (
			<div id="FeesInformation" data-testid="FeesInformation">
				<ListSubheader data-testid="FeesInformationListSubheader">
					<FormattedMessage {...messages.lblFeesHeader} />
				</ListSubheader>
				<hr
					style={inlineStyles.subheaderBorder}
					data-testid="FeesInformationHr"
				/>
				<FeeInfo
					feeTypes={settings.get('srtAccountFeeTypes')}
					fees={data.get('fees').toJS()}
					handleChangeFee={handleChangeFees}
					errors={
						data.hasIn(['errors', 'fees'])
							? data.getIn(['errors', 'fees']).toJS()
							: []
					}
				/>
				<div className="row">
					<div className="col-12 col-sm-3">
						<Button
							data-testid="RemoveFeeButton"
							variant="contained"
							id="srtAccountRemoveFeeSec"
							fullWidth
							onClick={handleClickRemoveFees}
						>
							<FormattedMessage {...messages.lblRemoveFees} />
						</Button>
					</div>
				</div>
			</div>
		);

		return (
			<div id="Account" data-testid="Account">
				<div
					className="row"
					style={inlineStyles.accountRow}
					data-testid="account-row"
				>
					<HomeLink
						id="srtAccountHome"
						className="col-12"
						text={data.get('name')}
						onClick={redirect}
					/>
					<div
						id="AccountDetails"
						className={columnClass}
						data-testid="AccountDetails"
					>
						<ListSubheader data-testid="AccountDetailsListSubheader">
							<FormattedMessage {...messages.lblGeneralHeader} />
						</ListSubheader>
						<hr
							style={inlineStyles.subheaderBorder}
							data-testid="AccountDetailsHr"
						/>
						<div data-testid="AccountDetailsInputs">
							<TextField
								data-testid="srtAccountName"
								id="srtAccountName"
								name="name"
								label={formatMessage(messages.lblName)}
								fullWidth
								onChange={handleChangeField}
								error={!!data.getIn(['errors', 'name'])}
								helperText={data.getIn(['errors', 'name'])}
								value={data.get('name')}
								style={inlineStyles.accountName}
								variant="standard"
							/>
							{hasSiloAccess || (
								<TextField
									id="srtAccountContext"
									data-testid="srtAccountContext"
									name="context"
									label={<FormattedMessage {...messages.lblContext} />}
									fullWidth
									onChange={handleChangeField}
									error={!!data.getIn(['errors', 'context'])}
									helperText={data.getIn(['errors', 'context'])}
									value={data.get('context')}
									variant="standard"
								/>
							)}
							<AssocUsersAutoComplete
								users={data.get('associatedUsers')}
								onChange={handleChangeAssociatedUser}
								placeholder={formatMessage(messages.lblAddAssocUser)}
								label={formatMessage(messages.lblAssocUser)}
								headerTitle={formatMessage(messages.lblAssocUsers)}
								dataTestId="srtAccountAssocUsers"
							/>
						</div>
					</div>
					<div className={columnClass} data-testid="AccountDetailsContact">
						<ListSubheader data-testid="AccountDetailsContactListSubheader">
							<FormattedMessage {...messages.lblContactHeader} />
						</ListSubheader>
						<hr
							style={inlineStyles.subheaderBorder}
							data-testid="AccountDetailsContactHr"
						/>
						<div data-testid="AccountDetailsContactInputs">
							<Address
								dataTestId="srtAccountAddress"
								idPrefix="srtCompany"
								values={data.get('address').toJS()}
								countries={settings.get('GlobalCountryList')}
								states={settings.get('StateProvinceList')}
								provinces={settings.get('CanadianProvincesList')}
								onChange={handleChangeAddress}
								errors={
									data.hasIn(['errors', 'address'])
										? data.getIn(['errors', 'address']).toJS()
										: {}
								}
								useDefaults
							/>
							<Contact
								dataTestId="srtAccountContact"
								columnClass="col-12"
								values={data.get('contact').toJS()}
								onChange={handleChangeContact}
								errors={
									data.hasIn(['errors', 'contact'])
										? data.getIn(['errors', 'contact']).toJS()
										: {}
								}
							/>
						</div>
						<div
							style={inlineStyles.fareSearchAllowed}
							data-testid="AccountDetailsFareSearchAllowed"
						>
							<FormControlLabel
								data-testid="AccountDetailsFareSearchAllowedFormControlLabel"
								label={<FormattedMessage {...messages.lblFareSearchAllowed} />}
								control={
									<Switch
										id="srtFareSearchAllowed"
										checked={data.get('fareSearchAllowed')}
										onChange={(event, checked) =>
											setData(data.merge({ fareSearchAllowed: checked }))
										}
									/>
								}
							/>
						</div>
						<ExternalPaymentProvider
							merchantAccountId={data.get('merchantAccountId')}
							handleChangeField={handleChangeField}
							displayDynamicDescriptors={false}
						/>
					</div>
				</div>
				{!showAdditionalOptions && (
					<div id="Buttons" data-testid="AccountDetailsButtons">
						<ListSubheader data-testid="AccountDetailsButtonsListSubheader">
							<FormattedMessage {...messages.lblOptionsHeader} />
						</ListSubheader>
						<hr
							style={inlineStyles.subheaderBorder}
							data-testid="AccountDetailsButtonsHr"
						/>
						<div
							style={inlineStyles.additionalOptionsButtons}
							data-testid="AccountDetailsButtonsDiv"
						>
							<div className="row" data-testid="AccountDetailsButtonsRow">
								{data.get('hasCustomInfo') || (
									<div
										className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3"
										data-testid="AddCustomInfoButtonContainer"
									>
										<Button
											data-testid="AddCustomInfoButton"
											variant="contained"
											id="srtAccountAddCISec"
											color="secondary"
											fullWidth
											styleName="button-spacing"
											onClick={handleClickAddCustomInfo}
											disabled={isProcessingAction}
										>
											<FormattedMessage {...messages.lblAddCustomInfo} />
										</Button>
									</div>
								)}
								{data.get('hasFees') || (
									<div
										className="col-12 col-sm-3 col-lg-2"
										data-testid="AddFeeButtonContainer"
									>
										<Button
											data-testid="AddFeeButton"
											variant="contained"
											id="srtAccountAddFeeSec"
											color="secondary"
											fullWidth
											styleName="button-spacing"
											onClick={handleClickAddFees}
											disabled={isProcessingAction}
										>
											<FormattedMessage {...messages.lblAddFees} />
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				{customInfo}
				{feesDiv}
				<hr style={inlineStyles.hr} data-testid="ButtonsHr" />
				<div
					id="SubmitButtons"
					className="row"
					styleName="space-fix"
					data-testid="ButtonsRow"
				>
					<div className="col-12 col-sm-6 col-lg-4 col-xl-3">
						{deleteButton}
					</div>
					<div className="col-12 col-sm-2 offset-sm-2 offset-lg-4 offset-xl-5">
						<Button
							data-testid="CancelButton"
							variant="contained"
							id="srtAccountCancel"
							styleName="button-spacing"
							fullWidth
							onClick={handleClickCancel}
							disabled={isProcessingAction}
						>
							<FormattedMessage {...messages.lblCancel} />
						</Button>
					</div>
					<div className="col-12 col-sm-2">
						<Button
							data-testid="SubmitButton"
							variant="contained"
							id="srtAccountSubmit"
							styleName="button-spacing"
							color="primary"
							fullWidth
							onClick={handleClickSubmit}
							disabled={isProcessingAction}
						>
							<FormattedMessage {...messages.lblSave} />
						</Button>
					</div>
				</div>
			</div>
		);
	},
);

Account.propTypes = {
	account: PropTypes.object,
	settings: PropTypes.object.isRequired,
	submit: PropTypes.func.isRequired,
	delete: PropTypes.func.isRequired,
	redirect: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	isProcessingAction: PropTypes.bool,
};

// This alias will be used to access bare component for unit testing
export { Account as AccountAlias };

export default injectIntl(Account);
